import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ReactElement, useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { SelfDevelopmentVideosForm } from "./selfDevelopmentVideosForm";
import { ListSkeleton } from "components/listSkeleton";
import RefreshIcon from "@mui/icons-material/Refresh";
import style from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { getSelfDevelopmentContentByCategory } from "redux/actions/selfDevelopmentActions";
import { MenuProps } from "styles/global.styles";

interface State {
  editing?: boolean;
  editItem?: any;
}

export default function SelfDevelopmentVideosScreen(): ReactElement {
  const dispatch = useDispatch();

  const selfDevelopmentData = useSelector(
    (state: RootState) => state.selfDevelopment.contentList
  );
  const categories = ["", "Featured video", "All videos"];

  const initialState: State = {
    editing: false,
    editItem: null,
  };

  const languages = useSelector((state: RootState) => state.app.languages);

  const typeList = [
    {
      displayName: "Mind",
      code: 1,
    },
    {
      displayName: "Body",
      code: 2,
    },
    {
      displayName: "Spirit",
      code: 3,
    },
  ];

  const [language, setLanguage] = useState<any>(1);
  const [type, setType] = useState<any>(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [states, setStates] = useState<State>(initialState);
  const [searchVideo, setSearchVideo] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSearchVideo("");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setSearchVideo("");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onClickBack = () => {
    setCurrentIndex(0);
    setSearchVideo("");
    setStates({
      editing: false,
      editItem: null,
    });
  };

  useEffect(() => {
    dispatch(
      getSelfDevelopmentContentByCategory(language, type, page, rowsPerPage)
    );
  }, [dispatch, language, page, rowsPerPage, type]);

  const renderVideosList = (data: any) => {
    return (
      <div>
        {data.length ? (
          <>
            <Grid
              style={{ backgroundColor: "transparent" }}
              className="d-flex py-2 pl-3 align-items-center"
            >
              <Grid className="d-flex flex-grow-1">
                <TextField
                  type="search"
                  value={searchVideo}
                  fullWidth
                  variant="outlined"
                  placeholder="Search video by title"
                  size="small"
                  onChange={(e) => {
                    setSearchVideo(e.target.value);
                    const filteredData = data.filter((item: any) =>
                      item.title
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    setsearchData(filteredData);
                  }}
                  className="mr-2"
                />
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    dispatch(
                      getSelfDevelopmentContentByCategory(
                        language,
                        type,
                        page,
                        rowsPerPage
                      )
                    );
                  }}
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer
              style={{ maxHeight: "400px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell
                      align="left"
                      sx={{
                        width: "40%",
                      }}
                    >
                      Title
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        width: "40%",
                      }}
                    >
                      category
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        width: "20%",
                      }}
                    >
                      Watching Time (sec)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchVideo !== "" ? searchData : data).map(
                    (item: any, x: any) => (
                      <React.Fragment key={x}>
                        <TableRow
                          sx={{ cursor: "pointer", height: "50px" }}
                          hover
                          onClick={() => {
                            setStates({
                              editing: true,
                              editItem: item,
                            });
                            setCurrentIndex(1);
                          }}
                        >
                          <TableCell align="left">
                            {item.title ? (
                              item.title
                            ) : (
                              <Chip
                                variant="filled"
                                label="Content is not set for this language"
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {categories[item.videocategory]}
                          </TableCell>
                          <TableCell align="left">{item.readingtime}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={selfDevelopmentData.content.pageable?.totalcount ?? 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No videos to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Card className="mt-3 mb-5">
            <Grow
              in={currentIndex === 0}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 0 ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid container className="align-items-center p-3" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">Videos</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setCurrentIndex(1);
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add Video
                    </Button>
                  </Grid>
                </Grid>
                <Grid container gap={2}>
                  <Grid item xs={6} sm={4} md={4} lg={3}>
                    <FormControl variant="outlined" className="m-3" fullWidth>
                      <InputLabel id="language-select">Language</InputLabel>
                      {languages && (
                        <Select
                          defaultValue=""
                          labelId="language-select"
                          value={language}
                          label="Language"
                          onChange={(e) => {
                            setLanguage(e.target.value as number);
                          }}
                          MenuProps={MenuProps}
                        >
                          {languages.map((lang: any, i: any) => (
                            <MenuItem key={i} value={lang.code}>
                              {lang.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={3}>
                    <FormControl variant="outlined" className="m-3" fullWidth>
                      <InputLabel id="language-select">Type</InputLabel>
                      <Select
                        defaultValue=""
                        labelId="language-select"
                        value={type}
                        label="Type"
                        onChange={(e) => {
                          setType(e.target.value as number);
                        }}
                      >
                        {typeList.map((type: any, i: any) => (
                          <MenuItem key={i} value={type.code}>
                            {type.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    {selfDevelopmentData.loading
                      ? ListSkeleton()
                      : renderVideosList(
                          selfDevelopmentData.content.videolistList
                        )}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
            <Grow
              in={currentIndex === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 1 ? { timeout: 1000 } : {})}
            >
              <SelfDevelopmentVideosForm
                type={type}
                isEditing={states.editing}
                editItem={states.editItem}
                onClickBack={onClickBack}
                lang={language}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
