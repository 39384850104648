import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Badge } from "api/models/gamification-service/Badge_pb";


interface DashboardState {
  badgesList: {
    loading: boolean;
    badges: Badge.AsObject[];
  };
}

const initialState: DashboardState = {
  badgesList: {
    loading: true,
    badges: [],
  }
};

export const badgesListSlice = createSlice({
  name: "badgesList",
  initialState,
  reducers: {
    loading: (state) => {
      state.badgesList.loading = true;
    },
    update: (state, action: PayloadAction<Badge.AsObject[]>) => {
      state.badgesList.loading = false;
      state.badgesList.badges = action.payload;
    },
    error: (state) => {
      state.badgesList.loading = false;
    },
  },
});

export const {
  loading: badgeListLoading,
  update: badgeListUpdated,
  error: badgeListError,
} = badgesListSlice.actions;

export default combineReducers({
  badgesList: badgesListSlice.reducer,
});
