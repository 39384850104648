// source: journal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.toObject = function(includeInstance, msg) {
  var f, obj = {
    journal: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    archivestatus: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    favourite: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    userid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    journaltype: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal;
  return proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJournal(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchivestatus(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavourite(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.JournalType} */ (reader.readEnum());
      msg.setJournaltype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJournal();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getArchivestatus();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getFavourite();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getJournaltype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string journal = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.getJournal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.setJournal = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} returns this
*/
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool archiveStatus = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.getArchivestatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.setArchivestatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool favourite = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.getFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.setFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 userID = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional unifiedplatform.heartintune.common.JournalType journalType = 6;
 * @return {!proto.unifiedplatform.heartintune.common.JournalType}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.getJournaltype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.JournalType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.JournalType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.prototype.setJournaltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    journal: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedat: (f = msg.getModifiedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    archivestatus: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    favourite: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    userid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    journaltype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    mooddetailsandtriggersList: jspb.Message.toObjectList(msg.getMooddetailsandtriggersList(),
    proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo;
  return proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setJournal(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedat(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchivestatus(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavourite(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.JournalType} */ (reader.readEnum());
      msg.setJournaltype(value);
      break;
    case 9:
      var value = new proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.deserializeBinaryFromReader);
      msg.addMooddetailsandtriggers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getJournal();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getArchivestatus();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFavourite();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getJournaltype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getMooddetailsandtriggersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string journal = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.getJournal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.setJournal = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp modifiedAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.getModifiedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.setModifiedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.clearModifiedat = function() {
  return this.setModifiedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.hasModifiedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool archiveStatus = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.getArchivestatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.setArchivestatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool favourite = 6;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.getFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.setFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 userID = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional unifiedplatform.heartintune.common.JournalType journalType = 8;
 * @return {!proto.unifiedplatform.heartintune.common.JournalType}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.getJournaltype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.JournalType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.JournalType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.setJournaltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated MoodDetailsAndTriggers moodDetailsAndTriggers = 9;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers>}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.getMooddetailsandtriggersList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers, 9));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.setMooddetailsandtriggersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers}
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.addMooddetailsandtriggers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.prototype.clearMooddetailsandtriggersList = function() {
  return this.setMooddetailsandtriggersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.toObject = function(includeInstance, msg) {
  var f, obj = {
    journalid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId;
  return proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJournalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJournalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 journalId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.prototype.getJournalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalId.prototype.setJournalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromdate: (f = msg.getFromdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    todate: (f = msg.getTodate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    searchjournalsby: jspb.Message.getFieldWithDefault(msg, 5, ""),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange;
  return proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFromdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTodate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchjournalsby(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTodate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSearchjournalsby();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp fromDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.getFromdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} returns this
*/
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.setFromdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.clearFromdate = function() {
  return this.setFromdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.hasFromdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp toDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.getTodate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} returns this
*/
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.setTodate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.clearTodate = function() {
  return this.setTodate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.hasTodate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 userID = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string searchJournalsBy = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.getSearchjournalsby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.setSearchjournalsby = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 6;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalDateRange.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.toObject = function(includeInstance, msg) {
  var f, obj = {
    journallistList: jspb.Message.toObjectList(msg.getJournallistList(),
    proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.toObject, includeInstance),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals}
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals;
  return proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals}
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.deserializeBinaryFromReader);
      msg.addJournallist(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagenumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagesize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJournallistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo.serializeBinaryToWriter
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated JournalInfo journalList = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo>}
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.getJournallistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals} returns this
*/
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.setJournallistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo}
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.addJournallist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.clearJournallistList = function() {
  return this.setJournallistList([]);
};


/**
 * optional int32 selectedPageNumber = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 selectedPageSize = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 totalCount = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.ListOfJournals.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    journalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    favourite: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus;
  return proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJournalid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavourite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJournalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFavourite();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 journalId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.prototype.getJournalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.prototype.setJournalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool favourite = 2;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.prototype.getFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalFavoriteStatus.prototype.setFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    journalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    archive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus;
  return proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJournalid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJournalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getArchive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 journalId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.prototype.getJournalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.prototype.setJournalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool archive = 2;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.prototype.getArchive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.RequestJournalArchiveStatus.prototype.setArchive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.toObject = function(includeInstance, msg) {
  var f, obj = {
    mooddetails: (f = msg.getMooddetails()) && proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.toObject(includeInstance, f),
    triggerdetailsList: jspb.Message.toObjectList(msg.getTriggerdetailsList(),
    proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers;
  return proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.deserializeBinaryFromReader);
      msg.setMooddetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.deserializeBinaryFromReader);
      msg.addTriggerdetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMooddetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.serializeBinaryToWriter
    );
  }
  f = message.getTriggerdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional MoodDetails moodDetails = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.prototype.getMooddetails = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers} returns this
*/
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.prototype.setMooddetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.prototype.clearMooddetails = function() {
  return this.setMooddetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.prototype.hasMooddetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TriggerDetails triggerDetails = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.prototype.getTriggerdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers} returns this
*/
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.prototype.setTriggerdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.prototype.addTriggerdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetailsAndTriggers.prototype.clearTriggerdetailsList = function() {
  return this.setTriggerdetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    moodid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    associatedcolor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    moodname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    moodtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails;
  return proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMoodid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedcolor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMoodname(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MoodType} */ (reader.readEnum());
      msg.setMoodtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoodid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAssociatedcolor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMoodname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMoodtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 moodId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.prototype.getMoodid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.prototype.setMoodid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string associatedColor = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.prototype.getAssociatedcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.prototype.setAssociatedcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string moodName = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.prototype.getMoodname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.prototype.setMoodname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.MoodType moodType = 4;
 * @return {!proto.unifiedplatform.heartintune.common.MoodType}
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.prototype.getMoodtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MoodType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MoodType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.MoodDetails.prototype.setMoodtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    triggerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    emojiUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    triggername: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails}
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails;
  return proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails}
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTriggerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmojiUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTriggername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTriggerid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmojiUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTriggername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 triggerId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.prototype.getTriggerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.prototype.setTriggerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string emoji_url = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.prototype.getEmojiUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.prototype.setEmojiUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string triggerName = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.prototype.getTriggername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.TriggerDetails.prototype.setTriggername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestjournalList: jspb.Message.toObjectList(msg.getRequestjournalList(),
    proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest;
  return proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.deserializeBinaryFromReader);
      msg.addRequestjournal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestjournalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RequestJournal requestJournal = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal>}
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.prototype.getRequestjournalList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.prototype.setRequestjournalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal}
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.prototype.addRequestjournal = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.journal.SaveListOfJournalsRequest.prototype.clearRequestjournalList = function() {
  return this.setRequestjournalList([]);
};


goog.object.extend(exports, proto.unifiedplatform.heartIntuneProfile.journal);
