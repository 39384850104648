import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Grow,
  IconButton,
  Paper,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import makeStyles from "@mui/styles/makeStyles";
import { ListSkeleton } from "components/listSkeleton";
import { Box } from "@mui/system";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import style from "./index.module.scss";
import InfoIcon from "@mui/icons-material/Info";
import {
  getAllBenifitsBySessionId,
  getAllLiveMeditationsBetweenDatesV2,
  getCoachListAsync,
} from "redux/actions/liveMeditationAction";
import { AssignLiveSessionForm } from "./assignLiveSessionForm";
import { schedularUpdateStep } from "redux/reducers/liveMeditationReducer";
import { useAuth } from "services/AuthService";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { CreateLiveSessionForm } from "../createLiveSession/createLiveSessionForm";

const useStyles = makeStyles((theme) => ({
  coachProfile: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  cardInfo: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  cardLabels: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

export const AssignLiveSessionScreen = () => {
  const userData = JSON.parse(localStorage.getItem("user")!);
  const classes = useStyles();

  const languages = [
    "",
    "Default",
    "English",
    "Hindi",
    "Telugu",
    "Gujarati",
    "Tamil",
    "kannada",
    "Marathi",
    "Italian",
    "Russian",
    "Spanish",
    "French",
    "Portuguese",
    "German",
    "Bengali",
    "Malayalam",
    "Ukrainian",
    "Odia",
    "English US",
    "English AUS",
  ];

  const currentUser = useAuth().currentUser;
  const dispatch = useDispatch();
  const sessionList = useSelector((state: RootState) => state.liveMeditation);

  const state = useSelector((state: RootState) => state.liveMeditation);

  const steps: string[] = ["Select Coach", "Select Time"];

  const [currentScreen, setCurrentScreen] = useState(0);
  const [searchSession, setSearchSession] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [searchData2, setsearchData2] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [coachId, setCoachId] = useState<number>(0);
  const [sessionId, setSessionId] = useState<number>(0);
  const [searchCoach, setsearchCoach] = useState("");
  const [sessionInfo, setSessionInfo] = useState<any>();
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    dispatch(getAllLiveMeditationsBetweenDatesV2(rowsPerPage, page, true));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    dispatch(schedularUpdateStep(0));
    dispatch(getCoachListAsync());
  }, [dispatch]);

  const _isStepDisabled = (stepIndex: number): boolean => {
    switch (stepIndex) {
      case 1:
        return !(
          state.selectedCoach !== undefined && state.selectedCoach !== null
        );
      case 2:
        // return !(state.sessionConfigType !== null);
        return state.sessionConfigType !== null;
      case 0:
      default:
        return false;
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage2 = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event: any) => {
    setRowsPerPage2(+event.target.value);
    setPage2(0);
  };

  const onClickBack = () => {
    setCurrentScreen(0);
    setSearchSession("");
    setsearchCoach("");
    setCoachId(0);
    setPage2(0);
    setRowsPerPage2(10);
    dispatch(schedularUpdateStep(0));
  };

  const sessionCard = (item) => {
    return (
      <>
        <Card
          sx={{
            borderRadius: "1rem",
            margin: "10px",
            padding: "5px",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6} lg={8}>
              <CardActionArea disabled>
                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                  <Grid item xs={12} sm={8}>
                    <CardContent
                      sx={{
                        paddingLeft: 0,
                      }}
                    >
                      <Box
                        className={classes.cardInfo}
                        sx={{
                          justifyContent: "space-between",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography component="div" variant="h6">
                          {item.sessioninfo?.sessiontitle}
                        </Typography>
                        <Typography
                          component="div"
                          variant="body2"
                          sx={{
                            opacity: "0.7",
                          }}
                        >
                          {item.sessioninfo?.aboutsession}
                        </Typography>
                        {languages[item.sessioninfo?.selectedlanguage] && (
                          <Typography
                            component="div"
                            variant="body1"
                            sx={{
                              opacity: "0.7",
                            }}
                          >
                            Language -{" "}
                            {languages[item.sessioninfo?.selectedlanguage]}
                          </Typography>
                        )}
                      </Box>
                    </CardContent>
                  </Grid>
                </Grid>
              </CardActionArea>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              lg={4}
              display={"flex"}
              justifyContent={"center"}
            >
              <CardActions>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="medium"
                      disableElevation
                      fullWidth
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setSessionId(item.sessioninfo?.id);
                        setCurrentScreen(1);
                        setSelectedTags(item.sessioninfo?.tagsList);
                      }}
                    >
                      <Typography variant="body2">Assign</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      disableElevation
                      fullWidth
                      startIcon={<InfoIcon />}
                      onClick={async () => {
                        await dispatch(
                          getAllBenifitsBySessionId(
                            Number(item.sessioninfo?.id)
                          )
                        );
                        setSessionInfo(item);
                        setCurrentScreen(3);
                      }}
                    >
                      <Typography variant="body2">Info</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </Card>
      </>
    );
  };

  const renderSessionList = (data: any) => {
    return (
      <div>
        {console.log("Assisgn Session Data", data)}
        {data.length ? (
          <>
            <Grid
              style={{ backgroundColor: "transparent" }}
              className="d-flex py-2 pl-3 align-items-center"
            >
              <Grid className="d-flex flex-grow-1">
                <TextField
                  type="search"
                  fullWidth
                  variant="outlined"
                  placeholder="Search session by title"
                  size="small"
                  value={searchSession}
                  onChange={(e) => {
                    setSearchSession(e.target.value);
                    setPage(0);
                    const filteredData = data.filter((item: any) =>
                      item.sessioninfo?.sessiontitle
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    setsearchData(filteredData);
                  }}
                  className="mr-2"
                />
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    dispatch(
                      getAllLiveMeditationsBetweenDatesV2(
                        rowsPerPage,
                        page,
                        true
                      )
                    );
                  }}
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            {(searchSession !== "" ? searchData : data).map(
              (item: any, x: any) => (
                <>{sessionCard(item)}</>
              )
            )}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={
                sessionList.liveMeditationsListBetween.liveMeditationsBetween
                  .pageable?.totalcount ?? 0
              }
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No sessions to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  const renderCoachList = () => (
    <>
      {state.coachList.coaches.length ? (
        <>
          <TableContainer
            style={{ maxHeight: "400px" }}
            className={style.scrollbar}
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell>Coach Profile Image</TableCell>
                  <TableCell align="left">Coach Name</TableCell>
                  <TableCell align="left">Coach Email</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchCoach !== ""
                  ? searchData2
                  : state.coachList.coaches.filter((coachProfile) => {
                      if (userData.role === 5) {
                        return coachProfile.email === currentUser?.email;
                      }
                      return true;
                    })
                )
                  .slice(
                    page2 * rowsPerPage2,
                    page2 * rowsPerPage2 + rowsPerPage2
                  )
                  .map((coachProfile, x) => {
                    return (() => {
                      if (userData.role === 5) {
                        if (currentUser?.email === coachProfile.email) {
                          return (
                            <React.Fragment key={`${coachProfile}${x}`}>
                              <TableRow hover>
                                <TableCell>
                                  <Avatar
                                    // style={{ backgroundColor: "#3f51b5" }}
                                    alt={coachProfile.name}
                                    src={coachProfile.imageurl}
                                  />
                                </TableCell>
                                <TableCell>{coachProfile.name}</TableCell>
                                <TableCell>{coachProfile.email}</TableCell>
                                <TableCell align="center">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      setCoachId(coachProfile.id);
                                      setCurrentScreen(2);
                                      dispatch(schedularUpdateStep(1));
                                    }}
                                  >
                                    Create Session
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        }
                      } else {
                        return (
                          <React.Fragment key={`${coachProfile}${x}`}>
                            <TableRow hover>
                              <TableCell>
                                <Avatar
                                  // style={{ backgroundColor: "#3f51b5" }}
                                  alt={coachProfile.name}
                                  src={coachProfile.imageurl}
                                />
                              </TableCell>
                              <TableCell>{coachProfile.name}</TableCell>
                              <TableCell>{coachProfile.email}</TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    setCoachId(coachProfile.id);
                                    setCurrentScreen(2);
                                    dispatch(schedularUpdateStep(1));
                                  }}
                                >
                                  Assign Session
                                </Button>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      }
                    })();
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={
              searchCoach !== ""
                ? searchData2.length
                : state.coachList.coaches.filter((coachProfile) => {
                    if (userData.role === 5) {
                      return coachProfile.email === currentUser?.email;
                    }
                    return true;
                  }).length
            }
            page={page2}
            onPageChange={handleChangePage2}
            rowsPerPage={rowsPerPage2}
            onRowsPerPageChange={handleChangeRowsPerPage2}
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
          />
        </>
      ) : (
        <Box height={350}>
          <Grid
            container
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" color="textSecondary">
              No coach to show
            </Typography>
          </Grid>
        </Box>
      )}
    </>
  );

  return (
    <Grid
      sx={{
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
        margin: {
          lg: "20px 80px 0px ",
          md: "20px 25px 0px ",
          sm: "20px 11px 0px",
        },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grow
            in={currentScreen === 0}
            mountOnEnter
            unmountOnExit
            style={{ transformOrigin: "0 0 0" }}
            {...(currentScreen === 0 ? { timeout: 1000 } : {})}
          >
            <div>
              <Grid
                container
                spacing={2}
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: " 16px",
                }}
              >
                <Grid item xs={12} sm={6} md={8} lg={6}>
                  <Grid
                    container
                    spacing={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Grid item>
                      <Typography variant="h6">Assign session</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <CardContent>
                <Paper>
                  {sessionList.liveMeditationsListBetween.loading
                    ? ListSkeleton()
                    : renderSessionList(
                        sessionList.liveMeditationsListBetween
                          .liveMeditationsBetween.sessioninfodetailsList
                      )}
                </Paper>
              </CardContent>
            </div>
          </Grow>
          {(currentScreen === 1 || currentScreen === 2) && (
            <Stepper
              activeStep={state.scheduleStep}
              alternativeLabel
              nonLinear
              style={{ padding: "10px 0", margin: 0 }}
            >
              {steps.map((step, index) => (
                <Step key={step} completed={false}>
                  <StepButton disabled={_isStepDisabled(index)}>
                    <StepLabel optional={false}>{step}</StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          )}
          <Grow
            in={currentScreen === 1}
            mountOnEnter
            unmountOnExit
            style={{ transformOrigin: "0 0 0" }}
            {...(currentScreen === 1 ? { timeout: 1000 } : {})}
          >
            <div>
              <Paper>
                <Grid
                  container
                  spacing={1}
                  style={{
                    padding: " 8px",
                    alignItems: "center",
                  }}
                >
                  <Grid item>
                    <IconButton onClick={() => onClickBack()} size="large">
                      <KeyboardBackspaceIcon color="action" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">Select Coach</Typography>
                  </Grid>
                </Grid>
                <Divider />
                {userData.role !== 5 ? (
                  <Grid
                    style={{ backgroundColor: "transparent" }}
                    className="d-flex py-2 pl-3 align-items-center"
                  >
                    <Grid className="d-flex flex-grow-1">
                      <TextField
                        type="search"
                        fullWidth
                        variant="outlined"
                        placeholder="Search coach by name or email"
                        size="small"
                        onChange={(e) => {
                          setsearchCoach(e.target.value);
                          const filteredData = state.coachList.coaches.filter(
                            (coachProfile) =>
                              coachProfile.name
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase()) ||
                              coachProfile.email
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                          );
                          setsearchData2(filteredData);
                        }}
                        className="mr-2"
                      />
                    </Grid>
                    <Grid className="d-flex align-items-center">
                      <IconButton
                        onClick={() => {
                          dispatch(getCoachListAsync());
                        }}
                        size="large"
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                {renderCoachList()}
              </Paper>
            </div>
          </Grow>

          <Grow
            in={currentScreen === 2}
            mountOnEnter
            unmountOnExit
            style={{ transformOrigin: "0 0 0" }}
            {...(currentScreen === 2 ? { timeout: 1000 } : {})}
          >
            <AssignLiveSessionForm
              onClickBack={onClickBack}
              coachId={coachId}
              sessionId={sessionId}
              setCoachId={setCoachId}
              setCurrentScreen={setCurrentScreen}
            />
          </Grow>
          <Grow
            in={currentScreen === 3}
            mountOnEnter
            unmountOnExit
            style={{ transformOrigin: "0 0 0" }}
            {...(currentScreen === 3 ? { timeout: 1000 } : {})}
          >
            <CreateLiveSessionForm
              onClickBack={onClickBack}
              isEditing={true}
              item={sessionInfo}
              type={"view"}
              rowsPerPage={10}
              page={0}
              setsessionId={setSessionId}
            />
          </Grow>
        </Grid>
      </Grid>
    </Grid>
  );
};
