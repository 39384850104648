import {
  CircularProgress,
  Button,
  Typography,
  Box,
  CardMedia,
  Chip,
  Grid,
  Divider,
  useMediaQuery,
} from "@mui/material";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DateRangeIcon from "@mui/icons-material/DateRange";
import GradeIcon from "@mui/icons-material/Grade";
import UsersIcon from "@mui/icons-material/SupervisedUserCircle";
import { useEffect, useState } from "react";
import moment from "moment";
import { getAllLiveMeditationsBetweenDates } from "redux/actions/liveMeditationAction";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { firebaseAuth } from "config/firebase";
import { grpc } from "@improbable-eng/grpc-web";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import {
  HEARTINTUNE_PROFILE_SERVICE_HOST,
  LIVE_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  RequestPreceptorSession,
  ResponseForLiveSessionState,
  ResponseForZoomWrapper,
  ID,
  RequestForLiveSessionState,
  ResponseAverageRating,
  ZoomLiveStreamActionRequest,
} from "api/models/live-meditation-service/live-meditation_pb";
import DialogBox from "components/dialogBox/dialog";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";
import { Empty } from "api/models/gamification-service/gamification_pb";
import makeStyles from "@mui/styles/makeStyles";
import AddToCalenderMenu from "./addToCalenderMenu";

// const PREFIX = "dialog";

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number;
}
interface Item {
  coachprofile: { id: number; email: string; imageurl: string; name: string };
  sessioninfo: {
    sessionsubtitle: string;
    sessiontitle: string;
    aboutsession: string;
    associatedcoachid: number;
    associatedlistofbenefits: any[];
    duration: number;
    id: number;
    iumageurl: string;
    link: string;
    liveparticipantcount: number;
    mediationsteps: string;
    registeredusercount: number;
    scheduledtime: { nanos: number; seconds: number };
    videourl: string;
    viewercountend: number;
    viewercountmid: number;
    viewercountstart: number;
    sessionstarttime: any;
    sessionendtime: any;
  };
}
interface Props {
  item: Item;
  dispatch: (a: any) => any;
  getAllBenifitsBySessionId: (a: number) => void;
  setcoachIdForEdit: (a: number) => void;
  setsessionIdForEdit: (a: number) => void;
  setStates: (a: { editing: boolean; editItem: Item }) => void;
  setcurrentIndex: (a: number) => void;
  setduplicateSession: (a: {
    editing: boolean;
    editItem: Item;
    coachId: number;
  }) => void;
  setsessionId: (a: number) => void;
  setsessionTitle: (a: any) => void;
  getAllQuestionsBySessionID: (a: number, b: number, c: number) => void;
  openModal?: any;
  setcoachIdForViewQuestions?: any;
  meetingStartUrl: any;
  startingLiveSession: number;
  liveSessionOn: number;
  liveSessionStop: number;
  startDate: any;
  endDate: any;
  setSnackBar: any;
  setInfoItem: any;
  language: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21;
}

const useStyles = makeStyles((theme) => ({
  coachProfile: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  cardInfo: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  cardLabels: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

export default function SingleCardComponent({
  item,
  dispatch,
  getAllBenifitsBySessionId,
  setcoachIdForEdit,
  setsessionIdForEdit,
  setStates,
  setcurrentIndex,
  setduplicateSession,
  setsessionId,
  setsessionTitle,
  meetingStartUrl,
  startingLiveSession,
  liveSessionOn,
  startDate,
  endDate,
  setSnackBar,
  language,
  setInfoItem,
}: Props) {
  const BUFFER_BEFORE_SCH_TIME: number = liveSessionOn * 60; //in seconds
  const BUFFER_AFTER_SCH_TIME: number = 95; //in seconds
  // const LIVE_SESSION_RUN_TIME: number = (liveSessionStop + 10) * 60; //in seconds
  console.log("item from livesession", item);
  const initialDeleteState = {
    showDeleteModal: false,
    deleteItemId: 0,
  };

  const classes = useStyles();

  const [sessionId] = useState(item.sessioninfo.id);
  const [sessionState, setSessionState] = useState<
    0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
  >(0);
  const [isSessionOn, setIsSessionOn] = useState<boolean>(false);
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const [deleteSession, setdeleteSession] = useState<State>(initialDeleteState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [averageRating, setAverageRating] = useState<any>(0);

  const [startConfirmation, setStartConfirmation] = useState<boolean>(false);
  const [stopConfirmation, setStopConfirmation] = useState<boolean>(false);
  const ResponsiveWidth = useMediaQuery("(min-width: 1000px)");

  // const state = useSelector(
  //   (state: RootState) =>
  //     state.liveMeditation.allSessionsQuestionList[sessionId.toString()]
  // );
  // const feedback = useSelector(
  //   (state: RootState) =>
  //     state.liveMeditation.feedbackList[sessionId.toString()]
  // );

  // useEffect(() => {
  //   function getSessionQuestions() {
  //     dispatch(getAllQuestionBySessionID(sessionId, 10, 0));
  //     dispatch(getAllUserFeedbackBySessionID(sessionId, 10, 0));
  //     console.log("Initital getUserFeedback");
  //   }
  //   getSessionQuestions();
  // }, [dispatch, sessionId]);

  useEffect(() => {
    getAverageFeedback();
    getPreceptorSessionState();
    enableBroadcast();
    _disableDelete();
    const broadcastTickInterval = setInterval(() => {
      enableBroadcast();
      _disableDelete();
    }, 29000);
    return () => {
      clearInterval(broadcastTickInterval);
    };
  }, [isSessionOn]);

  useEffect(() => {
    getPreceptorSessionState();
  }, [sessionState]);

  // const temp = { ...state };
  // const fb = { ...feedback };

  async function setPreceptorSessionState(
    _sessionState: 0 | 1 | 2 | 3 | 4 | 5 | 6
  ) {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();

    const reqBody: RequestPreceptorSession = new RequestPreceptorSession();
    reqBody.setLivesessionid(sessionId);
    reqBody.setLivesessionstate(_sessionState);
    grpc.unary(LiveMeditationService.PreceptorSession, {
      host: LIVE_MEDITATION_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<ResponseForLiveSessionState>) => {
        const { status, message } = res;
        if (status === grpc.Code.OK && message) {
          if (message) {
            console.log("set state response", message.toObject());
          }
        }
      },
    });
  }

  async function getPreceptorSessionState() {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();

    const reqBody: RequestForLiveSessionState =
      new RequestForLiveSessionState();
    reqBody.setLivesessionid(sessionId);

    grpc.unary(LiveMeditationService.GetLiveSessionCurrentState, {
      host: LIVE_MEDITATION_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<ResponseForLiveSessionState>) => {
        const { status, message } = res;
        console.log("session state call");
        if (status === grpc.Code.OK && message) {
          if (message) {
            console.log("Get session state response", message.toObject());
            setSessionState(message.toObject().livesessionstate);
          }
        }
      },
    });
  }
  async function getAverageFeedback() {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();

    const reqBody: ID = new ID();
    reqBody.setId(sessionId);

    grpc.unary(LiveMeditationService.GetAverageRatingBySessionId, {
      host: LIVE_MEDITATION_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<ResponseAverageRating>) => {
        const { status, message } = res;
        if (status === grpc.Code.OK && message) {
          if (message) {
            console.log("average rating response", message.toObject());
            if (message.getAverageraring().toString() === "NaN") {
              setAverageRating("N/A");
            } else {
              setAverageRating(
                `${message.toObject().averageraring.toFixed(1)}/3 (${
                  message.toObject().totalusersrating
                } votes)`
              );
            }
          }
        }
      },
    });
  }
  const endLiveStream = async () => {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: ZoomLiveStreamActionRequest =
      new ZoomLiveStreamActionRequest();
    reqBody.setLivestreamaction("Stop");
    grpc.unary(LiveMeditationService.UpdateZoomLiveStreamStatus, {
      host: LIVE_MEDITATION_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<ResponseForZoomWrapper>) => {
        const { message } = res;
        console.log("Live stream ended with code", message?.toObject().code);
      },
    });
  };
  const endZoomMeeting = async () => {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: Empty = new Empty();

    grpc.unary(LiveMeditationService.endZoomUrl, {
      host: LIVE_MEDITATION_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (status) => {
        setSessionState(5);
        console.log("Meeting ended", status);
      },
    });
  };

  const _DeleteLiveSessionId = async (data: any) => {
    console.log("_DeleteLiveSessionId data", data);
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(data.liveSessionId);
      setdeleteSession({ showDeleteModal: false });
      setIsLoading(true);
      grpc.unary(HeartIntuneProfileService.CancelLiveSessionById, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ProtobufMessage>) => {
          const { status, message } = res;
          console.log("Res", res);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "data coming form ClearUserDataForDeletedLiveSessions",
                message.toObject()
              );
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "success",
                message: "Session canceled successfully",
              });
              dispatch(
                getAllLiveMeditationsBetweenDates(startDate, endDate, language)
              );
            }
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: "error in canceling Session",
            });
            setdeleteSession({ showDeleteModal: false });
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "error",
        message: "error in canceling Session",
      });
      console.log(err);
    }
  };

  function stopLiveSession() {
    endLiveStream();
    endZoomMeeting();
  }
  const onclickStart = () => {
    window.open(meetingStartUrl, "_blank");
    setSessionState(2);
    setPreceptorSessionState(2);
    setStartConfirmation(false);
    // setTimeout(() => {
    //   setBroadcastLoading(false);
    // }, 2000);
  };

  const onclickStop = () => {
    // setBroadcastRunning(false);
    setStopConfirmation(false);
    stopLiveSession();
    //setPreceptorSessionState(5);
    // setPreceptorSessionState();
  };
  const enableBroadcast = () => {
    setIsSessionOn(
      Math.round(Date.now() / 1000) >=
        Number(item.sessioninfo?.scheduledtime?.seconds) -
          BUFFER_BEFORE_SCH_TIME &&
        Math.round(Date.now() / 1000) <
          Number(item.sessioninfo?.scheduledtime?.seconds) +
            BUFFER_AFTER_SCH_TIME
    );
  };
  const _disableDelete = () => {
    setDisableDelete(
      Math.round(Date.now() / 1000) >=
        item.sessioninfo.scheduledtime.seconds - startingLiveSession * 60
    );
  };

  const handleDeleteClose = () => {
    setdeleteSession({ showDeleteModal: false });
  };

  const _deleteSession = async () => {
    // const newTime=moment(new Date()).tz(`${timeZone}`).format("DD/MM/yyyy hh:mm a ");
    const data = {
      liveSessionId: deleteSession.deleteItemId,
    };
    _DeleteLiveSessionId(data);
  };
  const onDeleteSession = (liveSessionId: number) => {
    setdeleteSession({ showDeleteModal: true, deleteItemId: liveSessionId });
  };

  const broadcastButtonRender = (item) => {
    var currentTime = new Date();
    var endTime = new Date(item.sessioninfo.scheduledtime?.seconds * 1000);
    var difference = endTime.getTime() - currentTime.getTime();
    var resultInMinutes = Math.round(difference / 60000);
    let component;
    if (resultInMinutes > 40) {
      component = (
        <AddToCalenderMenu
          data={{
            start: item.sessioninfo.scheduledtime.seconds * 1000,
            duration: [1, "hour"],
            title: item.sessioninfo.sessiontitle,
            description: item.sessioninfo.aboutsession,
          }}
        />
      );
    } else {
      switch (sessionState) {
        case 0:
        case 1:
        case 3:
        case 5:
        case 6:
          component = (
            <Button
              variant="contained"
              fullWidth
              color="primary"
              disabled={!isSessionOn}
              onClick={() => setStartConfirmation(true)}
              startIcon={<PlayArrowRoundedIcon />}
            >
              <Typography variant="body2">Start</Typography>
            </Button>
          );
          break;

        case 2:
          component = (
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              onClick={() => setStopConfirmation(true)}
              startIcon={<StopRoundedIcon />}
            >
              <Typography variant="body2">Stop</Typography>
            </Button>
          );
          break;

        case 7:
          component = (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              // disabled={!isSessionOn}
              onClick={onclickStart}
              startIcon={<PlayArrowRoundedIcon />}
            >
              <Typography variant="body2">
                Pre Recorded Session Playing
              </Typography>
            </Button>
          );
          break;

        default:
          component = (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<WarningRoundedIcon />}
            >
              <Typography variant="body2">Warning</Typography>
            </Button>
          );
      }
    }
    return component;
  };
  return (
    <>
      <Box maxWidth="1200px" mr="30px" my="20px">
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid container flexDirection={ResponsiveWidth ? "row" : "column"}>
          <Grid item xs>
            <Box>
              <Grid container alignItems="center">
                <Grid item lg={3} sm={4} className={classes.coachProfile}>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 130,
                      width: 130,
                      borderRadius: "1rem",
                      display: "flex",
                      margin: "auto",
                      padding: "5px",
                    }}
                    image={item.coachprofile?.imageurl}
                    alt={item.sessioninfo?.sessiontitle}
                  ></CardMedia>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box
                    sx={{
                      paddingLeft: 0,
                    }}
                  >
                    <Box
                      className={classes.cardInfo}
                      sx={{
                        justifyContent: "space-between",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography component="div" variant="h6">
                        {item.sessioninfo?.sessiontitle}
                      </Typography>
                      <Typography component="div" variant="body1">
                        By {item.coachprofile?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                        gutterBottom
                      >
                        Scheduled on&nbsp;&nbsp;&nbsp;
                        {moment(
                          new Date(
                            Number(item.sessioninfo?.scheduledtime?.seconds) *
                              1000
                          )
                        )
                          .tz(moment.tz.guess(true))
                          .format("DD MMM, hh:mm a")}
                      </Typography>
                      <Grid item container flexWrap="nowrap" spacing={1}>
                        <Grid item>
                          <Chip
                            size="small"
                            variant="filled"
                            icon={<UsersIcon />}
                            label={`${item.sessioninfo?.registeredusercount} users`}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            size="small"
                            variant="filled"
                            icon={<DateRangeIcon />}
                            label={`${item.sessioninfo?.duration} minutes`}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            size="small"
                            variant="filled"
                            icon={<GradeIcon />}
                            label={`${averageRating}`}
                          />
                        </Grid>
                      </Grid>
                      <Grid item mt="10px">
                        {item.sessioninfo?.link && (
                          <Chip
                            sx={{
                              width: "100%",
                              maxWidth: "250px",
                            }}
                            label="Presentation Link"
                            target="_blank"
                            size="small"
                            color="primary"
                            component="a"
                            href={item.sessioninfo.link}
                            clickable
                            variant="outlined"
                          />
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={ResponsiveWidth ? 3 : 12}
            display="flex"
            justifyContent="center"
          >
            <Box maxWidth="250px">
              <Grid
                container
                mt={ResponsiveWidth ? undefined : 3}
                rowSpacing={1}
                columnSpacing={2}
              >
                <Grid item xs={12}>
                  <Box>{broadcastButtonRender(item)}</Box>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    disableElevation
                    // disabled={disableDelete}
                    startIcon={<RateReviewOutlinedIcon />}
                    onClick={async () => {
                      await dispatch(
                        getAllBenifitsBySessionId(Number(item.sessioninfo?.id))
                      );
                      setcoachIdForEdit(Number(item.coachprofile?.id));
                      setsessionIdForEdit(Number(item.sessioninfo?.id));
                      setStates({
                        editing: true,
                        editItem: item,
                      });
                      setcurrentIndex(3);
                    }}
                  >
                    <Typography variant="body2">Edit</Typography>
                    {/* Edit */}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    disableElevation
                    startIcon={<FileCopyIcon />}
                    onClick={() => {
                      setcurrentIndex(2);
                      setduplicateSession({
                        editing: true,
                        editItem: item,
                        coachId: item.coachprofile?.id,
                      });
                      dispatch(
                        getAllBenifitsBySessionId(Number(item.sessioninfo?.id))
                      );
                    }}
                  >
                    <Typography variant="body2">Copy</Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    fullWidth
                    disableElevation
                    startIcon={<DeleteIcon />}
                    onClick={() => onDeleteSession(sessionId)}
                    disabled={disableDelete}
                  >
                    <Typography variant="body2">Cancel</Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disableElevation
                    size="medium"
                    startIcon={<InfoIcon />}
                    onClick={() => {
                      setcurrentIndex(7);
                      setsessionId(Number(item.sessioninfo?.id));
                      setsessionTitle(item.sessioninfo?.sessiontitle);
                      setInfoItem(item);
                    }}
                    // disabled={state === undefined ? true : false}
                  >
                    <Typography variant="body2">Info</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <DialogBox
        open={deleteSession.showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={_deleteSession}
        type={"cancel"}
        title={`Are you sure that you want to cancel this session ?`}
      />
      <DialogBox
        open={startConfirmation || false}
        onClose={() => setStartConfirmation(false)}
        onCancel={() => setStartConfirmation(false)}
        onConfirm={onclickStart}
        type={"cancel"}
        title={`This action will start a zoom meeting which will be broadcasted. \nDo you want to continue?`}
      />
      <DialogBox
        open={stopConfirmation || false}
        onClose={() => setStopConfirmation(false)}
        onCancel={() => setStopConfirmation(false)}
        onConfirm={onclickStop}
        type={"cancel"}
        title={`This action will stop a zoom meeting and the live broadcast. \nDo you want to continue?`}
      />
    </>
  );
}
