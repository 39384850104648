import { Card, CardContent, Button, Dialog,  DialogContent, DialogActions } from "@mui/material";
//import { CloseRounded, SettingsPowerRounded } from "@mui/icons-material";
import React, { ReactElement } from "react";
import ReactCrop from "react-image-crop";

interface ImageCropProps {
  onImageLoaded: any;
  onCropComplete?: any;
  onCropChange: any;
  crop: any;
  src: string;
  onPressDone: any;
  onImageClose:any;
  open:boolean;
  //setopen:any
  onOpen:any;
  onClose:any;
  index?:number;
  type?:string
}

export default function ImageCrop(props: ImageCropProps): ReactElement {

  const _onOpen = () => {
    props.onOpen && props.onOpen();
  };

  const _onClose = () => {
    props.onClose && props.onClose();
  };
  return (
<>
<Dialog
      maxWidth="md"
      open={props.open}
      TransitionProps={{
        onEntered: _onOpen,
        onExit: _onClose,
      }}
    >
      <DialogContent dividers className="p-0" style={{maxWidth:'600px'}}>
      <Card>
      {/* <CardActionArea> */}
      <CardContent>
        <ReactCrop
          circularCrop
          src={props.src}
          crop={props.crop}
          ruleOfThirds
          onImageLoaded={props.onImageLoaded}
          onComplete={props.onCropComplete}
          onChange={props.onCropChange}
        />
      </CardContent>
      
      {/* </CardActionArea> */}
    </Card>
      </DialogContent>
      <DialogActions>
      <Button onClick={_onClose} variant="outlined" color="primary">
         Cancel
        </Button>
        <Button onClick={props.onPressDone} variant="outlined" color="primary">
          Done
        </Button>
          </DialogActions>
    </Dialog>


    </>
  );
}
