import {
  Card,
  IconButton, // Snackbar,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    //maxWidth: 300,
    width: "100%",
    boxShadow: "none",
    //position: "relative",
    //overflowY: "scroll",
  },
  progress: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },
  media: {
    height: 200,
    width: 200,
    //objectFit: "contain",
  },
  small: {
    height: 90,
    //  width:100,
    objectFit: "contain",
  },
}));

interface ImagePreviewProps {
  onImageClose?: any;
  src: string;
  fileName?: string;
  file?: any;
  setimageUrl?: any;
  size?: string;
  index?: number;
  rectangular?: boolean;
  type?: string;
}

function PreviewProfileImage(props: ImagePreviewProps): ReactElement {
  const style = useStyles();
  //console.log('props image',props.src)
  return (
    <>
      <Card className={style.root}>
        <IconButton
          aria-label="settings"
          onClick={() => {
            props.size === "small"
              ? props.onImageClose(props.index)
              : props.onImageClose();
          }}
          style={{
            position: "absolute",
            right: "-8px",
            top: "-8px",
            backgroundColor: "#CAC4C4",
            zIndex: 1,
          }}
          color="default"
          size="small"
        >
          <Close fontSize="small" color="inherit" />
        </IconButton>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          {props.type === "pdf" ? (
            <>
              <iframe
                src={`${props.src}#view=fitH`}
                width="100%"
                height="100%"
                title={props.src}
              />
            </>
          ) : (
            <img
              src={props.src}
              style={{ borderRadius: `${props.rectangular ? "5px" : "50%"}` }}
              className={props.size === "small" ? style.small : style.media}
              alt=""
            />
          )}
        </Grid>
      </Card>
    </>
  );
}

export default PreviewProfileImage;
