// source: program.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.unifiedplatform.gamification.program.GetAllProgramsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.GetAllThemesRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.ListOfTask', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.ProgramDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.ProgramList', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.RegisterProgram', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.TaskActivityType', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.TaskDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.ThemeDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.ThemeList', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.RegisterProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.RegisterProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.RegisterProgram.displayName = 'proto.unifiedplatform.gamification.program.RegisterProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.ProgramDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.ProgramDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.ProgramDetails.displayName = 'proto.unifiedplatform.gamification.program.ProgramDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.GetAllProgramsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.GetAllProgramsRequest.displayName = 'proto.unifiedplatform.gamification.program.GetAllProgramsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.displayName = 'proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.ProgramList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.program.ProgramList.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.ProgramList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.ProgramList.displayName = 'proto.unifiedplatform.gamification.program.ProgramList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.TaskDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.TaskDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.TaskDetails.displayName = 'proto.unifiedplatform.gamification.program.TaskDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.displayName = 'proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.ListOfTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.program.ListOfTask.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.ListOfTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.ListOfTask.displayName = 'proto.unifiedplatform.gamification.program.ListOfTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.displayName = 'proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.ThemeDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.ThemeDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.ThemeDetails.displayName = 'proto.unifiedplatform.gamification.program.ThemeDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.GetAllThemesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.GetAllThemesRequest.displayName = 'proto.unifiedplatform.gamification.program.GetAllThemesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.displayName = 'proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.ThemeList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.program.ThemeList.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.ThemeList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.ThemeList.displayName = 'proto.unifiedplatform.gamification.program.ThemeList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.displayName = 'proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.displayName = 'proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.RegisterProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.RegisterProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.RegisterProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    programid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expiryon: (f = msg.getExpiryon()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.RegisterProgram}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.RegisterProgram;
  return proto.unifiedplatform.gamification.program.RegisterProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.RegisterProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.RegisterProgram}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProgramid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiryon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.RegisterProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.RegisterProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.RegisterProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProgramid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiryon();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.RegisterProgram} returns this
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 programId = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.getProgramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.RegisterProgram} returns this
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.setProgramid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 userId = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.RegisterProgram} returns this
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.RegisterProgram} returns this
*/
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.RegisterProgram} returns this
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp expiryOn = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.getExpiryon = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.RegisterProgram} returns this
*/
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.setExpiryon = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.RegisterProgram} returns this
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.clearExpiryon = function() {
  return this.setExpiryon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.RegisterProgram.prototype.hasExpiryon = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.ProgramDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.ProgramDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fromdate: (f = msg.getFromdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    todate: (f = msg.getTodate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    programtopicname: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.ProgramDetails;
  return proto.unifiedplatform.gamification.program.ProgramDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.ProgramDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFromdate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTodate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramtopicname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.ProgramDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.ProgramDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFromdate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTodate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProgramtopicname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
*/
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
*/
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp createdAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
*/
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp fromDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.getFromdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
*/
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.setFromdate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.clearFromdate = function() {
  return this.setFromdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.hasFromdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp toDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.getTodate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
*/
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.setTodate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.clearTodate = function() {
  return this.setTodate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.hasTodate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string programTopicName = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.getProgramtopicname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramDetails.prototype.setProgramtopicname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.GetAllProgramsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.GetAllProgramsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageable: (f = msg.getPageable()) && common_common_pb.RequestPageable.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.GetAllProgramsRequest}
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.GetAllProgramsRequest;
  return proto.unifiedplatform.gamification.program.GetAllProgramsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.GetAllProgramsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.GetAllProgramsRequest}
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = new common_common_pb.RequestPageable;
      reader.readMessage(value,common_common_pb.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.GetAllProgramsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.GetAllProgramsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.GetAllProgramsRequest} returns this
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userId = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.GetAllProgramsRequest} returns this
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.RequestPageable pageable = 3;
 * @return {?proto.unifiedplatform.heartintune.common.RequestPageable}
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.RequestPageable} */ (
    jspb.Message.getWrapperField(this, common_common_pb.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.GetAllProgramsRequest} returns this
*/
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.GetAllProgramsRequest} returns this
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.program.GetAllProgramsRequest} returns this
 */
proto.unifiedplatform.gamification.program.GetAllProgramsRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fromdate: (f = msg.getFromdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    todate: (f = msg.getTodate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    programtopicname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isregistered: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage;
  return proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFromdate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTodate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramtopicname(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsregistered(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFromdate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTodate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProgramtopicname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsregistered();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
*/
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp fromDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.getFromdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
*/
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.setFromdate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.clearFromdate = function() {
  return this.setFromdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.hasFromdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp toDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.getTodate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
*/
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.setTodate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.clearTodate = function() {
  return this.setTodate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.hasTodate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string programTopicName = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.getProgramtopicname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.setProgramtopicname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool isRegistered = 8;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.getIsregistered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.prototype.setIsregistered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.program.ProgramList.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.ProgramList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.ProgramList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramList.toObject = function(includeInstance, msg) {
  var f, obj = {
    programlistwithselectedlanguageList: jspb.Message.toObjectList(msg.getProgramlistwithselectedlanguageList(),
    proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.toObject, includeInstance),
    programlistwithalllanguageList: jspb.Message.toObjectList(msg.getProgramlistwithalllanguageList(),
    proto.unifiedplatform.gamification.program.ProgramDetails.toObject, includeInstance),
    responsepageable: (f = msg.getResponsepageable()) && common_common_pb.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.ProgramList}
 */
proto.unifiedplatform.gamification.program.ProgramList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.ProgramList;
  return proto.unifiedplatform.gamification.program.ProgramList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.ProgramList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.ProgramList}
 */
proto.unifiedplatform.gamification.program.ProgramList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage;
      reader.readMessage(value,proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.deserializeBinaryFromReader);
      msg.addProgramlistwithselectedlanguage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.program.ProgramDetails;
      reader.readMessage(value,proto.unifiedplatform.gamification.program.ProgramDetails.deserializeBinaryFromReader);
      msg.addProgramlistwithalllanguage(value);
      break;
    case 3:
      var value = new common_common_pb.ResponsePageable;
      reader.readMessage(value,common_common_pb.ResponsePageable.deserializeBinaryFromReader);
      msg.setResponsepageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.ProgramList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.ProgramList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramlistwithselectedlanguageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.serializeBinaryToWriter
    );
  }
  f = message.getProgramlistwithalllanguageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.program.ProgramDetails.serializeBinaryToWriter
    );
  }
  f = message.getResponsepageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProgramWithSelectedLanguage programListWithSelectedLanguage = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage>}
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.getProgramlistwithselectedlanguageList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage>} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramList} returns this
*/
proto.unifiedplatform.gamification.program.ProgramList.prototype.setProgramlistwithselectedlanguageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.addProgramlistwithselectedlanguage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.program.ProgramList} returns this
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.clearProgramlistwithselectedlanguageList = function() {
  return this.setProgramlistwithselectedlanguageList([]);
};


/**
 * repeated ProgramDetails programListWithAllLanguage = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.program.ProgramDetails>}
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.getProgramlistwithalllanguageList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.program.ProgramDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.program.ProgramDetails, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.program.ProgramDetails>} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramList} returns this
*/
proto.unifiedplatform.gamification.program.ProgramList.prototype.setProgramlistwithalllanguageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.program.ProgramDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.program.ProgramDetails}
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.addProgramlistwithalllanguage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.program.ProgramDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.program.ProgramList} returns this
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.clearProgramlistwithalllanguageList = function() {
  return this.setProgramlistwithalllanguageList([]);
};


/**
 * optional unifiedplatform.heartintune.common.ResponsePageable responsePageable = 3;
 * @return {?proto.unifiedplatform.heartintune.common.ResponsePageable}
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.getResponsepageable = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, common_common_pb.ResponsePageable, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramList} returns this
*/
proto.unifiedplatform.gamification.program.ProgramList.prototype.setResponsepageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramList} returns this
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.clearResponsepageable = function() {
  return this.setResponsepageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramList.prototype.hasResponsepageable = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.TaskDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.TaskDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.TaskDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    url: (f = msg.getUrl()) && common_common_pb.Locale.toObject(includeInstance, f),
    scheduleat: (f = msg.getScheduleat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedat: (f = msg.getModifiedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    taskactivitytype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    useraction: jspb.Message.getFieldWithDefault(msg, 9, ""),
    ispublished: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    programid: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails}
 */
proto.unifiedplatform.gamification.program.TaskDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.TaskDetails;
  return proto.unifiedplatform.gamification.program.TaskDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.TaskDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails}
 */
proto.unifiedplatform.gamification.program.TaskDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setUrl(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduleat(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedat(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.gamification.program.TaskActivityType} */ (reader.readEnum());
      msg.setTaskactivitytype(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseraction(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspublished(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProgramid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.TaskDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.TaskDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.TaskDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getScheduleat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTaskactivitytype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getUseraction();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIspublished();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getProgramid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
*/
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
*/
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale url = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getUrl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
*/
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setUrl = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.clearUrl = function() {
  return this.setUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.hasUrl = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp scheduleAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getScheduleat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
*/
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setScheduleat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.clearScheduleat = function() {
  return this.setScheduleat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.hasScheduleat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
*/
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp modifiedAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getModifiedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
*/
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setModifiedat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.clearModifiedat = function() {
  return this.setModifiedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.hasModifiedat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TaskActivityType taskActivityType = 8;
 * @return {!proto.unifiedplatform.gamification.program.TaskActivityType}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getTaskactivitytype = function() {
  return /** @type {!proto.unifiedplatform.gamification.program.TaskActivityType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.gamification.program.TaskActivityType} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setTaskactivitytype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string userAction = 9;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getUseraction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setUseraction = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool isPublished = 10;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getIspublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setIspublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 programId = 11;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.getProgramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails} returns this
 */
proto.unifiedplatform.gamification.program.TaskDetails.prototype.setProgramid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    url: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scheduleat: (f = msg.getScheduleat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedat: (f = msg.getModifiedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    taskactivitytype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    useraction: jspb.Message.getFieldWithDefault(msg, 9, ""),
    ispublished: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    programid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    iscompleted: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage;
  return proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduleat(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedat(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.gamification.program.TaskActivityType} */ (reader.readEnum());
      msg.setTaskactivitytype(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseraction(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspublished(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProgramid(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScheduleat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTaskactivitytype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getUseraction();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIspublished();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getProgramid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getIscompleted();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string url = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp scheduleAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getScheduleat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
*/
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setScheduleat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.clearScheduleat = function() {
  return this.setScheduleat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.hasScheduleat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
*/
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp modifiedAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getModifiedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
*/
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setModifiedat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.clearModifiedat = function() {
  return this.setModifiedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.hasModifiedat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TaskActivityType taskActivityType = 8;
 * @return {!proto.unifiedplatform.gamification.program.TaskActivityType}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getTaskactivitytype = function() {
  return /** @type {!proto.unifiedplatform.gamification.program.TaskActivityType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.gamification.program.TaskActivityType} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setTaskactivitytype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string userAction = 9;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getUseraction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setUseraction = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool isPublished = 10;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getIspublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setIspublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 programId = 11;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getProgramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setProgramid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool isCompleted = 12;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.getIscompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.prototype.setIscompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.program.ListOfTask.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.ListOfTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.ListOfTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ListOfTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    tasklistwithalllanguageList: jspb.Message.toObjectList(msg.getTasklistwithalllanguageList(),
    proto.unifiedplatform.gamification.program.TaskDetails.toObject, includeInstance),
    tasklistwithselectedlanguageList: jspb.Message.toObjectList(msg.getTasklistwithselectedlanguageList(),
    proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && common_common_pb.ResponsePageable.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.ListOfTask}
 */
proto.unifiedplatform.gamification.program.ListOfTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.ListOfTask;
  return proto.unifiedplatform.gamification.program.ListOfTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.ListOfTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.ListOfTask}
 */
proto.unifiedplatform.gamification.program.ListOfTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.program.TaskDetails;
      reader.readMessage(value,proto.unifiedplatform.gamification.program.TaskDetails.deserializeBinaryFromReader);
      msg.addTasklistwithalllanguage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage;
      reader.readMessage(value,proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.deserializeBinaryFromReader);
      msg.addTasklistwithselectedlanguage(value);
      break;
    case 3:
      var value = new common_common_pb.ResponsePageable;
      reader.readMessage(value,common_common_pb.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.ListOfTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.ListOfTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ListOfTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTasklistwithalllanguageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.program.TaskDetails.serializeBinaryToWriter
    );
  }
  f = message.getTasklistwithselectedlanguageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.ResponsePageable.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * repeated TaskDetails taskListWithAllLanguage = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.program.TaskDetails>}
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.getTasklistwithalllanguageList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.program.TaskDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.program.TaskDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.program.TaskDetails>} value
 * @return {!proto.unifiedplatform.gamification.program.ListOfTask} returns this
*/
proto.unifiedplatform.gamification.program.ListOfTask.prototype.setTasklistwithalllanguageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.program.TaskDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.program.TaskDetails}
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.addTasklistwithalllanguage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.program.TaskDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.program.ListOfTask} returns this
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.clearTasklistwithalllanguageList = function() {
  return this.setTasklistwithalllanguageList([]);
};


/**
 * repeated TaskWithSelectedLanguage taskListWithSelectedLanguage = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage>}
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.getTasklistwithselectedlanguageList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage>} value
 * @return {!proto.unifiedplatform.gamification.program.ListOfTask} returns this
*/
proto.unifiedplatform.gamification.program.ListOfTask.prototype.setTasklistwithselectedlanguageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.addTasklistwithselectedlanguage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.program.ListOfTask} returns this
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.clearTasklistwithselectedlanguageList = function() {
  return this.setTasklistwithselectedlanguageList([]);
};


/**
 * optional unifiedplatform.heartintune.common.ResponsePageable pageable = 3;
 * @return {?proto.unifiedplatform.heartintune.common.ResponsePageable}
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, common_common_pb.ResponsePageable, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ListOfTask} returns this
*/
proto.unifiedplatform.gamification.program.ListOfTask.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ListOfTask} returns this
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.program.ListOfTask} returns this
 */
proto.unifiedplatform.gamification.program.ListOfTask.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    iscompleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    programid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails;
  return proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscompleted(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProgramid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTaskid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIscompleted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProgramid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 taskId = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.getTaskid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.setTaskid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 userId = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool isCompleted = 4;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.getIscompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.setIscompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails} returns this
*/
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 programId = 6;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.getProgramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails} returns this
 */
proto.unifiedplatform.gamification.program.ProgramTaskTrackerDetails.prototype.setProgramid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.ThemeDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.ThemeDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ThemeDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fromdate: (f = msg.getFromdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    todate: (f = msg.getTodate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    programid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.ThemeDetails;
  return proto.unifiedplatform.gamification.program.ThemeDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.ThemeDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFromdate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTodate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProgramid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.ThemeDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.ThemeDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ThemeDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFromdate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTodate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProgramid();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
*/
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
*/
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp createdAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
*/
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp fromDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.getFromdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
*/
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.setFromdate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.clearFromdate = function() {
  return this.setFromdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.hasFromdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp toDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.getTodate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
*/
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.setTodate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.clearTodate = function() {
  return this.setTodate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.hasTodate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 programId = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.getProgramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails} returns this
 */
proto.unifiedplatform.gamification.program.ThemeDetails.prototype.setProgramid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.GetAllThemesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.GetAllThemesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    programid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageable: (f = msg.getPageable()) && common_common_pb.RequestPageable.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.GetAllThemesRequest}
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.GetAllThemesRequest;
  return proto.unifiedplatform.gamification.program.GetAllThemesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.GetAllThemesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.GetAllThemesRequest}
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProgramid(value);
      break;
    case 2:
      var value = new common_common_pb.RequestPageable;
      reader.readMessage(value,common_common_pb.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.GetAllThemesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.GetAllThemesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 programId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.prototype.getProgramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.GetAllThemesRequest} returns this
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.prototype.setProgramid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.RequestPageable pageable = 2;
 * @return {?proto.unifiedplatform.heartintune.common.RequestPageable}
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.RequestPageable} */ (
    jspb.Message.getWrapperField(this, common_common_pb.RequestPageable, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.GetAllThemesRequest} returns this
*/
proto.unifiedplatform.gamification.program.GetAllThemesRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.GetAllThemesRequest} returns this
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.program.GetAllThemesRequest} returns this
 */
proto.unifiedplatform.gamification.program.GetAllThemesRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fromdate: (f = msg.getFromdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    todate: (f = msg.getTodate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    programid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage;
  return proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFromdate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTodate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProgramid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFromdate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTodate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProgramid();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} returns this
*/
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp fromDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.getFromdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} returns this
*/
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.setFromdate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.clearFromdate = function() {
  return this.setFromdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.hasFromdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp toDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.getTodate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} returns this
*/
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.setTodate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.clearTodate = function() {
  return this.setTodate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.hasTodate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 programId = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.getProgramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.prototype.setProgramid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.program.ThemeList.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.ThemeList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.ThemeList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ThemeList.toObject = function(includeInstance, msg) {
  var f, obj = {
    themelistwithselectedlanguageList: jspb.Message.toObjectList(msg.getThemelistwithselectedlanguageList(),
    proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.toObject, includeInstance),
    themelistwithalllanguageList: jspb.Message.toObjectList(msg.getThemelistwithalllanguageList(),
    proto.unifiedplatform.gamification.program.ThemeDetails.toObject, includeInstance),
    responsepageable: (f = msg.getResponsepageable()) && common_common_pb.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.ThemeList}
 */
proto.unifiedplatform.gamification.program.ThemeList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.ThemeList;
  return proto.unifiedplatform.gamification.program.ThemeList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.ThemeList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.ThemeList}
 */
proto.unifiedplatform.gamification.program.ThemeList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage;
      reader.readMessage(value,proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.deserializeBinaryFromReader);
      msg.addThemelistwithselectedlanguage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.program.ThemeDetails;
      reader.readMessage(value,proto.unifiedplatform.gamification.program.ThemeDetails.deserializeBinaryFromReader);
      msg.addThemelistwithalllanguage(value);
      break;
    case 3:
      var value = new common_common_pb.ResponsePageable;
      reader.readMessage(value,common_common_pb.ResponsePageable.deserializeBinaryFromReader);
      msg.setResponsepageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.ThemeList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.ThemeList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ThemeList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThemelistwithselectedlanguageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.serializeBinaryToWriter
    );
  }
  f = message.getThemelistwithalllanguageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.program.ThemeDetails.serializeBinaryToWriter
    );
  }
  f = message.getResponsepageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ThemeWithSelectedLanguage themeListWithSelectedLanguage = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage>}
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.getThemelistwithselectedlanguageList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage>} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeList} returns this
*/
proto.unifiedplatform.gamification.program.ThemeList.prototype.setThemelistwithselectedlanguageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.addThemelistwithselectedlanguage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.program.ThemeList} returns this
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.clearThemelistwithselectedlanguageList = function() {
  return this.setThemelistwithselectedlanguageList([]);
};


/**
 * repeated ThemeDetails themeListWithAllLanguage = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.program.ThemeDetails>}
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.getThemelistwithalllanguageList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.program.ThemeDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.program.ThemeDetails, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.program.ThemeDetails>} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeList} returns this
*/
proto.unifiedplatform.gamification.program.ThemeList.prototype.setThemelistwithalllanguageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.program.ThemeDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.program.ThemeDetails}
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.addThemelistwithalllanguage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.program.ThemeDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.program.ThemeList} returns this
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.clearThemelistwithalllanguageList = function() {
  return this.setThemelistwithalllanguageList([]);
};


/**
 * optional unifiedplatform.heartintune.common.ResponsePageable responsePageable = 3;
 * @return {?proto.unifiedplatform.heartintune.common.ResponsePageable}
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.getResponsepageable = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, common_common_pb.ResponsePageable, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ThemeList} returns this
*/
proto.unifiedplatform.gamification.program.ThemeList.prototype.setResponsepageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ThemeList} returns this
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.clearResponsepageable = function() {
  return this.setResponsepageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ThemeList.prototype.hasResponsepageable = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    programid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startdatetime: (f = msg.getStartdatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddatetime: (f = msg.getEnddatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pageable: (f = msg.getPageable()) && common_common_pb.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest;
  return proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProgramid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdatetime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddatetime(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 6:
      var value = new common_common_pb.RequestPageable;
      reader.readMessage(value,common_common_pb.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProgramid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStartdatetime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddatetime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_common_pb.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 programId = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.getProgramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.setProgramid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp startDateTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.getStartdatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} returns this
*/
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.setStartdatetime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.clearStartdatetime = function() {
  return this.setStartdatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.hasStartdatetime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp endDateTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.getEnddatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} returns this
*/
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.setEnddatetime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.clearEnddatetime = function() {
  return this.setEnddatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.hasEnddatetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 5;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional unifiedplatform.heartintune.common.RequestPageable pageable = 6;
 * @return {?proto.unifiedplatform.heartintune.common.RequestPageable}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.RequestPageable} */ (
    jspb.Message.getWrapperField(this, common_common_pb.RequestPageable, 6));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} returns this
*/
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndThemeRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.toObject = function(includeInstance, msg) {
  var f, obj = {
    themewithselectedlanguage: (f = msg.getThemewithselectedlanguage()) && proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.toObject(includeInstance, f),
    taskwithselectedlanguageList: jspb.Message.toObjectList(msg.getTaskwithselectedlanguageList(),
    proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.toObject, includeInstance),
    programwithselectedlanguage: (f = msg.getProgramwithselectedlanguage()) && proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.toObject(includeInstance, f),
    pageable: (f = msg.getPageable()) && common_common_pb.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme;
  return proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage;
      reader.readMessage(value,proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.deserializeBinaryFromReader);
      msg.setThemewithselectedlanguage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage;
      reader.readMessage(value,proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.deserializeBinaryFromReader);
      msg.addTaskwithselectedlanguage(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage;
      reader.readMessage(value,proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.deserializeBinaryFromReader);
      msg.setProgramwithselectedlanguage(value);
      break;
    case 4:
      var value = new common_common_pb.ResponsePageable;
      reader.readMessage(value,common_common_pb.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThemewithselectedlanguage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage.serializeBinaryToWriter
    );
  }
  f = message.getTaskwithselectedlanguageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage.serializeBinaryToWriter
    );
  }
  f = message.getProgramwithselectedlanguage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional ThemeWithSelectedLanguage themeWithSelectedLanguage = 1;
 * @return {?proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.getThemewithselectedlanguage = function() {
  return /** @type{?proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.program.ThemeWithSelectedLanguage|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} returns this
*/
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.setThemewithselectedlanguage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.clearThemewithselectedlanguage = function() {
  return this.setThemewithselectedlanguage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.hasThemewithselectedlanguage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TaskWithSelectedLanguage taskWithSelectedLanguage = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage>}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.getTaskwithselectedlanguageList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage>} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} returns this
*/
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.setTaskwithselectedlanguageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.addTaskwithselectedlanguage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.program.TaskWithSelectedLanguage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.clearTaskwithselectedlanguageList = function() {
  return this.setTaskwithselectedlanguageList([]);
};


/**
 * optional ProgramWithSelectedLanguage programWithSelectedLanguage = 3;
 * @return {?proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.getProgramwithselectedlanguage = function() {
  return /** @type{?proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage, 3));
};


/**
 * @param {?proto.unifiedplatform.gamification.program.ProgramWithSelectedLanguage|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} returns this
*/
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.setProgramwithselectedlanguage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.clearProgramwithselectedlanguage = function() {
  return this.setProgramwithselectedlanguage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.hasProgramwithselectedlanguage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.ResponsePageable pageable = 4;
 * @return {?proto.unifiedplatform.heartintune.common.ResponsePageable}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, common_common_pb.ResponsePageable, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} returns this
*/
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme} returns this
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.program.ProgramWithTaskAndTheme.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.unifiedplatform.gamification.program.TaskActivityType = {
  UNKNOWN: 0,
  MEDITATION: 1,
  CLEANING: 2,
  PRAYER: 3,
  VIDEO: 4,
  AUDIO: 5,
  READING: 6,
  DAIRY: 7,
  LINK: 8,
  SHARE: 9,
  OTHER: 10
};

goog.object.extend(exports, proto.unifiedplatform.gamification.program);
