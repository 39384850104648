/**
 * @author : Godwin Vinny Carole Kati
 * @created : 4141 28 2021, DDD, 01:04 A
 * @organisation : Codeprism Technologies Pvt Ltd
 **/ import {
  Card,
  CardActionArea,
  Button,
  Avatar,
  Box,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ImageOutlined, MusicNoteOutlined } from "@mui/icons-material";
import React, { ReactElement, useState } from "react";

import { Alert } from "@mui/material";
import AudioPlayer from "components/audio-player/audio-player";
import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { useFileUploadService } from "services/FileUplaodService";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import ImageCrop from "components/image-crop/image-crop";
import PreviewProfileImage from "../../../components/image-preview/profileImagePreview";
import {
  AddDurationFileDetailsRequest,
  AddMeditationFileDetailsRequest,
  DurationFile,
  MeditationFileDetails,
} from "api/models/guided-meditation-service/guided-meditation_pb";
import { firebaseAuth } from "config/firebase";
import { GuidedMeditationService } from "api/models/guided-meditation-service/guided-meditation_pb_service";
import {
  GAMIFICATION_SERVICE_HOST,
  GUIDED_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { getAllMeditation } from "redux/actions/guidedMeditationAction";
import { Locale } from "api/models/guided-meditation-service/common/common_pb";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
const PREFIX = "MeditationForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
    //overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },
}));

interface FormProps {
  isEditing?: boolean;
  editItem?: any;
  onClickBack?: any;
}

interface Crop {
  unit: "%" | "px" | undefined;
  width: number;
  aspect: number;
}
const meditationType = [
  "UNKNOWN_MEDITATION_TYPE",
  "MEDITATE_WITH_PURPOSE",
  "MEDITATE_WITH_GUIDANCE",
  "MEDITATE_WITH_PRECEPTOR",
  "MEDITATE_ANY_RECORDED_CONTEN",
];

export const MeditationForm = React.forwardRef<HTMLDivElement, FormProps>(
  (props, ref): ReactElement => {
    const dispatch = useDispatch();
    //const languages = useSelector((state: RootState) => state.app.languages);
    const hiddenFileInput: any = React.useRef(null);
    const hiddenSelectedImageInput: any = React.useRef(null);
    const hiddenAudioInput: any = React.useRef(null);

    const cropDef: Crop = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };

    const [title, setTitle] = useState<any>("");
    const [duration, setDuration] = useState<any>(0);
    const [audioUploadUrl, setAudioUploadUrl] = useState<string>();

    const [imageRef, setImageRef] = useState<any>();
    const [src, setSrc] = useState<any>(null);
    const [crop, setCrop] = useState<Crop>(cropDef);
    const [imageFile, setImageFile] = useState<any>(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState<any>(null);
    const [isAdding, setIsAdding] = useState(false);

    const [selectedImageRef, setselectedImageRef] = useState<any>();
    const [selectedImagesrc, setselectedImageSrc] = useState<any>(null);
    const [selectedImagecrop, setselectedImageCrop] = useState<Crop>(cropDef);
    const [selectedImageFile, setselectedImageFile] = useState<any>(null);
    const [selectedcroppedImageUrl, setselectedCroppedImageUrl] =
      useState<any>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setopen] = useState(false);
    const [isUploading, setIsUploading] = useState({
      image: false,
      selectedImage: false,
      audio: false,
    });
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const { appendFilesToUploadManager } = useFileUploadService();

    const _onOpenImage = () => {
      setopen(true);
    };
    const _onCloseImage = () => {
      setopen(false);
      setIsUploading({ ...isUploading, image: false, selectedImage: false });
    };

    // --------------------------image upload--------------------------------------->
    const handleUploadClick = () => {
      hiddenFileInput.current.click();
    };
    const onSelectImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading({ ...isUploading, image: true });
        setImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };

    const onCropDone = async () => {
      const cr: any = crop;
      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        setCroppedImageUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          "MeditationFile".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setCroppedImageUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading({ ...isUploading, image: false });
            } else {
              setIsUploading({ ...isUploading, image: false });
            }
          }
        );
      }
      setSrc(null);
    };

    const onCropChange = (crop: any) => {
      setCrop(crop);
    };
    const onCropComplete = async () => {
      //console.log("state crop", crop, imageRef);
    };
    const onImageClose = () => {
      setSrc(null);
      setImageFile(null);
      _deleteFileFromGcp(croppedImageUrl);
      setCroppedImageUrl("");
      setCrop(cropDef);
      setIsUploading({ ...isUploading, image: false });
    };

    // -------------------------- selected image upload--------------------------------------->
    const _handleUploadClick = () => {
      hiddenSelectedImageInput.current.click();
    };
    const _onSelectImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading({ ...isUploading, selectedImage: true });
        setselectedImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setselectedImageSrc(reader.result);
          setopen(true);
        };
      }
    };
    const _onImageLoaded = (image: any) => {
      setselectedImageRef(image);
    };

    const _onCropDone = async () => {
      const cr: any = selectedImagecrop;
      setIsUploading({ ...isUploading, selectedImage: true });
      if (selectedImageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(selectedImageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date()
            .getTime()
            .toString()}_${selectedImageFile.name.replaceAll(" ", "")}`
        );
        setselectedCroppedImageUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          "MeditationFile".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setselectedCroppedImageUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading({ ...isUploading, selectedImage: false });
            } else {
              setIsUploading({ ...isUploading, selectedImage: false });
            }
          }
        );
      }
      setselectedImageSrc(null);
    };

    const _onCropChange = (crop: any) => {
      setselectedImageCrop(crop);
    };
    const _onCropComplete = async () => {
      //console.log("state crop", crop, imageRef);
    };
    const _onImageClose = () => {
      setselectedImageSrc(null);
      _deleteFileFromGcp(selectedcroppedImageUrl);
      setselectedCroppedImageUrl("");
      setselectedImageFile(null);
      setselectedImageCrop(cropDef);
      setIsUploading({ ...isUploading, selectedImage: false });
    };
    //---------------------------audio upload------------------------------------->
    const handleAudioClick = () => {
      hiddenAudioInput.current.click();
    };

    const onAudioSelection = async (event: any) => {
      setIsUploading({ ...isUploading, audio: true });
      const data = new File(
        [event.target.files[0]],
        event.target.files[0].name.replaceAll(" ", "")
      );

      const uploadFile = toUploadFile(
        data,
        "MeditationFile".replaceAll(" ", "")
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setAudioUploadUrl(res.detail.response);
            setIsUploading({ ...isUploading, audio: false });
          } else {
            setIsUploading({ ...isUploading, audio: false });
          }
        }
      );
    };

    const onAudioClose = () => {
      setAudioUploadUrl("");
    };

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
              }
            } else {
              setIsLoading(false);
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };

    //---------------------------audio upload end------------------------------------->

    const validateMeditaion = () => {
      if (title.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Meditation title can not be empty",
        });
      } else if (duration === 0 || duration === "0") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Duration must be greater than 0",
        });
      } else if (croppedImageUrl === "" || croppedImageUrl === null) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Image can not be empty",
        });
      } else if (
        selectedcroppedImageUrl === "" ||
        selectedcroppedImageUrl === null
      ) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Image can not be empty",
        });
      } else if (audioUploadUrl === "" || audioUploadUrl === null) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Audio can not be empty",
        });
      } else {
        return true;
      }
    };

    const _addMeditation = () => {
      if (validateMeditaion()) {
        setIsAdding(true);
        const newData = {
          durationInSeconds: duration,
          imageURL: croppedImageUrl,
          selectedImageURL: selectedcroppedImageUrl,
        };
        _addDurationFile(newData);
      }
    };
    const _addDurationFile = async (data: any) => {
      setIsLoading(true);
      try {
        var addDurationReq = new AddDurationFileDetailsRequest();
        addDurationReq.setDurationinseconds(data.durationInSeconds);
        addDurationReq.setImageurl(data.imageURL);
        addDurationReq.setSelectedimageurl(data.selectedImageURL);

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GuidedMeditationService.addDurationFileDetails, {
          request: addDurationReq,
          host: GUIDED_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<DurationFile>) => {
            const { status, message } = res;
            if (status === grpc.Code.OK && message) {
              console.log("all ok: ", message.toObject());
              const id = message.toObject().id;
              console.log("id", id);
              // setdurationFileId(id);
              _addMed(id);
            } else {
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in adding Duration",
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in adding Duration",
        });
        console.error("something went wrong in Meditation", error);
      }
    };
    const _addMed = (id: number) => {
      const data: any = {
        meditationType: 2,
        title: title,
        subTitle: "",
        description: "",
        duration: Number(duration),
        tag: "https://storage.googleapis.com/heartintune-dev-storage/purposes/LS_HOLDER.png",
        rating: 0,
        likes: 5,
        audioUrl: audioUploadUrl,
        imageUrl: "",
        thumbnailImgUrl: "",
        purposeFileID: 7,
        durationFileID: id,
      };
      console.log(data);
      _addMeditationFile(data);
    };
    const _addMeditationFile = async (data: any) => {
      // console.log("meditaion data", data);
      try {
        const addMeditationReq = new AddMeditationFileDetailsRequest();

        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title);
        titleLocale.setEn(data.title);
        addMeditationReq.setTitle(titleLocale);

        const descriptionLocale: Locale = new Locale();
        descriptionLocale.setDefaultcontent(data.description);
        descriptionLocale.setEn(data.description);
        addMeditationReq.setDescription(descriptionLocale);

        const subtitleLocale: Locale = new Locale();
        subtitleLocale.setDefaultcontent(data.subTitle);
        subtitleLocale.setEn(data.subTitle);
        addMeditationReq.setSubtitle(subtitleLocale);

        const audioLocale: Locale = new Locale();
        audioLocale.setDefaultcontent(data.audioUrl);
        audioLocale.setEn(data.audioUrl);
        addMeditationReq.setAudiourl(audioLocale);

        const imageLocale: Locale = new Locale();
        imageLocale.setDefaultcontent(data.imageUrl);
        imageLocale.setEn(data.imageUrl);
        addMeditationReq.setImageurl(imageLocale);

        const thumbnailLocale: Locale = new Locale();
        thumbnailLocale.setDefaultcontent(data.thumbnailImgUrl);
        thumbnailLocale.setEn(data.thumbnailImgUrl);
        addMeditationReq.setThumbnailimgurl(thumbnailLocale);

        addMeditationReq.setLikes(data.likes);
        addMeditationReq.setMeditationtype(data.meditationType);
        addMeditationReq.setRating(data.rating);
        addMeditationReq.setTag(data.tag);
        addMeditationReq.setDurationofmeditationclip(data.duration);
        addMeditationReq.setDurationfileid(data.durationFileID);
        addMeditationReq.setPurposefileid(data.purposeFileID);
        console.log(addMeditationReq.toObject());

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GuidedMeditationService.addMeditationFileDetails, {
          request: addMeditationReq,
          host: GUIDED_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<MeditationFileDetails>) => {
            const { status, statusMessage, message } = res;
            console.log("statusMessage", statusMessage);
            console.log("grpc code", grpc.Code);

            if (status === grpc.Code.OK && message) {
              console.log("all ok ", message.toObject());
              dispatch(getAllMeditation(10, 0, 2));
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "success",
                message: "Guided meditation added successfully",
              });
              setTimeout(() => props.onClickBack(), 2000);
              return message;
            } else {
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in adding Guided meditation ",
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in addMeditation",
        });
        console.error("something went wrong in addMeditation", error);
      }
    };

    return (
      <Root ref={ref}>
        {isLoading && (
          <CircularProgress
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              zIndex: 5,
            }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid>
                <Typography variant="h6">Add New Meditation</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12} className="d-flex">
              <TextField
                fullWidth
                type="text"
                value={title}
                onChange={(e) => {
                  //onChangeTitle(e);
                  setTitle(e.target.value);
                }}
                onBlur={() => {
                  console.log("state values", title);
                }}
                label="Meditation Title"
                name="meditationTitle"
                required
                variant="outlined"
                className="mr-3"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="text"
                disabled
                //helperText={`Please enter the description of the meditation`}
                label="Meditation Type"
                name="type"
                variant="outlined"
                value={meditationType[2]}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value > "0" ? e.target.value : "");
                }}
                // helperText={`Please enter the duration of the meditation`}
                label="Meditation duration(in sec)"
                name="duration"
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {croppedImageUrl ? (
                    <PreviewProfileImage
                      onImageClose={onImageClose}
                      src={croppedImageUrl}
                    />
                  ) : (
                    <>
                      {isUploading.image ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.thumbNail}
                          onClick={() => {
                            handleUploadClick();
                          }}
                        >
                          <InputLabel htmlFor="thumbnail">
                            Upload image
                          </InputLabel>
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <ImageOutlined />
                          </Avatar>
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            hidden
                            onChange={onSelectImage}
                            ref={hiddenFileInput}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {selectedcroppedImageUrl ? (
                    <PreviewProfileImage
                      onImageClose={_onImageClose}
                      src={selectedcroppedImageUrl}
                    />
                  ) : (
                    <>
                      {isUploading.selectedImage ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.thumbNail}
                          onClick={() => {
                            _handleUploadClick();
                          }}
                        >
                          <InputLabel htmlFor="thumbnail">
                            Upload selected image
                          </InputLabel>
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <ImageOutlined />
                          </Avatar>
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            hidden
                            onChange={_onSelectImage}
                            ref={hiddenSelectedImageInput}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {audioUploadUrl ? (
                    <>
                      <Card className={classes.audioPlayer}>
                        <AudioPlayer
                          src={audioUploadUrl}
                          onAudioClose={onAudioClose}
                        />
                      </Card>
                    </>
                  ) : (
                    <>
                      {isUploading.audio === true ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.thumbNail}
                          onClick={() => {
                            handleAudioClick();
                          }}
                        >
                          <InputLabel htmlFor="thumbnail">
                            Upload Audio
                          </InputLabel>
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <MusicNoteOutlined />
                          </Avatar>
                          <input
                            accept="audio/*"
                            id="audioFile"
                            type="file"
                            hidden
                            onChange={onAudioSelection}
                            ref={hiddenAudioInput}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            className={classes.formBtn}
            onClick={() => {
              _addMeditation();
            }}
            disabled={isAdding}
            color="primary"
            variant="contained"
          >
            Add Meditation
          </Button>
        </Box>
        {src && (
          <ImageCrop
            src={src.toString()}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onCropComplete={onCropComplete}
            onCropChange={onCropChange}
            onPressDone={onCropDone}
            onImageClose={onImageClose}
            open={open}
            onOpen={_onOpenImage}
            onClose={_onCloseImage}
          />
        )}
        {selectedImagesrc && (
          <ImageCrop
            src={selectedImagesrc.toString()}
            crop={selectedImagecrop}
            onImageLoaded={_onImageLoaded}
            onCropComplete={_onCropComplete}
            onCropChange={_onCropChange}
            onPressDone={_onCropDone}
            onImageClose={_onImageClose}
            open={open}
            onOpen={_onOpenImage}
            onClose={_onCloseImage}
          />
        )}
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
