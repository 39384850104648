import React, { ReactElement, useContext, useEffect, useState } from "react";
import firebase from "firebase";
import { useHistory } from "react-router";
import { firebaseAuth } from "config/firebase";
interface AuthContextType {
 // createdUser?: firebase.User | null;
  currentUser?: firebase.User | null;
  emailSignUp?: (
    email: string,
    password: string
  ) => Promise<firebase.auth.UserCredential>;
  emailLogin?: (
    email: string,
    password: string
  ) => Promise<firebase.auth.UserCredential>;
  logout?: () => Promise<void>;
  resetPassword?: (email: string) => Promise<void>;
  updateEmail?: (email: string) => Promise<void> | undefined;
  updatePassword?: (password: string) => Promise<void> | undefined;
  //updateCurrentUser?:(user:firebase.User | null)=>Promise<firebase.auth.UserCredential> | undefined
}

export const AuthContext = React.createContext<AuthContextType>({});

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthService({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) {
  //const state = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<firebase.User | null>();
  const history = useHistory();
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        console.log('user',user);
        setCurrentUser(user);
       // history.replace("/login");
        // if (!history.location.pathname.match("/app")) history.replace("/app");
        //userLogin(user.uid);
      }else {
        history.replace("/login");
      }
      setLoading(false);
    });
    return unsubscribe;
  }, [history]);

  function emailSignUp(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return firebaseAuth.createUserWithEmailAndPassword(email, password);
  }

  function emailLogin(email: string, password: string) {
    return firebaseAuth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return firebaseAuth.signOut();
  }

  function resetPassword(email: string) {
    return firebaseAuth.sendPasswordResetEmail(email);
  }

  function updateEmail(email: string) {
    return currentUser?.updateEmail(email);
  }

  function updatePassword(password: string) {
    return currentUser?.updatePassword(password);
  }
 
  const value: AuthContextType = {
   
    currentUser,
    emailSignUp,
    emailLogin,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
