import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Grow,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ListSkeleton } from "components/listSkeleton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import BhandaraTagsForm from "./BhandaraTagsForm";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getAllBhandaraTags } from "redux/actions/bhandaraActions";
const BhandaraTagsScreen = () => {
  const dispatch = useDispatch();
  const tags = useSelector((state: RootState) => state.bhandara.tagList);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [searchTags, setSearchTags] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editItem, setEditItem] = useState({});

  const onClickBack = () => {
    setCurrentIndex(0);
    setIsEditing(false);
    setEditItem({});
    setSearchTags("");
    //dispatch(getAllTags(rowsPerPage, page));
  };

  const handleChangePage = (event: any, newpage: number) => {
    setPage(newpage);
    setSearchTags("");
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    setSearchTags("");
  };

  useEffect(() => {
    dispatch(getAllBhandaraTags(rowsPerPage, page));
  }, [dispatch, rowsPerPage, page]);

  const TagsList = (data: typeof tags.tags) => {
    return (
      <div>
        <Grid container>
          <Grid item flexGrow={1}>
            <TextField
              type="search"
              value={searchTags}
              fullWidth
              variant="outlined"
              placeholder="Search Tags by tagname"
              size="small"
              className="mr-2"
              onChange={(e) => {
                setSearchTags(e.target.value);
                const filteredData = data.tagsList.filter((item: any) =>
                  item.name.toLowerCase().includes(e.target.value.toLowerCase())
                );
                setsearchData(filteredData);
              }}
            />
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                dispatch(getAllBhandaraTags(rowsPerPage, page));
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
        {data?.tagsList?.length ? (
          <>
            <TableContainer sx={{ maxHeight: 400, mt: 1 }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="left">Tags</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchTags !== "" ? searchData : data.tagsList).map(
                    (item: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{ cursor: "pointer", height: "50px" }}
                        hover
                        onClick={() => {
                          setIsEditing(true);
                          setEditItem(item);
                          setCurrentIndex(1);
                        }}
                      >
                        <TableCell>{item.name}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={tags.tags.pageable?.totalcount!}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ height: 400, mt: 1 }}>
               <Typography color={'gray'}>No records found</Typography>
          </Box>
        )}
      </div>
    );
  };
  return (
    <Container>
      <Card sx={{ mt: 3, mb: 5 }}>
        <Grow
          in={currentIndex === 0}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          timeout={currentIndex === 0 ? 1000 : 0}
        >
          <div>
            <Grid container spacing={2} sx={{ p: 3 }}>
              <Grid item>
                <Typography variant="h6">Tags</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsEditing(false);
                    setEditItem({});
                    setCurrentIndex(1);
                  }}
                  startIcon={<AddIcon />}
                >
                  Add Tags
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              {tags.loading ? ListSkeleton() : TagsList(tags.tags)}
            </CardContent>
          </div>
        </Grow>
        <Grow
          in={currentIndex === 1}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          timeout={currentIndex === 1 ? 1000 : 0}
        >
          <div>
            <BhandaraTagsForm
              isEditing={isEditing}
              editItem={editItem}
              onClickBack={onClickBack}
              setCurrentIndex={setCurrentIndex}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          </div>
        </Grow>
      </Card>
    </Container>
  );
};

export default BhandaraTagsScreen;
