import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import { VideoFileOutlined } from "@mui/icons-material";
import {
  ContentCategoryRequest,
  ContentRequest,
  ContentResponse,
  DeleteFileRequest,
  ResponseStatus,
  Status,
} from "api/models/gamification-service/gamification_pb";
import { firebaseAuth } from "config/firebase";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import VideoPlayers from "components/video-player/videoPlayer";
import DialogBox from "components/dialogBox/dialog";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import { useFileUploadService } from "services/FileUplaodService";
import { toUploadFile } from "utils/helpers";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { RootState } from "redux/store/store";
import { getAllYoutubeVideos } from "redux/actions/heartsAppActions";

interface VideosProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21;
  page: number;
  rowsPerPage: number;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: String;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
  deleteItemId: "0",
};

const PREFIX = "ArticlesForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
    //overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));
const localeObject = {
  defaultcontent: "",
  en: "",
  hi: "",
  te: "",
  gu: "",
  ta: "",
  ka: "",
  mr: "",
  it: "",
  ru: "",
  es: "",
  fr: "",
  pt: "",
  de: "",
  bn: "",
  ml: "",
  uk: "",
  od: "",
  enUs: "",
  enAu: "",
  pa: "",
};

const languageType = Object.keys(localeObject);

export const VideosForms = React.forwardRef<HTMLDivElement, VideosProps>(
  (props, ref): ReactElement => {
    const hiddenVideoInput: any = React.useRef(null);

    const doccumentCategoryList = [
      {
        displayName: "Anxiety",
        code: 1,
      },
      {
        displayName: "Sleep",
        code: 2,
      },
      {
        displayName: "Meditate",
        code: 3,
      },
      {
        displayName: "Cleaning",
        code: 4,
      },
    ];

    const dispatch = useDispatch();
    const [videoUploadUrl, setVideoUploadUrl] = useState<string>("");
    const [videoCategory, setVideoCategory] = useState<any>(1);

    const languages = useSelector((state: RootState) => state.app.languages);
    const { appendFilesToUploadManager } = useFileUploadService();
    const [language, setLanguage] = useState<any>(1);
    const [states, setStates] = useState<State>(initialState);
    const [id, setId] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [subtitle, setSubtitle] = useState<string>("");
    const [readingTime, setReadingTime] = useState<number | string>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isUploading, setIsUploading] = useState({
      video: false,
      image: false,
      selectedImage: false,
    });
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const onClickEdit = useCallback(
      (item: any) => {
        console.log("item", item);
        setStates({ editing: true });
        setLanguage(props.lang);
        setId(item.id);
        setTitle(item.title);
        setSubtitle(item.subtitle);
        setReadingTime(item.readingtime);
        setVideoUploadUrl(item.videourl);
        setVideoCategory(item.documentcategory);
      },
      [props.lang]
    );

    useEffect(() => {
      console.log("Props: ", props.editItem);
      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
    }, [props.isEditing, props.editItem, onClickEdit]);
    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("video url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
              }
            } else {
              setIsLoading(false);
            }
          },
        });
      } catch (err) {
        console.log("Error ", err);
      }
    };
    const onVideoClose = () => {
      //setVideoFile(videoFileDef);
      setVideoUploadUrl("");
    };

    const handleVideoClick = () => {
      hiddenVideoInput.current.click();
    };

    const onClickDelete = (videoId: any) => {
      setStates({ showDeleteModal: true, deleteItemId: videoId });
    };

    const handleDeleteClose = () => {
      setStates({ showDeleteModal: false });
    };

    const deleteVideo = async () => {
      deleteVideoById(states.deleteItemId);
    };
    const deleteVideoById = async (videoeId: String | undefined) => {
      setIsLoading(true);
      console.log(`Delete call for video with id ${videoeId}`);
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: ContentCategoryRequest = new ContentCategoryRequest();
        reqBody.setContentid(Number(videoeId));
        reqBody.setContentcategory(7);
        grpc.unary(GamificationService.DeleteContentByIDAndCategory, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<Status>) => {
            const { status, message } = res;
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(`Delete video response ${message.toObject()}`);
                setIsLoading(false);
                _deleteFileFromGcp(videoUploadUrl);
                setStates({ showDeleteModal: false });
                setTimeout(() => props.onClickBack(), 2000);
                dispatch(
                  getAllYoutubeVideos(props.rowsPerPage, props.page, props.lang)
                );
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: " Deleted video successfully",
                });
              } else {
                setIsLoading(false);
                setStates({ showDeleteModal: false });
                setSnackBar({
                  open: true,
                  severity: "Error",
                  message: "Error in  deleting video",
                });
              }
            }
          },
        });
      } catch (err) {
        setIsLoading(false);
        console.log(`Error: ${err}`);
      }
    };

    const validateForm = () => {
      if (title.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video title can not be empty.",
        });
      } else if (subtitle.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video description can not be empty.",
        });
      } else if (Number(readingTime) <= 0) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video duration can not be less than or equal to zero.",
        });
      } else if (Number(readingTime) > 10000000) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video duration can not be more than 10000000",
        });
      } else if (Number(readingTime) - Math.floor(Number(readingTime)) !== 0) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video duration can not be in decimal.",
        });
      } else if (!videoUploadUrl) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video  can not be empty.",
        });
      } else {
        return true;
      }
    };

    const _addvideo = () => {
      if (validateForm()) {
        const videoData = {
          title: {
            ...localeObject,
            ...(language === 1 || language === 2
              ? { defaultcontent: title, en: title }
              : { [languageType[language - 1]]: title }),
          },
          subtitle: {
            ...localeObject,
            ...(language === 1 || language === 2
              ? { defaultcontent: subtitle, en: subtitle }
              : { [languageType[language - 1]]: subtitle }),
          },
          videoUrl: {
            ...localeObject,
            ...(language === 1 || language === 2
              ? { defaultcontent: videoUploadUrl, en: videoUploadUrl }
              : { [languageType[language - 1]]: videoUploadUrl }),
          },
          image: "",
          readingTime: readingTime,
          isWebView: false,
          webViewUrl: "",
          contentCategory: 7,
        };
        console.log("videoData", videoData);
        if (props.isEditing) {
          setIsAdding(true);
          updateVideo(videoData);
          console.log(videoData);
        } else {
          setIsAdding(true);
          addVideo(videoData);
        }
      }
    };
    const onVideoSelection = async (event: any) => {
      const file = event.target.files[0];
      if (file && file.type.startsWith("video/")) {
        setIsUploading({ ...isUploading, video: true });
        const reader: any = new FileReader();
        const data = new File(
          [event.target.files[0]],
          event.target.files[0].name.replaceAll(" ", "")
        );

        reader.readAsDataURL(data);
        reader.onloadend = function () {
          // setVideoFile({
          //   fileUrl: reader.result,
          //   file: data,
          // });
        };
        const uploadFile = toUploadFile(
          data,
          `${
            props.lang === 1
              ? "HeartsApp/videos"
              : `HeartsApp/videos/${languages[props.lang - 1].displayName
                  .toLowerCase()
                  .replaceAll(" ", "")}`
          }`
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setVideoUploadUrl(res.detail.response);
              setIsUploading({ ...isUploading, video: false });
            } else {
              setIsUploading({ ...isUploading, video: false });
            }
          }
        );
      } else {
        alert("Please select a video file.");
      }
    };
    const updateVideo = async (data: any) => {
      setIsLoading(true);
      try {
        console.log(`Request to updating video ${data.title.hi}`);
        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultcontent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);
        titleLocale.setPa(data.title.pa);

        const subtitleLocale: Locale = new Locale();
        subtitleLocale.setDefaultcontent(data.subtitle.defaultcontent);
        subtitleLocale.setEn(data.subtitle.en);
        subtitleLocale.setHi(data.subtitle.hi);
        subtitleLocale.setTe(data.subtitle.te);
        subtitleLocale.setGu(data.subtitle.gu);
        subtitleLocale.setTa(data.subtitle.ta);
        subtitleLocale.setKa(data.subtitle.ka);
        subtitleLocale.setMr(data.subtitle.mr);
        subtitleLocale.setIt(data.subtitle.it);
        subtitleLocale.setRu(data.subtitle.ru);
        subtitleLocale.setEs(data.subtitle.es);
        subtitleLocale.setFr(data.subtitle.fr);
        subtitleLocale.setPt(data.subtitle.pt);
        subtitleLocale.setDe(data.subtitle.de);
        subtitleLocale.setBn(data.subtitle.bn);
        subtitleLocale.setMl(data.subtitle.ml);
        subtitleLocale.setUk(data.subtitle.uk);
        subtitleLocale.setOd(data.subtitle.od);
        subtitleLocale.setEnUs(data.subtitle.enUs);
        subtitleLocale.setEnAu(data.subtitle.enAu);
        subtitleLocale.setPa(data.subtitle.pa);

        const videoUrlLocale: Locale = new Locale();
        videoUrlLocale.setDefaultcontent(data.videoUrl.defaultcontent);
        videoUrlLocale.setEn(data.videoUrl.en);
        videoUrlLocale.setHi(data.videoUrl.hi);
        videoUrlLocale.setTe(data.videoUrl.te);
        videoUrlLocale.setGu(data.videoUrl.gu);
        videoUrlLocale.setTa(data.videoUrl.ta);
        videoUrlLocale.setKa(data.videoUrl.ka);
        videoUrlLocale.setMr(data.videoUrl.mr);
        videoUrlLocale.setIt(data.videoUrl.it);
        videoUrlLocale.setRu(data.videoUrl.ru);
        videoUrlLocale.setEs(data.videoUrl.es);
        videoUrlLocale.setFr(data.videoUrl.fr);
        videoUrlLocale.setPt(data.videoUrl.pt);
        videoUrlLocale.setDe(data.videoUrl.de);
        videoUrlLocale.setBn(data.videoUrl.bn);
        videoUrlLocale.setMl(data.videoUrl.ml);
        videoUrlLocale.setUk(data.videoUrl.uk);
        videoUrlLocale.setOd(data.videoUrl.od);
        videoUrlLocale.setEnUs(data.videoUrl.enUs);
        videoUrlLocale.setEnAu(data.videoUrl.enAu);
        videoUrlLocale.setPa(data.videoUrl.pa);

        const reqBody = new ContentResponse();
        reqBody.setId(props.editItem.id);
        reqBody.setTitle(titleLocale);
        reqBody.setSubtitle(subtitleLocale);
        reqBody.setImage(data.image);
        reqBody.setReadingtime(data.readingTime);
        reqBody.setWebviewurl(data.webViewUrl);
        reqBody.setContentcategory(7);
        reqBody.setDocumentcategory(videoCategory);
        reqBody.setFeaturedcontent(2);
        reqBody.setVideocategory(5);
        reqBody.setVideourl(videoUrlLocale);
        console.log("Request for updating video", reqBody.toObject());
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GamificationService.UpdateContent, {
          request: reqBody,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ContentResponse>) => {
            const { status, message, statusMessage } = res;
            if (status === grpc.Code.OK && message) {
              console.log("update video response", message.toObject());
              setIsLoading(false);
              dispatch(
                getAllYoutubeVideos(props.rowsPerPage, props.page, props.lang)
              );
              setSnackBar({
                open: true,
                severity: "success",
                message: "video updated successfully",
              });
              setTimeout(() => props.onClickBack(), 2000);
            } else {
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: `Error in update video : ${statusMessage}`,
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.log(`Error in update video ${error}`);
        setSnackBar({
          open: true,
          severity: "error",
          message: `catch error: ${error}`,
        });
      }
    };

    const addVideo = async (data: any) => {
      setIsLoading(true);
      try {
        console.log(`Request to adding new video ${data}`);
        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultcontent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);
        titleLocale.setPa(data.title.pa);

        const subtitleLocale: Locale = new Locale();
        subtitleLocale.setDefaultcontent(data.subtitle.defaultcontent);
        subtitleLocale.setEn(data.subtitle.en);
        subtitleLocale.setHi(data.subtitle.hi);
        subtitleLocale.setTe(data.subtitle.te);
        subtitleLocale.setGu(data.subtitle.gu);
        subtitleLocale.setTa(data.subtitle.ta);
        subtitleLocale.setKa(data.subtitle.ka);
        subtitleLocale.setMr(data.subtitle.mr);
        subtitleLocale.setIt(data.subtitle.it);
        subtitleLocale.setRu(data.subtitle.ru);
        subtitleLocale.setEs(data.subtitle.es);
        subtitleLocale.setFr(data.subtitle.fr);
        subtitleLocale.setPt(data.subtitle.pt);
        subtitleLocale.setDe(data.subtitle.de);
        subtitleLocale.setBn(data.subtitle.bn);
        subtitleLocale.setMl(data.subtitle.ml);
        subtitleLocale.setUk(data.subtitle.uk);
        subtitleLocale.setOd(data.subtitle.od);
        subtitleLocale.setEnUs(data.subtitle.enUs);
        subtitleLocale.setEnAu(data.subtitle.enAu);
        subtitleLocale.setPa(data.subtitle.pa);

        const videoUrlLocale: Locale = new Locale();
        videoUrlLocale.setDefaultcontent(data.videoUrl.defaultcontent);
        videoUrlLocale.setEn(data.videoUrl.en);
        videoUrlLocale.setHi(data.videoUrl.hi);
        videoUrlLocale.setTe(data.videoUrl.te);
        videoUrlLocale.setGu(data.videoUrl.gu);
        videoUrlLocale.setTa(data.videoUrl.ta);
        videoUrlLocale.setKa(data.videoUrl.ka);
        videoUrlLocale.setMr(data.videoUrl.mr);
        videoUrlLocale.setIt(data.videoUrl.it);
        videoUrlLocale.setRu(data.videoUrl.ru);
        videoUrlLocale.setEs(data.videoUrl.es);
        videoUrlLocale.setFr(data.videoUrl.fr);
        videoUrlLocale.setPt(data.videoUrl.pt);
        videoUrlLocale.setDe(data.videoUrl.de);
        videoUrlLocale.setBn(data.videoUrl.bn);
        videoUrlLocale.setMl(data.videoUrl.ml);
        videoUrlLocale.setUk(data.videoUrl.uk);
        videoUrlLocale.setOd(data.videoUrl.od);
        videoUrlLocale.setEnUs(data.videoUrl.enUs);
        videoUrlLocale.setEnAu(data.videoUrl.enAu);
        videoUrlLocale.setPa(data.videoUrl.pa);

        const reqBody = new ContentRequest();
        reqBody.setTitle(titleLocale);
        reqBody.setSubtitle(subtitleLocale);
        reqBody.setImage(data.image);
        reqBody.setVideourl(videoUrlLocale);
        reqBody.setReadingtime(data.readingTime);
        reqBody.setIswebview(data.isWebView);
        reqBody.setWebviewurl(data.webViewUrl);
        reqBody.setContentcategory(7);
        reqBody.setDocumentcategory(videoCategory);
        reqBody.setFeaturedcontent(2);
        reqBody.setVideocategory(5);

        console.log("Request for adding video", reqBody.toObject());
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GamificationService.AddContent, {
          request: reqBody,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ContentResponse>) => {
            const { status, message, statusMessage } = res;
            if (status === grpc.Code.OK && message) {
              console.log("add video response", message.toObject());
              setIsLoading(false);
              dispatch(
                getAllYoutubeVideos(props.rowsPerPage, props.page, props.lang)
              );
              setSnackBar({
                open: true,
                severity: "success",
                message: "video added successfully",
              });
              setTimeout(() => props.onClickBack(), 2000);
            } else {
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: `Error in adding video : ${statusMessage}`,
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.log(`Error in adding video ${error}`);
        setSnackBar({
          open: true,
          severity: "error",
          message: error,
        });
      }
    };

    return (
      <Root ref={ref} className={classes.root}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit video" : "Add new videos"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      videos
                    </Typography>
                    <Tooltip
                      title={props.editItem.title}
                      disableHoverListener={props.editItem.title?.length < 20}
                      placement="bottom"
                    >
                      <Box>
                        <Typography
                          color="inherit"
                          style={{ cursor: "pointer" }}
                        >
                          {props.editItem.title?.slice(0, 20)}
                          {props.editItem.title?.length > 20 && "..."}
                        </Typography>
                      </Box>
                    </Tooltip>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => onClickDelete(id)}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="title"
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                type="text"
                name="subtitle"
                label="Description"
                variant="outlined"
                required
                value={subtitle}
                onChange={(e) => {
                  setSubtitle(e.target.value);
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="number"
                name="duration"
                label="Duration (min)"
                variant="outlined"
                value={readingTime}
                onChange={(e) => {
                  setReadingTime(e.target.value > "0" ? e.target.value : "0");
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="language-select">Category</InputLabel>
                <Select
                  defaultValue=""
                  labelId="video-category-select"
                  value={videoCategory}
                  label="Category"
                  onChange={(e) => {
                    setVideoCategory(e.target.value as number);
                  }}
                >
                  {doccumentCategoryList.map((catagory: any, i: any) => (
                    <MenuItem key={i} value={catagory.code}>
                      {catagory.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {videoUploadUrl ? (
                    <>
                      <VideoPlayers
                        src={videoUploadUrl}
                        onAudioClose={onVideoClose}
                      />
                    </>
                  ) : (
                    <>
                      {isUploading.video === true ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            className="pt-5"
                            alignItems="center"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.thumbNail}
                          onClick={() => {
                            handleVideoClick();
                          }}
                        >
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <VideoFileOutlined />
                          </Avatar>
                          <input
                            accept="video/*"
                            id="audioFile"
                            type="file"
                            hidden
                            onChange={onVideoSelection}
                            ref={hiddenVideoInput}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Upload Video
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            className={classes.formBtn}
            onClick={props.onClickBack}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={classes.formBtn}
            onClick={() => _addvideo()}
            color="primary"
            variant="contained"
            disabled={isAdding}
          >
            {props.isEditing ? "Update Video" : "Add Video"}
          </Button>
        </Box>

        <DialogBox
          open={states.showDeleteModal || false}
          onClose={handleDeleteClose}
          onCancel={handleDeleteClose}
          onConfirm={deleteVideo}
          type={"delete"}
          title={`Sure !! Want to delete this video ?`}
        />

        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
