import React, { ReactElement, useState } from "react";
import { Grid, Grow } from "@mui/material";
import { LostAndFound } from "components/bhandaraEvents/LostAndFount";

export default function LostAndFoundScreen(): ReactElement {
  const [currentScreen, setCurrentScreen] = useState(0);
  return (
    <Grid
      sx={{
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
        margin: {
          lg: "20px 80px 0px ",
          md: "20px 25px 0px ",
          sm: "20px 11px 0px",
        },
      }}
    >
      <Grow
        in={currentScreen === 0}
        mountOnEnter
        unmountOnExit
        style={{ transformOrigin: "0 0 0" }}
        {...(currentScreen === 0 ? { timeout: 1000 } : {})}
      >
        <LostAndFound />
      </Grow>
    </Grid>
  );
}
