import React, { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";

// import carousels from "./dummydata";
import DialogBox from "components/dialogBox/dialog";
import GoalEditor from "./GoalEditor";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { updateLayout } from "redux/actions/layoutManagerActions";
import { grpc } from "@improbable-eng/grpc-web";
import { CarouselDetails } from "api/models/heartintune-profile-service/heartintune-profile_pb";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import { HEARTINTUNE_PROFILE_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
interface CarouselListProps {
  data: string[];
  setSnackBar: any;
}

export default function CarouselList(props: CarouselListProps): ReactElement {
  const dispatch = useDispatch();
  const [showGoalEditor, setShowGoalEditor] = useState<boolean>(false);
  const [carouselsData, setCarouselsData] = useState(props.data);
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    setCarouselsData(props.data);
  }, [props]);

  useEffect(() => {
    dispatch(updateLayout(carouselsData));
  }, [carouselsData, dispatch]);

  function handleDragEnd(result: any) {
    if (!result.destination) return;
    const items = Array.from(carouselsData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCarouselsData(items);
  }

  const publishLayout = async () => {
    console.log(carouselsData);
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: CarouselDetails = new CarouselDetails();
    reqBody.setCarouseljson(JSON.stringify(carouselsData));
    grpc.unary(HeartIntuneProfileService.SaveCarousel, {
      request: reqBody,
      host: HEARTINTUNE_PROFILE_SERVICE_HOST,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          console.log(res.status);
          setShowModal(false);
          props.setSnackBar({
            open: true,
            severity: "success",
            message: "Layout published sucessfully",
          });
        } else {
          setShowModal(false);
          props.setSnackBar({
            open: true,
            severity: "success",
            message: "Could not publish layout",
          });
        }
      },
    });
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const onClickPublish = () => {
    setShowModal(true);
  };
  return (
    <Box mt={2} sx={{ height: "100%" }}>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box
          sx={{
            width: "max-content",
            p: 1,
            mb: 2,
            fontSize: "1.1rem",
          }}
        >
          Carousel Ordering
        </Box>
        <Button color="secondary" variant="contained" onClick={onClickPublish}>
          Publish
        </Button>
      </Stack>
      <Divider />
      <Box sx={{ height: "88%", overflow: "scroll" }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="carousels">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {carouselsData.map((item: any, idx: any) => (
                  <Box key={idx}>
                    <Draggable key={item.id} draggableId={item.id} index={idx}>
                      {(provided) => (
                        <ListItem
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          sx={{
                            padding: 2,
                            background: "#ECE4F6",
                            pt: 1.5,
                          }}
                          divider
                          alignItems="center"
                          secondaryAction={
                            <IconButton
                              color="primary"
                              disabled={!item.editable}
                              size="small"
                              onClick={() => setShowGoalEditor(true)}
                              edge="end"
                            >
                              <ModeEditOutlinedIcon
                                fontSize="inherit"
                                sx={{ fontWeight: "bolder" }}
                              />
                            </IconButton>
                          }
                        >
                          <span {...provided.dragHandleProps}>
                            <DragHandleRoundedIcon
                              fontSize="small"
                              color="primary"
                            />
                          </span>

                          <Typography
                            variant="body2"
                            sx={{ pl: 2, pr: 2 }}
                            color="primary"
                          >
                            {item.title}
                          </Typography>
                        </ListItem>
                      )}
                    </Draggable>
                    {/* <Divider color="secondary" light={true} /> */}
                  </Box>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <DialogBox
        open={showModal}
        onClose={handleModalClose}
        onCancel={handleModalClose}
        onConfirm={publishLayout}
        type={"cancel"}
        title={`Are you sure that you want to publish the layout?`}
      />
      <DialogBox
        open={showGoalEditor}
        onClose={() => setShowGoalEditor(false)}
        type="custom"
        children={<GoalEditor />}
      ></DialogBox>
    </Box>
  );
}
