import { grpc } from "@improbable-eng/grpc-web";
import {
  RequestPageable,
  TagsList,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { firebaseAuth } from "config/firebase";
import {
  GAMIFICATION_SERVICE_HOST,
  GUIDED_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { setTagsList, tagsListIsLoading } from "redux/reducers/tagReducer";
import { AppThunk } from "redux/store/store";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { GuidedMeditationService } from "api/models/guided-meditation-service/guided-meditation_pb_service";

export const getAllTags =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(tagsListIsLoading(true));
      const reqBody: RequestPageable = new RequestPageable();
      reqBody.setPagenumber(pageNumber);
      reqBody.setPagesize(pageSize);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.GetAllContentTags, {
        request: reqBody,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<TagsList>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log(message.toObject());
            dispatch(setTagsList(message.toObject()));
            dispatch(tagsListIsLoading(false));
          } else {
            console.log("Error in fetching tags ", statusMessage);
            dispatch(tagsListIsLoading(false));
          }
        },
      });
    } catch (err) {
      console.log("Error in fetching all content tags: ", err);
    }
  };

export const getAllMeditationTags =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(tagsListIsLoading(true));
      const reqBody: RequestPageable = new RequestPageable();
      reqBody.setPagenumber(pageNumber);
      reqBody.setPagesize(pageSize);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GuidedMeditationService.GetAllMeditationTags, {
        request: reqBody,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<TagsList>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log("Meditation Tags: ", message.toObject());
            dispatch(setTagsList(message.toObject()));
            dispatch(tagsListIsLoading(false));
          } else {
            console.log("Error in fetching tags ", statusMessage);
            dispatch(tagsListIsLoading(false));
          }
        },
      });
    } catch (err) {
      console.log("Error in fetching all content tags: ", err);
    }
  };
