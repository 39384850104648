import {
  Avatar,
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Alert } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import style from "./index.module.scss";
import {
  AddNewGoalDetailsRequest,
  GoalDetails,
} from "api/models/gamification-service/Goal_pb";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { firebaseAuth } from "config/firebase";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { grpc } from "@improbable-eng/grpc-web";
import {
  DeleteGoalByIdRequest,
  DeleteGoalDetailsByIdResponse,
  ResponseGoalDetails,
} from "api/models/gamification-service/gamification_pb";
import { getAllGoalsDetails } from "redux/actions/goalsAction";
import DialogBox from "components/dialogBox/dialog";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
const PREFIX = "GoalsForm";

const classes = {
  toggle: `${PREFIX}-toggle`,
  formBtn: `${PREFIX}-formBtn`,
  backdrop: `${PREFIX}-backdrop`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface GoalProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21;
}
interface deleteState {
  showDeleteModal?: boolean;
  deleteItemId?: number | null;
}

const meditationType = [
  "UNKNOWN_MEDITATION_TYPE",
  "MEDITATE_WITH_PURPOSE",
  "MEDITATE_WITH_GUIDANCE",
  "MEDITATE_WITH_PRECEPTOR",
  "MEDITATE_ANY_RECORDED_CONTEN",
];
// const Levels = ["EASY", "MODERATE", "DIFFICULT"];

export const GoalsForm = React.forwardRef<HTMLDivElement, GoalProps>(
  (props, ref) => {
    const initialDeleteState = {
      showDeleteModal: false,
      deleteItemId: 0,
    };

    const dispatch = useDispatch();

    const languages = useSelector((state: RootState) => state.app.languages);

    const badges = useSelector(
      (state: RootState) => state.dashboard.badgesList
    );

    const [goalId, setGoalId] = useState<any>("");
    const [language, setLanguage] = useState<any>(1);
    const [title, setTitle] = useState<any>("");
    const [type, setType] = useState<number | string>(1);
    const [level, setlevel] = useState("");
    const [days, setDays] = useState<number | string>(0);
    const [durationLimit, setDurationLimit] = useState<number | string>(0);
    const [badgeId, setBadgeId] = useState<any>(1);
    const [selectedBadge, setselectedBadge] = useState<any>(null);
    const [badgeCount, setBadgeCount] = useState<number | string>(1);
    const [expiryDays, setExpiryDays] = useState<number | string>(1);

    const [editGoal, setEditGoal] = useState<boolean>(false);
    const [deleteGoal, setdeleteGoal] =
      useState<deleteState>(initialDeleteState);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [expand, setexpand] = useState<boolean>(false);

    const [isAdding, setIsAdding] = useState(false);

    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const onClickEdit = useCallback(
      (item: any) => {
        console.log("Edit goal details: ", item);
        setEditGoal(true);
        setLanguage(props.lang);
        setTitle(item.goal.title);
        setlevel(item.goal.subtitle);
        setGoalId(item.goal.id);
        setDays(item.goal.days);
        setDurationLimit(item.goal.durationlimitindays);
        setBadgeCount(item.goal.badgecount);
        setBadgeId(item.goal.associatedbadgeid);
        setExpiryDays(item.goal.expirydates);
        setType(item.goal.typeofmeditationassociated);
      },
      [language]
    );

    useEffect(() => {
      console.log("Props: ", props);

      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
    }, [props.isEditing, props.editItem, onClickEdit]);

    const validateForm = () => {
      if (Number(durationLimit) < Number(days)) {
        alert("durationLimit should be equal or more than days");
      } else {
        return true;
      }
    };

    const addGoal = () => {
      if (validateForm()) {
        const addGoalData = {
          days: days,
          durationLimitInDays: durationLimit,
          typeOfMeditationAssociated: type,
          associatedBadgeID: Number(badgeId),
          title: {
            defaultContent: title,
            en: title,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
          subTitle: {
            defaultContent: level,
            en: level,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
          badgeCount: badgeCount,
          expiryDays: expiryDays,
        };
        _addGoals(addGoalData);
      }
    };

    const decideLocale = () => {
      // Create default description object with empty strings
      const defaultDescription = languages.reduce((acc, lang) => {
        if (lang.name === 'de') return acc;
        return {
          ...acc,
          [lang.name as string]: '',
        };
      }, {});

      // Define language code mapping dynamically from appReducer languages
      const languageMap: { [key: number]: keyof typeof defaultDescription } = 
        languages.reduce((acc, lang) => ({
          ...acc,
          [lang.code]: lang.name as keyof typeof defaultDescription
        }), {});

      // Get the target language code
      const targetLang = languageMap[language];
      
      if (!targetLang) {
        console.warn(`Unsupported language code: ${language}`);
        return { description: defaultDescription };
      }

      // Set the description for the target language
      return {
        reqTitle: {
          ...defaultDescription,
          [targetLang]: title,
          defaultContent: language === 1 
            ? title 
            : props.editItem.goal.title.defaultcontent
        },
        reqLevel: {
          ...defaultDescription,
          [targetLang]: level,
          defaultContent: language === 1 
            ? level 
            : props.editItem.goal.subTitle.defaultcontent
        },
      };
    };

    const updateGoal = () => {
      if (validateForm()) {
        setIsAdding(true);
        const data = {
          id: goalId,
          days: Number(days),
          durationLimitInDays: Number(durationLimit),
          typeOfMeditationAssociated: type,
          associatedBadgeID: Number(badgeId),
          title: decideLocale()?.reqTitle,
          subTitle: decideLocale()?.reqLevel,
          badgeCount: badgeCount,
          expiryDays: expiryDays,
        };
        console.log("Update Details data: ", data);
        _updateGoals(data);
      }
    };
    const onClickDelete = (goalId: number) => {
      setdeleteGoal({ showDeleteModal: true, deleteItemId: goalId });
    };
    const handleDeleteClose = () => {
      setdeleteGoal({ showDeleteModal: false });
    };
    const deleteGoals = () => {
      setdeleteGoal({ showDeleteModal: false });
      let data = {
        ID: deleteGoal.deleteItemId,
      };
      _deleteGoal(data);
    };

    const _addGoals = async (data: any) => {
      setIsAdding(true);
      setIsLoading(true);
      try {
        console.log("request data to add goal details", data);
        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultContent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);
        titleLocale.setPa(data.title.pa);

        const subTitleLocale: Locale = new Locale();
        subTitleLocale.setDefaultcontent(data.subTitle.defaultContent);
        subTitleLocale.setEn(data.subTitle.en);
        subTitleLocale.setHi(data.subTitle.hi);
        subTitleLocale.setTe(data.subTitle.te);
        subTitleLocale.setGu(data.subTitle.gu);
        subTitleLocale.setTa(data.subTitle.ta);
        subTitleLocale.setKa(data.subTitle.ka);
        subTitleLocale.setMr(data.subTitle.mr);
        subTitleLocale.setIt(data.subTitle.it);
        subTitleLocale.setRu(data.subTitle.ru);
        subTitleLocale.setEs(data.subTitle.es);
        subTitleLocale.setFr(data.subTitle.fr);
        subTitleLocale.setPt(data.subTitle.pt);
        subTitleLocale.setDe(data.subTitle.de);
        subTitleLocale.setBn(data.subTitle.bn);
        subTitleLocale.setMl(data.subTitle.ml);
        subTitleLocale.setUk(data.subTitle.uk);
        subTitleLocale.setOd(data.subTitle.od);
        subTitleLocale.setEnUs(data.subTitle.enUs);
        subTitleLocale.setEnAu(data.subTitle.enAu);
        subTitleLocale.setPa(data.subTitle.pa);

        const payload = new AddNewGoalDetailsRequest();
        payload.setDays(data.days);
        payload.setDurationlimitindays(data.durationLimitInDays);
        payload.setTypeofmeditationassociated(data.typeOfMeditationAssociated);
        payload.setAssociatedbadgeid(data.associatedBadgeID);
        payload.setTitle(titleLocale);
        payload.setSubtitle(subTitleLocale);
        payload.setBadgecount(data.badgeCount);
        payload.setExpirydays(data.expiryDays);
        console.log("request payload for add goal", payload);

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GamificationService.AddNewGoalDetails, {
          request: payload,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseGoalDetails>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            console.log("grpc code", grpc.Code);

            if (status === grpc.Code.OK && message) {
              if (res.message) {
                console.log("add goal response", res.message.toObject());
                setIsLoading(false);
                dispatch(getAllGoalsDetails(props.lang));
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: "goal added successfully",
                });
                setTimeout(() => props.onClickBack(), 2000);
              }
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: `Error in adding goal : ${statusMessage}`,
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.error("Error in add goals", error);
        setSnackBar({
          open: true,
          severity: "error",
          message: { error },
        });
      }
    };

    const _updateGoals = async (data: any) => {
      setIsLoading(true);
      try {
        console.log("request data to update goal", data);
        const payload = new GoalDetails();
        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultContent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);
        titleLocale.setPa(data.title.pa);

        const subTitleLocale: Locale = new Locale();
        subTitleLocale.setDefaultcontent(data.subTitle.defaultContent);
        subTitleLocale.setEn(data.subTitle.en);
        subTitleLocale.setHi(data.subTitle.hi);
        subTitleLocale.setTe(data.subTitle.te);
        subTitleLocale.setGu(data.subTitle.gu);
        subTitleLocale.setTa(data.subTitle.ta);
        subTitleLocale.setKa(data.subTitle.ka);
        subTitleLocale.setMr(data.subTitle.mr);
        subTitleLocale.setIt(data.subTitle.it);
        subTitleLocale.setRu(data.subTitle.ru);
        subTitleLocale.setEs(data.subTitle.es);
        subTitleLocale.setFr(data.subTitle.fr);
        subTitleLocale.setPt(data.subTitle.pt);
        subTitleLocale.setDe(data.subTitle.de);
        subTitleLocale.setBn(data.subTitle.bn);
        subTitleLocale.setMl(data.subTitle.ml);
        subTitleLocale.setUk(data.subTitle.uk);
        subTitleLocale.setOd(data.subTitle.od);
        subTitleLocale.setEnUs(data.subTitle.enUs);
        subTitleLocale.setEnAu(data.subTitle.enAu);
        subTitleLocale.setPa(data.subTitle.pa);

        payload.setId(data.id);
        payload.setDays(data.days);
        payload.setDurationlimitindays(data.durationLimitInDays);
        payload.setTypeofmeditationassociated(data.typeOfMeditationAssociated);
        payload.setAssociatedbadgeid(data.associatedBadgeID);
        payload.setTitle(titleLocale);
        payload.setSubtitle(subTitleLocale);
        payload.setBadgecount(data.badgeCount);
        payload.setExpirydays(data.expiryDays);
        //console.log('request payload for add goal',payload);

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GamificationService.UpdateGoalDetails, {
          request: payload,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseGoalDetails>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            console.log("grpc code", grpc.Code);

            if (status === grpc.Code.OK && message) {
              if (message) {
                setIsLoading(false);
                console.log("Update goal details response", message.toObject());
                dispatch(getAllGoalsDetails(language));
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: "goal updated successfully",
                });
                setTimeout(() => props.onClickBack(), 2000);
              }
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: `Error in updating goal:${statusMessage}`,
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.error("Error in update goals", error);
        setSnackBar({
          open: true,
          severity: "error",
          message: { error },
        });
      }
    };
    const _deleteGoal = async (data: any) => {
      try {
        console.log("Delete goal request", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteGoalByIdRequest = new DeleteGoalByIdRequest();
        reqBody.setId(data.ID);
        setIsLoading(true);
        grpc.unary(GamificationService.DeleteGoalDetailsByID, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<DeleteGoalDetailsByIdResponse>) => {
            const { status, message, statusMessage } = res;
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log("delete goal details response", message.toObject());
                setIsLoading(false);
                dispatch(getAllGoalsDetails(language));
                setdeleteGoal({ showDeleteModal: false });
                setTimeout(() => props.onClickBack(), 2000);
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: " Deleted goal successfully",
                });
              }
            } else {
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: `error in  deleting Goal : ${statusMessage}`,
              });
              setdeleteGoal({ showDeleteModal: false });
            }
          },
        });
      } catch (err) {
        setIsLoading(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "error in  deleting Goal",
        });
        console.log(err);
      }
    };
    return (
      <Root ref={ref}>
        <Backdrop open={isLoading} className={classes.backdrop}>
          {/* <Typography>hello</Typography> */}
          <CircularProgress />
        </Backdrop>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className="p-2 align-items-center"
        >
          <Grid item>
            <Grid
              container
              className={props.isEditing ? "" : "align-items-center"}
            >
              <Grid item>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Goal" : "Create New Goal"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      Goals
                    </Typography>
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      //onClick={props.onClickCoaches}
                    >
                      {props.editItem.goal.title}
                    </Typography>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => onClickDelete(goalId)}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="title"
                  label="Profile Title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  InputProps={{
                    readOnly: !props.isEditing || editGoal ? false : true,
                  }}
                  className="mr-3"
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={days}
                onChange={(e) => {
                  setDays(e.target.value > "0" ? e.target.value : "");
                }}
                label="Days"
                name="Days"
                required
                variant="outlined"
                InputProps={{
                  readOnly: !props.isEditing || editGoal ? false : true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={durationLimit}
                onChange={(e) => {
                  console.log("duration limit", e.target.value);
                  setDurationLimit(e.target.value > "0" ? e.target.value : "");
                }}
                label="Duration Limit(in days)"
                name="durationLimitInDays"
                required
                variant="outlined"
                InputProps={{
                  readOnly: !props.isEditing || editGoal ? false : true,
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="subtitle"
                  label="Level"
                  variant="outlined"
                  value={level}
                  onChange={(e) => {
                    setlevel(e.target.value);
                  }}
                  InputProps={{
                    readOnly: !props.isEditing || editGoal ? false : true,
                  }}
                  className="mr-3"
                />
              </Grid>
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Level</InputLabel>
                <Select
                  labelId="level"
                  value={level}
                  required
                  onChange={(e: any) => {
                    setlevel(e.target.value);
                    console.log("selected val", e.target.value);
                  }}
                  label="Level"
                  inputProps={{
                    readOnly: !props.isEditing || editGoal ? false : true,
                  }}
                >
                  {Levels.map((item: any, index: any) => {
                    return (
                      <MenuItem key={`med${index}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid> */}

            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="medType">Meditation Type</InputLabel>
                <Select
                  labelId="medType"
                  value={type}
                  required
                  onChange={(value: any) => {
                    setType(value.target.value);
                    console.log("selected val", value.target.value);
                  }}
                  label="Meditation Type"
                  inputProps={{
                    readOnly: !props.isEditing || editGoal ? false : true,
                  }}
                >
                  {meditationType.map((item, index) => {
                    return (
                      <MenuItem key={`med${index}`} value={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={badgeCount}
                onChange={(e) => {
                  setBadgeCount(e.target.value > "0" ? e.target.value : "");
                }}
                label="Badge Count"
                name="badgeCount"
                required
                variant="outlined"
                InputProps={{
                  readOnly: !props.isEditing || editGoal ? false : true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={expiryDays}
                onChange={(e) => {
                  console.log("duration limit", e.target.value);
                  setExpiryDays(e.target.value > "0" ? e.target.value : "");
                }}
                label="Expiry Days"
                name="expiryDays"
                required
                variant="outlined"
                InputProps={{
                  readOnly: !props.isEditing || editGoal ? false : true,
                }}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Card variant="outlined">
                <Grid
                  container
                  className="pl-3 pr-2 py-3"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => {
                    props.isEditing && !editGoal
                      ? setexpand(false)
                      : setexpand(!expand);
                  }}
                >
                  <Grid item>
                    <Typography color="textSecondary">
                      Associated Badge{" "}
                    </Typography>
                    {selectedBadge ? (
                      <Grid container alignItems="center" className="mt-2">
                        <Grid className="mr-2">
                          <Avatar src={selectedBadge.icon} />
                        </Grid>
                        <Typography>{selectedBadge.name}</Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid item>
                    {expand ? (
                      <ArrowDropUpIcon color="action" />
                    ) : (
                      <ArrowDropDownIcon color="action" />
                    )}
                  </Grid>
                </Grid>
                <Divider />
                {expand ? (
                  <CardContent>
                    <Box
                      maxHeight={350}
                      style={{ overflowY: "auto" }}
                      className={style.scrollbar}
                    >
                      {badges.badgesList.badges.map((item, index) => (
                        <React.Fragment key={`a${index}`}>
                          <Grid container className="p-1" alignItems="center">
                            <Grid item xs={2} md={1}>
                              <Avatar src={item.icon} />
                            </Grid>
                            <Grid item xs={9} md={10}>
                              <Typography variant="body1">
                                {item.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Radio
                                checked={`a${badgeId}` === `a${item.id}`}
                                onChange={(e: any) => {
                                  setBadgeId(e.target.value);
                                  setexpand(false);
                                  setselectedBadge(item);
                                }}
                                value={item.id}
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                              />
                            </Grid>
                          </Grid>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </Box>
                  </CardContent>
                ) : null}
              </Card>
            </Grid>
          </Grid>
          <DialogBox
            open={deleteGoal.showDeleteModal || false}
            onClose={handleDeleteClose}
            onCancel={handleDeleteClose}
            onConfirm={deleteGoals}
            type={"delete"}
            title={`Sure !! Want to delete this Item ?`}
          />
        </CardContent>
        <Divider />
        <CardActions className=" p-2 d-flex justify-content-end">
          <Box display="flex" justifyContent="flex-end">
            {props.isEditing ? (
              <>
                <Button
                  onClick={props.onClickBack}
                  color="primary"
                  variant="contained"
                  disabled={!editGoal}
                  className="mr-2"
                >
                  cancel
                </Button>
                <Button
                  onClick={() => {
                    console.log(props);
                    updateGoal();
                  }}
                  color="primary"
                  variant="contained"
                  disabled={
                    !editGoal ||
                    title === "" ||
                    level === "" ||
                    durationLimit === 0 ||
                    days === 0 ||
                    isAdding
                  }
                >
                  save
                </Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  addGoal();
                }}
                color="primary"
                variant="contained"
                disabled={
                  title === "" ||
                  level === "" ||
                  durationLimit === 0 ||
                  days === 0 ||
                  isAdding
                }
              >
                Add Goal
              </Button>
            )}
          </Box>
        </CardActions>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
