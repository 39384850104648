import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  InputLabel,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState, ReactElement } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import {
  getAllPurposeFile,
  getAllMeditationFileDetailsV2,
} from "redux/actions/guidedMeditationAction";
import { ListSkeleton } from "components/listSkeleton";
import style from "./index.module.scss";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { CreateMeditationWithPurpose } from "./CreateMeditationWithPurpose";
import { UpdateMeditationWithPurpose } from "./updateMeditationWithPurpose";
import { UpdateMeditationWithGuidance } from "./updateMeditationWithGuidance";
import { CreateMeditationWithGuidance } from "./createMeditationWithGuidance";
import { MenuProps } from "styles/global.styles";

interface State {
  editing?: boolean;
  editItem?: any;
  meditationtype: number;
}
export default function MeditationWithPurposeScreen(): ReactElement {
  const dispatch = useDispatch();
  const meditationListState = useSelector(
    (state: RootState) => state.guidedMeditation.meditationList
  );
  const languages = useSelector((state: RootState) => state.app.languages);

  const initialState = {
    editing: false,
    editItem: null,
    meditationtype: 1,
  };

  const [currentScreen, setcurrentScreen] = useState(1);
  const [state, setState] = useState<State>(initialState);
  const [searchMeditation, setsearchMeditation] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);

  const [pageNumber, setpageNumber] = useState(0);
  const [pageSize, setpageSize] = useState(10);
  const [meditationType, setMeditationType] = useState<0 | 1 | 2 | 3 | 4>(1);
  const [language, setLanguage] = useState<any>(2);

  const meditationTypeList = ["PURPOSE", "GUIDANCE"];

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setpageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setpageSize(+event.target.value);
    setpageNumber(0);
  };

  useEffect(() => {
    dispatch(
      getAllMeditationFileDetailsV2(pageSize, pageNumber, meditationType)
    );
  }, [dispatch, pageSize, pageNumber, meditationType]);

  // useEffect(() => {}, [language]);
  const setLocalisedContent = (item) => {
    switch (language) {
      case 1:
        return {
          title: item.title.defaultcontent,
          image: item.thumbnailimgurl.defaultcontent,
          attribute: item.attribute.defaultcontent,
        };
      case 2:
        return {
          title: item.title.en,
          image: item.thumbnailimgurl.en,
          attribute: item.attribute.en,
        };
      case 3:
        return {
          title: item.title.hi,
          image: item.thumbnailimgurl.hi,
          attribute: item.attribute.hi,
        };
      case 4:
        return {
          title: item.title.te,
          image: item.thumbnailimgurl.te,
          attribute: item.attribute.te,
        };
      case 5:
        return {
          title: item.title.gu,
          image: item.thumbnailimgurl.gu,
          attribute: item.attribute.gu,
        };
      case 6:
        return {
          title: item.title.ta,
          image: item.thumbnailimgurl.ta,
          attribute: item.attribute.ta,
        };
      case 7:
        return {
          title: item.title.ka,
          image: item.thumbnailimgurl.ka,
          attribute: item.attribute.ka,
        };
      case 8:
        return {
          title: item.title.mr,
          image: item.thumbnailimgurl.mr,
          attribute: item.attribute.mr,
        };
      case 9:
        return {
          title: item.title.it,
          image: item.thumbnailimgurl.it,
          attribute: item.attribute.it,
        };
      case 10:
        return {
          title: item.title.ru,
          image: item.thumbnailimgurl.ru,
          attribute: item.attribute.ru,
        };
      case 11:
        return {
          title: item.title.es,
          image: item.thumbnailimgurl.es,
          attribute: item.attribute.es,
        };
      case 12:
        return {
          title: item.title.fr,
          image: item.thumbnailimgurl.fr,
          attribute: item.attribute.fr,
        };
      case 13:
        return {
          title: item.title.pt,
          image: item.thumbnailimgurl.pt,
          attribute: item.attribute.pt,
        };
      case 14:
        return {
          title: item.title.de,
          image: item.thumbnailimgurl.de,
          attribute: item.attribute.de,
        };
      case 15:
        return {
          title: item.title.bn,
          image: item.thumbnailimgurl.bn,
          attribute: item.attribute.bn,
        };
      case 16:
        return {
          title: item.title.ml,
          image: item.thumbnailimgurl.ml,
          attribute: item.attribute.ml,
        };
      case 17:
        return {
          title: item.title.uk,
          image: item.thumbnailimgurl.uk,
          attribute: item.attribute.uk,
        };
      case 18:
        return {
          title: item.title.od,
          image: item.thumbnailimgurl.od,
          attribute: item.attribute.od,
        };
      case 19:
        return {
          title: item.title.enUs,
          image: item.thumbnailimgurl.enUs,
          attribute: item.attribute.enUs,
        };
      case 20:
        return {
          title: item.title.enAu,
          image: item.thumbnailimgurl.enAu,
          attribute: item.attribute.enAu,
        };
        case 21:
          let newItem = {
            title: item.title?.pa,
            image: item.thumbnailimgurl?.pa,
            attribute: item.attribute?.pa,
          };
          return newItem;
    }
  };

  const onClickBack = () => {
    setcurrentScreen(1);
    dispatch(
      getAllMeditationFileDetailsV2(pageSize, pageNumber, meditationType)
    );
  };

  const renderMeditationList = (data: any) => {
    return (
      <>
        <Grid
          style={{ backgroundColor: "transparent" }}
          className="d-flex py-2 pl-3 align-items-center"
        >
          <Grid className="d-flex flex-grow-1">
            <TextField
              type="search"
              fullWidth
              variant="outlined"
              placeholder="Search by meditation title or duration"
              size="small"
              value={searchMeditation}
              onChange={(e) => {
                setsearchMeditation(e.target.value);
                const filteredData = data.filter(
                  (meditation: any) =>
                    meditation.title.defaultcontent
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    meditation.durationofmeditationclip
                      .toString()
                      .includes(e.target.value)
                );

                setsearchData(filteredData);
              }}
              className="mr-2"
            />
          </Grid>
          <Grid className="d-flex align-items-center">
            <IconButton
              onClick={() => {
                dispatch(
                  getAllMeditationFileDetailsV2(
                    pageSize,
                    pageNumber,
                    meditationType
                  )
                );
              }}
              size="large"
            >
              <Tooltip title="Refresh">
                <RefreshIcon />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
        {data.length ? (
          <>
            <TableContainer
              style={{ maxHeight: "400px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left">Id</TableCell>
                    <TableCell align="left"> Title</TableCell>
                    <TableCell align="center">Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchMeditation !== "" ? searchData : data).map(
                    (meditation: any, x: any) => {
                      if (setLocalisedContent(meditation)?.title)
                        return (
                          <React.Fragment key={x}>
                            <TableRow
                              sx={{ cursor: "pointer" }}
                              hover
                              onClick={() => {
                                setState({
                                  editing: true,
                                  editItem: meditation,
                                  meditationtype: meditationType,
                                });
                                dispatch(getAllPurposeFile(1));
                                dispatch(
                                  getAllMeditationFileDetailsV2(
                                    pageSize,
                                    pageNumber,
                                    meditationType
                                  )
                                );
                                if (meditationType === 1) {
                                  setcurrentScreen(3);
                                } else if (meditationType === 2) {
                                  setcurrentScreen(4);
                                }
                              }}
                            >
                              <TableCell align="center">
                                <Avatar
                                  alt={setLocalisedContent(meditation)?.title}
                                  src={setLocalisedContent(meditation)?.image}
                                />
                              </TableCell>
                              <TableCell align="left">{meditation.id}</TableCell>
                              <TableCell>
                                {setLocalisedContent(meditation)?.title}
                              </TableCell>
                              <TableCell align="center">
                                {meditation.durationofmeditationclip}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={meditationListState.purposeMeditationList.totalcount ?? 0}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                Not enough data
              </Typography>
            </Grid>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Card className="mt-3 mb-5" style={{ position: "relative" }}>
              <Grow
                in={currentScreen === 1}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 1 ? { timeout: 1000 } : {})}
              >
                <div>
                  <Grid container className="align-items-center p-3">
                    <Typography variant="h6">Meditations</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (meditationType === 1) {
                          setcurrentScreen(2);
                        } else if (meditationType === 2) {
                          setcurrentScreen(5);
                        }
                        dispatch(getAllPurposeFile(1));
                      }}
                      startIcon={<AddIcon />}
                      className="ml-3"
                    >
                      Create Meditations
                    </Button>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} sm={4} md={4} lg={3}>
                      <FormControl variant="outlined" className="m-3" fullWidth>
                        <InputLabel id="meditation-type-select">
                          Meditate with
                        </InputLabel>
                        {meditationType && (
                          <Select
                            defaultValue={
                              meditationTypeList[meditationType - 1]
                            }
                            labelId="meditation-type-select"
                            value={meditationTypeList[meditationType - 1]}
                            label="Meditate with"
                            onChange={(e) => {
                              setMeditationType(
                                e.target.value === meditationTypeList[0] ? 1 : 2
                              );
                              setsearchMeditation("");
                              setState({
                                ...state,
                                meditationtype: meditationType,
                              });
                            }}
                          >
                            {meditationTypeList.map((medType: any, i: any) => (
                              <MenuItem key={i} value={medType}>
                                {medType}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={3}>
                      <FormControl
                        variant="outlined"
                        className="m-3 pl-2"
                        fullWidth
                      >
                        <InputLabel id="language-select">Language</InputLabel>
                        {languages && (
                          <Select
                            defaultValue=""
                            labelId="language-select"
                            value={language}
                            label="Language"
                            onChange={(e) => {
                              setLanguage(e.target.value as number);
                              // setIsLangSelected(true);
                              console.log("language", language);
                            }}
                            MenuProps={MenuProps}
                          >
                            {languages.map((lang, i) => (
                              <MenuItem key={i} value={lang.code}>
                                {lang.displayName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardContent>
                    <Paper>
                      {meditationListState.purposeMeditationList.loading
                        ? ListSkeleton()
                        : renderMeditationList(
                            meditationListState.purposeMeditationList
                              .purposeList
                          )}
                    </Paper>
                  </CardContent>
                </div>
              </Grow>
              <Grow
                in={currentScreen === 2}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 2 ? { timeout: 1000 } : {})}
              >
                <CreateMeditationWithPurpose onClickBack={onClickBack} />
              </Grow>
              <Grow
                in={currentScreen === 3}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 3 ? { timeout: 1000 } : {})}
              >
                <UpdateMeditationWithPurpose
                  isEditing={state.editing}
                  editItem={state.editItem}
                  medType={state.meditationtype}
                  onClickBack={onClickBack}
                  pageSize={pageSize}
                  pageNumber={pageNumber}
                  language={language}
                />
              </Grow>
              <Grow
                in={currentScreen === 4}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 4 ? { timeout: 1000 } : {})}
              >
                <UpdateMeditationWithGuidance
                  isEditing={state.editing}
                  editItem={state.editItem}
                  medType={state.meditationtype}
                  onClickBack={onClickBack}
                  pageSize={pageSize}
                  pageNumber={pageNumber}
                  language={language}
                />
              </Grow>
              <Grow
                in={currentScreen === 5}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 5 ? { timeout: 1000 } : {})}
              >
                <CreateMeditationWithGuidance onClickBack={onClickBack} />
              </Grow>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
