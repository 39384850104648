import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  CardContent,
  FormControl,
  TextField,
  Autocomplete,
  Alert,
  Button,
  CardActions,
  Snackbar,
} from "@mui/material";
import moment, { duration } from "moment";
import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import momentz from "moment-timezone";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { SessionInfo } from "api/models/live-meditation-service/live-meditation_pb";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import { LIVE_MEDITATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { schedularUpdateStep } from "redux/reducers/liveMeditationReducer";
import { useDispatch } from "react-redux";

interface AssignLiveSessionProp {
  onClickBack: any;
  coachId: number;
  sessionId: number;
  setCoachId: any;
  setCurrentScreen: any;
}

export const AssignLiveSessionForm = React.forwardRef<
  HTMLDivElement,
  AssignLiveSessionProp
>(({ onClickBack, coachId, sessionId, setCoachId, setCurrentScreen }, ref) => {
  const dispatch = useDispatch();

  const [scheduleDate, setscheduleDate] = useState<any>(new Date());
  const [scheduleDateTime, setscheduleDateTime] = useState<any>(new Date());
  const [scheduleTimeZone, setscheduleTimeZone] = useState<any>(new Date());
  const [timeZone, settimeZone] = useState<any>(moment.tz.guess());
  const [inputTimeZoneValue, setinputTimeZoneValue] = useState("");
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const validateForm = () => {
    if (new Date(scheduleDateTime).getTime() <= Date.now()) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Live session can't be scheduled for past time",
      });
    } else {
      return true;
    }
  };

  const scheduleSession = async (data: any) => {
    try {
      console.log("updated session data", data);
      var updatemeditaionReq = new SessionInfo();

      const currentGoogleTimeStamp: Timestamp = new Timestamp();
      currentGoogleTimeStamp.fromDate(new Date(data.scheduledTime));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      updatemeditaionReq.setId(data.id);
      updatemeditaionReq.setScheduledtime(currentGoogleTimeStamp);
      updatemeditaionReq.setAssociatedcoachid(data.associatedCoachId);

      grpc.unary(LiveMeditationService.UpdateScheduleSession, {
        request: updatemeditaionReq,
        host: LIVE_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<SessionInfo>) => {
          const { status, message, statusMessage } = res;
          console.log(res);

          if (status === grpc.Code.OK && message) {
            console.log("all ok: ", message.toObject());
            setSnackBar({
              open: true,
              severity: "success",
              message: "session assigned successfully",
            });

            setTimeout(() => {
              onClickBack();
            }, 500);
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: statusMessage.substring(statusMessage.indexOf("Live"))
                ? statusMessage.substring(statusMessage.indexOf("Live"))
                : `error in scheduling live session : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "error in  update session ",
      });
      console.error("something went wrong in update session", error);
    }
  };

  const _createSession = () => {
    if (validateForm()) {
      const data = {
        id: sessionId,
        scheduledTime: moment(scheduleDateTime).tz(moment.tz.guess()).format(),
        duration: duration,
        registeredUserCount: 0,
        liveParticipantsCount: 0,
        associatedCoachId: coachId,
      };

      scheduleSession(data);
    }
  };

  return (
    <div ref={ref}>
      <Grid
        container
        spacing={1}
        style={{
          padding: " 8px",
          alignItems: "center",
        }}
      >
        <Grid item>
          <IconButton
            onClick={() => {
              setCoachId(0);
              setCurrentScreen(1);
              dispatch(schedularUpdateStep(0));
            }}
            size="large"
          >
            <KeyboardBackspaceIcon color="action" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h6">Assign session</Typography>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={3} className="mt-2">
          <Grid item xs={12} sm={3} md={4} lg={4}>
            <FormControl variant="outlined" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  inputFormat="dd/MM/yyyy hh:mm a"
                  label="Schedule Date And Time"
                  renderInput={(params) => <TextField {...params} />}
                  minDateTime={new Date(Date.now())}
                  disablePast={true}
                  value={scheduleDate}
                  onChange={(value: any) => {
                    console.log("date value from schedule session", value);
                    const time = moment(value)
                      .tz(`${timeZone}`)
                      .format("DD/MM/yyyy hh:mm a ");
                    console.log(`on date change time for ${timeZone}`, time);
                    const newTime = moment(value).tz(`${timeZone}`).format();
                    console.log(
                      `on date change new time for ${timeZone}`,
                      newTime
                    );
                    setscheduleDateTime(newTime);
                    setscheduleDate(value);
                    setscheduleTimeZone(value);
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4}>
            <Grid>
              <Autocomplete
                disablePortal
                disableClearable
                fullWidth
                value={timeZone}
                onChange={(event, newValue) => {
                  settimeZone(newValue);
                  const time = moment(scheduleTimeZone)
                    .tz(`${newValue}`)
                    .format("DD/MM/yyyy hh:mm a");
                  setscheduleDate(time);
                  const newTime = moment(scheduleTimeZone)
                    .tz(`${newValue}`)
                    .format();
                  setscheduleDateTime(newTime);
                }}
                inputValue={inputTimeZoneValue}
                onInputChange={(event, newInputValue) => {
                  setinputTimeZoneValue(newInputValue);
                }}
                id="combo-box-demo"
                options={momentz.tz.names()}
                renderInput={(params) => (
                  <TextField {...params} label="Timezone" variant="outlined" />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className=" py-3 d-flex justify-content-end">
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => _createSession()}
        >
          Assign Session
        </Button>
      </CardActions>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </div>
  );
});
