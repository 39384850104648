import { createSlice } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

interface LayoutManagerDefaultState {
  carousels: {
    loading: boolean;
    carouselList: any;
  };
}

const initialState: LayoutManagerDefaultState = {
  carousels: {
    loading: false,
    carouselList: [],
  },
};

export const layoutSlice = createSlice({
  name: "layoutManager",
  initialState,
  reducers: {
    updateCarousels: (state, action) => {
      state.carousels.carouselList = action.payload;
    },
  },
});

export const { updateCarousels } = layoutSlice.actions;

export default combineReducers({
  layoutManager: layoutSlice.reducer,
});
