/**
 * @author : Godwin Vinny Carole Kati
 * @created : 2021-04-28 02:09:09
 * @organisation : Codeprism Technologies Pvt Ltd
 **/
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  List,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { ChangeEvent, ReactElement} from "react";
import { useFileUploadService } from "services/FileUplaodService";
import UploadListItem from "./UploadListItem";
import { useUploadManagerStyles } from "./UploadManager.style";
import style from './uploadmanager.module.scss'

export default function UploadManager(): ReactElement {
  const classes = useUploadManagerStyles();
  const { uploadFilesList } = useFileUploadService();
  const { clearFilesInUploadManager } = useFileUploadService();
  //const [closeUpload, setcloseUpload] = useState(false)
  const [expanded, setExpanded] = React.useState<string | boolean>( 'panel1');

  const handleChange =
    (panel: string) => (event:ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return <>
  {!uploadFilesList.length  ? '': 
  <Container className={style.managerContainer}>
    <Accordion className={classes.accordian}
      elevation={5}
       expanded={expanded === 'panel1' }
       onChange={handleChange('panel1')}
       >
         
      <AccordionSummary
      
        className={classes.accordianSummary}
        expandIcon={<ExpandMoreIcon style={{ color: "white",paddingTop:0 }} />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
       
        <Typography className={classes.heading} >
          Uploads
        </Typography>

      </AccordionSummary>
      <Divider />
      <AccordionDetails className={classes.details}>
        <List>
          {uploadFilesList.reverse().map((uploadFile) => (
            <UploadListItem key={uploadFile.token} uploadFile={uploadFile} />
          ))}
        </List>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        {/* <Button
          size="small"
          variant="outlined"
          color="default"
          onClick={clearFilesInUploadManager}
        >
          Clear Uploads
        </Button> */}
        <Button
          size="small"
          variant="outlined"
          // onClick={()=>setcloseUpload(true)}
          onClick={clearFilesInUploadManager}>
          Close
        </Button>
      </AccordionActions>
    </Accordion>
  </Container>}
  </>;
}
