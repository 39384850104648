import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  ContentCategoryRequest,
  ContentFilterRequest,
  ContentFilterResponse,
  GetAllContentFilterResponse,
  ID,
  ListOfContentResponse,
  RequestPageable,
  Status,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { AppThunk } from "redux/store/store";
import {
  setContentListLoading,
  contentList,
  filterList,
  setFilterListLoading,
} from "redux/reducers/contentReducer";
import { Locale } from "api/models/gamification-service/common/common_pb";

interface addFilterType {
  contentFilterName: any;
  setSnackBar: any;
  getAllFilterData: pageableType;
}

interface updateFilterType {
  id: number;
  contentFilterName: any;
  setSnackBar: any;
  getAllFilterData: pageableType;
}

interface deleteFilterType {
  id: number;
  setSnackBar: any;
  getAllFilterData: pageableType;
}

export interface pageableType {
  pageSize: number;
  pageNumber: number;
}

export const getContentByCategory =
  (
    lang:
      | 0
      | 1
      | 2
      | 3
      | 4
      | 5
      | 6
      | 7
      | 8
      | 9
      | 10
      | 11
      | 12
      | 13
      | 14
      | 15
      | 16
      | 17
      | 18
      | 19
      | 20
      | 21,
    category: 1 | 2 | 3 | 4,
    page: number,
    rowsPerPage: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setContentListLoading(true));

      const pagination: RequestPageable = new RequestPageable();

      pagination.setPagenumber(page);
      pagination.setPagesize(rowsPerPage);

      const reqBody: ContentCategoryRequest = new ContentCategoryRequest();

      reqBody.setSelectedlanguage(lang);
      reqBody.setContentcategory(category);
      reqBody.setPageable(pagination);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.GetContentByCategory, {
        request: reqBody,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ListOfContentResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log(message.toObject());
            dispatch(contentList(message.toObject()));
            dispatch(setContentListLoading(false));
          } else {
            console.log(`Error in fetching content ${statusMessage}`);
            dispatch(setContentListLoading(false));
          }
        },
      });
    } catch (error) {
      console.log(`Error in get content by category ${error}`);
    }
  };

export const getAllFilters =
  (data: pageableType): AppThunk =>
  async (dispatch) => {
    dispatch(setFilterListLoading(true));

    const jwtToken = await firebaseAuth.currentUser?.getIdToken();

    const reqBody = new RequestPageable();
    reqBody.setPagesize(data.pageSize);
    reqBody.setPagenumber(data.pageNumber);

    grpc.unary(GamificationService.GetAllContentFilterForAdmin, {
      host: GAMIFICATION_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<GetAllContentFilterResponse>) => {
        const { status, message, statusMessage } = res;
        console.log(message?.toObject());
        if (status === grpc.Code.OK && message) {
          dispatch(setFilterListLoading(false));
          dispatch(filterList(message.toObject()));
        } else {
          console.log(`Error in fetching filters ${statusMessage}`);
          dispatch(setFilterListLoading(false));
        }
      },
    });
  };

export const addFilter =
  (data: addFilterType): AppThunk =>
  async (dispatch) => {
    try {
      const filterNameLocale: Locale = new Locale();
      filterNameLocale.setDefaultcontent(data.contentFilterName.defaultcontent);
      filterNameLocale.setEn(data.contentFilterName.en);
      filterNameLocale.setHi(data.contentFilterName.hi);
      filterNameLocale.setTe(data.contentFilterName.te);
      filterNameLocale.setGu(data.contentFilterName.gu);
      filterNameLocale.setTa(data.contentFilterName.ta);
      filterNameLocale.setKa(data.contentFilterName.ka);
      filterNameLocale.setMr(data.contentFilterName.mr);
      filterNameLocale.setIt(data.contentFilterName.it);
      filterNameLocale.setRu(data.contentFilterName.ru);
      filterNameLocale.setEs(data.contentFilterName.es);
      filterNameLocale.setFr(data.contentFilterName.fr);
      filterNameLocale.setPt(data.contentFilterName.pt);
      filterNameLocale.setDe(data.contentFilterName.de);
      filterNameLocale.setBn(data.contentFilterName.bn);
      filterNameLocale.setMl(data.contentFilterName.ml);
      filterNameLocale.setUk(data.contentFilterName.uk);
      filterNameLocale.setOd(data.contentFilterName.od);
      filterNameLocale.setEnUs(data.contentFilterName.enUs);
      filterNameLocale.setEnAu(data.contentFilterName.enAu);
      filterNameLocale.setPa(data.contentFilterName.pa);

      const payload = new ContentFilterRequest();
      payload.setContentfiltername(filterNameLocale);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.AddContentFilter, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ContentFilterResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("add filter response", res.message.toObject());
              dispatch(getAllFilters(data.getAllFilterData));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Filter added successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in adding filter : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in add filter", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const updateFilter =
  (data: updateFilterType): AppThunk =>
  async (dispatch) => {
    try {
      const filterNameLocale: Locale = new Locale();
      filterNameLocale.setDefaultcontent(data.contentFilterName.defaultcontent);
      filterNameLocale.setEn(data.contentFilterName.en);
      filterNameLocale.setHi(data.contentFilterName.hi);
      filterNameLocale.setTe(data.contentFilterName.te);
      filterNameLocale.setGu(data.contentFilterName.gu);
      filterNameLocale.setTa(data.contentFilterName.ta);
      filterNameLocale.setKa(data.contentFilterName.ka);
      filterNameLocale.setMr(data.contentFilterName.mr);
      filterNameLocale.setIt(data.contentFilterName.it);
      filterNameLocale.setRu(data.contentFilterName.ru);
      filterNameLocale.setEs(data.contentFilterName.es);
      filterNameLocale.setFr(data.contentFilterName.fr);
      filterNameLocale.setPt(data.contentFilterName.pt);
      filterNameLocale.setDe(data.contentFilterName.de);
      filterNameLocale.setBn(data.contentFilterName.bn);
      filterNameLocale.setMl(data.contentFilterName.ml);
      filterNameLocale.setUk(data.contentFilterName.uk);
      filterNameLocale.setOd(data.contentFilterName.od);
      filterNameLocale.setEnUs(data.contentFilterName.enUs);
      filterNameLocale.setEnAu(data.contentFilterName.enAu);
      filterNameLocale.setPa(data.contentFilterName.pa);
      const payload = new ContentFilterResponse();
      payload.setId(data.id);
      payload.setContentfiltername(filterNameLocale);
      console.log("filter payload ", payload.toObject());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.UpdateContentFilter, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ContentFilterResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("update filter response", res.message.toObject());
              dispatch(getAllFilters(data.getAllFilterData));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Filter updated successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in updating filter : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in update filter", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const deleteFilter =
  (data: deleteFilterType): AppThunk =>
  async (dispatch) => {
    try {
      const payload = new ID();
      payload.setId(data.id);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.DeleteContentFilter, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("delte filter response", res.message.toObject());
              dispatch(getAllFilters(data.getAllFilterData));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "filter deleted successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in deleting filter : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in delete filter", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };
