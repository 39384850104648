import {
  Button,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import style from "./index.module.scss";
import { ScheduleAnnouncement } from "./ScheduleAnnouncement";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { getAllAnnouncements } from "redux/actions/bhandaraActions";
import { ListSkeleton } from "components/listSkeleton";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";

const TableWrapper = styled("div")({
  margin: "16px",
  height: "100%",
  overflow: "auto",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "5px",
});

interface State {
  editing?: boolean;
  editItem?: any;
}

export const UpcomingAnnouncements = React.forwardRef<HTMLDivElement, any>(
  (props, ref): ReactElement => {
    const initialState = {
      editing: false,
      editItem: null,
    };

    // const regX = new RegExp(
    //   /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
    // );

    const dispatch = useDispatch();
    const announcements = useSelector(
      (state: RootState) => state.bhandara.announcements.announcementsData
    );
    const [currentScreen, setCurrentScreen] = useState(0);
    const [states, setStates] = useState<State>(initialState);
    const [searchAnnouncement, setSearchAnnouncement] = useState("");
    const [searchData, setSearchData] = useState<any[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(0);

    useEffect(() => {
      dispatch(getAllAnnouncements(pageSize, pageNumber));
    }, [dispatch, pageNumber, pageSize]);

    const onClickBack = () => {
      setCurrentScreen(0);
      setStates({
        editing: false,
        editItem: null,
      });
    };

    function renderAnnouncements() {
      return (
        <div>
          <TableContainer
            style={{ maxHeight: "400px" }}
            className={style.scrollbar}
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell align="left">
                    <Typography>Announcement Text</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>Date & Time</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchAnnouncement !== ""
                  ? searchData
                  : announcements.announcementsList
                ).map((item: any, x: any) => (
                  <React.Fragment key={x}>
                    <TableRow
                      sx={{ cursor: "pointer", height: "50px" }}
                      hover
                      onClick={() => {
                        setStates({
                          editing: true,
                          editItem: item,
                        });
                        setCurrentScreen(1);
                        setSearchAnnouncement("")
                      }}
                    >
                      <TableCell align="left">
                        {item.announcementtext}
                      </TableCell>
                      <TableCell align="center">
                        {moment(item.scheduledat.seconds * 1000).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Grid container justifyContent="flex-end">
            <Grid
              item
              style={{ width: "450px", overflowX: "auto" }}
              className={style.scrollbar}
            >
              <Grid
                container
                className="d-flex justify-content-end align-items-center"
              >
                <Grid className="mr-2">
                  <Typography>Rows per page:</Typography>
                </Grid>
                <Grid>
                  <FormControl variant="standard">
                    <Select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                        dispatch(
                          getAllAnnouncements(
                            Number(e.target.value),
                            pageNumber
                          )
                        );
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid className="mx-2">
                  {(searchAnnouncement !== ""
                    ? searchData
                    : announcements.announcementsList
                  ).length ? (
                    <Typography>
                      {`1-${
                        (searchAnnouncement !== ""
                          ? searchData
                          : announcements.announcementsList
                        ).length
                      }`}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid className="d-flex align-items-center">
                  <IconButton
                    onClick={() => {
                      if (pageNumber > 0) {
                        const previousPage = pageNumber - 1;
                        setPageNumber(pageNumber - 1);
                        //setpageSize(10);
                        dispatch(getAllAnnouncements(pageSize, previousPage));
                      }
                    }}
                    disabled={pageNumber === 0}
                    size="large"
                  >
                    <ChevronLeftRounded />
                  </IconButton>
                  <Typography>{pageNumber + 1}</Typography>
                  <IconButton
                    onClick={() => {
                      const nextPage = pageNumber + 1;
                      setPageNumber(pageNumber + 1);
                      dispatch(getAllAnnouncements(pageSize, nextPage));
                    }}
                    disabled={
                      (searchAnnouncement !== ""
                        ? searchData
                        : announcements.announcementsList
                      ).length -
                        pageSize <
                      0
                    }
                    size="large"
                  >
                    <ChevronRightRounded />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div ref={ref}>
        <Grow
          in={currentScreen === 0}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 0 ? { timeout: 1000 } : {})}
        >
          <div>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                padding: " 16px",
              }}
            >
              <Grid item xs={12} sm={6} md={8} lg={6}>
                <Grid
                  container
                  spacing={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid item>
                    <Typography variant="h6">Announcements</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setCurrentScreen(1)}
                      startIcon={<AddIcon />}
                    >
                      Publish Announcement
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <TableWrapper>
              <Paper>
                <Grid
                  style={{ backgroundColor: "transparent" }}
                  className="d-flex py-2 pl-3 align-items-center"
                >
                  <Grid className="d-flex flex-grow-1">
                    <TextField
                      type="search"
                      fullWidth
                      variant="outlined"
                      placeholder="Search announcement by name"
                      size="small"
                      value={searchAnnouncement}
                      onChange={(e) => {
                        setSearchAnnouncement(e.target.value.toLowerCase());

                        const filteredData =
                          announcements.announcementsList.filter((item: any) =>
                            item.announcementtext
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          );
                        setSearchData(filteredData);
                      }}
                      className="mr-2"
                    />
                  </Grid>
                  <Grid className="d-flex align-items-center">
                    <IconButton
                      onClick={() => {
                        dispatch(getAllAnnouncements(pageSize, pageNumber));
                      }}
                      size="large"
                    >
                      <Tooltip title="Refresh">
                        <RefreshIcon />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                {announcements.loading ? ListSkeleton() : renderAnnouncements()}
              </Paper>
            </TableWrapper>
          </div>
        </Grow>
        <Grow
          in={currentScreen === 1}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 1 ? { timeout: 1000 } : {})}
        >
          <ScheduleAnnouncement
            isEditing={states.editing}
            editItem={states.editItem}
            onClickBack={onClickBack}
          />
        </Grow>
      </div>
    );
  }
);
