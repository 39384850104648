import { createSlice } from "@reduxjs/toolkit";
import { GetAllContentFilterResponse } from "api/models/gamification-service/gamification_pb";

interface contentDefaultState {
  contentList: {
    loading: boolean;
    content: {
      daajitalksvideoslistList: [];
      allcontentlistList: [];
      featuredcontent: {};
      pageable: {
        totalcount: number;
      };
    };
  };
  filters: {
    loading: boolean;
    filtersList: GetAllContentFilterResponse.AsObject;
  };
}

const initialState: contentDefaultState = {
  contentList: {
    loading: true,
    content: {
      daajitalksvideoslistList: [],
      allcontentlistList: [],
      featuredcontent: {},
      pageable: {
        totalcount: 0,
      },
    },
  },
  filters: {
    loading: false,
    filtersList: {
      contentfilterlocaleresponseList: [],
      contentfilterresponseList: [],
      pageable: {
        selectedpagenumber: 0,
        selectedpagesize: 10,
        totalcount: 0,
      },
    },
  },
};

export const contentSlice = createSlice({
  name: "contentList",
  initialState,
  reducers: {
    setContentListLoading: (state, action) => {
      state.contentList.loading = action.payload;
    },
    contentList: (state, action) => {
      state.contentList.content = action.payload;
    },
    setFilterListLoading: (state, action) => {
      state.filters.loading = action.payload;
    },
    filterList: (state, action) => {
      state.filters.filtersList = action.payload;
    },
  },
});

export const {
  setContentListLoading,
  contentList,
  setFilterListLoading,
  filterList,
} = contentSlice.actions;

export default contentSlice.reducer;
