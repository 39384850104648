import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ImageOutlined } from "@mui/icons-material";
import { Alert } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useCallback, useEffect, useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCoachListAsync } from "redux/actions/liveMeditationAction";
import ImageCrop from "components/image-crop/image-crop";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PreviewProfileImage from "../../../components/image-preview/profileImagePreview";
import {
  AddCoachRequest,
  AwsChannelRequest,
  CoachProfile,
  DeleteCoachByIdResponse,
  ID,
} from "api/models/live-meditation-service/live-meditation_pb";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { firebaseAuth } from "config/firebase";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import {
  GAMIFICATION_SERVICE_HOST,
  HEARTINTUNE_PROFILE_SERVICE_HOST,
  LIVE_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { grpc } from "@improbable-eng/grpc-web";
import { useFileUploadService } from "services/FileUplaodService";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogBox from "components/dialogBox/dialog";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { RootState } from "redux/store/store";
import { useAuth } from "services/AuthService";
import {
  ConnectUserRequest,
  ConnectUserResponse,
  RequestSetUserRole,
  RequestUserDetailsByEmail,
  ResponseUserDetailsByEmail,
  Status,
} from "api/models/heartintune-profile-service/heartintune-profile_pb";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import { createUserUpdate } from "redux/reducers/userReducer";
import { MenuProps } from "styles/global.styles";

const PREFIX = "AddCoach";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    maxWidth: 350,
    // overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: "80%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `10%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

interface FormProps {
  isEditing?: boolean;
  editItem?: any;
  onClickBack: any;
  onClickCoaches: any;
}
interface Crop {
  unit: "%" | "px" | undefined;
  width: number;
  aspect: number;
}
interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number;
}
interface coach {
  id: any;
  title: any;
  profiletitle: any;
  subTitle: any;
  profilesubtitle: any;
  name: any;
  email: any;
  profiledescription: any;
  playBackUrl: any;
  tag: any;
  imageurl: string;
  audioUrl: string;
}

interface AddCoachdata {
  id: number;
  name: string;
  email: any;
  title: any;
  subTitle: any;
  description: any;
  awsChannelInfo: any;
  imageURL: any;
  videoURL: any;
  playBackUrl: any;
}

const AddCoach = React.forwardRef<HTMLDivElement, FormProps>(
  (props, ref): ReactElement => {
    const dispatch = useDispatch();
    const { emailSignUp, emailLogin } = useAuth();
    const { appendFilesToUploadManager } = useFileUploadService();
    const languages = useSelector((state: RootState) => state.app.languages);
    const CHARACTER_LIMIT = 1000;
    const cropDef: Crop = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };
    const initialState = {
      editing: false,
      editItem: null,
      showDeleteModal: false,
      deleteItemId: 0,
    };

    const hiddenFileInput: any = React.useRef(null);
    const [language, setLanguage] = useState<any>(1);
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [title, settitle] = useState("");
    const [subTitle, setsubTitle] = useState("");
    const [description, setdescription] = useState("");
    const [chhanelName, setchhanelName] = useState("");
    const [latencyMode, setlatencyMode] = useState("LOW");
    const [type, settype] = useState("STANDARD");
    const [id, setid] = useState<any>(null);
    const [playBackUrl, setplayBackUrl] = useState("");
    const [channel, setchannel] = useState("commonChannel");

    const [imageRef, setImageRef] = useState<any>();
    const [src, setSrc] = useState<any>(null);
    const [crop, setCrop] = useState<Crop>(cropDef);
    const [imageFile, setImageFile] = useState<any>(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState<any>(null);
    const [oldCroppedImageUrl, setOldCroppedImageUrl] = useState<any>(null);
    const [croppedImage, setCroppedImage] = useState<any>(null);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    // const [reqTitle, setReqTitle] = useState<any>({});
    // const [reqSubTitle, setReqSubtitle] = useState<any>({});
    // const [reqDescription, setReqDescription] = useState<any>({});
    const [showPassword, setshowPassword] = useState(false);
    const [coachPassword, setCoachPassword] = useState("");
    const [isAdding, setIsAdding] = useState(false);

    const [states, setStates] = useState<State>(initialState);

    const [uid, setUid] = useState<boolean | string>(false);

    const [open, setopen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const onClickEdit = useCallback(
      (item: coach) => {
        console.log("editing coach details", item);
        setStates({ editing: true });
        switch (language) {
          case 1:
            settitle(item.profiletitle.defaultcontent);
            setsubTitle(item.profilesubtitle.defaultcontent);
            setdescription(item.profiledescription.defaultcontent);
            break;
          case 2:
            settitle(item.profiletitle.en);
            setsubTitle(item.profilesubtitle.en);
            setdescription(item.profiledescription.en);
            break;
          case 3:
            settitle(item.profiletitle.hi);
            setsubTitle(item.profilesubtitle.hi);
            setdescription(item.profiledescription.hi);
            break;
          case 4:
            settitle(item.profiletitle.te);
            setsubTitle(item.profilesubtitle.te);
            setdescription(item.profiledescription.te);
            break;
          case 5:
            settitle(item.profiletitle.gu);
            setsubTitle(item.profilesubtitle.gu);
            setdescription(item.profiledescription.gu);
            break;
          case 6:
            settitle(item.profiletitle.ta);
            setsubTitle(item.profilesubtitle.ta);
            setdescription(item.profiledescription.ta);
            break;
          case 7:
            settitle(item.profiletitle.ka);
            setsubTitle(item.profilesubtitle.ka);
            setdescription(item.profiledescription.ka);
            break;
          case 8:
            settitle(item.profiletitle.mr);
            setsubTitle(item.profilesubtitle.mr);
            setdescription(item.profiledescription.mr);
            break;
          case 9:
            settitle(item.profiletitle.it);
            setsubTitle(item.profilesubtitle.it);
            setdescription(item.profiledescription.it);
            break;
          case 10:
            settitle(item.profiletitle.ru);
            setsubTitle(item.profilesubtitle.ru);
            setdescription(item.profiledescription.ru);
            break;
          case 11:
            settitle(item.profiletitle.es);
            setsubTitle(item.profilesubtitle.es);
            setdescription(item.profiledescription.es);
            break;
          case 12:
            settitle(item.profiletitle.fr);
            setsubTitle(item.profilesubtitle.fr);
            setdescription(item.profiledescription.fr);
            break;
          case 13:
            settitle(item.profiletitle.pt);
            setsubTitle(item.profilesubtitle.pt);
            setdescription(item.profiledescription.pt);
            break;
          case 14:
            settitle(item.profiletitle.de);
            setsubTitle(item.profilesubtitle.de);
            setdescription(item.profiledescription.de);
            break;
          case 15:
            settitle(item.profiletitle.bn);
            setsubTitle(item.profilesubtitle.bn);
            setdescription(item.profiledescription.bn);
            break;
          case 16:
            settitle(item.profiletitle.ml);
            setsubTitle(item.profilesubtitle.ml);
            setdescription(item.profiledescription.ml);
            break;
          case 17:
            settitle(item.profiletitle.uk);
            setsubTitle(item.profilesubtitle.uk);
            setdescription(item.profiledescription.uk);
            break;
          case 18:
            settitle(item.profiletitle.od);
            setsubTitle(item.profilesubtitle.od);
            setdescription(item.profiledescription.od);
            break;
          case 19:
            settitle(item.profiletitle.enUs);
            setsubTitle(item.profilesubtitle.enUs);
            setdescription(item.profiledescription.enUs);
            break;
          case 20:
            settitle(item.profiletitle.enAu);
            setsubTitle(item.profilesubtitle.enAu);
            setdescription(item.profiledescription.enAu);
            break;
            case 21:
              settitle(item.profiletitle.pa);
              setsubTitle(item.profilesubtitle.pa);
              setdescription(item.profiledescription.pa);
              break;
        }
        setname(item.name);
        setemail(item.email);
        setid(item.id);
        setplayBackUrl(item.playBackUrl);
        setCroppedImageUrl(item.imageurl);
        setOldCroppedImageUrl(item.imageurl);
      },
      [language]
    );

    useEffect(() => {
      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
    }, [props.isEditing, props.editItem, onClickEdit]);

    useEffect(() => {
      console.log("click edit item", props.editItem);
    }, [props.editItem]);

    const onSelection = (event: any) => {
      if (event.target.files[0]) {
        //console.log("file", event.target.files[0]);
        setImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };
    const _onOpenAddCoach = () => {
      setopen(true);
    };
    const _onCloseAddCoach = () => {
      setopen(false);
      setSrc(null);
    };

    const onImageClose = () => {
      setSrc(null);
      setImageFile(null);
      setCroppedImage(null);
      setImageFile(null);
      setCrop(cropDef);
      // _deleteFileFromGcp(croppedImageUrl);
      setCroppedImageUrl(null);
    };
    const onCropDone = async () => {
      const cr: any = crop;
      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        setCroppedImageUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          "ProfilePicture".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              console.log("image url from add coach", res.detail.response);
              setCroppedImageUrl(res.detail.response);
            }
          },
          { passive: false }
        );
        setCroppedImage(croppedFile);
      }
      setSrc(null);
    };
    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };

    const onCropChange = (crop: any) => {
      setCrop(crop);
    };
    const onCropComplete = async () => {
      // console.log("state crop", crop, imageRef);
    };

    const handleUploadClick = () => {
      hiddenFileInput.current.click();
      //console.log("clicked on image upload");
    };

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                //setIsLoading(false);
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
                setCroppedImageUrl("");
              }
            } else {
              setIsLoading(false);
              // setSnackBar({
              //   open: true,
              //   severity: "error",
              //   message: "error in removing coach image",
              // });
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };

    const changeUserRoleToPreceptor = async (_id: number) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestSetUserRole = new RequestSetUserRole();
      reqBody.setUserid(_id);
      reqBody.setUserrole(5);
      grpc.unary(HeartIntuneProfileService.setUserRole, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK) {
            console.log(
              "change role to preceptor response",
              message?.toObject()
            );
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message:
                "User already exists with this email. Couldn't change role to preceptor",
            });
          }
        },
      });
    };

    const getFirebaseUid = async (_email: string) => {
      console.log("getFirebaseUid called");
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const reqBody: RequestUserDetailsByEmail =
        new RequestUserDetailsByEmail();

      reqBody.setEmail(_email);
      reqBody.setCheckemailexistinfirebase(true);

      grpc.unary(HeartIntuneProfileService.GetUserDetailsByEmail, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseUserDetailsByEmail>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log(message.toObject().uid);
            setUid(message.toObject().uid);
          } else {
            setUid(false);
          }
        },
      });
    };

    const checkIfUserExists = async (
      _email: string,
      data: any
      // userCredentials: any
    ) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const reqBody: RequestUserDetailsByEmail =
        new RequestUserDetailsByEmail();

      reqBody.setEmail(_email);
      console.log("req for user existing", reqBody.toObject());
      grpc.unary(HeartIntuneProfileService.GetUserDetailsByEmail, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseUserDetailsByEmail>) => {
          console.log("response for user existing", res.message?.toObject());
          const { status, message } = res;
          if (
            status === grpc.Code.OK &&
            message?.getUserlistList().length !== 0
          ) {
            if (message?.toObject().userlistList[0].provider === 2) {
              changeUserRoleToPreceptor(message?.toObject().userlistList[0].id);
              addCoach(data);
              setSnackBar({
                open: true,
                severity: "error",
                message:
                  "Please log out and try sign-in using Google with the provided email",
              });
              setIsAdding(false);
            } else if (message?.toObject().userlistList[0].provider === 4) {
              changeUserRoleToPreceptor(message?.toObject().userlistList[0].id);
              addCoach(data);
            } else {
              const userId: any = message?.toObject().userlistList[0]?.id;

              changeUserRoleToPreceptor(Number(userId));
              addCoach(data);
              setIsAdding(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: "contact admin - anupam.gupta@codeprism.in",
              });
            }
          } else if (
            status === grpc.Code.OK &&
            message?.getUserlistList().length === 0
          ) {
            setIsAdding(false);
            getFirebaseUid(_email);
            if (uid) {
              connectUser(uid, email, name);
              addCoach(data);
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "something went wrong in connecting user",
              });
            }
            // .then((res) => console.log(res));

            // setSnackBar({
            //   open: true,
            //   severity: "error",
            //   message: "Contact support",
            // });

            // connectUser(
            //   userCredentials.user?.uid,
            //   userCredentials.user?.email,
            //   userCredentials.user?.displayName
            // );
          } else {
            setIsAdding(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: "something went wrong in creating user",
            });
          }
        },
      });
    };
    const validateForm = () => {
      const emailRegex = /\S+@\S+\.\S+/;
      // const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (name.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: " name can not be empty",
        });
      } else if (email.trim() === "" || email === null) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "email can not be empty",
        });
      } else if (emailRegex.test(email) === false) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "invalid email",
        });
      } else if (states.editing === false && coachPassword.length <= 4) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "please provide a valid and secure password",
        });
      } else if (title === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "title can not be empty",
        });
      } else if (subTitle === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "sub title can not be empty",
        });
      } else if (description === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "description  can not be empty",
        });
      } else if (channel === "createChannel" && chhanelName === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "chhanel name can not be empty",
        });
      } else if (croppedImageUrl === null || croppedImageUrl === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Profile image can not be empty",
        });
      } else {
        return true;
      }
    };

    const emptyData = () => {
      setname("");
      setemail("");
      settitle("");
      setsubTitle("");
      setdescription("");
      setlatencyMode("");
      settype("");
      setchhanelName("");
    };
    const addCoach = async (data: AddCoachdata) => {
      try {
        console.log("add coach data", data.title);
        var addCoachReq = new AddCoachRequest();
        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultContent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);

        const subTitleLocale: Locale = new Locale();
        subTitleLocale.setDefaultcontent(data.subTitle.defaultContent);
        subTitleLocale.setEn(data.subTitle.en);
        subTitleLocale.setHi(data.subTitle.hi);
        subTitleLocale.setTe(data.subTitle.te);

        const descriptionLocale: Locale = new Locale();
        descriptionLocale.setDefaultcontent(data.description.defaultContent);
        descriptionLocale.setEn(data.description.en);
        descriptionLocale.setHi(data.description.hi);
        descriptionLocale.setTe(data.description.te);

        const awsChhanel: AwsChannelRequest = new AwsChannelRequest();
        awsChhanel.setChannelname(data.awsChannelInfo.channelName);
        awsChhanel.setAuthorized(data.awsChannelInfo.authorized);
        awsChhanel.setLatencymode(data.awsChannelInfo.latencyMode);
        awsChhanel.setType(data.awsChannelInfo.type);
        awsChhanel.setTagsList(data.awsChannelInfo.tags);

        addCoachReq.setName(data.name);
        addCoachReq.setEmail(data.email);
        addCoachReq.setProfiletitle(titleLocale);
        addCoachReq.setProfilesubtitle(subTitleLocale);
        addCoachReq.setProfiledescription(descriptionLocale);
        addCoachReq.setImageurl(data.imageURL);
        addCoachReq.setVideourl(data.videoURL);
        addCoachReq.setAwschannelinfo(awsChhanel);

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        console.log(addCoachReq.toObject());
        grpc.unary(LiveMeditationService.AddCoach, {
          request: addCoachReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<CoachProfile>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              console.log("all ok. got book: ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: "coach added successfully",
              });
              setShowPreview(true);
              dispatch(getCoachListAsync());
              setTimeout(() => {
                props.onClickBack();
              }, 2000);
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in adding coach",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in addCoach",
        });
        console.error("something went wrong in addCoach", error);
      }
    };

    const updateCoach = async (data: AddCoachdata) => {
      console.log("update coach request", data);
      try {
        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultContent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);
        titleLocale.setPa(data.title.pa);

        const subTitleLocale: Locale = new Locale();
        subTitleLocale.setDefaultcontent(data.subTitle.defaultContent);
        subTitleLocale.setEn(data.subTitle.en);
        subTitleLocale.setHi(data.subTitle.hi);
        subTitleLocale.setTe(data.subTitle.te);
        subTitleLocale.setGu(data.subTitle.gu);
        subTitleLocale.setTa(data.subTitle.ta);
        subTitleLocale.setKa(data.subTitle.ka);
        subTitleLocale.setMr(data.subTitle.mr);
        subTitleLocale.setIt(data.subTitle.it);
        subTitleLocale.setRu(data.subTitle.ru);
        subTitleLocale.setEs(data.subTitle.es);
        subTitleLocale.setFr(data.subTitle.fr);
        subTitleLocale.setPt(data.subTitle.pt);
        subTitleLocale.setDe(data.subTitle.de);
        subTitleLocale.setBn(data.subTitle.bn);
        subTitleLocale.setMl(data.subTitle.ml);
        subTitleLocale.setUk(data.subTitle.uk);
        subTitleLocale.setOd(data.subTitle.od);
        subTitleLocale.setEnUs(data.subTitle.enUs);
        subTitleLocale.setEnAu(data.subTitle.enAu);
        subTitleLocale.setPa(data.subTitle.pa);

        const descriptionLocale: Locale = new Locale();
        descriptionLocale.setDefaultcontent(data.description.defaultContent);
        descriptionLocale.setEn(data.description.en);
        descriptionLocale.setHi(data.description.hi);
        descriptionLocale.setTe(data.description.te);
        descriptionLocale.setGu(data.description.gu);
        descriptionLocale.setTa(data.description.ta);
        descriptionLocale.setKa(data.description.ka);
        descriptionLocale.setMr(data.description.mr);
        descriptionLocale.setIt(data.description.it);
        descriptionLocale.setRu(data.description.ru);
        descriptionLocale.setEs(data.description.es);
        descriptionLocale.setFr(data.description.fr);
        descriptionLocale.setPt(data.description.pt);
        descriptionLocale.setDe(data.description.de);
        descriptionLocale.setBn(data.description.bn);
        descriptionLocale.setMl(data.description.ml);
        descriptionLocale.setUk(data.description.uk);
        descriptionLocale.setOd(data.description.od);
        descriptionLocale.setEnUs(data.description.enUs);
        descriptionLocale.setEnAu(data.description.enAu);
        descriptionLocale.setPa(data.description.pa);

        const updateCoachReq = new CoachProfile();

        if (data.imageURL !== oldCroppedImageUrl) {
          _deleteFileFromGcp(oldCroppedImageUrl);
        }

        updateCoachReq.setName(data.name);
        updateCoachReq.setEmail(data.email);
        updateCoachReq.setProfiletitle(titleLocale);
        updateCoachReq.setProfilesubtitle(subTitleLocale);
        updateCoachReq.setProfiledescription(descriptionLocale);
        updateCoachReq.setImageurl(data.imageURL);
        updateCoachReq.setVideourl(data.videoURL);
        updateCoachReq.setId(data.id);
        updateCoachReq.setPlaybackurl(data.playBackUrl);

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        console.log("payload", updateCoachReq.toObject());
        grpc.unary(LiveMeditationService.UpdateCoach, {
          request: updateCoachReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res) => {
            const { status, message } = res;
            if (status === grpc.Code.OK && message) {
              console.log("all ok. got book: ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: "coach updated successfully",
              });
              emptyData();
              setTimeout(() => {
                props.onClickBack();
              }, 2000);
              dispatch(getCoachListAsync());
              //return message;
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in updateding coach ",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in update Coach ",
        });
        console.error("something went wrong in update Coach", error);
      }
    };

    const _addCoach = () => {
      if (validateForm()) {
        var coachData: any = {
          name: name,
          email: email,
          title: {
            defaultContent: title,
            en: title,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
          subTitle: {
            defaultContent: subTitle,
            en: subTitle,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
          description: {
            defaultContent: description,
            en: description,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
          awsChannelInfo:
            channel === "commonChannel"
              ? {
                  authorized: false,
                  type: "",
                  latencyMode: "",
                  channelName: "",
                  tags: [],
                }
              : {
                  authorized: false,
                  type: type,
                  latencyMode: latencyMode,
                  channelName: chhanelName,
                  tags: [],
                },
          imageURL: croppedImageUrl,
          videoURL: channel === "commonChannel" ? "awsCommonChannel" : "",
        };
        setIsAdding(true);
        createCoachUser(coachData);
      }
    };
    const decideLocale = () => {
      // Create default description object with empty strings
      const defaultDescription = languages.reduce((acc, lang) => {
        if (lang.name === 'de') return acc;
        return {
          ...acc,
          [lang.name as string]: '',
        };
      }, {});

      // Define language code mapping dynamically from appReducer languages
      const languageMap: { [key: number]: keyof typeof defaultDescription } = 
        languages.reduce((acc, lang) => ({
          ...acc,
          [lang.code]: lang.name as keyof typeof defaultDescription
        }), {});

      // Get the target language code
      const targetLang = languageMap[language];
      
      if (!targetLang) {
        console.warn(`Unsupported language code: ${language}`);
        return { description: defaultDescription };
      }

      // Set the description for the target language
      return {
        reqTitle: {
          ...defaultDescription,
          [targetLang]: title,
          defaultContent: language === 1 
            ? title 
            : props.editItem.profiletitle.defaultcontent
        },
        reqSubTitle: {
          ...defaultDescription,
          [targetLang]: subTitle,
          defaultContent: language === 1 
            ? subTitle 
            : props.editItem.profilesubtitle.defaultcontent
        },
        reqDescription: {
          ...defaultDescription,
          [targetLang]: description,
          defaultContent: language === 1 
            ? description 
            : props.editItem.profiledescription.defaultcontent
        },
      };
    };

    const _updateCoach = () => {
      if (validateForm()) {
        const coachData: any = {
          id: id,
          name: name,
          email: email,
          title: decideLocale()?.reqTitle,
          subTitle: decideLocale()?.reqSubTitle,
          description: decideLocale()?.reqDescription,
          imageURL: croppedImageUrl,
          videoURL: "awsCommonChannel",
          playBackUrl: playBackUrl,
        };
        setIsAdding(true);
        updateCoach(coachData);
      }
    };

    const changeRoleToUser = async (_id: number) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestSetUserRole = new RequestSetUserRole();
      reqBody.setUserid(_id);
      reqBody.setUserrole(4);
      grpc.unary(HeartIntuneProfileService.setUserRole, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK) {
            console.log("change role to user", message?.toObject());
          }
        },
      });
    };

    const getUserIdByEmail = async (_email: string) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const reqBody: RequestUserDetailsByEmail =
        new RequestUserDetailsByEmail();

      reqBody.setEmail(_email);
      grpc.unary(HeartIntuneProfileService.GetUserDetailsByEmail, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseUserDetailsByEmail>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            if (message.getUserlistList().length) {
              // setCoachUserId(message.toObject().userlistList[0].id);
              changeRoleToUser(message.toObject().userlistList[0].id);
            }
          }
        },
      });
    };

    const onClickDelete = (coachProfileId: number) => {
      setStates({ showDeleteModal: true, deleteItemId: coachProfileId });
    };
    const handleDeleteClose = () => {
      setStates({ showDeleteModal: false });
    };

    const deleteCoach = async () => {
      getUserIdByEmail(email);
      deleteCoachByID(Number(states.deleteItemId));
    };
    const deleteCoachByID = async (coachId: number) => {
      setIsLoading(true);
      console.log("delete api called for coach id", coachId);
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: ID = new ID();
        reqBody.setId(coachId);
        grpc.unary(LiveMeditationService.DeleteCoachByID, {
          host: LIVE_MEDITATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<DeleteCoachByIdResponse>) => {
            //console.log("delete response", res);
            const { status, message } = res;
            if (status === grpc.Code.OK && message) {
              if (message) {
                setIsLoading(false);
                console.log("data coming", message.toObject().message);
                dispatch(getCoachListAsync());
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: "coach deleted successfully",
                });
                setStates({ showDeleteModal: false });
                setTimeout(() => props.onClickBack(), 2000);
              }
            } else {
              setIsLoading(false);
              setStates({ showDeleteModal: false });
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in  deleting coach",
              });
            }
          },
        });
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };

    const createCoachUser = (data: any) => {
      emailSignUp &&
        emailSignUp(email, coachPassword)
          .then((userCredentials) => {
            console.log("firebase res for add user", userCredentials);
            userCredentials.user
              ?.updateProfile({
                displayName: `${name}`,
              })
              .then((res) => {
                console.log("res", res);
                connectUser(userCredentials.user?.uid, email, name);
                addCoach(data);
              })
              .catch((err) => {
                setIsAdding(false);
                console.log(err);
              });
          })
          .catch((err) => {
            console.log("err", err);
            if (err.code === "auth/email-already-in-use") {
              //if user is already exist in firebase get firebase uid
              // emailLogin &&
              //   emailLogin(email, coachPassword)
              //     .then((userCredentials) => {
              //       console.log("firebase res for login user", userCredentials);
              //       checkIfUserExists(email, data, userCredentials);
              //     })
              //     .catch((err) => {
              //       setIsAdding(false);
              //       console.log("err", err);
              //     });
              checkIfUserExists(email, data);
            } else {
              console.log(err.message);
              setIsAdding(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: err.message,
              });
            }
          });
    };

    const connectUser = async (uid: any, _email: any, _name: string) => {
      let data = {
        uid: uid,
        email: _email,
        status: 2,
        provider: 4,
        Addressline: "PRECEPTOR",
        displayName: `${_name}`,
      };
      try {
        console.log("reuest data to connect user", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: ConnectUserRequest = new ConnectUserRequest();
        //reqBody.setUserid(userId);
        reqBody.setDisplayname(data.displayName);
        reqBody.setEmail(data.email);
        reqBody.setStatus(2);
        reqBody.setProvider(4);
        reqBody.setFirebaseid(data.uid);
        reqBody.setAddressline(data.Addressline);
        grpc.unary(HeartIntuneProfileService.ConnectUser, {
          host: HEARTINTUNE_PROFILE_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },

          onEnd: (res: UnaryOutput<ConnectUserResponse>) => {
            const { status, statusMessage, message } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from connect user ",
                  message.toObject()
                );
                dispatch(createUserUpdate());
              } else {
                setSnackBar({
                  open: true,
                  severity: "error",
                  message: "error in adding user",
                });
              }
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <Root ref={ref} {...props}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          style={{
            padding: " 8px",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Grid
              container
              className={states.editing ? "" : "align-items-center"}
            >
              <Grid item>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {states.editing ? "Edit Coach" : "  Create Coach"}
                </Typography>
                {states.editing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      Coaches
                    </Typography>
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickCoaches}
                    >
                      {name}
                    </Typography>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => onClickDelete(id)}
              >
                Delete
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={states.editing ? 4 : 6} md={4} sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="name"
                label=" Coach Name"
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                  setchhanelName(
                    name === "" ? " " : `${e.target.value.replace(/\W/g, "")}`
                  );
                }}
              />
            </Grid>
            <Grid item lg={states.editing ? 5 : 6} md={4} sm={12} xs={12}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="title"
                  label=" Profile Title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => {
                    settitle(e.target.value);
                  }}
                  className="mr-3"
                />
              </Grid>
            </Grid>
            {states.editing && (
              <Grid item xs={6} sm={4} md={4} lg={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="language-select">Language</InputLabel>
                  {languages && (
                    <Select
                      defaultValue=""
                      labelId="language-select"
                      value={language}
                      label="Language"
                      onChange={(e) => {
                        setLanguage(e.target.value as number);
                      }}
                      MenuProps={MenuProps}
                    >
                      {languages.map((lang: any, i: any) => (
                        <MenuItem key={i} value={lang.code}>
                          {lang.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid>
            )}

            <Grid item md={6} sm={6} xs={12}>
              <TextField
                fullWidth
                required
                id="outlined-basic"
                label=" Coach Email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                disabled={states.editing}
              />
            </Grid>
            {!states.editing && (
              <Grid item md={6} sm={12} xs={12} className="d-flex">
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => {
                    setCoachPassword(e.target.value);
                  }}
                  // error={formErrors?.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => {
                            setshowPassword(!showPassword);
                          }}
                          size="large"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className="mr-3"
                />
              </Grid>
            )}
            <Grid item md={6} sm={12} xs={12} className="d-flex">
              <TextField
                fullWidth
                required
                type="text"
                name="subTitle"
                label="Coach Nickname"
                variant="outlined"
                value={subTitle}
                onChange={(e) => {
                  setsubTitle(e.target.value);
                }}
              />
            </Grid>

            {states.editing ? (
              ""
            ) : (
              <Grid item md={6} sm={12} xs={12}>
                <Card variant="outlined">
                  <CardContent className="pb-3">
                    <Typography color="textSecondary" className="mb-2">
                      Configure Live Channel
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        name="channel"
                        value={channel}
                        onChange={(e) => {
                          setchannel(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="commonChannel"
                          control={<Radio color="primary" />}
                          label="Use common channel"
                          labelPlacement="end"
                        />

                        <FormControlLabel
                          value="createChannel"
                          control={<Radio color="primary" />}
                          label="Create channel"
                        />
                      </RadioGroup>
                    </FormControl>

                    {channel === "createChannel" && (
                      <Grid container spacing={3} className="mt-2">
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            name="channelName"
                            label=" Channel name"
                            variant="outlined"
                            // value= {name===''? " ": `LiveChannelfor${name}`}
                            value={chhanelName}
                            onChange={(e) => {
                              setchhanelName(e.target.value.replace(/\W/g, ""));
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Latency Mode
                            </FormLabel>
                            <RadioGroup
                              row
                              name="latencyMode"
                              value={latencyMode}
                              onChange={(e) => {
                                setlatencyMode(e.target.value);
                              }}
                            >
                              <FormControlLabel
                                value="NORMAL"
                                control={<Radio color="primary" />}
                                label="Standard"
                                labelPlacement="end"
                              />

                              <FormControlLabel
                                value="LOW"
                                control={<Radio color="primary" />}
                                label="Low"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Type</FormLabel>
                            <RadioGroup
                              row
                              name="type"
                              value={type}
                              onChange={(e) => {
                                settype(e.target.value);
                              }}
                            >
                              <FormControlLabel
                                value="STANDARD"
                                control={<Radio color="primary" />}
                                label="Standard"
                              />
                              <FormControlLabel
                                value="BASIC"
                                control={<Radio color="primary" />}
                                label="Basic"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item md={12} sm={12} xs={12} className="d-flex">
              <TextField
                fullWidth
                required
                name="description"
                label=" Profile Description"
                variant="outlined"
                multiline
                rows={4}
                // maxRows={20}
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                  style: {
                    fontSize: 14,
                  },
                }}
                value={description}
                helperText={
                  <Typography variant="caption">
                    {description?.length || 0}/{CHARACTER_LIMIT} words
                  </Typography>
                }
                onChange={(e) => {
                  setdescription(e.target.value);
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {croppedImageUrl ? (
                    <PreviewProfileImage
                      onImageClose={onImageClose}
                      src={croppedImageUrl}
                      //fileName={`${imageFile.name}`}
                      file={croppedImage}
                      //setimageUrl={setimageUrl}
                      //uploadFile={{file:croppedImage,objectPath:'',token:''}}
                    />
                  ) : (
                    <CardActionArea
                      className={classes.thumbNail}
                      onClick={() => {
                        handleUploadClick();
                      }}
                    >
                      {/* <InputLabel htmlFor="image">
                        Upload Profile Image
                      </InputLabel> */}
                      <Avatar className={classes.actionIcon} variant="square">
                        <ImageOutlined />
                      </Avatar>
                      <input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        hidden
                        onChange={onSelection}
                        ref={hiddenFileInput}
                      />
                    </CardActionArea>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Profile Image
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                variant="outlined"
                sx={{
                  height: "100%",
                  // width: "100%",
                  boxShadow: "none",
                  position: "relative",
                  // p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1">
                  Coach Image Rectangular Preview
                </Typography>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 200, width: 200 }}
                >
                  <img
                    src={croppedImageUrl}
                    style={{
                      borderRadius: "5px",
                      height: "155px",
                      width: "190px",
                    }}
                    alt=""
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className=" p-1 d-flex justify-content-end">
          <Box p={1} display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                states.editing ? _updateCoach() : _addCoach();
              }}
              disabled={
                name === "" ||
                title === "" ||
                subTitle === "" ||
                description === "" ||
                email === "" ||
                isAdding
                // email === "" ||
                // croppedImageUrl === "" ||
                // croppedImageUrl === null
              }
            >
              {states.editing ? "Update Coach" : " Create Coach"}
            </Button>
          </Box>
        </CardActions>
        {src && (
          <ImageCrop
            src={src.toString()}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onCropComplete={onCropComplete}
            onCropChange={onCropChange}
            onPressDone={onCropDone}
            onImageClose={onImageClose}
            open={open}
            onOpen={_onOpenAddCoach}
            onClose={_onCloseAddCoach}
          />
        )}

        <DialogBox
          open={states.showDeleteModal || false}
          onClose={handleDeleteClose}
          onCancel={handleDeleteClose}
          onConfirm={deleteCoach}
          type={"delete"}
          title={`Sure !! Want to delete this Item ?`}
        />

        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
export default AddCoach;
