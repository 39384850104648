import { useEffect } from "react";
import {
  //Link as RouterLink,
  useLocation,
} from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  Paper,
  List,
  Typography,
  Hidden,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import NavItem from "components/NavBar/navItem";
import { version } from "../../../package.json";
import { selectNavItemByRole } from "./roleBasedNavItem";
import { Global } from "@emotion/react";

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  name: {},
}));

// const styles = () => createStyles({});

const NavBar = ({ onMobileClose, openMobile }: NavBarProps) => {
  // const state = useSelector((state: RootState) => state.user);
  const classes = useStyles();
  const location = useLocation();
  const users: any = localStorage.getItem("user");
  const User = JSON.parse(users);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, openMobile, onMobileClose]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      sx={{
        bgcolor: "primary.main",
        color: "primary.contrastText",
        border: "none",
      }}
    >
      {/* <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        sx={{ color: "primary.contrastText" }}
        p={2}
      >
        <Avatar
          className={classes.avatar}
          // component={RouterLink}
          src={currentUser?.photoURL || "./admin-logo.jpg"}
          //to="/app/settings"
        />
        <Typography
          className={classes.name}
          // color="textPrimary"
          variant="h5"
          align="center"
        >
          {currentUser?.displayName || user.name}
        </Typography>
        <Typography variant="body2" align="center">
          {currentUser?.email || user.email}
        </Typography>
      </Box>
      <Divider /> */}
      <Box sx={{ padding: "15px" }} maxWidth="95%">
        {/* --------------------------groups-------------------------- */}
        {/* <>
    
          <Typography
            variant="subtitle1"
            gutterBottom={true}
            sx={{
              fontWeight: "600",
              fontSize: "0.9em",
              marginTop: "3vh",
            }}
          >
            Groups
          </Typography>
          <List>
            {
              //navItems.filter((items)=>items.role.includes(state.userRole))
              selectNavItemByRole(User.role).map((item) => {
                if (item.category === "Groups") {
                  return (
                    <NavItem
                      href={item.href}
                      key={item.title}
                      title={item.title}
                      icon={item.icon}
                      category={item.category}
                    />
                  );
                }
              })
            }
          </List>
          <Divider variant="middle" sx={{ background: "white" }}></Divider>
        </> */}
        {/* --------------------------Meditations-------------------------- */}
        {/* <>
          <Typography
            variant="subtitle1"
            gutterBottom={true}
            sx={{
              fontWeight: "600",
              fontSize: "0.9em",
              marginTop: "3vh",
            }}
          >
            Meditations
          </Typography>
          <List>
            {
              //navItems.filter((items)=>items.role.includes(state.userRole))
              selectNavItemByRole(User.role).map((item) => {
                if (item.category === "Meditations") {
                  return (
                    <NavItem
                      href={item.href}
                      key={item.title}
                      title={item.title}
                      icon={item.icon}
                      category={item.category}
                    />
                  );
                }
              })
            }
          </List>
          <Divider variant="middle" sx={{ background: "white" }}></Divider>
        </> */}

        {User.role === 1 || User.role === 2 ? (
          <>
            <Typography
              variant="subtitle1"
              gutterBottom={true}
              sx={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "3vh",
              }}
            >
              User Management
            </Typography>
            <List>
              {
                //navItems.filter((items)=>items.role.includes(state.userRole))
                selectNavItemByRole(User.role).map((item) => {
                  if (item.category === "User Management")
                    return (
                      <NavItem
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                        category={item.category}
                      />
                    );
                })
              }
            </List>
            <Divider
              variant="middle"
              sx={{ background: "white", height: "1px" }}
            ></Divider>
          </>
        ) : (
          ""
        )}
        {User.role === 1 || User.role === 2 ? (
          <>
            <Typography
              variant="subtitle1"
              gutterBottom={true}
              sx={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "3vh",
              }}
            >
              HeartsApp
            </Typography>
            <List>
              {
                //navItems.filter((items)=>items.role.includes(state.userRole))
                selectNavItemByRole(User.role).map((item) => {
                  if (item.category === "HeartsApp")
                    return (
                      <NavItem
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                        category={item.category}
                      />
                    );
                })
              }
            </List>
            <Divider
              variant="middle"
              sx={{ background: "white", height: "1px" }}
            ></Divider>
          </>
        ) : (
          ""
        )}

        {User.role === 1 || User.role === 2 || User.role === 3 ? (
          <>
            <Typography
              variant="subtitle1"
              gutterBottom={true}
              sx={{ fontWeight: "600", fontSize: "0.9em", marginTop: "3vh" }}
            >
              Gamification
            </Typography>
            <List>
              {
                //navItems.filter((items)=>items.role.includes(state.userRole))
                selectNavItemByRole(User.role).map((item) => {
                  if (item.category === "Gamification")
                    return (
                      <NavItem
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                        category={item.category}
                      />
                    );
                })
              }
            </List>
          </>
        ) : (
          ""
        )}

        {User.role === 1 || User.role === 2 ? (
          <>
            <Typography
              variant="subtitle1"
              gutterBottom={true}
              sx={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "3vh",
              }}
            >
              User Management
            </Typography>
            <List>
              {
                //navItems.filter((items)=>items.role.includes(state.userRole))
                selectNavItemByRole(User.role).map((item) => {
                  if (item.category === "User Management")
                    return (
                      <NavItem
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                        category={item.category}
                      />
                    );
                })
              }
            </List>
            <Divider
              variant="middle"
              sx={{ background: "white", height: "1px" }}
            ></Divider>
          </>
        ) : (
          ""
        )}
      </Box>

      <Box flexGrow={1} />
      <Box className="mr-4 mb-4">
        <Typography align="right" variant="body2">
          Version # {version}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              borderRight: "0",
              backgroundColor: process.env.REACT_APP_PRIMARY_MAIN || "#643E94",
              // maxWidth: "16vw",
            },
          }}
        />
        <Paper
          sx={{
            display: { lg: "none", xs: "block" },
            border: "none",
          }}
        >
          <Drawer
            anchor="left"
            classes={{ paper: classes.mobileDrawer }}
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
          >
            {content}
          </Drawer>
        </Paper>

        <Paper
          sx={{
            display: { lg: "block", xs: "none" },
          }}
        >
          <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
            sx={{backgroundColor:'primary.main'}}
          >
            {content}
          </Drawer>
        </Paper>
      </Hidden>
    </>
  );
};

export default NavBar;
