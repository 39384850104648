import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  // GetAllGoalsResponse,
  GetAllGoalInfoV2ResponseForAdminV2,
  ResponsePageable,
  ResponseGoal,
  ResponseGoalInfoV2,
} from "api/models/gamification-service/gamification_pb";

interface GoalsDefaultState {
  goalsList: {
    loading: boolean;
    goals?: ResponseGoal.AsObject[];
  };
  goalsListV2: {
    loading: boolean;
    goals?: GetAllGoalInfoV2ResponseForAdminV2.AsObject;
  };
}
const initialState: GoalsDefaultState = {
  goalsList: {
    loading: true,
    goals: [],
  },
  goalsListV2: {
    loading: true,
    goals: {
      goaltypesList: [],
      pageable: {
        totalcount: 0,
        selectedpagesize: 0,
        selectedpagenumber: 0,
      },
    },
  },
};

export const goalsListSlice = createSlice({
  name: "goalsList",
  initialState,
  reducers: {
    loading: (state) => {
      state.goalsList.loading = true;
    },
    update: (state, action: PayloadAction<ResponseGoal.AsObject[]>) => {
      state.goalsList.loading = false;
      state.goalsList.goals = action.payload;
    },
    error: (state, action: PayloadAction) => {
      state.goalsList.loading = false;
    },
    goalsListLoading: (state) => {
      state.goalsListV2.loading = true;
    },
    goalsListUpdate: (
      state,
      action: PayloadAction<GetAllGoalInfoV2ResponseForAdminV2.AsObject>
    ) => {
      state.goalsListV2.loading = false;
      state.goalsListV2.goals = action.payload;
    },
    goalsListError: (state, action: PayloadAction) => {
      state.goalsListV2.loading = false;
    },
  },
});

export const {
  loading: goalsListLoading,
  update: goalsListUpdate,
  error: goalsListError,
  goalsListLoading: goalsListLoadingV2,
  goalsListUpdate: goalsListUpdateV2,
  goalsListError: goalsListErrorV2,
} = goalsListSlice.actions;

export default combineReducers({
  goalsList: goalsListSlice.reducer,
});
