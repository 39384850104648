import React, { useState, ReactElement, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Card,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ViewFeedback from "./viewFeedback";
import QuestionsCard from "./questionscard";
import { grpc } from "@improbable-eng/grpc-web";
import {
  AwsChannelViewerCountResponse,
  ID,
} from "api/models/live-meditation-service/live-meditation_pb";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import { LIVE_MEDITATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import ViewIssue from "./viewIssue";
import moment from "moment";

interface Props {
  setcurrentIndex: any;
  sessionTitle?: string;
  sessionId: number;
  coachIdForViewQuestions: number;
  infoItem: any;
}

const MoreInfo = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      setcurrentIndex,
      sessionId,
      sessionTitle,
      coachIdForViewQuestions,
      infoItem,
    },
    ref
  ): ReactElement => {
    const [viewCount, setViewerCount] = useState<string>("0");
    const [tabValue, setTabValue] = useState<string>("Questions");

    const users: any = localStorage.getItem("user");
    const User = JSON.parse(users);

    console.log(User);

    console.log(infoItem);
    useEffect(() => {
      getDynamicLiveSessionViewerCountV2(sessionId);
      return function () {
        rpc.close();
      };
    }, [sessionId]);
    let rpc: any;
    const getDynamicLiveSessionViewerCountV2 = async (
      liveSessionId: number
    ) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(liveSessionId);
      console.log("Request", reqBody);

      rpc = grpc.invoke(
        LiveMeditationService.getDynamicLiveSessionViewerCountV2,
        {
          host: LIVE_MEDITATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onMessage: (res: AwsChannelViewerCountResponse) => {
            console.log("Response", res.toObject());
            if (res.toObject().state === "LIVE") {
              setViewerCount(res.toObject().viewercount.toString());
              console.log("viewer count: ", viewCount);
            } else if (res.toObject().state === "OFFLINE") {
              setViewerCount("STREAM OFFLINE");
            } else {
              console.log("Error in fetching viewer count");
            }
          },
          onEnd: (res: any) => {
            console.log("Stream Ended");
          },
        }
      );
    };

    const renderData = () => {
      var startTime = new Date();
      var endTime = new Date(
        infoItem.sessioninfo.scheduledtime?.seconds * 1000
      );
      var difference = endTime.getTime() - startTime.getTime();
      var resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes > 0) {
        if (resultInMinutes < 40) {
          return (
            <>
              <Typography variant="h6">
                session starting in {resultInMinutes} minutes
              </Typography>
            </>
          );
        } else {
          return (
            <>
              <Typography variant="h6">session not started yet</Typography>
            </>
          );
        }
      } else {
        return (
          <>
            <Grid container spacing={1} columnSpacing={10}>
              <Grid item xs={12} md={8}>
                <Grid container display={"flex"} sx={{}}>
                  <Grid
                    item
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                      justifyContent: "space-around",
                    }}
                  >
                    <Typography>Started at </Typography>
                    <Typography>Ended at </Typography>
                  </Grid>
                  <Grid
                    item
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                      marginLeft: "20px",
                    }}
                  >
                    <Chip
                      sx={{
                        marginBottom: "5px",
                      }}
                      label={`
                         ${moment(
                           new Date(
                             infoItem.sessioninfo.sessionstarttime?.seconds *
                               1000
                           ).toString()
                         ).format("DD MMM, hh:mm a")}`}
                    />
                    <Chip
                      label={`
                        ${moment(
                          new Date(
                            infoItem.sessioninfo.sessionendtime?.seconds * 1000
                          ).toString()
                        ).format("DD MMM, hh:mm a")}`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  justifyContent: "space-around",
                }}
              >
                <Typography>
                  Users at start - {infoItem.sessioninfo.viewercountstart}
                </Typography>
                <Typography>
                  Users at end - {infoItem.sessioninfo.viewercountend}
                </Typography>
              </Grid>
            </Grid>
            {/* <CardMedia
            component="img"
            sx={{
              marginRight: "20px",
              height: 50,
              width: 50,
              borderRadius: "0.5rem",
            }}
            image={infoItem.coachprofile?.imageurl}
          ></CardMedia>
          <ListItemText
            primary="Coach Name"
            secondary={infoItem.coachprofile?.name}
          /> */}
          </>
        );
      }
    };

    return (
      <div ref={ref}>
        <Grid
          sx={{
            display: "flex",
            padding: "10px",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <IconButton
              style={{ paddingRight: "20px" }}
              onClick={() => setcurrentIndex(0)}
              size="large"
            >
              <KeyboardBackspaceIcon color="action" />
            </IconButton>
            <Box>
              <Typography gutterBottom variant="h5" component="h2">
                {sessionTitle}
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography
                  color="inherit"
                  style={{ cursor: "pointer" }}
                  onClick={() => setcurrentIndex(0)}
                >
                  {sessionTitle}
                </Typography>
                {(User.role === 1 || User.role === 2) && (
                  <Typography color="textPrimary">{sessionId}</Typography>
                )}
                <Typography color="textPrimary">Info</Typography>
              </Breadcrumbs>
            </Box>
          </Box>
        </Grid>
        <Divider />
        <Paper sx={{ padding: "10px", margin: "10px" }} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8}>
              <Card
                sx={{
                  padding: "11px 20px",
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {renderData()}
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card
                sx={{ padding: "11px", display: "flex", alignItems: "center" }}
              >
                <ListItemIcon>
                  <LiveTvIcon fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Live Users" secondary={viewCount} />
              </Card>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "transparent" }}
            className="py-2"
          >
            <Grid item>
              <Tabs value={tabValue} onChange={(e, val) => setTabValue(val)}>
                <Tab label="Questions" value="Questions"></Tab>
                <Tab label="Feedbacks" value="Feedbacks"></Tab>
                <Tab label="Issues" value="Issues"></Tab>
              </Tabs>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            {tabValue === "Questions" && (
              <Grid item xs={12}>
                <QuestionsCard
                  sessionId={sessionId}
                  setcurrentIndex={setcurrentIndex}
                  sessionTitle={sessionTitle}
                />
              </Grid>
            )}
            {tabValue === "Feedbacks" && (
              <Grid item xs={12}>
                <ViewFeedback
                  setcurrentIndex={setcurrentIndex}
                  sessionId={sessionId}
                  sessionTitle={sessionTitle}
                  coachIdForViewQuestions={coachIdForViewQuestions}
                />
              </Grid>
            )}
            {tabValue === "Issues" && (
              <Grid item xs={12}>
                <ViewIssue
                  setcurrentIndex={setcurrentIndex}
                  sessionId={sessionId}
                  sessionTitle={sessionTitle}
                  coachIdForViewQuestions={coachIdForViewQuestions}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </div>
    );
  }
);

export default MoreInfo;
