import { styled } from "@mui/styles";

const PREFIX = "BlogsForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));