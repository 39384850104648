import React from "react";
import { ReactElement } from "react";
import {
  Typography,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/store";

import { ListSkeleton } from "components/listSkeleton";
interface Props {
  setcurrentIndex: any;
  sessionTitle?: string;
  sessionId: number;
  coachIdForViewQuestions: number;
}
const ViewQuestions = React.forwardRef<HTMLDivElement, Props>(
  (
    { setcurrentIndex, sessionId, sessionTitle, coachIdForViewQuestions },
    ref
  ): ReactElement => {
    //const dispatch = useDispatch();
    const sesionIds = useSelector(
      (state: RootState) => state.liveMeditation.sessionIdLists
    );
    console.log("sesionIds", sesionIds.Ids.livesessionidsList);

    return (
      <div ref={ref}>
        {sesionIds.loading ? (
          ListSkeleton()
        ) : (
          <div>
            {sesionIds.Ids.livesessionidsList.length ? (
              sesionIds.Ids.livesessionidsList.map(
                (item: any, index: number) => (
                  <React.Fragment key={`a${index}`}>
                    {/* <QuestionsCard sessionId={sessionId} /> */}
                  </React.Fragment>
                )
              )
            ) : (
              <Grid
                container
                style={{
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="textSecondary">
                  Not enough Data
                </Typography>
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
);
export default ViewQuestions;
