import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import React, { useState } from "react";
import { classes } from "./UploadFileContent.styles";
import { ImageOutlined } from "@mui/icons-material";
import { Crop } from "react-image-crop";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import { useFileUploadService } from "services/FileUplaodService";
import ImageCrop from "components/image-crop/image-crop";
import { RootState } from "redux/store/store";
import { useSelector } from "react-redux";
import AudioPlayer from "components/audio-player/audio-player";

const UploadFileContent = ({
  title,
  fileUrl,
  setFileUrl,
  onFileClose,
  language,
  type,
  uploadPath,
}: UploadFileContentProps) => {
  const languages = useSelector((state: RootState) => state.app.languages);

  const cropDef: Crop = {
    x: 25,
    y: 25,
    height: 20,
    width: 20,
    unit: "%",
  };
  //const [imageUrl, setImageUrl] = useState<any>(null);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [imageCrop, setImageCrop] = useState<Crop>(cropDef);
  const [imageRef, setImageRef] = useState<any>();
  const [imageFile, setImageFile] = useState<any>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [open, setOpen] = useState(false);

  const hiddenFileInputImage: any = React.useRef(null);

  const { appendFilesToUploadManager } = useFileUploadService();

  const getInputAccept = () => {
    switch (type) {
      case "image":
        return "image/*";
      case "pdf":
        return "application/pdf";
      case "audio":
        return "audio/mp3";
      case "video":
        return "video/*";

      default:
        break;
    }
  };
  const onInputChange = (event: any) => {
    switch (type) {
      case "image":
        onSelectImage(event);
        break;
      case "pdf":
        onSelectPdf(event);
        break;
      case "audio":
        onSelectAudio(event);
        break;
      case "video":
        onSelectPdf(event);
        break;

      default:
        break;
    }
  };

  const handleUploadClickImage = () => {
    hiddenFileInputImage.current.click();
  };
  const onOpenImage = () => {
    setOpen(true);
  };
  const onCloseImage = () => {
    setOpen(false);
    setImageSrc("");
    setImageCrop(cropDef);
    setIsUploading(false);
  };
  const onImageLoaded = (image: any) => {
    setImageRef(image);
  };
  const onSelectImage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading(true);
      setImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setOpen(true);
      };
    }
  };

  const onImageCropDone = async () => {
    const cr: any = imageCrop;
    if (imageRef && cr.width && cr.height) {
      const croppedUrl = await getCroppedImg(imageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
          " ",
          ""
        )}`
      );
      setFileUrl(croppedUrl);
      const uploadFile = toUploadFile(
        croppedFile,
        `${
          language === 1
            ? uploadPath
            : `${uploadPath}/${languages[language - 1].displayName
                .toLowerCase()
                .replaceAll(" ", "")}`
        }`
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setFileUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading(false);
          } else {
            setIsUploading(false);
          }
        }
      );
    }
    setImageSrc(null);
  };

  const onImageCropChange = (crop: any) => {
    setImageCrop(crop);
  };

  const _onImageClose = () => {
    onFileClose();
    setImageSrc(null);
    //deleteFileFromGcp(imageUrl);
    setFileUrl("");
    setImageFile(null);
    setImageCrop(cropDef);
    setIsUploading(false);
  };
  const onCropComplete = async () => {};
  const onSelectPdf = (event: any) => {
    const file = event.target.files[0];
    if (event.target.files[0]) {
      //setImageFile(event.target.files[0]);
      if (file && file.type === "application/pdf") {
        //setIsPdfUploading(true);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          //setPdfSrc(reader.result);
          console.log("reader.result", event.target.files[0]);
          const uploadFile = toUploadFile(
            event.target.files[0],
            `${
              language === 1
                ? uploadPath
                : `${uploadPath}/${languages[language - 1].displayName
                    .toLowerCase()
                    .replaceAll(" ", "")}`
            }`
          );
          appendFilesToUploadManager([uploadFile]);
          window.addEventListener(
            uploadFile.token,
            (res: CustomEventInit<UploadEventResponse>) => {
              if (res.detail?.code === 200) {
                setFileUrl(res.detail.response);
                console.log("pdf url res", res.detail.response);
                setIsUploading(false);
              } else {
                setIsUploading(false);
              }
            }
          );
        };
      } else {
        alert("Please select a pdf file.");
        // Show an error message or perform other actions for invalid file types
      }
    }
  };
  const onSelectAudio = async (event: any) => {
    const file = event.target.files[0];
    console.log('file type',file.type);
      if (file && file.type=== 'audio/mpeg') {
        setIsUploading(true );
        const data = new File(
          [event.target.files[0]],
          event.target.files[0].name.replaceAll(" ", "")
        );
        const uploadFile = toUploadFile(
          data,
          `${
            language === 1
              ? uploadPath
              : `${uploadPath}/${languages[language - 1].displayName
                  .toLowerCase()
                  .replaceAll(" ", "")}`
          }`
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setFileUrl(res.detail.response);
              setIsUploading(false);
            } else {
              setIsUploading(false);
            }
          }
        );
      } else {
        alert("Please select an mp3 audio file.");
        setFileUrl("");
        setIsUploading(false);
      }
  };

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <Card variant="outlined" className={classes.root}>
          {fileUrl ? (
            <>
              {type === "audio" && (
                <Card className={classes.audioPlayer}>
                  <AudioPlayer src={fileUrl} onAudioClose={onFileClose} />
                </Card>
              )}
              {type === "video" && <></>}
              {(type === "image" || type === "pdf") && (
                <PreviewProfileImage
                  onImageClose={onFileClose}
                  src={fileUrl}
                  rectangular
                  type={type}
                />
              )}
            </>
          ) : (
            <>
              {isUploading ? (
                <Card className={classes.thumbNail}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className="pt-5"
                  >
                    <Typography className="pt-4">Uploading...</Typography>
                  </Grid>
                </Card>
              ) : (
                <CardActionArea
                  className={classes.root}
                  onClick={() => {
                    handleUploadClickImage();
                  }}
                >
                  <Avatar className={classes.actionIcon} variant="square">
                    <ImageOutlined />
                  </Avatar>

                  <input
                    accept={getInputAccept()}
                    id="contained-button-file"
                    type="file"
                    hidden
                    onChange={onInputChange}
                    ref={hiddenFileInputImage}
                  />
                </CardActionArea>
              )}
            </>
          )}
        </Card>
        {type === "pdf" ? (
          <>
            {fileUrl ? (
              <Button
                onClick={() => {
                  //console.log("first", fileUrl);
                  window.open(fileUrl, "_blank");
                }}
              >
                View Pdf
              </Button>
            ) : (
              <Typography variant="overline" align="center" gutterBottom>
                Pdf
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="overline" align="center" gutterBottom>
            {title}
          </Typography>
        )}
      </FormControl>

      {type === "image" && imageSrc && (
        <ImageCrop
          src={imageSrc.toString()}
          crop={imageCrop}
          onImageLoaded={onImageLoaded}
          onCropComplete={onCropComplete}
          onCropChange={onImageCropChange}
          onPressDone={onImageCropDone}
          onImageClose={_onImageClose}
          open={open}
          onOpen={onOpenImage}
          onClose={onCloseImage}
        />
      )}
    </>
  );
};

export default UploadFileContent;
