import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { AppThunk } from "redux/store/store";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import {
  ID,
  FAQsRequest,
  FAQsResponse,
  GetAllFAQsResponse,
  Status,
  RequestPageable,
} from "api/models/gamification-service/gamification_pb";
import { setFaq, setFaqLoading } from "redux/reducers/faqreducer";

interface addFAQType {
  question: any;
  answer: any;
  screen: 0 | 1 | 2 | 3 | 4;
  setSnackBar: any;
  pageable: RequestPageable.AsObject;
}

interface updateFAQType {
  id: number;
  question: any;
  answer: any;
  screen: 0 | 1 | 2 | 3 | 4;
  setSnackBar: any;
  pageable: RequestPageable.AsObject;
}

interface deleteFAQType {
  id: number;
  setSnackBar: any;
  pageable: RequestPageable.AsObject;
}

export const getAllFAQ =
  (data: RequestPageable.AsObject): AppThunk =>
  async (dispatch) => {
    dispatch(setFaqLoading(true));
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody = new RequestPageable();
    reqBody.setPagenumber(data.pagenumber);
    reqBody.setPagesize(data.pagesize);

    grpc.unary(GamificationService.GetAllFAQs, {
      host: GAMIFICATION_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<GetAllFAQsResponse>) => {
        const { status, message } = res;
        if (status === grpc.Code.OK && message) {
          console.log(message.toObject());
          dispatch(setFaqLoading(false));
          dispatch(setFaq(message.toObject()));
        } else {
          console.log(`Error in fetching FAQs`, res);
          dispatch(setFaqLoading(false));
        }
      },
    });
  };

export const addFAQ =
  (data: addFAQType): AppThunk =>
  async (dispatch) => {
    console.log("data", data.question, data.answer);
    try {
      const question: Locale = new Locale();
      question.setDefaultcontent(data.question.defaultcontent);
      question.setEn(data.question.defaultcontent);
      question.setHi(data.question.hi);
      question.setTe(data.question.te);
      question.setGu(data.question.gu);
      question.setTa(data.question.ta);
      question.setKa(data.question.ka);
      question.setMr(data.question.mr);
      question.setIt(data.question.it);
      question.setRu(data.question.ru);
      question.setEs(data.question.es);
      question.setFr(data.question.fr);
      question.setPt(data.question.pt);
      question.setDe(data.question.de);
      question.setBn(data.question.bn);
      question.setMl(data.question.ml);
      question.setUk(data.question.uk);
      question.setOd(data.question.od);
      question.setEnUs(data.question.enUs);
      question.setEnAu(data.question.enAu);
      question.setPa(data.question.pa);

      const answer: Locale = new Locale();
      answer.setDefaultcontent(data.answer.defaultcontent.join("*"));
      answer.setEn(data.answer.defaultcontent.join("*"));
      answer.setHi(data.answer.hi.join("*"));
      answer.setTe(data.answer.te.join("*"));
      answer.setGu(data.answer.gu.join("*"));
      answer.setTa(data.answer.ta.join("*"));
      answer.setKa(data.answer.ka.join("*"));
      answer.setMr(data.answer.mr.join("*"));
      answer.setIt(data.answer.it.join("*"));
      answer.setRu(data.answer.ru.join("*"));
      answer.setEs(data.answer.es.join("*"));
      answer.setFr(data.answer.fr.join("*"));
      answer.setPt(data.answer.pt.join("*"));
      answer.setDe(data.answer.de.join("*"));
      answer.setBn(data.answer.bn.join("*"));
      answer.setMl(data.answer.ml.join("*"));
      answer.setUk(data.answer.uk.join("*"));
      answer.setOd(data.answer.od.join("*"));
      answer.setEnUs(data.answer.enUs.join("*"));
      answer.setEnAu(data.answer.enAu.join("*"));
      answer.setPa(data.answer.pa.join("*"));
      const payload = new FAQsRequest();
      payload.setFaqs(question);
      payload.setFaqsanswers(answer);
      payload.setSelectedscreen(data.screen);
      console.log("req payload", payload.toObject());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.AddFAQs, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<FAQsResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("add FAQ response", res.message.toObject());
              dispatch(
                getAllFAQ({
                  pagenumber: data.pageable.pagenumber,
                  pagesize: data.pageable.pagesize,
                })
              );
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "FAQ added successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in adding FAQ : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in add FAQ", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const updateFAQ =
  (data: updateFAQType): AppThunk =>
  async (dispatch) => {
    console.log("updateFAQ data", data.answer);
    try {
      const question: Locale = new Locale();
      question.setDefaultcontent(data.question.defaultcontent);
      question.setEn(data.question.defaultcontent);
      question.setHi(data.question.hi);
      question.setTe(data.question.te);
      question.setGu(data.question.gu);
      question.setTa(data.question.ta);
      question.setKa(data.question.ka);
      question.setMr(data.question.mr);
      question.setIt(data.question.it);
      question.setRu(data.question.ru);
      question.setEs(data.question.es);
      question.setFr(data.question.fr);
      question.setPt(data.question.pt);
      question.setDe(data.question.de);
      question.setBn(data.question.bn);
      question.setMl(data.question.ml);
      question.setUk(data.question.uk);
      question.setOd(data.question.od);
      question.setEnUs(data.question.enUs);
      question.setEnAu(data.question.enAu);
      question.setPa(data.question.pa);

      const answer: Locale = new Locale();
      answer.setDefaultcontent(data.answer.defaultcontent.join("*"));
      answer.setEn(data.answer.defaultcontent.join("*"));
      answer.setHi(data.answer.hi ? data.answer.hi.join("*") : data.answer.hi);
      answer.setTe(data.answer.te ? data.answer.te.join("*") : data.answer.te);
      answer.setGu(data.answer.gu ? data.answer.gu.join("*") : data.answer.te);
      answer.setTa(data.answer.ta ? data.answer.ta.join("*") : data.answer.ta);
      answer.setKa(data.answer.ka ? data.answer.ka.join("*") : data.answer.ka);
      answer.setMr(data.answer.mr.join("*"));
      answer.setIt(data.answer.it.join("*"));
      answer.setRu(data.answer.ru.join("*"));
      answer.setEs(data.answer.es.join("*"));
      answer.setFr(data.answer.fr.join("*"));
      answer.setPt(data.answer.pt.join("*"));
      answer.setDe(data.answer.de.join("*"));
      answer.setBn(data.answer.bn.join("*"));
      answer.setMl(data.answer.ml.join("*"));
      answer.setUk(data.answer.uk.join("*"));
      answer.setOd(data.answer.od.join("*"));
      answer.setEnUs(data.answer.enUs.join("*"));
      answer.setEnAu(data.answer.enAu.join("*"));
      answer.setPa(data.answer.pa.join("*"));

      const payload = new FAQsResponse();
      payload.setId(data.id);
      payload.setFaqs(question);
      payload.setFaqsanswers(answer);
      payload.setSelectedscreen(data.screen);
      console.log("req payload", payload.toObject());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.UpdateFAQs, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<FAQsResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("update FAQ response", res.message.toObject());
              dispatch(
                getAllFAQ({
                  pagenumber: data.pageable.pagenumber,
                  pagesize: data.pageable.pagesize,
                })
              );
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "FAQ updated successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in updated FAQ : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in update FAQ", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const deleteFAQ =
  (data: deleteFAQType): AppThunk =>
  async (dispatch) => {
    try {
      const payload = new ID();
      payload.setId(data.id);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.DeleteFAQsByID, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("delte FAQ response", res.message.toObject());
              dispatch(
                getAllFAQ({
                  pagenumber: data.pageable.pagenumber,
                  pagesize: data.pageable.pagesize,
                })
              );
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "FAQ deleted successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in deleting FAQ : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in delete FAQ", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };
