import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Alert,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { firebaseAuth } from "config/firebase";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Root } from "./archive.style";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogBox from "components/dialogBox/dialog";

interface EventProps {
  isEditing: any;
  editItem: any;
  onClickBack: any;
  onClickEdit: any;
  setSnackBar: any;
  snackBar: any;
  onChangeEventTitle: any;
  isLoading: boolean;
  eventTitle: string;
  subEvent: any;
  onClickAddSubEvent: any;
  onClickDeleteSubEvent: any;
  onChangeSubEventName: any;
  onChangeSubEventUrl: any;
  onClickAddEvent:any;
  onClickDelete:any;
  handleDeleteClose:any;
  deleteSubEvent:any;
  showDeleteModal:boolean;
}

export const ArchiveForm: any = React.forwardRef<HTMLDivElement, EventProps>(
  (
    {
      isEditing,
      editItem,
      onClickBack,
      onClickEdit,
      setSnackBar,
      snackBar,
      onChangeEventTitle,
      isLoading,
      eventTitle,
      subEvent,
      onClickAddSubEvent,
      onClickDeleteSubEvent,
      onChangeSubEventName,
      onChangeSubEventUrl,
      onClickAddEvent,
      onClickDelete,
      handleDeleteClose,
      deleteSubEvent,
      showDeleteModal
    },
    ref
  ): ReactElement => {
    // const initialState = {
    //   editing: false,
    //   editItem: null,
    //   showDeleteModal: false,
    //   deleteItemId: 0,
    // };

    useEffect(() => {
      if (isEditing) {
        console.log("Edit event details: ", editItem);
        onClickEdit();
      }
    }, [isEditing, editItem]);

    return (
      <Root ref={ref}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          style={{
            padding: " 8px",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Grid container className={isEditing ? "" : "align-items-center"}>
              <Grid item>
                <IconButton onClick={onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {isEditing ? "Edit Sub Event" : "  Create Event"}
                </Typography>
                {isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={onClickBack}
                    >
                      events
                    </Typography>
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={() => null}
                    >
                      {editItem.subeventname}
                    </Typography>
                    <Typography color="textPrimary">edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={onClickDelete}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="eventName"
                  label="Event Name"
                  variant="outlined"
                  onChange={onChangeEventTitle}
                  value={eventTitle}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Card variant="outlined">
                <Typography color="textSecondary" className="pl-3 pt-3">
                  Sub Events
                </Typography>
                <CardContent>
                  <Grid container spacing={3}>
                    {(subEvent.length === 0
                      ? [{ name: "",url:"" }]
                      : subEvent
                    ).map((item: any, index: any) => (
                      <Grid
                        item
                        xs={12}
                        key={index}
                        className="d-flex align-items-start mt-2"
                        
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label=" name"
                              name="names"
                              variant="outlined"
                              multiline
                              fullWidth
                              required={!index}
                            //   rows={1}
                            //   maxRows={20}
                              inputProps={{
                                readOnly: isEditing ? true : false,
                                style: {
                                  fontSize: 14,
                                },
                              }}
                              value={item.name}
                              onChange={(e:any) => {
                                onChangeSubEventName(e,index);
                              }}
                              style={{ flexGrow: 1, marginRight: "10px" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="url"
                              name="url"
                              variant="outlined"
                              fullWidth
                             // multiline
                              required={!index}
                            //   rows={1}
                            //   maxRows={20}
                              inputProps={{
                                readOnly: isEditing ? true : false,
                                style: {
                                  fontSize: 14,
                                },
                              }}
                              value={item.url}
                              onChange={(e) => {
                                onChangeSubEventUrl(e,index);
                              }}
                              style={{ flexGrow: 1, marginRight: "10px" }}
                            />
                          </Grid>
                        </Grid>

                        <Grid>
                          {index ? (
                            <IconButton
                              onClick={() => onClickDeleteSubEvent(index)}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              style={{ visibility: "hidden" }}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  {/* {type === "copy" ? null : ( */}
                  <Grid className="mt-3">
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ textTransform: "none" }}
                      onClick={onClickAddSubEvent}
                    >
                      Add New
                    </Button>
                  </Grid>
                  {/* )} */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className=" p-2 d-flex justify-content-end">
          <Box display="flex" justifyContent="flex-end">
            {isEditing ? (
              <>
                <Button
                  onClick={onClickBack}
                  color="primary"
                  variant="contained"
                  className="mr-2"
                  disabled
                >
                  Cancel
                </Button>
                <Button
                  //onClick={onClickUpdateEvent}
                  color="primary"
                  //disabled={isAdding}
                  variant="contained"
                  disabled
                >
                  Update Event
                </Button>
              </>
            ) : (
              <Button
                onClick={onClickAddEvent}
                color="primary"
                //disabled={isAdding}
                variant="contained"
              >
                Add Event
              </Button>
            )}
          </Box>
        </CardActions>
        <DialogBox
        open={showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={deleteSubEvent}
        type={"delete"}
        title={`Sure !! Want to delete this video ?`}
      />
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
