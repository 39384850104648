import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactElement, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBadgesAsync } from "redux/actions/dashboardActions";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ImageOutlined } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import ImageCrop from "components/image-crop/image-crop";
import PreviewProfileImage from "../../../components/image-preview/profileImagePreview";
import { firebaseAuth } from "config/firebase";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { useFileUploadService } from "services/FileUplaodService";
import DialogBox from "components/dialogBox/dialog";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import DeleteIcon from "@mui/icons-material/Delete";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import {
  AddNewBadgeInfoRequest,
  BadgeDetails,
  DeleteBadgeDetailsByIdResponse,
  GetBadgeByIdRequest,
} from "api/models/gamification-service/Badge_pb";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { RootState } from "redux/store/store";

const PREFIX = "MeditationForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
    //overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

interface FormProps {
  isEditing?: boolean;
  editItem?: any;
  onClickBack: any;
  onClickBadges: any;
  lang:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21;
}

interface Crop {
  unit: "%" | "px" | undefined;
  width: number;
  aspect: number;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number;
}

interface badge {
  id: any;
  name: any;
  icon: any;
  badgedescription: any;
  badgecolor: any;
}

interface AddNewBadgeInfoData {
  id: number;
  name: any;
  icon: string;
  badgeDescription: any;
  badgeColor: string;
}

const CreateBadge: any = React.forwardRef<HTMLDivElement, FormProps>(
  (props, ref): ReactElement => {
    const dispatch = useDispatch();
    const { appendFilesToUploadManager } = useFileUploadService();

    const cropDef: Crop = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };

    const initialState = {
      editing: false,
      editItem: null,
      showDeleteModal: false,
      deleteItemId: 0,
    };

    const hiddenFileInput: any = React.useRef(null);
    const [states, setStates] = useState<State>(initialState);
    const [name, setName] = useState("");
    const [badgeDescription, setBadgeDescription] = useState("");
    const [icon, setIcon] = useState("");
    const [badgeColor, setBadgeColor] = useState("");
    const [imageRef, setImageRef] = useState<any>();
    const [src, setSrc] = useState<any>(null);
    const [crop, setCrop] = useState<Crop>(cropDef);
    const [imageFile, setImageFile] = useState<any>(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState<any>(null);
    const [croppedImage, setCroppedImage] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setopen] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const [isUploading, setIsUploading] = useState({
      image: false,
      selectedImage: false,
      audio: false,
    });

    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
              }
            } else {
              setIsLoading(false);
            }
          },
        });
      } catch (err) {
        console.log("Error ", err);
      }
    };

    const _onOpenImage = () => {
      setopen(true);
    };
    const _onCloseImage = () => {
      setopen(false);
      setIsUploading({ ...isUploading, image: false, selectedImage: false });
    };

    // --------------------------image upload--------------------------------------->
    const handleUploadClick = () => {
      hiddenFileInput.current.click();
    };
    const onSelectImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading({ ...isUploading, image: true });
        setImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };

    const onCropDone = async () => {
      const cr: any = crop;
      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        setCroppedImageUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          "badges".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setCroppedImageUrl(res.detail.response);
              setIcon(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading({ ...isUploading, image: false });
            } else {
              setIsUploading({ ...isUploading, image: false });
            }
          }
        );
        setCroppedImage(croppedFile);
      }
      setSrc(null);
    };

    const onCropChange = (crop: any) => {
      setCrop(crop);
    };
    const onCropComplete = async () => {};
    const onImageClose = () => {
      setSrc(null);
      setImageFile(null);
      setCroppedImage(null);
      setCrop(cropDef);
      _deleteFileFromGcp(croppedImageUrl);
      setCroppedImageUrl("");
    };

    const validateForm = () => {
      if (name.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: " Name can not be empty",
        });
      } else if (badgeDescription.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Badge Description can not be empty",
        });
      } else if (icon === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Icon can not be empty",
        });
      } else {
        return true;
      }
    };

    const addNewBadgeInfo = async (data: AddNewBadgeInfoData) => {
      try {
        console.log("Badge data : ", data);
        const addNewBadgeInfoReq: AddNewBadgeInfoRequest =
          new AddNewBadgeInfoRequest();
        const nameLocale: Locale = new Locale();
        nameLocale.setDefaultcontent(data.name.defaultContent);
        nameLocale.setEn(data.name.en);
        nameLocale.setHi(data.name.hi);
        nameLocale.setTe(data.name.te);
        nameLocale.setGu(data.name.gu);
        nameLocale.setTa(data.name.ta);
        nameLocale.setKa(data.name.ka);
        nameLocale.setMr(data.name.mr);
        nameLocale.setIt(data.name.it);
        nameLocale.setRu(data.name.ru);
        nameLocale.setEs(data.name.es);
        nameLocale.setFr(data.name.fr);
        nameLocale.setPt(data.name.pt);
        nameLocale.setDe(data.name.de);
        nameLocale.setBn(data.name.bn);
        nameLocale.setMl(data.name.ml);
        nameLocale.setUk(data.name.uk);
        nameLocale.setOd(data.name.od);
        nameLocale.setEnUs(data.name.enUs);
        nameLocale.setEnAu(data.name.enAu);
        nameLocale.setPa(data.name.pa);

        const badgeDescriptionLocale: Locale = new Locale();
        badgeDescriptionLocale.setDefaultcontent(
          data.badgeDescription.defaultContent
        );
        badgeDescriptionLocale.setEn(data.badgeDescription.en);
        badgeDescriptionLocale.setHi(data.badgeDescription.hi);
        badgeDescriptionLocale.setTe(data.badgeDescription.te);
        badgeDescriptionLocale.setGu(data.badgeDescription.gu);
        badgeDescriptionLocale.setTa(data.badgeDescription.ta);
        badgeDescriptionLocale.setKa(data.badgeDescription.ka);
        badgeDescriptionLocale.setMr(data.badgeDescription.mr);
        badgeDescriptionLocale.setIt(data.badgeDescription.it);
        badgeDescriptionLocale.setRu(data.badgeDescription.ru);
        badgeDescriptionLocale.setEs(data.badgeDescription.es);
        badgeDescriptionLocale.setFr(data.badgeDescription.fr);
        badgeDescriptionLocale.setPt(data.badgeDescription.pt);
        badgeDescriptionLocale.setDe(data.badgeDescription.de);
        badgeDescriptionLocale.setBn(data.badgeDescription.bn);
        badgeDescriptionLocale.setMl(data.badgeDescription.ml);
        badgeDescriptionLocale.setUk(data.badgeDescription.uk);
        badgeDescriptionLocale.setOd(data.badgeDescription.od);
        badgeDescriptionLocale.setEnUs(data.badgeDescription.enUs);
        badgeDescriptionLocale.setEnAu(data.badgeDescription.enAu);
        badgeDescriptionLocale.setPa(data.badgeDescription.pa);

        addNewBadgeInfoReq.setName(nameLocale);
        addNewBadgeInfoReq.setBadgedescription(badgeDescriptionLocale);
        addNewBadgeInfoReq.setIcon(data.icon);
        addNewBadgeInfoReq.setBadgecolor(data.badgeColor);

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        console.log("Request for badge  ", addNewBadgeInfoReq.toObject());

        grpc.unary(GamificationService.AddNewBadgeInfo, {
          request: addNewBadgeInfoReq,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<BadgeDetails>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              console.log("All OK : ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: "Badge added successfully",
              });
              // emptyData();
              setTimeout(() => {
                props.onClickBack();
              }, 2000);
              dispatch(getBadgesAsync(props.lang));
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in adding new badge",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in addNewBadgeInfo",
        });
        console.log("Something wrong with badge data", error);
      }
    };

    const _addNewBadgeInfo = () => {
      var newBadgeInfoData: any = {};
      if (validateForm()) {
        switch (props.lang) {
          case 1:
            newBadgeInfoData = {
              name: {
                defaultContent: name,
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: badgeDescription,
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 2:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: name,
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: badgeDescription,
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 3:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: name,
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: badgeDescription,
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 4:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: name,
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: badgeDescription,
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 5:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: name,
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: badgeDescription,
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 6:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: name,
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: badgeDescription,
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 7:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: name,
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: badgeDescription,
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 8:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: name,
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: badgeDescription,
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 9:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: name,
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: badgeDescription,
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 10:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: name,
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: badgeDescription,
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 11:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: name,
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: badgeDescription,
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 12:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: name,
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: badgeDescription,
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 13:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: name,
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: badgeDescription,
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 14:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: name,
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: badgeDescription,
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 15:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: name,
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: badgeDescription,
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 16:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: name,
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: badgeDescription,
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 17:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: name,
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: badgeDescription,
                od: "",
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 18:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: name,
                enUs: "",
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: badgeDescription,
                enUs: "",
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 19:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: name,
                enAu: "",
pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: badgeDescription,
                enAu: "",
pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
          case 20:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: name,
				pa: "",
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: badgeDescription,
				pa: "",
              },
              badgeColor: badgeColor,
            };
            break;
			case 21:
            newBadgeInfoData = {
              name: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
				pa: name,
              },
              icon: icon,
              badgeDescription: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
				pa: badgeDescription,
              },
              badgeColor: badgeColor,
            };
            break;
        }

        setIsAdding(true);
        addNewBadgeInfo(newBadgeInfoData);
      }
    };

    return (
      <Root ref={ref} className={classes.root}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid>
                <Typography variant="h6">
                  {states.editing ? "Edit Gem" : "Add New Gem"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                label="Badge Name"
                name="name"
                required
                value={name}
                variant="outlined"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <TextField
                fullWidth
                type="text"
                multiline
                minRows={2}
                label="Badge Description"
                name="badgeDescription"
                required
                value={badgeDescription}
                variant="outlined"
                onChange={(e) => {
                  setBadgeDescription(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined">
                  {croppedImageUrl ? (
                    <PreviewProfileImage
                      onImageClose={onImageClose}
                      src={croppedImageUrl}
                      file={croppedImage}
                    />
                  ) : (
                    <>
                      {isUploading.image ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.root}
                          onClick={() => {
                            handleUploadClick();
                          }}
                        >
                          {/* <InputLabel htmlFor="thumbnail">
                            Upload image
                          </InputLabel> */}
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <ImageOutlined />
                          </Avatar>
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            hidden
                            onChange={onSelectImage}
                            ref={hiddenFileInput}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Image
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              _addNewBadgeInfo();
              dispatch(getBadgesAsync(props.lang));
            }}
            disabled={isAdding}
          >
            Add Badge
          </Button>
        </Box>
        {src && (
          <ImageCrop
            src={src.toString()}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onCropComplete={onCropComplete}
            onCropChange={onCropChange}
            onPressDone={onCropDone}
            onImageClose={onImageClose}
            open={open}
            onOpen={_onOpenImage}
            onClose={_onCloseImage}
          />
        )}
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);

export const UpdateBadge: any = React.forwardRef<HTMLDivElement, FormProps>(
  (props, ref): ReactElement => {
    const languages = useSelector((state: RootState) => state.app.languages);
    const dispatch = useDispatch();
    const { appendFilesToUploadManager } = useFileUploadService();

    const cropDef: Crop = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };

    const initialState = {
      editing: false,
      editItem: null,
      showDeleteModal: false,
      deleteItemId: 0,
    };

    const hiddenFileInput: any = React.useRef(null);
    const [language, setLanguage] = useState<
      | 0
      | 1
      | 2
      | 3
      | 4
      | 5
      | 6
      | 7
      | 8
      | 9
      | 10
      | 11
      | 12
      | 13
      | 14
      | 15
      | 16
      | 17
      | 18
      | 19
      | 20
	  | 21
    >(props.lang);
    const [states, setStates] = useState<State>(initialState);
    const [name, setName] = useState("");
    const [badgeDescription, setBadgeDescription] = useState("");
    const [icon, setIcon] = useState<any>("");
    const [badgeColor, setBadgeColor] = useState("");
    const [imageRef, setImageRef] = useState<any>();
    const [src, setSrc] = useState<any>(null);
    const [crop, setCrop] = useState<Crop>(cropDef);
    const [imageFile, setImageFile] = useState<any>(null);
    const [id, setid] = useState<any>(null);
    const [croppedImage, setCroppedImage] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setopen] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const [isUploading, setIsUploading] = useState({
      image: false,
      selectedImage: false,
      audio: false,
    });

    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const onClickEdit = useCallback(
      (item: badge) => {
        console.log("Edit badge details: ", item);

        setStates({ editing: true });
        setName(item.name);
        setBadgeDescription(item.badgedescription);
        setIcon(item.icon);
        setBadgeColor(item.badgecolor);
        setid(item.id);
      },
      [language]
    );

    useEffect(() => {
      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
    }, [props.isEditing, props.editItem, onClickEdit]);

    useEffect(() => {}, [props.editItem]);

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
              }
            } else {
              setIsLoading(false);
            }
          },
        });
      } catch (err) {
        console.log("Error ", err);
      }
    };

    const _onOpenImage = () => {
      setopen(true);
    };
    const _onCloseImage = () => {
      setopen(false);
      setIsUploading({ ...isUploading, image: false, selectedImage: false });
    };

    // --------------------------image upload--------------------------------------->
    const handleUploadClick = () => {
      hiddenFileInput.current.click();
    };
    const onSelectImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading({ ...isUploading, image: true });
        setImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };

    const onCropDone = async () => {
      const cr: any = crop;
      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        setIcon(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          "badges".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setIcon(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading({ ...isUploading, image: false });
            } else {
              setIsUploading({ ...isUploading, image: false });
            }
          }
        );
        setCroppedImage(croppedFile);
      }
      setSrc(null);
    };

    const onCropChange = (crop: any) => {
      setCrop(crop);
    };
    const onCropComplete = async () => {};
    const onImageClose = () => {
      setSrc(null);
      setImageFile(null);
      setCroppedImage(null);
      setCrop(cropDef);
      _deleteFileFromGcp(props.editItem.icon);
      setIcon("");
    };

    const validateForm = () => {
      if (name.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: " Name can not be empty",
        });
      } else if (badgeDescription.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Badge Description can not be empty",
        });
      } else if (icon === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Icon can not be empty",
        });
      } else {
        return true;
      }
    };

    const emptyData = () => {
      setName("");
      setBadgeDescription("");
      setIcon("");
      setBadgeColor("");
    };

    const decideLocale = () => {
      // Create default description object with empty strings
      const defaultDescription = languages.reduce((acc, lang) => {
        if (lang.name === 'de') return acc;
        return {
          ...acc,
          [lang.name as string]: '',
        };
      }, {});

      // Define language code mapping dynamically from appReducer languages
      const languageMap: { [key: number]: keyof typeof defaultDescription } = 
        languages.reduce((acc, lang) => ({
          ...acc,
          [lang.code]: lang.name as keyof typeof defaultDescription
        }), {});

      // Get the target language code
      const targetLang = languageMap[language];
      
      if (!targetLang) {
        console.warn(`Unsupported language code: ${language}`);
        return { description: defaultDescription };
      }

      // Set the description for the target language
      return {
        reqName: {
          ...defaultDescription,
          [targetLang]: name,
          defaultContent: language === 1 
            ? name 
            : props.editItem.name.defaultcontent
        },
        reqBadgeDescription: {
          ...defaultDescription,
          [targetLang]: badgeDescription,
          defaultContent: language === 1 
            ? badgeDescription 
            : props.editItem.badgedescription.defaultcontent
        },
      };
    };

    const updateNewBadgeInfo = async (data: AddNewBadgeInfoData) => {
      console.log("Update new badge request, ", data);
      try {
        const nameLocale: Locale = new Locale();
        nameLocale.setDefaultcontent(data.name.defaultContent);
        nameLocale.setEn(data.name.en);
        nameLocale.setHi(data.name.hi);
        nameLocale.setTe(data.name.te);
        nameLocale.setGu(data.name.gu);
        nameLocale.setTa(data.name.ta);
        nameLocale.setKa(data.name.ka);
        nameLocale.setMr(data.name.mr);
        nameLocale.setIt(data.name.it);
        nameLocale.setRu(data.name.ru);
        nameLocale.setEs(data.name.es);
        nameLocale.setFr(data.name.fr);
        nameLocale.setPt(data.name.pt);
        nameLocale.setDe(data.name.de);
        nameLocale.setBn(data.name.bn);
        nameLocale.setMl(data.name.ml);
        nameLocale.setUk(data.name.uk);
        nameLocale.setOd(data.name.od);
        nameLocale.setEnUs(data.name.enUs);
        nameLocale.setEnAu(data.name.enAu);
        nameLocale.setPa(data.name.pa);

        const badgeDescriptionLocale: Locale = new Locale();
        badgeDescriptionLocale.setDefaultcontent(
          data.badgeDescription.defaultContent
        );
        badgeDescriptionLocale.setEn(data.badgeDescription.en);
        badgeDescriptionLocale.setHi(data.badgeDescription.hi);
        badgeDescriptionLocale.setTe(data.badgeDescription.te);
        badgeDescriptionLocale.setGu(data.badgeDescription.gu);
        badgeDescriptionLocale.setTa(data.badgeDescription.ta);
        badgeDescriptionLocale.setKa(data.badgeDescription.ka);
        badgeDescriptionLocale.setMr(data.badgeDescription.mr);
        badgeDescriptionLocale.setIt(data.badgeDescription.it);
        badgeDescriptionLocale.setRu(data.badgeDescription.ru);
        badgeDescriptionLocale.setEs(data.badgeDescription.es);
        badgeDescriptionLocale.setFr(data.badgeDescription.fr);
        badgeDescriptionLocale.setPt(data.badgeDescription.pt);
        badgeDescriptionLocale.setDe(data.badgeDescription.de);
        badgeDescriptionLocale.setBn(data.badgeDescription.bn);
        badgeDescriptionLocale.setMl(data.badgeDescription.ml);
        badgeDescriptionLocale.setUk(data.badgeDescription.uk);
        badgeDescriptionLocale.setOd(data.badgeDescription.od);
        badgeDescriptionLocale.setEnUs(data.badgeDescription.enUs);
        badgeDescriptionLocale.setEnAu(data.badgeDescription.enAu);
        badgeDescriptionLocale.setPa(data.badgeDescription.pa);

        const updateNewBadgeInfoReq = new BadgeDetails();
        updateNewBadgeInfoReq.setName(nameLocale);
        updateNewBadgeInfoReq.setBadgedescription(badgeDescriptionLocale);
        updateNewBadgeInfoReq.setBadgecolor(data.badgeColor);
        updateNewBadgeInfoReq.setIcon(data.icon);
        updateNewBadgeInfoReq.setId(data.id);

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        console.log("payload", updateNewBadgeInfoReq.toObject());

        grpc.unary(GamificationService.UpdateBadgeInfoById, {
          request: updateNewBadgeInfoReq,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<BadgeDetails>) => {
            const { status, message } = res;
            console.log("Message", message?.toObject());
            if (status === grpc.Code.OK && message) {
              setSnackBar({
                open: true,
                severity: "success",
                message: "Badge updated successfully",
              });
              emptyData();
              setTimeout(() => {
                props.onClickBack();
              }, 500);
              dispatch(getBadgesAsync(language));
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in updating badge",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in updateNewBadgeInfo",
        });
        console.log("Something wrong with badge data", error);
      }
    };

    const _updateNewBadgeInfo = () => {
      if (validateForm()) {
        const newBadgeInfoData: any = {
          id: id,
          name: decideLocale()?.reqName,
          badgeDescription: decideLocale()?.reqBadgeDescription,
          icon: icon,
          badgeColor: badgeColor,
        };

        setIsAdding(true);
        updateNewBadgeInfo(newBadgeInfoData);
      }
    };

    const onClickDelete = (badgeId: number) => {
      setStates({ showDeleteModal: true, deleteItemId: badgeId });
    };

    const handleDeleteClose = () => {
      setStates({ showDeleteModal: false });
    };

    const deleteBadge = async () => {
      deleteBadgeById(Number(states.deleteItemId));
    };

    const deleteBadgeById = async (badgeId: number) => {
      setIsLoading(true);
      console.log("Delete API for badge id", badgeId);
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: GetBadgeByIdRequest = new GetBadgeByIdRequest();
        reqBody.setId(badgeId);
        grpc.unary(GamificationService.DeleteBadgeDetailsById, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<DeleteBadgeDetailsByIdResponse>) => {
            const { status, message } = res;

            if (status === grpc.Code.OK && message) {
              if (message) {
                setIsLoading(false);
                console.log("data coming", message.toObject().message);
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: "badge deleted successfully",
                });

                _deleteFileFromGcp(props.editItem.icon);
                setStates({ showDeleteModal: false });
                setTimeout(() => props.onClickBack(), 2000);
                dispatch(getBadgesAsync(language));
              }
            } else {
              setIsLoading(false);
              setStates({ showDeleteModal: false });
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in  deleting badge",
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    return (
      <Root ref={ref} className={classes.root}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid>
                <Typography variant="h6">Gem</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              disabled={id === 7}
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => onClickDelete(props.editItem.id)}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={10} xs={12}>
              <TextField
                fullWidth
                type="text"
                label="Badge Name"
                name="name"
                required
                value={name}
                variant="outlined"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                fullWidth
                type="text"
                multiline
                minRows={2}
                label="Badge Description"
                name="badgeDescription"
                required
                value={badgeDescription}
                variant="outlined"
                onChange={(e) => {
                  setBadgeDescription(e.target.value);
                }}
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined">
                  {icon ? (
                    <PreviewProfileImage
                      onImageClose={onImageClose}
                      src={icon}
                      file={croppedImage}
                    />
                  ) : (
                    <>
                      {isUploading.image ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.root}
                          onClick={() => {
                            handleUploadClick();
                          }}
                        >
                          {/* <InputLabel htmlFor="thumbnail">
                            Upload image
                          </InputLabel> */}
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <ImageOutlined />
                          </Avatar>
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            hidden
                            onChange={onSelectImage}
                            ref={hiddenFileInput}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Image
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            disabled={isAdding}
            onClick={() => {
              _updateNewBadgeInfo();
            }}
          >
            Update Badge
          </Button>
        </Box>
        {src && (
          <ImageCrop
            src={src.toString()}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onCropComplete={onCropComplete}
            onCropChange={onCropChange}
            onPressDone={onCropDone}
            onImageClose={onImageClose}
            open={open}
            onOpen={_onOpenImage}
            onClose={_onCloseImage}
          />
        )}
        <DialogBox
          open={states.showDeleteModal || false}
          onClose={handleDeleteClose}
          onCancel={handleDeleteClose}
          onConfirm={deleteBadge}
          type={"delete"}
          title={`Sure !! Want to delete this Item ?`}
        />
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);

export default CreateBadge;
