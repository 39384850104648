import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import {
  Typography,
  Grid,
  Box,
  Card,
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import style from "./index.module.scss";
import { grpc } from "@improbable-eng/grpc-web";
import {
  RequestQuestionBySessionId,
  ListOfQuestion,
} from "api/models/live-meditation-service/live-meditation_pb";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import { LIVE_MEDITATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import InfoCard from "./infoCard";
import moment from "moment";

interface Props {
  sessionTitle?: string;
  sessionId: number;
  setcurrentIndex: (a: number) => void;
}

const QuestionsCard = React.forwardRef<HTMLDivElement, Props>(
  ({ sessionId, sessionTitle, setcurrentIndex }, ref): ReactElement => {
    console.log("id", sessionId);

    const [pageNumber, setpageNumber] = useState(0);
    const [pageSize, setpageSize] = useState(10);

    const [liveQuestionsList, setLiveQuestionsList] = useState<any>([]);

    // useEffect(() => {
    //   dispatch(getAllQuestionBySessionID(sessionId, pageSize, pageNumber));
    // }, [dispatch, sessionId, pageSize, pageNumber]);

    useEffect(() => {
      getAllDynamicUserQuestionBySessionIDV2(sessionId, pageSize, pageNumber);
      return function () {
        rpc.close();
      };
    }, []);
    let rpc: any;
    const getAllDynamicUserQuestionBySessionIDV2 = async (
      liveSessionId: number,
      pageSize: number,
      pageNumber: number
    ) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestQuestionBySessionId =
        new RequestQuestionBySessionId();
      reqBody.setLivesessionid(liveSessionId);
      reqBody.setPagenumber(pageNumber);
      reqBody.setPagesize(pageSize);

      rpc = grpc.invoke(
        LiveMeditationService.GetAllDynamicUserQuestionBySessionIDV2,
        {
          host: LIVE_MEDITATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },

          onMessage: (res: ListOfQuestion) => {
            setLiveQuestionsList(res.toObject().questionList);
            console.log("message recieved", res.toObject());
          },

          onEnd: (res: any) => {
            const { status, message } = res;
            // console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from get all user question",
                  message.toObject()
                );
                // const assembledPayload = {
                //   sessionId: liveSessionId,
                //   sessionQuestions: message.toObject(),
                // };
              }
            }
          },
        }
      );
    };
    const contactDetails = (item) => {
      if (item.mobilenumber && item.email) {
        return `Number:${item.mobilenumber}, Email:${item.email}`;
      } else {
        return item.mobilenumber
          ? `Number:${item.mobilenumber}`
          : item.email
          ? `Email:${item.email}`
          : "";
      }
    };

    const renderQuestionsList = () => {
      //console.log('quesinlists',state.questionList.questions.questionList);
      return (
        <Box
          height="50vh"
          style={{ overflow: "auto" }}
          className={style.scrollbar}
        >
          {liveQuestionsList.length > 0 ? (
            <Box>
              {liveQuestionsList.map((item: any, index: any) => (
                <Card key={index} sx={{ marginX: "25px", marginY: "10px" }}>
                  <ListItem
                    sx={{
                      display: "flex",
                    }}
                  >
                    <ListItemIcon>
                      <Avatar sx={{ backgroundColor: "#ffb875" }} src="" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "body2",
                      }}
                      primary={
                        item.username === ""
                          ? `<Anonymous User>`
                          : item.username
                      }
                      secondary={contactDetails(item)}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="right"
                      sx={{
                        fontStyle: "italic",
                        alignSelf: "center",
                      }}
                    >
                      {moment(
                        new Date(
                          (Number(item.createdat?.seconds) +
                            Number(item.createdat?.nanos) * 10 ** -9) *
                            1000
                        ).toString()
                      ).format("DD MMM, hh:mm a")}
                    </Typography>
                  </ListItem>
                  {item.questiontext && (
                    <ListItem>
                      <Typography variant="body2" color="textSecondary">
                        {item.questiontext}
                      </Typography>
                    </ListItem>
                  )}
                </Card>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                fontWeight: 400,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="text.secondary">
                There are no questions at this time
              </Typography>
            </Box>
          )}
        </Box>
      );
    };
    return (
      <div ref={ref}>
        {
          <Card sx={{ minWidth: "100%" }}>
            <Grid
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                marginBottom: "2px",
                boxShadow: 2,
              }}
              className="d-flex p-3 px-4 align-items-start"
            >
              <Typography variant="h6">Questions</Typography>
            </Grid>
            {renderQuestionsList()}
          </Card>
        }
      </div>
    );
  }
);
export default QuestionsCard;
