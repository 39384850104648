import { ReactElement, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  //createStyles,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getBadgesAsync } from "redux/actions/dashboardActions";
import { RootState } from "redux/store/store";
import { Badge } from "api/models/gamification-service/Badge_pb";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { ListSkeleton } from "components/listSkeleton";
import RefreshIcon from "@mui/icons-material/Refresh";
import CreateBadge, { UpdateBadge } from "./createBadge";
import { MenuProps } from "styles/global.styles";

interface State {
  badgesList?: Array<any>;
  loadingBadges?: boolean;
  editing?: boolean;
  loadingImage?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number;
}

export default function DashboardScreen(): ReactElement {
  const dispatch = useDispatch();
  const badgeListState = useSelector(
    (state: RootState) => state.dashboard.badgesList
  );
  const languages = useSelector((state: RootState) => state.app.languages);
  // const hiddenFileInput: any = React.useRef(null);
  // const initialState = {
  //   badgeNameLang: languages[0],
  // };

  const initialState = {
    loadingBadges: false,
    editing: false,
    loadingImage: false,
    editItem: null,
    showDeleteModal: false,
    deleteItemId: 0,
  };

  // const [state, setState] = useState(initialState);
  const [language, setLanguage] = useState<any>(1);
  const [currentScreen, setcurrentScreen] = useState(1);
  const [searchBadges, setsearchBadges] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [states, setStates] = useState<State>(initialState);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getBadgesAsync(language));
  }, [dispatch, language, setcurrentScreen]);

  const onClickBack = () => {
    setcurrentScreen(1);
    setStates({
      editing: false,
      editItem: null,
    });
  };

  const onClickBadges = () => {
    setcurrentScreen(3);
    setStates({
      editing: false,
      editItem: null,
    });
  };

  const renderBadgeList = (badges: Badge.AsObject[]) => (
    <>
      {badges.length ? (
        <>
          <TableContainer
            style={{ maxHeight: "400px" }}
            // className={style.scrollbar}
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell>Gems</TableCell>
                  <TableCell align="left"> Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchBadges !== "" ? searchData : badges)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          sx={{ cursor: "pointer" }}
                          hover
                          onClick={() => {
                            setStates({
                              editing: true,
                              editItem: item,
                            });
                            // executeScroll();
                            setcurrentScreen(3);
                          }}
                        >
                          <TableCell align="center">
                            <Avatar
                              // style={{ backgroundColor: "#3f51b5" }}
                              alt=""
                              src={item.icon}
                            />
                          </TableCell>
                          <TableCell>
                            {item.name ? (
                              item.name
                            ) : (
                              <Chip
                                variant="filled"
                                label="Content is set for this language"
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {item.badgedescription ? (
                              item.badgedescription
                            ) : (
                              <Chip
                                variant="filled"
                                label="Content is set for this language"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={searchBadges !== "" ? searchData.length : badges.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
            // className={style.scrollbar}
          />
        </>
      ) : (
        <Box height={400}>
          <Grid
            container
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" color="textSecondary">
              No live session to show
            </Typography>
          </Grid>
        </Box>
      )}
    </>
  );

  // const handleUploadClick = () => {
  //   hiddenFileInput.current.click();
  //   console.log("clicked on upload");
  // };

  return (
    // <Box py={3} minHeight="100%">
    // </Box>
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Card className="mt-3 mb-5" style={{ position: "relative" }}>
            <Grow
              in={currentScreen === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentScreen === 1 ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid container className="align-items-center p-3">
                  <Typography variant="h6">All Gems</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setcurrentScreen(2)}
                    startIcon={<AddIcon />}
                    className="ml-3"
                  >
                    Create Gems
                  </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <FormControl variant="outlined" className="m-3" fullWidth>
                    <InputLabel id="language-select">Language</InputLabel>
                    {languages && (
                      <Select
                        defaultValue=""
                        labelId="language-select"
                        value={language}
                        label="Language"
                        onChange={(e) => {
                          setLanguage(e.target.value as number);
                          // dispatch(getBadgesAsync(language));
                        }}
                        MenuProps={MenuProps}
                      >
                        {languages.map((lang: any, i: any) => (
                          <MenuItem key={i} value={lang.code}>
                            {lang.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    <Grid
                      item
                      xs={12}
                      style={{ backgroundColor: "transparent" }}
                      className="py-2"
                    >
                      <Grid className="d-flex align-items-center">
                        <TextField
                          type="search"
                          fullWidth
                          value={searchBadges}
                          variant="outlined"
                          placeholder="Search gem name"
                          size="small"
                          onChange={(e) => {
                            setsearchBadges(e.target.value);
                            setPage(0);
                            const filteredData =
                              badgeListState.badgesList.badges.filter(
                                (badgesList) =>
                                  badgesList.name
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase())
                              );

                            setsearchData(filteredData);
                          }}
                          className="ml-3"
                        />

                        <Grid>
                          <IconButton
                            onClick={() => {
                              dispatch(getBadgesAsync(language));
                            }}
                            size="large"
                          >
                            <Tooltip title="Refresh">
                              <RefreshIcon />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    {badgeListState.badgesList.loading
                      ? ListSkeleton()
                      : renderBadgeList(badgeListState.badgesList.badges)}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
            <Grow
              in={currentScreen === 2}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentScreen === 2 ? { timeout: 1000 } : {})}
            >
              <CreateBadge
                onClickBack={onClickBack}
                isEditing={states.editing}
                editItem={states.editItem}
                onClickBadges={onClickBadges}
                lang={language}
              />
            </Grow>
            <Grow
              in={currentScreen === 3}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentScreen === 3 ? { timeout: 1000 } : {})}
            >
              <UpdateBadge
                onClickBack={onClickBack}
                isEditing={states.editing}
                editItem={states.editItem}
                onClickBadges={onClickBadges}
                lang={language}
              />
            </Grow>
          </Card>
        </Grid>
        {/* <Grid item lg={8} md={12} xl={9} xs={12}>
          {renderAddBadgeForm()}
        </Grid> */}
      </Grid>
    </Container>
  );
}
