import { createSlice } from "@reduxjs/toolkit";

interface donationsDefaultState {
  donationsList: {
    loading: boolean;
    donations: [];
  };
}

const initialState: donationsDefaultState = {
  donationsList: {
    loading: true,
    donations: [],
  },
};

export const donationsSlice = createSlice({
  name: "donationsList",
  initialState,
  reducers: {
    setDonationsListLoading: (state, action) => {
      state.donationsList.loading = action.payload;
    },
    donationsList: (state, action) => {
      state.donationsList.donations = action.payload;
    },
  },
});

export const { setDonationsListLoading, donationsList } =
  donationsSlice.actions;

export default donationsSlice.reducer;
