import React, { BaseSyntheticEvent, useState } from "react";
import {
  Alert,
  Avatar,
  Button,
  Container,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { firebaseAuth } from "config/firebase";

import EmailIcon from "@mui/icons-material/Email";
import { isValidEmail } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -8,
  },
}));
export default function ForgotPasswordScreen() {
  const classes = useStyles();
  const [email, setEmail] = useState<string>("");
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  function resetPassword() {
    firebaseAuth
      .sendPasswordResetEmail(email)
      .then((res) =>
        setSnackBar({
          open: true,
          severity: "success",
          message: `Password resent link sent to ${email}.\n Kindly check the spam folder if your are not able to find it`,
        })
      )
      .catch((err) =>
        setSnackBar({
          open: true,
          severity: "error",
          message: err.code,
        })
      );
  }
  function handleSubmit(e: BaseSyntheticEvent) {
    e.preventDefault();
    if (email && isValidEmail(email)) {
      resetPassword();
    } else {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Please enter a valid email",
      });
    }
  }
  return (
    <Container maxWidth="xs">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <EmailIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            sx={{ p: 2 }}
            placeholder="Please enter your email"
            variant="outlined"
            // margin="normal"
            required
            fullWidth
            id="email"
            // label="Email"

            name="email"
            autoComplete="email"
            // autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></TextField>
        </form>
        <div className={classes.wrapper}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            Send Password Reset Email
          </Button>
        </div>
      </Paper>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
