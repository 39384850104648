import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  GcpPubSubRequest,
  ListOfUserInfo,
  RequestForGetUserDetails,
  RequestUserID,
  ResponseForSearchUserByDetails,
  SearchUserByDetailsRequest,
  Status,
  UserInfo,
} from "api/models/heartintune-profile-service/heartintune-profile_pb";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import { HEARTINTUNE_PROFILE_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import {
  searchedUsersListError,
  searchedUsersListLoading,
  searchedUsersListUpdate,
  UserDetailsByIdError,
  UserDetailsByIdLoading,
  UserDetailsByIdUpdate,
  usersListError,
  usersListLoading,
  usersListUpdate,
} from "redux/reducers/userReducer";
import { AppThunk } from "redux/store/store";

export const getAllUserList =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usersListLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      // console.log(jwtToken);
      const reqBody: RequestForGetUserDetails = new RequestForGetUserDetails();
      reqBody.setPagenumber(pageNumber);
      reqBody.setPagesize(pageSize);

      grpc.unary(HeartIntuneProfileService.GetUserDetails, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },

        onEnd: (res: UnaryOutput<ListOfUserInfo>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("data coming", message.toObject());
              dispatch(usersListUpdate(message.toObject()));
            }
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(usersListError());
    }
  };

export const searchUserDetails =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(searchedUsersListLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const pagination = new RequestForGetUserDetails();

      pagination.setPagenumber(data.pageNumber);
      pagination.setPagesize(data.pageSize);

      const reqBody: SearchUserByDetailsRequest =
        new SearchUserByDetailsRequest();
      if (data.searchFilter === "name") {
        reqBody.setName(data.searchInput);
      } else if (data.searchFilter === "email") {
        reqBody.setEmail(data.searchInput);
      } else reqBody.setPhonenumber(data.searchInput);

      reqBody.setPageable(pagination);

      grpc.unary(HeartIntuneProfileService.SearchUserDetails, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },

        onEnd: (res: UnaryOutput<ResponseForSearchUserByDetails>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("searched user data", message.toObject());
              dispatch(searchedUsersListUpdate(message.toObject()));
            } else {
              dispatch(searchedUsersListError());
            }
          } else {
            dispatch(searchedUsersListError());
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(searchedUsersListError());
    }
  };

export const getUserDetailsByIdV2 =
  (userId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(UserDetailsByIdLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      // console.log(jwtToken);
      const reqBody: RequestUserID = new RequestUserID();
      reqBody.setUserid(userId);

      grpc.unary(HeartIntuneProfileService.GetUserDetailsByID, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },

        onEnd: (res: UnaryOutput<UserInfo>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage for userID", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "data coming from get user details by id ",
                message.toObject()
              );
              dispatch(UserDetailsByIdUpdate(message.toObject()));
            }
          } else {
            console.log("error", statusMessage);
            dispatch(UserDetailsByIdError(statusMessage));
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(UserDetailsByIdError("Could not fetch user details"));
    }
  };

export const clearCache = async (userID: number, setSnackBar: any) => {
  try {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: GcpPubSubRequest = new GcpPubSubRequest();
    reqBody.setTopicid("test-topic");
    reqBody.setMessage(`clearCache_${userID}`);
    grpc.unary(HeartIntuneProfileService.GcpPublishTopic, {
      host: HEARTINTUNE_PROFILE_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<Status>) => {
        const { status, message } = res;
        console.log("Status: ", status, "Message: ", message);
        if (grpc.Code.OK && message) {
          setSnackBar({
            open: true,
            severity: "success",
            message: "Successfully cleared user's cache",
          });
        } else {
          setSnackBar({
            open: true,
            severity: "error",
            message: "error in deleting user cache",
          });
        }
      },
    });
  } catch (error) {
    console.log(error);
    setSnackBar({
      open: true,
      severity: "error",
      message: "error in deleting user cache",
    });
  }
};

// export const userLogin =(uid: any): AppThunk =>async (dispatch) =>  {
//   const history = useHistory();
//   // const data = {
//   //   uid: uid,
//   //   status: 2,
//   //   provider: 4,
//   // };
//   try {
//     console.log("data", uid);
//     const jwtToken = await firebaseAuth.currentUser?.getIdToken();
//     const reqBody: ConnectUserRequest = new ConnectUserRequest();
//     //reqBody.setUserid(userId);
//     reqBody.setStatus(2);
//     reqBody.setProvider(4);
//     reqBody.setFirebaseid(uid);
//     grpc.unary(HeartIntuneProfileService.UserLogin, {
//       host: HEARTINTUNE_PROFILE_SERVICE_HOST,
//       request: reqBody,
//       metadata: {
//         Authorization: `Bearer ${jwtToken}`,
//       },

//       onEnd: (res: UnaryOutput<ConnectUserResponse>) => {
//         //const { status, message } = res;
//         const { status, statusMessage, headers, message, trailers } = res;
//         console.log("statusMessage", statusMessage);
//         console.log("headers", headers);
//         console.log("trailers", trailers);
//         console.log("grpc code", grpc.Code);
//         if (status === grpc.Code.OK && message) {
//           if (message) {
//             //updateCurrentUser(message.toObject().user);
//             console.log("data coming from user login ", message.toObject());
//             const userRole = message.toObject().user?.role;
//             console.log('user role',userRole)
//             dispatch(userRoleUpdate(message.toObject().user?.role));
//         return
//             // if (userRole === (Role.USER ||Role.CUSTOM_ROLE || Role.UNKNOWN_ROLE) ) {
//             //     //history.replace("/login");
//             //     // setState({
//             //     //   loading: false,
//             //     //   errorMessage:
//             //     //     "You are not authorized to sign in to admin portal",
//             //     //   showError: true,
//             //     // });
//             //     history.replace("/login")
//             // } else {
//             //   if (!history.location.pathname.match("/app"))
//             //     history.replace("/app");

//             // }
//           }
//         }
//       },
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };
