import React, { useEffect } from "react";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Divider,
  List,
  Typography,
  Avatar,
  Popover,
  MenuItem,
  Menu,
  Grid,
  Button,
  Paper,
  Stack,
  Snackbar,
  Alert,
  AlertTitle,
  CssBaseline,
  Tooltip,
  ButtonGroup,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "services/AuthService";
import Logo from "components/logo";
import { Link as RouterLink, useLocation } from "react-router-dom";
import NavItem from "components/NavBar/navItem";
import { version } from "../../package.json";
import { selectNavItemByRole } from "./NavBar/roleBasedNavItem";
import { Global } from "@emotion/react";
import firebase from "firebase";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import {
  fetchRemoteConfigHelpDocUrls,
  getCoachListAsync,
} from "redux/actions/liveMeditationAction";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpIcon from "@mui/icons-material/Help";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";

const PREFIX = "TopBar";

const classes = {
  root: `${PREFIX}-root`,
  avatar: `${PREFIX}-avatar`,
  name: `${PREFIX}-name`,
  item: `${PREFIX}-item`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  active: `${PREFIX}-active`,
};

const user = {
  avatar: null,
  email: "admin@example.com",
  name: "Admin Name",
};

interface TopBarProps {
  className?: string;
  onMobileNavOpen: () => void;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: 250,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 250,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 250,
    width: `calc(100% - ${250}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }: TopBarProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const helpDocs = useSelector(
    (state: RootState) => state.liveMeditation.helpDocs
  );
  const { logout } = useAuth();
  const users: any = localStorage.getItem("user");
  const User = JSON.parse(users);
  const { currentUser } = useAuth();
  const [top, settop] = React.useState(window.innerWidth >= 800);
  const [popoverAnchor, setPopoverAnchor] = React.useState<any>(null);
  const [notificationTitle, setNotificationTitle] = React.useState<any>("");
  // const [coachPhotoUrl, setCoachPhotoUrl] = React.useState("");
  const [snackBar, setSnackBar] = React.useState<any>({
    open: false,
    severity: "success",
    message: "",
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openUserInfo = Boolean(anchorEl2);

  useEffect(() => {
    dispatch(getCoachListAsync());
    // getCoachPhoto();
    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage((payload) => {
        console.log("foreground message", payload);
        setNotificationTitle(payload.notification.title);
        setSnackBar({
          open: true,
          severity: "info",
          message: `${payload.notification.body}`,
        });
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname.includes("live")) {
      dispatch(fetchRemoteConfigHelpDocUrls());

      // console.log("HELP DOCS", helpDocs);
    }
  }, [dispatch, location]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickUserInfo = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUserInfo = () => {
    setAnchorEl2(null);
  };

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    settop(open);
  };

  async function handleLogout() {
    try {
      logout && (await logout());
    } catch (err) {
      console.log(err);
    }
    localStorage.clear();
  }

  // function getCoachPhoto() {
  //   if (User.role === 5) {
  //     setCoachPhotoUrl(
  //       coachList.coaches.filter((coach) => {
  //         return coach.email === User.email;
  //       })[0]?.imageurl
  //     );
  //   }
  // }

  const accountInfo = (
    <Paper sx={{ padding: 2 }}>
      <Stack direction="column" alignItems="center" alignContent="center">
        <Typography color="textPrimary" variant="h5" align="center">
          {currentUser?.displayName || user.name}
        </Typography>

        <Typography color="textSecondary" variant="body2" align="center">
          {currentUser?.email || user.email}
        </Typography>

        <Button
          fullWidth
          sx={{ marginTop: "10px" }}
          variant="contained"
          color="secondary"
          onClick={() => handleLogout()}
        >
          log out
        </Button>
      </Stack>
    </Paper>
  );
  const content = (
    <Box
      minWidth={20}
      sx={{
        maxWidth: "100%",
        backgroundColor: "primary.main",
        overflow: "hidden",
      }}
      display="flex"
      flexDirection={"column"}
      role="presentation"
      onClick={toggleDrawer(top)}
      onKeyDown={toggleDrawer(top)}
    >
      <Box
        pr={0}
        pl={0}
        pt={0}
        overflow={"scroll"}
        style={{
          overflowX: "hidden",
          borderTop: "1px solid rgba(255, 255, 255, 0.12)",
        }}
      >
        {User.role === 1 || User.role === 2 ? (
          <>
            {top && (
              <Typography
                color="white"
                variant="subtitle1"
                gutterBottom={true}
                sx={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginTop: "1vw",
                  paddingLeft: "18px",
                }}
              >
                Groups
              </Typography>
            )}

            {selectNavItemByRole(User.role).map((item, index) => {
              if (item.category === "Groups") {
                return (
                  <Tooltip
                    key={index}
                    title={item.title}
                    disableHoverListener={top}
                    placement="right"
                  >
                    <Box>
                      <NavItem
                        href={item.href}
                        title={top ? item.title : ""}
                        icon={item.icon}
                        category={item.category}
                      />
                    </Box>
                  </Tooltip>
                );
              }
            })}

            <Divider />
          </>
        ) : (
          ""
        )}
        {User.role !== 8 && User.role !== 9 ? (
          <>
            {top && (
              <Typography
                color="white"
                variant="subtitle1"
                gutterBottom={true}
                sx={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginTop: "1vw",
                  paddingLeft: "18px",
                }}
              >
                Guided Meditations
              </Typography>
            )}

            {selectNavItemByRole(User.role).map((item, index) => {
              if (item.category === "Guided Meditations")
                return (
                  <Tooltip
                    key={index}
                    title={item.title}
                    disableHoverListener={top}
                    placement="right"
                  >
                    <Box>
                      <NavItem
                        href={item.href}
                        title={top ? item.title : ""}
                        icon={item.icon}
                        category={item.category}
                      />
                    </Box>
                  </Tooltip>
                );
            })}

            <Divider />
          </>
        ) : (
          ""
        )}
        {User.role !== 8 && User.role !== 9 ? (
          <>
            {top && (
              <Typography
                color="white"
                variant="subtitle1"
                gutterBottom={true}
                sx={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginTop: "1vw",
                  paddingLeft: "18px",
                }}
              >
                Live Sessions
              </Typography>
            )}

            {selectNavItemByRole(User.role).map((item, index) => {
              if (item.category === "Live Sessions")
                return (
                  <Tooltip
                    key={index}
                    title={item.title}
                    disableHoverListener={top}
                    placement="right"
                  >
                    <Box>
                      <NavItem
                        href={item.href}
                        title={top ? item.title : ""}
                        icon={item.icon}
                        category={item.category}
                      />
                    </Box>
                  </Tooltip>
                );
            })}

            <Divider />
          </>
        ) : (
          ""
        )}
        {User.role === 1 || User.role === 2 ? (
          <>
            {top && (
              <Typography
                color="white"
                variant="subtitle1"
                gutterBottom={true}
                sx={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginTop: "1vw",
                  paddingLeft: "18px",
                }}
              >
                User Management
              </Typography>
            )}

            {selectNavItemByRole(User.role).map((item, index) => {
              if (item.category === "User Management") {
                return (
                  <Tooltip
                    key={index}
                    title={item.title}
                    disableHoverListener={top}
                    placement="right"
                  >
                    <Box>
                      <NavItem
                        href={item.href}
                        title={top ? item.title : ""}
                        icon={item.icon}
                        category={item.category}
                      />
                    </Box>
                  </Tooltip>
                );
              }
            })}

            <Divider />
          </>
        ) : (
          ""
        )}

        {User.role === 1 || User.role === 2 || User.role === 3 ? (
          <>
            {top && (
              <Typography
                color="white"
                variant="subtitle1"
                gutterBottom={true}
                sx={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginTop: "1vw",
                  paddingLeft: "18px",
                }}
              >
                Gamification
              </Typography>
            )}

            {selectNavItemByRole(User.role).map((item, index) => {
              if (item.category === "Gamification") {
                return (
                  <Tooltip
                    key={index}
                    title={item.title}
                    disableHoverListener={top}
                    placement="right"
                  >
                    <Box>
                      <NavItem
                        href={item.href}
                        title={top ? item.title : ""}
                        icon={item.icon}
                        category={item.category}
                      />
                    </Box>
                  </Tooltip>
                );
              }
            })}
            <Divider />
          </>
        ) : (
          ""
        )}

        {User.role === 1 || User.role === 2 || User.role === 3 ? (
          <>
            {top && (
              <Typography
                color="white"
                variant="subtitle1"
                gutterBottom={true}
                sx={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginTop: "1vw",
                  paddingLeft: "18px",
                }}
              >
                Resources
              </Typography>
            )}

            {selectNavItemByRole(User.role).map((item, index) => {
              if (item.category === "Resources") {
                return (
                  <Tooltip
                    key={index}
                    title={item.title}
                    disableHoverListener={top}
                    placement="right"
                  >
                    <Box>
                      <NavItem
                        href={item.href}
                        title={top ? item.title : ""}
                        icon={item.icon}
                        category={item.category}
                      />
                    </Box>
                  </Tooltip>
                );
              }
            })}
            <Divider />
          </>
        ) : (
          ""
        )}

        {User.role === 1 || User.role === 2 || User.role === 3 ? (
          <>
            {top && (
              <Typography
                color="white"
                variant="subtitle1"
                gutterBottom={true}
                sx={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginTop: "1vw",
                  paddingLeft: "18px",
                }}
              >
                HeartsApp
              </Typography>
            )}

            {selectNavItemByRole(User.role).map((item, index) => {
              if (item.category === "HeartsApp") {
                return (
                  <Tooltip
                    key={index}
                    title={item.title}
                    disableHoverListener={top}
                    placement="right"
                  >
                    <Box>
                      <NavItem
                        href={item.href}
                        title={top ? item.title : ""}
                        icon={item.icon}
                        category={item.category}
                      />
                    </Box>
                  </Tooltip>
                );
              }
            })}
            <Divider />
          </>
        ) : (
          ""
        )}

        {User.role === 1 || User.role === 2 || User.role === 3 ? (
          <>
            {top && (
              <Typography
                color="white"
                variant="subtitle1"
                gutterBottom={true}
                sx={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginTop: "1vw",
                  paddingLeft: "18px",
                }}
              >
                Self Development
              </Typography>
            )}

            {selectNavItemByRole(User.role).map((item, index) => {
              if (item.category === "Self Development") {
                return (
                  <Tooltip
                    key={index}
                    title={item.title}
                    disableHoverListener={top}
                    placement="right"
                  >
                    <Box>
                      <NavItem
                        href={item.href}
                        title={top ? item.title : ""}
                        icon={item.icon}
                        category={item.category}
                      />
                    </Box>
                  </Tooltip>
                );
              }
            })}
            <Divider />
          </>
        ) : (
          ""
        )}
        {User.role === 1 || User.role === 2 || User.role === 8 ? (
          <>
            {top && (
              <Typography
                color="white"
                variant="subtitle1"
                gutterBottom={true}
                sx={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginTop: "1vw",
                  paddingLeft: "18px",
                }}
              >
                Bhandara
              </Typography>
            )}
            <List>
              {selectNavItemByRole(User.role).map((item, index) => {
                if (item.category === "Bhandara") {
                  return (
                    <Tooltip
                      key={index}
                      title={item.title}
                      disableHoverListener={top}
                      placement="right"
                    >
                      <Box>
                        <NavItem
                          href={item.href}
                          title={top ? item.title : ""}
                          icon={item.icon}
                          category={item.category}
                        />
                      </Box>
                    </Tooltip>
                  );
                }
              })}
            </List>
            <Divider />
          </>
        ) : (
          ""
        )}

        {(User.role === 1 || User.role === 2 || User.role === 9) && (
          <>
            {top && (
              <Typography
                color="white"
                variant="subtitle1"
                gutterBottom={true}
                sx={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginTop: "1vw",
                  paddingLeft: "18px",
                }}
              >
                Program
              </Typography>
            )}
            <List>
              {selectNavItemByRole(User.role).map((item, index) => {
                if (item.category === "Program") {
                  return (
                    <Tooltip
                      key={index}
                      title={item.title}
                      disableHoverListener={top}
                      placement="right"
                    >
                      <Box>
                        <NavItem
                          href={item.href}
                          title={top ? item.title : ""}
                          icon={item.icon}
                          category={item.category}
                        />
                      </Box>
                    </Tooltip>
                  );
                }
              })}
            </List>
            <Divider />
          </>
        )}
      </Box>
      <Box>
        <Divider sx={{ borderBottomWidth: 2 }} />
        {top ? (
          <ButtonGroup orientation="vertical" size="small" fullWidth>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogout}
              sx={{ padding: "8px 0" }}
              style={{ textTransform: "none" }}
            >
              <ExitToAppRoundedIcon fontSize="small" />
              <Typography
                align="center"
                variant="caption"
                style={{ marginLeft: "5px" }}
              >
                Logout
              </Typography>
            </Button>
            <Divider sx={{ borderBottomWidth: 2 }} />
            <Button
              variant="contained"
              color="primary"
              sx={{ padding: "8px 0" }}
              style={{ textTransform: "none" }}
            >
              <Typography align="center" variant="caption">
                Version {version}
              </Typography>
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup orientation="vertical" fullWidth>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogout}
              sx={{ paddingLeft: "9px" }}
            >
              {/* <LogoutIcon fontSize="small" /> */}
              <ExitToAppRoundedIcon fontSize="small" />
            </Button>
            <Divider sx={{ borderBottomWidth: 2 }} />
            <Button
              variant="contained"
              color="primary"
              sx={{ paddingLeft: "5px" }}
              style={{ textTransform: "none" }}
            >
              <Typography
                align="center"
                variant="caption"
                style={{ marginLeft: "3px" }}
              >
                v{version}
              </Typography>
            </Button>
          </ButtonGroup>
        )}
        {/* {top ? (
          <Box className="mt-2">
            <Typography align="center" variant="body2" color="secondary">
              Version # {version}
            </Typography>
          </Box>
        ) : (
          <Typography
            align="left"
            variant="body2"
            color="secondary"
            sx={{ padding: "1vh", paddingLeft: "10px" }}
          >
            {version}
          </Typography>
        )} */}
      </Box>
    </Box>
  );

  const theme = useTheme();

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              borderRight: "0",
              backgroundColor: process.env.REACT_APP_PRIMARY_MAIN || "#643E94",
              overflow: "hidden",
              "*::-webkit-scrollbar": {
                WebkitAppearance: "none",
                borderRadius: "4px",
                border: "1px solid rgba(0,0,0,.2)",
                width: "7px",
              },
              "*::-webkit-scrollbar-thumb": {
                borderRadius: "4px",
                backgroundColor: "rgba(0,0,0,.5)",
                WebkitBoxShadow: "0 0 1px rgba(255,255,255,.5)",
              },
            },
          }}
        />
        <AppBar
          elevation={0}
          position="fixed"
          sx={{ backgroundColor: "primary.main" }}
        >
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={7}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item lg={0.6} md={0.6} xs={2}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => settop(!top)}
                      edge="start"
                      sx={{
                        marginRight: 2,
                        marginTop: "2vh",
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xl={6} lg={7} md={8} sm={9} xs={10}>
                    <RouterLink to="/app">
                      <Logo />
                    </RouterLink>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden lgDown>
                <Popover
                  open={Boolean(popoverAnchor)}
                  anchorEl={popoverAnchor}
                  onClose={() => setPopoverAnchor(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  {accountInfo}
                </Popover>
              </Hidden>
              <Grid item xs={5}>
                <Grid container display={"flex"} flexDirection="row-reverse">
                  {location.pathname.includes("live") && (
                    <IconButton
                      color="inherit"
                      onClick={handleClick}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <HelpIcon />
                    </IconButton>
                  )}
                  <Button
                    aria-describedby={"user-info"}
                    variant="contained"
                    onClick={handleClickUserInfo}
                    disableElevation={true}
                  >
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      src={currentUser?.photoURL || "./admin-logo.jpg"}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={top} sx={{ overflow: "hidden" }}>
          <DrawerHeader>
            <IconButton sx={{ marginY: "18px" }} onClick={() => settop(false)}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon sx={{ color: "white" }} />
              )}
            </IconButton>
          </DrawerHeader>
          {content}
        </Drawer>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            <AlertTitle>{notificationTitle}</AlertTitle>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Box>
      <Popover
        id={"user-info"}
        open={openUserInfo}
        anchorEl={anchorEl2}
        onClose={handleCloseUserInfo}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper
          sx={{
            marginTop: 0,
            padding: 1,
            left: 8,
          }}
        >
          <Box alignItems="center" display="flex" flexDirection="column" py={2}>
            <Avatar
              sx={{ width: 52, height: 52 }}
              src={currentUser?.photoURL || "./admin-logo.jpg"}
            />
            <Typography className={classes.name} variant="h6" align="center">
              {currentUser?.displayName || user.name}
            </Typography>
            <Typography variant="body2" align="center">
              {currentUser?.email || user.email}
            </Typography>
          </Box>
        </Paper>
      </Popover>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => window.open(helpDocs.whatsNew)}>
          What's New
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => window.open(helpDocs.howToUse)}>
          How to use
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            window.open(helpDocs.roadmap);
          }}
        >
          Roadmap
        </MenuItem>
      </Menu>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
