import { grpc } from "@improbable-eng/grpc-web";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import {
  DeviceNotificationRequest,
  Notification,
} from "api/models/heartintune-profile-service/notification_pb";
import { HEARTINTUNE_PROFILE_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { HeartInTuneNotificationRequest } from "api/models/heartintune-profile-service/heartintune-profile_pb";

export default function NotificationModal({
  open,
  onClose,
  setSnackBar,
}: // setsearchUser,
any): ReactElement {
  // const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.user);
  const [title, setTitle] = useState<any>("");
  const [body, setbody] = useState<any>("");
  const [commaSeparatedIds, setCommaSeparatedIds] = useState<any>("");
  const [category, setcategory] = useState<any>("");
  const emptyData = () => {
    setTitle("");
    setbody("");
    setcategory("");
    setCommaSeparatedIds("");
  };

  const _sendDeviceNotification = () => {
    if (state.userDetailsById.details.fcmtokenList.length) {
      state.userDetailsById.details.fcmtokenList.map((item) =>
        sendDeviceNotification(item.fcmtoken)
      );
    }
  };
  const sendDeviceNotification = async (fcmtoken: any) => {
    const data = {
      title: title,
      body: body,
      category: category,
    };
    try {
      console.log("Delete User datatoken", fcmtoken);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody = new HeartInTuneNotificationRequest();
      const notification = new Notification();

      notification.setTitle(data.title);
      notification.setBody(data.body);
      notification.setCategory(data.category);

      const devicenotificationrequest = new DeviceNotificationRequest();
      devicenotificationrequest.setDeviceregistrationtoken(fcmtoken);
      devicenotificationrequest.setNotification(notification);

      reqBody.setDevicenotificationrequest(devicenotificationrequest);
      reqBody.setUseridswithcommaseperated(commaSeparatedIds);
      // setIsLoading(true);
      console.log("notification request", reqBody.toObject());
      grpc.unary(
        HeartIntuneProfileService.SendUserNotificationFromHeartInTune,
        {
          host: HEARTINTUNE_PROFILE_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);

            // console.log("message", message);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log("send notification response", message.toObject());
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: "notification sent successfully",
                });
                emptyData();
                onClose();
                // setsearchUser('');
              }
            } else {
              // setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in  sending notification",
              });
            }
          },
        }
      );
    } catch (err) {
      //   setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "error",
        message: "error in  sending notification",
      });
      console.log(err);
    }
  };
  return (
    <>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionProps={
          {
            //onEntered: _onOpen,
          }
        }
      >
        {state.userDetailsById.loading ? (
          <>
            <DialogContent>
              {state.userDetailsById.error ? (
                <Typography> state.userDetailsById.message</Typography>
              ) : (
                <CircularProgress />
              )}
            </DialogContent>
          </>
        ) : (
          <>
            {state.userDetailsById.details.fcmtokenList.length ? (
              <>
                <DialogTitle
                  style={{ backgroundColor: "var(--grey-white)", width: 650 }}
                >
                  <Typography variant="h6" component="span">
                    Send Notification
                  </Typography>

                  <IconButton
                    style={{ position: "absolute", right: 0, top: 8 }}
                    onClick={() => {
                      emptyData();
                      onClose();
                    }}
                    size="large"
                  >
                    <CloseRounded color="disabled" />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers className="">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        type="text"
                        label="Provide comma separated IDs"
                        name="type"
                        variant="outlined"
                        value={commaSeparatedIds}
                        onChange={(e) => setCommaSeparatedIds(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        type="text"
                        label="Notification Title"
                        name="type"
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        type="text"
                        label="Notification Body"
                        name="type"
                        variant="outlined"
                        value={body}
                        onChange={(e) => setbody(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Notifiaction Category"
                        name="type"
                        variant="outlined"
                        value={category}
                        onChange={(e) => setcategory(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Grid className="px-3 py-2">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => _sendDeviceNotification()}
                      disabled={
                        body === "" || title === "" || commaSeparatedIds === ""
                      }
                    >
                      Done
                    </Button>
                  </Grid>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContent dividers>
                  <Typography variant="h6" align="center">
                    Can not send notification
                  </Typography>
                  <Typography variant="h6" align="center">
                    User is logged out of all devices
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Grid className="px-3">
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => onClose()}
                    >
                      ok
                    </Button>
                  </Grid>
                </DialogActions>
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );
}
