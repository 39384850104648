import "@mui/styles";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";
import PrivateRoute from "components/privateRoute";
import UploadManager from "components/UploadManager/UploadManager";
import { GlobalStyles } from "config/globalStyles";
import { DarkTheme, LightTheme } from "config/themes";
import AppScreen from "pages/app";
import LoginScreen from "pages/auth/login/login.screen";
import {
  //BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  HashRouter, //replaced with HashRouter
} from "react-router-dom";
import AuthService from "services/AuthService";
import { useThemeDetector } from "utils/helpers";
import ForgotPasswordScreen from "pages/auth/login/forgotPassword.screen";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default function Splash() {
  const isDarkTheme = useThemeDetector();
  const appliedTheme = createTheme(
    adaptV4Theme(isDarkTheme ? DarkTheme : LightTheme)
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appliedTheme}>
        <CssBaseline />
        <GlobalStyles />
        <HashRouter>
          <AuthService>
            <Switch>
              {/* <Route path="/signup">
                  <SignUpScreen />
                </Route> */}
              <Route exact path={"/"}>
                <Redirect to={`/login`} />
              </Route>
              <Route path="/login" component={LoginScreen} />
              <Route path="/forgot" component={ForgotPasswordScreen} />
              <PrivateRoute path="/app" component={AppScreen} />
            </Switch>
          </AuthService>
        </HashRouter>
        <UploadManager />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
