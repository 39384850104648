import {
  Action,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import appReducer from "redux/reducers/appReducer";
import bhandaraReducer from "redux/reducers/bhandaraReducer";
import dashboardReducer from "redux/reducers/dashboardReducer";
import goalsReducer from "redux/reducers/goalsReducer";
import guidedReducer from "redux/reducers/guidedMeditationReducer";
import liveMeditationReducer from "redux/reducers/liveMeditationReducer";
import userReducer from "redux/reducers/userReducer";
import layoutReducer from "redux/reducers/layoutManagerReducer";
import donationsReducer from "redux/reducers/donationReducer";
import moodReducer from "redux/reducers/moodReducer";
import contentReducer from "redux/reducers/contentReducer";
import quoteReducer from "redux/reducers/quoteReducer";
import triggerReducer from "redux/reducers/triggerReducer";
import meditationBackground from "redux/reducers/meditationBackground";
import faq from "redux/reducers/faqreducer";
import groupsReducer from "redux/reducers/groupsReducer";
import selfDevelopment from "redux/reducers/selfDevelopment";
import tagReducer from "redux/reducers/tagReducer";
import heartsAppReducer from "redux/reducers/heartsAppReducer";
import programReducer from "redux/reducers/programReducer";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  middleware: () => customizedMiddleware,
  reducer: {
    dashboard: dashboardReducer,
    guidedMeditation: guidedReducer,
    liveMeditation: liveMeditationReducer,
    goals: goalsReducer,
    app: appReducer,
    user: userReducer,
    bhandara: bhandaraReducer,
    layout: layoutReducer,
    donations: donationsReducer,
    moods: moodReducer,
    content: contentReducer,
    quote: quoteReducer,
    trigger: triggerReducer,
    meditationBackground: meditationBackground,
    faq: faq,
    tags: tagReducer,
    heartsApp: heartsAppReducer,
    groups: groupsReducer,
    selfDevelopment: selfDevelopment,
    program: programReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
