import {
  Avatar,
  Card,
  CardContent,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
  TextField,
  Grid,
  Button,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  Grow,
} from "@mui/material";

import { Skeleton } from "@mui/material";

import UpcomingLiveMeditation from "components/liveMeditation/upcomingLiveMeditation";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLiveMeditationsBetweenDates,
  getCoachListAsync,
} from "redux/actions/liveMeditationAction";
import { schedularUpdateStep } from "redux/reducers/liveMeditationReducer";
import { RootState } from "redux/store/store";
import RefreshIcon from "@mui/icons-material/Refresh";

import style from "./index.module.scss";

import ScheduleSession from "components/liveMeditation/scheduleSession";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SessionBenifits from "components/liveMeditation/sessionBenifits";
import { useAuth } from "services/AuthService";
// interface duplicateState {
//   editing?: boolean;
//   editItem?: any;
//   coachId?: number;
//   type?: string;
// }

export default function LiveMeditationScreen(): ReactElement {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.liveMeditation);

  // const initialState = {
  //   editing: false,
  //   editItem: null,
  //   coachId:0,
  //   type:'copy'
  // };
  // console.log("node env", process.env.NODE_ENV);
  const start = moment(new Date()).startOf("day").format("YYYY-MM-DD");
  const end = moment(moment(new Date()).add(7, "day"))
    .endOf("day")
    .format("YYYY-MM-DD");
  // const end=new Date();
  // console.log("start date", start);
  // console.log("end date", end);

  const [searchCoach, setsearchCoach] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [currentScreen, setcurrentScreen] = useState(0);
  const currentUser = useAuth().currentUser;
  const [userData, setUserData] = useState<any>(
    JSON.parse(localStorage.getItem("user")!)
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [coachId, setCoachId] = useState(0);
  const [sessionId, setsessionId] = useState(0);
  const [endDateTime, setendDateTime] = useState(new Date());

  const [startDate, setstartDate] = useState<any>(start);
  const [endDate, setendDate] = useState<any>(end);
  //const [duplicateSession, setduplicateSession] = useState<duplicateState>(initialState);

  const steps: string[] = [
    "Select Coach",
    "Create Session",
    "Add Session Benefits",
  ];

  useEffect(() => {
    dispatch(getAllLiveMeditationsBetweenDates(start, end));
  }, [dispatch, start, end]);

  const onClickScheduleSession = () => {
    setcurrentScreen(1);
  };
  const onClickBackScheduleSession = () => {
    dispatch(schedularUpdateStep(0));
    setcurrentScreen(0);
  };
  // const onClickCopy = () => {
  //   //setcurrentScreen(1);
  //   //dispatch(schedularUpdateStep(1));
  // };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const _handleStepClick = (stepIndex: number) => {
    dispatch(schedularUpdateStep(stepIndex));
  };

  // const _onCoachSelect = (coachProfile: CoachProfile.AsObject) => {
  //   dispatch(updateSelectedCoach(coachProfile));
  //   dispatch(schedularUpdateStep(1));
  //   dispatch(getAllLiveMeditationsAsync());
  //   setCoachId(coachProfile.id);
  // };

  const _isStepDisabled = (stepIndex: number): boolean => {
    switch (stepIndex) {
      case 1:
        return !(
          state.selectedCoach !== undefined && state.selectedCoach !== null
        );
      case 2:
        // return !(state.sessionConfigType !== null);
        return state.sessionConfigType !== null;
      case 0:
      default:
        return false;
    }
  };
  const _onCreateSession = (coachProfile: any) => {
    dispatch(schedularUpdateStep(1));
    setCoachId(coachProfile.id);
  };
  const onClickBack = () => {
    //setcurrentScreen(1)
    dispatch(schedularUpdateStep(0));
  };

  const renderListSkeleton = () =>
    Array(4)
      .fill(0)
      .map((i, x) => (
        <React.Fragment key={`coachSkele${x}`}>
          <ListItem>
            <ListItemIcon>
              <Skeleton variant="circular">
                <Avatar />
              </Skeleton>
            </ListItemIcon>
            <ListItemText
              primary={<Skeleton />}
              secondary={<Skeleton width="80%" />}
            />
          </ListItem>
          <Divider variant="inset" />
        </React.Fragment>
      ));

  const renderCoachList = () => (
    <>
      {state.coachList.coaches.length ? (
        <>
          <TableContainer
            style={{ maxHeight: "400px" }}
            className={style.scrollbar}
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell>Coach Profile Image</TableCell>
                  <TableCell align="left">Coach Name</TableCell>
                  <TableCell align="left">Coach Email</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchCoach !== ""
                  ? searchData
                  : state.coachList.coaches.filter((coachProfile) => {
                      if (userData.role === 5) {
                        return coachProfile.email === currentUser?.email;
                      }
                      return true;
                    })
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((coachProfile, x) => {
                    if (userData.role === 5) {
                      if (currentUser?.email === coachProfile.email) {
                        return (
                          <React.Fragment key={`${coachProfile}${x}`}>
                            <TableRow hover>
                              <TableCell>
                                <Avatar
                                  // style={{ backgroundColor: "#3f51b5" }}
                                  alt={coachProfile.name}
                                  src={coachProfile.imageurl}
                                />
                              </TableCell>
                              <TableCell>{coachProfile.name}</TableCell>
                              <TableCell>{coachProfile.email}</TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    _onCreateSession(coachProfile);
                                  }}
                                >
                                  Create Session
                                </Button>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      }
                    } else {
                      return (
                        <React.Fragment key={`${coachProfile}${x}`}>
                          <TableRow hover>
                            <TableCell>
                              <Avatar
                                // style={{ backgroundColor: "#3f51b5" }}
                                alt={coachProfile.name}
                                src={coachProfile.imageurl}
                              />
                            </TableCell>
                            <TableCell>{coachProfile.name}</TableCell>
                            <TableCell>{coachProfile.email}</TableCell>
                            <TableCell align="center">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  _onCreateSession(coachProfile);
                                }}
                              >
                                Create Session
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    }
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={
              searchCoach !== ""
                ? searchData.length
                : state.coachList.coaches.filter((coachProfile) => {
                    if (userData.role === 5) {
                      return coachProfile.email === currentUser?.email;
                    }
                    return true;
                  }).length
            }
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
          />
        </>
      ) : (
        <Box height={350}>
          <Grid
            container
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" color="textSecondary">
              No coach to show
            </Typography>
          </Grid>
        </Box>
      )}
    </>
  );

  return (
    <Grid
      sx={{
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
        margin: {
          lg: "20px 80px 0px ",
          md: "20px 25px 0px ",
          sm: "20px 11px 0px",
        },
      }}
    >
      {/* <UpcomingLiveMeditation /> */}
      {/* <Card className="mt-3 mb-5" raised={false}> */}
      <Grow
        in={currentScreen === 0}
        mountOnEnter
        unmountOnExit
        style={{ transformOrigin: "0 0 0" }}
        {...(currentScreen === 0 ? { timeout: 1000 } : {})}
      >
        <UpcomingLiveMeditation
          onClickScheduleSession={onClickScheduleSession}
          setstartDate={setstartDate}
          startDate={startDate}
          setendDate={setendDate}
          endDate={endDate}
          endDateTime={endDateTime}
          setendDateTime={setendDateTime}
          //setduplicateSession={setduplicateSession}
          //onClickCopy={onClickCopy}
        />
      </Grow>

      <Grow
        in={currentScreen === 1}
        mountOnEnter
        unmountOnExit
        style={{ transformOrigin: "0 0 0" }}
        {...(currentScreen === 1 ? { timeout: 1000 } : {})}
      >
        <div>
          <Grid
            container
            spacing={1}
            style={{
              padding: "8px",
              alignItems: "center",
            }}
          >
            <Grid item>
              <IconButton
                onClick={() => onClickBackScheduleSession()}
                size="large"
              >
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">Schedule Live Session</Typography>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Stepper
              activeStep={state.scheduleStep}
              alternativeLabel
              nonLinear
              style={{ padding: "10px 0", margin: 0 }}
            >
              {steps.map((step, index) => (
                <Step key={step} completed={false}>
                  <StepButton
                    onClick={() => {
                      _handleStepClick(index);
                    }}
                    disabled={_isStepDisabled(index)}
                  >
                    <StepLabel optional={false}>{step}</StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {state.scheduleStep === 0 ? (
              <Paper>
                {userData.role !== 5 ? (
                  <Grid
                    style={{ backgroundColor: "transparent" }}
                    className="d-flex py-2 pl-3 align-items-center"
                  >
                    <Grid className="d-flex flex-grow-1">
                      <TextField
                        type="search"
                        fullWidth
                        variant="outlined"
                        placeholder="Search coach by name or email"
                        size="small"
                        onChange={(e) => {
                          setsearchCoach(e.target.value);
                          const filteredData = state.coachList.coaches.filter(
                            (coachProfile) =>
                              coachProfile.name
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase()) ||
                              coachProfile.email
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                          );
                          setsearchData(filteredData);
                        }}
                        className="mr-2"
                      />
                    </Grid>
                    <Grid className="d-flex align-items-center">
                      <IconButton
                        onClick={() => {
                          dispatch(getCoachListAsync());
                        }}
                        size="large"
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}

                {state.coachList.loading
                  ? renderListSkeleton()
                  : renderCoachList()}
              </Paper>
            ) : state.scheduleStep === 1 ? (
              <Card>
                <ScheduleSession
                  coachId={coachId}
                  onClickBack={onClickBack}
                  setLiveSessionId={setsessionId}
                  setendDateTime={setendDateTime}
                  setstartDate={setstartDate}
                  setendDate={setendDate}
                  // duplicateSession={duplicateSession}
                  // setduplicateSession={setduplicateSession}
                  // onClickBackScheduleSession={onClickBackScheduleSession}
                />
              </Card>
            ) : state.scheduleStep === 2 ? (
              <SessionBenifits
                sessionId={sessionId}
                setcurrentScreen={setcurrentScreen}
                endDateTime={endDateTime}
                setstartDate={setstartDate}
                setendDate={setendDate}
                coachId={coachId}
              />
            ) : null}
          </CardContent>
        </div>
      </Grow>
      {/* </Card> */}
    </Grid>
  );
}
