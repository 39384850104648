import React, { ReactElement, useState } from "react";
import { Grid, Grow } from "@mui/material";

import { UpcomingEvents } from "components/bhandaraEvents/upcomingEvents";
import { Archive } from "./archive";
import { ArchiveForm } from "./archiveForm";
import { useDispatch } from "react-redux";
import { firebaseAuth } from "config/firebase";
import {
  ArchivesEventsDetails,
  ArchivesEventsRequest,
  ArchivesSubEventsDetailsRequest,
  ID,
  ListOfArchivesSubEventsDetailsRequest,
  Status,
} from "api/models/hfn-events-service/hfn-events_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import { HFN_EVENTS_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { getAllArchives, getAllArchiveSubEvents } from "redux/actions/bhandaraActions";

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number;
  
}

export default function ArchiveScreen(): ReactElement {
  const initialState = {
    editing: false,
    editItem: null,
    showDeleteModal: false,
    deleteItemId: 0,
  };

  const [currentScreen, setCurrentScreen] = useState(0);
  const [states, setStates] = useState<State>(initialState);
  const [searchEvent, setSearchEvent] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventTitle, setEventTitle] = useState<any>("");
  const [subEvent, setSubEvent] = useState<any>([
    { name: "", url: "" },
    { name: "", url: "" },
  ]);
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });
  const [isAdding, setIsAdding] = useState(false);

  const onClickBack = () => {
    setCurrentScreen(0);
    setStates({
      editing: false,
      editItem: null,
    });
  };
  const onClickEdit = () => {
    setEventTitle(states.editItem.title);
  };
  const onChangeEventTitle = (e: any) => {
    setEventTitle(e.target.value);
  };
  const onChangeSubEventName = (e: any, index: number) => {
    let newSubEvent = [...subEvent];
    newSubEvent[index].name = e.target.value;
    setSubEvent(newSubEvent);
  };
  const onChangeSubEventUrl = (e: any, index: number) => {
    let newSubEvent = [...subEvent];
    newSubEvent[index].url = e.target.value;
    setSubEvent(newSubEvent);
  };
  const onClickAddSubEvent = () => {
    setSubEvent([...subEvent, { name: " ", url: " " }]);
  };
  const onClickDeleteSubEvent = (i: any) => {
    let newSubEvent = [...subEvent];
    newSubEvent.splice(i, 1);
    setSubEvent(newSubEvent);
  };
  const validateData = () => {
    let filterData: any[] = [];
    subEvent.forEach((item: any) => {
      if (item.name === "" || item.email==='') {
        filterData.push({name:item.name,url:item.url});
      } else return;
    });

    if (eventTitle.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Event title can not be empty",
      });
    } else if (filterData.length) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "subevent name and email is required",
      });
    } else {
      return true;
    }
  };
  function onClickAddEvent() {
    if (validateData()) {
      // alert("valiadte");
      setIsAdding(true);
      addScheduleEvent(eventTitle);
    }
  }
  function onClickUpdateEvent() {
    if (validateData()) {
      setIsAdding(true);
      setIsLoading(true);
      //updateScheduledEvent(props.editItem.id);
    }
  }

  const addScheduleEvent = async (title: string) => {
    setIsLoading(true);
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ArchivesEventsRequest = new ArchivesEventsRequest();
      reqBody.setEventname(title);

      grpc.unary(HFNEventsService.AddArchivesEvents, {
        request: reqBody,
        host: HFN_EVENTS_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ArchivesEventsDetails>) => {
          const { status, statusMessage, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log("data from add event response", message?.toObject());
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "success",
              message: "Event created successfully",
            });

            addSubEvent(message?.toObject().id);
            // dispatch(getAllArchives(pageSize, pageNumber));
            // setTimeout(onClickBack, 2000);
          } else {
            setIsLoading(false);
            console.log("error in create event", statusMessage);
            setSnackBar({
              open: true,
              severity: "error",
              message: "Error while creating event",
            });
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      setSnackBar({
        open: true,
        severity: "error",
        message: { err } || "Error while creating event",
      });
    }
  };
  const addSubEvent = async (id: number) => {
    try {
      setIsLoading(true);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const reqBody: ListOfArchivesSubEventsDetailsRequest =
        new ListOfArchivesSubEventsDetailsRequest();
      reqBody.setArchivessubeventsdetailsrequestList(
        subEvent.map((event: any) => {
          var subEventReq = new ArchivesSubEventsDetailsRequest();
          subEventReq.setId(id);
          subEventReq.setSubeventname(event.name);
          subEventReq.setUrl(event.url);
          return subEventReq
        })
      );
      console.log('reqBody',reqBody)

      grpc.unary(HFNEventsService.AddArchivesSubEvents, {
        request: reqBody,
        host: HFN_EVENTS_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, statusMessage } = res;
          if (status === grpc.Code.OK) {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "success",
              message: "Sub Event created successfully",
            });
            dispatch(getAllArchives(pageSize, pageNumber));
            setTimeout(onClickBack, 2000);
          } else {
            setIsLoading(false);
            console.log("error in create sub event", statusMessage);
            setSnackBar({
              open: true,
              severity: "error",
              message: "Error while creating sub event",
            });
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "error",
        message:  "Error while creating sub event",
      });
      console.log(err);
    }
  };

  //   const updateScheduledEvent = async (id: number) => {
  //     const jwtToken = await firebaseAuth.currentUser?.getIdToken();
  //     const reqBody: ScheduleEventDetails = new ScheduleEventDetails();

  //     const currentGoogleTimeStamp: Timestamp = new Timestamp();
  //     currentGoogleTimeStamp.fromDate(new Date(scheduleDate));

  //     reqBody.setId(id);
  //     reqBody.setTitle(eventTitle);
  //     reqBody.setSubtitle(subTitle);
  //     reqBody.setDirectionurl(directions);
  //     reqBody.setLivestreamingurl(streamingUrl);
  //     reqBody.setScheduletime(currentGoogleTimeStamp);
  //     reqBody.setLatitude(Number(latitude));
  //     reqBody.setLongitude(Number(longitude));
  //     console.log(reqBody.toObject());
  //     grpc.unary(HFNEventsService.UpdateScheduleEvent, {
  //       request: reqBody,
  //       host: HFN_EVENTS_SERVICE_HOST,
  //       metadata: {
  //         Authorization: `Bearer ${jwtToken}`,
  //       },
  //       onEnd: (res: UnaryOutput<ScheduleEventDetails>) => {
  //         const { status, message } = res;
  //         if (status === grpc.Code.OK && message) {
  //           dispatch(
  //             getAllScheduleEventBetweenDates(
  //               props.startDate,
  //               props.endDate,
  //               100,
  //               0
  //             )
  //           );
  //           setSnackBar({
  //             open: true,
  //             severity: "success",
  //             message: "event updated successfully",
  //           });
  //           setTimeout(props.onClickBack, 2000);
  //         } else {
  //           console.log("update status", status);
  //         }
  //       },
  //     });
  //   };

  //   const deleteScheduledEvent = async (id: number) => {
  //     const jwtToken = await firebaseAuth.currentUser?.getIdToken();

  //     const reqBody: ID = new ID();

  //     reqBody.setId(id);
  //     grpc.unary(HFNEventsService.DeleteScheduleEventById, {
  //       request: reqBody,
  //       host: HFN_EVENTS_SERVICE_HOST,
  //       metadata: {
  //         Authorization: `Bearer ${jwtToken}`,
  //       },
  //       onEnd: (res: UnaryOutput<Status>) => {
  //         const { status, message } = res;
  //         if (status === grpc.Code.OK && message) {
  //           setSnackBar({
  //             open: true,
  //             severity: "success",
  //             message: "event deleted successfully",
  //           });
  //           setTimeout(props.onClickBack, 2000);
  //           dispatch(
  //             getAllScheduleEventBetweenDates(
  //               props.startDate,
  //               props.endDate,
  //               100,
  //               0
  //             )
  //           );
  //         } else {
  //           setSnackBar({
  //             open: true,
  //             severity: "error",
  //             message: "error in deleting event",
  //           });
  //         }
  //       },
  //     });
  //   };

  // function onClickDelete() {
  //   deleteSubEvent(states.editItem.id);
  // }
  const onClickDelete = () => {
    console.log('onClickDelete')
    setStates({ showDeleteModal: true, deleteItemId:states.editItem.id });
  };

  const handleDeleteClose = () => {
    setStates({ showDeleteModal: false });
  };
  const deleteSubEvent = async () => {
    deleteSubEventById(states.deleteItemId);
  };
  const deleteSubEventById = async (id: any) => {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
console.log('deleting sub events for id',id)
    const reqBody: ID = new ID();

    reqBody.setId(id);
    grpc.unary(HFNEventsService.DeleteArchivesSubEventsById, {
      request: reqBody,
      host: HFN_EVENTS_SERVICE_HOST,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<Status>) => {
        const { status, message } = res;
        if (status === grpc.Code.OK && message) {
          setSnackBar({
            open: true,
            severity: "success",
            message: "archive sub event deleted successfully",
          });
          setTimeout(onClickBack, 2000);
          dispatch(getAllArchiveSubEvents(pageSize,pageNumber));
        } else {
          setSnackBar({
            open: true,
            severity: "error",
            message: "error in deleting archive sub event",
          });
        }
      },
    });
  };

  return (
    <Grid
      sx={{
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
        margin: {
          lg: "20px 80px 0px ",
          md: "20px 25px 0px ",
          sm: "20px 11px 0px",
        },
      }}
    >
      <Grow
        in={currentScreen === 0}
        mountOnEnter
        unmountOnExit
        style={{ transformOrigin: "0 0 0" }}
        {...(currentScreen === 0 ? { timeout: 1000 } : {})}
      >
        <Archive
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          setCurrentScreen={setCurrentScreen}
          searchEvent={searchEvent}
          setSearchEvent={setSearchEvent}
          setStates={setStates}
        />
      </Grow>
      <Grow
        in={currentScreen === 1}
        mountOnEnter
        unmountOnExit
        style={{ transformOrigin: "0 0 0" }}
        {...(currentScreen === 1 ? { timeout: 1000 } : {})}
      >
        <ArchiveForm
          isEditing={states.editing}
          editItem={states.editItem}
          onClickBack={onClickBack}
          onClickEdit={onClickEdit}
          snackBar={snackBar}
          setSnackBar={setSnackBar}
          onChangeEventTitle={onChangeEventTitle}
          isLoading={isLoading}
          eventTitle={eventTitle}
          subEvent={subEvent}
          onClickAddSubEvent={onClickAddSubEvent}
          onClickDeleteSubEvent={onClickDeleteSubEvent}
          onChangeSubEventName={onChangeSubEventName}
          onChangeSubEventUrl={onChangeSubEventUrl}
          onClickAddEvent={onClickAddEvent}
          onClickDelete={onClickDelete}
          handleDeleteClose={handleDeleteClose}
          deleteSubEvent={deleteSubEvent}
          showDeleteModal={states.showDeleteModal}
        />
      </Grow>
    </Grid>
  );
}
