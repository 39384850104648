import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ListOfUserInfo,
  ResponseForSearchUserByDetails,
  UserInfo,
  //UserInfoV2,
} from "api/models/heartintune-profile-service/heartintune-profile_pb";

interface UsersDefaultState {
  userRole: number;
  createUser: boolean;
  usersList: {
    loading: boolean;
    users: ListOfUserInfo.AsObject;
  };
  searchedButtonClicked?: boolean;
  userDetailsById: {
    loading: boolean;
    details: UserInfo.AsObject;
    error: boolean;
    message: string;
  };
}
const initialState: UsersDefaultState = {
  userRole: 1,
  createUser: true,
  usersList: {
    loading: true,
    users: {
      userinfoList: [],
      selectedpagesize: 10,
      selectedpagenumber: 0,
      totalcount: 0,
    },
  },
  searchedButtonClicked: false,
  userDetailsById: {
    loading: true,
    details: {
      deviceList: [],
      fcmtokenList: [],
      journalinfoList: [],
      user: undefined,
    },
    error: false,
    message: "",
  },
};

export const usersListSlice = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    userRoleUpdate: (state, action) => {
      state.userRole = action.payload;
    },
    createUserUpdate: (state) => {
      state.createUser = true;
    },
    usersListLoading: (state) => {
      state.usersList.loading = true;
    },
    usersListUpdate: (
      state,
      action: PayloadAction<ListOfUserInfo.AsObject>
    ) => {
      state.usersList.loading = false;
      state.usersList.users = {
        userinfoList: action.payload.userinfoList,
        selectedpagesize: action.payload.selectedpagenumber,
        selectedpagenumber: action.payload.selectedpagesize,
        totalcount: action.payload.totalcount,
      };
    },
    usersListError: (state) => {
      state.usersList.loading = false;
    },
    searchedUsersListLoading: (state) => {
      state.usersList.loading = true;
    },
    searchedUsersListUpdate: (
      state,
      action: PayloadAction<ResponseForSearchUserByDetails.AsObject>
    ) => {
      state.usersList.loading = false;
      state.usersList.users = {
        userinfoList: action.payload.userList,
        selectedpagesize: action.payload.selectedpagenumber,
        selectedpagenumber: action.payload.selectedpagesize,
        totalcount: action.payload.totalcount,
      };
    },
    searchedUsersListError: (state) => {
      state.usersList.loading = false;
    },
    clickedStart: (state) => {
      state.searchedButtonClicked = true;
    },
    clickedEnd: (state) => {
      state.searchedButtonClicked = false;
    },
    UserDetailsByIdLoading: (state) => {
      state.userDetailsById.loading = true;
    },
    UserDetailsByIdUpdate: (
      state,
      action: PayloadAction<UserInfo.AsObject>
    ) => {
      state.userDetailsById.loading = false;
      state.userDetailsById.error = false;
      state.userDetailsById.details = action.payload;
    },
    UserDetailsByIdError: (state, action) => {
      state.userDetailsById.loading = false;
      state.userDetailsById.error = true;
      state.userDetailsById.message = action.payload;
    },
  },
});

export const {
  userRoleUpdate,
  createUserUpdate,
  usersListLoading,
  usersListUpdate,
  usersListError,
  searchedUsersListLoading,
  searchedUsersListUpdate,
  searchedUsersListError,
  clickedStart,
  clickedEnd,
  UserDetailsByIdLoading,
  UserDetailsByIdUpdate,
  UserDetailsByIdError,
} = usersListSlice.actions;

export default usersListSlice.reducer;
