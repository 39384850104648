import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "45%",
    height: "40%",
    paddingTop: "2vh",
    [theme.breakpoints.down("sm")]: {
      minWidth: "80%",
    }
  },
}));

const Logo = (props: any) => {
  const classes = useStyles();
  return (
    <img
      alt="Codeprism Technologies"
      className={classes.logo}
      src="new_favicon.png"
      {...props}
    />
  );
};

export default Logo;
