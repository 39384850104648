import { grpc } from "@improbable-eng/grpc-web";
//import { Code } from "@improbable-eng/grpc-web/dist/typings/Code";
//import { Metadata } from "@improbable-eng/grpc-web/dist/typings/metadata";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { GetAllBadgesResponse } from "api/models/gamification-service/Badge_pb";
import { RequestForGetAllBadgeDetails } from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { AppThunk } from "redux/store/store";
import {
  badgeListError,
  badgeListLoading,
  badgeListUpdated,
} from "../reducers/dashboardReducer";

export const getBadgesAsync =
  (lang: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(badgeListLoading());
      const reqBody = new RequestForGetAllBadgeDetails();
      reqBody.setSelectedlanguage(lang);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.GetAllBadgesDetails, {
        request: reqBody,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<GetAllBadgesResponse>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log("data coming from get all badges", message.toObject());
            if (message) {
              dispatch(badgeListUpdated(message.toObject().badgeList));
            }
          }
        },
      });
    } catch (err) {
      dispatch(badgeListError());
      console.error(err);
    }
  };
