import { createSlice } from "@reduxjs/toolkit";

interface moodsDefaultState {
  moodsList: {
    loading: boolean;
    moods: [];
  };
}

const initialState: moodsDefaultState = {
  moodsList: {
    loading: true,
    moods: [],
  },
};

export const moodsSlice = createSlice({
  name: "moodsList",
  initialState,
  reducers: {
    setMoodsListLoading: (state) => {
      state.moodsList.loading = false
    },
    moodsList: (state, action) => {
      state.moodsList.moods = action.payload;
    },
  },
});

export const { setMoodsListLoading, moodsList } = moodsSlice.actions;

export default moodsSlice.reducer;
