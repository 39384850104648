import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { Locale } from "api/models/gamification-service/common/common_pb";
import {
  GetAllMoodTriggerResponse,
  ID,
  MoodTriggerRequest,
  MoodTriggerResponse,
  RequestPageable,
  Status,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import {
  setTriggersListLoading,
  triggersList,
} from "redux/reducers/triggerReducer";
import { AppThunk } from "redux/store/store";

interface pageable {
  pageNumber: number;
  pageSize: number;
}

interface addTriggerType {
  pageable: pageable;
  triggerName: Locale.AsObject;
  emojiUrl: string;
  setSnackBar: any;
}

interface updateTriggerType {
  id: number;
  pageable: pageable;
  triggerName: Locale.AsObject;
  emojiUrl: string;
  setSnackBar: any;
}

interface deleteTriggerType {
  id: number;
  pageable: pageable;
  setSnackBar: any;
}

export const getAllTriggers =
  (data: pageable): AppThunk =>
  async (dispatch) => {
    dispatch(setTriggersListLoading(true));
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestPageable = new RequestPageable();

      reqBody.setPagenumber(data.pageNumber);
      reqBody.setPagesize(data.pageSize);

      grpc.unary(GamificationService.GetAllMoodTriggerForAdmin, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<GetAllMoodTriggerResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log("All Triggers", message.toObject());
            dispatch(setTriggersListLoading(false));
            dispatch(triggersList(message.toObject()));
          } else {
            console.log(`Error in fetching triggers ${statusMessage}`);
            dispatch(setTriggersListLoading(false));
          }
        },
      });
    } catch (error) {
      dispatch(setTriggersListLoading(false));
      console.error("Error in add triggers", error);
    }
  };

export const addTrigger =
  (data: addTriggerType): AppThunk =>
  async (dispatch) => {
    try {
      console.log("run");
      const triggerName: Locale = new Locale();
      triggerName.setDefaultcontent(data.triggerName.defaultcontent);
      triggerName.setEn(data.triggerName.en);
      triggerName.setHi(data.triggerName.hi);
      triggerName.setTe(data.triggerName.te);
      triggerName.setGu(data.triggerName.gu);
      triggerName.setTa(data.triggerName.ta);
      triggerName.setKa(data.triggerName.ka);
      triggerName.setMr(data.triggerName.mr);
      triggerName.setIt(data.triggerName.it);
      triggerName.setRu(data.triggerName.ru);
      triggerName.setEs(data.triggerName.es);
      triggerName.setFr(data.triggerName.fr);
      triggerName.setPt(data.triggerName.pt);
      triggerName.setDe(data.triggerName.de);
      triggerName.setBn(data.triggerName.bn);
      triggerName.setMl(data.triggerName.ml);
      triggerName.setUk(data.triggerName.uk);
      triggerName.setOd(data.triggerName.od);
      triggerName.setEnUs(data.triggerName.enUs);
      triggerName.setEnAu(data.triggerName.enAu);
      triggerName.setPa(data.triggerName.pa);

      const payload = new MoodTriggerRequest();
      payload.setTiggername(triggerName);
      payload.setEmojiUrl(data.emojiUrl);
      console.log("run");

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.AddMoodTrigger, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<MoodTriggerResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("add trigger response", res.message.toObject());
              dispatch(getAllTriggers(data.pageable));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Trigger added successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in adding trigger : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in add trigger", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const updateTrigger =
  (data: updateTriggerType): AppThunk =>
  async (dispatch) => {
    try {
      const triggerName: Locale = new Locale();
      triggerName.setDefaultcontent(data.triggerName.defaultcontent);
      triggerName.setEn(data.triggerName.en);
      triggerName.setHi(data.triggerName.hi);
      triggerName.setTe(data.triggerName.te);
      triggerName.setGu(data.triggerName.gu);
      triggerName.setTa(data.triggerName.ta);
      triggerName.setKa(data.triggerName.ka);
      triggerName.setMr(data.triggerName.mr);
      triggerName.setIt(data.triggerName.it);
      triggerName.setRu(data.triggerName.ru);
      triggerName.setEs(data.triggerName.es);
      triggerName.setFr(data.triggerName.fr);
      triggerName.setPt(data.triggerName.pt);
      triggerName.setDe(data.triggerName.de);
      triggerName.setBn(data.triggerName.bn);
      triggerName.setMl(data.triggerName.ml);
      triggerName.setUk(data.triggerName.uk);
      triggerName.setOd(data.triggerName.od);
      triggerName.setEnUs(data.triggerName.enUs);
      triggerName.setEnAu(data.triggerName.enAu);
      triggerName.setPa(data.triggerName.pa);

      const payload = new MoodTriggerResponse();
      payload.setId(data.id);
      payload.setTiggername(triggerName);
      payload.setEmojiUrl(data.emojiUrl);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.UpdateMoodTrigger, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<MoodTriggerResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("edit trigger response", res.message.toObject());
              dispatch(getAllTriggers(data.pageable));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Trigger edited successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in editing trigger : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in edit trigger", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const deleteTriggerById =
  (data: deleteTriggerType): AppThunk =>
  async (dispatch) => {
    try {
      const payload = new ID();
      payload.setId(data.id);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.DeleteMoodTrigger, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("delete trigger response", res.message.toObject());
              dispatch(getAllTriggers(data.pageable));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Trigger deleted successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in deleting trigger : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in delete trigger", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };
