import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Grow,
  IconButton,
  ListItem,
  ListItemText,
  Snackbar,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllLostAndFoundItems } from "redux/actions/bhandaraActions";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import { RootState } from "redux/store/store";
import AddLostAndFound from "./AddLostAndFound";
import { firebaseAuth } from "config/firebase";
import { grpc } from "@improbable-eng/grpc-web";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import { HFN_EVENTS_SERVICE_HOST } from "api/serviceEndpoints";
import { ID } from "api/models/hfn-events-service/hfn-events_pb";
import DialogBox from "components/dialogBox/dialog";
import { ListSkeleton } from "components/listSkeleton";
import style from "./index.module.scss";

export const LostAndFound = React.forwardRef<HTMLDivElement, any>(
  ({}, ref): ReactElement => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [tabValue, setTabValue] = useState<1 | 2>(1);
    const [deleteLostFoundItem, setDeleteLostFoundItem] = useState<number>(0);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [page1, setPage1] = useState(0);
    const [rowsPerPage1, setRowsPerPage1] = useState(10);

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleChangePage1 = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage1(newPage);
    };

    const handleChangeRowsPerPage1 = (event: any) => {
      setRowsPerPage1(+event.target.value);
      setPage1(0);
    };

    const dispatch = useDispatch();
    const lostAndFoundData = useSelector(
      (state: RootState) => state.bhandara.lostAndFound.lostAndFoundData
    );

    console.log(lostAndFoundData);

    useEffect(() => {
      if (tabValue === 1) {
        dispatch(getAllLostAndFoundItems(rowsPerPage, page, tabValue));
      } else {
        dispatch(getAllLostAndFoundItems(rowsPerPage1, page1, tabValue));
      }
    }, [dispatch, page, page1, rowsPerPage, rowsPerPage1, tabValue]);

    const onClickBack = () => {
      setCurrentIndex(0);
    };

    const deleteItem = async (id: number) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const reqBody: ID = new ID();
      reqBody.setId(id);

      grpc.unary(HFNEventsService.DeleteLostAndFoundItem, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res) => {
          const { status } = res;
          if (status === grpc.Code.OK) {
            setSnackBar({
              open: true,
              severity: "success",
              message: "Item deleted successfully",
            });
            if (tabValue === 1) {
              dispatch(getAllLostAndFoundItems(rowsPerPage, page, tabValue));
            } else {
              dispatch(getAllLostAndFoundItems(rowsPerPage1, page1, tabValue));
            }
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: "Encountered an error while deleting lost & found item",
            });
          }
        },
      });
    };
    const onClickDelete = (id: number) => {
      setDeleteLostFoundItem(id);
      setShowDeleteModal(true);
    };
    const handleDelete = () => {
      deleteItem(deleteLostFoundItem);
      setShowDeleteModal(false);
    };
    const renderLostList = () => {
      return (
        <>
          <Box height="65vh" style={{ overflow: "auto" }}>
            {lostAndFoundData.lostAndFoundLData.lostandfounddetailsList.length >
            0 ? (
              <Box>
                {lostAndFoundData.lostAndFoundLData.lostandfounddetailsList.map(
                  (item: any, index: any) => (
                    <>
                      <Card
                        key={index}
                        sx={{
                          marginX: "25px",
                          marginY: "10px",
                          borderRadius: "1rem",
                        }}
                      >
                        <Stack direction="row">
                          <ListItem>
                            <ListItemText
                              primary={item.description}
                              secondary={item.keylocation}
                            />
                          </ListItem>

                          <Box
                            m={3}
                            display="flex"
                            flexDirection="column"
                            width={{ lg: "20%", md: "20%" }}
                          >
                            <Button
                              sx={{ mb: 3 }}
                              variant="contained"
                              color="secondary"
                              size="medium"
                              startIcon={<DeleteIcon />}
                              onClick={() => onClickDelete(item.id)}
                            >
                              <Typography variant="body2">Delete</Typography>
                            </Button>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              align="right"
                            >
                              {item.contactnumber}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="right"
                            >
                              {moment(
                                new Date(
                                  (Number(item.createdat?.seconds) +
                                    Number(item.createdat?.nanos) * 10 ** -9) *
                                    1000
                                ).toString()
                              ).format("DD MMM, hh:mm a")}
                            </Typography>
                          </Box>
                        </Stack>
                      </Card>
                    </>
                  )
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  fontWeight: 400,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  There is no lost reports at this time
                </Typography>
              </Box>
            )}
          </Box>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={Number(
              lostAndFoundData.lostAndFoundLData.pageable.totalcount
            )}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
            className={style.scrollbar}
          />
        </>
      );
    };

    const renderFoundList = () => {
      return (
        <>
          <Box height="65vh" style={{ overflow: "auto" }}>
            {lostAndFoundData.lostAndFoundLData.lostandfounddetailsList.length >
            0 ? (
              <Box>
                {lostAndFoundData.lostAndFoundLData.lostandfounddetailsList.map(
                  (item: any, index: any) => (
                    <>
                      <Card
                        key={index}
                        sx={{
                          marginX: "25px",
                          marginY: "10px",
                          borderRadius: "1rem",
                        }}
                      >
                        <Stack direction="row">
                          <ListItem>
                            <ListItemText
                              primary={item.description}
                              secondary={item.keylocation}
                            />
                          </ListItem>
                          <Box
                            m={3}
                            display="flex"
                            flexDirection="column"
                            width={{ lg: "20%", md: "20%" }}
                          >
                            <Button
                              sx={{ mb: 3 }}
                              variant="contained"
                              color="secondary"
                              size="medium"
                              startIcon={<DeleteIcon />}
                              onClick={() => onClickDelete(item.id)}
                            >
                              <Typography variant="body2">Delete</Typography>
                            </Button>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              align="right"
                            >
                              {item.contactnumber}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="right"
                            >
                              {moment(
                                new Date(
                                  (Number(item.createdat?.seconds) +
                                    Number(item.createdat?.nanos) * 10 ** -9) *
                                    1000
                                ).toString()
                              ).format("DD MMM, hh:mm a")}
                            </Typography>
                          </Box>
                        </Stack>
                      </Card>
                    </>
                  )
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  fontWeight: 400,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  There is no found reports at this time
                </Typography>
              </Box>
            )}
          </Box>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={Number(
              lostAndFoundData.lostAndFoundLData.pageable.totalcount
            )}
            page={page1}
            onPageChange={handleChangePage1}
            rowsPerPage={rowsPerPage1}
            onRowsPerPageChange={handleChangeRowsPerPage1}
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
            className={style.scrollbar}
          />
        </>
      );
    };

    return (
      <div ref={ref}>
        <Grow
          in={currentIndex === 0}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentIndex === 0 ? { timeout: 1000 } : {})}
        >
          <div>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                padding: " 16px",
              }}
            >
              <Grid item xs={12} sm={6} md={8} lg={6}>
                <Grid
                  container
                  spacing={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid item>
                    <Typography variant="h6">Lost & Found</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setCurrentIndex(1);
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add Lost / Found item
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "transparent" }}
              className="py-2"
            >
              <Grid display="flex" justifyContent="space-between">
                <Grid item>
                  <Tabs
                    value={tabValue}
                    onChange={(e, val) => {
                      setTabValue(val);
                    }}
                  >
                    <Tab label="Lost" value={1}></Tab>
                    <Tab label="Found" value={2}></Tab>
                  </Tabs>
                </Grid>
                <Grid item mr={2}>
                  <IconButton
                    onClick={() => {
                      if (tabValue === 1) {
                        dispatch(
                          getAllLostAndFoundItems(rowsPerPage, page, tabValue)
                        );
                      } else {
                        dispatch(
                          getAllLostAndFoundItems(rowsPerPage1, page1, tabValue)
                        );
                      }
                    }}
                    size="large"
                  >
                    <Tooltip title="Refresh">
                      <RefreshIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            {lostAndFoundData.loading
              ? ListSkeleton()
              : tabValue === 1
              ? renderLostList()
              : renderFoundList()}
          </div>
        </Grow>
        <Grow
          in={currentIndex === 1}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentIndex === 1 ? { timeout: 1000 } : {})}
        >
          <AddLostAndFound onClickBack={onClickBack} />
        </Grow>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
        <DialogBox
          open={showDeleteModal || false}
          onClose={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          type={"cancel"}
          title={`Are you sure that you want to remove this item ?`}
        />
      </div>
    );
  }
);
