import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { ListSkeleton } from "components/listSkeleton";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import AddIcon from "@mui/icons-material/Add";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import { getAllDonations } from "redux/actions/donationActions";
import { CreateDonation } from "./CreateDonation";

interface State {
  editing?: boolean;
  editItem?: any;
}
export default function DonationsScreen(): ReactElement {
  const dispatch = useDispatch();

  const donationList = useSelector(
    (state: RootState) => state.donations.donationsList
  );

  const initialState = {
    editing: false,
    editItem: null,
  };

  const [currentScreen, setcurrentScreen] = useState(1);
  const [state, setState] = useState<State>(initialState);
  const [searchMeditation, setsearchMeditation] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);

  const [pageNumber, setpageNumber] = useState(0);
  const [pageSize, setpageSize] = useState(10);

  useEffect(() => {
    dispatch(getAllDonations(pageSize, pageNumber));
    console.log(donationList);
  }, [dispatch, pageSize, pageNumber]);

  const onClickBack = () => {
    setcurrentScreen(1);
  };

  const renderDonationList = (data: any) => {
    return (
      <>
        {data.length ? (
          <>
            <TableContainer style={{ maxHeight: "400px" }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="left"> Title</TableCell>
                    <TableCell align="left">Amounts</TableCell>
                    <TableCell align="center"> Currency</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((donation: any, x: any) => (
                    <React.Fragment key={x}>
                      <TableRow
                        sx={{ cursor: "pointer", height: "50px" }}
                        hover
                        onClick={() => {
                          setState({
                            editing: true,
                            editItem: donation,
                          });
                          setcurrentScreen(3);
                        }}
                      >
                        <TableCell>{donation.title.defaultcontent}</TableCell>
                        <TableCell align="left">
                          {donation.amountsList.map((amt, i) => (
                            <Chip key={i} variant="outlined" label={amt} />
                          ))}
                        </TableCell>
                        <TableCell align="center">
                          {donation.currency}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                Not enough data
              </Typography>
            </Grid>
          </Box>
        )}
        <Divider />
        <Grid container justifyContent="flex-end">
          <Grid item style={{ width: "450px", overflowX: "auto" }}>
            <Grid
              container
              className="d-flex justify-content-end align-items-center"
            >
              <Grid className="mr-2">
                <Typography>Rows per page:</Typography>
              </Grid>
              <Grid>
                <FormControl variant="standard">
                  <Select
                    value={pageSize}
                    onChange={(e) => {
                      setpageSize(Number(e.target.value));
                      // dispatch(
                      //   getAllDonations(Number(e.target.value), pageNumber)
                      // );
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid className="mx-2">
                {data.length ? (
                  <Typography>
                    {searchMeditation === ""
                      ? `1-${data.length} of ${data.length}`
                      : `1-${searchData.length} of ${searchData.length}`}
                  </Typography>
                ) : null}
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    if (pageNumber > 0) {
                      const previousPage = pageNumber - 1;
                      setpageNumber(pageNumber - 1);
                      //setpageSize(10);
                      // dispatch(getAllDonations(pageSize, previousPage));
                    }
                  }}
                  disabled={pageNumber === 0}
                  size="large"
                >
                  <ChevronLeftRounded />
                </IconButton>
                <Typography>{pageNumber + 1}</Typography>
                <IconButton
                  onClick={() => {
                    const nextPage = pageNumber + 1;
                    setpageNumber(pageNumber + 1);
                    // dispatch(getAllDonations(pageSize, nextPage));
                  }}
                  disabled={!data.length}
                  size="large"
                >
                  <ChevronRightRounded />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Card className="mt-3 mb-5" style={{ position: "relative" }}>
              <Grow
                in={currentScreen === 1}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 1 ? { timeout: 1000 } : {})}
              >
                <div>
                  <Grid container className="align-items-center p-3">
                    <Typography variant="h6">Donation Cards</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setcurrentScreen(2);
                        setState({ editing: false });
                      }}
                      startIcon={<AddIcon />}
                      className="ml-3"
                    >
                      Create Donation
                    </Button>
                  </Grid>
                  <Divider />
                  <CardContent>
                    <Paper>
                      {donationList.loading
                        ? ListSkeleton()
                        : renderDonationList(donationList.donations)}
                    </Paper>
                  </CardContent>
                </div>
              </Grow>
              <Grow
                in={currentScreen === 2}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 2 ? { timeout: 1000 } : {})}
              >
                <CreateDonation
                  onClickBack={onClickBack}
                  pageSize={pageSize}
                  pageNumber={pageNumber}
                />
              </Grow>
              <Grow
                in={currentScreen === 3}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 2 ? { timeout: 1000 } : {})}
              >
                <CreateDonation
                  isEditing={state.editing}
                  editItem={state.editItem}
                  onClickBack={onClickBack}
                  pageSize={pageSize}
                  pageNumber={pageNumber}
                />
              </Grow>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
