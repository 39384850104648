import {
  Alert,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { Tag } from "api/models/gamification-service/gamification_pb";
import { AppThunk } from "redux/store/store";
import { tagsListIsLoading } from "redux/reducers/tagReducer";
import { Tags } from "api/models/live-meditation-service/live-meditation_pb";
import { firebaseAuth } from "config/firebase";
import { LIVE_MEDITATION_SERVICE_HOST } from "api/serviceEndpoints";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";

interface Props {
  onClickBack: () => void;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  isEditing: boolean;
  editItem: any;
}

const LiveSessionTagForm = ({
  onClickBack,
  setCurrentIndex,
  isEditing,
  editItem,
}: Props) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [noOfTags, setNoOfTags] = useState<any>([new Tag().setName("")]);
  const [tagName, setTagName] = useState<string>("");
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  function nth(n: number) {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  }

  const DeleteTag = (id: number) => {
    let tags = [...noOfTags];
    tags.splice(id, 1);
    setNoOfTags(tags);
  };

  const AddTag = () => {
    setNoOfTags((tags) => [...tags, new Tag().setName("")]);
  };

  const createNewTags = () => {
    const temp = noOfTags.map((item) => {
      return String(item.getName().trim());
    });

    if (temp.includes(""))
      setSnackBar({
        open: true,
        severity: "error",
        message: "Empty tags are not allowed",
      });
    else {
      dispatch(AddLiveSessionTags(noOfTags));
    }
  };

  const updateTags = () => {
    if (tagName.trim().length === 0)
      return setSnackBar({
        open: true,
        severity: "error",
        message: "Empty tags are not allowed",
      });
    const updatedTag = new Tag();
    updatedTag.setId(editItem.id);
    updatedTag.setName(tagName.trim());

    const data = [updatedTag];

    dispatch(AddLiveSessionTags(data));
  };

  useEffect(() => {
    if (isEditing) setTagName(editItem.name);
  }, [isEditing]);

  const AddLiveSessionTags =
    (tagData: any): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(tagsListIsLoading(true));
        setDisabled(true);
        const reqBody: Tags = new Tags();
        reqBody.setTagsList(tagData);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();

        grpc.unary(LiveMeditationService.AddOrUpdateMultipleTags, {
          request: reqBody,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<Tags>) => {
            const { status, message, statusMessage } = res;
            if (status === grpc.Code.OK && message) {
              console.log("Tags Added: ", message.toObject());

              setSnackBar({
                open: true,
                severity: "success",
                message: "Tags added successfully",
              });
              dispatch(tagsListIsLoading(false));
              setTimeout(() => {
                onClickBack();
              }, 1000);
            } else {
              console.log("Error in uploading tag: ", statusMessage);
              setSnackBar({
                open: true,
                severity: "error",
                message: "Failed to add tags",
              });
              dispatch(tagsListIsLoading(false));
              setTimeout(() => {
                onClickBack();
              }, 1000);
            }
          },
        });
      } catch (err) {
        console.log("Error in sending tags: ", err);
        setSnackBar({
          open: true,
          severity: "error",
          message: "Failed to add tags",
        });
        dispatch(tagsListIsLoading(false));
        setTimeout(() => {
          onClickBack();
        }, 1000);
      }
    };

  return (
    <>
      <Grid container spacing={1} sx={{ p: 2 }} alignItems="center">
        <Grid item>
          <IconButton onClick={() => setCurrentIndex(0)}>
            <KeyboardBackspaceSharpIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h6">Add Tags</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Typography color="textSecondary" className="pl-3 pt-3">
        Tags
      </Typography>
      <CardContent>
        <Grid container spacing={3}>
          {noOfTags.map((item: any, index: any) => (
            <Grid
              item
              xs={12}
              key={index}
              className="d-flex align-items-start mt-2"
            >
              {isEditing ? (
                <TextField
                  label="Tag Name"
                  name="Tags"
                  variant="outlined"
                  inputProps={{
                    style: {
                      fontSize: 14,
                    },
                  }}
                  value={tagName}
                  onChange={(e) => {
                    setTagName(e.target.value);
                  }}
                  sx={{ flexGrow: 1 }}
                />
              ) : (
                <TextField
                  label={`${index + 1}${nth(index + 1)} tag`}
                  name="Tags"
                  variant="outlined"
                  inputProps={{
                    style: {
                      fontSize: 14,
                    },
                  }}
                  value={item.tag}
                  onChange={(e) => {
                    let tagName = [...noOfTags];
                    tagName[index] = new Tag().setName(e.target.value.trim());
                    setNoOfTags(tagName);
                  }}
                  sx={{ flexGrow: 1 }}
                />
              )}

              <Grid>
                {index ? (
                  <IconButton onClick={() => DeleteTag(index)} size="large">
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <IconButton sx={{ visibility: "hidden" }} size="large">
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container mt={3}>
          {!isEditing && (
            <Grid container mt={3}>
              <Button
                color="primary"
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={AddTag}
              >
                Add More
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Divider />
      <Grid container p={3}>
        <Grid item flexGrow={1}></Grid>
        <Grid item>
          {isEditing ? (
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              sx={{ textTransform: "none" }}
              onClick={() => {
                updateTags();
              }}
            >
              {isEditing && "Update Tag"}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              sx={{ textTransform: "none" }}
              onClick={() => {
                createNewTags();
              }}
            >
              {noOfTags.length > 1 ? "Add Tags" : "Add Tag"}
            </Button>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LiveSessionTagForm;
