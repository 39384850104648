export const setLocalisedContent = (item,language) => {
   
    switch (language) {
      case 1:
        return {
          id:item.id,
          title: item.title.defaultcontent,
          description:item.description.defaultcontent,
          meditationsteps:item.meditationsteps.defaultcontent,
          videourl:item.videourl.defaultcontent,
          imgurl: item.imgurl,
          thumbnail:item.thumbnail
        };
      case 2:
        return {
          id:item.id,
          title: item.title.en,
          description:item.description.en,
          meditationsteps:item.meditationsteps.en,
          videourl:item.videourl.en,
          imgurl: item.imgurl,
          thumbnail:item.thumbnail
        };
      case 3:
        return {
          id:item.id,
          title: item.title.hi,
          description:item.description.hi,
          meditationsteps:item.meditationsteps.hi,
         
          videourl:item.videourl.hi,
          imgurl: item.imgurl,
          thumbnail:item.thumbnail
        };
      case 4:
        return {
          id:item.id,
          title: item.title.te,
          description:item.description.te,
          meditationsteps:item.meditationsteps.te,
          
          videourl:item.videourl.te,
          imgurl: item.imgurl,
          thumbnail:item.thumbnail
        };
      case 5:
        return {
          id:item.id,
          title: item.title.gu,
          description:item.description.gu,
          meditationsteps:item.meditationsteps.gu,
         
          videourl:item.videourl.gu,
          imgurl: item.imgurl,
          thumbnail:item.thumbnail
        };
      case 6:
        return {
          id:item.id,
          title: item.title.ta,
          description:item.description.ta,
          meditationsteps:item.meditationsteps.ta,
          videourl:item.videourl.ta,
          imgurl: item.imgurl,
          thumbnail:item.thumbnail
        };
      case 7:
        return {
          id:item.id,
          title: item.title.ka,
          description:item.description.ka,
          meditationsteps:item.meditationsteps.ka,  
          videourl:item.videourl.ka,
          imgurl: item.imgurl,
          thumbnail:item.thumbnail
        };
    }
  };