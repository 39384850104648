import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MeditationFileDetails,
  MeditationFileDetailsV2,
  PurposeFile,
  PurposeFileDetails,
} from "api/models/guided-meditation-service/guided-meditation_pb";

interface GuidedMeditationDefaultState {
  meditationList: {
    loading: boolean;
    guidedList?: Array<MeditationFileDetails.AsObject>;
  };
  purposeMeditationList: {
    loading: boolean;
    purposeList?: Array<MeditationFileDetailsV2.AsObject>;
    totalcount?: number;
  };
  imageUrl: {
    url?: string;
    loading: boolean;
  };
  audioUrl: {
    url?: string;
    loading: boolean;
  };
  videoUrl: {
    url?: string;
    loading: boolean;
  };
  purposeFileList: {
    loading: boolean;
    purposeFile: PurposeFile.AsObject[];
  };
  currentDurationFile: any;
  purposeFile: any;
}

const initialState: GuidedMeditationDefaultState = {
  meditationList: {
    loading: true,
    guidedList: [],
  },
  purposeMeditationList: {
    loading: true,
    purposeList: [],
  },
  imageUrl: {
    loading: true,
  },
  audioUrl: {
    loading: true,
  },
  videoUrl: {
    loading: true,
  },
  purposeFileList: {
    loading: false,
    purposeFile: [],
  },
  currentDurationFile: {
    id: 0,
    durationinseconds: 0,
    imageurl: "",
    selectedimageurl: "",
  },
  purposeFile: PurposeFileDetails,
};

export const meditationListSlice = createSlice({
  name: "meditationList",
  initialState,
  reducers: {
    meditationListLoading: (state, action: PayloadAction) => {
      action.type === "LIST"
        ? (state.meditationList.loading = true)
        : action.type === "IMAGE"
        ? (state.imageUrl.loading = true)
        : action.type === "AUDIO"
        ? (state.audioUrl.loading = true)
        : (state.meditationList.loading = true);
    },
    meditationListUpdated: (
      state,
      action: PayloadAction<MeditationFileDetails.AsObject[]>
    ) => {
      state.meditationList.loading = false;
      state.meditationList.guidedList = action.payload;
    },
    meditationListError: (state, action: PayloadAction) => {
      action.type === "LIST"
        ? (state.meditationList.loading = false)
        : action.type === "IMAGE"
        ? (state.imageUrl.loading = false)
        : action.type === "AUDIO"
        ? (state.audioUrl.loading = false)
        : (state.meditationList.loading = false);
    },
    meditationListUpdateImage: (state, action: PayloadAction<string>) => {
      state.imageUrl.loading = false;
      state.imageUrl.url = action.payload;
    },
    meditationListUpdateAudio: (state, action: PayloadAction<string>) => {
      state.audioUrl.loading = false;
      state.audioUrl.url = action.payload;
    },
    purposeMeditationListLoading: (state) => {
      state.purposeMeditationList.loading = true;
    },
    purposeMeditationListUpdate: (
      state,
      action: PayloadAction<{
        meditationfiledetailsv2List: MeditationFileDetailsV2.AsObject[];
        totalcount: number;
      }>
    ) => {
      state.purposeMeditationList.loading = false;
      state.purposeMeditationList.purposeList =
        action.payload.meditationfiledetailsv2List;
      state.purposeMeditationList.totalcount = action.payload.totalcount;
    },
    purposeMeditationListError: (state) => {
      state.purposeFileList.loading = false;
    },
    purposeFileListUpLoading: (state) => {
      state.purposeFileList.loading = true;
    },
    purposeFileListUpdate: (
      state,
      action: PayloadAction<PurposeFile.AsObject[]>
    ) => {
      state.purposeFileList.loading = false;
      state.purposeFileList.purposeFile = action.payload;
    },
    purposeFileListUpError: (state) => {
      state.purposeFileList.loading = false;
    },
    setCurrentDurationFile: (state, action) => {
      state.currentDurationFile = action.payload;
    },
    setPurposeFile: (state, action) => {
      state.purposeFile = action.payload;
    },
  },
});

export const {
  meditationListLoading,
  meditationListUpdated,
  meditationListError,
  meditationListUpdateImage,
  meditationListUpdateAudio,
  purposeMeditationListLoading,
  purposeMeditationListUpdate,
  purposeMeditationListError,
  purposeFileListUpLoading,
  purposeFileListUpdate,
  purposeFileListUpError,
  setCurrentDurationFile,
  setPurposeFile,
} = meditationListSlice.actions;

export default combineReducers({
  meditationList: meditationListSlice.reducer,
});
