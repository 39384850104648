import {
  CircularProgress,
  Avatar,
  Alert,
  Grid,
  IconButton,
  Typography,
  Divider,
  CardContent,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Card,
  Button,
  CardActions,
  Snackbar,
  CardActionArea,
  OutlinedInput,
  Checkbox,
  ListItemText,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store/store";
import DeleteIcon from "@mui/icons-material/Delete";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import AddIcon from "@mui/icons-material/Add";
import {
  CreateLiveMeditationRequest,
  RequestUpdateBenefits,
  ResponseStatus,
  SessionInfo,
  Tag,
  UpdateBenefits,
} from "api/models/live-meditation-service/live-meditation_pb";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import {
  GAMIFICATION_SERVICE_HOST,
  LIVE_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import {
  getAllLiveMeditationsBetweenDatesV2,
  getCoachListAsync,
  getAllSessionBenifitsByCoachId,
  getAllBenifitsBySessionId,
  getAllLiveSessionTags,
} from "redux/actions/liveMeditationAction";
import { schedularUpdateStep } from "redux/reducers/liveMeditationReducer";
import makeStyles from "@mui/styles/makeStyles";
import { ImageOutlined } from "@mui/icons-material";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";
import { DeleteFileRequest } from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import ImageCrop from "components/image-crop/image-crop";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import { getCroppedImg, dataURLtoFile, toUploadFile } from "utils/helpers";
import { useFileUploadService } from "services/FileUplaodService";
import { MenuProps } from "styles/global.styles";

interface LiveSessionProps {
  onClickBack?: any;
  isEditing: boolean;
  item?: any;
  setsessionId?: any;
  setCurrentScreen?: any;
  type?: any;
  rowsPerPage: number;
  cereateSessionHandler?: any;
  page: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    maxWidth: 350,
  },
  rootVideo: {
    height: 200,
    width: 300,
  },
  imgCardHeader: {
    height: "20%",
    textOverflow: "elipse",
  },
  progress: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },
  formBtn: {
    marginRight: "20px",
  },
  thumbNail: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
  },
  media: {
    height: "60%",
  },
  actionIcon: {
    alignSelf: "center",
    left: "45%",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },
}));

interface Crop {
  unit: "%" | "px" | undefined;
  width: number;
  aspect: number;
}

export const CreateLiveSessionForm = React.forwardRef<
  HTMLDivElement,
  LiveSessionProps
>(
  (
    {
      onClickBack,
      isEditing,
      item,
      setsessionId,
      type,
      rowsPerPage,
      page,
      cereateSessionHandler,
      setCurrentScreen,
    },
    ref
  ) => {
    const rootState = useSelector((state: RootState) => state.liveMeditation);
    const languages = useSelector((state: RootState) => state.app.languages);
    const tagsList = useSelector((state: RootState) => state.tags.tagList);

    const cropDef: Crop = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };

    const hiddenIconInput: any = React.useRef([]);
    const classes = useStyles();

    const dispatch = useDispatch();
    const [id, setId] = useState(0);
    const [sessionTitle, setsessionTitle] = useState("");
    const [aboutSession, setaboutSession] = useState("");
    const [selectedTagsId, setSelectedTagsId] = useState<number[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [link, setLink] = useState("");
    const [language, setLanguage] = useState<any>(2);
    const [showAlert, setshowAlert] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
    const [tagpage, setTagPage] = useState<number>(0);
    const [duration, setduration] = useState<number>(30);
    const [benefitsArr, setbenefitsArr] = useState<any>([]);
    const [benefitsArrNew, setbenefitsArrNew] = useState<any>([]);
    const [streamSource, setStreamSource] = useState<1 | 2>(1);
    const [videoUrl, setVideoUrl] = useState("");
    const [editSessionBenefits, seteditSessionBenefits] = useState<any>({
      editBenefit: false,
      addNewBenefit: false,
      addExistingBenefit: false,
    });
    const { appendFilesToUploadManager } = useFileUploadService();
    const [stepsForMeditation, setstepsForMeditation] = useState<any>([
      { stepValue: "" },
      { stepValue: "" },
      { stepValue: "" },
      { stepValue: "" },
      { stepValue: "" },
      { stepValue: "" },
    ]);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const [iconRef, seticonRef] = useState<any>();
    const [iconSrc, seticonSrc] = useState<any>(null);
    const [iconCrop, seticonCrop] = useState<Crop>(cropDef);
    const [iconFile, seticonFile] = useState<any>(null);
    const [croppedIcon, setCroppedIcon] = useState<any>(null);

    const [open, setopen] = useState(false);
    const [currentIconIndex, setcurrentIconIndex] = useState(0);
    const [activityType, setActivityType] = useState<string>("");

    const onClickEdit = React.useCallback(
      (item: any) => {
        // dispatch(getAllBenifitsBySessionId(item.sessioninfo?.id));
        setsessionId(item.sessioninfo?.id);
        setId(item.sessioninfo?.id);
        setsessionTitle(item.sessioninfo?.sessiontitle);
        setaboutSession(item.sessioninfo?.aboutsession);
        setLink(item.sessioninfo?.link);
        setduration(item.sessioninfo?.duration);
        const arr = item.sessioninfo?.meditationsteps
          .split("\n")
          .map((step: any) => ({ stepValue: step }));
        setstepsForMeditation(arr);
        setLanguage(item.sessioninfo?.selectedlanguage);
        setIsDuplicate(true);
        setSelectedTagsId(item.sessioninfo?.tagidsList);
        setSelectedTags(item.sessioninfo?.tagsList.map((item) => item.name));
        setActivityType(item.sessioninfo?.attribute);
        setStreamSource(item.sessioninfo?.streamsource);
        setVideoUrl(item.sessioninfo?.videourl);
      },
      [setsessionId]
    );

    const onEditBenifits = React.useCallback(() => {
      const arr = rootState.benifitsListById.benifits.listofbenefitsList.map(
        (item) => ({
          idofbenefit: item.id,
          description: item.description,
          imageurl: item.imageurl,
          removebenefit: false,
          editBenefits: false,
        })
      );
      // console.log(
      //   "benefit array",
      //   rootState.benifitsListById.benifits.listofbenefitsList
      // );
      setbenefitsArr(arr);
      setbenefitsArrNew(arr);
    }, [rootState.benifitsListById.benifits.listofbenefitsList]);

    useEffect(() => {
      dispatch(getAllLiveSessionTags(10, tagpage, false));
    }, [tagpage]);

    useEffect(() => {
      console.log(isEditing, type, item);
      if (isEditing || type === "view") {
        onClickEdit(item);
      }
      onEditBenifits();
    }, [isEditing, item, onClickEdit, onEditBenifits, type]);

    const nth = (n: number) => {
      return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
    };

    const onClickAdd = () => {
      setstepsForMeditation([...stepsForMeditation, { stepValue: "" }]);
    };

    const onClickDelete = (i: any) => {
      let newstepsForMeditation = [...stepsForMeditation];
      newstepsForMeditation.splice(i, 1);
      setstepsForMeditation(newstepsForMeditation);
    };

    const emptyData = () => {
      setsessionTitle("");
      setaboutSession("");
      setduration(30);
    };

    const handleMultipleTagChange = (event: any) => {
      const {
        target: { value },
      } = event;
      // console.log(value);

      const arrOfNum: number[] = (
        typeof value === "string" ? value.split(",") : value
      ).map((str) => {
        return Number(str);
      });
      const val = selectedTagsId.filter((item) => value.indexOf(item) === -1);
      const tagIndex = selectedTagsId.indexOf(val[0]);

      setSelectedTagsId(arrOfNum);

      if (tagIndex > -1) {
        setSelectedTags(() => {
          const updatedTags = [...selectedTags];
          updatedTags.splice(tagIndex, 1);
          // console.log("updated Tags: ", updatedTags);
          return updatedTags;
        });
      } else if (tagIndex === -1) {
        setSelectedTags(() => {
          const temp = tagsList.tags.tagsList.filter((item) =>
            value.includes(item.id)
          );

          const arrOfString = temp.map((item: any) => item.name);
          return [...new Set([...selectedTags, ...arrOfString])];
        });
      }
    };

    const tagsArray = () => {
      let arr: Tag[] = [];
      for (const tagIndex in selectedTags) {
        const temp = new Tag();
        temp.setId(selectedTagsId[tagIndex]);
        temp.setName(selectedTags[tagIndex]);
        arr.push(temp);
      }
      console.log("tagsArray: ", arr);
      return arr;
    };

    const validateForm = () => {
      if (sessionTitle.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "session title can not be empty",
        });
      } else if (aboutSession.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "about session can not be empty",
        });
      } else if (stepsForMeditation[0].stepValue.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Atleast one meditation step is required",
        });
      } else if (selectedTagsId.length === 0) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Minimum 1 tag needs to be selected",
        });
      } else if (selectedTagsId.length > 3) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Maximum 3 tags can be selected",
        });
      } else if (activityType.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Activity type  can not be empty",
        });
      } else {
        return true;
      }
    };

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setTagPage(newPage);
    };

    const handleSourceChange = (event: any) => {
      setStreamSource(event.target.value);
    };

    const updateBenefits = () => {
      const data = {
        updateBenefits: benefitsArrNew,
        liveSessionID: id,
      };
      UpdateSessionBenefits(data);
    };
    const UpdateSessionBenefits = async (data: any) => {
      console.log("update benefit data", data);
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: RequestUpdateBenefits = new RequestUpdateBenefits();
        reqBody.setUpdatebenefitsList(
          data.updateBenefits.map((benefits: UpdateBenefits.AsObject) => {
            let _updateBenifits = new UpdateBenefits();
            _updateBenifits.setDescription(benefits.description);
            _updateBenifits.setIdofbenefit(benefits.idofbenefit);
            _updateBenifits.setImageurl(benefits.imageurl);
            _updateBenifits.setRemovebenefit(benefits.removebenefit);
            return _updateBenifits;
          })
        );
        reqBody.setLivesessionid(data.liveSessionID);
        grpc.unary(LiveMeditationService.UpdateSessionBenefits, {
          request: reqBody,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ProtobufMessage>) => {
            const { status, message } = res;

            if (status === grpc.Code.OK && message) {
              console.log("all ok: ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: "session benefits updated successfully",
              });
              //dispatch(getAllLiveMeditationsBetweenDates(start, end));
              dispatch(getAllBenifitsBySessionId(Number(id)));
              setCurrentScreen(1);
              seteditSessionBenefits({
                ...editSessionBenefits,
                editBenefit: false,
              });
              return message;
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in  update session benefit",
              });
              dispatch(getAllBenifitsBySessionId(Number(id)));
              seteditSessionBenefits({
                ...editSessionBenefits,
                editBenefit: false,
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error in  updating benefit",
        });
        console.error("something went wrong in update session benefits", error);
      }
    };

    const _createSession = () => {
      const medSteps = stepsForMeditation
        .filter((item: any) => item.stepValue !== "")
        .map((item: any, index: any) => `${index + 1}.${item.stepValue}`)
        .join("\n");
      const medStepsUpdate = stepsForMeditation
        .filter((item: any) => item.stepValue !== "")
        .map((item: any, index: any) => `${item.stepValue}`)
        .join("\n");

      if (validateForm()) {
        const data = {
          id: id,
          scheduledTime: moment(new Date()).tz(moment.tz.guess()).format(),
          sessionTitle: sessionTitle,
          sessionSubTitle: "",
          sessionInfo: "",
          duration: duration,
          registeredUserCount: 0,
          liveParticipantsCount: 0,
          aboutSession: aboutSession,
          meditationSteps:
            isEditing || type === "copy" ? medStepsUpdate : medSteps,
          associatedCoachId: 0,
          imageURL: "",
          languageType: language,
          link: link,
          attribute: activityType,
          streamSource: streamSource,
          videoURL: streamSource === 2 ? videoUrl : "",
        };

        if (type === "edit") {
          updateSession(data);
        } else {
          scheduleSession(data);
        }
        setTimeout(() => {
          onClickBack();
        }, 500);
      }
    };

    //---------------------------for icon upload------------------------------------->

    const handleIconClick = (idx: any) => {
      hiddenIconInput.current[idx].click();
    };
    const onIconSelection = (event: any, index: any) => {
      setcurrentIconIndex(index);
      if (event.target.files[0]) {
        seticonFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          seticonSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onIconCropDone = async () => {
      const cr: any = iconCrop;
      if (iconRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(iconRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${iconFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        const uploadFile = toUploadFile(
          croppedFile,
          "ProfilePicture".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              let newbenefitsArrNew = [...benefitsArrNew];
              newbenefitsArrNew[currentIconIndex].imageurl =
                res.detail.response;
              setbenefitsArr(newbenefitsArrNew);
              let newbenefitsArr = [...benefitsArr];
              newbenefitsArr[currentIconIndex].imageurl = res.detail.response;
              setbenefitsArr(newbenefitsArr);
            }
          }
        );
        setCroppedIcon(croppedFile);
      }
      seticonSrc(null);
    };

    const onIconClose = (index: number) => {
      seticonSrc(null);
      seticonFile(null);
      setCroppedIcon(null);
      seticonFile(null);
      seticonCrop(cropDef);
      let newbenefitsArrNew = [...benefitsArrNew];
      _deleteFileFromGcp(newbenefitsArrNew[index].imageurl);
      newbenefitsArrNew[index].imageurl = "";
      setbenefitsArr(newbenefitsArrNew);
      let newbenefitsArr = [...benefitsArr];
      newbenefitsArr[index].imageurl = "";

      setbenefitsArr(newbenefitsArr);
    };

    const onIconLoaded = (image: any) => {
      seticonRef(image);
    };

    const onIconCropChange = (crop: any) => {
      seticonCrop(crop);
    };
    const onIconCropComplete = async () => {
      //console.log("state crop", crop, imageRef);
    };

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);

        //CroppedImageUrl
        //setIsLoading(true);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                //setIsLoading(false);
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
                let newbenefitsArr = [...benefitsArr];
                newbenefitsArr[currentIconIndex].imageurl = "";
                setbenefitsArr(newbenefitsArr);
              }
            } else {
              setIsLoading(false);
              // setSnackBar({
              //   open: true,
              //   severity: "error",
              //   message: "error in removing coach image",
              // });
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };

    const _onOpenAddCoach = () => {
      setopen(true);
    };
    const _onCloseAddCoach = () => {
      setopen(false);
    };
    //------------------------icon upload end----------------------------------------->

    const scheduleSession = async (data: any) => {
      try {
        setIsLoading(true);
        console.log("create session data", data);
        var addmeditaionReq = new CreateLiveMeditationRequest();
        const currentGoogleTimeStamp: Timestamp = new Timestamp();
        currentGoogleTimeStamp.fromDate(new Date(data.scheduledTime));
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();

        addmeditaionReq.setScheduledtime(currentGoogleTimeStamp);
        addmeditaionReq.setSessiontitle(data.sessionTitle);
        addmeditaionReq.setSessionsubtitle(data.sessionSubTitle);
        addmeditaionReq.setSessioninfo(data.sessionInfo);
        addmeditaionReq.setDuration(Number(data.duration));
        addmeditaionReq.setAboutsession(data.aboutSession);
        addmeditaionReq.setAssociatedcoachid(data.associatedCoachId);
        addmeditaionReq.setLiveparticipantscount(data.liveParticipantsCount);
        addmeditaionReq.setRegisteredusercount(data.registeredUserCount);
        addmeditaionReq.setMeditationsteps(data.meditationSteps);
        addmeditaionReq.setImageurl(data.imageURL);
        addmeditaionReq.setVideourl(data.videoURL);
        addmeditaionReq.setLanguagetype(data.languageType);
        addmeditaionReq.setLink(data.link);
        addmeditaionReq.setTagsList(tagsArray());
        addmeditaionReq.setAttribute(data.attribute);
        addmeditaionReq.setStreamsource(data.streamSource);
        grpc.unary(LiveMeditationService.createSession, {
          request: addmeditaionReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<SessionInfo>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            console.log(grpc.Code);
            if (status === grpc.Code.OK && message) {
              const sessionData = message.toObject();
              console.log("all ok: ", message.toObject());
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "success",
                message: " Session created successfully",
              });
              dispatch(
                getAllLiveMeditationsBetweenDatesV2(rowsPerPage, page, true)
              );

              if (isEditing || type === "copy") {
                if (
                  rootState.benifitsListById.benifits.listofbenefitsList.length
                ) {
                  setshowAlert(true);
                  setIsLoading(true);
                } else {
                  setTimeout(() => {
                    onClickBack();
                  }, 2000);
                }
              } else {
                emptyData();
                setTimeout(() => {
                  dispatch(schedularUpdateStep(1));
                  cereateSessionHandler(sessionData.id);
                }, 2000);
                dispatch(getCoachListAsync());
                dispatch(
                  getAllSessionBenifitsByCoachId(3, 0, data.associatedCoachId)
                );
              }
              return message;
            } else {
              setIsLoading(false);
              console.log("error in scheduling test", statusMessage);
              let str = statusMessage.toString();
              setSnackBar({
                open: true,
                severity: "error",
                message: str.substring(str.indexOf(":") + 1),
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in schedule session",
        });
        console.error("something went wrong in schedule session", error);
      }
    };
    const onDeleteBenefits = (i: any) => {
      let newbenefitsArr = [...benefitsArr];
      newbenefitsArr.splice(i, 1);
      setbenefitsArr(newbenefitsArr);
    };

    const updateSession = async (data: any) => {
      try {
        setIsLoading(true);
        console.log("updated session data", data);
        var updatemeditaionReq = new SessionInfo();

        const currentGoogleTimeStamp: Timestamp = new Timestamp();
        currentGoogleTimeStamp.fromDate(new Date(data.scheduledTime));
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        updatemeditaionReq.setId(data.id);
        updatemeditaionReq.setScheduledtime(currentGoogleTimeStamp);
        updatemeditaionReq.setSessiontitle(data.sessionTitle);
        updatemeditaionReq.setSessionsubtitle(data.sessionSubTitle);
        updatemeditaionReq.setSessioninfo(data.sessionInfo);
        updatemeditaionReq.setDuration(Number(data.duration));
        updatemeditaionReq.setAboutsession(data.aboutSession);
        updatemeditaionReq.setAssociatedcoachid(data.associatedCoachId);
        updatemeditaionReq.setLiveparticipantscount(data.liveParticipantsCount);
        updatemeditaionReq.setRegisteredusercount(data.registeredUserCount);
        updatemeditaionReq.setMeditationsteps(data.meditationSteps);
        updatemeditaionReq.setImageurl(data.imageURL);
        updatemeditaionReq.setVideourl(data.videoURL);
        updatemeditaionReq.setStreamsource(data.streamSource);
        updatemeditaionReq.setLink(data.link);
        updatemeditaionReq.setTagsList(tagsArray());
        updatemeditaionReq.setAttribute(data.attribute);

        grpc.unary(LiveMeditationService.UpdateScheduleSession, {
          request: updatemeditaionReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<SessionInfo>) => {
            const { status, message } = res;
            setIsLoading(false);
            if (status === grpc.Code.OK && message) {
              console.log("all ok: ", message.toObject());
              // const updatedData = message.toObject();
              setSnackBar({
                open: true,
                severity: "success",
                message: "session updated successfully",
              });
              dispatch(
                getAllLiveMeditationsBetweenDatesV2(rowsPerPage, page, true)
              );
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in  update session ",
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "error in  update session ",
        });
        console.error("something went wrong in update session", error);
      }
    };
    return (
      <div ref={ref}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        {showAlert && (
          <Alert
            icon={<CircularProgress size={20} />}
            severity="info"
            style={{ position: "absolute", top: "50%", right: "50%" }}
          >
            Adding benefits...
          </Alert>
        )}
        <Grid
          container
          spacing={1}
          style={{
            padding: " 8px",
            alignItems: "center",
          }}
        >
          <Grid item>
            <IconButton onClick={() => onClickBack()} size="large">
              <KeyboardBackspaceIcon color="action" />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {type !== "view" &&
                (type === "edit"
                  ? "Edit defined session"
                  : "Define live session")}
              {type === "view" && "Session info"}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3} className="mt-2">
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="language-select">Language</InputLabel>
                {languages && (
                  <Select
                    disabled={isDuplicate}
                    labelId="language-select"
                    value={language}
                    label="Language"
                    onChange={(e) => {
                      setLanguage(e.target.value as number);
                      // setIsLangSelected(true);
                    }}
                    MenuProps={MenuProps}
                  >
                    {languages.map((lang, i) => (
                      <MenuItem key={i} value={lang.code}>
                        {lang.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={10} xs={8}>
              <TextField
                fullWidth
                label="Session Title"
                name="session title"
                required
                variant="outlined"
                value={sessionTitle}
                onChange={(e) => setsessionTitle(e.target.value)}
                inputProps={{
                  readOnly: type === "view" ? true : false,
                }}
              />
            </Grid>
            <Grid item lg={2} xs={4}>
              <TextField
                fullWidth
                required
                defaultValue={30}
                type="number"
                label="Duration"
                value={duration}
                error={type !== "view" && (duration < 30 || duration > 60)}
                helperText={
                  type !== "view" &&
                  (duration < 30 || duration > 60
                    ? "Enter a value between 30 and 60 minutes"
                    : "")
                }
                inputProps={{
                  readOnly: type === "view" ? true : false,
                }}
                onChange={(e) => {
                  setduration(e.target.value as unknown as number);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="activityType"
                value={activityType}
                inputProps={{
                  readOnly: type === "view" ? true : false,
                }}
                onChange={(e) => {
                  setActivityType(e.target.value);
                }}
                label="Activity Type"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="About session"
                name="About session"
                required
                variant="outlined"
                multiline
                minRows={2}
                inputProps={{
                  style: {
                    fontSize: 14,
                  },
                  readOnly: type === "view" ? true : false,
                }}
                value={aboutSession}
                onChange={(e) => setaboutSession(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Source</InputLabel>
                <Select
                  value={streamSource}
                  label="Source"
                  onChange={handleSourceChange}
                  inputProps={{
                    readOnly: type === "view" ? true : false,
                  }}
                >
                  <MenuItem value={1}>Zoom</MenuItem>
                  <MenuItem value={2}>Youtube</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {streamSource === 2 && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Video URL"
                  name="Video URL"
                  inputProps={{
                    readOnly: type === "view" ? true : false,
                  }}
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Link"
                name="Link"
                //disabled={isEditing ? true :false}
                //required
                variant="outlined"
                inputProps={{
                  readOnly: type === "view" ? true : false,
                  //maxLength: CHARACTER_LIMIT,
                  style: {
                    fontSize: 14,
                  },
                }}
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="multiple-input">Tags</InputLabel>
                <Select
                  labelId="tags-multiple-checkbox-label"
                  id="tags-multiple-checkbox"
                  multiple
                  disabled={type === "view" ? true : false}
                  value={selectedTagsId}
                  onChange={handleMultipleTagChange}
                  input={<OutlinedInput label="Tags" />}
                  renderValue={() => selectedTags.join(",")}
                  MenuProps={{
                    sx: {
                      maxHeight: "500px",
                    },
                  }}
                >
                  {tagsList.loading ? (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    tagsList.tags.tagsList.map((x: any) => (
                      <MenuItem key={x.id} value={x.id}>
                        <Checkbox checked={selectedTagsId.indexOf(x.id) > -1} />
                        <ListItemText primary={String(x.name)} />
                      </MenuItem>
                    ))
                  )}
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={31}
                    page={tagpage}
                    onPageChange={handleChangePage}
                    rowsPerPage={10}
                    style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
                  />
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Card variant="outlined">
                <Typography color="textSecondary" className="pl-3 pt-3">
                  Meditation Steps
                </Typography>
                <CardContent>
                  <Grid container spacing={3}>
                    {(stepsForMeditation.length === 0
                      ? [{ stepValue: "" }]
                      : stepsForMeditation
                    ).map((item: any, index: any) => (
                      <Grid
                        item
                        xs={12}
                        key={index}
                        className="d-flex align-items-start mt-2"
                      >
                        <TextField
                          label={`${index + 1}${nth(
                            index + 1
                          )} meditation step`}
                          name="Meditation steps"
                          variant="outlined"
                          multiline
                          required={!index}
                          maxRows={20}
                          inputProps={{
                            readOnly: type === "view" ? true : false,
                            style: {
                              fontSize: 14,
                            },
                          }}
                          value={item.stepValue}
                          onChange={(e) => {
                            let newstepsForMeditation = [...stepsForMeditation];
                            newstepsForMeditation[index].stepValue =
                              e.target.value;
                            setstepsForMeditation(newstepsForMeditation);
                          }}
                          style={{ flexGrow: 1, marginRight: "10px" }}
                        />

                        <Grid>
                          {type !== "view" &&
                            (index ? (
                              <IconButton
                                onClick={() => onClickDelete(index)}
                                size="large"
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                style={{ visibility: "hidden" }}
                                size="large"
                              >
                                <DeleteIcon />
                              </IconButton>
                            ))}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  {type === "view" ? null : (
                    <Grid className="mt-3">
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ textTransform: "none" }}
                        onClick={() => onClickAdd()}
                      >
                        Add row
                      </Button>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
            {isEditing && type !== "copy" ? (
              <Grid item xs={12}>
                <Card variant="outlined">
                  <Grid
                    container
                    spacing={2}
                    className="p-3"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography style={{ fontWeight: "bold" }}>
                        Session Benefits
                      </Typography>
                    </Grid>
                    {type !== "view" && (
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<AddIcon />}
                              onClick={() => {
                                seteditSessionBenefits({
                                  ...editSessionBenefits,
                                  addNewBenefit: true,
                                });
                                setCurrentScreen(2);
                                dispatch(
                                  getAllSessionBenifitsByCoachId(3, 0, 0)
                                );
                              }}
                              disabled={
                                editSessionBenefits.addNewBenefit ||
                                editSessionBenefits.editBenefit
                              }
                            >
                              Add benefits
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                seteditSessionBenefits({
                                  ...editSessionBenefits,
                                  editBenefit: true,
                                });
                              }}
                              disabled={
                                editSessionBenefits.editBenefit ||
                                !rootState.benifitsListById.benifits
                                  .listofbenefitsList.length
                              }
                            >
                              Edit
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => updateBenefits()}
                              disabled={!editSessionBenefits.editBenefit}
                            >
                              Save
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                seteditSessionBenefits({
                                  ...editSessionBenefits,
                                  editBenefit: false,
                                });
                                onEditBenifits();
                              }}
                              disabled={!editSessionBenefits.editBenefit}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Divider />
                  {rootState.benifitsListById.benifits.listofbenefitsList
                    .length !== 0 ? (
                    <CardContent
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                      className={style.scrollbar}
                    >
                      {benefitsArr.map((item: any, index: any) => {
                        return (
                          <div key={index}>
                            {editSessionBenefits.editBenefit ? (
                              <div>
                                <Grid
                                  container
                                  spacing={2}
                                  className="align-items-center mt-2 "
                                >
                                  <Grid item xs={10} sm={10} md={4}>
                                    <FormControl variant="outlined" fullWidth>
                                      <Card
                                        variant="outlined"
                                        className={classes.rootVideo}
                                      >
                                        {item.imageurl ? (
                                          <PreviewProfileImage
                                            onImageClose={onIconClose}
                                            src={item.imageurl}
                                            file={croppedIcon}
                                            size="small"
                                            index={index}
                                          />
                                        ) : (
                                          <CardActionArea
                                            className={classes.thumbNail}
                                            onClick={() => {
                                              handleIconClick(index);
                                            }}
                                          >
                                            <InputLabel htmlFor="image">
                                              Upload Icon
                                            </InputLabel>
                                            <Avatar
                                              className={classes.actionIcon}
                                              variant="square"
                                            >
                                              <ImageOutlined />
                                            </Avatar>
                                            <input
                                              accept="image/*"
                                              id="contained-button-file"
                                              type="file"
                                              hidden
                                              onChange={(e) =>
                                                onIconSelection(e, index)
                                              }
                                              //ref={hiddenIconInput}
                                              ref={(el) =>
                                                (hiddenIconInput.current[
                                                  index
                                                ] = el)
                                              }
                                            />
                                          </CardActionArea>
                                        )}
                                      </Card>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={10} sm={10} md={7}>
                                    <TextField
                                      fullWidth
                                      label="Benefits"
                                      name="Benefits"
                                      variant="outlined"
                                      multiline
                                      rows={7}
                                      maxRows={20}
                                      inputProps={{
                                        //maxLength: CHARACTER_LIMIT,
                                        style: {
                                          fontSize: 14,
                                        },
                                      }}
                                      value={item.description}
                                      onChange={(e) => {
                                        let newbenefitsArr = [
                                          ...benefitsArrNew,
                                        ];
                                        newbenefitsArr[index].description =
                                          e.target.value;
                                        setbenefitsArrNew(newbenefitsArr);
                                        let benefitsAr = [...benefitsArr];
                                        benefitsAr[index].description =
                                          e.target.value;
                                        setbenefitsArr(benefitsAr);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={1} md={1}>
                                    <IconButton
                                      //disabled={editSessionBenefits.editBenefit}
                                      onClick={() => {
                                        let newbenefitsArr = [
                                          ...benefitsArrNew,
                                        ];
                                        newbenefitsArr[index].removebenefit =
                                          true;
                                        setbenefitsArrNew(newbenefitsArr);
                                        onDeleteBenefits(index);
                                      }}
                                      size="large"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </div>
                            ) : (
                              <div>
                                <Grid
                                  container
                                  className="p-2"
                                  key={index}
                                  alignItems="center"
                                >
                                  <Grid
                                    item
                                    xs={2}
                                    sm={1}
                                    md={1}
                                    style={{ maxWidth: "60px" }}
                                  >
                                    <Avatar
                                      //style={{ backgroundColor: "#3f51b5" }}
                                      src={item.imageurl}
                                    />
                                  </Grid>
                                  <Grid item xs={10} sm={11} md={11}>
                                    <Typography variant="body1">
                                      {item.description}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Divider style={{ margin: "0 10px " }} />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </CardContent>
                  ) : (
                    <CardContent className="p-3">
                      <Typography align="center">
                        No benefits available.
                      </Typography>
                    </CardContent>
                  )}
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
        <Divider />
        {type !== "view" && (
          <CardActions className=" py-3 d-flex justify-content-end">
            <Button
              disabled={duration < 30 || duration > 60 || isLoading}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => _createSession()}
            >
              {type === "edit"
                ? "Update defined session"
                : "Create defined session"}
            </Button>
          </CardActions>
        )}
        {iconSrc && (
          <ImageCrop
            src={iconSrc.toString()}
            crop={iconCrop}
            onImageLoaded={onIconLoaded}
            onCropComplete={onIconCropComplete}
            onCropChange={onIconCropChange}
            onPressDone={onIconCropDone}
            onImageClose={onIconClose}
            open={open}
            onOpen={_onOpenAddCoach}
            onClose={_onCloseAddCoach}
          />
        )}
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
);
