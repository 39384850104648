import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalizationProvider from "@mui/lab/LocalizationProvider/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { useDispatch } from "react-redux";
import { getAllScheduleEventBetweenDates } from "redux/actions/bhandaraActions";
import { grpc } from "@improbable-eng/grpc-web";
import { firebaseAuth } from "config/firebase";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import { HFN_EVENTS_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  ID,
  ScheduleEventDetails,
  ScheduleEventRequest,
  Status,
} from "api/models/hfn-events-service/hfn-events_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

const PREFIX = "CreateEvent";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
};

interface EventProps {
  isEditing: any;
  editItem: any;
  onClickBack: any;
  startDate: any;
  endDate: any;
}

// interface State {
//   editing?: boolean;
//   editItem?: any;
//   showDeleteModal?: boolean;
//   deleteItemId?: number;
// }

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    maxWidth: 350,
    // overflow: "scroll",
  },
  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `10%`,
    left: `40%`,
  },
}));

export const CreateEvent: any = React.forwardRef<HTMLDivElement, EventProps>(
  (props, ref): ReactElement => {
    // const initialState = {
    //   editing: false,
    //   editItem: null,
    //   showDeleteModal: false,
    //   deleteItemId: 0,
    // };

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [scheduleDate, setScheduleDate] = useState(Date.now());
    const [eventTitle, setEventTitle] = useState<any>("");
    const [subTitle, setSubTitle] = useState<any>("");
    const [directions, setDirections] = useState<string>("");
    const [streamingUrl, setStreamingUrl] = useState<string>("");
    const [latitude, setLatitude] = useState("0");
    const [longitude, setLongitude] = useState("0");
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
      if (props.isEditing) {
        console.log("Edit event details: ", props.editItem);
        setEventTitle(props.editItem.title);
        setScheduleDate(Number(props.editItem.scheduletime.seconds) * 1000);
        setSubTitle(props.editItem.subtitle);
        setDirections(props.editItem.directionurl);
        setStreamingUrl(props.editItem.livestreamingurl);
        setLongitude(props.editItem.longitude);
        setLatitude(props.editItem.latitude);
      }
    }, [props.isEditing, props.editItem]);

    const addScheduleEvent = async (
      title: string,
      subTitle: string,
      scheduleTime: number,
      directionUrl: string,
      liveStreamingUrl: string,
      longitude: number,
      latitude: number
    ) => {
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();

        const reqBody: ScheduleEventRequest = new ScheduleEventRequest();
        const currentGoogleTimeStamp: Timestamp = new Timestamp();
        currentGoogleTimeStamp.fromDate(new Date(scheduleTime));

        reqBody.setScheduletime(currentGoogleTimeStamp);
        reqBody.setTitle(title);
        reqBody.setSubtitle(subTitle);
        reqBody.setDirectionurl(directionUrl);
        reqBody.setLivestreamingurl(liveStreamingUrl);
        reqBody.setLatitude(latitude);
        reqBody.setLongitude(longitude);

        grpc.unary(HFNEventsService.AddScheduleEvent, {
          request: reqBody,
          host: HFN_EVENTS_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ScheduleEventDetails>) => {
            const { status, statusMessage } = res;
            if (status === grpc.Code.OK) {
              setSnackBar({
                open: true,
                severity: "success",
                message: "Event created successfully",
              });
              dispatch(
                getAllScheduleEventBetweenDates(
                  props.startDate,
                  props.endDate,
                  100,
                  0
                )
              );
              setTimeout(props.onClickBack, 2000);
            } else {
              console.log("error in create event", statusMessage);
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error while creating event",
              });
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };

    const updateScheduledEvent = async (id: number) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ScheduleEventDetails = new ScheduleEventDetails();

      const currentGoogleTimeStamp: Timestamp = new Timestamp();
      currentGoogleTimeStamp.fromDate(new Date(scheduleDate));

      reqBody.setId(id);
      reqBody.setTitle(eventTitle);
      reqBody.setSubtitle(subTitle);
      reqBody.setDirectionurl(directions);
      reqBody.setLivestreamingurl(streamingUrl);
      reqBody.setScheduletime(currentGoogleTimeStamp);
      reqBody.setLatitude(Number(latitude));
      reqBody.setLongitude(Number(longitude));
      console.log(reqBody.toObject());
      grpc.unary(HFNEventsService.UpdateScheduleEvent, {
        request: reqBody,
        host: HFN_EVENTS_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ScheduleEventDetails>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            dispatch(
              getAllScheduleEventBetweenDates(
                props.startDate,
                props.endDate,
                100,
                0
              )
            );
            setSnackBar({
              open: true,
              severity: "success",
              message: "event updated successfully",
            });
            setTimeout(props.onClickBack, 2000);
          } else {
            console.log("update status", status);
          }
        },
      });
    };

    const deleteScheduledEvent = async (id: number) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const reqBody: ID = new ID();

      reqBody.setId(id);
      grpc.unary(HFNEventsService.DeleteScheduleEventById, {
        request: reqBody,
        host: HFN_EVENTS_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            setSnackBar({
              open: true,
              severity: "success",
              message: "event deleted successfully",
            });
            setTimeout(props.onClickBack, 2000);
            dispatch(
              getAllScheduleEventBetweenDates(
                props.startDate,
                props.endDate,
                100,
                0
              )
            );
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: "error in deleting event",
            });
          }
        },
      });
    };

    const validateData = () => {
      const UrlRegX = new RegExp(
        /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
      );
      if (eventTitle.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Event title can not be empty",
        });
      } else if (!UrlRegX.test(directions)) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Enter a valid URL in direction URL",
        });
      } else if (scheduleDate < Date.now()) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Event cannot be created for past time",
        });
      } else if (subTitle.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Venue can not be empty",
        });
      } else if (Number(latitude) === 0 && Number(longitude) === 0) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Please enter latitude and longitude",
        });
      } else if (!Number(latitude)) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Please enter a valid latitude",
        });
      } else if (!Number(longitude)) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Please enter a valid longitude",
        });
      } else {
        return true;
      }
    };
    function onClickAddEvent() {
      if (validateData()) {
        setIsAdding(true);
        setIsLoading(true);
        addScheduleEvent(
          eventTitle,
          subTitle,
          scheduleDate,
          directions,
          streamingUrl,
          Number(longitude),
          Number(latitude)
        );
      }
    }
    function onClickUpdateEvent() {
      if (validateData()) {
        setIsAdding(true);
        setIsLoading(true);
        updateScheduledEvent(props.editItem.id);
      }
    }

    function onClickDelete() {
      deleteScheduledEvent(props.editItem.id);
    }
    return (
      <Root ref={ref}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          style={{
            padding: " 8px",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Grid
              container
              className={props.isEditing ? "" : "align-items-center"}
            >
              <Grid item>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Event" : "  Create Event"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      events
                    </Typography>
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {}}
                    >
                      {`event-name`}
                    </Typography>
                    <Typography color="textPrimary">edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={onClickDelete}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="eventTitle"
                  label="Event Title"
                  variant="outlined"
                  onChange={(e) => {
                    setEventTitle(e.target.value);
                  }}
                  value={eventTitle}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    inputFormat="dd/MM/yyyy hh:mm a"
                    label="Schedule Date And Time"
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                    disablePast={true}
                    value={scheduleDate}
                    onChange={(value: any) => {
                      setScheduleDate(Date.parse(value));
                    }}
                    // inputVariant="outlined"
                    // TextFieldComponent={(props) => (
                    //   <TextField variant="outlined" {...props} />
                    // )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="subtitle"
                  label="Venue"
                  variant="outlined"
                  onChange={(e) => {
                    setSubTitle(e.target.value);
                  }}
                  value={subTitle}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="directions"
                  label="Direction Url"
                  variant="outlined"
                  onChange={(e) => {
                    setDirections(e.target.value);
                  }}
                  value={directions}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  // required
                  type="text"
                  name="liveStreamingUrl"
                  label="Live Streaming Url"
                  variant="outlined"
                  onChange={(e) => {
                    setStreamingUrl(e.target.value);
                  }}
                  value={streamingUrl}
                />
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="longitude"
                label="Longitude"
                variant="outlined"
                onChange={(e) => {
                  setLongitude(e.target.value);
                }}
                value={longitude}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="latitude"
                label="Latitude"
                variant="outlined"
                onChange={(e) => {
                  setLatitude(e.target.value);
                }}
                value={latitude}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className=" p-2 d-flex justify-content-end">
          <Box display="flex" justifyContent="flex-end">
            {props.isEditing ? (
              <>
                <Button
                  onClick={props.onClickBack}
                  color="primary"
                  variant="contained"
                  className="mr-2"
                >
                  Cancel
                </Button>
                <Button
                  onClick={onClickUpdateEvent}
                  color="primary"
                  disabled={isAdding}
                  variant="contained"
                >
                  Update Event
                </Button>
              </>
            ) : (
              <Button
                onClick={onClickAddEvent}
                color="primary"
                disabled={isAdding}
                variant="contained"
              >
                Add Event
              </Button>
            )}
          </Box>
        </CardActions>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
