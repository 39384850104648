import { grpc } from "@improbable-eng/grpc-web";
import { styled } from "@mui/material/styles";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import {
  RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount,
  Status,
} from "api/models/heartintune-profile-service/heartintune-profile_pb";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import { HEARTINTUNE_PROFILE_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import React, { ReactElement, useEffect, useState } from "react";


const PREFIX = "emailModal";

const classes = {
  backdrop: `${PREFIX}-backdrop`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme: Theme }) => ({
  [`& .${classes.backdrop}`]: {
    zIndex: Theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function EmailModal({
  open,
  onClose,
  setSnackBar,
  openEmailPopup,
  sessionId,
}: // setsearchUser,
any): ReactElement {
  const [email, setemail] = useState<string>(`${openEmailPopup.userEmail}`);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    openEmailPopup && setemail(openEmailPopup.userEmail);
  }, [openEmailPopup]);

  const sendEmail = async () => {
    try {
      //console.log("Delete User datatoken", fcmtoken);
      setIsLoading(true);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody =
        new RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount();

      reqBody.setLivesessionid(sessionId);
      reqBody.setEmail(email);
      console.log("reqbody", reqBody.toObject());
      grpc.unary(HeartIntuneProfileService.SendQuestionsToCoachEmail, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("send Email response", message.toObject());
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "success",
                message: "Email sent successfully",
              });
              setTimeout(() => {
                onClose();
              }, 1000);
            }
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: "error in  sending Email",
            });
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "error",
        message: "error in  sending notification",
      });
      console.log(err);
    }
  };

  return (
    <Root>
      <Dialog maxWidth="md" open={open}>
        <DialogTitle>
          <Typography variant="h6" component="span">
            Send Email
          </Typography>
          <IconButton
            style={{ position: "absolute", right: 0, top: 8 }}
            onClick={onClose}
            size="large"
          >
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: 500, margin: 2 }}>
          {isLoading && (
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress color="primary" />
            </Backdrop>
          )}
          <Grid container>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                type="text"
                // label="To"
                name="type"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid className="px-3 py-2">
            <Button
              color="primary"
              variant="contained"
              onClick={() => sendEmail()}
              //disabled={subject === "" || email === "" }
            >
              Ok
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </Root>
  );
}
