// package: unifiedplatform.HFNEvents
// file: hfn-events.proto

var hfn_events_pb = require("./hfn-events_pb");
var google_protobuf_wrappers_pb = require("google-protobuf/google/protobuf/wrappers_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var HFNEventsService = (function () {
  function HFNEventsService() {}
  HFNEventsService.serviceName = "unifiedplatform.HFNEvents.HFNEventsService";
  return HFNEventsService;
}());

HFNEventsService.Test = {
  methodName: "Test",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.Empty,
  responseType: hfn_events_pb.TestResponse
};

HFNEventsService.AddScheduleEvent = {
  methodName: "AddScheduleEvent",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ScheduleEventRequest,
  responseType: hfn_events_pb.ScheduleEventDetails
};

HFNEventsService.UpdateScheduleEvent = {
  methodName: "UpdateScheduleEvent",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ScheduleEventDetails,
  responseType: hfn_events_pb.ScheduleEventDetails
};

HFNEventsService.AddEssentialDetails = {
  methodName: "AddEssentialDetails",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.EssentialRequest,
  responseType: hfn_events_pb.EssentialDetails
};

HFNEventsService.UpdateEssentialDetails = {
  methodName: "UpdateEssentialDetails",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.EssentialDetails,
  responseType: hfn_events_pb.EssentialDetails
};

HFNEventsService.GetAllEssentialDetails = {
  methodName: "GetAllEssentialDetails",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.RequestPageable,
  responseType: hfn_events_pb.EssentialDetailsResponse
};

HFNEventsService.DeleteEssentialDetailsById = {
  methodName: "DeleteEssentialDetailsById",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.Status
};

HFNEventsService.DeleteScheduleEventById = {
  methodName: "DeleteScheduleEventById",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.Status
};

HFNEventsService.getScheduleEventByID = {
  methodName: "getScheduleEventByID",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.ScheduleEventDetails
};

HFNEventsService.GetAllScheduleEvent = {
  methodName: "GetAllScheduleEvent",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.GetAllScheduleEventRequest,
  responseType: hfn_events_pb.GetAllScheduleEventResponse
};

HFNEventsService.GetUpcomingScheduleEvent = {
  methodName: "GetUpcomingScheduleEvent",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.GetAllScheduleEventRequest,
  responseType: hfn_events_pb.GetAllScheduleEventResponse
};

HFNEventsService.AddHelpLine = {
  methodName: "AddHelpLine",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.LostAndFoundRequest,
  responseType: hfn_events_pb.LostAndFoundDetails
};

HFNEventsService.GetAllLostAndFoundDetails = {
  methodName: "GetAllLostAndFoundDetails",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.GetAllLostOrFoundItemDetailsRequest,
  responseType: hfn_events_pb.getAllLostAndFoundDetailsResponse
};

HFNEventsService.SearchLostAndFoundItem = {
  methodName: "SearchLostAndFoundItem",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.searchLostAndFoundItem,
  responseType: hfn_events_pb.getAllLostAndFoundDetailsResponse
};

HFNEventsService.GetAllDynamicLostAndFoundItem = {
  methodName: "GetAllDynamicLostAndFoundItem",
  service: HFNEventsService,
  requestStream: false,
  responseStream: true,
  requestType: hfn_events_pb.BhandaraHelpLineTypeRequest,
  responseType: hfn_events_pb.getAllLostAndFoundDetailsResponse
};

HFNEventsService.DeleteLostAndFoundItem = {
  methodName: "DeleteLostAndFoundItem",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.Status
};

HFNEventsService.UpdateLostAndFoundItem = {
  methodName: "UpdateLostAndFoundItem",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.LostAndFoundDetails,
  responseType: hfn_events_pb.LostAndFoundDetails
};

HFNEventsService.PublishAnnouncement = {
  methodName: "PublishAnnouncement",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.AnnouncementRequest,
  responseType: hfn_events_pb.AnnouncementDetails
};

HFNEventsService.UpdatePublishAnnouncement = {
  methodName: "UpdatePublishAnnouncement",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.AnnouncementDetails,
  responseType: hfn_events_pb.AnnouncementDetails
};

HFNEventsService.DeletePublishAnnouncementByID = {
  methodName: "DeletePublishAnnouncementByID",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.Status
};

HFNEventsService.GetAllPublishAnnouncement = {
  methodName: "GetAllPublishAnnouncement",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.RequestPageable,
  responseType: hfn_events_pb.GetAllPublishAnnouncementResponse
};

HFNEventsService.GetAllDynamicPublishAnnouncement = {
  methodName: "GetAllDynamicPublishAnnouncement",
  service: HFNEventsService,
  requestStream: false,
  responseStream: true,
  requestType: hfn_events_pb.Empty,
  responseType: hfn_events_pb.GetAllPublishAnnouncementResponse
};

HFNEventsService.AddKeyLocation = {
  methodName: "AddKeyLocation",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.KeyLocationRequest,
  responseType: hfn_events_pb.KeyLocationDetails
};

HFNEventsService.UpdateKeyLocation = {
  methodName: "UpdateKeyLocation",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.KeyLocationDetails,
  responseType: hfn_events_pb.KeyLocationDetails
};

HFNEventsService.DeleteKeyLocationById = {
  methodName: "DeleteKeyLocationById",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.Status
};

HFNEventsService.GetAllKeyLocations = {
  methodName: "GetAllKeyLocations",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.RequestPageable,
  responseType: hfn_events_pb.GetAllKeyLocationsResponse
};

HFNEventsService.SearchKeyLocationsByCategoryId = {
  methodName: "SearchKeyLocationsByCategoryId",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.GetAllKeyLocationsResponse
};

HFNEventsService.AddKeyLocationCategory = {
  methodName: "AddKeyLocationCategory",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.KeyLocationCategoryRequest,
  responseType: hfn_events_pb.KeyLocationCategoryDetails
};

HFNEventsService.UpdateKeyLocationCategory = {
  methodName: "UpdateKeyLocationCategory",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.KeyLocationCategoryDetails,
  responseType: hfn_events_pb.KeyLocationCategoryDetails
};

HFNEventsService.DeleteKeyLocationCategoryById = {
  methodName: "DeleteKeyLocationCategoryById",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.Status
};

HFNEventsService.GetAllKeyLocationCategories = {
  methodName: "GetAllKeyLocationCategories",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.Empty,
  responseType: hfn_events_pb.GetAllKeyLocationCategoriesDetails
};

HFNEventsService.AddBhandaraCategory = {
  methodName: "AddBhandaraCategory",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.BhandaraCategoryRequest,
  responseType: hfn_events_pb.BhandaraCategoryDetail
};

HFNEventsService.UpdateBhandaraCategory = {
  methodName: "UpdateBhandaraCategory",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.BhandaraCategoryDetail,
  responseType: hfn_events_pb.BhandaraCategoryDetail
};

HFNEventsService.GetAllBhandaraCategory = {
  methodName: "GetAllBhandaraCategory",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.Empty,
  responseType: hfn_events_pb.GetAllBhandaraCategoryResponse
};

HFNEventsService.AddArchivesEvents = {
  methodName: "AddArchivesEvents",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ArchivesEventsRequest,
  responseType: hfn_events_pb.ArchivesEventsDetails
};

HFNEventsService.UpdateArchivesEvents = {
  methodName: "UpdateArchivesEvents",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ArchivesEventsDetails,
  responseType: hfn_events_pb.ArchivesEventsDetails
};

HFNEventsService.AddArchivesSubEvents = {
  methodName: "AddArchivesSubEvents",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ListOfArchivesSubEventsDetailsRequest,
  responseType: hfn_events_pb.Status
};

HFNEventsService.UpdateArchivesSubEvents = {
  methodName: "UpdateArchivesSubEvents",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ArchivesSubEventsDetailsRequest,
  responseType: hfn_events_pb.ArchivesSubEventsDetails
};

HFNEventsService.DeleteArchivesEventsById = {
  methodName: "DeleteArchivesEventsById",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.Status
};

HFNEventsService.DeleteArchivesSubEventsById = {
  methodName: "DeleteArchivesSubEventsById",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.Status
};

HFNEventsService.GetAllArchivesEvents = {
  methodName: "GetAllArchivesEvents",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.RequestPageable,
  responseType: hfn_events_pb.GetAllArchivesEventsDetails
};

HFNEventsService.GetAllArchivesSubEvents = {
  methodName: "GetAllArchivesSubEvents",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.RequestPageable,
  responseType: hfn_events_pb.GetAllArchivesSubEventsDetails
};

HFNEventsService.AddOrUpdateMultipleTags = {
  methodName: "AddOrUpdateMultipleTags",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.TagsList,
  responseType: hfn_events_pb.TagsList
};

HFNEventsService.GetAllTags = {
  methodName: "GetAllTags",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.RequestPageable,
  responseType: hfn_events_pb.TagsList
};

HFNEventsService.AddOrUpdateContent = {
  methodName: "AddOrUpdateContent",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.Content,
  responseType: hfn_events_pb.Content
};

HFNEventsService.GetBhandaraContentByCategory = {
  methodName: "GetBhandaraContentByCategory",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.RequestForGetContentWithLocaleSupport,
  responseType: hfn_events_pb.ListOfContent
};

HFNEventsService.DeleteContentById = {
  methodName: "DeleteContentById",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.Status
};

HFNEventsService.CreateCard = {
  methodName: "CreateCard",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.CardDetails,
  responseType: hfn_events_pb.CardDetails
};

HFNEventsService.UpdateCard = {
  methodName: "UpdateCard",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.CardDetails,
  responseType: hfn_events_pb.CardDetails
};

HFNEventsService.DeleteCardById = {
  methodName: "DeleteCardById",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.Status
};

HFNEventsService.GetAllCards = {
  methodName: "GetAllCards",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.GetAllCardsRequest,
  responseType: hfn_events_pb.ListOfCard
};

HFNEventsService.GetCardById = {
  methodName: "GetCardById",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.ID,
  responseType: hfn_events_pb.CardDetails
};

HFNEventsService.SaveOrUpdateWidgetOrder = {
  methodName: "SaveOrUpdateWidgetOrder",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.WidgetOrder,
  responseType: hfn_events_pb.Status
};

HFNEventsService.FetchWidgetOrderByScreen = {
  methodName: "FetchWidgetOrderByScreen",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: hfn_events_pb.WidgetOrderRequest,
  responseType: hfn_events_pb.ListOfWidgetOrder
};

HFNEventsService.DeleteWidgetOrderById = {
  methodName: "DeleteWidgetOrderById",
  service: HFNEventsService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_wrappers_pb.Int32Value,
  responseType: hfn_events_pb.Status
};

exports.HFNEventsService = HFNEventsService;

function HFNEventsServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

HFNEventsServiceClient.prototype.test = function test(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.Test, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.addScheduleEvent = function addScheduleEvent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.AddScheduleEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.updateScheduleEvent = function updateScheduleEvent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.UpdateScheduleEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.addEssentialDetails = function addEssentialDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.AddEssentialDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.updateEssentialDetails = function updateEssentialDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.UpdateEssentialDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllEssentialDetails = function getAllEssentialDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllEssentialDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deleteEssentialDetailsById = function deleteEssentialDetailsById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeleteEssentialDetailsById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deleteScheduleEventById = function deleteScheduleEventById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeleteScheduleEventById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getScheduleEventByID = function getScheduleEventByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.getScheduleEventByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllScheduleEvent = function getAllScheduleEvent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllScheduleEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getUpcomingScheduleEvent = function getUpcomingScheduleEvent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetUpcomingScheduleEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.addHelpLine = function addHelpLine(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.AddHelpLine, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllLostAndFoundDetails = function getAllLostAndFoundDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllLostAndFoundDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.searchLostAndFoundItem = function searchLostAndFoundItem(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.SearchLostAndFoundItem, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllDynamicLostAndFoundItem = function getAllDynamicLostAndFoundItem(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(HFNEventsService.GetAllDynamicLostAndFoundItem, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deleteLostAndFoundItem = function deleteLostAndFoundItem(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeleteLostAndFoundItem, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.updateLostAndFoundItem = function updateLostAndFoundItem(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.UpdateLostAndFoundItem, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.publishAnnouncement = function publishAnnouncement(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.PublishAnnouncement, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.updatePublishAnnouncement = function updatePublishAnnouncement(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.UpdatePublishAnnouncement, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deletePublishAnnouncementByID = function deletePublishAnnouncementByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeletePublishAnnouncementByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllPublishAnnouncement = function getAllPublishAnnouncement(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllPublishAnnouncement, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllDynamicPublishAnnouncement = function getAllDynamicPublishAnnouncement(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(HFNEventsService.GetAllDynamicPublishAnnouncement, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.addKeyLocation = function addKeyLocation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.AddKeyLocation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.updateKeyLocation = function updateKeyLocation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.UpdateKeyLocation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deleteKeyLocationById = function deleteKeyLocationById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeleteKeyLocationById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllKeyLocations = function getAllKeyLocations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllKeyLocations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.searchKeyLocationsByCategoryId = function searchKeyLocationsByCategoryId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.SearchKeyLocationsByCategoryId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.addKeyLocationCategory = function addKeyLocationCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.AddKeyLocationCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.updateKeyLocationCategory = function updateKeyLocationCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.UpdateKeyLocationCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deleteKeyLocationCategoryById = function deleteKeyLocationCategoryById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeleteKeyLocationCategoryById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllKeyLocationCategories = function getAllKeyLocationCategories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllKeyLocationCategories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.addBhandaraCategory = function addBhandaraCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.AddBhandaraCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.updateBhandaraCategory = function updateBhandaraCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.UpdateBhandaraCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllBhandaraCategory = function getAllBhandaraCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllBhandaraCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.addArchivesEvents = function addArchivesEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.AddArchivesEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.updateArchivesEvents = function updateArchivesEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.UpdateArchivesEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.addArchivesSubEvents = function addArchivesSubEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.AddArchivesSubEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.updateArchivesSubEvents = function updateArchivesSubEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.UpdateArchivesSubEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deleteArchivesEventsById = function deleteArchivesEventsById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeleteArchivesEventsById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deleteArchivesSubEventsById = function deleteArchivesSubEventsById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeleteArchivesSubEventsById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllArchivesEvents = function getAllArchivesEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllArchivesEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllArchivesSubEvents = function getAllArchivesSubEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllArchivesSubEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.addOrUpdateMultipleTags = function addOrUpdateMultipleTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.AddOrUpdateMultipleTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllTags = function getAllTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.addOrUpdateContent = function addOrUpdateContent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.AddOrUpdateContent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getBhandaraContentByCategory = function getBhandaraContentByCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetBhandaraContentByCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deleteContentById = function deleteContentById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeleteContentById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.createCard = function createCard(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.CreateCard, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.updateCard = function updateCard(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.UpdateCard, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deleteCardById = function deleteCardById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeleteCardById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getAllCards = function getAllCards(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetAllCards, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.getCardById = function getCardById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.GetCardById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.saveOrUpdateWidgetOrder = function saveOrUpdateWidgetOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.SaveOrUpdateWidgetOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.fetchWidgetOrderByScreen = function fetchWidgetOrderByScreen(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.FetchWidgetOrderByScreen, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HFNEventsServiceClient.prototype.deleteWidgetOrderById = function deleteWidgetOrderById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HFNEventsService.DeleteWidgetOrderById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.HFNEventsServiceClient = HFNEventsServiceClient;

