import { createSlice } from "@reduxjs/toolkit";

interface quoteState {
  quoteList: {
    loading: boolean;
    quotes: {
      quotedetailsList: [];
      pageable: {
        totalcount: number;
      };
    };
  };
}

const initialState: quoteState = {
  quoteList: {
    loading: true,
    quotes: {
      quotedetailsList: [],
      pageable: {
        totalcount: 0,
      },
    },
  },
};

export const quoteSlice = createSlice({
  name: "quoteSlice",
  initialState,
  reducers: {
    setQuoteListLoading: (state, action) => {
      state.quoteList.loading = action.payload;
    },
    quoteList: (state, action) => {
      state.quoteList.quotes = action.payload;
    },
  },
});

export const { setQuoteListLoading, quoteList } = quoteSlice.actions;

export default quoteSlice.reducer;
