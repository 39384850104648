/**
 * @author : Godwin Vinny Carole Kati
 * @created : 2021-04-30 00:38:12
 * @organisation : Codeprism Technologies Pvt Ltd
 **/
import React, { ReactElement, useContext, useState } from "react";
interface FileUploadContextType {
  uploadFilesList: UploadFile[];
  appendFilesToUploadManager: (files: UploadFile[]) => void;
  clearFilesInUploadManager: () => void;
}

export const FileUploadContext = React.createContext<FileUploadContextType>({
  uploadFilesList: [],
  appendFilesToUploadManager: ([]) => {},
  clearFilesInUploadManager: () => {},
});

export function useFileUploadService() {
  return useContext(FileUploadContext);
}

export default function FileUploadService({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) {
  const [uploadFilesList, setUploadFilesList] = useState<UploadFile[]>([]);

  function appendFiles(files: UploadFile[]) {
    setUploadFilesList([...uploadFilesList, ...files]);
  }

  function clearFiles() {
    setUploadFilesList([]);
  }

  const value: FileUploadContextType = {
    uploadFilesList,
    appendFilesToUploadManager: appendFiles,
    clearFilesInUploadManager: clearFiles,
  };

  return (
    <FileUploadContext.Provider value={value}>
      {children}
    </FileUploadContext.Provider>
  );
}
