import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CoachProfile,
  ResponseLiveMeditation,
  ListOfQuestion,
  responseScheduleSessionsDateRange,
  ResponseForGetAllBenefits,
  ResponseListOfBenefits,
  ResponseForGetAllBenefitsByCoachId,
  ListOfUserFeedBacks,
  //ResponseForGetLiveSessionByCoachId,
} from "api/models/live-meditation-service/live-meditation_pb";

export enum SessionConfigType {
  "new",
  "edit",
}
// interface sessionDetails{
//   sessionInfo:any,
//   questions:any,
// }

interface LiveMeditationDefaultState {
  coachList: {
    loading: boolean;
    coaches: CoachProfile.AsObject[];
  };

  allSessionsQuestionList: {
    [key: string]: { questionList: any; totalcount: number };
  };

  liveMeditationsList: {
    loading: boolean;
    liveMeditations: ResponseLiveMeditation.AsObject[];
  };
  scheduleStep: number;
  selectedCoach?: CoachProfile.AsObject;
  selectedLiveSession?: ResponseLiveMeditation.AsObject;
  sessionConfigType?: SessionConfigType;
  liveMeditationsListBetween: {
    loading: boolean;
    liveMeditationsBetween: responseScheduleSessionsDateRange.AsObject;
  };
  questionList: {
    loading: boolean;
    questions: ListOfQuestion.AsObject;
  };
  userQuestionList: {
    loading: boolean;
    questions: ListOfQuestion.AsObject;
  };
  benifitsList: {
    loading: boolean;
    benifits: ResponseForGetAllBenefits.AsObject;
  };
  benifitsListById: {
    loading: boolean;
    benifits: ResponseListOfBenefits.AsObject;
  };
  benifitsIdList: {
    loading: boolean;
    IdList: number[];
  };

  benifitsListByCoachId: {
    loading: boolean;
    benifits: ResponseForGetAllBenefitsByCoachId.AsObject;
  };
  sessionIdLists: {
    loading: boolean;
    Ids: any;
  };

  liveSessionDetails: {
    [key: string]: { sessionInfo: any };
  };

  globalLoading: {
    loading: boolean;
  };
  feedbackList: {
    [key: string]: { loading: boolean; feedback: ListOfUserFeedBacks.AsObject };
  };
  helpDocs: {
    howToUse: string;
    whatsNew: string;
    roadmap: string;
  };
}

const initialState: LiveMeditationDefaultState = {
  coachList: {
    loading: true,
    coaches: [],
  },
  allSessionsQuestionList: {
    "0": { questionList: [], totalcount: 0 },
  },
  liveMeditationsList: {
    loading: true,
    liveMeditations: [],
  },
  scheduleStep: 0,
  liveMeditationsListBetween: {
    loading: true,
    liveMeditationsBetween: { sessioninfodetailsList: [] },
  },
  questionList: {
    loading: true,
    questions: { questionList: [], totalcount: 0 },
  },
  userQuestionList: {
    loading: true,
    questions: { questionList: [], totalcount: 0 },
  },
  benifitsList: {
    loading: true,
    benifits: {
      sessionbenefitsList: [],
      selectedpagesize: 0,
      selectedpagenumber: 0,
      totalcount: 0,
    },
  },
  benifitsListById: {
    loading: true,
    benifits: { listofbenefitsList: [], livesessionid: 0 },
  },
  benifitsListByCoachId: {
    loading: true,
    benifits: {
      sessionbenefitsList: [],
      selectedpagesize: 10,
      selectedpagenumber: 0,
    },
  },
  benifitsIdList: {
    loading: false,
    IdList: [],
  },
  sessionIdLists: {
    loading: false,
    Ids: { livesessionidsList: [] },
  },
  liveSessionDetails: {
    "0": { sessionInfo: {} },
  },
  globalLoading: {
    loading: false,
  },
  feedbackList: {
    "0": {
      loading: false,
      feedback: {
        userlivesessionfeedbackList: [],
        responsepageable: {
          selectedpagenumber: 0,
          selectedpagesize: 10,
          totalcount: 0,
        },
      },
    },
  },
  helpDocs: {
    howToUse: "",
    whatsNew: "",
    roadmap: "",
  },
};

const liveMeditationSlice = createSlice({
  name: "coachList",
  initialState,
  reducers: {
    globalLoading: (state) => {
      state.globalLoading.loading = true;
    },
    globalLoadingUpdate: (state) => {
      state.globalLoading.loading = false;
    },
    globalLoadingError: (state) => {
      state.globalLoading.loading = false;
    },
    coachListLoading: (state) => {
      state.coachList.loading = true;
    },
    storeQuestions: (state, action) => {
      state.allSessionsQuestionList = {
        ...state.allSessionsQuestionList,
        [action.payload.sessionId.toString()]: {
          ...action.payload.sessionQuestions,
        },
      };
    },
    coachListUpdate: (
      state,
      action: PayloadAction<CoachProfile.AsObject[]>
    ) => {
      state.coachList.loading = false;
      state.coachList.coaches = action.payload;
    },
    coachListError: (state) => {
      state.coachList.loading = false;
    },
    schedularUpdateStep: (state, action: PayloadAction<number>) => {
      state.scheduleStep = action.payload;
    },
    updateSelectedCoach: (
      state,
      action: PayloadAction<CoachProfile.AsObject>
    ) => {
      state.selectedCoach = action.payload;
    },
    liveMeditationLoading: (state) => {
      state.liveMeditationsList.loading = true;
    },
    liveMeditationListUpdate: (
      state,
      action: PayloadAction<ResponseLiveMeditation.AsObject[]>
    ) => {
      state.liveMeditationsList.loading = false;
      state.liveMeditationsList.liveMeditations = action.payload;
    },
    liveMeditationlistError: (state) => {
      state.liveMeditationsList.loading = false;
    },
    setSelectedLiveMeditationSession: (
      state,
      action: PayloadAction<ResponseLiveMeditation.AsObject | undefined>
    ) => {
      state.selectedLiveSession = action.payload;
    },
    setLiveSessionConfigType: (
      state,
      action: PayloadAction<SessionConfigType>
    ) => {
      state.sessionConfigType = action.payload;
    },
    liveSessionsBetweenLoading: (state) => {
      state.liveMeditationsListBetween.loading = true;
    },
    liveSessionsBetweenUpdate: (
      state,
      action: PayloadAction<responseScheduleSessionsDateRange.AsObject>
    ) => {
      state.liveMeditationsListBetween.loading = false;
      state.liveMeditationsListBetween.liveMeditationsBetween = action.payload;
    },
    liveSessionsBetweenError: (state) => {
      state.liveMeditationsListBetween.loading = false;
    },
    questionListLoading: (state) => {
      state.questionList.loading = true;
    },
    questionListUpdate: (state, action) => {
      state.questionList.loading = false;
      state.questionList.questions = action.payload;
    },
    questionListError: (state) => {
      state.questionList.loading = false;
    },
    userQuestionListLoading: (state) => {
      state.userQuestionList.loading = true;
    },
    userQuestionListUpdate: (
      state,
      action: PayloadAction<ListOfQuestion.AsObject>
    ) => {
      state.userQuestionList.loading = false;
      state.userQuestionList.questions = action.payload;
    },
    userQuestionListError: (state) => {
      state.userQuestionList.loading = false;
    },
    benifitsListLoading: (state) => {
      state.benifitsList.loading = true;
    },
    benifitsListUpdate: (
      state,
      action: PayloadAction<ResponseForGetAllBenefits.AsObject>
    ) => {
      state.benifitsList.loading = false;
      state.benifitsList.benifits = action.payload;
    },
    benifitsListError: (state) => {
      state.benifitsList.loading = false;
    },
    benifitsListByIdLoading: (state) => {
      state.benifitsIdList.loading = true;
      state.benifitsListById.loading = true;
    },
    benifitsListByIdUpdate: (
      state,
      action: PayloadAction<ResponseListOfBenefits.AsObject>
    ) => {
      state.benifitsIdList.loading = true;
      state.benifitsListById.loading = false;
      state.benifitsListById.benifits = action.payload;
    },
    benifitsListByIdError: (state) => {
      state.benifitsIdList.loading = false;
      state.benifitsListById.loading = false;
    },
    benifitsIdArrayUpdate: (state, action: PayloadAction<number[]>) => {
      state.benifitsIdList.loading = false;
      state.benifitsIdList.IdList = action.payload;
    },
    benifitsIdArrayLoading: (state) => {
      state.benifitsIdList.loading = false;
    },
    benifitsListByCoachIdLoading: (state) => {
      state.benifitsListByCoachId.loading = true;
    },
    benifitsListByCoachIdUpdate: (
      state,
      action: PayloadAction<ResponseForGetAllBenefitsByCoachId.AsObject>
    ) => {
      state.benifitsListByCoachId.loading = false;
      state.benifitsListByCoachId.benifits = action.payload;
    },
    benifitsListByCoachIdError: (state) => {
      state.benifitsListByCoachId.loading = false;
    },
    liveSessionsByCoachIdLoading: (state) => {
      state.sessionIdLists.loading = true;
    },

    liveSessionsByCoachIdUpdate: (state, action) => {
      state.sessionIdLists.loading = false;
      state.sessionIdLists.Ids.livesessionidsList = action.payload;
    },
    liveSessionsByCoachIdError: (state) => {
      state.sessionIdLists.loading = false;
    },
    liveSessionDetailsLoading: (state, action) => {},
    liveSessionDetailsUpdate: (state, action) => {
      //console.log('action',action.payload);
      state.liveSessionDetails = {
        ...state.liveSessionDetails,
        [action.payload.sessionId.toString()]: {
          sessionInfo: { ...action.payload.result },
        },
      };
    },
    liveSessionDetailsError: (state, action) => {},
    feedbackListLoading: (state, action) => {
      state.feedbackList = {
        ...state.feedbackList,
        [action.payload.toString()]: {
          loading: true,
          feedback: { userlivesessionfeedbackList: [], responsepageable: {} },
        },
      };
    },
    feedbackListUpdate: (state, action) => {
      state.feedbackList = {
        ...state.feedbackList,
        [action.payload.sessionId.toString()]: {
          loading: false,
          feedback: action.payload.sessionFeedback,
        },
      };
    },
    feedbackListError: (state, action) => {
      state.feedbackList = {
        ...state.feedbackList,
        [action.payload.toString()]: {
          loading: false,
          feedback: { userlivesessionfeedbackList: [], responsepageable: {} },
        },
      };
    },
    updateHelpDocs: (state, action) => {
      state.helpDocs = action.payload;
    },
  },
});

export const {
  coachListLoading,
  coachListUpdate,
  coachListError,
  schedularUpdateStep,
  updateSelectedCoach,
  liveMeditationLoading,
  liveMeditationListUpdate,
  liveMeditationlistError,
  setLiveSessionConfigType,
  setSelectedLiveMeditationSession,
  liveSessionsBetweenLoading,
  liveSessionsBetweenUpdate,
  liveSessionsBetweenError,
  questionListUpdate,
  questionListLoading,
  questionListError,
  userQuestionListLoading,
  userQuestionListUpdate,
  userQuestionListError,
  benifitsListUpdate,
  benifitsListLoading,
  benifitsListError,
  benifitsListByIdLoading,
  benifitsListByIdUpdate,
  benifitsListByIdError,
  benifitsListByCoachIdLoading,
  benifitsListByCoachIdUpdate,
  benifitsListByCoachIdError,
  benifitsIdArrayUpdate,
  storeQuestions,
  liveSessionsByCoachIdLoading,
  liveSessionsByCoachIdUpdate,
  liveSessionsByCoachIdError,
  liveSessionDetailsLoading,
  liveSessionDetailsUpdate,
  liveSessionDetailsError,
  globalLoadingUpdate,
  globalLoadingError,
  globalLoading,
  benifitsIdArrayLoading,
  feedbackListLoading,
  feedbackListUpdate,
  feedbackListError,
  updateHelpDocs,
} = liveMeditationSlice.actions;

export default liveMeditationSlice.reducer;
