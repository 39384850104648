import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
//import style from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllArchives, getAllArchiveSubEvents } from "redux/actions/bhandaraActions";
import { RootState } from "redux/store/store";
import { ListSkeleton } from "components/listSkeleton";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import { minifyText } from "utils/helpers";

const TableWrapper = styled("div")({
  margin: "16px",
  height: "100%",
  overflow: "auto",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "5px",
});

export const Archive = React.forwardRef<HTMLDivElement, any>(
  (props, ref): ReactElement => {
    const [searchEvent, setSearchEvent] = useState("");
    const [searchData, setSearchData] = useState<any[]>([]);

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.bhandara.archiveSubEvent);

    useEffect(() => {
      //dispatch(getAllArchives(props.pageSize, props.pageNumber));
      dispatch(getAllArchiveSubEvents(props.pageSize, props.pageNumber));
      
      console.log("scheduled events list", state.eventList);
    }, [props.pageSize, props.pageNumber]);



    function renderLiveSessionsListBetween(data:any) {
      return (
        <div>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "transparent" }}
            className="py-2"
          >
            <Grid className="d-flex align-items-center">
              <TextField
                type="search"
                fullWidth
                value={searchEvent}
                variant="outlined"
                placeholder="Search events by name"
                size="small"
                onChange={(e) => {
                  setSearchEvent(e.target.value);
                  const filteredData = data.filter((item: any) =>
                    item?.subeventname
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  );
                  setSearchData(filteredData);
                }}
                className="ml-3"
                //sx={{ visibility: "hidden" }}
              />

              <Grid>
                <IconButton
                  onClick={() => {
                    dispatch(getAllArchives(props.pageSize, props.pageNumber));
                  }}
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer style={{ maxHeight: "400px" }} className="scrollbar">
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell align="center">Event Name</TableCell>
                  <TableCell align="center">Sub Event Name</TableCell>
                  <TableCell align="center">Sub Event Url</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchEvent !== "" ? searchData : data).map(
                  (item: any, x: any) => {
                    return (
                      <React.Fragment key={x}>
                        <TableRow
                          sx={{ cursor: "pointer", height: "50px" }}
                          hover
                          onClick={() => {
                            props.setStates({
                              editing: true,
                              editItem: item,
                            });
                            props.setCurrentScreen(1);
                          }}
                        >
                          <TableCell align="center">{item.archiveseventsdetails.eventname}</TableCell>
                          <TableCell align="center">{item.subeventname}</TableCell>
                          <TableCell align="center">{minifyText(item.url,30)}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Grid container justifyContent="flex-end">
            <Grid
              item
              style={{ width: "450px", overflowX: "auto" }}
              className="scrollbar"
            >
              <Grid
                container
                //spacing={2}
                className="d-flex justify-content-end align-items-center"
              >
                <Grid className="mr-2">
                  <Typography>Rows per page:</Typography>
                </Grid>
                <Grid>
                  <FormControl variant="standard">
                    <Select
                      value={props.pageSize}
                      onChange={(e) => {
                        props.setPageSize(Number(e.target.value));
                        dispatch(
                          getAllArchives(props.pageSize, props.pageNumber)
                        );
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid className="mx-2">
                  {state.eventList.archivessubeventsdetailsList.length ? (
                      <Typography>
                        {`1-${state.eventList.archivessubeventsdetailsList.length}`}
                      </Typography>
                    ) : null}
                </Grid>
                <Grid className="d-flex align-items-center">
                  <IconButton
                    onClick={() => {
                      if (props.pageNumber > 0) {
                        const previousPage = props.pageNumber - 1;
                        props.setPageNumber(props.pageNumber - 1);
                        //setpageSize(10);
                        dispatch(
                          getAllArchives(props.pageSize, props.pageNumber)
                        );
                      }
                    }}
                    disabled={props.pageNumber === 0}
                    size="large"
                  >
                    <ChevronLeftRounded />
                  </IconButton>
                  <Typography>{props.pageNumber + 1}</Typography>
                  <IconButton
                    onClick={() => {
                      const nextPage = props.pageNumber + 1;
                      props.setPageNumber(props.pageNumber + 1);
                      dispatch(
                        getAllArchives(props.pageSize, props.pageNumber)
                      );
                    }}
                    //   disabled={
                    //     state.scheduledEventsList.eventsList.length - pageSize < 0
                    //   }
                    size="large"
                  >
                    <ChevronRightRounded />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div ref={ref}>
        <div>
          <Grid
            container
            spacing={2}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              padding: " 16px",
            }}
          >
            <Grid item xs={12} sm={6} md={8} lg={6}>
              <Grid
                container
                spacing={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Grid item>
                  <Typography variant="h6">Archive</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.setCurrentScreen(1)}
                    startIcon={<AddIcon />}
                  >
                    Add Archive
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <TableWrapper>
            <Paper>
              {state.loading
                ? ListSkeleton()
                : renderLiveSessionsListBetween(
                    state.eventList.archivessubeventsdetailsList
                  )}
            </Paper>
          </TableWrapper>
        </div>
      </div>
    );
  }
);
