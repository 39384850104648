import {
  Card,
  CardActionArea,
  Button,
  Avatar,
  Box,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  CircularProgress,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  MenuItem,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ImageOutlined, MusicNoteOutlined } from "@mui/icons-material";
import React, { ReactElement, useEffect, useState } from "react";
import { Alert } from "@mui/material";
import AudioPlayer from "components/audio-player/audio-player";

import { useDispatch, useSelector } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { useFileUploadService } from "services/FileUplaodService";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import ImageCrop from "components/image-crop/image-crop";
import PreviewProfileImage from "../../../../components/image-preview/profileImagePreview";
import {
  AddMeditationFileDetailsRequestV2,
  MeditationFileDetailsV2,
} from "api/models/guided-meditation-service/guided-meditation_pb";
import { firebaseAuth } from "config/firebase";
import { GuidedMeditationService } from "api/models/guided-meditation-service/guided-meditation_pb_service";
import {
  GAMIFICATION_SERVICE_HOST,
  GUIDED_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { getAllMeditationFileDetailsV2 } from "redux/actions/guidedMeditationAction";
import { Locale } from "api/models/gamification-service/common/common_pb";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { Tag } from "api/models/guided-meditation-service/guided-meditation_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { Crop } from "react-image-crop";
import { RootState } from "redux/store/store";
import { getAllMeditationTags } from "redux/actions/tagActions";
import { meditationCategories } from "constants/meditation";

const PREFIX = "CreateMeditationWithPurpose";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
    //overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

interface FormProps {
  isEditing?: boolean;
  editItem?: any;
  onClickBack?: any;
}

export const CreateMeditationWithGuidance = React.forwardRef<
  HTMLDivElement,
  FormProps
>((props, ref): ReactElement => {
  const dispatch = useDispatch();
  //const languages = useSelector((state: RootState) => state.app.languages);

  const tagsList = useSelector((state: RootState) => state.tags.tagList);

  const hiddenFileInput: any = React.useRef(null);
  const hiddenSelectedImageInput: any = React.useRef(null);
  const hiddenBackgroundImageInput: any = React.useRef(null);
  const hiddenAudioInput: any = React.useRef(null);

  const cropDef: Crop = {
    x: 25,
    y: 25,
    height: 20,
    width: 20,
    unit: "%",
  };

  const [title, setTitle] = useState<any>("");
  const [subTitle, setSubTitle] = useState<any>("");
  // const [state, setState] = useState<State>(initialState);

  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState<any>(0);
  const [instructionDuration, setInstructionDuration] = useState<any>(0);
  // const [likes, setlikes] = useState<string>("");
  const [audioUploadUrl, setAudioUploadUrl] = useState<string>("");

  const [imageRef, setImageRef] = useState<any>();
  const [src, setSrc] = useState<any>(null);
  const [crop, setCrop] = useState<Crop>(cropDef);
  const [imageFile, setImageFile] = useState<any>(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState<any>(null);

  const [selectedImageRef, setselectedImageRef] = useState<any>();
  const [selectedImagesrc, setselectedImageSrc] = useState<any>(null);
  const [selectedImagecrop, setselectedImageCrop] = useState<Crop>(cropDef);
  const [selectedImageFile, setselectedImageFile] = useState<any>(null);
  const [selectedcroppedImageUrl, setselectedCroppedImageUrl] =
    useState<any>(null);
  const [activityType, setActivityType] = useState<string>("");

  const [backgroundImageRef, setBackgroundImageRef] = useState<any>();
  const [backgroundImageSrc, setBackgroundImageSrc] = useState<any>(null);
  const [backgroundImageCrop, setBackgroundImageCrop] = useState<Crop>(cropDef);
  const [backgroundImageFile, setBackgroundImageFile] = useState<any>(null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<any>(null);
  const [selectedTagsId, setSelectedTagsId] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setopen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [isUploading, setIsUploading] = useState({
    image: false,
    selectedImage: false,
    backgroundImage: false,
    audio: false,
  });

  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });
  const [meditationCategory, setMeditationCategory] = useState<0 | 1 | 2 | 3>(
    0
  );
  const { appendFilesToUploadManager } = useFileUploadService();

  const _onOpenImage = () => {
    setopen(true);
  };
  const _onCloseImage = () => {
    setopen(false);
    setIsUploading({
      ...isUploading,
      image: false,
      selectedImage: false,
      backgroundImage: false,
    });
  };

  // --------------------------image upload--------------------------------------->
  const handleUploadClick = () => {
    hiddenFileInput.current.click();
    console.log("clicked on image upload");
  };
  const onSelectImage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, image: true });
      setImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setSrc(reader.result);
        setopen(true);
      };
    }
  };
  const onImageLoaded = (image: any) => {
    setImageRef(image);
  };

  const onCropDone = async () => {
    setIsUploading({ ...isUploading, image: true });
    const cr: any = crop;
    if (imageRef && cr.width && cr.height) {
      const croppedUrl = await getCroppedImg(imageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
          " ",
          ""
        )}`
      );
      //setCroppedImageUrl(croppedUrl);
      console.log("image", croppedFile);
      const uploadFile = toUploadFile(
        croppedFile,
        "MeditateNow/thumbnails".replaceAll(" ", "")
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setCroppedImageUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, image: false });
          }
        }
      );
    }
    setSrc(null);
  };

  const onCropChange = (crop: any) => {
    setCrop(crop);
  };
  const onCropComplete = async () => {
    //console.log("state crop", crop, imageRef);
  };
  const onImageClose = () => {
    setSrc(null);
    setImageFile(null);
    _deleteFileFromGcp(croppedImageUrl);
    setCroppedImageUrl("");
    setCrop(cropDef);
  };

  // -------------------------- selected image upload--------------------------------------->
  const _handleUploadClick = () => {
    hiddenSelectedImageInput.current.click();
    console.log("clicked on image upload");
  };
  const _onSelectImage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, selectedImage: true });
      setselectedImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setselectedImageSrc(reader.result);
        setopen(true);
      };
    }
  };
  const _onImageLoaded = (image: any) => {
    setselectedImageRef(image);
  };

  const _onCropDone = async () => {
    setIsUploading({ ...isUploading, selectedImage: true });
    const cr: any = selectedImagecrop;
    if (selectedImageRef && cr.width && cr.height) {
      const croppedUrl = await getCroppedImg(selectedImageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date().getTime().toString()}_${selectedImageFile.name.replaceAll(
          " ",
          ""
        )}`
      );
      // setselectedCroppedImageUrl(croppedUrl);
      console.log("image", croppedFile);
      const uploadFile = toUploadFile(
        croppedFile,
        "MeditateNow/favourite-images".replaceAll(" ", "")
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setselectedCroppedImageUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, selectedImage: false });
          } else {
            setIsUploading({ ...isUploading, selectedImage: false });
          }
        }
      );
    }
    setselectedImageSrc(null);
  };

  const _onCropChange = (crop: any) => {
    setselectedImageCrop(crop);
  };
  const _onCropComplete = async () => {
    //console.log("state crop", crop, imageRef);
  };
  const _onImageClose = () => {
    setselectedImageSrc(null);
    _deleteFileFromGcp(selectedcroppedImageUrl);
    setselectedCroppedImageUrl("");
    setselectedImageFile(null);
    setselectedImageCrop(cropDef);
  };
  const _deleteFileFromGcp = async (data: any) => {
    try {
      console.log("image url to delete from gcp", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: DeleteFileRequest = new DeleteFileRequest();
      reqBody.setImgpath(data);
      grpc.unary(GamificationService.DeleteFileFromGCP, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              //setIsLoading(false);
              console.log(
                "data coming from delete file from gcp",
                message.toObject()
              );
              //setCroppedImageUrl("");
            }
          } else {
            setIsLoading(false);
          }
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  //---------------------------background image upload------------------------------------->
  const backgroundHandleUploadClick = () => {
    hiddenBackgroundImageInput.current.click();
    console.log("clicked on image upload");
  };

  const onSelectBackgroundImage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, backgroundImage: true });
      setBackgroundImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setBackgroundImageSrc(reader.result);
        setopen(true);
      };
    }
  };

  const onBackgroundCropComplete = async () => {
    //console.log("state crop", crop, imageRef);
  };

  const onBackgroundImageLoaded = (image: any) => {
    setBackgroundImageRef(image);
  };

  const onBackgroundCropChange = (crop: any) => {
    setBackgroundImageCrop(crop);
  };

  const onBackgroundCropDone = async () => {
    setIsUploading({ ...isUploading, backgroundImage: true });
    const cr: any = backgroundImageCrop;
    if (backgroundImageRef && cr.width && cr.height) {
      const croppedUrl = await getCroppedImg(backgroundImageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date()
          .getTime()
          .toString()}_${backgroundImageFile.name.replaceAll(" ", "")}`
      );
      // setselectedCroppedImageUrl(croppedUrl);
      console.log("image", croppedFile);
      const uploadFile = toUploadFile(
        croppedFile,
        "MeditateNow/background-images".replaceAll(" ", "")
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setBackgroundImageUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, backgroundImage: false });
          } else {
            setIsUploading({ ...isUploading, backgroundImage: false });
          }
        }
      );
    }
    setBackgroundImageSrc(null);
  };

  const onBackgroundImageClose = () => {
    setBackgroundImageSrc(null);
    _deleteFileFromGcp(backgroundImageUrl);
    setBackgroundImageUrl("");
    setBackgroundImageFile(null);
    setBackgroundImageCrop(cropDef);
  };

  //---------------------------audio upload------------------------------------->
  const handleAudioClick = () => {
    hiddenAudioInput.current.click();
  };

  const onAudioSelection = async (event: any) => {
    setIsUploading({ ...isUploading, audio: true });
    const data = new File(
      [event.target.files[0]],
      event.target.files[0].name.replaceAll(" ", "")
    );
    const uploadFile = toUploadFile(
      data,
      "MeditateNow/audios".replaceAll(" ", "")
    );
    appendFilesToUploadManager([uploadFile]);
    window.addEventListener(
      uploadFile.token,
      (res: CustomEventInit<UploadEventResponse>) => {
        if (res.detail?.code === 200) {
          setAudioUploadUrl(res.detail.response);
          setIsUploading({ ...isUploading, audio: false });
        } else {
          setIsUploading({ ...isUploading, audio: false });
        }
      }
    );
  };

  const onAudioClose = () => {
    setAudioUploadUrl("");
  };

  //---------------------------audio upload end------------------------------------->

  const handleMultipleTagChange = (event: any) => {
    const {
      target: { value },
    } = event;
    // console.log(value);

    const arrOfNum: number[] = (
      typeof value === "string" ? value.split(",") : value
    ).map((str) => {
      return Number(str);
    });
    const val = selectedTagsId.filter((item) => value.indexOf(item) === -1);
    const tagIndex = selectedTagsId.indexOf(val[0]);

    setSelectedTagsId(arrOfNum);

    if (tagIndex > -1) {
      setSelectedTags(() => {
        const updatedTags = [...selectedTags];
        updatedTags.splice(tagIndex, 1);
        // console.log("updated Tags: ", updatedTags);
        return updatedTags;
      });
    } else if (tagIndex === -1) {
      setSelectedTags(() => {
        const temp = tagsList.tags.tagsList.filter((item) =>
          value.includes(item.id)
        );

        const arrOfString = temp.map((item: any) => item.name);
        return [...new Set([...selectedTags, ...arrOfString])];
      });
    }
  };

  useEffect(() => {
    dispatch(getAllMeditationTags(10, page));
  }, [page]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const tagsArray = () => {
    let arr: Tag[] = [];
    for (const tagIndex in selectedTags) {
      const temp = new Tag();
      temp.setId(selectedTagsId[tagIndex]);
      temp.setName(selectedTags[tagIndex]);
      arr.push(temp);
    }
    return arr;
  };

  const validateMeditaion = () => {
    if (title.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Meditation title can not be empty",
      });
    } else if (description.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Meditation description  can not be empty",
      });
    // } else if (activityType.trim() === "") {
    //   setSnackBar({
    //     open: true,
    //     severity: "error",
    //     message: "Activity type  can not be empty",
    //   });
    } else if (+duration === 0) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Duration must be greater than 0",
      });
    } else if (+instructionDuration > +duration) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Instruction clip time must be less than duration",
      });
    } else if (croppedImageUrl === "" || croppedImageUrl === null) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Thumbnail can not be empty",
      });
    } else if (
      selectedcroppedImageUrl === "" ||
      selectedcroppedImageUrl === null
    ) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Favourie image can not be empty",
      });
    } else if (backgroundImageUrl === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Background image can not be empty",
      });
    } else if (audioUploadUrl === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Audio can not be empty",
      });
    } else if (selectedTagsId.length === 0) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Minimum 1 tag needs to be selected",
      });
    } else if (selectedTagsId.length > 3) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Maximum 3 tags can be selected",
      });
      // } else if (activityType === "") {
      //   setSnackBar({
      //     open: true,
      //     severity: "error",
      //     message: "Activity type can not be empty",
      //   });
    } else {
      return true;
    }
  };

  const _addMeditation = () => {
    if (validateMeditaion()) {
      const data: any = {
        meditationType: 2,
        meditationcategory: meditationCategory,
        title: title,
        attribute: activityType,
        subTitle: subTitle,
        description: description,
        duration: Number(duration),
        instructionDuration: Number(instructionDuration),
        tag: "",
        rating: 4,
        likes: 5,
        audioUrl: audioUploadUrl,
        audioUrlV2: audioUploadUrl,
        thumbnailImgUrl: croppedImageUrl,
        backgroundImageUrl: backgroundImageUrl,
        imageUrl: selectedcroppedImageUrl,
        purposeFileID: 7,
        durationFileID: 4,
      };
      setIsAdding(true);
      _addMeditationFileV2(data);
    }
  };

  const _addMeditationFileV2 = async (data: any) => {
    console.log(`Meditation V2 data ${data}`);
    try {
      const reqBody = new AddMeditationFileDetailsRequestV2();

      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title);
      titleLocale.setEn(data.title);
      reqBody.setTitle(titleLocale);

      const subtitleLocale: Locale = new Locale();
      subtitleLocale.setDefaultcontent(data.subTitle);
      subtitleLocale.setEn(data.subTitle);
      reqBody.setSubtitle(subtitleLocale);

      const descriptionLocale: Locale = new Locale();
      descriptionLocale.setDefaultcontent(data.description);
      descriptionLocale.setEn(data.description);
      reqBody.setDescription(descriptionLocale);

      const audioLocale: Locale = new Locale();
      audioLocale.setDefaultcontent(data.audioUrl);
      audioLocale.setEn(data.audioUrl);
      reqBody.setAudiourl(audioLocale);

      const audioLocaleV2: Locale = new Locale();
      audioLocaleV2.setDefaultcontent(data.audioUrlV2);
      audioLocaleV2.setEn(data.audioUrlV2);
      reqBody.setAudiourlv2(audioLocaleV2);

      const imageLocale: Locale = new Locale();
      imageLocale.setDefaultcontent(data.imageUrl);
      imageLocale.setEn(data.imageUrl);
      reqBody.setImageurl(imageLocale);

      const thumbnailLocale: Locale = new Locale();
      thumbnailLocale.setDefaultcontent(data.thumbnailImgUrl);
      thumbnailLocale.setEn(data.thumbnailImgUrl);

      const attributeLocale: Locale = new Locale();
      attributeLocale.setDefaultcontent('Meditation');
      attributeLocale.setEn('Meditation');

      reqBody.setThumbnailimgurl(thumbnailLocale);
      reqBody.setAttribute(attributeLocale);
      reqBody.setBackgroundimageurl(data.backgroundImageUrl);

      reqBody.setLikes(data.likes);
      reqBody.setMeditationtype(data.meditationType);
      reqBody.setRating(data.rating);
      reqBody.setTag(data.tag);
      reqBody.setDurationofmeditationclip(data.duration);
      reqBody.setDurationofinstructionclip(data.instructionDuration);
      reqBody.setDurationfileid(data.durationFileID);
      reqBody.setPurposefileid(data.purposeFileID);
      reqBody.setTagsList(tagsArray());
      reqBody.setMeditationcategory(data.meditationcategory);

      console.log(reqBody.toObject());

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GuidedMeditationService.addMeditationFileDetailsV2, {
        request: reqBody,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<MeditationFileDetailsV2>) => {
          const { status, statusMessage, message } = res;
          console.log("statusMessage", statusMessage);
          console.log("grpc code", grpc.Code);

          if (status === grpc.Code.OK && message) {
            console.log("all ok ", message.toObject());
            // dispatch(getAllPurposeMeditation(10, 0, 1));
            dispatch(getAllMeditationFileDetailsV2(10, 0, 1));
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "success",
              message: " Meditation with guidance added successfully",
            });
            setTimeout(() => props.onClickBack(), 2000);
            return message;
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: "error in adding Meditation with guidance ",
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "error",
        message: "something went wrong in addMeditationWithguidance",
      });
      console.error(
        "something went wrong in addMeditation with guidance",
        error
      );
    }
  };

  return (
    // <form noValidate onSubmit={submit}>
    // <Card>
    <Root ref={ref} className={classes.root}>
      {isLoading && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            right: "50%",
            zIndex: 5,
          }}
        />
      )}
      <Grid
        container
        className="p-2 align-items-center"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid container alignItems="center">
            <Grid>
              <IconButton onClick={() => props.onClickBack()} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid>
              <Typography variant="h6">
                Add New Meditation With Guidance
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              type="text"
              value={title}
              onChange={(e) => {
                //onChangeTitle(e);
                setTitle(e.target.value);
              }}
              // helperText={`Please enter the title of the meditation`}
              label="Meditation Title"
              name="meditationTitle"
              required
              variant="outlined"
              //value={title ? title[titleLang.toLowerCase()] || "" : ""}
              className="mr-3"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              type="text"
              value={subTitle}
              onChange={(e) => {
                setSubTitle(e.target.value);
              }}
              label="Meditation Sub-Title"
              name="meditationTitle"
              // required
              variant="outlined"
              className="mr-3"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              required
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              //helperText={`Please enter the description of the meditation`}
              label="Description"
              name="description"
              rows={2}
              variant="outlined"
              multiline
              value={description}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="activityType"
              value={activityType}
              onChange={(e) => {
                setActivityType(e.target.value);
              }}
              label="Activity Type"
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="medType">Meditation Category</InputLabel>
              <Select
                labelId="medType"
                value={meditationCategory}
                required
                onChange={(value: any) => {
                  setMeditationCategory(value.target.value);
                  // console.log("selected val", value.target.value);
                }}
                label="Meditation Category"
              >
                {meditationCategories.map((item, index) => {
                  return (
                    <MenuItem key={`meditation${index}`} value={index+1}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              type="number"
              onChange={(e) => {
                setDuration(e.target.value);
              }}
              onBlur={() => {
                (duration === "" || +duration < 0) && setDuration(0);
              }}
              // helperText={`Please enter the duration of the meditation`}
              label="Meditation duration (in sec)"
              name="duration"
              required
              variant="outlined"
              value={duration}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              type="number"
              onChange={(e) => {
                setInstructionDuration(e.target.value);
              }}
              onBlur={() => {
                (instructionDuration === "" || +instructionDuration < 0) &&
                  setInstructionDuration(0);
              }}
              // helperText={`Please enter the duration of the meditation`}
              label="Instruction clip duration (in sec)"
              name="instructionDuration"
              required
              variant="outlined"
              value={instructionDuration}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Tags</InputLabel>
              <Select
                labelId="tags-multiple-checkbox-label"
                id="tags-multiple-checkbox"
                multiple
                value={selectedTagsId}
                onChange={handleMultipleTagChange}
                input={<OutlinedInput label="Tags" />}
                renderValue={() => selectedTags.join(",")}
                MenuProps={{
                  sx: {
                    maxHeight: "500px",
                  },
                }}
              >
                {tagsList.loading ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  tagsList.tags.tagsList.map((x: any) => (
                    <MenuItem key={x.id} value={x.id}>
                      <Checkbox checked={selectedTagsId.indexOf(x.id) > -1} />
                      <ListItemText primary={String(x.name)} />
                    </MenuItem>
                  ))
                )}
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={tagsList.tags.pageable?.totalcount!}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={10}
                  style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
                />
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined" className={classes.root}>
                {croppedImageUrl ? (
                  <PreviewProfileImage
                    onImageClose={onImageClose}
                    src={croppedImageUrl}
                    rectangular={true}
                  />
                ) : (
                  <>
                    {isUploading.image === true ? (
                      <Card className={classes.thumbNail}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="pt-5"
                        >
                          <Typography className="pt-4">Uploading...</Typography>
                        </Grid>
                      </Card>
                    ) : (
                      <CardActionArea
                        className={classes.thumbNail}
                        onClick={() => {
                          handleUploadClick();
                        }}
                      >
                        {/* <InputLabel htmlFor="thumbnail">
                          Upload thumbnail
                        </InputLabel> */}
                        <Avatar className={classes.actionIcon} variant="square">
                          <ImageOutlined />
                        </Avatar>
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={onSelectImage}
                          ref={hiddenFileInput}
                        />
                      </CardActionArea>
                    )}
                  </>
                )}
              </Card>
              <Typography variant="overline" align="center" gutterBottom>
                Thumbnail
              </Typography>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined" className={classes.root}>
                {selectedcroppedImageUrl ? (
                  <PreviewProfileImage
                    onImageClose={_onImageClose}
                    src={selectedcroppedImageUrl}
                    rectangular={true}
                  />
                ) : (
                  <>
                    {isUploading.selectedImage === true ? (
                      <Card className={classes.thumbNail}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="pt-5"
                        >
                          <Typography className="pt-4">Uploading...</Typography>
                        </Grid>
                      </Card>
                    ) : (
                      <CardActionArea
                        className={classes.thumbNail}
                        onClick={() => {
                          _handleUploadClick();
                        }}
                      >
                        {/* <InputLabel htmlFor="thumbnail">
                          Upload favourite image
                        </InputLabel> */}
                        <Avatar className={classes.actionIcon} variant="square">
                          <ImageOutlined />
                        </Avatar>
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={_onSelectImage}
                          ref={hiddenSelectedImageInput}
                        />
                      </CardActionArea>
                    )}
                  </>
                )}
              </Card>
              <Typography variant="overline" align="center" gutterBottom>
                Favourite Image
              </Typography>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined" className={classes.root}>
                {backgroundImageUrl ? (
                  <PreviewProfileImage
                    onImageClose={onBackgroundImageClose}
                    src={backgroundImageUrl}
                    rectangular={true}
                  />
                ) : (
                  <>
                    {isUploading.backgroundImage === true ? (
                      <Card className={classes.thumbNail}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="pt-5"
                        >
                          <Typography className="pt-4">Uploading...</Typography>
                        </Grid>
                      </Card>
                    ) : (
                      <CardActionArea
                        className={classes.thumbNail}
                        onClick={() => {
                          backgroundHandleUploadClick();
                        }}
                      >
                        {/* <InputLabel htmlFor="thumbnail">
                          Upload background image
                        </InputLabel> */}
                        <Avatar className={classes.actionIcon} variant="square">
                          <ImageOutlined />
                        </Avatar>
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={onSelectBackgroundImage}
                          ref={hiddenBackgroundImageInput}
                        />
                      </CardActionArea>
                    )}
                  </>
                )}
              </Card>
              <Typography variant="overline" align="center" gutterBottom>
                Background Image
              </Typography>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined" className={classes.root}>
                {audioUploadUrl ? (
                  <>
                    <AudioPlayer
                      src={audioUploadUrl}
                      onAudioClose={onAudioClose}
                    />
                  </>
                ) : (
                  <>
                    {isUploading.audio === true ? (
                      <Card className={classes.thumbNail}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="pt-5"
                        >
                          <Typography className="pt-4">Uploading...</Typography>
                        </Grid>
                      </Card>
                    ) : (
                      <CardActionArea
                        className={classes.thumbNail}
                        onClick={() => {
                          handleAudioClick();
                        }}
                      >
                        {/* <InputLabel htmlFor="thumbnail">
                          Upload Audio
                        </InputLabel> */}
                        <Avatar className={classes.actionIcon} variant="square">
                          <MusicNoteOutlined />
                        </Avatar>
                        <input
                          accept="audio/*"
                          id="audioFile"
                          type="file"
                          hidden
                          onChange={onAudioSelection}
                          ref={hiddenAudioInput}
                        />
                      </CardActionArea>
                    )}
                  </>
                )}
              </Card>
              <Typography variant="overline" align="center" gutterBottom>
                Audio
              </Typography>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          className={classes.formBtn}
          onClick={() => {
            _addMeditation();
          }}
          disabled={isAdding}
          color="primary"
          variant="contained"
        >
          Add Meditation
        </Button>
      </Box>

      {src && (
        <ImageCrop
          src={src.toString()}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onCropComplete={onCropComplete}
          onCropChange={onCropChange}
          onPressDone={onCropDone}
          onImageClose={onImageClose}
          open={open}
          onOpen={_onOpenImage}
          onClose={onImageClose}
        />
      )}
      {selectedImagesrc && (
        <ImageCrop
          src={selectedImagesrc.toString()}
          crop={selectedImagecrop}
          onImageLoaded={_onImageLoaded}
          onCropComplete={_onCropComplete}
          onCropChange={_onCropChange}
          onPressDone={_onCropDone}
          onImageClose={_onImageClose}
          open={open}
          onOpen={_onOpenImage}
          onClose={_onImageClose}
        />
      )}
      {backgroundImageSrc && (
        <ImageCrop
          src={backgroundImageSrc.toString()}
          crop={backgroundImageCrop}
          onImageLoaded={onBackgroundImageLoaded}
          onCropComplete={onBackgroundCropComplete}
          onCropChange={onBackgroundCropChange}
          onPressDone={onBackgroundCropDone}
          onImageClose={onBackgroundImageClose}
          open={open}
          onOpen={_onOpenImage}
          onClose={onBackgroundImageClose}
        />
      )}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Root>
  );
});
