import React, { BaseSyntheticEvent, useState } from "react";
import { version } from "../../../../package.json";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useAuth } from "services/AuthService";
import { isValidEmail } from "utils/helpers";
import Copyright from "components/copyright";
import { firebaseAuth } from "config/firebase";
import firebase from "firebase";
import {
  ConnectUserRequest,
  ConnectUserResponse,
  Role,
} from "api/models/heartintune-profile-service/heartintune-profile_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import { HEARTINTUNE_PROFILE_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { useDispatch } from "react-redux";
import { userRoleUpdate } from "redux/reducers/userReducer";
import { useHistory } from "react-router";
import { Link as ReactRouterLink } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import GoogleIcon from "@mui/icons-material/Google";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -8,
  },
}));
interface State {
  loading?: boolean;
  showError?: boolean;
  errorMessage?: string;
  formErrors?: {
    email: boolean;
    password: boolean;
  };
}

export default function LoginScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { emailLogin } = useAuth();
  const history = useHistory();
  const [state, setState] = useState<State>();
  const [showPassword, setshowPassword] = useState(false);

  function handleSubmit(e: BaseSyntheticEvent) {
    e.preventDefault();
    const {
      email: { value: email },
      password: { value: password },
    } = e.target.elements;

    if (email && isValidEmail(email) && password) {
      setState({
        loading: true,
        formErrors: {
          email: email === "" || !isValidEmail(email),
          password: password === "",
        },
      });
      emailLogin &&
        emailLogin(email, password)
          .then((res) => {
            console.log("firebase response for login", res);

            setState({
              loading: false,
            });
            userLogin(res.user?.uid);
          })
          .catch((err) => {
            console.log('firebase login error',err)
            setState({
              loading: false,
              errorMessage: `${
                err?.message || err.toString()
              } Try sign in using google`,
              showError: true,
            });
          });
    } else {
      setState({
        formErrors: {
          email: email === "" || !isValidEmail(email),
          password: password === "",
        },
      });
    }
  }
  function logInWithGoogle() {
    firebaseAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((res) => {
        console.log("firebase response for google login", res);
        userLogin(res.user?.uid);
      });
  }
  const userLogin = async (uid: any) => {
    // const data = {
    //   uid: uid,
    //   status: 2,
    //   provider: 4,
    // };
    try {
      console.log("uid for login", uid);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ConnectUserRequest = new ConnectUserRequest();
      //reqBody.setUserid(userId);
      reqBody.setStatus(2);
      reqBody.setProvider(4);
      reqBody.setFirebaseid(uid);

      grpc.unary(HeartIntuneProfileService.UserLogin, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },

        onEnd: (res: UnaryOutput<ConnectUserResponse>) => {
          const { status, statusMessage, message } = res;
          console.log("statusMessage", statusMessage);
          console.log("grpc code", status);
          if (status === grpc.Code.OK && message) {
            if (message) {
              //updateCurrentUser(message.toObject().user);
              console.log("data coming from user login ", message.toObject());
              const userRole = message.toObject().user?.role;
              console.log("user role", userRole);
              localStorage.setItem(
                "user",
                JSON.stringify(message.toObject().user)
              );
              dispatch(userRoleUpdate(message.toObject().user?.role));

              if (
                userRole ===
                (Role.USER || Role.CUSTOM_ROLE || Role.UNKNOWN_ROLE)
              ) {
                //history.replace("/login");
                setState({
                  loading: false,
                  errorMessage:
                    "You are not authorized to sign in to admin portal",
                  showError: true,
                });
                history.replace("/login");
              } else {
                if (!history.location.pathname.match("/app"))
                  history.replace("/app");
              }
            }
          }
        },
      });
    } catch (err: any) {
      console.log(err);
      setState({
        loading: false,
        errorMessage: `${
          err?.message || err.toString()
        }. Try sign in using google`,
        showError: true,
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            error={state?.formErrors?.email}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            error={state?.formErrors?.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setshowPassword(!showPassword)}
                    size="large"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={state?.loading}
            >
              Sign In
            </Button>
            {state?.loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            <Divider sx={{ mb: 2 }}>or</Divider>
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              onClick={logInWithGoogle}
              startIcon={<GoogleIcon />}
            >
              Login With Google
            </Button>
          </div>
          <Grid container justifyContent="center">
            <Grid item>
              <Link variant="body2" align="center">
                <ReactRouterLink to="forgot">Forgot password?</ReactRouterLink>
              </Link>
            </Grid>
            {/* <Grid item>
              <RouterLink to="/signup" className="MuiLink-underlineNone">
                <Typography
                  variant="body2"
                  color="primary"
                  className="MuiLink-underlineHover MuiLink-button"
                >
                  Create Account
                </Typography>
              </RouterLink>
            </Grid> */}
          </Grid>
        </form>
      </div>
      <Box mt={2}>
        <Typography align="center" color="textSecondary" variant="body2">
          Version # {version}
        </Typography>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Snackbar
        key="login-snack"
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        autoHideDuration={6000}
        open={state?.showError}
        onClose={() => {
          setState({
            showError: false,
          });
        }}
      >
        <Alert severity="error">{state?.errorMessage}</Alert>
      </Snackbar>
    </Container>
  );
}
