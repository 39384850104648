import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import { ImageOutlined } from "@mui/icons-material";
import {
  ContentCategoryRequest,
  ContentRequest,
  ContentResponse,
  DeleteFileRequest,
  ResponseStatus,
  Status,
} from "api/models/gamification-service/gamification_pb";
import { firebaseAuth } from "config/firebase";
import DialogBox from "components/dialogBox/dialog";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import { useFileUploadService } from "services/FileUplaodService";
import { toUploadFile } from "utils/helpers";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { RootState } from "redux/store/store";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { grpc } from "@improbable-eng/grpc-web";
import { getAllPDFs } from "redux/actions/heartsAppActions";

interface PdfProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21;
  page: number;
  rowsPerPage: number;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: String;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
  deleteItemId: "0",
};

const PREFIX = "PdfForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
    //overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

const localeObject = {
  defaultcontent: "",
  en: "",
  hi: "",
  te: "",
  gu: "",
  ta: "",
  ka: "",
  mr: "",
  it: "",
  ru: "",
  es: "",
  fr: "",
  pt: "",
  de: "",
  bn: "",
  ml: "",
  uk: "",
  od: "",
  enUs: "",
  enAu: "",
  pa: "",
};

const languageType = Object.keys(localeObject);

export const PdfsForms = React.forwardRef<HTMLDivElement, PdfProps>(
  (props, ref): ReactElement => {
    const doccumentCategoryList = [
      {
        displayName: "Anxiety",
        code: 1,
      },
      {
        displayName: "Sleep",
        code: 2,
      },
      {
        displayName: "Meditate",
        code: 3,
      },
      {
        displayName: "Cleaning",
        code: 4,
      },
    ];
    const dispatch = useDispatch();

    const [pdfCategory, setPdfCategory] = useState<any>(1);
    const languages = useSelector((state: RootState) => state.app.languages);
    const { appendFilesToUploadManager } = useFileUploadService();
    const [language, setLanguage] = useState<any>(1);
    const [states, setStates] = useState<State>(initialState);
    const [id, setId] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [subtitle, setSubtitle] = useState<string>("");
    const [readingTime, setReadingTime] = useState<number>(10);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pdfUrl, setPdfUrl] = useState<string>("");
    const [isAdding, setIsAdding] = useState(false);
    const hiddenFileInput1: any = React.useRef(null);
    const [isPdfUploading, setIsPdfUploading] = useState(false);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const onClickEdit = useCallback(
      (item: any) => {
        setStates({ editing: true });
        setLanguage(props.lang);
        setId(item.id);
        setTitle(item.title);
        setSubtitle(item.subtitle);
        setReadingTime(item.readingtime);
        setPdfUrl(item.pdfurl);
        setPdfCategory(item.documentcategory);
      },
      [props.lang]
    );

    useEffect(() => {
      console.log("Props: ", props.editItem);
      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
    }, [props.isEditing, props.editItem, onClickEdit]);

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("pdf url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
              }
            } else {
              setIsLoading(false);
            }
          },
        });
      } catch (err) {
        console.log("Error ", err);
      }
    };
    const handleUploadClick1 = () => {
      hiddenFileInput1.current.click();
    };

    const onSelectPdf = (event: any) => {
      const file = event.target.files[0];
      if (event.target.files[0]) {
        //setImageFile(event.target.files[0]);
        if (file && file.type === "application/pdf") {
          setIsPdfUploading(true);
          const reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]);
          reader.onloadend = () => {
            //setPdfSrc(reader.result);
            console.log("reader.result", event.target.files[0]);
            const uploadFile = toUploadFile(
              event.target.files[0],
              `${
                language === 1
                  ? "HeartsApp/pdfs"
                  : `rHeartsApp/pdfs/${languages[language - 1].displayName
                      .toLowerCase()
                      .replaceAll(" ", "")}`
              }`
            );
            appendFilesToUploadManager([uploadFile]);
            window.addEventListener(
              uploadFile.token,
              (res: CustomEventInit<UploadEventResponse>) => {
                if (res.detail?.code === 200) {
                  setPdfUrl(res.detail.response);
                  console.log("pdf url res", res.detail.response);
                  setIsPdfUploading(false);
                } else {
                  setIsPdfUploading(false);
                }
              }
            );
          };
        } else {
          alert("Please select a pdf file.");
          // Show an error message or perform other actions for invalid file types
        }
      }
    };

    const onImageClose = () => {
      _deleteFileFromGcp(pdfUrl);
      setPdfUrl("");
      setIsPdfUploading(false);
    };

    const onClickDelete = (pdfId: any) => {
      setStates({ showDeleteModal: true, deleteItemId: pdfId });
    };

    const handleDeleteClose = () => {
      setStates({ showDeleteModal: false });
    };

    const deletePdf = async () => {
      deletePdfById(states.deleteItemId);
    };
    const deletePdfById = async (pdfId: String | undefined) => {
      setIsLoading(true);
      console.log(`Delete call for pdf with id ${pdfId}`);
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: ContentCategoryRequest = new ContentCategoryRequest();
        reqBody.setContentid(Number(pdfId));
        reqBody.setContentcategory(6);
        grpc.unary(GamificationService.DeleteContentByIDAndCategory, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<Status>) => {
            const { status, message } = res;
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(`Delete pdf response ${message.toObject()}`);
                setIsLoading(false);
                _deleteFileFromGcp(pdfUrl);
                setStates({ showDeleteModal: false });
                setTimeout(() => props.onClickBack(), 2000);
                dispatch(getAllPDFs(props.rowsPerPage, props.page, props.lang));
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: " Deleted pdf successfully",
                });
              } else {
                setIsLoading(false);
                setStates({ showDeleteModal: false });
                setSnackBar({
                  open: true,
                  severity: "Error",
                  message: "Error in  deleting pdf",
                });
              }
            }
          },
        });
      } catch (err) {
        setIsLoading(false);
        console.log(`Error: ${err}`);
      }
    };

    const validateForm = () => {
      if (title.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Pdf title can not be empty.",
        });
      } else if (subtitle.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Pdf description can not be empty.",
        });
      } else if (!pdfUrl) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "pdf can not be empty.",
        });
      } else {
        return true;
      }
    };

    const _addPdf = () => {
      if (validateForm()) {
        const pdfData = {
          title: {
            ...localeObject,
            ...(language === 1 || language === 2
              ? { defaultcontent: title, en: title }
              : { [languageType[language - 1]]: title }),
          },

          subtitle: {
            ...localeObject,
            ...(language === 1 || language === 2
              ? { defaultcontent: subtitle, en: subtitle }
              : { [languageType[language - 1]]: subtitle }),
          },
          readingTime: readingTime,
          isWebView: false,
          webViewUrl: "",
          contentCategory: 6,
          featuredContent: pdfCategory,
          video: "",
        };
        if (props.isEditing) {
          setIsAdding(true);
          updatePdf(pdfData);
        } else {
          setIsAdding(true);
          addPdf(pdfData);
        }
      }
    };

    const updatePdf = async (data: any) => {
      setIsLoading(true);
      try {
        console.log(`Request to adding new pdf ${data}`);

        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title?.defaultcontent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);
        titleLocale.setPa(data.title.pa);

        const subtitleLocale: Locale = new Locale();
        subtitleLocale.setDefaultcontent(data.subtitle?.defaultcontent);
        subtitleLocale.setEn(data.subtitle.en);
        subtitleLocale.setHi(data.subtitle.hi);
        subtitleLocale.setTe(data.subtitle.te);
        subtitleLocale.setGu(data.subtitle.gu);
        subtitleLocale.setTa(data.subtitle.ta);
        subtitleLocale.setKa(data.subtitle.ka);
        subtitleLocale.setMr(data.subtitle.mr);
        subtitleLocale.setIt(data.subtitle.it);
        subtitleLocale.setRu(data.subtitle.ru);
        subtitleLocale.setEs(data.subtitle.es);
        subtitleLocale.setFr(data.subtitle.fr);
        subtitleLocale.setPt(data.subtitle.pt);
        subtitleLocale.setDe(data.subtitle.de);
        subtitleLocale.setBn(data.subtitle.bn);
        subtitleLocale.setMl(data.subtitle.ml);
        subtitleLocale.setUk(data.subtitle.uk);
        subtitleLocale.setOd(data.subtitle.od);
        subtitleLocale.setEnUs(data.subtitle.enUs);
        subtitleLocale.setEnAu(data.subtitle.enAu);
        subtitleLocale.setPa(data.subtitle.pa);

        const reqBody = new ContentResponse();
        reqBody.setTitle(titleLocale);
        reqBody.setSubtitle(subtitleLocale);
        reqBody.setId(props.editItem.id);
        reqBody.setPdfurl(pdfUrl);
        reqBody.setReadingtime(data.readingTime);
        reqBody.setWebviewurl(data.webViewUrl);
        reqBody.setContentcategory(6);
        reqBody.setDocumentcategory(pdfCategory);
        reqBody.setFeaturedcontent(2);

        console.log("Request for updating pdf", reqBody.toObject());
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GamificationService.UpdateContent, {
          request: reqBody,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ContentResponse>) => {
            const { status, message, statusMessage } = res;
            if (status === grpc.Code.OK && message) {
              console.log("update pdf response", message.toObject());
              setIsLoading(false);
              dispatch(getAllPDFs(props.rowsPerPage, props.page, props.lang));
              setSnackBar({
                open: true,
                severity: "success",
                message: "pdf updated successfully",
              });
              setTimeout(() => props.onClickBack(), 2000);
            } else {
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: `Error in update pdf : ${statusMessage}`,
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.log(`Error in update pdf ${error}`);
        setSnackBar({
          open: true,
          severity: "error",
          message: error,
        });
      }
    };

    const addPdf = async (data: any) => {
      setIsLoading(true);
      try {
        console.log(`Request to adding new pdf ${data}`);

        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultcontent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);
        titleLocale.setPa(data.title.pa);

        const subtitleLocale: Locale = new Locale();
        subtitleLocale.setDefaultcontent(data.subtitle.defaultcontent);
        subtitleLocale.setEn(data.subtitle.en);
        subtitleLocale.setHi(data.subtitle.hi);
        subtitleLocale.setTe(data.subtitle.te);
        subtitleLocale.setGu(data.subtitle.gu);
        subtitleLocale.setTa(data.subtitle.ta);
        subtitleLocale.setKa(data.subtitle.ka);
        subtitleLocale.setMr(data.subtitle.mr);
        subtitleLocale.setIt(data.subtitle.it);
        subtitleLocale.setRu(data.subtitle.ru);
        subtitleLocale.setEs(data.subtitle.es);
        subtitleLocale.setFr(data.subtitle.fr);
        subtitleLocale.setPt(data.subtitle.pt);
        subtitleLocale.setDe(data.subtitle.de);
        subtitleLocale.setBn(data.subtitle.bn);
        subtitleLocale.setMl(data.subtitle.ml);
        subtitleLocale.setUk(data.subtitle.uk);
        subtitleLocale.setOd(data.subtitle.od);
        subtitleLocale.setEnUs(data.subtitle.enUs);
        subtitleLocale.setEnAu(data.subtitle.enAu);
        subtitleLocale.setPa(data.subtitle.pa);

        const reqBody = new ContentRequest();
        reqBody.setTitle(titleLocale);
        reqBody.setSubtitle(subtitleLocale);
        reqBody.setPdfurl(pdfUrl);
        reqBody.setReadingtime(data.readingTime);
        reqBody.setIswebview(data.isWebView);
        reqBody.setWebviewurl(data.webViewUrl);
        reqBody.setContentcategory(6);
        reqBody.setDocumentcategory(pdfCategory);
        reqBody.setFeaturedcontent(2);

        console.log("Request for adding pdf", reqBody.toObject());
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GamificationService.AddContent, {
          request: reqBody,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ContentResponse>) => {
            const { status, message, statusMessage } = res;
            if (status === grpc.Code.OK && message) {
              console.log("add pdf response", message.toObject());
              setIsLoading(false);
              dispatch(getAllPDFs(props.rowsPerPage, props.page, props.lang));
              setSnackBar({
                open: true,
                severity: "success",
                message: "pdf added successfully",
              });
              setTimeout(() => props.onClickBack(), 2000);
            } else {
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: `Error in add pdf : ${statusMessage}`,
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.log(`Error in add pdf ${error}`);
        setSnackBar({
          open: true,
          severity: "error",
          message: { error },
        });
      }
    };

    return (
      <Root ref={ref} className={classes.root}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Pdf" : "Add new Pdfs"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      PDFs
                    </Typography>
                    <Tooltip
                      title={props.editItem.title}
                      disableHoverListener={props.editItem.title?.length < 20}
                      placement="bottom"
                    >
                      <Box>
                        <Typography
                          color="inherit"
                          style={{ cursor: "pointer" }}
                        >
                          {props.editItem.title?.slice(0, 20)}
                          {props.editItem.title?.length > 20 && "..."}
                        </Typography>
                      </Box>
                    </Tooltip>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => onClickDelete(id)}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="title"
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={1}
                type="text"
                name="subtitle"
                label="Description"
                variant="outlined"
                required
                value={subtitle}
                onChange={(e) => {
                  setSubtitle(e.target.value);
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="language-select">Category</InputLabel>
                <Select
                  defaultValue=""
                  labelId="pdf-category-select"
                  value={pdfCategory}
                  label="Category"
                  onChange={(e) => {
                    setPdfCategory(e.target.value as number);
                  }}
                >
                  {doccumentCategoryList.map((catagory: any, i: any) => (
                    <MenuItem key={i} value={catagory.code}>
                      {catagory.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} />
            <Grid item md={4} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {pdfUrl ? (
                    <PreviewProfileImage
                      onImageClose={onImageClose}
                      src={pdfUrl}
                      rectangular
                      type="pdf"
                    />
                  ) : (
                    <>
                      {isPdfUploading ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.root}
                          onClick={() => {
                            handleUploadClick1();
                          }}
                        >
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <ImageOutlined />
                          </Avatar>
                          <input
                            accept="application/pdf"
                            id="contained-button-file"
                            type="file"
                            hidden
                            onChange={onSelectPdf}
                            ref={hiddenFileInput1}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
                {pdfUrl ? (
                  <Button
                    onClick={() => {
                      console.log("first", pdfUrl);
                      window.open(pdfUrl, "_blank");
                    }}
                  >
                    View Pdf
                  </Button>
                ) : (
                  <Typography variant="overline" align="center" gutterBottom>
                    Upload Pdf
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            className={classes.formBtn}
            onClick={props.onClickBack}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={classes.formBtn}
            onClick={() => _addPdf()}
            color="primary"
            variant="contained"
            disabled={isAdding}
          >
            {props.isEditing ? "Update PDF" : "Add PDF"}
          </Button>
        </Box>

        <DialogBox
          open={states.showDeleteModal || false}
          onClose={handleDeleteClose}
          onCancel={handleDeleteClose}
          onConfirm={deletePdf}
          type={"delete"}
          title={`Sure !! Want to delete this pdf ?`}
        />

        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
