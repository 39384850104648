import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Snackbar,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogBox from "components/dialogBox/dialog";
import { useDispatch } from "react-redux";
import {
  addQuotes,
  deleteQuotes,
  getAllQuote,
  updateQuotes,
} from "redux/actions/quoteActions";
import { classes } from "../../groups/schedulePreRecordedVideo/scheduledPreRecordedVideo.styles";
import { Root } from "./quotes.styles";

interface QuotesProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang: number;
  getAllQuoteData: getAllQuote;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
};

export const QuotesForm = React.forwardRef<HTMLDivElement, QuotesProps>(
  (props, ref): ReactElement => {
    const languageType = [
      "defaultcontent",
      "en",
      "hi",
      "te",
      "gu",
      "ta",
      "ka",
      "mr",
      "it",
      "ru",
      "es",
      "fr",
      "pt",
      "de",
      "bn",
      "ml",
      "uk",
      "od",
      "enUs",
      "enAu",
      "pa",
    ];

    const dispatch = useDispatch();

    const [states, setStates] = useState<State>(initialState);
    const [id, setId] = useState<number>();
    const [author, setAuthor] = useState({
      defaultcontent: "",
      en: "",
      hi: "",
      te: "",
      gu: "",
      ta: "",
      ka: "",
      mr: "",
      it: "",
      ru: "",
      es: "",
      fr: "",
      pt: "",
      de: "",
      bn: "",
      ml: "",
      uk: "",
      od: "",
      enUs: "",
      enAu: "",
      pa: "",
    });
    const [quote, setQuote] = useState({
      defaultcontent: "",
      en: "",
      hi: "",
      te: "",
      gu: "",
      ta: "",
      ka: "",
      mr: "",
      it: "",
      ru: "",
      es: "",
      fr: "",
      pt: "",
      de: "",
      bn: "",
      ml: "",
      uk: "",
      od: "",
      enUs: "",
      enAu: "",
      pa: "",
    });
    const [tagone, setTagone] = useState<string>("");
    const [tagtwo, setTagtwo] = useState<string>("");
    const [tagthree, setTagthree] = useState<string>("");
    const [screen, setScreen] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<number>(0);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [isAdding, setIsAdding] = useState(false);

    const onClickEdit = useCallback((item: any) => {
      setStates({ editing: true });
      setId(item.id);
      setAuthor({
        ...item.author,
        [languageType[props.lang - 1]]: item.author[
          languageType[props.lang - 1]
        ]
          ? item.author[languageType[props.lang - 1]]
          : item.author.defaultcontent,
      });
      setQuote({
        ...item.quote,
        [languageType[props.lang - 1]]: item.quote[languageType[props.lang - 1]]
          ? item.quote[languageType[props.lang - 1]]
          : item.quote.defaultcontent,
      });
      setTagone(item.tagone);
      setTagtwo(item.tagtwo);
      setTagthree(item.tagthree);
      setScreen(item.screen);
    }, []);

    useEffect(() => {
      console.log("EditItem: ", props.editItem);
      if (props.isEditing) {
        onClickEdit(props.editItem);
        //setLanguage(props.lang - 1);
      }
      setLanguage(props.lang - 1);
    }, [onClickEdit, props.editItem, props.isEditing, props.lang]);

    const validateForm = () => {
      if (author[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Quote author can not be empty.",
        });
      } else if (quote[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Quote can not be empty.",
        });
      } else if (tagone.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Quote Tag 1 can not be empty.",
        });
      } else if (tagtwo.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Quote Tag 2 time can not be less than zero.",
        });
      } else if (tagthree.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Quote Tag 3 can not be empty.",
        });
      } else {
        return true;
      }
    };

    const quoteHandler = () => {
      if (validateForm()) {
        console.log({
          id: Number(id),
          author,
          quote,
          tagone,
          tagtwo,
          tagthree,
          screen,
          setSnackBar,
        });
        setIsLoading(true);
        if (props.isEditing) {
          setIsAdding(true);
          dispatch(
            updateQuotes({
              id: Number(id),
              author,
              quote,
              tagone,
              tagtwo,
              tagthree,
              screen,
              setSnackBar,
              getAllQuoteData: props.getAllQuoteData,
            })
          );
        } else {
          setIsAdding(true);
          dispatch(
            addQuotes({
              author: { ...author, en: author.defaultcontent },
              quote: { ...quote, en: quote.defaultcontent },
              tagone,
              tagtwo,
              tagthree,
              screen,
              setSnackBar,
              getAllQuoteData: props.getAllQuoteData,
            })
          );
        }
        setTimeout(() => {
          setIsLoading(false);
          props.onClickBack();
        }, 2000);
      }
    };

    const deleteQuote = () => {
      setIsLoading(true);
      setStates({ showDeleteModal: false });
      dispatch(
        deleteQuotes({
          id: Number(id),
          setSnackBar,
          getAllQuoteData: {
            pageNumber: 0,
            pageSize: 10,
          },
        })
      );
      setTimeout(() => {
        setIsLoading(false);
        props.onClickBack();
      }, 2000);
    };

    return (
      <Root ref={ref} className={classes.root}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Quote" : "Add New Quote"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      Quotes
                    </Typography>
                    <Typography color="inherit" style={{ cursor: "pointer" }}>
                      {props.editItem.quote[languageType[language]]?.slice(
                        0,
                        21
                      )}
                      {props.editItem.quote[languageType[language]]?.length >
                        21 && "..."}
                    </Typography>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setStates({ showDeleteModal: true });
                  }}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="author"
                label="Author"
                variant="outlined"
                value={author[languageType[language]]}
                onChange={(e) => {
                  if (
                    languageType[language] === "defaultcontent" ||
                    languageType[language] === "en"
                  ) {
                    setAuthor((prevState) => ({
                      ...prevState,
                      defaultcontent: e.target.value,
                      en: e.target.value,
                    }));
                  } else {
                    setAuthor((prevState) => ({
                      ...prevState,
                      [languageType[language]]: e.target.value,
                    }));
                  }
                }}
                inputProps={{
                  maxLength: 20,
                }}
                helperText={`${author[languageType[language]].length}/${20}`}
                className="mr-3"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={2}
                required
                type="text"
                name="quote"
                label="Quote"
                variant="outlined"
                value={quote[languageType[language]]}
                onChange={(e) => {
                  if (
                    languageType[language] === "defaultcontent" ||
                    languageType[language] === "en"
                  ) {
                    setQuote((prevState) => ({
                      ...prevState,
                      defaultcontent: e.target.value,
                      en: e.target.value,
                    }));
                  } else {
                    setQuote((prevState) => ({
                      ...prevState,
                      [languageType[language]]: e.target.value,
                    }));
                  }
                }}
                inputProps={{
                  maxLength: 255,
                }}
                helperText={`${quote[languageType[language]].length}/${255}`}
                className="mr-3"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="tag1"
                label="Tag 1"
                variant="outlined"
                value={tagone}
                onChange={(e) => {
                  setTagone(e.target.value);
                }}
                inputProps={{
                  maxLength: 30,
                }}
                helperText={`${tagone.length}/${30}`}
                className="mr-3"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="tag2"
                label="Tag 2"
                variant="outlined"
                value={tagtwo}
                onChange={(e) => {
                  setTagtwo(e.target.value);
                }}
                inputProps={{
                  maxLength: 30,
                }}
                helperText={`${tagtwo.length}/${30}`}
                className="mr-3"
              />{" "}
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="tag3"
                label="Tag 3"
                variant="outlined"
                value={tagthree}
                onChange={(e) => {
                  setTagthree(e.target.value);
                }}
                inputProps={{
                  maxLength: 30,
                }}
                helperText={`${tagthree.length}/${30}`}
                className="mr-3"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                type="text"
                name="screen"
                label="Screen"
                variant="outlined"
                value={screen}
                onChange={(e) => {
                  setScreen(e.target.value);
                }}
                inputProps={{
                  maxLength: 30,
                }}
                helperText={`${screen.length}/${30}`}
                className="mr-3"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            className={classes.formBtn}
            onClick={props.onClickBack}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={classes.formBtn}
            onClick={() => quoteHandler()}
            color="primary"
            variant="contained"
            disabled={isAdding}
          >
            {props.isEditing ? "Update Quote" : "Add Quote"}
          </Button>
        </Box>
        <DialogBox
          open={states.showDeleteModal || false}
          onClose={() => {
            setStates({ showDeleteModal: false });
          }}
          onCancel={() => {
            setStates({ showDeleteModal: false });
          }}
          onConfirm={deleteQuote}
          type={"delete"}
          title={`Sure !! Want to delete this Quote ?`}
        />
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
