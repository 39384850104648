import {
  Avatar,
  Card,
  CardContent,
  Divider,
  FormControl,
  Typography,
  TextField,
  Grid,
  Button,
  CardActions,
  IconButton,
  Box,
  CardActionArea,
  InputLabel,
  Snackbar,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material";

import { Alert, Skeleton } from "@mui/material";
import {
  RequestAddBenefitAndSessionIDs,
  RequestSessionBenefits,
  ResponseSessionBenefits,
  SessionBenefits,
} from "api/models/live-meditation-service/live-meditation_pb";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBenifitsBySessionId,
  getAllLiveMeditationsBetweenDates,
  //getAllSessionBenifits,
  getAllSessionBenifitsByCoachId,
} from "redux/actions/liveMeditationAction";
import { schedularUpdateStep } from "redux/reducers/liveMeditationReducer";
import { RootState } from "redux/store/store";
import { firebaseAuth } from "config/firebase";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  GAMIFICATION_SERVICE_HOST,
  LIVE_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import style from "./index.module.scss";
import makeStyles from "@mui/styles/makeStyles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  ImageOutlined,
} from "@mui/icons-material";
import ImageCrop from "components/image-crop/image-crop";
import { useFileUploadService } from "services/FileUplaodService";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";
import moment from "moment";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 150,
    width: "100%",
  },
  rootVideo: {
    height: 250,
    width: "100%",
  },
  imgCardHeader: {
    height: "20%",
    textOverflow: "elipse",
  },
  thumbNail: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
  },
  actionIcon: {
    alignSelf: "center",
    //left: "45%",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },
}));
interface Crop {
  unit: "%" | "px" | undefined;
  width: number;
  aspect: number;
}

const imageFileDef: any = {
  file: null,
  fileUrl: null || "",
};

const SessionBenifits = React.forwardRef<HTMLDivElement, any>(
  (
    {
      sessionId,
      page,
      onClickEdit,
      setcurrentScreen,
      endDateTime,
      setstartDate,
      setendDate,
      coachId,
    },
    ref
  ): ReactElement => {
    //console.log('end date',endDateTime);
    // const CHARACTER_LIMIT = 150;
    // console.log('coach id in session benefit',coachId)
    const hiddenImageInput: any = React.useRef([]);
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.liveMeditation);

    const cropDef: Crop = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };

    const [imageRef, setImageRef] = useState<any>();
    const [src, setSrc] = useState<any>(null);
    const [crop, setCrop] = useState<Crop>(cropDef);
    const [imageFile, setimageFile] = useState<any>(null);
    const [croppedImage, setCroppedImage] = useState<any>(null);
    const [open, setopen] = useState(true);
    const [pageNumber, setpageNumber] = useState(0);
    const [pageSize, setpageSize] = useState(3);
    const [currentIndex, setcurrentIndex] = useState(0);
    const [checkedList, setcheckedList] = useState<number[]>([]);
    //const [value, setvalue] = useState<any[]>([]);
    const [sucessBenefit, setsucessBenefit] = useState({
      addNewBenefit: false,
      addExistingBenefit: false,
    });

    const [benefitsArr, setbenefitsArr] = useState<any>([
      { description: "", imageurl: "" },
    ]);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const { appendFilesToUploadManager } = useFileUploadService();

    // useEffect(() => {
    //   dispatch(getAllSessionBenifits(pageSize, pageNumber));
    // }, [dispatch,pageSize,pageNumber]);

    useEffect(() => {
      dispatch(getAllSessionBenifitsByCoachId(pageSize, pageNumber, coachId));
    }, [dispatch, pageSize, pageNumber, coachId]);

    const onClickAdd = () => {
      setbenefitsArr([...benefitsArr, { description: "", imageurl: "" }]);
    };
    const onClickDelete = (i: any) => {
      let newbenefitsArr = [...benefitsArr];
      newbenefitsArr.splice(i, 1);
      setbenefitsArr(newbenefitsArr);
    };

    const _onOpenCrop = () => {
      setopen(true);
    };
    const _onCloseCrop = () => {
      setopen(false);
      setSrc(null);
    };

    const onImageSelection = async (event: any, index: any) => {
      setcurrentIndex(index);
      if (event.target.files[0]) {
        setimageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onCropDone = async () => {
      const cr: any = crop;

      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);

        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        // const reader = new FileReader();
        // reader.readAsDataURL(croppedFile);
        // reader.onloadend = () => {
        //  console.log('crroped image url from benefit',reader.result)
        // };
        // let newbenefitsArr = [...benefitsArr];
        //       newbenefitsArr[currentIndex].imageurl = croppedUrl;
        //       setbenefitsArr(newbenefitsArr);
        const uploadFile = toUploadFile(
          croppedFile,
          "ProfilePicture".replaceAll(" ", "")
        );
        //console.log('crroped file from session benefit',croppedFile)
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              console.log(
                "img url response from session benefits",
                res.detail.response
              );
              let newbenefitsArr = [...benefitsArr];
              newbenefitsArr[currentIndex].imageurl = res.detail.response;
              setbenefitsArr(newbenefitsArr);
            }
          }
        );
        setCroppedImage(croppedFile);
      }
      setSrc(null);
    };
    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };

    const onCropChange = (crop: any) => {
      setCrop(crop);
    };
    const onCropComplete = async () => {
      //console.log("state crop", crop, imageRef);
    };

    const onImageClose = (index: number) => {
      setimageFile(imageFileDef);
      setSrc(null);
      setopen(false);
      setCrop(cropDef);
      let newbenefitsArr = [...benefitsArr];
      _deleteFileFromGcp(newbenefitsArr[index].imageurl);
      newbenefitsArr[index].imageurl = "";
      setbenefitsArr(newbenefitsArr);
      //_deleteFileFromGcp( newbenefitsArr[index].imageurl)
    };
    const onCloseImage = (index: number) => {
      setimageFile(imageFileDef);
      setopen(false);
      setSrc(null);
      setCrop(cropDef);
      let newbenefitsArr = [...benefitsArr];
      _deleteFileFromGcp(newbenefitsArr[index].imageurl);
      newbenefitsArr[index].imageurl = "";
      setbenefitsArr(newbenefitsArr);
    };

    const handleImageClick = (idx: any) => {
      hiddenImageInput.current[idx].click();
    };

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);

        //CroppedImageUrl
        //setIsLoading(true);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                //setIsLoading(false);
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
                //setCroppedImageUrl("");
              }
            } else {
              console.log("err", statusMessage);
              // setIsLoading(false);
              // setSnackBar({
              //   open: true,
              //   severity: "error",
              //   message: "error in removing coach image",
              // });
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };

    const _onClickCheck = (e: any, id: number) => {
      //let arr:any[]=[...value];

      let resultArray: number[] = [...checkedList];
      if (e.target.checked) {
        resultArray = checkedList.filter((CheckedId) => CheckedId !== id);
        resultArray.push(id);
        //arr.push({})
      } else {
        resultArray = checkedList.filter((CheckedId) => CheckedId !== id);
      }

      setcheckedList([...resultArray]);
    };

    const _addSessionBenifits = () => {
      let filterData: any[] = [];
      benefitsArr.forEach((item: any) => {
        if (item.description === "") {
          setSnackBar({
            open: true,
            severity: "error",
            message: "Benefit Title can not be empty",
          });
          filterData.push(item.description);
        } else return;
      });
      console.log("filtered data", filterData);
      const data = {
        sessionBenefitsList: benefitsArr,
        liveSessionID: sessionId,
      };
      if (!filterData.length) {
        addSessionBenifits(data);
      }
    };
    const addSessionBenifits = async (data: any) => {
      console.log("request data from add session benefit", data);
      try {
        var sessionBenifitsReq = new RequestSessionBenefits();
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        sessionBenifitsReq.setSessionbenefitslistList(
          data.sessionBenefitsList.map((benifits: SessionBenefits.AsObject) => {
            const _sessionBenifits = new SessionBenefits();
            _sessionBenifits.setDescription(benifits.description);
            _sessionBenifits.setImageurl(benifits.imageurl);
            return _sessionBenifits;
          })
        );
        sessionBenifitsReq.setLivesessionid(data.liveSessionID);

        grpc.unary(LiveMeditationService.AddSessionBenefits, {
          request: sessionBenifitsReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseSessionBenefits>) => {
            const { status, message } = res;

            if (status === grpc.Code.OK && message) {
              console.log("all ok: ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: "session benefits added successfully",
              });

              setsucessBenefit({
                ...sucessBenefit,
                addNewBenefit: true,
              });

              if (page === "liveSession") {
                dispatch(getAllBenifitsBySessionId(sessionId));
                // if (
                //   sucessBenefit.addNewBenefit &&
                //   sucessBenefit.addExistingBenefit
                // ) {
                //   setTimeout(() => onClickEdit(), 2000);
                // }
                setTimeout(() => onClickEdit(), 2000);
              } else {
                const end = moment(moment(new Date()).add(7, "day"))
                  .endOf("day")
                  .format("YYYY-MM-DD , HH:mm:ss");
                const start = moment(new Date())
                  .startOf("day")
                  .format("YYYY-MM-DD , HH:mm:ss");
                // setTimeout(() => dispatch(schedularUpdateStep(0)), 2000);

                setTimeout(() => {
                  setstartDate(start);
                  setendDate(end);
                  dispatch(schedularUpdateStep(0));
                  setcurrentScreen(0);
                  dispatch(getAllLiveMeditationsBetweenDates(start, end));
                }, 2000);
              }

              return message;
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: " error in adding benefits",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: " something went wrong in add session benefits",
        });

        console.error("something went wrong in add session benefits", error);
      }
    };
    const _addExistingBenifits = () => {
      const data = {
        liveSessionID: sessionId,
        benefitsID: checkedList,
      };
      _addBenefitsToSession(data);
    };

    const _addBenefitsToSession = async (data: any) => {
      console.log("benifits data", data);
      try {
        var sessionBenifitsReq = new RequestAddBenefitAndSessionIDs();
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();

        sessionBenifitsReq.setBenefitsidList(data.benefitsID);
        sessionBenifitsReq.setLivesessionid(data.liveSessionID);

        grpc.unary(LiveMeditationService.addBenefitsToSession, {
          request: sessionBenifitsReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ProtobufMessage>) => {
            const { status, message } = res;

            if (status === grpc.Code.OK && message) {
              console.log("all ok: ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: "benefits added to session successfully",
              });

              setsucessBenefit({
                ...sucessBenefit,
                addExistingBenefit: true,
              });
              if (page === "liveSession") {
                dispatch(getAllBenifitsBySessionId(sessionId));
                // if (
                //   sucessBenefit.addNewBenefit &&
                //   sucessBenefit.addExistingBenefit
                // ) {
                //   setTimeout(() => onClickEdit(), 2000);
                // }
                setTimeout(() => onClickEdit(), 2000);
              } else {
                const end = moment(endDateTime)
                  .endOf("day")
                  .format("YYYY-MM-DD , HH:mm:ss");
                const start = moment(new Date())
                  .startOf("day")
                  .format("YYYY-MM-DD,HH:mm:ss");
                // setTimeout(() => dispatch(schedularUpdateStep(0)), 2000);

                setTimeout(() => {
                  dispatch(getAllLiveMeditationsBetweenDates(start, end));
                  setstartDate(start);
                  setendDate(end);
                  dispatch(schedularUpdateStep(0));
                  setcurrentScreen(0);
                }, 2000);
              }
              return message;
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: " error in adding benefits to session",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: " error in adding benefits",
        });
        console.error(
          "something went wrong in add benefits to session ",
          error
        );
      }
    };

    const renderListSkeleton = () =>
      Array(4)
        .fill(0)
        .map((i, x) => (
          <React.Fragment key={`coachSkele${x}`}>
            <ListItem>
              <ListItemIcon>
                <Skeleton variant="circular">
                  <Avatar />
                </Skeleton>
              </ListItemIcon>
              <ListItemText
                primary={<Skeleton />}
                secondary={<Skeleton width="80%" />}
              />
            </ListItem>
            <Divider variant="inset" />
          </React.Fragment>
        ));

    const renderBenefitsList = () => {
      //console.log('benefitlistbycoach id data',state.benifitsListByCoachId.benifits.sessionbenefitsList)
      return (
        <Box
          maxHeight={450}
          style={{ overflowY: "scroll" }}
          className={style.scrollbar}
        >
          {state.benifitsListByCoachId.benifits.sessionbenefitsList.length ? (
            state.benifitsListByCoachId.benifits.sessionbenefitsList
              .filter(
                (ele, ind) =>
                  ind ===
                  state.benifitsListByCoachId.benifits.sessionbenefitsList.findIndex(
                    (elem) => elem.description === ele.description
                  )
              )
              .map((item, index) => (
                <React.Fragment key={index}>
                  <Grid container className="p-3">
                    <Grid item xs={1} style={{ maxWidth: "60px" }}>
                      <Avatar
                        //style={{ backgroundColor: "#3f51b5" }}
                        src={item.imageurl}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">
                        {item.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Checkbox
                        name={`${index}`}
                        color="primary"
                        // checked={checked}
                        value={item.id}
                        onChange={(e) => {
                          _onClickCheck(e, item.id);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Divider variant="inset" />
                </React.Fragment>
              ))
          ) : (
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No Data available
              </Typography>
            </Grid>
          )}
        </Box>
      );
    };

    return (
      <div ref={ref}>
        {/* <Card variant="outlined" className="mt-3 mb-5"> */}
        <Card>
          {page === "liveSession" && (
            <>
              <Grid
                container
                spacing={2}
                style={{
                  padding: " 8px",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <IconButton onClick={() => onClickEdit()} size="large">
                    <KeyboardBackspaceIcon color="action" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h6">Add Session Benefits</Typography>
                </Grid>
              </Grid>
              <Divider />
            </>
          )}
          <CardContent>
            <Grid
              container
              className="my-3"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  Create and add new benefit
                </Typography>
              </Grid>
              {/* {page === "liveSession" && (
            <Grid item>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => _addSessionBenifits()}
              >
                Create Benefits
              </Button>
            </Grid>
          )} */}
            </Grid>

            <Card variant="outlined">
              <CardContent>
                {benefitsArr.map((item: any, index: any) => (
                  <Grid
                    container
                    alignItems="center"
                    key={index}
                    className="mt-2"
                  >
                    <Grid item xs={12} md={11}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={7} md={9}>
                          <TextField
                            fullWidth
                            label="Benefits"
                            name="Benefits"
                            variant="outlined"
                            multiline
                            rows={6}
                            maxRows={20}
                            inputProps={{
                              // maxLength: CHARACTER_LIMIT,
                              style: {
                                fontSize: 14,
                              },
                            }}
                            value={item.description}
                            onChange={(e) => {
                              let newbenefitsArr = [...benefitsArr];
                              newbenefitsArr[index].description =
                                e.target.value;
                              setbenefitsArr(newbenefitsArr);
                            }}
                          />
                        </Grid>

                        <Grid item xs={10} sm={5} md={3}>
                          <FormControl variant="outlined" fullWidth>
                            <Card variant="outlined" className={classes.root}>
                              {item.imageurl ? (
                                <PreviewProfileImage
                                  onImageClose={onCloseImage}
                                  src={item.imageurl}
                                  file={croppedImage}
                                  size="small"
                                  index={index}
                                />
                              ) : (
                                <CardActionArea
                                  className={classes.thumbNail}
                                  onClick={() => {
                                    handleImageClick(index);
                                  }}
                                >
                                  <InputLabel htmlFor="image">
                                    Upload Icon
                                  </InputLabel>
                                  <Grid container justifyContent="center">
                                    <Avatar
                                      className={classes.actionIcon}
                                      variant="square"
                                    >
                                      <ImageOutlined />
                                    </Avatar>
                                    <input
                                      accept="image/*"
                                      id="contained-button-file"
                                      type="file"
                                      hidden
                                      onChange={(e) =>
                                        onImageSelection(e, index)
                                      }
                                      ref={(el) =>
                                        (hiddenImageInput.current[index] = el)
                                      }
                                    />
                                  </Grid>
                                </CardActionArea>
                              )}
                            </Card>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} md={1}>
                      {index ? (
                        <IconButton
                          onClick={() => onClickDelete(index)}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                    </Grid>
                  </Grid>
                ))}
                <Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={() => onClickAdd()}
                    className="mt-2"
                  >
                    Add row
                  </Button>
                </Grid>

                {src && (
                  <ImageCrop
                    src={src.toString()}
                    crop={crop}
                    onImageLoaded={onImageLoaded}
                    onCropComplete={onCropComplete}
                    onCropChange={onCropChange}
                    onPressDone={onCropDone}
                    onImageClose={onImageClose}
                    open={open}
                    onOpen={_onOpenCrop}
                    onClose={_onCloseCrop}
                  />
                )}
              </CardContent>
              {/* {page === "liveSession" ? null : ( */}
              <>
                <Divider />
                <CardActions className=" py-2 d-flex justify-content-end pr-3">
                  <Button
                    //size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => _addSessionBenifits()}
                  >
                    Create Benefits
                  </Button>
                </CardActions>
              </>
              {/* )} */}
            </Card>

            <Grid
              container
              className="my-3"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  Choose from existing benefit
                </Typography>
              </Grid>
              {/* {page === "liveSession" && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="mr-3"
                onClick={_addExistingBenifits}
              >
                Add Benifits
              </Button>
            </Grid>
          )} */}
            </Grid>
            <Card variant="outlined" style={{}} className="mt-2">
              <CardContent>
                {state.benifitsListByCoachId.loading
                  ? renderListSkeleton()
                  : renderBenefitsList()}
              </CardContent>
              <Divider />
              <CardActions className="py-0">
                <Grid
                  container
                  // spacing={2}
                  className="d-flex justify-content-end align-items-center"
                >
                  <Grid item>
                    {/* 1-{arr.length} of {state.benifitsList.benifits.totalcount} */}
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        if (pageNumber > 0) {
                          const previousPage = pageNumber - 1;
                          setpageNumber(pageNumber - 1);
                          setpageSize(3);
                          dispatch(
                            getAllSessionBenifitsByCoachId(
                              pageSize,
                              previousPage,
                              coachId
                            )
                          );
                        }
                      }}
                      disabled={pageNumber === 0}
                      size="large"
                    >
                      <ChevronLeftRounded />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography color="initial" variant="subtitle2">
                      Page {pageNumber + 1}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        const nextPage = pageNumber + 1;
                        setpageNumber(pageNumber + 1);
                        dispatch(
                          getAllSessionBenifitsByCoachId(
                            pageSize,
                            nextPage,
                            coachId
                          )
                        );
                      }}
                      size="large"
                    >
                      <ChevronRightRounded />
                    </IconButton>
                  </Grid>
                  {/* {page === "liveSession" ? null : ( */}
                  <Button
                    variant="contained"
                    color="primary"
                    className="mr-3"
                    onClick={_addExistingBenifits}
                    disabled={
                      !state.benifitsListByCoachId.benifits.sessionbenefitsList
                        .length
                    }
                  >
                    Add Benifits
                  </Button>
                  {/* )} */}
                </Grid>
              </CardActions>
            </Card>
          </CardContent>

          <Snackbar
            open={snackBar.open}
            autoHideDuration={10000}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              severity={snackBar.severity}
              onClose={() => {
                setSnackBar({ ...snackBar, open: false });
              }}
            >
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Card>
      </div>
    );
  }
);
export default SessionBenifits;
