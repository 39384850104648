import {
  Avatar,
  Card,
  CardContent,
  Divider,
  FormControl,
  Typography,
  TextField,
  Grid,
  Button,
  CardActions,
  IconButton,
  CardActionArea,
  InputLabel,
  Snackbar,
} from "@mui/material";

import { Alert } from "@mui/material";
import {
  RequestSessionBenefits,
  ResponseSessionBenefits,
  SessionBenefits,
} from "api/models/live-meditation-service/live-meditation_pb";
import React, { useState } from "react";
import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import {
  getAllBenifitsBySessionId,
  getAllLiveMeditationsBetweenDatesV2,
  //getAllSessionBenifits,
} from "redux/actions/liveMeditationAction";
import { schedularUpdateStep } from "redux/reducers/liveMeditationReducer";
import { firebaseAuth } from "config/firebase";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  GAMIFICATION_SERVICE_HOST,
  LIVE_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import makeStyles from "@mui/styles/makeStyles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import { ImageOutlined } from "@mui/icons-material";
import ImageCrop from "components/image-crop/image-crop";
import { useFileUploadService } from "services/FileUplaodService";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 150,
    width: "100%",
  },
  rootVideo: {
    height: 250,
    width: "100%",
  },
  imgCardHeader: {
    height: "20%",
    textOverflow: "elipse",
  },
  thumbNail: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
  },
  actionIcon: {
    alignSelf: "center",
    //left: "45%",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },
}));
interface Crop {
  unit: "%" | "px" | undefined;
  width: number;
  aspect: number;
}

const imageFileDef: any = {
  file: null,
  fileUrl: null || "",
};

const CreateLiveSessionBenifits = React.forwardRef<HTMLDivElement, any>(
  (
    {
      sessionId,
      page,
      onClickEdit,
      setcurrentScreen,
      rowsPerPage,
      onClickBack,
    },
    ref
  ): ReactElement => {
    const hiddenImageInput: any = React.useRef([]);
    const classes = useStyles();
    const dispatch = useDispatch();

    const cropDef: Crop = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };

    const [imageRef, setImageRef] = useState<any>();
    const [src, setSrc] = useState<any>(null);
    const [crop, setCrop] = useState<Crop>(cropDef);
    const [imageFile, setimageFile] = useState<any>(null);
    const [croppedImage, setCroppedImage] = useState<any>(null);
    const [open, setopen] = useState(true);
    const [currentIndex, setcurrentIndex] = useState(0);
    const [sucessBenefit, setsucessBenefit] = useState({
      addNewBenefit: false,
      addExistingBenefit: false,
    });

    const [benefitsArr, setbenefitsArr] = useState<any>([
      { description: "", imageurl: "" },
    ]);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const { appendFilesToUploadManager } = useFileUploadService();

    const onClickAdd = () => {
      setbenefitsArr([...benefitsArr, { description: "", imageurl: "" }]);
    };
    const onClickDelete = (i: any) => {
      let newbenefitsArr = [...benefitsArr];
      newbenefitsArr.splice(i, 1);
      setbenefitsArr(newbenefitsArr);
    };

    const _onOpenCrop = () => {
      setopen(true);
    };
    const _onCloseCrop = () => {
      setopen(false);
      setSrc(null);
    };

    const onImageSelection = async (event: any, index: any) => {
      setcurrentIndex(index);
      if (event.target.files[0]) {
        setimageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onCropDone = async () => {
      const cr: any = crop;

      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);

        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        const uploadFile = toUploadFile(
          croppedFile,
          "ProfilePicture".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              console.log(
                "img url response from session benefits",
                res.detail.response
              );
              let newbenefitsArr = [...benefitsArr];
              newbenefitsArr[currentIndex].imageurl = res.detail.response;
              setbenefitsArr(newbenefitsArr);
            }
          }
        );
        setCroppedImage(croppedFile);
      }
      setSrc(null);
    };
    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };

    const onCropChange = (crop: any) => {
      setCrop(crop);
    };
    const onCropComplete = async () => {};

    const onImageClose = (index: number) => {
      setimageFile(imageFileDef);
      setSrc(null);
      setopen(false);
      setCrop(cropDef);
      let newbenefitsArr = [...benefitsArr];
      _deleteFileFromGcp(newbenefitsArr[index].imageurl);
      newbenefitsArr[index].imageurl = "";
      setbenefitsArr(newbenefitsArr);
    };
    const onCloseImage = (index: number) => {
      setimageFile(imageFileDef);
      setopen(false);
      setSrc(null);
      setCrop(cropDef);
      let newbenefitsArr = [...benefitsArr];
      _deleteFileFromGcp(newbenefitsArr[index].imageurl);
      newbenefitsArr[index].imageurl = "";
      setbenefitsArr(newbenefitsArr);
    };

    const handleImageClick = (idx: any) => {
      hiddenImageInput.current[idx].click();
    };

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);

        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
              }
            } else {
              console.log("err", statusMessage);
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };

    const _addSessionBenifits = () => {
      let filterData: any[] = [];
      benefitsArr.forEach((item: any) => {
        if (item.description === "") {
          setSnackBar({
            open: true,
            severity: "error",
            message: "Benefit Title can not be empty",
          });
          filterData.push(item.description);
        } else return;
      });
      console.log("filtered data", filterData);
      const data = {
        sessionBenefitsList: benefitsArr,
        liveSessionID: sessionId,
      };
      if (!filterData.length) {
        addSessionBenifits(data);
      }
    };
    const addSessionBenifits = async (data: any) => {
      console.log("request data from add session benefit", data);
      try {
        var sessionBenifitsReq = new RequestSessionBenefits();
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        sessionBenifitsReq.setSessionbenefitslistList(
          data.sessionBenefitsList.map((benifits: SessionBenefits.AsObject) => {
            const _sessionBenifits = new SessionBenefits();
            _sessionBenifits.setDescription(benifits.description);
            _sessionBenifits.setImageurl(benifits.imageurl);
            return _sessionBenifits;
          })
        );
        sessionBenifitsReq.setLivesessionid(data.liveSessionID);

        grpc.unary(LiveMeditationService.AddSessionBenefits, {
          request: sessionBenifitsReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseSessionBenefits>) => {
            const { status, message } = res;

            if (status === grpc.Code.OK && message) {
              console.log("all ok: ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: "session benefits added successfully",
              });

              setsucessBenefit({
                ...sucessBenefit,
                addNewBenefit: true,
              });

              if (page === "liveSession") {
                dispatch(getAllBenifitsBySessionId(sessionId));
                setTimeout(() => onClickEdit(), 2000);
              } else {
                setTimeout(() => {
                  dispatch(schedularUpdateStep(0));
                  setcurrentScreen(0);
                  dispatch(
                    getAllLiveMeditationsBetweenDatesV2(rowsPerPage, page, true)
                  );
                }, 2000);
              }

              return message;
            } else {
              console.log("Benefits error: ", res);
              setSnackBar({
                open: true,
                severity: "error",
                message: " error in adding benefits",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: " something went wrong in add session benefits",
        });

        console.error("something went wrong in add session benefits", error);
      }
    };

    return (
      <div ref={ref}>
        <Card>
          <Grid
            container
            spacing={2}
            style={{
              padding: " 8px",
              alignItems: "center",
            }}
          >
            <Grid item>
              <IconButton onClick={() => onClickBack()} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">Add Session Benefits</Typography>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Grid
              container
              className="my-3"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  Create and add new benefit
                </Typography>
              </Grid>
            </Grid>
            <Card variant="outlined">
              <CardContent>
                {benefitsArr.map((item: any, index: any) => (
                  <Grid
                    container
                    alignItems="center"
                    key={index}
                    className="mt-2"
                  >
                    <Grid item xs={12} md={11}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={7} md={9}>
                          <TextField
                            fullWidth
                            label="Benefits"
                            name="Benefits"
                            variant="outlined"
                            multiline
                            rows={6}
                            inputProps={{
                              style: {
                                fontSize: 14,
                              },
                            }}
                            value={item.description}
                            onChange={(e) => {
                              let newbenefitsArr = [...benefitsArr];
                              newbenefitsArr[index].description =
                                e.target.value;
                              setbenefitsArr(newbenefitsArr);
                            }}
                          />
                        </Grid>
                        <Grid item xs={10} sm={5} md={3}>
                          <FormControl variant="outlined" fullWidth>
                            <Card variant="outlined" className={classes.root}>
                              {item.imageurl ? (
                                <PreviewProfileImage
                                  onImageClose={onCloseImage}
                                  src={item.imageurl}
                                  file={croppedImage}
                                  size="small"
                                  index={index}
                                />
                              ) : (
                                <CardActionArea
                                  className={classes.thumbNail}
                                  onClick={() => {
                                    handleImageClick(index);
                                  }}
                                >
                                  <InputLabel htmlFor="image">
                                    Upload Icon
                                  </InputLabel>
                                  <Grid container justifyContent="center">
                                    <Avatar
                                      className={classes.actionIcon}
                                      variant="square"
                                    >
                                      <ImageOutlined />
                                    </Avatar>
                                    <input
                                      accept="image/*"
                                      id="contained-button-file"
                                      type="file"
                                      hidden
                                      onChange={(e) =>
                                        onImageSelection(e, index)
                                      }
                                      ref={(el) =>
                                        (hiddenImageInput.current[index] = el)
                                      }
                                    />
                                  </Grid>
                                </CardActionArea>
                              )}
                            </Card>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} md={1}>
                      {index ? (
                        <IconButton
                          onClick={() => onClickDelete(index)}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                    </Grid>
                  </Grid>
                ))}
                <Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={() => onClickAdd()}
                    className="mt-2"
                  >
                    Add row
                  </Button>
                </Grid>

                {src && (
                  <ImageCrop
                    src={src.toString()}
                    crop={crop}
                    onImageLoaded={onImageLoaded}
                    onCropComplete={onCropComplete}
                    onCropChange={onCropChange}
                    onPressDone={onCropDone}
                    onImageClose={onImageClose}
                    open={open}
                    onOpen={_onOpenCrop}
                    onClose={_onCloseCrop}
                  />
                )}
              </CardContent>
              <>
                <Divider />
                <CardActions className=" py-2 d-flex justify-content-end pr-3">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => _addSessionBenifits()}
                  >
                    Create Benefits
                  </Button>
                </CardActions>
              </>
            </Card>
          </CardContent>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={10000}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              severity={snackBar.severity}
              onClose={() => {
                setSnackBar({ ...snackBar, open: false });
              }}
            >
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Card>
      </div>
    );
  }
);
export default CreateLiveSessionBenifits;
