import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { Button, ListItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface NavItemProps {
  className?: string;
  href: string;
  icon: any;
  title: string;
  category?: string;
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 1,
  },
  button: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightLight,
    // fontSize: theme.typography.fontSize.toFixed(1),
    fontSize: "small",
    justifyContent: "flex-start",
    letterSpacing: 0,
    borderRadius: "0",
    textTransform: "none",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "0",
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    transform: "scale(0.9)",
  },
  title: {
    marginRight: "auto",
  },
  active: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: "0",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  category,
  ...rest
}: NavItemProps) => {
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      sx={{ margin: "0" }}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
        disableRipple
        fullWidth={true}
        sx={{ padding: `${title ? "8px 2px 8px 25px" : "8px 2px 8px 15px"}`}}
      >
        {Icon && <Icon className={classes.icon} fontSize="small" />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
