import React, { useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactElement, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { getAllPurposeFile } from "redux/actions/guidedMeditationAction";
import { PurposeCategoryForm } from "./purposeCategoryForm";
import { ListSkeleton } from "components/listSkeleton";
import { PurposeFile } from "api/models/guided-meditation-service/guided-meditation_pb";
import { MenuProps } from "styles/global.styles";

interface State {
  editing?: boolean;
  editItem?: any;
}

export default function PurposeCategoryScreen(): ReactElement {
  const dispatch = useDispatch();
  const state = useSelector(
    (state: RootState) => state.guidedMeditation.meditationList
  );

  const initialState = {
    editing: false,
    editItem: null,
  };

  const languages = useSelector((state: RootState) => state.app.languages);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [language, setLanguage] = useState<any>(1);
  const [searchPurposeCategories, setSearchPurposeCategories] = useState("");
  const [states, setStates] = useState<State>(initialState);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getAllPurposeFile(language));
  }, [dispatch, language, setCurrentScreen]);

  const onClickBack = () => {
    setCurrentScreen(1);
    setStates({
      editing: false,
      editItem: null,
    });
  };

  const renderPurposeList = (purposes: PurposeFile.AsObject[]) => (
    <>
      {purposes.length ? (
        <>
          <TableContainer style={{ maxHeight: "400px" }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell>Avtar</TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchPurposeCategories !== "" ? searchData : purposes)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          hover
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setStates({
                              editing: true,
                              editItem: item,
                            });
                            setCurrentScreen(2);
                          }}
                        >
                          <TableCell align="center">
                            <Avatar alt="" src={item.imageurl} />
                          </TableCell>
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.purpose}</TableCell>
                          {/* <TableCell align="center">
                            <IconButton
                              onClick={() => {
                                setStates({
                                  editing: true,
                                  editItem: item,
                                });
                                setCurrentScreen(2);
                              }}
                              size="large"
                            >
                              <RateReviewOutlinedIcon color="primary" />
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={
              searchPurposeCategories !== ""
                ? searchData.length
                : purposes.length
            }
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
          />
        </>
      ) : (
        <Box height={400}>
          <Grid
            container
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" color="textSecondary">
              No live session to show
            </Typography>
          </Grid>
        </Box>
      )}
    </>
  );

  return (
    <Container>
      <Grid item>
        <Grid item xs={12}>
          <Card className="mt-3 mb-5" style={{ position: "relative" }}>
            <Grow
              in={currentScreen === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentScreen === 1 ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid container className="align-items-center p-3">
                  <Typography variant="h6">Purposes</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setCurrentScreen(2)}
                    startIcon={<AddIcon />}
                    className="ml-3"
                  >
                    Add New Purpose
                  </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <FormControl variant="outlined" className="m-3" fullWidth>
                    <InputLabel id="language-select">Language</InputLabel>
                    {languages && (
                      <Select
                        defaultValue=""
                        labelId="language-select"
                        value={language}
                        label="Language"
                        onChange={(e) => {
                          setLanguage(e.target.value as number);
                        }}
                        MenuProps={MenuProps}
                      >
                        {languages.map((lang: any, i: any) => (
                          <MenuItem key={i} value={lang.code}>
                            {lang.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    <Grid
                      item
                      xs={12}
                      style={{ backgroundColor: "transparent" }}
                      className="py-2"
                    >
                      <Grid className="d-flex align-items-center">
                        <TextField
                          type="search"
                          fullWidth
                          value={searchPurposeCategories}
                          variant="outlined"
                          placeholder="Search purpose by name"
                          size="small"
                          onChange={(e) => {
                            setSearchPurposeCategories(e.target.value);
                            const filteredData =
                              state.purposeFileList.purposeFile.filter(
                                (item: any) =>
                                  item.purpose
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase())
                              );
                            setSearchData(filteredData);
                          }}
                          className="ml-3"
                        />

                        <Grid>
                          <IconButton
                            onClick={() => {
                              dispatch(getAllPurposeFile(language));
                            }}
                            size="large"
                          >
                            <Tooltip title="Refresh">
                              <RefreshIcon />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    {state.purposeFileList.loading
                      ? ListSkeleton()
                      : renderPurposeList(state.purposeFileList.purposeFile)}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
            <Grow
              in={currentScreen === 2}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentScreen === 2 ? { timeout: 1000 } : {})}
            >
              <PurposeCategoryForm
                onClickBack={onClickBack}
                isEditing={states.editing}
                editItem={states.editItem}
                lang={language}
              />
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
