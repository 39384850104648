import {
  Card,
  CardContent,
  Divider,
  FormControl,
  Typography,
  TextField,
  Grid,
  Button,
  CardActions,
  IconButton,
  Snackbar,
  CircularProgress,
  Avatar,
  InputLabel,
  MenuItem,
  Select,
  // FormControlLabel,
  // InputLabel,
  // Select,
  // MenuItem,
} from "@mui/material";
import DateTimePicker from "@mui/lab/DateTimePicker";

import { Alert, Autocomplete } from "@mui/material";
import {
  // AddLiveMeditationRequest,
  //AddLiveMeditationRequest,
  CreateLiveMeditationRequest,
  RequestAddBenefitAndSessionIDs,
  SessionInfo,
} from "api/models/live-meditation-service/live-meditation_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";
import momentz from "moment-timezone";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  //getAllBenifitsBySessionId,
  getAllLiveMeditationsBetweenDates,
  getAllSessionBenifitsByCoachId,
  getCoachListAsync,
} from "redux/actions/liveMeditationAction";
import { schedularUpdateStep } from "redux/reducers/liveMeditationReducer";
import { firebaseAuth } from "config/firebase";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { LIVE_MEDITATION_SERVICE_HOST } from "api/serviceEndpoints";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import { RootState } from "redux/store/store";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";
import { ListSkeleton } from "components/listSkeleton";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
//import SelectTimezoneMaterialUi from "select-timezone-material-ui";

interface FormProps {
  setLiveSessionId?: any;
  onClickBack: any;
  coachId?: number | undefined;
  setendDateTime?: any;
  duplicateSession?: any;
  setduplicateSession?: any;
  onClickBackScheduleSession?: any;
  type?: string;
  isEditing?: boolean;
  editItem?: any;
  setstartDate?: any;
  setendDate?: any;
  endDateTime?: any;
}

const ScheduleSession = React.forwardRef<HTMLDivElement, FormProps>(
  (
    {
      coachId,
      onClickBack,
      setLiveSessionId,
      setendDateTime,
      setendDate,
      setstartDate,
      // duplicateSession,
      // setduplicateSession,
      isEditing,
      editItem,
      type,
    },
    ref
  ): ReactElement => {
    const rootState = useSelector((state: RootState) => state.liveMeditation);
    const languages = useSelector((state: RootState) => state.app.languages);

    // console.log('duplicate session benefit',rootState.benifitsListById.benifits.listofbenefitsList)
    //console.log('coach id',coachId);
    //const CHARACTER_LIMIT = 1000;
    const dispatch = useDispatch();
    const [sessionTitle, setsessionTitle] = useState("");
    const [sessionSubTitle, setsessionSubTitle] = useState("");
   
    const [sessionInfo, setsessionInfo] = useState("");
    const [aboutSession, setaboutSession] = useState("");
    const [language, setLanguage] = useState<any>(2);
    // const [isLangSelected, setIsLangSelected] = useState<boolean>(false);
    const [newcoachId, setnewcoachId] = useState(coachId);
    const [showAlert, setshowAlert] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
    const [duration, setduration] = useState<number>(30);
    const [scheduleDate, setscheduleDate] = useState<any>(new Date());
    const [scheduleDateTime, setscheduleDateTime] = useState<any>(new Date());
    const [scheduleTimeZone, setscheduleTimeZone] = useState<any>(new Date());
    const [timeZone, settimeZone] = useState<any>(moment.tz.guess());
    const [inputTimeZoneValue, setinputTimeZoneValue] = useState("");
    const [stepsForMeditation, setstepsForMeditation] = useState<any>([
      { stepValue: "" },
      { stepValue: "" },
      { stepValue: "" },
      { stepValue: "" },
      { stepValue: "" },
      { stepValue: "" },
    ]);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    useEffect(() => {
      if (isEditing) {
        onClickDuplicate(editItem);

        //console.log(rootState.benifitsListById.benifits.listofbenefitsList);
      }
    }, [isEditing, editItem]);
    const onClickDuplicate = (item: any) => {
      setsessionTitle(item.sessioninfo?.sessiontitle);
      setsessionSubTitle(item.sessioninfo?.sessionsubtitle);
      setsessionInfo(item.sessioninfo?.sessioninfo);
      setaboutSession(item.sessioninfo?.aboutsession);
      setduration(item.sessioninfo?.duration);
      setscheduleDate(new Date());
      setscheduleDateTime(new Date());
      setnewcoachId(item.coachprofile?.id);
      const arr = item.sessioninfo?.meditationsteps
        .split("\n")
        .map((step: any) => ({ stepValue: step }));
      setstepsForMeditation(arr);
      setLanguage(item.sessioninfo?.selectedlanguage);
      setIsDuplicate(true);
    };

    const onClickAdd = () => {
      setstepsForMeditation([...stepsForMeditation, { stepValue: "" }]);
    };
    const onClickDelete = (i: any) => {
      let newstepsForMeditation = [...stepsForMeditation];
      newstepsForMeditation.splice(i, 1);
      setstepsForMeditation(newstepsForMeditation);
    };

    const validateForm = () => {
      if (sessionTitle.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "session title can not be empty",
        });
      } else if (aboutSession.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "about session can not be empty",
        });
      } else if (stepsForMeditation[0].stepValue.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Atleast one meditation step is required",
        });
      } else if (new Date(scheduleDateTime).getTime() <= Date.now()) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Live session can't be scheduled for past time",
        });
      }
       else {
        return true;
      }
    };
    const emptyData = () => {
      setscheduleDate(new Date());
      setsessionTitle("");
      setsessionSubTitle("");
      setsessionInfo("");
      setaboutSession("");
      setduration(30);
      //setmeditationSteps("");
    };
    const _createSession = () => {
      const medSteps = stepsForMeditation
        .filter((item: any) => item.stepValue !== "")
        .map((item: any, index: any) => `${index + 1}.${item.stepValue}`)
        .join("\n");
      const medStepsUpdate = stepsForMeditation
        .filter((item: any) => item.stepValue !== "")
        .map((item: any, index: any) => `${item.stepValue}`)
        .join("\n");

      if (validateForm()) {
        //console.log('schedule date',new Date(scheduleDateTime))
        const data = {
          scheduledTime: moment(scheduleDateTime)
            .tz(moment.tz.guess())
            .format(),
          //scheduledTime:scheduleDateTime,
          sessionTitle: sessionTitle,
          sessionSubTitle: sessionSubTitle,
          sessionInfo: sessionInfo,
          duration: duration,
          registeredUserCount: 0,
          liveParticipantsCount: 0,
          aboutSession: aboutSession,
          meditationSteps:
            isEditing || type === "copy" ? medStepsUpdate : medSteps,
          //associatedCoachId: type === "copy" ? newcoachId : coachId,
          associatedCoachId: newcoachId,
          imageURL: "",
          videoURL: "",
          languageType: language,
          
        };

        scheduleSession(data);
      }
    };

    const scheduleSession = async (data: any) => {
      try {
        setIsLoading(true);
        console.log("create session data", data);
        var addmeditaionReq = new CreateLiveMeditationRequest();
        //console.log(data.scheduledTime)
        const currentGoogleTimeStamp: Timestamp = new Timestamp();
        currentGoogleTimeStamp.fromDate(new Date(data.scheduledTime));
        //console.log('epoch time',currentGoogleTimeStamp.fromDate(new Date(data.scheduledTime)))
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();

        addmeditaionReq.setScheduledtime(currentGoogleTimeStamp);
        addmeditaionReq.setSessiontitle(data.sessionTitle);
        addmeditaionReq.setSessionsubtitle(data.sessionSubTitle);
        addmeditaionReq.setSessioninfo(data.sessionInfo);
        addmeditaionReq.setDuration(Number(data.duration));
        addmeditaionReq.setAboutsession(data.aboutSession);
        addmeditaionReq.setAssociatedcoachid(data.associatedCoachId);
        addmeditaionReq.setLiveparticipantscount(data.liveParticipantsCount);
        addmeditaionReq.setRegisteredusercount(data.registeredUserCount);
        addmeditaionReq.setMeditationsteps(data.meditationSteps);
        addmeditaionReq.setImageurl(data.imageURL);
        addmeditaionReq.setVideourl(data.videoURL);
        addmeditaionReq.setLanguagetype(data.languageType);
        addmeditaionReq.setLink(data.link)

        grpc.unary(LiveMeditationService.createSession, {
          request: addmeditaionReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<SessionInfo>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            console.log(grpc.Code);
            if (status === grpc.Code.OK && message) {
              console.log("all ok: ", message.toObject());
              const id = message.toObject().id;
              setLiveSessionId(id);
              //setendDateTime(data.scheduledTime);
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "success",
                message: " Session created successfully",
              });
              const end = moment(moment(new Date()).add(7, "day"))
                .endOf("day")
                .format("YYYY-MM-DD");
              const start = moment(new Date())
                .startOf("day")
                .format("YYYY-MM-DD");
              console.log(start, end);
              dispatch(getAllLiveMeditationsBetweenDates(start, end));
              setstartDate(start);
              setendDate(end);

              if (isEditing || type === "copy") {
                if (
                  rootState.benifitsListById.benifits.listofbenefitsList.length
                ) {
                  //addbenefit();
                  setshowAlert(true);
                  setIsLoading(true);
                  setTimeout(
                    () => _addExistingBenifits(id, data.scheduledTime),
                    1000
                  );
                } else {
                  setTimeout(() => {
                    //dispatch(schedularUpdateStep(0));
                    onClickBack();
                  }, 2000);
                }
                setendDateTime(data.scheduledTime);
              } else {
                emptyData();
                setendDateTime(data.scheduledTime);
                setTimeout(() => dispatch(schedularUpdateStep(2)), 2000);
                dispatch(getCoachListAsync());
                dispatch(
                  getAllSessionBenifitsByCoachId(3, 0, data.associatedCoachId)
                );
              }
              return message;
            } else {
              setIsLoading(false);
              console.log("error in scheduling test", statusMessage);
              let str = statusMessage.toString();
              setSnackBar({
                open: true,
                severity: "error",
                message: str.substring(str.indexOf(":") + 1),
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in schedule session",
        });
        console.error("something went wrong in schedule session", error);
      }
    };

    const _addExistingBenifits = (id: number, endDateTime: any) => {
      setIsLoading(true);
      console.log("checkedList", rootState.benifitsIdList.IdList);
      const data = {
        liveSessionID: id,
        benefitsID: rootState.benifitsIdList.IdList,
      };
      _addBenefitsToSession(data, endDateTime);
    };

    const _addBenefitsToSession = async (data: any, endDateTime: any) => {
      console.log("copy benifits data", data);
      setIsLoading(true);
      try {
        var sessionBenifitsReq = new RequestAddBenefitAndSessionIDs();
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();

        sessionBenifitsReq.setBenefitsidList(data.benefitsID);
        sessionBenifitsReq.setLivesessionid(data.liveSessionID);

        grpc.unary(LiveMeditationService.addBenefitsToSession, {
          request: sessionBenifitsReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ProtobufMessage>) => {
            const { status, message } = res;

            if (status === grpc.Code.OK && message) {
              console.log("all ok: ", message.toObject());
              // if (isEditing || type === "copy") {
              setshowAlert(false);
              setIsLoading(false);
              const end = moment(moment(new Date()).add(7, "day"))
                .endOf("day")
                .format("YYYY-MM-DD");
              const start = moment(new Date())
                .startOf("day")
                .format("YYYY-MM-DD");
              setSnackBar({
                open: true,
                severity: "success",
                message: " Benefits added successfully",
              });
              console.log(start, end);
              setTimeout(() => {
                dispatch(getAllLiveMeditationsBetweenDates(start, end));
                setstartDate(start);
                setendDate(end);
                //dispatch(schedularUpdateStep(0));
                onClickBack();
              }, 2000);
              // }
              //onClickBack();

              return message;
            } else {
              setshowAlert(false);
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: " error in adding benefits to session",
              });
            }
          },
        });
      } catch (error) {
        setshowAlert(false);
        setIsLoading(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: " error in adding benefits",
        });
        console.error(
          "something went wrong in add benefits to session ",
          error
        );
      }
    };

    function nth(n: number) {
      return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
    }
    return (
      <div ref={ref}>
        {/* <Card variant="outlined" className="mt-3 mb-5"> */}
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        {showAlert && (
          <Alert
            icon={<CircularProgress size={20} />}
            severity="info"
            style={{ position: "absolute", top: "50%", right: "50%" }}
          >
            Adding benefits...
          </Alert>
        )}
        <Grid
          container
          spacing={1}
          style={{
            padding: " 8px",
            alignItems: "center",
          }}
        >
          <Grid item>
            <IconButton onClick={() => onClickBack()} size="large">
              <KeyboardBackspaceIcon color="action" />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h6"> Create Live Session </Typography>
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3} className="mt-2">
            <Grid item xs={12} sm={3} md={4} lg={4}>
              <FormControl variant="outlined" fullWidth>
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    inputFormat="dd/MM/yyyy hh:mm a"
                    label="Schedule Date And Time"
                    renderInput={(params) => <TextField {...params} />}
                    //variant="inline"
                    // autoOk
                    minDateTime={new Date(Date.now())}
                    disablePast={true}
                    value={scheduleDate}
                    // error={false}
                    // helperText={null}
                    onChange={(value: any) => {
                      console.log("date value from schedule session", value);
                      const time = moment(value)
                        .tz(`${timeZone}`)
                        .format("DD/MM/yyyy hh:mm a ");
                      // console.log(`on date change time for ${timeZone}`, value);
                      //const time=moment.tz(value, 'YYYY-MM-DD HH:mm:ss', `${timeZone}`).format('DD/MM/yyyy hh:mm a ');
                      console.log(`on date change time for ${timeZone}`, time);
                      const newTime = moment(value).tz(`${timeZone}`).format();
                      // const newTime=moment.tz(value, 'YYYY-MM-DD HH:mm:ss', `${timeZone}`).format();
                      console.log(
                        `on date change new time for ${timeZone}`,
                        newTime
                      );
                      setscheduleDateTime(newTime);
                      setscheduleDate(value);
                      setscheduleTimeZone(value);
                    }}
                    // inputVariant="outlined"
                    // TextFieldComponent={(props) => (
                    //   <TextField variant="outlined" {...props} />
                    // )}
                  />
                </LocalizationProvider>
                {/* </MuiPickersUtilsProvider> */}
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <Grid>
                <Autocomplete
                  disablePortal
                  disableClearable
                  fullWidth
                  value={timeZone}
                  onChange={(event, newValue) => {
                    // console.log('current time zone', moment.tz('Asia/Bahrain').format('DD/MM/yyyy hh:mm a' ))
                    settimeZone(newValue);
                    // const time = moment.tz(scheduleTimeZone, 'YYYY-MM-DD HH:mm:ss', `${newValue}`).format('DD/MM/yyyy hh:mm a')
                    const time = moment(scheduleTimeZone)
                      .tz(`${newValue}`)
                      .format("DD/MM/yyyy hh:mm a");
                    setscheduleDate(time);
                    // console.log(`on select change time for ${newValue}`, time);
                    // const newTime=moment.tz(scheduleTimeZone, 'YYYY-MM-DD HH:mm:ss', `${newValue}`).format()
                    const newTime = moment(scheduleTimeZone)
                      .tz(`${newValue}`)
                      .format();
                    // console.log(`on select change new time for ${newValue}`,newTime);
                    setscheduleDateTime(newTime);
                  }}
                  inputValue={inputTimeZoneValue}
                  onInputChange={(event, newInputValue) => {
                    setinputTimeZoneValue(newInputValue);
                  }}
                  id="combo-box-demo"
                  options={momentz.tz.names()}
                  // style={{ maxWidth: 350 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Timezone"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="language-select">Language</InputLabel>
                {languages && (
                  <Select
                    disabled={isDuplicate}
                    defaultValue=""
                    labelId="language-select"
                    value={language}
                    label="Language"
                    onChange={(e) => {
                      setLanguage(e.target.value as number);
                      // setIsLangSelected(true);
                    }}
                  >
                    {languages.map((lang, i) => (
                      <MenuItem key={i} value={lang.code}>
                        {lang.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>

            <Grid item lg={10} xs={8}>
              <TextField
                fullWidth
                label="Session Title"
                name="session title"
                required
                variant="outlined"
                value={sessionTitle}
                onChange={(e) => setsessionTitle(e.target.value)}
                inputProps={{
                  readOnly: isEditing ? true : false,
                }}
              />
            </Grid>
            <Grid item lg={2} xs={4}>
              <TextField
                fullWidth
                required
                defaultValue={30}
                type="number"
                label="Duration"
                value={duration}
                error={duration < 30 || duration > 60}
                helperText={
                  duration < 30 || duration > 60
                    ? "Enter a value between 30 and 60 minutes"
                    : ""
                }
                onChange={(e) => {
                  setduration(e.target.value as unknown as number);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="About session"
                name="About session"
                required
                variant="outlined"
                multiline
                minRows={2}
                inputProps={{
                  // readOnly: isEditing ? true : false,
                  //maxLength: CHARACTER_LIMIT,
                  style: {
                    fontSize: 14,
                  },
                }}
                value={aboutSession}
                onChange={(e) => setaboutSession(e.target.value)}
              />
            </Grid>
           


            <Grid item xs={12} md={12} sm={12}>
              <Card variant="outlined">
                <Typography color="textSecondary" className="pl-3 pt-3">
                  Meditation Steps
                </Typography>
                <CardContent>
                  <Grid container spacing={3}>
                    {(stepsForMeditation.length === 0
                      ? [{ stepValue: "" }]
                      : stepsForMeditation
                    ).map((item: any, index: any) => (
                      <Grid
                        item
                        xs={12}
                        key={index}
                        className="d-flex align-items-start mt-2"
                      >
                        <TextField
                          //fullWidth
                          //label={`step ${index + 1}`}
                          label={`${index + 1}${nth(
                            index + 1
                          )} meditation step`}
                          name="Meditation steps"
                          variant="outlined"
                          multiline
                          required={!index}
                          rows={1}
                          maxRows={20}
                          inputProps={{
                            readOnly: isEditing ? true : false,
                            style: {
                              fontSize: 14,
                            },
                          }}
                          value={item.stepValue}
                          onChange={(e) => {
                            let newstepsForMeditation = [...stepsForMeditation];
                            newstepsForMeditation[index].stepValue =
                              e.target.value;
                            setstepsForMeditation(newstepsForMeditation);
                          }}
                          style={{ flexGrow: 1, marginRight: "10px" }}
                        />

                        <Grid>
                          {index ? (
                            <IconButton
                              onClick={() => onClickDelete(index)}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              style={{ visibility: "hidden" }}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  {type === "copy" ? null : (
                    <Grid className="mt-3">
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ textTransform: "none" }}
                        onClick={() => onClickAdd()}
                      >
                        Add row
                      </Button>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
            {isEditing ? (
              <Grid item xs={12}>
                <Card variant="outlined">
                  {rootState.benifitsListById.loading ||
                  rootState.benifitsIdList.loading ? (
                    ListSkeleton()
                  ) : (
                    <CardContent>
                      {rootState.benifitsListById.benifits.listofbenefitsList
                        .length ? (
                        <>
                          <Typography color="textSecondary" className="pb-3">
                            Benefits
                          </Typography>
                          {rootState.benifitsListById.benifits.listofbenefitsList.map(
                            (item, index) => (
                              <React.Fragment key={index}>
                                <Grid
                                  container
                                  className="p-2"
                                  alignItems="center"
                                >
                                  <Grid
                                    item
                                    xs={2}
                                    sm={1}
                                    md={1}
                                    style={{ maxWidth: "60px" }}
                                  >
                                    <Avatar
                                      //style={{ backgroundColor: "#3f51b5" }}
                                      src={item.imageurl}
                                    />
                                  </Grid>
                                  <Grid item xs={10} sm={11} md={11}>
                                    <Typography variant="body1">
                                      {item.description}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Divider style={{ margin: "0 10px " }} />
                              </React.Fragment>
                            )
                          )}
                        </>
                      ) : (
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography color="textSecondary" className="py-2">
                            No benefits added for this session
                          </Typography>
                        </Grid>
                      )}
                    </CardContent>
                  )}
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className=" py-3 d-flex justify-content-end">
          <Button
            // disabled={!isDuplicate && !isLangSelected}
            disabled={duration < 30 || duration > 60}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => _createSession()}
          >
            Create Session
          </Button>
        </CardActions>
        {/* <Snackbar
          open={!isDuplicate && !isLangSelected}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert severity="info">Language is mandatory</Alert>
        </Snackbar> */}
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
);
export default ScheduleSession;
