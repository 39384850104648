// source: Badge.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.AddNewBadgeRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.Badge', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.BadgeDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.BadgeDetailsV2', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.GetAllBadgesResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.Badge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.Badge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.Badge.displayName = 'proto.unifiedplatform.gamification.badge.Badge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.AddNewBadgeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.displayName = 'proto.unifiedplatform.gamification.badge.AddNewBadgeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.GetAllBadgesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.displayName = 'proto.unifiedplatform.gamification.badge.GetAllBadgesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.displayName = 'proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.displayName = 'proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.displayName = 'proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.displayName = 'proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.BadgeDetailsV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.BadgeDetailsV2.displayName = 'proto.unifiedplatform.gamification.badge.BadgeDetailsV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.displayName = 'proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.BadgeDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.BadgeDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.BadgeDetails.displayName = 'proto.unifiedplatform.gamification.badge.BadgeDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.displayName = 'proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.displayName = 'proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.displayName = 'proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.Badge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.Badge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.Badge.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badgedescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    badgecolor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    badgecount: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.Badge}
 */
proto.unifiedplatform.gamification.badge.Badge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.Badge;
  return proto.unifiedplatform.gamification.badge.Badge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.Badge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.Badge}
 */
proto.unifiedplatform.gamification.badge.Badge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgedescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgecolor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.Badge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.Badge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.Badge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadgedescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBadgecolor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.badge.Badge} returns this
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.Badge} returns this
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.Badge} returns this
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string badgeDescription = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.getBadgedescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.Badge} returns this
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.setBadgedescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string badgeColor = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.getBadgecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.Badge} returns this
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.setBadgecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 badgeCount = 6;
 * @return {number}
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.badge.Badge} returns this
 */
proto.unifiedplatform.gamification.badge.Badge.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.AddNewBadgeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 2, ""),
    badgedescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badgecolor: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeRequest}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.AddNewBadgeRequest;
  return proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.AddNewBadgeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeRequest}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgedescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgecolor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.AddNewBadgeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBadgedescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadgecolor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeRequest} returns this
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string icon = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeRequest} returns this
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string badgeDescription = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.prototype.getBadgedescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeRequest} returns this
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.prototype.setBadgedescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string badgeColor = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.prototype.getBadgecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeRequest} returns this
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeRequest.prototype.setBadgecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.GetAllBadgesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeList: jspb.Message.toObjectList(msg.getBadgeList(),
    proto.unifiedplatform.gamification.badge.Badge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgesResponse}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.GetAllBadgesResponse;
  return proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.GetAllBadgesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgesResponse}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.badge.Badge;
      reader.readMessage(value,proto.unifiedplatform.gamification.badge.Badge.deserializeBinaryFromReader);
      msg.addBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.GetAllBadgesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.badge.Badge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Badge badge = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.badge.Badge>}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.prototype.getBadgeList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.badge.Badge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.badge.Badge, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.badge.Badge>} value
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgesResponse} returns this
*/
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.prototype.setBadgeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.badge.Badge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.badge.Badge}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.prototype.addBadge = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.badge.Badge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgesResponse} returns this
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesResponse.prototype.clearBadgeList = function() {
  return this.setBadgeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest}
 */
proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest;
  return proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest}
 */
proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest} returns this
 */
proto.unifiedplatform.gamification.badge.GetBadgeByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest;
  return proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest} returns this
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    badge: (f = msg.getBadge()) && proto.unifiedplatform.gamification.badge.Badge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse;
  return proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.badge.Badge;
      reader.readMessage(value,proto.unifiedplatform.gamification.badge.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.badge.Badge.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse} returns this
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Badge badge = 2;
 * @return {?proto.unifiedplatform.gamification.badge.Badge}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.prototype.getBadge = function() {
  return /** @type{?proto.unifiedplatform.gamification.badge.Badge} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.badge.Badge, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.badge.Badge|undefined} value
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse} returns this
*/
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse} returns this
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeByIdResponse.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    badge: (f = msg.getBadge()) && proto.unifiedplatform.gamification.badge.Badge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest}
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest;
  return proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest}
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.badge.Badge;
      reader.readMessage(value,proto.unifiedplatform.gamification.badge.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.badge.Badge.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest} returns this
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Badge badge = 2;
 * @return {?proto.unifiedplatform.gamification.badge.Badge}
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.prototype.getBadge = function() {
  return /** @type{?proto.unifiedplatform.gamification.badge.Badge} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.badge.Badge, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.badge.Badge|undefined} value
 * @return {!proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest} returns this
*/
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest} returns this
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.badge.UpdateBadgeByIdRequest.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.BadgeDetailsV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badgedescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    badgecolor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    badgecount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    earnedcount: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.BadgeDetailsV2;
  return proto.unifiedplatform.gamification.badge.BadgeDetailsV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgedescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgecolor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEarnedcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.BadgeDetailsV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadgedescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBadgecolor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEarnedcount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string badgeDescription = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.getBadgedescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.setBadgedescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string badgeColor = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.getBadgecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.setBadgecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 badgeCount = 6;
 * @return {number}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 earnedCount = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.getEarnedcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetailsV2.prototype.setEarnedcount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && common_common_pb.Locale.toObject(includeInstance, f),
    icon: jspb.Message.getFieldWithDefault(msg, 2, ""),
    badgedescription: (f = msg.getBadgedescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    badgecolor: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest;
  return proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setBadgedescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgecolor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBadgedescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getBadgecolor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale name = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.getName = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest} returns this
*/
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest} returns this
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string icon = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest} returns this
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale badgeDescription = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.getBadgedescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest} returns this
*/
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.setBadgedescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest} returns this
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.clearBadgedescription = function() {
  return this.setBadgedescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.hasBadgedescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string badgeColor = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.getBadgecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest} returns this
 */
proto.unifiedplatform.gamification.badge.AddNewBadgeInfoRequest.prototype.setBadgecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.BadgeDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && common_common_pb.Locale.toObject(includeInstance, f),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badgedescription: (f = msg.getBadgedescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    badgecolor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    badgecount: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.BadgeDetails;
  return proto.unifiedplatform.gamification.badge.BadgeDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setBadgedescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgecolor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.BadgeDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadgedescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getBadgecolor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale name = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.getName = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails} returns this
*/
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale badgeDescription = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.getBadgedescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails} returns this
*/
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.setBadgedescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.clearBadgedescription = function() {
  return this.setBadgedescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.hasBadgedescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string badgeColor = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.getBadgecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.setBadgecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 badgeCount = 6;
 * @return {number}
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails} returns this
 */
proto.unifiedplatform.gamification.badge.BadgeDetails.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    badge: (f = msg.getBadge()) && proto.unifiedplatform.gamification.badge.BadgeDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse;
  return proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.badge.BadgeDetails;
      reader.readMessage(value,proto.unifiedplatform.gamification.badge.BadgeDetails.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.badge.BadgeDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse} returns this
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BadgeDetails badge = 2;
 * @return {?proto.unifiedplatform.gamification.badge.BadgeDetails}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.prototype.getBadge = function() {
  return /** @type{?proto.unifiedplatform.gamification.badge.BadgeDetails} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.badge.BadgeDetails, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.badge.BadgeDetails|undefined} value
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse} returns this
*/
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse} returns this
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.badge.DeleteBadgeDetailsByIdResponse.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeList: jspb.Message.toObjectList(msg.getBadgeList(),
    proto.unifiedplatform.gamification.badge.Badge.toObject, includeInstance),
    badgedetailsv2List: jspb.Message.toObjectList(msg.getBadgedetailsv2List(),
    proto.unifiedplatform.gamification.badge.BadgeDetailsV2.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse;
  return proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.badge.Badge;
      reader.readMessage(value,proto.unifiedplatform.gamification.badge.Badge.deserializeBinaryFromReader);
      msg.addBadge(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.badge.BadgeDetailsV2;
      reader.readMessage(value,proto.unifiedplatform.gamification.badge.BadgeDetailsV2.deserializeBinaryFromReader);
      msg.addBadgedetailsv2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.badge.Badge.serializeBinaryToWriter
    );
  }
  f = message.getBadgedetailsv2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.badge.BadgeDetailsV2.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Badge badge = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.badge.Badge>}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.prototype.getBadgeList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.badge.Badge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.badge.Badge, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.badge.Badge>} value
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse} returns this
*/
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.prototype.setBadgeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.badge.Badge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.badge.Badge}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.prototype.addBadge = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.badge.Badge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.prototype.clearBadgeList = function() {
  return this.setBadgeList([]);
};


/**
 * repeated BadgeDetailsV2 badgeDetailsV2 = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetailsV2>}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.prototype.getBadgedetailsv2List = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.badge.BadgeDetailsV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.badge.BadgeDetailsV2, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetailsV2>} value
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse} returns this
*/
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.prototype.setBadgedetailsv2List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.prototype.addBadgedetailsv2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.badge.BadgeDetailsV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.badge.GetAllBadgesDetailsResponse.prototype.clearBadgedetailsv2List = function() {
  return this.setBadgedetailsv2List([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeList: jspb.Message.toObjectList(msg.getBadgeList(),
    proto.unifiedplatform.gamification.badge.BadgeDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse;
  return proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.badge.BadgeDetails;
      reader.readMessage(value,proto.unifiedplatform.gamification.badge.BadgeDetails.deserializeBinaryFromReader);
      msg.addBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.badge.BadgeDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BadgeDetails badge = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.prototype.getBadgeList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.badge.BadgeDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>} value
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse} returns this
*/
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.prototype.setBadgeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails}
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.prototype.addBadge = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.badge.BadgeDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse} returns this
 */
proto.unifiedplatform.gamification.badge.GetAllBadgeDetailsForAdminResponse.prototype.clearBadgeList = function() {
  return this.setBadgeList([]);
};


goog.object.extend(exports, proto.unifiedplatform.gamification.badge);
