import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { Typography, Grid, Box, Card } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { ListSkeleton } from "components/listSkeleton";
import style from "./index.module.scss";
import { firebaseAuth } from "config/firebase";
import { grpc } from "@improbable-eng/grpc-web";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import { LIVE_MEDITATION_SERVICE_HOST } from "api/serviceEndpoints";
import {
  ReportTypeWithIdRequest,
  ResponseForLiveSessionFeedBacks,
} from "api/models/live-meditation-service/live-meditation_pb";
import InfoCard from "./infoCard";
interface Props {
  setcurrentIndex: any;
  sessionTitle?: string;
  sessionId: number;
  coachIdForViewQuestions: number;
}

const ViewIssue = React.forwardRef<HTMLDivElement, Props>(
  (
    { setcurrentIndex, sessionId, sessionTitle, coachIdForViewQuestions },
    ref
  ): ReactElement => {
    const fb = useSelector(
      (state: RootState) =>
        state.liveMeditation.feedbackList[sessionId.toString()]
    );
    const state = { ...fb };
    const [LiveIssueList, setLiveIssueList] = useState<any>([]);

    useEffect(() => {
      getDynamicLiveIssue(sessionId);
      return function () {
        rpc.close();
      };
    }, []);
    let rpc: any;
    const getDynamicLiveIssue = async (sessionID: number) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ReportTypeWithIdRequest = new ReportTypeWithIdRequest();
      reqBody.setId(sessionID);
      reqBody.setUserreporttype(1);
      rpc = grpc.invoke(LiveMeditationService.GetDynamicFeedBacksV2, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onMessage: (res: ResponseForLiveSessionFeedBacks) => {
          setLiveIssueList(res.toObject().userlivesessionfeedbackList);
          console.log("message recieved", res.toObject());
        },
        onEnd: (res: any) => {
          console.log("stream end");
        },
      });
    };
    const renderIssueList = () => {
      return (
        <Box
          height="50vh"
          style={{ overflow: "auto" }}
          className={style.scrollbar}
        >
          {LiveIssueList.length > 0 ? (
            <Box>
              {LiveIssueList.map((item: any, index: any) => (
                <InfoCard item={item} index={index} />
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                fontWeight: 400,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="text.secondary">
                There is no issue at this time
              </Typography>
            </Box>
          )}
        </Box>
      );
    };
    return (
      <div ref={ref}>
        {
          <Card sx={{ minWidth: "100%" }}>
            <Grid
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                marginBottom: "2px",
                boxShadow: 2,
              }}
              className="d-flex p-3 px-4 align-items-start"
            >
              <Typography variant="h6">
                Issues ({LiveIssueList.length})
              </Typography>
            </Grid>
            {state.loading ? ListSkeleton() : renderIssueList()}
          </Card>
        }
      </div>
    );
  }
);
export default ViewIssue;
