import React, { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import { goals } from "./dummydata";
const StyledPaperModal = styled(Paper)(({ theme }) => ({
  position: "absolute",
  minWidth: "50vw",
  backgroundColor: theme.palette.background.paper,
  padding: 18,
  top: `25vh`,
  left: `25vw`,
  right: `25vw`,
}));
const GoalEditor = React.forwardRef<HTMLDivElement, any>(
  (props, ref): ReactElement => {
    const [goalsData, setGoalsData] = useState(goals);
    function handleDragEnd(result: any) {
      if (!result.destination) return;
      const items = Array.from(goalsData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setGoalsData(items);
    }
    useEffect(() => {
      console.log("goalsdata", goalsData);
    }, [goalsData]);
    return (
      <div ref={ref}>
        <StyledPaperModal>
          <Box
            sx={{
              width: "max-content",
              p: 1,
              mb: 2,
              fontSize: "1.1rem",
            }}
          >
            Goal Ordering
          </Box>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="carousels">
              {(provided) => (
                <Stack
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  spacing={4}
                >
                  {goalsData.map((item, idx) => (
                    <Draggable key={item.id} draggableId={item.id} index={idx}>
                      {(provided) => (
                        <Paper
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          sx={{
                            padding: 1,
                            background: "#ECE4F6",
                            pt: 1.5,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span {...provided.dragHandleProps}>
                              <DragHandleRoundedIcon
                                fontSize="small"
                                color="primary"
                              />
                            </span>

                            <Typography
                              variant="body2"
                              sx={{ pl: 2, pr: 2 }}
                              color="primary"
                            >
                              {item.title}
                            </Typography>
                            <Switch
                              checked={item.visibility}
                              onChange={(e) => {
                                setGoalsData(
                                  goalsData.map((i) =>
                                    i.id === item.id
                                      ? { ...i, visibility: e.target.checked }
                                      : i
                                  )
                                );
                              }}
                            ></Switch>
                          </Box>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
          <Button variant="contained" sx={{ mt: 2 }}>
            Publish
          </Button>
        </StyledPaperModal>
      </div>
    );
  }
);
export default GoalEditor;
