import { styled } from "@mui/styles";


const PREFIX = "CreateEvent";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
};
export const Root = styled("div")(({ theme }) => ({
    [`& .${classes.root}`]: {
      height: 250,
      maxWidth: 350,
      // overflow: "scroll",
    },
    [`& .${classes.paper}`]: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      top: `10%`,
      left: `40%`,
    },
  }));