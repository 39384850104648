import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";

import { firebaseAuth } from "config/firebase";
import DialogBox from "components/dialogBox/dialog";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { RootState } from "redux/store/store";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import {
  GAMIFICATION_SERVICE_HOST,
  HFN_EVENTS_SERVICE_HOST,
} from "api/serviceEndpoints";
import { grpc } from "@improbable-eng/grpc-web";
import { Root, classes } from "./DaajiMessage.styles";
import UploadFileContent from "components/upload-file-content/UploadFileContent";
import {
  getAllBhandaraTags,
  getAllDaajiMessage,
} from "redux/actions/bhandaraActions";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import {
  Content,
  ID,
  Status,
  Tag,
} from "api/models/hfn-events-service/hfn-events_pb";
import { ContentCategory } from "api/models/hfn-events-service/common/common_pb";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { localeObject } from "constants/lang";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: String;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
  deleteItemId: "0",
};

const languageType = Object.keys(localeObject);

export const DaajiMessageForm = React.forwardRef<
  HTMLDivElement,
  DaajiMessageFormProps
>((props, ref): ReactElement => {
  const dispatch = useDispatch();
  const tagsList = useSelector((state: RootState) => state.bhandara.tagList);
  const [language, setLanguage] = useState<languageType>(1);
  const [states, setStates] = useState<State>(initialState);
  const [id, setId] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [readingTime, setReadingTime] = useState<string>("10");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [isAdding, setIsAdding] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [page, setPage] = useState<number>(0);
  const [selectedTagsId, setSelectedTagsId] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [publishedDate, setPublishedDate] = useState(new Date());
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const onClickEdit = useCallback(
    (item: any) => {
      setStates({ editing: true });
      setLanguage(props.lang);
      setId(item.id);
      setTitle(item.title);
      setPdfUrl(item.contenturl);
      setImageUrl(item.thumbnailimageurl);
      setReadingTime(item.readingtime);
      setSelectedTagsId(item.tagsList.map((item) => item.id));
      setSelectedTags(item.tagsList.map((item) => item.name));
      setPublishedDate(new Date(Number(item.publishedat.seconds) * 1000));
    },
    [props.lang]
  );

  useEffect(() => {
    console.log("Props: ", props.editItem);
    if (props.isEditing) {
      onClickEdit(props.editItem);
    }
  }, [props.isEditing, props.editItem, onClickEdit]);

  useEffect(() => {
    dispatch(getAllBhandaraTags(10, page));
  }, [page, dispatch]);

  const handleMultipleTagChange = (event: any) => {
    const {
      target: { value },
    } = event;
    // console.log(value);

    const arrOfNum: number[] = (
      typeof value === "string" ? value.split(",") : value
    ).map((str) => {
      return Number(str);
    });
    const val = selectedTagsId.filter((item) => value.indexOf(item) === -1);
    const tagIndex = selectedTagsId.indexOf(val[0]);
    setSelectedTagsId(arrOfNum);
    if (tagIndex > -1) {
      setSelectedTags(() => {
        const updatedTags = [...selectedTags];
        updatedTags.splice(tagIndex, 1);
        // console.log("updated Tags: ", updatedTags);
        return updatedTags;
      });
    } else if (tagIndex === -1) {
      setSelectedTags(() => {
        const temp = tagsList.tags.tagsList.filter((item) =>
          value.includes(item.id)
        );

        const arrOfString = temp.map((item: any) => item.name);
        return [...new Set([...selectedTags, ...arrOfString])];
      });
    }
  };
  const tagsArray = () => {
    let arr: Tag[] = [];
    for (const tagIndex in selectedTags) {
      const temp = new Tag();
      temp.setId(selectedTagsId[tagIndex]);
      temp.setName(selectedTags[tagIndex]);
      arr.push(temp);
    }

    console.log("tagsArray: ", arr);
    return arr;
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const _deleteFileFromGcp = async (data: any) => {
    try {
      console.log("pdf url to delete from gcp", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: DeleteFileRequest = new DeleteFileRequest();
      reqBody.setImgpath(data);
      grpc.unary(GamificationService.DeleteFileFromGCP, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "data coming from delete file from gcp",
                message.toObject()
              );
            }
          } else {
            setIsLoading(false);
          }
        },
      });
    } catch (err) {
      console.log("Error ", err);
    }
  };
  const onImageClose = () => {
    _deleteFileFromGcp(imageUrl);
    setImageUrl("");
  };

  const onPdfClose = () => {
    _deleteFileFromGcp(pdfUrl);
    setPdfUrl("");
  };

  const onClickDelete = (messageId: any) => {
    setStates({ showDeleteModal: true, deleteItemId: messageId });
  };

  const handleDeleteClose = () => {
    setStates({ showDeleteModal: false });
  };

  const deleteMessage = async () => {
    deleteMessageById(states.deleteItemId);
  };
  const deleteMessageById = async (messageId: String | undefined) => {
    setIsLoading(true);
    console.log(`Delete call for message with id ${messageId}`);
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(Number(messageId));
      grpc.unary(HFNEventsService.DeleteContentById, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(`Delete message response ${message.toObject()}`);
              setIsLoading(false);
              _deleteFileFromGcp(pdfUrl);
              setStates({ showDeleteModal: false });
              setTimeout(() => props.onClickBack(), 2000);
              dispatch(
                getAllDaajiMessage(props.rowsPerPage, props.page, props.lang)
              );
              setSnackBar({
                open: true,
                severity: "success",
                message: " Deleted Message successfully",
              });
            } else {
              setIsLoading(false);
              setStates({ showDeleteModal: false });
              setSnackBar({
                open: true,
                severity: "Error",
                message: "Error in  deleting Message",
              });
            }
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      console.log(`Error: ${err}`);
      setSnackBar({
        open: true,
        severity: "Error",
        message: "Error in  deleting Message",
      });
    }
  };

  const validateForm = () => {
    if (title.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Message title can not be empty.",
      });
    } else if (!pdfUrl) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "pdf can not be empty.",
      });
    } else if (!imageUrl) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Thumbnail can not be empty.",
      });
    } else {
      return true;
    }
  };

  const _addMessage = () => {
    if (validateForm()) {
      const messageData = {
        title: {
          ...localeObject,
          ...(language === 1 || language === 2
            ? { defaultcontent: title, en: title }
            : { [languageType[language - 1]]: title }),
        },

        contentUrl: {
          ...localeObject,
          ...(language === 1 || language === 2
            ? { defaultcontent: pdfUrl, en: pdfUrl }
            : { [languageType[language - 1]]: pdfUrl }),
        },
        readingTime: readingTime,
        thumbnailimageurl: imageUrl,
      };
      setIsAdding(true);
      addMessage(messageData);
    }
  };

  const addMessage = async (data: any) => {
    setIsLoading(true);
    try {
      console.log(`Request to adding new message ${data}`);

      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title?.defaultcontent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);
      titleLocale.setPa(data.title.pa);

      const contentLocale: Locale = new Locale();
      contentLocale.setDefaultcontent(data.contentUrl.defaultcontent);
      contentLocale.setEn(data.contentUrl.en);
      contentLocale.setHi(data.contentUrl.hi);
      contentLocale.setTe(data.contentUrl.te);
      contentLocale.setGu(data.contentUrl.gu);
      contentLocale.setTa(data.contentUrl.ta);
      contentLocale.setKa(data.contentUrl.ka);
      contentLocale.setMr(data.contentUrl.mr);
      contentLocale.setIt(data.contentUrl.it);
      contentLocale.setRu(data.contentUrl.ru);
      contentLocale.setEs(data.contentUrl.es);
      contentLocale.setFr(data.contentUrl.fr);
      contentLocale.setPt(data.contentUrl.pt);
      contentLocale.setDe(data.contentUrl.de);
      contentLocale.setBn(data.contentUrl.bn);
      contentLocale.setMl(data.contentUrl.ml);
      contentLocale.setUk(data.contentUrl.uk);
      contentLocale.setOd(data.contentUrl.od);
      contentLocale.setEnUs(data.contentUrl.enUs);
      contentLocale.setEnAu(data.contentUrl.enAu);
      contentLocale.setPa(data.contentUrl.pa);

      const currentGoogleTimeStamp: Timestamp = new Timestamp();
      currentGoogleTimeStamp.fromDate(new Date(publishedDate));

      const reqBody = new Content();
      props.isEditing && reqBody.setId(props.editItem.id);
      reqBody.setTitle(titleLocale);
      reqBody.setContenturl(contentLocale);
      reqBody.setThumbnailimageurl(imageUrl);
      reqBody.setReadingtime(data.readingTime);
      reqBody.setTagsList(tagsArray());
      reqBody.setContentcategory(ContentCategory.DAAJI_MESSAGES);
      reqBody.setPublishedat(currentGoogleTimeStamp);
      console.log("Request for updating message", reqBody.toObject());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(HFNEventsService.AddOrUpdateContent, {
        request: reqBody,
        host: HFN_EVENTS_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Content>) => {
          const { status, message, statusMessage } = res;
          setIsAdding(false);
          if (status === grpc.Code.OK && message) {
            console.log("update message response", message.toObject());
            setIsLoading(false);
            dispatch(
              getAllDaajiMessage(props.rowsPerPage, props.page, props.lang)
            );
            setSnackBar({
              open: true,
              severity: "success",
              message: `message ${
                props.isEditing ? "updated" : "added"
              } successfully`,
            });
            setTimeout(() => props.onClickBack(), 2000);
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: `Error in ${
                props.isEditing ? "update" : "add"
              } message : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      setIsAdding(false);
      console.log("Error in update message", error);
      setSnackBar({
        open: true,
        severity: "error",
        message: error,
      });
    }
  };

  return (
    <Root ref={ref} className={classes.root}>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", right: "50%" }}
        />
      )}
      <Grid
        container
        className="p-2 align-items-center"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid container alignItems="center">
            <Grid>
              <IconButton onClick={props.onClickBack} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {props.isEditing
                  ? "Edit Daaji's Message"
                  : "Add new Daaji's Message"}
              </Typography>
              {props.isEditing ? (
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={props.onClickBack}
                  >
                    Daaji's Message
                  </Typography>
                  <Tooltip
                    title={props.editItem.title}
                    disableHoverListener={props.editItem.title?.length < 20}
                    placement="bottom"
                  >
                    <Box>
                      <Typography color="inherit" style={{ cursor: "pointer" }}>
                        {props.editItem.title?.slice(0, 20)}
                        {props.editItem.title?.length > 20 && "..."}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Typography color="textPrimary">Edit</Typography>
                </Breadcrumbs>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {props.isEditing ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => onClickDelete(id)}
              >
                Delete
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="title"
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className="mr-3"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="number"
              name="readingTime"
              label="Reading Time (sec)"
              variant="outlined"
              value={readingTime}
              onChange={(e) => {
                setReadingTime(e.target.value > "0" ? e.target.value : "");
              }}
              className="mr-3"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              //style={{ maxWidth: "250px" }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Published Date"
                  maxDate={new Date()}
                  value={publishedDate}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value: any) => {
                    setPublishedDate(value);
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Tags</InputLabel>
              <Select
                labelId="tags-multiple-checkbox-label"
                id="tags-multiple-checkbox"
                multiple
                value={selectedTagsId}
                onChange={handleMultipleTagChange}
                input={<OutlinedInput label="Tags" />}
                renderValue={() => selectedTags.join(",")}
                MenuProps={{
                  sx: {
                    maxHeight: "500px",
                  },
                }}
              >
                {tagsList.loading ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  tagsList.tags.tagsList.map((x: any) => (
                    <MenuItem key={x.id} value={x.id}>
                      <Checkbox checked={selectedTagsId.indexOf(x.id) > -1} />
                      <ListItemText primary={String(x.name)} />
                    </MenuItem>
                  ))
                )}
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={tagsList.tags.pageable?.totalcount || 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={10}
                  style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
                />
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <UploadFileContent
              title="Image"
              fileUrl={imageUrl}
              setFileUrl={setImageUrl}
              type="image"
              onFileClose={onImageClose}
              language={language}
              uploadPath="Bhandara/daaji's-message/images"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <UploadFileContent
              title="Pdf"
              fileUrl={pdfUrl}
              setFileUrl={setPdfUrl}
              type="pdf"
              onFileClose={onPdfClose}
              language={language}
              uploadPath="Bhandara/daaji's-message/pdfs"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          className={classes.formBtn}
          onClick={props.onClickBack}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          className={classes.formBtn}
          onClick={() => _addMessage()}
          color="primary"
          variant="contained"
          disabled={isAdding}
        >
          {props.isEditing ? "Update Message" : "Add Message"}
        </Button>
      </Box>

      <DialogBox
        open={states.showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={deleteMessage}
        type={"delete"}
        title={`Sure !! Want to delete this Message?`}
      />

      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Root>
  );
});
