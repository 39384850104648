import { ReactElement } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { useAuth } from "services/AuthService";

interface Props extends RouteProps {
  component: any;
}

export default function PrivateRoute({
  component: Component,
  ...rest
}: Props): ReactElement {
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser ? <Component {...props} /> : <Redirect to="/login" />
      }
    ></Route>
  );
}
