import { Card, IconButton, Theme, useTheme, Grid } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Close, PauseCircleFilled } from "@mui/icons-material";
import React, { ReactElement, useState } from "react";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import VideoPlayer from "utils/video";

interface AudioPlayerProps {
  onAudioClose?: any;
  // onEnded: any;
  src: string;
  audioTitle?: string;
  style?: any;
  type?: string;
}

export default function AudioPlayer(props: AudioPlayerProps): ReactElement {
  console.log("audio url ", props.src);
  const playerClasses = playerStyles();
  const theme = useTheme();
  const audioStateDef: any = {
    paused: true,
  };
  const [audioState, setaudioState] = useState(audioStateDef);
  const handlePlay = () => {
    const audio: any = document.getElementsByClassName("audio")[0];
    audioState.paused ? audio.play() : audio.pause();
  };
  // const croppedFile: File = dataURLtoFile(props.src, 'videos');
  const urlType: string = props.src.substring(
    props.src.lastIndexOf(".") + 1,
    props.src.length
  );
  const url = urlType.toString();
  //console.log('url',url);

  const videoJsOptions = {
    autoplay: false,
    width: 280,
    height: 200,
    controls: true,
    sources: [
      {
        src: `${props.src}`,
        type: url === "m3u8" ? "application/x-mpegURL" : `video/${url}`,
        //type:'video/mp4'
      },
    ],
  };

  return (
    <Card className={playerClasses.root}>
      {/* <CardHeader
          className={playerClasses.header}
            action={
              <IconButton
                aria-label="settings"
                onClick={() => {
                  props.onAudioClose();
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            }
          /> */}
      {props.type === "update" || props.type === "view" ? null : (
        <IconButton
          aria-label="settings"
          onClick={() => {
            props.onAudioClose();
          }}
          style={{ position: "absolute", right: "0px", top: "0px" }}
          size="large"
        >
          <Close fontSize="small" />
        </IconButton>
      )}

      {props.type === "video" || props.type === "update" ? null : (
        <div className={playerClasses.details}>
          <div className={playerClasses.controls}>
            <IconButton aria-label="previous" size="large">
              {theme.direction === "rtl" ? (
                <SkipNextIcon />
              ) : (
                <SkipPreviousIcon />
              )}
            </IconButton>
            <IconButton
              aria-label="play/pause"
              onClick={() => {
                setaudioState({ paused: !audioState.paused });
                handlePlay();
              }}
              size="large"
            >
              {audioState.paused ? (
                <PlayArrowIcon className={playerClasses.playIcon} />
              ) : (
                <PauseCircleFilled className={playerClasses.playIcon} />
              )}
            </IconButton>
            <IconButton aria-label="next" size="large">
              {theme.direction === "rtl" ? (
                <SkipPreviousIcon />
              ) : (
                <SkipNextIcon />
              )}
            </IconButton>
          </div>
        </div>
      )}
      {props.type === "video" ? (
        <Grid container justifyContent="center" className="pt-3">
          <VideoPlayer {...videoJsOptions} />
        </Grid>
      ) : (
        <Grid>
          <audio
            className="audio"
            src={props.src}
            onAbort={() => setaudioState({ paused: true })}
            onEnded={() => {
              console.log("audio ended");
              setaudioState({ paused: true });
            }}
          ></audio>
        </Grid>
      )}
    </Card>
  );
}

const playerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // display: "flex",
      // flexDirection: "column",
      height: "100%",
      //overflow: 'scroll',
      width: "100%",
      position: "relative",
      //justifyContent: "center",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      marginTop: "20%",
      width: "100%",
    },
    content: {
      display: "flex",
      // flex: "1 0 auto",
      flexDirection: "row",
      width: "100%",
    },
    cover: {
      width: 151,
    },
    controls: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    closeBtn: {
      alignSelf: "flex-end",
    },
    header: {
      width: "100%",
      height: "20%",
    },
  })
);
