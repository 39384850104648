import { createSlice } from "@reduxjs/toolkit";
import { GetAllFAQsResponse } from "api/models/gamification-service/gamification_pb";

interface faqType {
  faqList: GetAllFAQsResponse.AsObject;
  loading: boolean;
}
const initialState: faqType = {
  loading: false,
  faqList: {
    faqsandanswersList: [],
  },
};

export const faqSlice = createSlice({
  name: "meditationBackground",
  initialState,
  reducers: {
    setFaq: (state, action) => {
      state.faqList = action.payload;
    },
    setFaqLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setFaq, setFaqLoading } = faqSlice.actions;
export default faqSlice.reducer;
