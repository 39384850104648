export const GAMIFICATION_SERVICE_HOST: string =
  process.env.REACT_APP_GAMIFICATION_SERVICE_HOST ||
  "https://gamification-service-grpc.dev.heartfulnessinstitute.in:443";

// export const GAMIFICATION_SERVICE_HOST: string = "http://localhost:9004";

export const GUIDED_MEDITATION_SERVICE_HOST: string =
  process.env.REACT_APP_GUIDED_MEDITATION_SERVICE_HOST ||
  "https://guided-meditation-service-grpc.dev.heartfulnessinstitute.in:443";

export const LIVE_MEDITATION_SERVICE_HOST: string =
  process.env.REACT_APP_LIVE_MEDITATION_SERVICE_HOST ||
  "https://live-meditation-service-grpc.dev.heartfulnessinstitute.in:443";

export const HEARTINTUNE_PROFILE_SERVICE_HOST: string =
  process.env.REACT_APP_HEARTINTUNE_PROFILE_SERVICE_HOST ||
  "https://innermastery-profile-service-grpc.dev.heartfulnessinstitute.in:443";

export const HFN_EVENTS_SERVICE_HOST: string =
  process.env.REACT_APP_HFN_EVENTS_SERVICE_HOST ||
  "https://hfn-events-service-grpc.dev.heartfulnessinstitute.in:443";
