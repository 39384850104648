import React, { ReactElement, useEffect } from "react";
import Container from "@mui/material/Container";
import LayoutEditor from "components/LayoutComponents/LayoutEditor";
import LayoutPreview from "components/LayoutComponents/LayoutPreview";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { getCarousels } from "redux/actions/layoutManagerActions";
import { Grid } from "@mui/material";

export default function LayoutmanagerScreen(): ReactElement {
  const carouselsList = useSelector(
    (state: RootState) => state.layout.layoutManager.carousels.carouselList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCarousels());
  }, [dispatch]);
  return (
    <Container>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <LayoutEditor />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <LayoutPreview data={carouselsList} />
        </Grid>
      </Grid>
    </Container>
  );
}
