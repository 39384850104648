import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Grow,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TablePagination,
  Tooltip,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCoachListAsync } from "redux/actions/liveMeditationAction";
import { RootState } from "redux/store/store";
import AddCoach from "./AddCoach";

import RefreshIcon from "@mui/icons-material/Refresh";
import style from "./index.module.scss";

interface State {
  meditationList?: Array<any>;
  loadingMeditation?: boolean;
  editing?: boolean;
  loadingImage?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number;
  badgeNameLang?: any;
}

export default function CoachesScreen(): ReactElement {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.liveMeditation);
  const languages = useSelector((state: RootState) => state.app.languages);

  const initialState = {
    loadingMeditation: false,
    editing: false,
    loadingImage: false,
    editItem: null,
    showDeleteModal: false,
    deleteItemId: 0,
    badgeNameLang: languages[0],
  };
  const [currentIndex, setcurrentIndex] = useState(0);
  const [searchCoach, setsearchCoach] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [states, setStates] = useState<State>(initialState);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    dispatch(getCoachListAsync());
  }, [dispatch]);

  const onClickBack = () => {
    setcurrentIndex(0);
    setStates({
      editing: false,
      editItem: null,
    });
  };
  const onClickCoaches = () => {
    setcurrentIndex(2);
    setStates({
      editing: false,
      editItem: null,
    });
  };

  const renderListSkeleton = () =>
    Array(4)
      .fill(0)
      .map((i, x) => (
        <React.Fragment key={`coachSkele${x}`}>
          <ListItem>
            <ListItemIcon>
              <Skeleton variant="circular">
                <Avatar />
              </Skeleton>
            </ListItemIcon>
            <ListItemText
              primary={<Skeleton />}
              secondary={<Skeleton width="80%" />}
            />
          </ListItem>
          <Divider variant="inset" />
        </React.Fragment>
      ));

  const renderCoachList = () => (
    <>
      {state.coachList.coaches.length ? (
        <>
          <TableContainer
            style={{ maxHeight: "400px" }}
            className={style.scrollbar}
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell align="left">Image</TableCell>
                  <TableCell align="left">Coach Id</TableCell>
                  <TableCell align="left"> Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchCoach !== "" ? searchData : state.coachList.coaches)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((coachProfile, x) => (
                    <React.Fragment key={x}>
                      <TableRow
                        sx={{ cursor: "pointer" }}
                        hover
                        onClick={() => {
                          setStates({
                            editing: true,
                            editItem: coachProfile,
                          });
                          // executeScroll();
                          setcurrentIndex(1);
                        }}
                      >
                        <TableCell align="center">
                          <Avatar
                            // style={{ backgroundColor: "#3f51b5" }}
                            alt={coachProfile.name}
                            src={coachProfile.imageurl}
                          />
                        </TableCell>
                        <TableCell>{coachProfile.id}</TableCell>
                        <TableCell>{coachProfile.name}</TableCell>
                        <TableCell>{coachProfile.email}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={
              searchCoach !== ""
                ? searchData.length
                : state.coachList.coaches.length
            }
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
            className={style.scrollbar}
          />
        </>
      ) : (
        <Box height={350}>
          <Grid
            container
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" color="textSecondary">
              No live session to show
            </Typography>
          </Grid>
        </Box>
      )}
    </>
  );

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Card className="mt-3 mb-5" style={{ position: "relative" }}>
            <Grow
              in={currentIndex === 0}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 0 ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid container className="align-items-center p-3" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">All Coaches</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setcurrentIndex(1)}
                      startIcon={<AddIcon />}
                    >
                      Create Coach
                    </Button>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    <Grid
                      style={{ backgroundColor: "transparent" }}
                      className="d-flex py-2 pl-3 align-items-center"
                    >
                      <Grid className="d-flex flex-grow-1">
                        <TextField
                          type="search"
                          fullWidth
                          variant="outlined"
                          placeholder="Search coach by name or email"
                          size="small"
                          onChange={(e) => {
                            setsearchCoach(e.target.value);
                            const filteredData = state.coachList.coaches.filter(
                              (coachProfile) =>
                                coachProfile.name
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase()) ||
                                coachProfile.email
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                            );
                            setsearchData(filteredData);
                          }}
                          className="mr-2"
                        />
                      </Grid>
                      <Grid className="d-flex align-items-center">
                        <IconButton
                          onClick={() => {
                            dispatch(getCoachListAsync());
                          }}
                          size="large"
                        >
                          <Tooltip title="Refresh">
                            <RefreshIcon />
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    {/* </Grid> */}
                    {state.coachList.loading
                      ? renderListSkeleton()
                      : renderCoachList()}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
            <Grow
              in={currentIndex === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 1 ? { timeout: 1000 } : {})}
            >
              <AddCoach
                isEditing={states.editing}
                editItem={states.editItem}
                onClickBack={onClickBack}
                onClickCoaches={onClickCoaches}
              />
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
