import { createTheme } from "@mui/material";

export const LightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: process.env.REACT_APP_PRIMARY_MAIN || "#643E94",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffb875",
    },
  },
  typography: {
    fontFamily: "Manrope",
    fontWeightRegular: 500,
  },
});

export const DarkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main:process.env.REACT_APP_PRIMARY_MAIN || "#643E94",
    },
    secondary: {
      main: "#ffb875",
    },
  },
  typography: {
    fontFamily: "Manrope",
    fontWeightRegular: 500,
  },
});
