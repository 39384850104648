import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { getAllMeditation } from "redux/actions/guidedMeditationAction";
import "react-image-crop/dist/ReactCrop.css";

import { ListSkeleton } from "components/listSkeleton";
import { MeditationForm } from "./meditationForm";

import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import style from "./index.module.scss";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { UpdateGuidedMeditation } from "./updateMeditation";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
//import moment from "moment";
interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number | null;
}
// const meditationType = [
//   "UNKNOWN_MEDITATION_TYPE",
//   "MEDITATE_WITH_PURPOSE",
//   "MEDITATE_WITH_GUIDANCE",
//   "MEDITATE_WITH_PRECEPTOR",
//   "MEDITATE_ANY_RECORDED_CONTEN",
// ];

export default function GuidedMeditationScreen(): ReactElement {
  const dispatch = useDispatch();

  const meditationListState = useSelector(
    (state: RootState) => state.guidedMeditation.meditationList
  );
  const initialState = {
    editing: false,
    editItem: null,
  };

  const [currentScreen, setcurrentScreen] = useState(1);

  const [state, setState] = useState<State>(initialState);

  const [searchMeditation, setsearchMeditation] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [pageNumber, setpageNumber] = useState(0);
  const [pageSize, setpageSize] = useState(10);

  useEffect(() => {
    dispatch(getAllMeditation(pageSize, pageNumber, 2));
    //console.log(meditationListState);
  }, [dispatch, pageSize, pageNumber]);

  const onClickBack = () => {
    setcurrentScreen(1);
    setState({
      editing: false,
      editItem: null,
    });
  };
  const renderMeditationList = (data: any) => {
    console.log(data);
    // const newData =
    //   data.length &&
    //   data.filter((meditation: any) =>
    //     meditationType[meditation.meditationtype].includes(
    //       "MEDITATE_WITH_GUIDANCE"
    //     )
    //   );

    return (
      <>
        <Grid
          style={{ backgroundColor: "transparent" }}
          className="d-flex py-2 pl-3 align-items-center"
        >
          <Grid className="d-flex flex-grow-1">
            <TextField
              type="search"
              fullWidth
              variant="outlined"
              placeholder="Search by meditation title or duration"
              size="small"
              value={searchMeditation}
              onChange={(e) => {
                setsearchMeditation(e.target.value);
                const filteredData = data.filter(
                  (meditation: any) =>
                    meditation.title.defaultcontent
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    meditation.durationofmeditationclip
                      .toString()
                      .includes(e.target.value)
                );
                setsearchData(filteredData);
              }}
              className="mr-2"
            />
          </Grid>
          <Grid className="d-flex align-items-center">
            <IconButton
              onClick={() => {
                dispatch(getAllMeditation(pageSize, pageNumber, 2));
              }}
              size="large"
            >
              <Tooltip title="Refresh">
                <RefreshIcon />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
        {data.length ? (
          <>
            <TableContainer
              style={{ maxHeight: "400px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left"> Title</TableCell>
                    <TableCell align="center">Duration</TableCell>
                    <TableCell align="center">Edit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchMeditation !== "" ? searchData : data).map(
                    (meditation: any, x: any) => (
                      <React.Fragment key={x}>
                        <TableRow hover>
                          <TableCell align="center">
                            <Avatar
                              // style={{ backgroundColor: "#3f51b5" }}
                              alt={meditation.title.defaultcontent}
                              src={meditation.title.defaultcontent}
                            />
                          </TableCell>
                          <TableCell>
                            {meditation.title.defaultcontent}
                          </TableCell>
                          <TableCell align="center">
                            {/* {moment(meditation.durationofmeditationclip).format('mm:ss')}  */}
                            {meditation.durationofmeditationclip}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              onClick={() => {
                                setState({
                                  editing: true,
                                  editItem: meditation,
                                });
                                setcurrentScreen(3);
                              }}
                              size="large"
                            >
                              <RateReviewOutlinedIcon color="primary" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  )}
                  {/* {data.map((meditation: any, i: any) => (
                  <React.Fragment key={i}>
                    <TableRow>
                      <TableCell>{meditation.title.defaultcontent}</TableCell>
                    </TableRow>
                  </React.Fragment>
                ))} */}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                Not enough data
              </Typography>
            </Grid>
          </Box>
        )}
        <Divider />
        <Grid container justifyContent="flex-end">
          <Grid
            item
            style={{ width: "450px", overflowX: "auto" }}
            className={style.scrollbar}
          >
            <Grid
              container
              //spacing={2}
              className="d-flex justify-content-end align-items-center"
            >
              <Grid className="mr-2">
                <Typography>Rows per page:</Typography>
              </Grid>
              <Grid>
                <FormControl variant="standard">
                  <Select
                    value={pageSize}
                    onChange={(e) => {
                      setpageSize(Number(e.target.value));
                      dispatch(
                        getAllMeditation(Number(e.target.value), pageNumber, 2)
                      );
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid className="mx-2">
                {data.length ? (
                  <Typography>
                    {searchMeditation === ""
                      ? `1-${data.length} of ${data.length}`
                      : `1-${searchData.length} of ${searchData.length}`}
                  </Typography>
                ) : null}
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    if (pageNumber > 0) {
                      const previousPage = pageNumber - 1;
                      setpageNumber(pageNumber - 1);
                      setpageSize(10);
                      dispatch(getAllMeditation(pageSize, previousPage, 2));
                    }
                  }}
                  disabled={pageNumber === 0}
                  size="large"
                >
                  <ChevronLeftRounded />
                </IconButton>
                <Typography>{pageNumber + 1}</Typography>
                <IconButton
                  onClick={() => {
                    const nextPage = pageNumber + 1;
                    setpageNumber(pageNumber + 1);
                    dispatch(getAllMeditation(pageSize, nextPage, 2));
                  }}
                  disabled={!data.length}
                  size="large"
                >
                  <ChevronRightRounded />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Card
              className="mt-3 mb-5"
              style={{ position: "relative" }}
            >
              <Grow
                in={currentScreen === 1}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 1 ? { timeout: 1000 } : {})}
              >
                <div>
                  <Grid container className="align-items-center p-3">
                    <Typography variant="h6">
                      All Meditations With Guidance
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setcurrentScreen(2)}
                      startIcon={<AddIcon />}
                      className="ml-3"
                      //disabled
                    >
                      Create Meditation
                    </Button>
                  </Grid>
                  <Divider />
                  <CardContent>
                    <Paper>
                      {meditationListState.meditationList.loading
                        ? ListSkeleton()
                        : renderMeditationList(
                            meditationListState.meditationList.guidedList
                          )}
                    </Paper>
                    {/* </Box> */}
                  </CardContent>
                </div>
              </Grow>
              <Grow
                in={currentScreen === 2}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 2 ? { timeout: 1000 } : {})}
              >
                <MeditationForm onClickBack={onClickBack} />
              </Grow>
              <Grow
                in={currentScreen === 3}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 3 ? { timeout: 1000 } : {})}
              >
                <UpdateGuidedMeditation
                  isEditing={state.editing}
                  editItem={state.editItem}
                  onClickBack={onClickBack}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                />
              </Grow>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* <UploadAny /> */}
    </>
  );
}
