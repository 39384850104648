// source: schedule.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scheduledate: (f = msg.getScheduledate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expirydate: (f = msg.getExpirydate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    goalid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    completedstatus: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    userid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo;
  return proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirydate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompletedstatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 7:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScheduledate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpirydate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCompletedstatus();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp scheduleDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.getScheduledate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.setScheduledate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.clearScheduledate = function() {
  return this.setScheduledate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.hasScheduledate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp expiryDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.getExpirydate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.setExpirydate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.clearExpirydate = function() {
  return this.setExpirydate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.hasExpirydate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 goalId = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool completedStatus = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.getCompletedstatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.setCompletedstatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 userId = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 7;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 liveSessionId = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    scheduledate: (f = msg.getScheduledate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expirydate: (f = msg.getExpirydate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    goalid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    completedstatus: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    userid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    whichday: jspb.Message.getFieldWithDefault(msg, 8, ""),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 9, 0),
    goalcategoryid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    calendareventid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    notificationstatus: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 13, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 14, 0),
    selecteddatetime: (f = msg.getSelecteddatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo;
  return proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirydate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompletedstatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhichday(value);
      break;
    case 9:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalcategoryid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalendareventid(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotificationstatus(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSelecteddatetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getScheduledate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpirydate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCompletedstatus();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getWhichday();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getGoalcategoryid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCalendareventid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNotificationstatus();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getSelecteddatetime();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp scheduleDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getScheduledate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setScheduledate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.clearScheduledate = function() {
  return this.setScheduledate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.hasScheduledate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp expiryDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getExpirydate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setExpirydate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.clearExpirydate = function() {
  return this.setExpirydate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.hasExpirydate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 goalId = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool completedStatus = 6;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getCompletedstatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setCompletedstatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 userId = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string whichDay = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getWhichday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setWhichday = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 9;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 goalCategoryId = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getGoalcategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setGoalcategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string calendarEventId = 11;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getCalendareventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setCalendareventid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool notificationStatus = 12;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getNotificationstatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setNotificationstatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int64 liveSessionId = 13;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 duration = 14;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional google.protobuf.Timestamp selectedDateTime = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.getSelecteddatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.setSelecteddatetime = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.clearSelecteddatetime = function() {
  return this.setSelecteddatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo.prototype.hasSelecteddatetime = function() {
  return jspb.Message.getField(this, 15) != null;
};


goog.object.extend(exports, proto.unifiedplatform.heartIntuneProfile.schedule);
