import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Avatar,
  Card,
} from "@mui/material";
import moment from "moment";

const InfoCard = ({ item, index }: any) => {
  const contactDetails = (item) => {
    if (item.mobilenumber && item.email) {
      return `Number:${item.mobilenumber}, Email:${item.email}`;
    } else {
      return item.mobilenumber
        ? `Number:${item.mobilenumber}`
        : item.email
        ? `Email:${item.email}`
        : "";
    }
  };
  return (
    <Card key={index} sx={{ marginX: "25px", marginY: "10px" }}>
      <ListItem
        sx={{
          display: "flex",
        }}
      >
        <ListItemIcon>
          <Avatar sx={{ bgcolor: "#ffb875" }} src="" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            variant: "body2",
          }}
          primary={item.username === "" ? `<Anonymous User>` : item.username}
          secondary={contactDetails(item)}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          align="right"
          sx={{
            fontStyle: "italic",
            alignSelf: "center",
          }}
        >
          {moment(new Date(item.timestamp.seconds * 1000).toString()).format(
            "DD MMM, hh:mm a"
          )}
        </Typography>
      </ListItem>
      {item.textissue && (
        <ListItem>
          <Typography variant="body2" color="textSecondary">
            {item.textissue}
          </Typography>
        </ListItem>
      )}
    </Card>
  );
};

export default InfoCard;
