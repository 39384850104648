import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  ListOfDonationDetailsResponse,
  RequestPageable,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import {
  donationsList,
  setDonationsListLoading,
} from "redux/reducers/donationReducer";
import { AppThunk } from "redux/store/store";

export const getAllDonations =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: RequestPageable = new RequestPageable();
    reqBody.setPagenumber(pageNumber);
    reqBody.setPagesize(pageSize);
    grpc.unary(GamificationService.GetAllDonationDetailsForAdmin, {
      host: GAMIFICATION_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<ListOfDonationDetailsResponse>) => {
        const { status, message, statusMessage } = res;
        if (status === grpc.Code.OK && message) {
          dispatch(setDonationsListLoading(false));
          dispatch(donationsList(message.toObject().donationdetailsList));
        } else {
          console.error("error in fetching donations", statusMessage);
        }
      },
    });
  };
