import { createSlice } from "@reduxjs/toolkit";
import { GetAllMoodTriggerResponse } from "api/models/gamification-service/gamification_pb";

interface triggersDefaultState {
  triggersList: {
    loading: boolean;
    triggers: GetAllMoodTriggerResponse.AsObject;
  };
}

const initialState: triggersDefaultState = {
  triggersList: {
    loading: true,
    triggers: {
      moodtriggerlocalresponseList: [],
      moodtriggerresponseList: [],
      pageable: {
        selectedpagenumber: 0,
        selectedpagesize: 10,
        totalcount: 0,
      },
    },
  },
};

export const triggersSlice = createSlice({
  name: "triggersList",
  initialState,
  reducers: {
    setTriggersListLoading: (state, action) => {
      state.triggersList.loading = action.payload;
    },
    triggersList: (state, action) => {
      state.triggersList.triggers = action.payload;
    },
  },
});

export const { setTriggersListLoading, triggersList } = triggersSlice.actions;

export default triggersSlice.reducer;
