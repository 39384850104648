import {
  TableRow,
  TableCell,
  Avatar,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
  Chip,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { unpublishGroup } from "redux/actions/groupsAction";
import DialogBox from "components/dialogBox/dialog";
import GroupRecurrence from "./GroupRecurrence";
import { RootState } from "redux/store/store";

const GroupItem = ({
  item,
  setLocalisedContent,
  languageType,
  language,
  setStates,
  setCurrentIndex,
  pageSize,
  pageNumber,
  isUnpublished,
}) => {
  const dispatch = useDispatch();

  const [publishStatus, setPublishStatus] = useState<boolean>(item.publish);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [recurrenceModal, setRecurrenceModal] = useState<boolean>(false);

  const scheduledVideoList = useSelector(
    (state: RootState) => state.groups.scheduledVideoList
  );

  useEffect(() => {
    setPublishStatus(item.publish);
  }, [item]);

  const changePublishStatus = () => {
    setPublishStatus(!publishStatus);
    dispatch(unpublishGroup(item.id, pageNumber, pageSize, false));
  };

  const decideRecurrence = () => {
    // console.log(item);
    if (item.recurrence) {
      const days = item.weekdaysList
        .map((el) => getDaysFromWeekdaysList(el))
        .toString()
        .replaceAll(",", " ");
      return days;
    } else {
      return moment(new Date(Number(item.scheduletime?.seconds) * 1000))
        .tz(moment.tz.guess(true))
        .format("DD MMM, hh:mm a");
    }
  };

  const handleItemClick = () => {
    setRecurrenceModal(true);
    console.log(moment().startOf("day").format());
  };

  const getDaysFromWeekdaysList = (el: number) => {
    switch (el) {
      case 1:
        return "Mon";
      case 2:
        return "Tue";
      case 3:
        return "Wed";
      case 4:
        return "Thu";
      case 5:
        return "Fri";
      case 6:
        return "Sat";
      case 7:
        return "Sun";
    }
  };

  return (
    <>
      <TableRow
        hover
        sx={{
          cursor: "pointer",
          height: "50px",
          background: publishStatus ? "" : "#F0F0F0",
          filter: publishStatus ? "" : "grayscale(100%)",
        }}
      >
        <TableCell align="center" onClick={handleItemClick}>
          <Avatar
            alt={
              item.prerecorded.title[languageType[language]]
                ? item.prerecorded.title[languageType[language]]
                : "NOT AVAILABLE IN THIS LANGUAGE"
            }
            src={setLocalisedContent(item.prerecorded)?.imgurl}
          />
        </TableCell>
        <TableCell align="center">{item.id}</TableCell>
        <TableCell align="left" onClick={handleItemClick}>
          <Typography variant="body2">
            {item.prerecorded.title[languageType[language]] ? (
              item.prerecorded.title[languageType[language]]
            ) : (
              <Chip
                variant="filled"
                label="Content not set for this language"
              />
            )}
          </Typography>
        </TableCell>
        <TableCell align="center" onClick={handleItemClick}>
          {item.userregistercount}
        </TableCell>
        <TableCell align="left" onClick={handleItemClick}>
          <Chip
            variant="outlined"
            color="primary"
            label={
              <Typography
                variant="button"
                color="primary"
                sx={{ textTransform: "none", fontSize: "12px" }}
              >
                {decideRecurrence()}
              </Typography>
            }
          />
        </TableCell>
        <TableCell align="left" onClick={handleItemClick}>
          <Typography variant="body2">
            {moment(new Date(Number(item.scheduletime?.seconds) * 1000))
              .tz(moment.tz.guess(true))
              .format(" hh:mm a")}
          </Typography>
        </TableCell>
        {!isUnpublished ? (
          <>
            <TableCell align="center">
              <FormControlLabel
                checked={publishStatus}
                control={<Switch name="publish" disabled={!publishStatus} />}
                label={
                  <Typography variant="caption">
                    {publishStatus ? "Published" : "Unpublished"}
                  </Typography>
                }
                onChange={() => setShowDeleteModal(true)}
              />
            </TableCell>
            <TableCell align="left">
              <IconButton
                size="large"
                color="primary"
                disabled={!publishStatus}
                onClick={() => {
                  setStates({
                    editing: true,
                    editItem: {
                      id: item.id,
                      userJoinCount: item.userjoincount,
                      scheduledTime: item.scheduletime,
                      itemData: setLocalisedContent(item.prerecorded),
                      weekdaysList: item.weekdaysList,
                    },
                  });
                  setCurrentIndex(1);
                }}
              >
                <EditIcon />
              </IconButton>
            </TableCell>
          </>
        ) : (
          ""
        )}
      </TableRow>
      <Dialog
        open={showDeleteModal || false}
        onClose={() => setShowDeleteModal(false)}
        // onCancel={() => setShowDeleteModal(false)}
        // onConfirm={changePublishStatus}
        // type={"delete firebase"}
      >
        <DialogTitle>{"Are you Sure?"}</DialogTitle>
        <DialogContent>
          Once a group is unpublished it can't be published again. Are you sure
          you want to unpublish?
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            autoFocus
            onClick={() => setShowDeleteModal(false)}
          >
            No
          </Button>
          <Button variant="outlined" onClick={changePublishStatus} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <DialogBox
        type="custom"
        open={recurrenceModal}
        onClose={() => setRecurrenceModal(!recurrenceModal)}
      >
        <GroupRecurrence
          id={item.id}
          title={item.title}
          setRecurrenceModal={() => setRecurrenceModal(!recurrenceModal)}
        />
      </DialogBox>
    </>
  );
};
export default GroupItem;
