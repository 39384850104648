import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Grow,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Stack,
  Badge,
} from "@mui/material";

import { ListSkeleton } from "components/listSkeleton";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGoalsInfoV2 } from "redux/actions/goalsAction";
import { RootState } from "redux/store/store";
import { GoalsFormV2 } from "./goalFormV2";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import style from "./index.module.scss";
import { CompositeGoalForm } from "./compositeGoalForm";
import { ResponseGoalInfoV2 } from "api/models/gamification-service/gamification_pb";

interface State {
  editing?: boolean;
  editItem?: any;
}
export default function GoalsScreen(): ReactElement {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.goals.goalsList);
  const initialState = {
    editing: false,
    editItem: null,
  };
  const [currentIndex, setcurrentIndex] = useState(0);
  const [searchGoal, setsearchGoal] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [states, setStates] = useState<State>(initialState);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getAllGoalsInfoV2(rowsPerPage, page));
  }, [dispatch, page, rowsPerPage]);

  const onClickBack = (run: any) => {
    dispatch(getAllGoalsInfoV2(rowsPerPage, page));
    setcurrentIndex(0);
    setStates({
      editing: false,
      editItem: null,
    });
  };

  const renderGoalList = (data: any) => {
    return (
      <div>
        {data.length ? (
          <>
            <Grid
              style={{ backgroundColor: "transparent" }}
              className="d-flex py-2 pl-3 align-items-center"
            >
              <Grid className="d-flex flex-grow-1">
                <TextField
                  type="search"
                  fullWidth
                  variant="outlined"
                  placeholder="Search goal by title"
                  size="small"
                  onChange={(e) => {
                    setsearchGoal(e.target.value);
                    const filteredData = data.filter((item: any) =>
                      item.goaltypes.title.en
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    setsearchData(filteredData);
                  }}
                  className="mr-2"
                />
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    dispatch(getAllGoalsInfoV2(rowsPerPage, page));
                  }}
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer
              style={{ maxHeight: "400px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left">Goal Id</TableCell>
                    <TableCell align="left">Goal Title</TableCell>
                    <TableCell align="left">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchGoal !== "" ? searchData : data).map(
                    (item: ResponseGoalInfoV2.AsObject, x: any) => (
                      <React.Fragment key={x}>
                        <TableRow
                          sx={{ cursor: "pointer" }}
                          hover
                          onClick={() => {
                            setStates({
                              editing: true,
                              editItem: item,
                            });
                            item.goaltypes?.goalcategory === 10
                              ? setcurrentIndex(2)
                              : setcurrentIndex(1);
                          }}
                        >
                          <TableCell align="center">
                            {item.badgewithcountList.length ? (
                              <Stack direction="row" spacing={2}>
                                {item.badgewithcountList.map((el) => (
                                  <Badge
                                    key={el.id}
                                    badgeContent={el.badgecount}
                                    color="secondary"
                                    overlap="circular"
                                  >
                                    <Avatar src={el.icon} />
                                  </Badge>
                                ))}
                              </Stack>
                            ) : (
                              <Box display="flex" alignItems="flex-start">
                                <Badge
                                  badgeContent={item.goaltypes?.badgecount}
                                  color="secondary"
                                  overlap="circular"
                                >
                                  <Avatar src={item.badge?.icon} />
                                </Badge>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell>
                            {item.goaltypes?.id}
                          </TableCell>
                          <TableCell>{item.goaltypes?.title?.en}</TableCell>
                          <TableCell>
                            {item.goaltypes?.description?.en}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={Number(state.goalsListV2.goals?.pageable?.totalcount)}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No goals to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Card className="mt-3 mb-5">
            <Grow
              in={currentIndex === 0}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 0 ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid container className="align-items-center p-3" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">All Goals V2</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setcurrentIndex(1);
                      }}
                      startIcon={<AddIcon />}
                    >
                      Create Goal
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setcurrentIndex(2);
                      }}
                      startIcon={<AddIcon />}
                      disabled
                    >
                      Create Composite Goal
                    </Button>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    {state.goalsListV2.loading
                      ? ListSkeleton()
                      : renderGoalList(state.goalsListV2.goals?.goaltypesList)}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
            <Grow
              in={currentIndex === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 1 ? { timeout: 1000 } : {})}
            >
              <GoalsFormV2
                isEditing={states.editing}
                editItem={states.editItem}
                onClickBack={onClickBack}
                pageSize={rowsPerPage}
                pageNumber={page}
                setPageNumber={setPage}
                isPageBackRequired={
                  !(Number(state.goalsListV2.goals?.goaltypesList.length) - 1)
                }
              />
            </Grow>
            <Grow
              in={currentIndex === 2}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 2 ? { timeout: 1000 } : {})}
            >
              <CompositeGoalForm
                isEditing={states.editing}
                editItem={states.editItem}
                onClickBack={onClickBack}
                pageSize={rowsPerPage}
                pageNumber={page}
                setPageNumber={setPage}
                isPageBackRequired={
                  !(Number(state.goalsListV2.goals?.goaltypesList.length) - 1)
                }
              />
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
