import { createSlice } from "@reduxjs/toolkit";

interface heartsAppContent {
  loading: boolean;
  Content: {
    allcontentlistList: [];
    youtubevideoslistList: [];
    totalpdfarticlecount: number;
    totalyoutubevideocount: number;
    pageable: {
      totalcount: number;
      selectedPageSize: number;
      selectedPageNumber: number;
    };
  };
}

const initialState: heartsAppContent = {
  loading: false,
  Content: {
    allcontentlistList: [],
    youtubevideoslistList: [],
    totalpdfarticlecount: 0,
    totalyoutubevideocount: 0,
    pageable: {
      totalcount: 0,
      selectedPageNumber: 0,
      selectedPageSize: 0,
    },
  },
};

export const heartsAppSlice = createSlice({
  name: "heartsApp",
  initialState,
  reducers: {
    heartsAppContentLoading(state, action) {
      state.loading = action.payload;
    },
    setHeartsAppContent(state, action) {
      state.Content = action.payload;
    },
  },
});

export const { heartsAppContentLoading, setHeartsAppContent } =
  heartsAppSlice.actions;

export default heartsAppSlice.reducer;
