import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { ImageOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import {
  getAllKeyLocations,
  getAllLocationCategories,
} from "redux/actions/bhandaraActions";
import ImageCrop from "components/image-crop/image-crop";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import { useFileUploadService } from "services/FileUplaodService";
import { firebaseAuth } from "config/firebase";
import {
  ID,
  KeyLocationDetails,
  KeyLocationRequest,
  Status,
} from "api/models/hfn-events-service/hfn-events_pb";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import {
  GAMIFICATION_SERVICE_HOST,
  HFN_EVENTS_SERVICE_HOST,
} from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";

const PREFIX = "CreateEvent";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  thumbNail: `${PREFIX}-thumbNail`,
  actionIcon: `${PREFIX}-actionIcon`,
};

interface EventProps {
  isEditing: any;
  editItem: any;
  onClickBack: any;
}

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    maxWidth: 350,
    // overflow: "scroll",
  },
  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `10%`,
    left: `40%`,
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },
}));

export const AddLocation: any = React.forwardRef<HTMLDivElement, EventProps>(
  (props, ref): ReactElement => {
    const cropDef = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };

    const dispatch = useDispatch();
    const categories = useSelector(
      (state: RootState) =>
        state.bhandara.locations.keyLocationCategories.categoriesList
    );
    const { appendFilesToUploadManager } = useFileUploadService();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [locationName, setLocationName] = useState<any>("");
    const [locationDescription, setLocationDescription] = useState<any>("");
    const [startTime, setStartTime] = useState<any>("");
    const [endTime, setEndTime] = useState<any>("");
    const [googleMapsUrl, setGoogleMapsUrl] = useState<string>("");
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [editLocation] = useState<boolean>(true);
    const [categoryId, setCategoryId] = useState<number>(1);
    const [latitude, setLatitude] = useState("0");
    const [longitude, setLongitude] = useState("0");

    const hiddenFileInput: any = React.useRef(null);
    const [src, setSrc] = useState<any>(null);
    const [imageRef, setImageRef] = useState<any>();
    const [imageFile, setImageFile] = useState<any>(null);
    const [crop, setCrop] = useState<any>(cropDef);
    const [open, setopen] = useState(false);

    const [croppedImageUrl, setCroppedImageUrl] = useState<any>(null);
    const [isAdding, setIsAdding] = useState(false);

    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const onClickEdit = useCallback((item: any) => {
      // console.log("Edit event details: ", item);
      setLocationName(item.title);
      setLocationDescription(item.description);
      // setLocationImageUrl(item.locationImageUrl);
      setStartTime(item.openat);
      setEndTime(item.closeat);
      // setMapLayout(item.mapLayout);
      setGoogleMapsUrl(item.directionurl);
      setCategoryId(item.keylocationcategorydetail.id);
      setCroppedImageUrl(item.imageurl);
      setLongitude(item.longitude);
      setLatitude(item.latitude);
    }, []);

    useEffect(() => {
      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
    }, [props.isEditing, props.editItem, onClickEdit]);

    useEffect(() => {
      dispatch(getAllLocationCategories());
    }, [dispatch]);

    // --------------------image crop and upload logic [start]--------------------//

    const onImageClose = () => {
      setSrc(null);
      setImageFile(null);
      _deleteFileFromGcp(croppedImageUrl);
      setCroppedImageUrl("");
      setCrop(cropDef);
    };

    const handleUploadClick = () => {
      hiddenFileInput.current.click();
      console.log("clicked on image upload");
    };
    const onSelectImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading(true);
        setImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };
    const onCropChange = (crop: any) => {
      setCrop(crop);
    };

    const onCropDone = async () => {
      setIsUploading(true);
      const cr: any = crop;
      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        setCroppedImageUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          "hfn-events/keyLocation".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setCroppedImageUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading(false);
            } else {
              setIsUploading(false);
            }
          }
        );
      }
      setSrc(null);
    };
    const _onOpenImage = () => {
      setopen(true);
    };
    const _onCloseImage = () => {
      setopen(false);
      setIsUploading(false);
    };

    // --------------------image crop and upload logic [end]--------------------//
    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                //setIsLoading(false);
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
                //setCroppedImageUrl("");
              }
            } else {
              setIsLoading(false);
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    const addKeyLocation = async (
      title: string,
      openat: string,
      closeat: string,
      imageurl: string,
      directionurl: string,
      description: string,
      locCategoryId: number,
      latitude: number,
      longitude: number
    ) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: KeyLocationRequest = new KeyLocationRequest();
      reqBody.setTitle(title);
      reqBody.setOpenat(openat);
      reqBody.setCloseat(closeat);
      reqBody.setImageurl(imageurl);
      reqBody.setDirectionurl(directionurl);
      reqBody.setDescription(description);
      reqBody.setLatitude(latitude);
      reqBody.setLongitude(longitude);
      reqBody.setKeylocationcategoryid(locCategoryId);

      grpc.unary(HFNEventsService.AddKeyLocation, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<KeyLocationDetails>) => {
          const { status } = res;
          if (status === grpc.Code.OK) {
            setSnackBar({
              open: true,
              severity: "success",
              message: "Location added sucessfully",
            });
          } else {
            setSnackBar({
              open: true,
              severity: "success",
              message: "Error in creating location",
            });
          }
        },
      });
    };
    const updateLocation = async (
      id: number,
      title: string,
      openat: string,
      closeat: string,
      imageurl: string,
      directionurl: string,
      description: string,
      locCategoryId: number,
      latitude: number,
      longitude: number
    ) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: KeyLocationDetails = new KeyLocationDetails();
      reqBody.setId(id);
      reqBody.setTitle(title);
      reqBody.setOpenat(openat);
      reqBody.setCloseat(closeat);
      reqBody.setImageurl(imageurl);
      reqBody.setDirectionurl(directionurl);
      reqBody.setDescription(description);
      reqBody.setLatitude(latitude);
      reqBody.setLongitude(longitude);
      reqBody.setKeylocationcategoryid(locCategoryId);
      grpc.unary(HFNEventsService.UpdateKeyLocation, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<KeyLocationDetails>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            setSnackBar({
              open: true,
              severity: "success",
              message: "Location updated sucessfully",
            });
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: "Error in updating meditation",
            });
          }
        },
      });
    };

    const deleteKeyLocationById = async (id: number) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(id);
      grpc.unary(HFNEventsService.DeleteKeyLocationById, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status } = res;
          if (status === grpc.Code.OK) {
            setSnackBar({
              open: true,
              severity: "success",
              message: "Location deleted sucessfully",
            });
            dispatch(getAllKeyLocations(50, 0));
            setTimeout(() => props.onClickBack(), 2000);
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: "Error in deleting location",
            });
          }
        },
      });
    };

    const validateData = () => {
      const UrlRegX = new RegExp(
        /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
      );
      if (locationName.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Location name can not be empty",
        });
      } else if (!UrlRegX.test(googleMapsUrl)) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "enter a valid URL in maps url",
        });
      } else if (locationDescription.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "location description can not be empty",
        });
      } else if (croppedImageUrl.trim() === "" || croppedImageUrl === null) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "please upload a location image",
        });
      } else if (startTime.trim() === "" || endTime.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Open at and clost at fields cannot be empty",
        });
      } else if (Number(latitude) === 0 && Number(longitude) === 0) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Please enter latitude and longitude",
        });
      } else if (!Number(latitude)) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Please enter a valid latitude",
        });
      } else if (!Number(longitude)) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Please enter a valid longitude",
        });
      } else {
        return true;
      }
    };

    function onClickAdd() {
      if (validateData()) {
        setIsAdding(true);
        addKeyLocation(
          locationName,
          startTime,
          endTime,
          croppedImageUrl,
          googleMapsUrl,
          locationDescription,
          categoryId,
          Number(latitude),
          Number(longitude)
        );
        setTimeout(() => props.onClickBack(), 2000);
      }
    }
    function onClickDelete() {
      deleteKeyLocationById(props.editItem.id);
    }

    function onClickUpdate() {
      if (validateData()) {
        setIsAdding(true);
        updateLocation(
          props.editItem.id,
          locationName,
          startTime,
          endTime,
          croppedImageUrl,
          googleMapsUrl,
          locationDescription,
          categoryId,
          Number(latitude),
          Number(longitude)
        );
        setTimeout(() => props.onClickBack(), 2000);
      }
    }
    return (
      <Root ref={ref}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          style={{
            padding: " 8px",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Grid
              container
              className={props.isEditing ? "" : "align-items-center"}
            >
              <Grid item>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Location" : "Create Location"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      locations
                    </Typography>
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {}}
                    >
                      {locationName}
                    </Typography>
                    <Typography color="textPrimary">edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={onClickDelete}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="locationName"
                  label="Location Name"
                  variant="outlined"
                  onChange={(e) => {
                    setLocationName(e.target.value);
                  }}
                  value={locationName}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="googleMapsUrl"
                  label="Google Maps Url"
                  variant="outlined"
                  onChange={(e) => {
                    setGoogleMapsUrl(e.target.value);
                  }}
                  value={googleMapsUrl}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="startTime"
                  label="Opens at"
                  variant="outlined"
                  onChange={(e) => {
                    setStartTime(e.target.value);
                  }}
                  value={startTime}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="endTime"
                  label="Closes at"
                  variant="outlined"
                  onChange={(e) => {
                    setEndTime(e.target.value);
                  }}
                  value={endTime}
                />
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid className="d-flex mb-4">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="category-select">Category</InputLabel>

                  <Select
                    defaultValue=""
                    labelId="category-select"
                    value={categoryId as unknown as string}
                    label="Category"
                    onChange={(e) => {
                      setCategoryId(e.target.value as unknown as number);
                    }}
                  >
                    {categories.map((cat: any, i) => (
                      <MenuItem key={i} value={cat.id}>
                        {cat.categoryname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="locationDescription"
                  label="Location Description"
                  variant="outlined"
                  onChange={(e) => {
                    setLocationDescription(e.target.value);
                  }}
                  value={locationDescription}
                  multiline
                  minRows={3}
                  maxRows={6}
                />
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid className="d-flex mb-4">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="longitude"
                  label="Longitude"
                  variant="outlined"
                  onChange={(e) => {
                    setLongitude(e.target.value);
                  }}
                  value={longitude}
                />
              </Grid>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="latitude"
                  label="Latitude"
                  variant="outlined"
                  onChange={(e) => {
                    setLatitude(e.target.value);
                  }}
                  value={latitude}
                />
              </Grid>
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {editLocation ? (
                    <>
                      {croppedImageUrl ? (
                        <PreviewProfileImage
                          onImageClose={onImageClose}
                          src={croppedImageUrl}
                        />
                      ) : (
                        <>
                          {isUploading === true ? (
                            <Card className={classes.thumbNail}>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className="pt-5"
                              >
                                <Typography className="pt-4">
                                  Uploading...
                                </Typography>
                              </Grid>
                            </Card>
                          ) : (
                            <CardActionArea
                              className={classes.thumbNail}
                              onClick={() => {
                                handleUploadClick();
                              }}
                            >
                              {/* <InputLabel htmlFor="thumbnail">
                                Upload image
                              </InputLabel> */}
                              <Avatar
                                className={classes.actionIcon}
                                variant="square"
                              >
                                <ImageOutlined />
                              </Avatar>
                              <input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                hidden
                                onChange={onSelectImage}
                                ref={hiddenFileInput}
                              />
                            </CardActionArea>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className="mt-4"
                    >
                      {croppedImageUrl === "" || croppedImageUrl === null ? (
                        <Avatar
                          src=""
                          style={{ height: "200px", width: "200px" }}
                        />
                      ) : (
                        <img
                          alt="coach Profile"
                          src={croppedImageUrl}
                          style={{
                            height: "200px",
                            width: "200px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </Grid>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Image
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className=" p-2 d-flex justify-content-end">
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              disabled={isAdding}
              onClick={() => (props.editItem ? onClickUpdate() : onClickAdd())}
            >
              {props.editItem ? "Update Location" : "Add Location"}
            </Button>
          </Box>
        </CardActions>
        {src && (
          <ImageCrop
            src={src.toString()}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onCropChange={onCropChange}
            onPressDone={onCropDone}
            onImageClose={onImageClose}
            open={open}
            onOpen={_onOpenImage}
            onClose={_onCloseImage}
          />
        )}
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
