import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Switch,
  Typography,
  FormControlLabel,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";

import { firebaseAuth } from "config/firebase";
import DialogBox from "components/dialogBox/dialog";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import {
  GAMIFICATION_SERVICE_HOST,
  HFN_EVENTS_SERVICE_HOST,
} from "api/serviceEndpoints";
import { grpc } from "@improbable-eng/grpc-web";
import { Root, classes } from "./HomeBanner.styles";
import UploadFileContent from "components/upload-file-content/UploadFileContent";
import {
  getAllHomeBanner,
} from "redux/actions/bhandaraActions";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import {
  CardDetails,
  ID,
  Status,
} from "api/models/hfn-events-service/hfn-events_pb";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { RootState } from "redux/store/store";
import { MenuProps } from "styles/global.styles";
interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: String;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
  deleteItemId: "0",
};


export const HomeBannerForm = React.forwardRef<
  HTMLDivElement,
  HomeBannerFormProps
>((props, ref): ReactElement => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState<any>(1);
  const [states, setStates] = useState<State>(initialState);
  const [id, setId] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [screenName, setScreenName] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [routing, setRouting] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>(true);
  const [isAdding, setIsAdding] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [publishedDate, setPublishedDate] = useState(new Date());
  const languages = useSelector((state: RootState) => state.app.languages);
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const onClickEdit = useCallback(
    (item: any) => {
      const cardData = JSON.parse(item?.carddata);
      setStates({ editing: true });
      setLanguage(item.selectedlanguage);
      setId(item.id);
      setTitle(cardData?.title);
      setRouting(cardData?.routing);
      setScreenName(cardData?.screenName);
      setLink(cardData?.link);
      setImageUrl(cardData?.image);
      setIsPublished(item.ispublished || false);
      setPublishedDate(new Date(Number(item.createddate.seconds) * 1000));
    },
    [props]
  );

  useEffect(() => {
    if (props.isEditing) {
      onClickEdit(props.editItem);
    }
  }, [props.isEditing, props.editItem, onClickEdit]);


  const _deleteFileFromGcp = async (data: any) => {
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: DeleteFileRequest = new DeleteFileRequest();
      reqBody.setImgpath(data);
      grpc.unary(GamificationService.DeleteFileFromGCP, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "data coming from delete file from gcp",
                message.toObject()
              );
            }
          } else {
            setIsLoading(false);
          }
        },
      });
    } catch (err) {
      console.log("Error ", err);
    }
  };
  const onImageClose = () => {
    _deleteFileFromGcp(imageUrl);
    setImageUrl("");
  };


  const onClickDelete = (messageId: any) => {
    setStates({ showDeleteModal: true, deleteItemId: messageId });
  };

  const handleDeleteClose = () => {
    setStates({ showDeleteModal: false });
  };

  const deleteMessage = async () => {
    deleteMessageById();
  };
  const deleteMessageById = async () => {
    setIsLoading(true);
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(Number(id));
      grpc.unary(HFNEventsService.DeleteCardById, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            if (message) {
              setIsLoading(false);
              setStates({ showDeleteModal: false });
              setTimeout(() => props.onClickBack(), 2000);
              dispatch(
                getAllHomeBanner(props.rowsPerPage, props.page, props.lang)
              );
              setSnackBar({
                open: true,
                severity: "success",
                message: "Banner deleted successfully",
              });
            } else {
              setIsLoading(false);
              setStates({ showDeleteModal: false });
              setSnackBar({
                open: true,
                severity: "Error",
                message: "Error in deleting Banner",
              });
            }
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "Error",
        message: "Error in  deleting Banner",
      });
    }
  };

  const validateForm = () => {
    if (title.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Banner title can not be empty.",
      });
    } else if (routing.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Routing can not be empty.",
      });
    } else if (routing.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Routing can not be empty.",
      });
    } else if (routing === "INTERNAL" && screenName.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Screen name can not be empty.",
      });
    } else if (routing === "EXTERNAL" && link.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Web URL can not be empty.",
      });
    } else if (!imageUrl) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Banner image can not be empty.",
      });
    } else {
      return true;
    }
  };

  const _addMessage = () => {
    if (validateForm()) {
      const messageData = {
        title: title,
        link: link || "",
        screenName: screenName || "",
        routing: routing,
        image: imageUrl,
      };
      setIsAdding(true);
      addMessage(messageData);
    }
  };

  const addMessage = async (data: any) => {
    setIsLoading(true);
    try {

      const currentGoogleTimeStamp: Timestamp = new Timestamp();
      currentGoogleTimeStamp.fromDate(new Date(publishedDate));
      const reqBody = new CardDetails();
      props.isEditing && reqBody.setId(props.editItem.id);
      reqBody.setIspublished(isPublished);
      reqBody.setCarddata(JSON.stringify(data));
      reqBody.setSelectedlanguage(language);
      reqBody.setCreateddate(currentGoogleTimeStamp);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      let Action = props.isEditing && props.editItem.id ? HFNEventsService.UpdateCard: HFNEventsService.CreateCard;
      console.log("req", reqBody.toObject());
      grpc.unary(Action, {
        request: reqBody,
        host: HFN_EVENTS_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<CardDetails>) => {
          const { status, message, statusMessage } = res;
          console.log("req", res);
          setIsAdding(false);
          if (status === grpc.Code.OK && message) {
            setIsLoading(false);
            dispatch(
              getAllHomeBanner(props.rowsPerPage, props.page, props.lang)
            );
            setSnackBar({
              open: true,
              severity: "success",
              message: `Banner ${
                props.isEditing ? "updated" : "added"
              } successfully`,
            });
            setTimeout(() => props.onClickBack(), 2000);
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: `Error in ${
                props.isEditing ? "update" : "add"
              } message : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      setIsAdding(false);
      setSnackBar({
        open: true,
        severity: "error",
        message: error,
      });
    }
  };

  return (
    <Root ref={ref} className={classes.root}>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", right: "50%" }}
        />
      )}
      <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className="p-2 align-items-center"
        >
          <Grid item>
            <Grid
              container
              className={props.isEditing ? "" : "align-items-center"}
            >
              <Grid item>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Home Banner" : "Create Home Banner"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      Home Banner
                    </Typography>
                    {/* <Typography color="inherit" style={{ cursor: "pointer" }}>
                      {props.editItem.goaltypes.title.en}
                    </Typography> */}
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControlLabel
              checked={isPublished}
              control={<Switch name="publish" />}
              label={
                <Typography>
                  {isPublished ? "Published" : "Unpublished"}
                </Typography>
              }
              onChange={() => setIsPublished((publish: boolean) => !publish)}
            />
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => onClickDelete(id)}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
      <CardContent>
      <Grid container spacing={1}>

      </Grid>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="language-select">Language</InputLabel>
              {languages && (
                <Select
                  defaultValue=""
                  labelId="language-select"
                  value={language}
                  label="Language"
                  onChange={(e) => {
                    setLanguage(e.target.value as number);
                  }}
                  MenuProps={MenuProps}
                >
                  {languages.map((lang: any, i: any) => (
                    <MenuItem key={i} value={lang.code}>
                      {lang.displayName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="title"
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className="mr-3"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel>Routing</InputLabel>
              <Select
                label="Routing"
                value={routing}
                onChange={(e) => setRouting(e.target.value as string)}
              >
                <MenuItem value="INTERNAL">INTERNAL</MenuItem>
                <MenuItem value="EXTERNAL">EXTERNAL</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {routing === "INTERNAL" && <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="screenName"
              label="Screen Name"
              variant="outlined"
              value={screenName}
              onChange={(e) => {
                setScreenName(e.target.value);
              }}
              className="mr-3"
            />
          </Grid>}
          {routing === "EXTERNAL" && <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="link"
              label="Web URL"
              variant="outlined"
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
              }}
              className="mr-3"
            />
          </Grid>}
          <Grid item md={4} xs={12}>
            <UploadFileContent
              title="Image"
              fileUrl={imageUrl}
              setFileUrl={setImageUrl}
              type="image"
              onFileClose={onImageClose}
              language={language}
              uploadPath="Bhandara/daaji's-message/images"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          className={classes.formBtn}
          onClick={props.onClickBack}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          className={classes.formBtn}
          onClick={() => _addMessage()}
          color="primary"
          variant="contained"
          disabled={isAdding}
        >
          {props.isEditing ? "Update Banner" : "Add Banner"}
        </Button>
      </Box>

      <DialogBox
        open={states.showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={deleteMessage}
        type={"delete"}
        title={`Sure !! Want to delete this Banner?`}
      />

      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Root>
  );
});
