import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOccurrencesById } from "redux/actions/groupsAction";
import { RootState } from "redux/store/store";
import { Close } from "@mui/icons-material";

const GroupRecurrence = ({ id, title, setRecurrenceModal }) => {
  const occurrenceList = useSelector(
    (state: RootState) => state.groups.occurrenceList.content
  );

  const dispatch = useDispatch();

  const end = moment().endOf("day").format("yyyy-MM-DD");
  const start = moment().subtract(1, "w").endOf("day").format();
  const [startDate, setstartDate] = useState<any>(start);
  const [endDate, setendDate] = useState<any>(end);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(8);

  useEffect(() => {
    dispatch(getAllOccurrencesById(id, startDate, endDate, page, rowsPerPage));
    console.log("GR", occurrenceList);
  }, [endDate, startDate, page, rowsPerPage]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper
      sx={{
        position: "absolute",
        minWidth: "90vw",
        padding: 3,
        top: `20vh`,
        left: `5vw`,
        overflowY: "scroll",
      }}
    >
      <Grid
        container
        sx={{
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          borderRadius: "5px",
        }}
      >
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="mt-2 ml-4"
        >
          <Typography variant="h6" display="inline">
            Group Occurences {title}
          </Typography>
          <IconButton onClick={setRecurrenceModal}>
            <CloseSharpIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12} className="my-3 mx-4">
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <FormControl variant="outlined" style={{ maxWidth: "150px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Start Date"
                    maxDate={new Date(endDate)}
                    value={startDate}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(value: any) => {
                      const time = moment(value)
                        .tz(moment.tz.guess(true))
                        .format("YYYY-MM-DD");
                      setstartDate(time);
                      console.log(
                        "selected start date in seconds",
                        Math.floor(Date.parse(value) / 1000)
                      );
                      const start = moment(time)
                        .startOf("day")
                        .format("YYYY-MM-DD");
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" style={{ maxWidth: "150px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="End Date"
                    maxDate={new Date(end)}
                    // minDate={new Date(startDate)}
                    value={endDate}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(value: any) => {
                      const time = moment(new Date(value))
                        .tz(moment.tz.guess())
                        .format("YYYY-MM-DD");
                      const end = moment(time)
                        .endOf("day")
                        .format("YYYY-MM-DD");
                      setendDate(end);
                      console.log(
                        "selected end date in seconds",
                        Math.floor(Date.parse(value) / 1000)
                      );
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%" }} />

        <Grid container>
          {occurrenceList.groupoccurrencesList &&
          occurrenceList.groupoccurrencesList.length > 0 ? (
            occurrenceList.groupoccurrencesList.map((el) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper
                  className="m-2 p-3"
                  elevation={0}
                  sx={{
                    width: "98%",
                    border: "1px solid #643E94",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    className="mb-2"
                  >
                    <ScheduleIcon
                      fontSize="medium"
                      color="secondary"
                      className="mr-2"
                    />
                    <Typography
                      variant="button"
                      color="primary"
                      fontWeight="bold"
                    >
                      {moment(el.scheduledtime.seconds * 1000).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </Typography>
                  </Box>

                  <Divider />
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    className="mt-2"
                  >
                    <PeopleIcon
                      fontSize="medium"
                      color="secondary"
                      className="mr-2"
                    />
                    <Typography
                      variant="button"
                      color="primary"
                      fontWeight="bold"
                    >
                      {el.userjoincount}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))
          ) : (
            <Box height={350} width="100%">
              <Grid
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="textSecondary">
                  No occurrences to show
                </Typography>
              </Grid>
            </Box>
          )}
        </Grid>

        <Divider sx={{ width: "100%" }} />

        <TablePagination
          rowsPerPageOptions={[4, 8, 16, 24]}
          count={occurrenceList.pageable.totalcount}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Grid>
    </Paper>
  );
};

export default GroupRecurrence;
