import { createSlice } from "@reduxjs/toolkit";
import { ListOfSelectedMeditaionBckgndDetails } from "api/models/guided-meditation-service/guided-meditation_pb";

interface meditationBackgroundType {
  backgroundList: ListOfSelectedMeditaionBckgndDetails.AsObject;
  loading: boolean;
}
const initialState: meditationBackgroundType = {
  loading: false,
  backgroundList: {
    meditaionbckgndimgList: [],
    meditaionbckgndmusicList: [],
  },
};

export const meditationBackgroundSlice = createSlice({
  name: "meditationBackground",
  initialState,
  reducers: {
    setMeditationBackgrounds: (state, action) => {
      state.backgroundList = action.payload;
    },
    setMeditationBackgroundsLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setMeditationBackgrounds, setMeditationBackgroundsLoading } =
  meditationBackgroundSlice.actions;
export default meditationBackgroundSlice.reducer;
