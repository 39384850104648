import React from "react";
import ReactDOM from "react-dom";

// Globla CSS imports
import "./pages/app/index.scss";
import "bootstrap/dist/css/bootstrap-grid.css";

import reportWebVitals from "./reportWebVitals";
import Splash from "pages/splash/splash.screen";
import { Provider } from "react-redux";
import { store } from "redux/store/store";
import "fontsource-roboto";
import FileUploadService from "services/FileUplaodService";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import 'index.scss'


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const theme = createTheme();
ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <FileUploadService>
          <Splash />
        </FileUploadService>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
