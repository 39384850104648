import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  Empty,
  ListOfMoodDetailsResponse,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { AppThunk } from "redux/store/store";
import { setMoodsListLoading, moodsList } from "redux/reducers/moodReducer";

export const getAllMoodDetails = (): AppThunk => async (dispatch) => {
  try {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: Empty = new Empty();
    grpc.unary(GamificationService.GetAllMoodDetails, {
      host: GAMIFICATION_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<ListOfMoodDetailsResponse>) => {
        const { status, message, statusMessage } = res;
        if (status === grpc.Code.OK && message) {
          console.log("All moods", message.toObject());
          dispatch(setMoodsListLoading());
          dispatch(moodsList(message.toObject().mooddetailresponseList));
        } else {
          console.log(`Error in fetching moods ${statusMessage}`);
          dispatch(setMoodsListLoading());
        }
      },
    });
  } catch (error) {
    console.error("Error in add mood", error);
  }
};
