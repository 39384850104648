import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import { firebaseAuth } from "config/firebase";
import { grpc } from "@improbable-eng/grpc-web";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import {
  HEARTINTUNE_PROFILE_SERVICE_HOST,
  HFN_EVENTS_SERVICE_HOST,
} from "api/serviceEndpoints";
import { AnnouncementRequest as PublishAnnouncementRequest } from "api/models/heartintune-profile-service/heartintune-profile_pb";
import {
  AnnouncementDetails,
  ID,
  Status,
} from "api/models/hfn-events-service/hfn-events_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { useDispatch } from "react-redux";
import { getAllAnnouncements } from "redux/actions/bhandaraActions";
import DialogBox from "components/dialogBox/dialog";

const PREFIX = "ScheduleAnnouncement";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  thumbNail: `${PREFIX}-thumbNail`,
  actionIcon: `${PREFIX}-actionIcon`,
};

interface EventProps {
  isEditing: any;
  editItem: any;
  onClickBack: any;
}

// interface State {
//   editing?: boolean;
//   editItem?: any;
//   showDeleteModal?: boolean;
//   deleteItemId?: number;
// }

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    maxWidth: 350,
    // overflow: "scroll",
  },
  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `10%`,
    left: `40%`,
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },
}));
export const ScheduleAnnouncement: any = React.forwardRef<
  HTMLDivElement,
  EventProps
>((props, ref): ReactElement => {
  const dispatch = useDispatch();
  const [isLoading] = useState<boolean>(false);

  const [announcementMessage, setAnnouncementMessage] = useState<any>("");
  const [url, setUrl] = useState("");
  const [deleteAnnouncementItem, setDeleteAnnouncementItem] =
    useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState(false);

  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const onClickEdit = useCallback((item: any) => {
    setAnnouncementMessage(item.announcementtext);
    setUrl(item.locationurl);
  }, []);

  useEffect(() => {
    if (props.isEditing) {
      onClickEdit(props.editItem);
      console.log("props.editItem", props.editItem);
    }
  }, [props.isEditing, props.editItem, onClickEdit]);

  const publishAnnouncement = async (
    announcementText: string,
    publishStatus: 0 | 1 | 2
  ) => {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: PublishAnnouncementRequest =
      new PublishAnnouncementRequest();

    const googleTimeStamp: Timestamp = new Timestamp();
    googleTimeStamp.setSeconds(Math.round(Date.now() / 1000));
    reqBody.setTopic("BHANDARA_EVENTS");
    reqBody.setAnnouncementtext(announcementText);
    reqBody.setScheduledat(googleTimeStamp);
    reqBody.setLocationurl(url);
    reqBody.setPublishstatus(publishStatus);
    console.log(reqBody.toObject());
    grpc.unary(HeartIntuneProfileService.PublishAnnouncementInBhandara, {
      host: HEARTINTUNE_PROFILE_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<AnnouncementDetails>) => {
        const { status, message, statusMessage } = res;
        if (status === grpc.Code.OK && message) {
          setSnackBar({
            open: true,
            severity: "success",
            message: "announcement added successfully",
          });
          dispatch(getAllAnnouncements(10, 0));
          setTimeout(props.onClickBack, 1500);
        } else {
          console.log("error in adding announcement \n", status, statusMessage);
          setSnackBar({
            open: true,
            severity: "error",
            message: "Something went wrong while adding the announcement",
          });
        }
      },
    });
  };

  const editAnnouncement = async (
    id: number,
    announcementText: string,

    publishStatus: 0 | 1 | 2
  ) => {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();

    const reqBody: AnnouncementDetails = new AnnouncementDetails();

    const googleTimeStamp: Timestamp = new Timestamp();
    googleTimeStamp.setSeconds(props.editItem.scheduledat.seconds);

    reqBody.setId(id);
    reqBody.setAnnouncementtext(announcementText);
    reqBody.setScheduledat(googleTimeStamp);
    reqBody.setLocationurl(url);
    reqBody.setPublishstatus(publishStatus);
    console.log("updated time", reqBody.toObject().scheduledat);

    grpc.unary(HFNEventsService.UpdatePublishAnnouncement, {
      host: HFN_EVENTS_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<AnnouncementDetails>) => {
        const { status, statusMessage } = res;
        if (status === grpc.Code.OK) {
          setSnackBar({
            open: true,
            severity: "success",
            message: "announcement updated successfully",
          });
          dispatch(getAllAnnouncements(10, 0));
          setTimeout(props.onClickBack, 1500);
        } else {
          setSnackBar({
            open: true,
            severity: "error",
            message: "something went wrong while updating announcement",
          });
          console.log("error in updating announcement\n", statusMessage);
        }
      },
    });
  };

  const deleteAnnouncement = async (id: number) => {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: ID = new ID();
    reqBody.setId(id);
    grpc.unary(HFNEventsService.DeletePublishAnnouncementByID, {
      host: HFN_EVENTS_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<Status>) => {
        const { status, statusMessage } = res;
        if (status === grpc.Code.OK) {
          setSnackBar({
            open: true,
            severity: "success",
            message: "Announcement deleted successfully",
          });
          dispatch(getAllAnnouncements(10, 0));
          setTimeout(props.onClickBack, 1500);
        } else {
          setSnackBar({
            open: true,
            severity: "error",
            message: "A problem occured while deleting the announcement",
          });
          console.log("error in deleting annoucnement \n", statusMessage);
        }
      },
    });
  };

  const onClickDelete = (id: number) => {
    setDeleteAnnouncementItem(id);
    setShowDeleteModal(true);
  };
  const handleDelete = () => {
    deleteAnnouncement(deleteAnnouncementItem);
    setShowDeleteModal(false);
  };

  const validateData = () => {
    // const UrlRegX = new RegExp(
    //   /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
    // );
    if (announcementMessage.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Announcement message can not be empty",
      });
    } else if (url !== "") {
      if (
        !url.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
        )
      ) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Please enter a valid url",
        });
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  function onClickAddAnnouncement() {
    if (validateData()) {
      setIsAdding(true);
      publishAnnouncement(announcementMessage, 1);
    }
  }

  function onClickUpdateAnnouncement() {
    if (validateData()) {
      setIsAdding(true);
      editAnnouncement(props.editItem.id, announcementMessage, 1);
    }
  }

  return (
    <Root ref={ref}>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", right: "50%" }}
        />
      )}
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        style={{
          padding: " 8px",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Grid
            container
            className={props.isEditing ? "" : "align-items-center"}
          >
            <Grid item>
              <IconButton onClick={props.onClickBack} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {props.isEditing ? "Edit Announcement" : "Add Announcement"}
              </Typography>
              {props.isEditing ? (
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={props.onClickBack}
                  >
                    announcements
                  </Typography>
                  <Typography
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={() => {}}
                  >
                    {announcementMessage}
                  </Typography>
                  <Typography color="textPrimary">edit</Typography>
                </Breadcrumbs>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {props.isEditing ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => onClickDelete(props.editItem.id)}
              >
                Delete
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid className="d-flex">
              <TextField
                fullWidth
                required
                type="text"
                name="announcementMessage"
                label="Announcement"
                variant="outlined"
                onChange={(e) => {
                  setAnnouncementMessage(e.target.value);
                }}
                value={announcementMessage}
                multiline
                minRows={2}
                maxRows={4}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              name="Link"
              label="link"
              variant="outlined"
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              className="mr-3"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className=" p-2 d-flex justify-content-end">
        <Box display="flex" justifyContent="flex-end">
          {props.isEditing ? (
            <>
              <Button
                onClick={props.onClickBack}
                color="primary"
                variant="contained"
                className="mr-2"
              >
                Cancel
              </Button>
              <Button
                onClick={onClickUpdateAnnouncement}
                disabled={isAdding}
                color="primary"
                variant="contained"
              >
                Update Announcement
              </Button>
            </>
          ) : (
            <Button
              onClick={onClickAddAnnouncement}
              disabled={isAdding}
              color="primary"
              variant="contained"
            >
              Add Announcement
            </Button>
          )}
        </Box>
      </CardActions>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <DialogBox
        open={showDeleteModal || false}
        onClose={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        type={"cancel"}
        title={`Are you sure that you want to remove this item ?`}
      />
    </Root>
  );
});
