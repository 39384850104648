import { createSlice } from "@reduxjs/toolkit";
import { GroupsResponse } from "api/models/live-meditation-service/live-meditation_pb";

interface groupsDefaultState {
  videosList: {
    loading: boolean;
    content: {
      prerecordedsList: any[];
      pageable: {
        totalcount: number;
        selectedpagesize: number;
        selectedpagenumber: number;
      };
    };
  };
  scheduledVideoList: {
    loading: boolean;
    content: {
      groupsList: any[];
      pageable: {
        totalcount: number;
        selectedpagesize: number;
        selectedpagenumber: number;
      };
    };
  };
  occurrenceList: {
    loading: boolean;
    content: {
      groupoccurrencesList: any[];
      pageable: {
        totalcount: number;
        selectedpagesize: number;
        selectedpagenumber: number;
      };
    };
  };
}

const initialState: groupsDefaultState = {
  videosList: {
    loading: true,
    content: {
      prerecordedsList: [],
      pageable: {
        totalcount: 0,
        selectedpagesize: 10,
        selectedpagenumber: 0,
      },
    },
  },
  scheduledVideoList: {
    loading: false,
    content: {
      groupsList: [],
      pageable: {
        totalcount: 0,
        selectedpagesize: 10,
        selectedpagenumber: 0,
      },
    },
  },
  occurrenceList: {
    loading: true,
    content: {
      groupoccurrencesList: [],
      pageable: {
        totalcount: 0,
        selectedpagesize: 10,
        selectedpagenumber: 0,
      },
    },
  },
};

export const groupsSlice = createSlice({
  name: "videosList",
  initialState,
  reducers: {
    setVideosListLoading: (state) => {
      state.videosList.loading = true;
    },
    setVideosListUpdate: (state, action) => {
      state.videosList.content = action.payload;
      state.videosList.loading = false;
    },
    setVideosListError: (state) => {
      state.videosList.loading = false;
    },
    scheduledVideosListLoading: (state) => {
      state.scheduledVideoList.loading = true;
    },
    setScheduledVideosListUpdate: (state, action) => {
      state.scheduledVideoList.content = action.payload;
      state.scheduledVideoList.loading = false;
    },
    scheduledVideosListError: (state) => {
      state.scheduledVideoList.loading = false;
    },
    occurrenceListLoading: (state) => {
      state.occurrenceList.loading = true;
    },
    occurrenceListUpdate: (state, action) => {
      state.occurrenceList.content = action.payload;
      state.scheduledVideoList.loading = false;
    },
    occurrenceListError: (state) => {
      state.occurrenceList.loading = false;
    },
  },
});

export const {
  setVideosListLoading,
  setVideosListUpdate,
  setVideosListError,
  scheduledVideosListLoading,
  setScheduledVideosListUpdate,
  scheduledVideosListError,
  occurrenceListLoading,
  occurrenceListUpdate,
  occurrenceListError,
} = groupsSlice.actions;

export default groupsSlice.reducer;
