import React, { ReactChild, ReactChildren, useState } from "react";
import { styled } from "@mui/material";
import NavBar from "components/NavBar";
import TopBar from "components/TopBar";

interface DashboardLayoutProps {
  children: ReactChild | ReactChildren;
}

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: "100vh",
  display: "flex",
  // flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 70,
  [theme.breakpoints.up("lg")]: {
    // paddingLeft: 256,
  },
}));

const DashboardLayoutContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderTopLeftRadius: "20px",
  display: "flex",
  // flex: "1 1 auto",
  overflow: "hidden",
}));

const DashboardLayoutContent = styled("div")({
  borderTopLeftRadius: "20px",
  // flex: "0 0 auto",
  // flexGrow: 1,
  height: "100%",
  overflow: "auto",
  borderWidth: "0",
  width: "110vw",
});

const DashboardLayout = (props: DashboardLayoutProps) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <DashboardLayoutRoot>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        {/* <Box
          sx={{
            display: { lg: "block", xs: "none" },
            position: "fixed",
            height: "20px",
            background: "#643E94",
            top: 64,
            left: "16vw",
            width: "100%",
            opacity: 1,
            zIndex: 3,
            "&::before": {
              position: "absolute",
              backgroundColor: "transparent",
              content: "''",
              bottom: "-50px",
              height: "50px",
              width: "25px",
              borderTopRightRadius: "25px",
              boxShadow: "0 -25px 0 0 #643E94",
              // transform: "rotate(0.25turn)",
              transform: "scaleX(-1)",
            },
          }}
        ></Box> */}
        <DashboardLayoutContainer>
          <DashboardLayoutContent>{props.children}</DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
