
export const goals = [
  {
    id: "1",
    title: "Learn to Relax",
    category: "Self Guided",
    level: "easy",
    visibility: true,
  },
  {
    id: "2",
    title: "World Meditation Day",
    category: "Live",
    level: "easy",
    visibility: true,
  },
];
export const carousels = [
  { id: "1", title: "Upcoming Live Mediations", editable: false },
  { id: "2", title: "Choose a Goal", editable: true, associatedData: goals },
  { id: "3", title: "Carousel three" },
];

export default carousels;
