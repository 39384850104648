import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import {
  AddNewGoalInfoV2Request,
  DeleteGoalInfoV2ByIdResponse,
  ID,
  ResponseGoalInfoV2,
  updateGoalInfoV2Request,
} from "api/models/gamification-service/gamification_pb";
import { firebaseAuth } from "config/firebase";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import {
  getAllGoalsDetails,
  getAllGoalsInfoV2,
} from "redux/actions/goalsAction";
import DialogBox from "components/dialogBox/dialog";
// import { getAllGoalsInfoV2 } from "redux/actions/goalsAction";

interface CompositeGoalProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  pageSize: number;
  pageNumber: number;
  setPageNumber: any;
  isPageBackRequired: boolean;
}
const localeObject = {
  defaultcontent: "",
  en: "",
  hi: "",
  te: "",
  gu: "",
  ta: "",
  ka: "",
  mr: "",
  it: "",
  ru: "",
  es: "",
  fr: "",
  pt: "",
  de: "",
  bn: "",
  ml: "",
  uk: "",
  od: "",
  enUs: "",
  enAu: "",
  pa: "",
};
interface deleteState {
  showDeleteModal?: boolean;
  deleteItemId?: number | null;
}

export const CompositeGoalForm = React.forwardRef<
  HTMLDivElement,
  CompositeGoalProps
>((props, ref) => {
  const initialDeleteState = {
    showDeleteModal: false,
    deleteItemId: 0,
  };

  const languages = useSelector((state: RootState) => state.app.languages);
  const state = useSelector((state: RootState) => state.goals.goalsList);
  const dispatch = useDispatch();
  const [deleteGoal, setdeleteGoal] = useState<deleteState>(initialDeleteState);
  const [expand, setexpand] = useState<boolean>(false);

  const [title, setTitle] = useState<string>("");
  const [language, setLanguage] = useState<any>(2);
  const [description, setDescription] = useState("");
  const [editGoal, setEditGoal] = useState<boolean>(false);
  const [goalId, setGoalId] = useState<any>("");
  const [selectedGoals, setSelectedGoals] = useState<
    ResponseGoalInfoV2.AsObject[]
  >([]);
  // const [deleteGoal, setdeleteGoal] = useState<deleteState>(initialDeleteState);

  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const onClickEdit = useCallback(
    (item: any) => {
      setGoalId(item.goaltypes.id);
      setEditGoal(true);
      setTitle(item.goaltypes.title.en);
      setDescription(item.goaltypes.description.en);
      setTimeout(() => {
        setSelectedGoals(
          state.goalsListV2.goals?.goaltypesList.filter(
            (i) =>
              i.goaltypes?.id === item.goaltypes.requiredsessionidsList[0] ||
              i.goaltypes?.id === item.goaltypes.requiredsessionidsList[1]
          )!
        );
      }, 500);
    },
    [state]
  );

  useEffect(() => {
    console.log(
      "selected goal ids",
      selectedGoals.map((el) => el.goaltypes?.id)
    );
  }, [selectedGoals]);

  useEffect(() => {
    dispatch(getAllGoalsInfoV2(100, 0));
  }, [dispatch]);

  useEffect(() => {
    if (props.isEditing) {
      onClickEdit(props.editItem);
    }
  }, [dispatch, onClickEdit, props.editItem, props.isEditing]);

  const removeSelectedGoal = (goalId) => {
    // console.log(
    //   selectedGoals.forEach((el) => console.log(el.goaltypes?.id, goalId))
    // );
    setSelectedGoals(selectedGoals.filter((el) => el.goaltypes?.id !== goalId));
  };

  const onClickDelete = (goalId: number) => {
    setdeleteGoal({ showDeleteModal: true, deleteItemId: goalId });
  };
  const handleDeleteClose = () => {
    setdeleteGoal({ showDeleteModal: false });
  };
  const deleteGoals = () => {
    setdeleteGoal({ showDeleteModal: false });
    let data = {
      ID: deleteGoal.deleteItemId,
    };
    _deleteGoal(data);
  };

  const _addGoals = async (data: any) => {
    try {
      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultcontent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);
      titleLocale.setPa(data.title.pa);

      const descriptionLocale: Locale = new Locale();
      descriptionLocale.setDefaultcontent(data.description.defaultcontent);
      descriptionLocale.setEn(data.description.en);
      descriptionLocale.setHi(data.description.hi);
      descriptionLocale.setTe(data.description.te);
      descriptionLocale.setGu(data.description.gu);
      descriptionLocale.setTa(data.description.ta);
      descriptionLocale.setKa(data.description.ka);
      descriptionLocale.setMr(data.description.mr);
      descriptionLocale.setIt(data.description.it);
      descriptionLocale.setRu(data.description.ru);
      descriptionLocale.setEs(data.description.es);
      descriptionLocale.setFr(data.description.fr);
      descriptionLocale.setPt(data.description.pt);
      descriptionLocale.setDe(data.description.de);
      descriptionLocale.setBn(data.description.bn);
      descriptionLocale.setMl(data.description.ml);
      descriptionLocale.setUk(data.description.uk);
      descriptionLocale.setOd(data.description.od);
      descriptionLocale.setEnUs(data.description.enUs);
      descriptionLocale.setEnAu(data.description.enAu);
      descriptionLocale.setPa(data.description.pa);

      const payload = new AddNewGoalInfoV2Request();
      payload.setTitle(titleLocale);
      payload.setDescription(descriptionLocale);

      payload.setGoalcategory(data.goalCategory);
      payload.setAssociatedbadgeid(data.associatedBadgeID);
      payload.setRequiredidsList(data.requiredIDs);
      console.log("request data to add goal details", payload.toObject());

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.AddNewGoalInfoV2, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseGoalInfoV2>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage  add goal details", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              // setIsLoading(false);
              dispatch(getAllGoalsDetails(language));
              setSnackBar({
                open: true,
                severity: "success",
                message: "goal added successfully",
              });
              dispatch(getAllGoalsInfoV2(props.pageSize, props.pageNumber));
              setTimeout(() => props.onClickBack(true), 2000);
            }
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: `Error in adding goal : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      // setIsLoading(false);
      console.error("Error in add goals", error);
      setSnackBar({
        open: true,
        severity: "error",
        message: error,
      });
    }
  };
  const _updateGoal = async (data: any) => {
    try {
      const payload: updateGoalInfoV2Request = new updateGoalInfoV2Request();

      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultcontent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);
      titleLocale.setPa(data.title.pa);

      const descriptionLocale: Locale = new Locale();
      descriptionLocale.setDefaultcontent(data.description.defaultcontent);
      descriptionLocale.setEn(data.description.en);
      descriptionLocale.setHi(data.description.hi);
      descriptionLocale.setTe(data.description.te);
      descriptionLocale.setGu(data.description.gu);
      descriptionLocale.setTa(data.description.ta);
      descriptionLocale.setKa(data.description.ka);
      descriptionLocale.setMr(data.description.mr);
      descriptionLocale.setIt(data.description.it);
      descriptionLocale.setRu(data.description.ru);
      descriptionLocale.setEs(data.description.es);
      descriptionLocale.setFr(data.description.fr);
      descriptionLocale.setPt(data.description.pt);
      descriptionLocale.setDe(data.description.de);
      descriptionLocale.setBn(data.description.bn);
      descriptionLocale.setMl(data.description.ml);
      descriptionLocale.setUk(data.description.uk);
      descriptionLocale.setOd(data.description.od);
      descriptionLocale.setEnUs(data.description.enUs);
      descriptionLocale.setEnAu(data.description.enAu);
      descriptionLocale.setPa(data.description.pa);

      payload.setId(data.id);
      payload.setTitle(titleLocale);
      payload.setDescription(descriptionLocale);
      payload.setGoalcategory(data.goalCategory);
      payload.setRequiredidsList(data.requiredIDs);
      console.log("request payload for update goal", payload.toObject());

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.UpdateGoalInfoV2, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseGoalInfoV2>) => {
          const { status, message, statusMessage } = res;

          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("Update goal details response", message.toObject());
              dispatch(getAllGoalsDetails(language));
              setSnackBar({
                open: true,
                severity: "success",
                message: "goal updated successfully",
              });
              dispatch(getAllGoalsInfoV2(props.pageSize, props.pageNumber));
              setTimeout(() => props.onClickBack(true), 2000);
            }
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: `Error in updating goal:${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in update goals", error);
      setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

  const _deleteGoal = async (data: any) => {
    try {
      console.log("Delete goal request", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(data.ID);

      grpc.unary(GamificationService.DeleteGoalInfoV2ByID, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<DeleteGoalInfoV2ByIdResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("delete goal details response", message.toObject());

              setdeleteGoal({ showDeleteModal: false });
              dispatch(getAllGoalsDetails(language));
              if (props.isPageBackRequired && props.pageNumber) {
                dispatch(
                  getAllGoalsInfoV2(props.pageSize, props.pageNumber - 1)
                );
                props.setPageNumber(props.pageNumber - 1);
              } else {
                getAllGoalsInfoV2(props.pageSize, props.pageNumber);
              }
              setTimeout(() => props.onClickBack(true), 2000);
              setSnackBar({
                open: true,
                severity: "success",
                message: " Deleted goal successfully",
              });
            }
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: `error in  deleting Goal : ${statusMessage}`,
            });
            setdeleteGoal({ showDeleteModal: false });
          }
        },
      });
    } catch (err) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "error in  deleting Goal",
      });
      console.log(err);
    }
  };

  const validateGoal = () => {
    if (title.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "title can not be empty",
      });
    } else if (description.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "description can not be empty",
      });
    } else if (selectedGoals.length < 2) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "please select 2 atomic goals to be included",
      });
    } else {
      return true;
    }
  };

  const addGoal = () => {
    if (validateGoal()) {
      var goalData: any = {
        title: {
          ...localeObject,
          defaultcontent: title,
          en: title,
        },
        description: {
          ...localeObject,
          defaultcontent: description,
          en: description,
        },
        goalCategory: 10,
        associatedBadgeID: 7,
        requiredIDs: [...new Set(selectedGoals)].map((el) => el.goaltypes?.id),
      };
      _addGoals(goalData);
    }
  };

  const updateGoal = () => {
    if (validateGoal()) {
      var goalData: any = {
        title: {
          ...localeObject,
          defaultcontent: title,
          en: title,
        },
        description: {
          ...localeObject,
          defaultcontent: description,
          en: description,
        },
        id: props.editItem.goaltypes.id,
        goalCategory: 10,
        associatedBadgeID: 7,
        requiredIDs: [...new Set(selectedGoals)].map((el) => el.goaltypes?.id),
      };
      _updateGoal(goalData);
    }
  };
  return (
    <div ref={ref}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        className="p-2 align-items-center"
      >
        <Grid item>
          <Grid
            container
            className={props.isEditing ? "" : "align-items-center"}
          >
            <Grid item>
              <IconButton onClick={props.onClickBack} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {props.isEditing ? "Edit Goal" : "Create New Composite Goal"}
              </Typography>
              {props.isEditing ? (
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={props.onClickBack}
                  >
                    Goals
                  </Typography>
                  <Typography color="inherit" style={{ cursor: "pointer" }}>
                    {props.editItem.goaltypes.title.en}
                  </Typography>
                  <Typography color="textPrimary">Edit</Typography>
                </Breadcrumbs>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {props.isEditing ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => onClickDelete(goalId)}
              >
                Delete
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3} p={2}>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="title"
            label="Goal Title"
            variant="outlined"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            className="mr-3"
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
            disabled={props.editItem ? false : true}
          >
            <InputLabel id="language-select">Language</InputLabel>
            {languages && (
              <Select
                defaultValue=""
                labelId="language-select"
                value={language}
                label="Language"
                inputProps={{
                  readOnly: props.editItem ? false : true,
                }}
                onChange={(e) => {
                  setLanguage(e.target.value as number);
                }}
              >
                {languages.map((lang, i) => (
                  <MenuItem key={i} value={lang.code}>
                    {lang.displayName}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Grid>
            <TextField
              fullWidth
              required
              type="text"
              name="title"
              label="Goal Description"
              variant="outlined"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              className="mr-3"
            />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Card variant="outlined">
            <Grid
              container
              className="m-3"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  Selected Goals
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              {selectedGoals.length ? (
                [...new Set(selectedGoals)].map((item, index) => (
                  <Card
                    key={`a${index}`}
                    variant="outlined"
                    sx={{ mb: 1, p: 1 }}
                  >
                    <Grid container className="p-1" alignItems="center">
                      <Grid item xs={2} md={1}>
                        <Avatar
                          src={
                            item.badgewithcountList.length
                              ? item.badgewithcountList[0].icon
                              : item.badge?.icon
                          }
                        />
                      </Grid>
                      <Grid item xs={9} md={10}>
                        <Typography variant="body1">
                          {item.goaltypes?.title?.defaultcontent}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          size="large"
                          color="secondary"
                          onClick={() => {
                            removeSelectedGoal(item.goaltypes?.id);
                            console.log(item.goaltypes?.id);
                          }}
                        >
                          <RemoveCircleTwoToneIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Card>
                ))
              ) : (
                <Typography color="textSecondary">No Goals Selected</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                className="pl-3 pr-2 py-3"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => {
                  setexpand(!expand);
                }}
              >
                <Grid item>
                  <Typography color="textSecondary">
                    Choose Atomic Goals to be included
                  </Typography>
                </Grid>
                <Grid item>
                  {expand ? (
                    <ArrowDropUpIcon color="action" />
                  ) : (
                    <ArrowDropDownIcon color="action" />
                  )}
                </Grid>
              </Grid>
              {expand ? (
                <CardContent>
                  <Box
                    maxHeight={350}
                    style={{ overflowY: "auto" }}
                    //   className={style.scrollbar}
                  >
                    {state.goalsListV2.goals?.goaltypesList.length &&
                      state.goalsListV2.goals.goaltypesList
                        .filter((el) => el.goaltypes?.goalcategory !== 10)
                        .map((item, index) => (
                          <React.Fragment key={`a${index}`}>
                            <Grid container className="p-1" alignItems="center">
                              <Grid item xs={2} md={1}>
                                <Avatar
                                  src={
                                    item.badgewithcountList.length
                                      ? item.badgewithcountList[0].icon
                                      : item.badge?.icon
                                  }
                                />
                              </Grid>
                              <Grid item xs={9} md={10}>
                                <Typography variant="body1">
                                  {item.goaltypes?.title?.defaultcontent}
                                </Typography>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  size="large"
                                  color="primary"
                                  onClick={() => {
                                    selectedGoals.length < 2
                                      ? setSelectedGoals((prevGoals) => [
                                          ...prevGoals,
                                          item,
                                        ])
                                      : setSnackBar({
                                          open: true,
                                          severity: "error",
                                          message:
                                            "Please make sure the composite goal contains exactly 2 atomic goals",
                                        });
                                  }}
                                >
                                  <AddCircleTwoToneIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Divider />
                          </React.Fragment>
                        ))}
                  </Box>
                </CardContent>
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider />
      <CardActions className=" p-2 d-flex justify-content-end">
        <Box display="flex" justifyContent="flex-end">
          {props.isEditing ? (
            <>
              <Button
                onClick={props.onClickBack}
                color="primary"
                variant="contained"
                // disabled={!editGoal}
                className="mr-2"
              >
                cancel
              </Button>
              <Button
                onClick={() => {
                  updateGoal();
                }}
                color="primary"
                variant="contained"
              >
                save
              </Button>
            </>
          ) : (
            <Button
              onClick={() => {
                addGoal();
              }}
              color="primary"
              variant="contained"
            >
              Add Goal
            </Button>
          )}
        </Box>
      </CardActions>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <DialogBox
        open={deleteGoal.showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={deleteGoals}
        type={"delete"}
        title={`Sure !! Want to delete this Item ?`}
      />
    </div>
  );
});
