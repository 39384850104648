import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import { firebaseAuth } from "config/firebase";
import React, { useState, useEffect, useCallback, ReactElement } from "react";
import { Crop } from "react-image-crop";
import { useDispatch, useSelector } from "react-redux";
import { useFileUploadService } from "services/FileUplaodService";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DialogBox from "components/dialogBox/dialog";
import ImageCrop from "components/image-crop/image-crop";
import { ImageOutlined, MusicNoteOutlined } from "@mui/icons-material";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import DeleteIcon from "@mui/icons-material/Delete";
import { RootState } from "redux/store/store";
import {
  addBackground,
  deleteBackground,
  updateBackground,
} from "redux/actions/meditationBackgroundAction";
import AudioPlayer from "components/audio-player/audio-player";

const PREFIX = "BackgroundCategoryForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number;
}

interface FormProps {
  editing?: boolean;
  backgroundtype: 1 | 2;
  editItem?: any;
  onClickBack: any;
  lang:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21;
}

export const MeditationBackgroundForm: any = React.forwardRef<
  HTMLDivElement,
  FormProps
>((props, ref): ReactElement => {
  const dispatch = useDispatch();
  const languages = useSelector((state: RootState) => state.app.languages);
  const languageType = [
    "defaultcontent",
    "en",
    "hi",
    "te",
    "gu",
    "ta",
    "ka",
    "mr",
    "it",
    "ru",
    "es",
    "fr",
    "pt",
    "de",
    "bn",
    "ml",
    "uk",
    "od",
    "enUs",
    "enAu",
    "pa",
  ];
  const { appendFilesToUploadManager } = useFileUploadService();

  let cropDef: Crop = {
    x: 25,
    y: 25,
    height: 20,
    width: 20,
    unit: "%",
  };

  const initialState = {
    editing: false,
    editItem: null,
    showDeleteModal: false,
    deleteItemId: 0,
  };

  const hiddenFileInput: any = React.useRef(null);
  const hiddenFileInput2: any = React.useRef(null);
  const hiddenAudioInput: any = React.useRef(null);
  const [language, setLanguage] = useState<
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
  >(props.lang);
  const [states, setStates] = useState<State>(initialState);
  const [id, setId] = useState<any>(null);
  const [title, setTitle] = useState({
    defaultcontent: "",
    en: "",
    hi: "",
    te: "",
    gu: "",
    ta: "",
    ka: "",
    mr: "",
    it: "",
    ru: "",
    es: "",
    fr: "",
    pt: "",
    de: "",
    bn: "",
    ml: "",
    uk: "",
    od: "",
    enUs: "",
    enAu: "",
    pa: "",
  });

  const [url, setUrl] = useState<string>("");
  const [imageRef, setImageRef] = useState<any>();
  const [src, setSrc] = useState<any>(null);
  const [crop, setCrop] = useState<any>(cropDef);
  const [imageFile, setImageFile] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);

  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<string>("");
  const [thumbnailRef, setThumbnailRef] = useState<any>();
  const [thumbnailSrc, setWithoutBorderSrc] = useState<any>(null);
  const [thumbnailCrop, setThumbnailCrop] = useState<any>(cropDef);
  const [thumbnailFile, setThumbnailFile] = useState<any>(null);
  const [withoutBorderCroppedImage, setWithoutBorderCroppedImage] =
    useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isAdding, setIsAdding] = useState(false);

  const [open, setopen] = useState(false);

  const [isUploading, setIsUploading] = useState({
    image: false,
    thumbnail: false,
    audio: false,
  });

  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const onClickEdit = useCallback(
    (item: any) => {
      console.log(item);

      setStates({ editing: true });
      //setTitle(item.title[languageType[props.lang]]);
      setTitle((x) => ({
        defaultcontent: item.title.defaultcontent,
        en: item.title.en,
        hi: item.title.hi ? item.title.hi : item.title.defaultcontent,
        te: item.title.te ? item.title.te : item.title.defaultcontent,
        gu: item.title.gu ? item.title.gu : item.title.defaultcontent,
        ta: item.title.ta ? item.title.ta : item.title.defaultcontent,
        ka: item.title.ka ? item.title.ka : item.title.defaultcontent,
        mr: item.title.mr ? item.title.mr : item.title.defaultcontent,
        it: item.title.it ? item.title.it : item.title.defaultcontent,
        ru: item.title.ru ? item.title.ru : item.title.defaultcontent,
        es: item.title.es ? item.title.es : item.title.defaultcontent,
        fr: item.title.fr ? item.title.fr : item.title.defaultcontent,
        pt: item.title.pt ? item.title.pt : item.title.defaultcontent,
        de: item.title.de ? item.title.de : item.title.defaultcontent,
        bn: item.title.bn ? item.title.bn : item.title.defaultcontent,
        ml: item.title.ml ? item.title.ml : item.title.defaultcontent,
        uk: item.title.uk ? item.title.uk : item.title.defaultcontent,
        od: item.title.od ? item.title.od : item.title.defaultcontent,
        enUs: item.title.enUs ? item.title.enUs : item.title.defaultcontent,
        enAu: item.title.enAu ? item.title.enAu : item.title.defaultcontent,
        pa: item.title.pa ? item.title.pa : item.title.defaultcontent,
      }));
      setUrl(item.url);
      setThumbnailImageUrl(item.thumbnailimgurl);
      setId(item.id);
      setLanguage(props.lang);
    },
    [props.lang]
  );

  useEffect(() => {
    if (props.editing) {
      onClickEdit(props.editItem);
    }
  }, [props.editing, props.editItem, onClickEdit]);

  useEffect(() => {}, [props.editItem]);

  const _deleteFileFromGcp = async (data: any) => {
    try {
      console.log("image url to delete from gcp", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: DeleteFileRequest = new DeleteFileRequest();
      reqBody.setImgpath(data);
      grpc.unary(GamificationService.DeleteFileFromGCP, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "data coming from delete file from gcp",
                message.toObject()
              );
            }
          } else {
            setIsLoading(false);
          }
        },
      });
    } catch (err) {
      console.log("Error ", err);
    }
  };

  const _onOpenImage = () => {
    setopen(true);
  };
  const _onCloseImage = () => {
    setopen(false);
    setIsUploading({
      ...isUploading,
      image: false,
      thumbnail: false,
    });
  };

  // --------------------------image upload--------------------------------------->
  const handleUploadClick = () => {
    hiddenFileInput.current.click();
  };
  const onSelectImage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, image: true });
      setImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setSrc(reader.result);
        setopen(true);
      };
    }
  };
  const onImageLoaded = (image: any) => {
    setImageRef(image);
  };

  const onCropDone = async () => {
    const cr: any = crop;
    if (imageRef && cr.width && cr.height) {
      const croppedUrl: any = await getCroppedImg(imageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
          " ",
          ""
        )}`
      );
      setUrl(croppedUrl);
      const uploadFile = toUploadFile(
        croppedFile,
        `${
          language === 1
            ? "backgrounds/category-images"
            : `backgrounds/category-images/${languages[language - 1].displayName
                .toLowerCase()
                .replaceAll(" ", "")}`
        }`
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, image: false });
          } else {
            setIsUploading({ ...isUploading, image: false });
          }
        }
      );
      setCroppedImage(croppedFile);
    }
    setSrc(null);
  };

  const onCropChange = (crop: any) => {
    setCrop(crop);
  };
  const onCropComplete = async () => {};

  const onImageClose = () => {
    setSrc(null);
    setImageFile(null);
    setCroppedImage(null);
    setCrop(cropDef);
    _deleteFileFromGcp(url);
    setUrl("");
  };

  const handleThumbnailUploadClick = () => {
    hiddenFileInput2.current.click();
  };
  const onThumbnailSelectImage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, thumbnail: true });
      setThumbnailFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setWithoutBorderSrc(reader.result);
        setopen(true);
      };
    }
  };
  const onThumbnailLoaded = (image: any) => {
    setThumbnailRef(image);
  };

  const onThumbnailCropDone = async () => {
    const cr: any = thumbnailCrop;
    if (thumbnailRef && cr.width && cr.height) {
      const croppedUrl: any = await getCroppedImg(thumbnailRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date().getTime().toString()}_${thumbnailFile.name.replaceAll(
          " ",
          ""
        )}`
      );
      setThumbnailImageUrl(croppedUrl);
      const uploadFile = toUploadFile(
        croppedFile,
        `${
          language === 1
            ? "backgrounds/category-images/without-border-image"
            : `backgrounds/category-images/without-border-image/${languages[
                language - 1
              ].displayName
                .toLowerCase()
                .replaceAll(" ", "")}`
        }`
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setThumbnailImageUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, thumbnail: false });
          } else {
            setIsUploading({ ...isUploading, thumbnail: false });
          }
        }
      );
      setWithoutBorderCroppedImage(croppedFile);
    }
    setWithoutBorderSrc(null);
  };

  const onThumbnailCropChange = (crop: any) => {
    setThumbnailCrop(crop);
  };
  const onThumbnailCropComplete = async () => {};

  const onThumbnailClose = () => {
    setWithoutBorderSrc(null);
    setThumbnailFile(null);
    setWithoutBorderCroppedImage(null);
    setThumbnailCrop(cropDef);
    _deleteFileFromGcp(thumbnailImageUrl);
    setThumbnailImageUrl("");
  };

  //---------------------------audio upload------------------------------------->

  const handleAudioClick = () => {
    hiddenAudioInput.current.click();
  };

  const onAudioSelection = async (event: any) => {
    setIsUploading({ ...isUploading, audio: true });
    const data = new File(
      [event.target.files[0]],
      event.target.files[0].name.replaceAll(" ", "")
    );
    const uploadFile = toUploadFile(
      data,
      "MeditateNow/audios".replaceAll(" ", "")
    );
    appendFilesToUploadManager([uploadFile]);
    window.addEventListener(
      uploadFile.token,
      (res: CustomEventInit<UploadEventResponse>) => {
        if (res.detail?.code === 200) {
          setUrl(res.detail.response);
          setIsUploading({ ...isUploading, audio: false });
        } else {
          setIsUploading({ ...isUploading, audio: false });
        }
      }
    );
  };

  const onAudioClose = () => {
    setUrl("");
  };

  //---------------------------audio upload end------------------------------------->

  const validateForm = () => {
    if (title[languageType[language - 1]].trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Title can not be empty",
      });
    } else if (url === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: `${
          props.backgroundtype === 1 ? "Image" : "Audio"
        } can not be empty`,
      });
    } else if (props.backgroundtype === 2) {
      if (thumbnailImageUrl === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Thumbnail Image can not be empty",
        });
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const updateBackgroundHandler = () => {
    if (validateForm()) {
      if (props.editing) {
        setIsAdding(true);
        dispatch(
          updateBackground({
            id,
            title,
            url,
            thumbnailImageUrl,
            setSnackBar,
            backgroundType: props.backgroundtype,
          })
        );
      } else {
        setIsAdding(true);
        dispatch(
          addBackground({
            title,
            url,
            thumbnailImageUrl,
            setSnackBar,
            backgroundType: props.backgroundtype,
          })
        );
      }
      setTimeout(props.onClickBack, 1500);
    }
  };

  const onClickDelete = (backgroundId: number) => {
    setStates({ showDeleteModal: true, deleteItemId: backgroundId });
  };

  const handleDeleteClose = () => {
    setStates({ showDeleteModal: false });
  };

  const deleteBackgroundHandler = async () => {
    dispatch(
      deleteBackground({ id: Number(states.deleteItemId), setSnackBar })
    );

    setTimeout(props.onClickBack, 1500);
  };

  return (
    <Root ref={ref} className={classes.root}>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", right: "50%" }}
        />
      )}

      <Grid
        container
        className="p-2 align-items-center"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid container alignItems="center">
            <Grid>
              <IconButton onClick={props.onClickBack} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid>
              <Typography variant="h6">
                {props.editing ? "Edit Background" : "Add New Background"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {props.editing ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => onClickDelete(props.editItem.id)}
                disabled={id === 4 || id === 8}
              >
                Delete
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              label="Title"
              name="title"
              required
              value={title[languageType[language - 1]]}
              variant="outlined"
              onChange={(e) => {
                // console.log("props.lang", props.lang, language);
                // console.log("title", title);
                props.editing
                  ? setTitle({
                      //...title
                      defaultcontent:
                        props.lang - 1 === 0 || props.lang - 1 === 1
                          ? e.target.value
                          : props.editItem.title.defaultcontent,
                      en:
                        props.lang - 1 === 1 || props.lang - 1 === 0
                          ? e.target.value
                          : props.editItem.title.en,
                      hi:
                        props.lang - 1 === 2
                          ? e.target.value
                          : props.editItem.title.hi,
                      te:
                        props.lang - 1 === 3
                          ? e.target.value
                          : props.editItem.title.te,
                      gu:
                        props.lang - 1 === 4
                          ? e.target.value
                          : props.editItem.title.gu,
                      ta:
                        props.lang - 1 === 5
                          ? e.target.value
                          : props.editItem.title.ta,
                      ka:
                        props.lang - 1 === 6
                          ? e.target.value
                          : props.editItem.title.ka,
                      mr:
                        props.lang - 1 === 7
                          ? e.target.value
                          : props.editItem.title.mr,
                      it:
                        props.lang - 1 === 8
                          ? e.target.value
                          : props.editItem.title.it,
                      ru:
                        props.lang - 1 === 9
                          ? e.target.value
                          : props.editItem.title.ru,
                      es:
                        props.lang - 1 === 10
                          ? e.target.value
                          : props.editItem.title.es,
                      fr:
                        props.lang - 1 === 11
                          ? e.target.value
                          : props.editItem.title.fr,
                      pt:
                        props.lang - 1 === 12
                          ? e.target.value
                          : props.editItem.title.pt,
                      de:
                        props.lang - 1 === 13
                          ? e.target.value
                          : props.editItem.title.de,
                      bn:
                        props.lang - 1 === 14
                          ? e.target.value
                          : props.editItem.title.bn,
                      ml:
                        props.lang - 1 === 15
                          ? e.target.value
                          : props.editItem.title.ml,
                      uk:
                        props.lang - 1 === 16
                          ? e.target.value
                          : props.editItem.title.uk,
                      od:
                        props.lang - 1 === 17
                          ? e.target.value
                          : props.editItem.title.od,
                      enUs:
                        props.lang - 1 === 18
                          ? e.target.value
                          : props.editItem.title.enUs,
                      enAu:
                        props.lang - 1 === 19
                          ? e.target.value
                          : props.editItem.title.enAu,
                      pa:
                        props.lang - 1 === 20
                          ? e.target.value
                          : props.editItem.title.pa,
                    })
                  : setTitle((x) => ({
                      ...x,
                      [languageType[0]]: e.target.value,
                      [languageType[language - 1]]: e.target.value,
                    }));
              }}
            />
          </Grid>
          {props.backgroundtype === 1 && (
            <Grid item xs={4}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined">
                  {url ? (
                    <PreviewProfileImage
                      onImageClose={onImageClose}
                      src={url}
                      file={croppedImage}
                    />
                  ) : (
                    <>
                      {isUploading.image ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.root}
                          onClick={() => {
                            handleUploadClick();
                          }}
                        >
                          {/* <InputLabel htmlFor="thumbnail">
                          Upload image
                        </InputLabel> */}
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <ImageOutlined />
                          </Avatar>
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            hidden
                            onChange={onSelectImage}
                            ref={hiddenFileInput}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Image
                </Typography>
              </FormControl>
            </Grid>
          )}
          {props.backgroundtype === 2 && (
            <>
              <Grid item md={4} xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <Card variant="outlined" className={classes.root}>
                    {url ? (
                      <>
                        <AudioPlayer src={url} onAudioClose={onAudioClose} />
                      </>
                    ) : (
                      <>
                        {isUploading.audio === true ? (
                          <Card className={classes.thumbNail}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="pt-5"
                            >
                              <Typography className="pt-4">
                                Uploading...
                              </Typography>
                            </Grid>
                          </Card>
                        ) : (
                          <CardActionArea
                            className={classes.thumbNail}
                            onClick={() => {
                              handleAudioClick();
                            }}
                          >
                            {/* <InputLabel htmlFor="thumbnail">
                          Upload Audio
                        </InputLabel> */}
                            <Avatar
                              className={classes.actionIcon}
                              variant="square"
                            >
                              <MusicNoteOutlined />
                            </Avatar>
                            <input
                              accept="audio/*"
                              id="audioFile"
                              type="file"
                              hidden
                              onChange={onAudioSelection}
                              ref={hiddenAudioInput}
                            />
                          </CardActionArea>
                        )}
                      </>
                    )}
                  </Card>
                  <Typography variant="overline" align="center" gutterBottom>
                    Audio
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth>
                  <Card variant="outlined">
                    {thumbnailImageUrl ? (
                      <PreviewProfileImage
                        onImageClose={onThumbnailClose}
                        src={thumbnailImageUrl}
                        file={withoutBorderCroppedImage}
                      />
                    ) : (
                      <>
                        {isUploading.thumbnail ? (
                          <Card className={classes.thumbNail}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="pt-5"
                            >
                              <Typography className="pt-4">
                                Uploading...
                              </Typography>
                            </Grid>
                          </Card>
                        ) : (
                          <CardActionArea
                            className={classes.root}
                            onClick={() => {
                              handleThumbnailUploadClick();
                            }}
                          >
                            {/* <InputLabel htmlFor="thumbnail">
                          Upload image
                        </InputLabel> */}
                            <Avatar
                              className={classes.actionIcon}
                              variant="square"
                            >
                              <ImageOutlined />
                            </Avatar>
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              type="file"
                              hidden
                              onChange={onThumbnailSelectImage}
                              ref={hiddenFileInput2}
                            />
                          </CardActionArea>
                        )}
                      </>
                    )}
                  </Card>
                  <Typography variant="overline" align="center" gutterBottom>
                    Thumbnail Image
                  </Typography>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          disabled={isAdding}
          onClick={() => {
            updateBackgroundHandler();
          }}
        >
          {props.editing ? "Update Background" : "Add Background"}
        </Button>
      </Box>
      {src && (
        <ImageCrop
          src={src.toString()}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onCropComplete={onCropComplete}
          onCropChange={onCropChange}
          onPressDone={onCropDone}
          onImageClose={onImageClose}
          open={open}
          onOpen={_onOpenImage}
          onClose={_onCloseImage}
        />
      )}
      {thumbnailSrc && (
        <ImageCrop
          src={thumbnailSrc.toString()}
          crop={thumbnailCrop}
          onImageLoaded={onThumbnailLoaded}
          onCropComplete={onThumbnailCropComplete}
          onCropChange={onThumbnailCropChange}
          onPressDone={onThumbnailCropDone}
          onImageClose={onThumbnailClose}
          open={open}
          onOpen={_onOpenImage}
          onClose={_onCloseImage}
        />
      )}
      <DialogBox
        open={states.showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={deleteBackgroundHandler}
        type={"delete"}
        title={`Sure !! Want to delete this Item ?`}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Root>
  );
});
