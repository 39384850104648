import { colors } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

/**
 * @author : Godwin Vinny Carole Kati
 * @created : 2021-04-28 02:48:38
 * @organisation : Codeprism Technologies Pvt Ltd
 **/

export const useUploadManagerStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  heading: {
    color: 'white',
    fontSize: theme.typography.pxToRem(15),
    padding:0,
    margin:0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    background: theme.palette.background.default,
    alignItems: "center",
    padding: 0,
    "& ul": {
      maxHeight: 400,
      width: "100%",
      overflow: "auto",
    },
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  // managerContainer: {
  //    position: "fixed",
  //   right: 10,
  //   bottom: 0,
  //   maxWidth: 360,
  //   minWidth:360,
  
  // },
  accordian: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  accordianSummary: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    maxHeight:'40px !important',
    minHeight:'30px !important'
  },
  successCheckIcon: {
    color: colors.green[400]
  }
  
  
}));