import {
  Modal,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactChild, ReactChildren, ReactElement } from "react";

const PREFIX = "dialog";

const classes = {
  paper: `${PREFIX}-paper`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    position: "absolute",
    // width: 400,
    minWidth: "50vw",
    backgroundColor: theme.palette.background.paper,
    // padding: theme.spacing(2, 4, 3),
    padding: 18,
    top: `25vh`,
    left: `25vw`,
    right: `25vw`,
  },
}));

interface DialogBoxProps {
  open: boolean;
  onClose: any;
  onCancel?: any;
  onConfirm?: any;
  type?: string;
  children?: ReactChild | ReactChildren | any;
  title?: string;
}

export default function DialogBox(props: DialogBoxProps): ReactElement {
  const deleteDialog = () => {
    return (
      <Card className={classes.paper}>
        <CardActionArea>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="h2"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {props.title}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button onClick={props.onCancel} variant="outlined" color="primary">
            {props.type === "delete firebase" || props.type === "cancel"
              ? "No"
              : "Cancel"}
          </Button>
          <Button
            onClick={() => {
              props.onConfirm();
            }}
            variant="outlined"
            color="secondary"
          >
            {props.type === "delete firebase" || props.type === "cancel"
              ? "Yes"
              : " Delete"}
          </Button>
        </CardActions>
      </Card>
    );
  };

  return (
    <StyledModal
      open={props.open || false}
      onClose={props.onClose}
      // aria-labelledby="simple-modal-title"
      // aria-describedby="simple-modal-description"
    >
      {props.type === "custom" ? props.children : deleteDialog()}
    </StyledModal>
  );
}
