import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ReactElement, useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";

import { ListSkeleton } from "components/listSkeleton";
import RefreshIcon from "@mui/icons-material/Refresh";
import style from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { PreRecordedVideosForm } from "./preRecordedVideosForm";
import { getAllPreRecordedVideos } from "redux/actions/groupsAction";
import { MenuProps } from "styles/global.styles";
import { languageType } from "config/language";

interface State {
  editing?: boolean;
  editItem?: any;
  tagList?: any;
  prerecordedtype?: number | null;
}

export default function PreRecordedVideosScreen(): ReactElement {
  const dispatch = useDispatch();

  const preRecordedVideoList = useSelector(
    (state: RootState) => state.groups.videosList
  );

  const initialState: State = {
    editing: false,
    editItem: null,
    tagList: null,
  };

  const languages = useSelector((state: RootState) => state.app.languages);
  const [language, setLanguage] = useState<any>(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [states, setStates] = useState<State>(initialState);
  const [searchVideo, setSearchVideo] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSearchVideo("");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setSearchVideo("");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onClickBack = () => {
    setCurrentIndex(0);
    setSearchVideo("");
    setStates({
      editing: false,
      editItem: null,
      tagList: null,
      prerecordedtype: null,
    });
  };

  useEffect(() => {
    dispatch(getAllPreRecordedVideos(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const setLocalisedContent = (item: any) => {
    switch (language) {
      case 1:
        return {
          id: item.id,
          title: item.title.defaultcontent,
          description: item.description.defaultcontent,
          meditationsteps: item.meditationsteps.defaultcontent,
          videourl: item.videourl.defaultcontent,
          attribute: item.attribute.defaultcontent,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 2:
        return {
          id: item.id,
          title: item.title.en,
          description: item.description.en,
          meditationsteps: item.meditationsteps.en,
          videourl: item.videourl.en,
          attribute: item.attribute.en,

          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 3:
        return {
          id: item.id,
          title: item.title.hi,
          description: item.description.hi,
          meditationsteps: item.meditationsteps.hi,
          videourl: item.videourl.hi,
          attribute: item.attribute.hi,

          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 4:
        return {
          id: item.id,
          title: item.title.te,
          description: item.description.te,
          meditationsteps: item.meditationsteps.te,
          videourl: item.videourl.te,
          attribute: item.attribute.te,

          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 5:
        return {
          id: item.id,
          title: item.title.gu,
          description: item.description.gu,
          meditationsteps: item.meditationsteps.gu,
          videourl: item.videourl.gu,
          attribute: item.attribute.gu,

          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 6:
        return {
          id: item.id,
          title: item.title.ta,
          description: item.description.ta,
          meditationsteps: item.meditationsteps.ta,
          videourl: item.videourl.ta,
          attribute: item.attribute.ta,

          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 7:
        return {
          id: item.id,
          title: item.title.ka,
          description: item.description.ka,
          meditationsteps: item.meditationsteps.ka,
          videourl: item.videourl.ka,
          attribute: item.attribute.ka,

          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 8:
        return {
          id: item.id,
          title: item.title.mr,
          description: item.description.mr,
          meditationsteps: item.meditationsteps.mr,
          videourl: item.videourl.mr,
          attribute: item.attribute.mr,

          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 9:
        return {
          id: item.id,
          title: item.title.it,
          description: item.description.it,
          meditationsteps: item.meditationsteps.it,
          videourl: item.videourl.it,
          attribute: item.attribute.it,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 10:
        return {
          id: item.id,
          title: item.title.ru,
          description: item.description.ru,
          meditationsteps: item.meditationsteps.ru,
          videourl: item.videourl.ru,
          attribute: item.attribute.ru,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 11:
        return {
          id: item.id,
          title: item.title.es,
          description: item.description.es,
          meditationsteps: item.meditationsteps.es,
          videourl: item.videourl.es,
          attribute: item.attribute.es,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 12:
        return {
          id: item.id,
          title: item.title.fr,
          description: item.description.fr,
          meditationsteps: item.meditationsteps.fr,
          videourl: item.videourl.fr,
          attribute: item.attribute.fr,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 13:
        return {
          id: item.id,
          title: item.title.pt,
          description: item.description.pt,
          meditationsteps: item.meditationsteps.pt,
          videourl: item.videourl.pt,
          attribute: item.attribute.pt,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 14:
        return {
          id: item.id,
          title: item.title.de,
          description: item.description.de,
          meditationsteps: item.meditationsteps.de,
          videourl: item.videourl.de,
          attribute: item.attribute.de,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 15:
        return {
          id: item.id,
          title: item.title.bn,
          description: item.description.bn,
          meditationsteps: item.meditationsteps.bn,
          videourl: item.videourl.bn,
          attribute: item.attribute.bn,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 16:
        return {
          id: item.id,
          title: item.title.ml,
          description: item.description.ml,
          meditationsteps: item.meditationsteps.ml,
          videourl: item.videourl.ml,
          attribute: item.attribute.ml,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 17:
        return {
          id: item.id,
          title: item.title.uk,
          description: item.description.uk,
          meditationsteps: item.meditationsteps.uk,
          videourl: item.videourl.uk,
          attribute: item.attribute.uk,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 18:
        return {
          id: item.id,
          title: item.title.od,
          description: item.description.od,
          meditationsteps: item.meditationsteps.od,
          videourl: item.videourl.od,
          attribute: item.attribute.od,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 19:
        return {
          id: item.id,
          title: item.title.enUs,
          description: item.description.enUs,
          meditationsteps: item.meditationsteps.enUs,
          videourl: item.videourl.enUs,
          attribute: item.attribute.enUs,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
      case 20:
        return {
          id: item.id,
          title: item.title.enAu,
          description: item.description.enAu,
          meditationsteps: item.meditationsteps.enAu,
          videourl: item.videourl.enAu,
          attribute: item.attribute.enAu,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
        case 21:
        return {
          id: item.id,
          title: item.title.pa,
          description: item.description.pa,
          meditationsteps: item.meditationsteps.pa,
          videourl: item.videourl.pa,
          attribute: item.attribute.pa,
          defaultContent: {
            title: item.title.defaultcontent,
            description: item.description.defaultcontent,
            meditationsteps: item.meditationsteps.defaultcontent,
            videourl: item.videourl.defaultcontent,
            attribute: item.attribute.defaultcontent,
          },

          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
          duration: item.videoduration,
          videoType: item.prerecordedtype,
        };
    }
  };

  const setVideoType = (data: any) => {
    switch (data) {
      case 0:
        return "Groups";
      case 1:
        return "Circles";
      case 2:
        return "Live Session";
      default:
        return "Unkown";
    }
  };

  const renderVideosList = (data: any) => {
     console.log("data", data);
    return (
      <div>
        {data.length ? (
          <>
            <Grid
              style={{ backgroundColor: "transparent" }}
              className="d-flex py-2 pl-3 align-items-center"
            >
              <Grid className="d-flex flex-grow-1">
                <TextField
                  type="search"
                  value={searchVideo}
                  fullWidth
                  variant="outlined"
                  placeholder="Search video by title"
                  size="small"
                  onChange={(e) => {
                    setSearchVideo(e.target.value);
                    const filteredData = data.filter((item: any) =>
                      setLocalisedContent(item)
                        ?.title.toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    setsearchData(filteredData);
                  }}
                  className="mr-2"
                />
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    dispatch(getAllPreRecordedVideos(page, rowsPerPage));
                  }}
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer
              style={{ maxHeight: "400px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left">Id</TableCell>
                    <TableCell
                      align="left"
                      // sx={{
                      //   width: "50%",
                      // }}
                    >
                      Title
                    </TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">Duration (in seconds)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchVideo !== "" ? searchData : data).map(
                    (item: any, x: number) => {
                      return (
                        <React.Fragment key={x}>
                          <TableRow
                            sx={{ cursor: "pointer", height: "50px" }}
                            hover
                            onClick={() => {
                              setStates({
                                editing: true,
                                editItem: setLocalisedContent(item),
                                tagList: item.tagsList,
                                prerecordedtype: item.prerecordedtype,
                              });
                              setCurrentIndex(1);
                              console.log("item", item);
                            }}
                          >
                            <TableCell align="center">
                              <Avatar
                                alt={
                                  item.title[languageType[language]]
                                    ? item.title[languageType[language]]
                                    : "NOT AVAILABLE IN THIS LANGUAGE"
                                  // : item.title[languageType[1]]
                                }
                                src={item.imgurl}
                              />
                            </TableCell>
                            <TableCell align="center">
                              {item.id}
                            </TableCell>
                            <TableCell align="left">
                              {
                                item.title[languageType[language]] ? (
                                  item.title[languageType[language]]
                                ) : (
                                  <Chip
                                    variant="filled"
                                    label="Content not set for this language"
                                  />
                                )
                                // : item.title[languageType[1]]
                              }
                            </TableCell>
                            <TableCell align="center">
                              {setVideoType(item.prerecordedtype)}
                            </TableCell>
                            <TableCell align="center">
                              {item.videoduration}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={preRecordedVideoList.content.pageable?.totalcount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No videos to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Card className="mt-3 mb-5">
            <Grow
              in={currentIndex === 0}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 0 ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid container className="align-items-center p-3" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">Videos</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setCurrentIndex(1);
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add Video
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <FormControl variant="outlined" className="m-3" fullWidth>
                    <InputLabel id="language-select">Language</InputLabel>
                    {languages && (
                      <Select
                        defaultValue=""
                        labelId="language-select"
                        value={language}
                        label="Language"
                        onChange={(e) => {
                          setLanguage(e.target.value as number);
                        }}
                        MenuProps={MenuProps}
                      >
                        {languages.map((lang: any, i: any) => (
                          <MenuItem key={i} value={lang.code}>
                            {lang.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    {preRecordedVideoList.loading
                      ? ListSkeleton()
                      : renderVideosList(
                          preRecordedVideoList.content.prerecordedsList
                        )}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
            <Grow
              in={currentIndex === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 1 ? { timeout: 1000 } : {})}
            >
              <PreRecordedVideosForm
                isEditing={states.editing}
                editItem={states.editItem}
                tags={states.tagList}
                prerecordedtype={states.prerecordedtype}
                onClickBack={onClickBack}
                lang={language}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
