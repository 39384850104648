import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProgramDefaultState {
    tasksList: {
        loading: boolean;
        tasks?: any;
    };
    themeList: {
        loading: boolean;
        themes?: any;
    },
    programId?: number;
};

const initialState: ProgramDefaultState = {
    tasksList: {
        loading: true,
        tasks: [],
    },
    themeList: {
        loading: true,
        themes: [],
    },
    programId: 0
};

export const programSlice = createSlice({
    name: "programList",
    initialState,
    reducers: {
        taskLoading: (state) => {
            state.tasksList.loading = true;
        },
        taskUpdate: (state, action: PayloadAction<any[]>) => {
            state.tasksList.loading = false;
            state.tasksList.tasks = action.payload;
        },
        taskError: (state) => {
            state.tasksList.loading = false;
        },
        themeLoading: (state) => {
            state.themeList.loading = true;
        },
        themeUpdate: (state, action: PayloadAction<any[]>) => {
            state.themeList.loading = false;
            state.themeList.themes = action.payload;
        },
        themeError: (state) => {
            state.themeList.loading = false;
        },
        setProgramId : (state, action: PayloadAction<number>) => {
            state.programId = action.payload;
        }
    }
});

export const {
    taskLoading,
    taskUpdate,
    taskError,
    themeLoading,
    themeUpdate,
    themeError,
    setProgramId
} = programSlice.actions;

export default combineReducers({
    programList: programSlice.reducer
})