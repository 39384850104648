import { Card, IconButton, Theme, Grid } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import React, { ReactElement } from "react";
import VideoPlayer from "utils/video";

interface AudioPlayerProps {
  onAudioClose?: any;
  // onEnded: any;
  src: string;
  audioTitle?: string;
  style?: any;
  type?: string;
}

export default function VideoPlayers(props: AudioPlayerProps): ReactElement {
  console.log("video url ", props.src);
  const playerClasses = playerStyles();

  const urlType: string = props.src.substring(
    props.src.lastIndexOf(".") + 1,
    props.src.length
  );
  const url = urlType.toString();
  //console.log('url',url);

  const videoJsOptions = {
    autoplay: false,
    width: 280,
    height: 200,
    controls: true,
    // responsive: true,
    sources: [
      {
        src: `${props.src}`,
        type: url === "m3u8" ? "application/x-mpegURL" : `video/${url}`,
        //type:'video/mp4'
      },
    ],
  };

  return (
    <Card className={playerClasses.root}>
      {props.type === "update" ? null : (
        <IconButton
          aria-label="settings"
          onClick={() => {
            props.onAudioClose();
          }}
          style={{
            position: "absolute",
            right: "-10px",
            top: "-10px",
            backgroundColor: "#CAC4C4",
            zIndex: 10,
          }}
          size="small"
        >
          <Close fontSize="small" />
        </IconButton>
      )}
      <Grid
        container
        justifyContent="center"
        className="pt-3"
        position={"relative"}
        zIndex={"0"}
      >
        {/* {props.src } */}
        <div key={props.src}>
          <VideoPlayer {...videoJsOptions} />
        </div>
        {/* <video width="320" height="240" controls>
                    <source src={props.src} type="video/mp4" />
                   </video> */}
      </Grid>
    </Card>
  );
}

const playerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      //position: "relative",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      marginTop: "20%",
      width: "100%",
    },
    content: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    cover: {
      width: 151,
    },
    controls: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    closeBtn: {
      alignSelf: "flex-end",
    },
    header: {
      width: "100%",
      height: "20%",
    },
  })
);
