import React, { useState } from "react";
import { Alert, Paper, Snackbar, styled } from "@mui/material";
import CarouselList from "./CarouselList";
import { RootState } from "redux/store/store";
import { useSelector } from "react-redux";

const StyledPaper = styled(Paper)({
  marginTop: "5vh",
  height: "78vh",
  padding: 10,
});
export default function LayoutEditor() {
  const carouselsList = useSelector(
    (state: RootState) => state.layout.layoutManager.carousels.carouselList
  );
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  return (
    <StyledPaper elevation={1}>
      <CarouselList data={carouselsList} setSnackBar={setSnackBar} />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </StyledPaper>
  );
}
