import React, { ReactElement, useState } from "react";
import { styled } from "@mui/styles";
import {
  Alert,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch } from "react-redux";
import { bulkUploadQuote } from "redux/actions/quoteActions";

interface QuotesProps {
  onClickBack: any;
  getAllQuoteData: any;
}

const PREFIX = "BlogsForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

export const BulkUploadForm = React.forwardRef<HTMLDivElement, QuotesProps>(
  (props, ref): ReactElement => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [link, setLink] = useState("");
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const linkChangeHandler = (e) => {
      setLink(e.target.value);
    };

    const validateForm = () => {
      const regEx = /https:\/\/docs\.google\.com\/(.*)\/d\/(.*?)/g;
      if (link.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "link can not be empty.",
        });
      } else if (!regEx.test(link)) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "please enter valid link",
        });
      } else {
        return true;
      }
    };

    const bulkUploadHandler = () => {
      if (validateForm()) {
        setIsLoading(true);
        const temp = link.slice(0, link.indexOf("edit"));

        const genratedLink =
          temp +
          (temp.charAt(temp.length - 1) === "/"
            ? "gviz/tq?tqx=out:csv"
            : "/gviz/tq?tqx=out:csv");

        dispatch(
          bulkUploadQuote({
            link: genratedLink,
            getAllQuoteData: props.getAllQuoteData,
            setSnackBar,
          })
        );

        setTimeout(() => {
          setIsLoading(false);
          props.onClickBack();
        }, 2000);
      }
    };

    return (
      <>
        <Root ref={ref} className={classes.root}>
          {isLoading && (
            <CircularProgress
              style={{ position: "absolute", top: "50%", right: "50%" }}
            />
          )}
          <Grid
            container
            className="p-2 align-items-center"
            justifyContent="space-between"
          >
            <Grid item>
              <Grid container alignItems="center">
                <Grid>
                  <IconButton onClick={props.onClickBack} size="large">
                    <KeyboardBackspaceIcon color="action" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h6">Upload Quotes</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="link"
                  label="Link"
                  variant="outlined"
                  value={link}
                  onChange={linkChangeHandler}
                  className="mr-3"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box p={2} display="flex" justifyContent="flex-end">
            <Button
              className={classes.formBtn}
              onClick={props.onClickBack}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              className={classes.formBtn}
              onClick={bulkUploadHandler}
              color="primary"
              variant="contained"
            >
              Upload
            </Button>
          </Box>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={10000}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              severity={snackBar.severity}
              onClose={() => {
                setSnackBar({ ...snackBar, open: false });
              }}
            >
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Root>
      </>
    );
  }
);
