import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  RequestPageable,
  ListOfQuoteDetails,
  AddQuoteRequest,
  QuoteDetails,
  ID,
  Status,
  URLRequest,
  BulkUploadQuoteResponse,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { AppThunk } from "redux/store/store";
import { setQuoteListLoading, quoteList } from "redux/reducers/quoteReducer";
import { Locale } from "api/models/gamification-service/common/common_pb";
interface addQuote {
  author: any;
  quote: any;
  tagone: string;
  tagtwo: string;
  tagthree: string;
  screen: string;
  setSnackBar: any;
  getAllQuoteData: getAllQuote;
}

interface updateQuote {
  id: number;
  author: any;
  quote: any;
  tagone: string;
  tagtwo: string;
  tagthree: string;
  screen: string;
  setSnackBar: any;
  getAllQuoteData: getAllQuote;
}

interface deleteQuote {
  id: number;
  setSnackBar: any;
  getAllQuoteData: getAllQuote;
}

interface bulkUpload {
  link: string;
  setSnackBar: any;
  getAllQuoteData: getAllQuote;
}

export interface getAllQuote {
  pageSize: number;
  pageNumber: number;
}

export const getAllQuoteDetails =
  (data: getAllQuote): AppThunk =>
  async (dispatch) => {
    dispatch(setQuoteListLoading(true));
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody = new RequestPageable();
    reqBody.setPagesize(data.pageSize);
    reqBody.setPagenumber(data.pageNumber);
    grpc.unary(GamificationService.GetAllQuotes, {
      host: GAMIFICATION_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<ListOfQuoteDetails>) => {
        const { status, message, statusMessage } = res;
        if (status === grpc.Code.OK && message) {
          dispatch(setQuoteListLoading(false));
          dispatch(quoteList(message.toObject()));
        } else {
          console.log(`Error in fetching quotes ${statusMessage}`);
          dispatch(setQuoteListLoading(false));
        }
      },
    });
  };

export const addQuotes =
  (data: addQuote): AppThunk =>
  async (dispatch) => {
    try {
      const authorLocale: Locale = new Locale();
      authorLocale.setDefaultcontent(data.author.defaultcontent);
      authorLocale.setEn(data.author.en);
      authorLocale.setHi(data.author.hi);
      authorLocale.setTe(data.author.te);
      authorLocale.setGu(data.author.gu);
      authorLocale.setTa(data.author.ta);
      authorLocale.setKa(data.author.ka);
      authorLocale.setMr(data.author.mr);
      authorLocale.setIt(data.author.it);
      authorLocale.setRu(data.author.ru);
      authorLocale.setEs(data.author.es);
      authorLocale.setFr(data.author.fr);
      authorLocale.setPt(data.author.pt);
      authorLocale.setDe(data.author.de);
      authorLocale.setBn(data.author.bn);
      authorLocale.setMl(data.author.ml);
      authorLocale.setUk(data.author.uk);
      authorLocale.setOd(data.author.od);
      authorLocale.setEnUs(data.author.enUs);
      authorLocale.setEnAu(data.author.enAu);
      authorLocale.setPa(data.author.pa);

      const quoteLocale: Locale = new Locale();
      quoteLocale.setDefaultcontent(data.quote.defaultcontent);
      quoteLocale.setEn(data.quote.en);
      quoteLocale.setHi(data.quote.hi);
      quoteLocale.setTe(data.quote.te);
      quoteLocale.setGu(data.quote.gu);
      quoteLocale.setTa(data.quote.ta);
      quoteLocale.setKa(data.quote.ka);
      quoteLocale.setMr(data.quote.mr);
      quoteLocale.setIt(data.quote.it);
      quoteLocale.setRu(data.quote.ru);
      quoteLocale.setEs(data.quote.es);
      quoteLocale.setFr(data.quote.fr);
      quoteLocale.setPt(data.quote.pt);
      quoteLocale.setDe(data.quote.de);
      quoteLocale.setBn(data.quote.bn);
      quoteLocale.setMl(data.quote.ml);
      quoteLocale.setUk(data.quote.uk);
      quoteLocale.setOd(data.quote.od);
      quoteLocale.setEnUs(data.quote.enUs);
      quoteLocale.setEnAu(data.quote.enAu);
      quoteLocale.setPa(data.quote.pa);

      const payload = new AddQuoteRequest();
      payload.setAuthor(authorLocale);
      payload.setQuote(quoteLocale);
      payload.setTagone(data.tagone.trim());
      payload.setTagtwo(data.tagtwo.trim());
      payload.setTagthree(data.tagthree.trim());
      payload.setScreen(data.screen.trim());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.AddQuotes, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("add quote response", res.message.toObject());
              dispatch(getAllQuoteDetails(data.getAllQuoteData));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Quote added successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in adding quote : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in add quote", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const updateQuotes =
  (data: updateQuote): AppThunk =>
  async (dispatch) => {
    try {
      const authorLocale: Locale = new Locale();
      authorLocale.setDefaultcontent(data.author.defaultcontent);
      authorLocale.setEn(data.author.en);
      authorLocale.setHi(data.author.hi);
      authorLocale.setTe(data.author.te);
      authorLocale.setGu(data.author.gu);
      authorLocale.setTa(data.author.ta);
      authorLocale.setKa(data.author.ka);
      authorLocale.setMr(data.author.mr);
      authorLocale.setIt(data.author.it);
      authorLocale.setRu(data.author.ru);
      authorLocale.setEs(data.author.es);
      authorLocale.setFr(data.author.fr);
      authorLocale.setPt(data.author.pt);
      authorLocale.setDe(data.author.de);
      authorLocale.setBn(data.author.bn);
      authorLocale.setMl(data.author.ml);
      authorLocale.setUk(data.author.uk);
      authorLocale.setOd(data.author.od);
      authorLocale.setEnUs(data.author.enUs);
      authorLocale.setEnAu(data.author.enAu);
      authorLocale.setPa(data.author.pa);

      const quoteLocale: Locale = new Locale();
      quoteLocale.setDefaultcontent(data.quote.defaultcontent);
      quoteLocale.setEn(data.quote.en);
      quoteLocale.setHi(data.quote.hi);
      quoteLocale.setTe(data.quote.te);
      quoteLocale.setGu(data.quote.gu);
      quoteLocale.setTa(data.quote.ta);
      quoteLocale.setKa(data.quote.ka);
      quoteLocale.setMr(data.quote.mr);
      quoteLocale.setIt(data.quote.it);
      quoteLocale.setRu(data.quote.ru);
      quoteLocale.setEs(data.quote.es);
      quoteLocale.setFr(data.quote.fr);
      quoteLocale.setPt(data.quote.pt);
      quoteLocale.setDe(data.quote.de);
      quoteLocale.setBn(data.quote.bn);
      quoteLocale.setMl(data.quote.ml);
      quoteLocale.setUk(data.quote.uk);
      quoteLocale.setOd(data.quote.od);
      quoteLocale.setEnUs(data.quote.enUs);
      quoteLocale.setEnAu(data.quote.enAu);
      quoteLocale.setPa(data.quote.pa);

      const payload = new QuoteDetails();
      payload.setId(data.id);
      payload.setAuthor(authorLocale);
      payload.setQuote(quoteLocale);
      payload.setTagone(data.tagone.trim());
      payload.setTagtwo(data.tagtwo.trim());
      payload.setTagthree(data.tagthree.trim());
      payload.setScreen(data.screen.trim());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.UpdateQuotes, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<QuoteDetails>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("update quote response", res.message.toObject());
              dispatch(getAllQuoteDetails(data.getAllQuoteData));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Quote updated successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in updated quote : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in update quote", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const deleteQuotes =
  (data: deleteQuote): AppThunk =>
  async (dispatch) => {
    try {
      const payload = new ID();
      payload.setId(data.id);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.DeleteQuotes, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("delte quote response", res.message.toObject());
              dispatch(getAllQuoteDetails(data.getAllQuoteData));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Quote deleted successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in deleting quote : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in delete quote", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const bulkUploadQuote =
  (data: bulkUpload): AppThunk =>
  async (dispatch) => {
    try {
      const payload = new URLRequest();
      payload.setUrl(data.link);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.BulkUploadQuotes, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<BulkUploadQuoteResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("bulk upload quote response", res.message.toObject());
              dispatch(getAllQuoteDetails(data.getAllQuoteData));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Quotes uploaded successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in uploading quote : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in upload quote", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };
