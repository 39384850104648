import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    FormControl,
    Grid,
    Grow,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Tooltip,
    InputLabel,
    TablePagination,
} from "@mui/material";
import React, { useEffect, useState, ReactElement } from "react";
import { ListSkeleton } from "components/listSkeleton";
import style from "./index.module.scss";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { donationsList } from "redux/reducers/donationReducer";





export default function DonationsList(): ReactElement {
    const [searchDonation, setsearchDonation] = useState("");
    const [searchData, setsearchData] = useState<any[]>([]);



    const RenderDonationsList = () => {
        return (
            <>
                <Grid
                    style={{ backgroundColor: "transparent" }}
                    className="d-flex py-2 pl-3 align-items-center"
                >
                    <Grid className="d-flex flex-grow-1">
                        <TextField
                            type="search"
                            fullWidth
                            variant="outlined"
                            placeholder="Search by donations title or duration"
                            size="small"
                            value={searchDonation}
                            autoFocus
                            onChange={(e) => {
                                console.log(e.target.value)
                                setsearchDonation(e.target.value);
                            }}
                            className="mr-2"
                        />
                    </Grid>
                    <Grid className="d-flex align-items-center">
                        <Tooltip title="Refresh">
                            <RefreshIcon />
                        </Tooltip>
                    </Grid>
                </Grid>
                <TableContainer
                    style={{ maxHeight: "400px" }}
                    className={style.scrollbar}
                >
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow style={{ fontWeight: "bold" }}>
                                <TableCell align="left">Image</TableCell>
                                <TableCell align="left"> Title</TableCell>
                                <TableCell align="center">Duration</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ cursor: "pointer" }}
                                hover
                            >
                                <TableCell>
                                    {1 > 0 ? "showing" : "not_showing"}
                                </TableCell>
                                <TableCell>
                                    {1 > 0 ? "showing" : "not_showing"}
                                </TableCell>
                                <TableCell align="center">
                                    {1 > 0 ? "showing" : "not_showing"}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    };

    return (
        <>
            <Container>
                <Grid container>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Card className="mt-3 mb-5" style={{ position: "relative" }}>
                            <div>
                                <Grid container className="align-items-center p-3">
                                    <Typography variant="h6">Donations</Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        className="ml-3"
                                    >
                                        Add Donations
                                    </Button>
                                </Grid>
                                <Divider />
                                <CardContent>
                                    <Paper>
                                        <RenderDonationsList />
                                    </Paper>
                                </CardContent>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}