import firebase from "firebase";
export function initializePushNotifications() {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging
      .getToken({
        vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_CERTIFICATE,
      })
      .then((token) => {
        if (token) {
          console.log("FCM Token", token);
        } else {
          console.log("token not found");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
