import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  CarouselDetails,
  Empty,
} from "api/models/heartintune-profile-service/heartintune-profile_pb";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import { HEARTINTUNE_PROFILE_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { updateCarousels } from "redux/reducers/layoutManagerReducer";
import { AppThunk } from "redux/store/store";

export const updateLayout = (data) => (dispatch) => {
  dispatch(updateCarousels(data));
};

export const getCarousels = (): AppThunk => async (dispatch) => {
  const jwtToken = await firebaseAuth.currentUser?.getIdToken();
  grpc.unary(HeartIntuneProfileService.GetCarousel, {
    request: new Empty(),
    host: HEARTINTUNE_PROFILE_SERVICE_HOST,
    metadata: { Authorization: `Bearer ${jwtToken}` },
    onEnd: (res: UnaryOutput<CarouselDetails>) => {
      const { status, message, statusMessage } = res;
      if (status === grpc.Code.OK && message) {
        dispatch(updateCarousels(JSON.parse(message.toObject().carouseljson)));
        console.log(
          "carousels data from rpc",
          JSON.parse(message.toObject().carouseljson)
        );
      } else {
        console.error(`error in fetching carousels${statusMessage}`);
      }
    },
  });
};
