import {
  Avatar,
  Button,
  Divider,
  Grid,
  Grow,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import MapIcon from "@mui/icons-material/Map";
import style from "./index.module.scss";
import { AddLocation } from "./AddLocation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { getAllKeyLocations } from "redux/actions/bhandaraActions";
import { ListSkeleton } from "components/listSkeleton";

const TableWrapper = styled("div")({
  margin: "16px",
  height: "100%",
  overflow: "auto",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "5px",
});

interface State {
  editing?: boolean;
  editItem?: any;
}

export const KeyLocations = React.forwardRef<HTMLDivElement, any>(
  ({ setstartDate, startDate, setendDate, endDate }, ref): ReactElement => {
    const initialState = {
      editing: false,
      editItem: null,
    };
    const [currentScreen, setCurrentScreen] = useState(0);
    const [states, setStates] = useState<State>(initialState);
    const [searchEvent] = useState("");

    const dispatch = useDispatch();
    const keyLocationsState = useSelector(
      (state: RootState) => state.bhandara.locations.keyLocations
    );
    useEffect(() => {
      dispatch(getAllKeyLocations(100, 0));
    }, [dispatch, currentScreen]);

    const onClickBack = () => {
      setCurrentScreen(0);
      setStates({
        editing: false,
        editItem: null,
      });
    };

    function renderKeyLocationsList() {
      return (
        <TableContainer
          style={{ maxHeight: "400px" }}
          className={style.scrollbar}
        >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ fontWeight: "bold" }}>
                <TableCell align="left">Image</TableCell>
                <TableCell align="center">Location Name</TableCell>
                <TableCell align="center">Location Category</TableCell>
                <TableCell align="center">Timing</TableCell>
                <TableCell align="center">Map Layout</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keyLocationsState.keyLocationsList.map((item: any, x: any) => (
                <React.Fragment key={x}>
                  <TableRow
                    sx={{ cursor: "pointer"}}
                    hover
                    onClick={() => {
                      setStates({
                        editing: true,
                        editItem: item,
                      });
                      setCurrentScreen(1);
                    }}
                  >
                    <TableCell align="center">
                      <Avatar src={item.imageurl} />
                    </TableCell>
                    <TableCell align="center">{item.title}</TableCell>
                    <TableCell align="center">
                      {item.keylocationcategorydetail.categoryname}
                    </TableCell>
                    <TableCell align="center">
                      {item.openat} to {item.closeat}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          window.open(item.directionurl);
                        }}
                        size="large"
                      >
                        <MapIcon color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <div ref={ref}>
        <Grow
          in={currentScreen === 0}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 0 ? { timeout: 1000 } : {})}
        >
          <div>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                padding: " 16px",
              }}
            >
              <Grid item xs={12} sm={6} md={8} lg={6}>
                <Grid
                  container
                  spacing={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid item>
                    <Typography variant="h6">Key Locations</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setCurrentScreen(1)}
                      startIcon={<AddIcon />}
                    >
                      Add Location
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <TableWrapper>
              <Paper>
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: "transparent" }}
                  className="py-2"
                >
                  <Grid className="d-flex align-items-center">
                    <TextField
                      type="search"
                      fullWidth
                      value={searchEvent}
                      variant="outlined"
                      placeholder="Search events by name"
                      size="small"
                      onChange={(e) => {}}
                      className="ml-3"
                      sx={{ visibility: "hidden" }}
                    />

                    <Grid>
                      <IconButton
                        onClick={() => {
                          dispatch(getAllKeyLocations(100, 0));
                        }}
                        size="large"
                      >
                        <Tooltip title="Refresh">
                          <RefreshIcon />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                {keyLocationsState.loading
                  ? ListSkeleton()
                  : renderKeyLocationsList()}
              </Paper>
            </TableWrapper>
          </div>
        </Grow>
        <Grow
          in={currentScreen === 1}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 1 ? { timeout: 1000 } : {})}
        >
          <AddLocation
            isEditing={states.editing}
            editItem={states.editItem}
            onClickBack={onClickBack}
          />
        </Grow>
      </div>
    );
  }
);
