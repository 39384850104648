import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  addTrigger,
  deleteTriggerById,
  updateTrigger,
} from "redux/actions/triggerActions";
import DialogBox from "components/dialogBox/dialog";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import { useFileUploadService } from "services/FileUplaodService";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { ImageOutlined } from "@mui/icons-material";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import ImageCrop from "components/image-crop/image-crop";

interface TriggerFormType {
  isEditing: boolean;
  lang: number;
  setPage: any;
  onClickBack: any;
  editItem: any;
  pageable: any;
}

interface Crop {
  unit: "%" | "px" | undefined;
  width: number;
  aspect: number;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: String;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
  deleteItemId: "0",
};

const PREFIX = "TriggersForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
    //overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

export const TriggersForm = React.forwardRef<HTMLDivElement, TriggerFormType>(
  (props, ref): ReactElement => {
    const languageType = [
      "defaultcontent",
      "en",
      "hi",
      "te",
      "gu",
      "ta",
      "ka",
      "mr",
      "it",
      "ru",
      "es",
      "fr",
      "pt",
      "de",
      "bn",
      "ml",
      "uk",
      "od",
      "enUs",
      "enAu",
      "pa",
    ];
    const dispatch = useDispatch();
    const { appendFilesToUploadManager } = useFileUploadService();

    const cropDef: Crop = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };
    const hiddenFileInput: any = React.useRef(null);

    const [id, setId] = useState<any>();
    const [states, setStates] = useState<State>(initialState);
    const [triggerName, setTriggerName] = useState<any>({
      defaultcontent: "",
      en: "",
      hi: "",
      te: "",
      gu: "",
      ta: "",
      ka: "",
      mr: "",
      it: "",
      ru: "",
      es: "",
      fr: "",
      pt: "",
      de: "",
      bn: "",
      ml: "",
      uk: "",
      od: "",
      enUs: "",
      enAu: "",
      pa: "",
    });
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [language, setLanguage] = useState<number>(0);

    const [imageRef, setImageRef] = useState<any>();
    const [src, setSrc] = useState<any>(null);
    const [crop, setCrop] = useState<Crop>(cropDef);
    const [imageFile, setImageFile] = useState<any>(null);
    const [croppedImage, setCroppedImage] = useState<any>(null);
    const [emojiUrl, setEmojiUrl] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setopen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [isAdding, setIsAdding] = useState(false);
    const onClickEdit = useCallback((item: any) => {
      console.log("Edit trigger details: ", item);
      setStates({ editing: true });
      setId(item.id);
      setTriggerName(item.tiggername);
      setEmojiUrl(item.emojiUrl);
    }, []);

    useEffect(() => {
      if (props.isEditing) {
        onClickEdit(props.editItem);
        setLanguage(props.lang - 1);
      }
    }, [props.isEditing, props.editItem, onClickEdit, props.lang]);

    // image upload

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
              }
            } else {
              setIsLoading(false);
            }
          },
        });
      } catch (err) {
        console.log("Error ", err);
      }
    };

    const handleUploadClick = () => {
      hiddenFileInput.current.click();
    };
    const onSelectImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading(true);
        setImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };

    const onCropDone = async () => {
      const cr: any = crop;
      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        setEmojiUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          "trigger".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setEmojiUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading(false);
            } else {
              setIsUploading(false);
            }
          }
        );
        setCroppedImage(croppedFile);
      }
      setSrc(null);
    };

    const onCropChange = (crop: any) => {
      setCrop(crop);
    };
    const onCropComplete = async () => {};
    const onImageClose = () => {
      setSrc(null);
      setImageFile(null);
      setCroppedImage(null);
      setCrop(cropDef);
      _deleteFileFromGcp(emojiUrl);
      setEmojiUrl("");
    };

    const _onOpenImage = () => {
      setopen(true);
    };
    const _onCloseImage = () => {
      setopen(false);
      setIsUploading(false);
    };

    const validateForm = () => {
      if (triggerName[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: " Trigger name can not be empty",
        });
      } else if (emojiUrl === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: " Trigger image can not be empty",
        });
      } else {
        return true;
      }
    };

    const deleteTrigger = async () => {
      setIsLoading(true);
      dispatch(
        deleteTriggerById({
          id: Number(states.deleteItemId),
          pageable: props.pageable,
          setSnackBar,
        })
      );
      setTimeout(props.onClickBack, 2000);
    };

    const handleDeleteClose = () => {
      setStates({ showDeleteModal: false });
    };

    const onClickDelete = (moodId: any) => {
      setStates({ showDeleteModal: true, deleteItemId: id });
    };

    const _addTrigger = () => {
      if (validateForm()) {
        if (props.isEditing) {
          setIsAdding(true);
          setIsLoading(true);
          dispatch(
            updateTrigger({
              id,
              triggerName,
              pageable: props.pageable,
              emojiUrl,
              setSnackBar,
            })
          );
          setTimeout(props.onClickBack, 2000);
        } else {
          setIsAdding(true);
          setIsLoading(true);
          dispatch(
            addTrigger({
              triggerName,
              pageable: props.pageable,
              emojiUrl,
              setSnackBar,
            })
          );
          setTimeout(props.onClickBack, 2000);
        }
      }
    };

    return (
      <Root ref={ref} className={classes.root}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing
                    ? "Edit Mood Trigger"
                    : "Create New Mood Trigger"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      Mood Triggers
                    </Typography>
                    <Typography color="inherit" style={{ cursor: "pointer" }}>
                      {props.editItem.tiggername[languageType[language]]}
                    </Typography>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => onClickDelete(id)}
                  disabled
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="name"
                label="Trigger Name"
                variant="outlined"
                value={triggerName[languageType[language]]}
                multiline
                onChange={(e) => {
                  setTriggerName((prevState) => ({
                    ...prevState,
                    [languageType[language]]: e.target.value,
                  }));
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined">
                  {emojiUrl ? (
                    <PreviewProfileImage
                      onImageClose={onImageClose}
                      src={emojiUrl}
                      file={croppedImage}
                    />
                  ) : (
                    <>
                      {isUploading ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.root}
                          onClick={() => {
                            handleUploadClick();
                          }}
                        >
                          {/* <InputLabel htmlFor="thumbnail">
                            Upload image
                          </InputLabel> */}
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <ImageOutlined />
                          </Avatar>
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            hidden
                            onChange={onSelectImage}
                            ref={hiddenFileInput}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Image
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            className={classes.formBtn}
            onClick={props.onClickBack}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={classes.formBtn}
            onClick={() => _addTrigger()}
            color="primary"
            variant="contained"
            disabled={isAdding}
          >
            {props.isEditing ? "Update mood trigger" : "Add mood trigger"}
          </Button>
        </Box>
        {src && (
          <ImageCrop
            src={src.toString()}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onCropComplete={onCropComplete}
            onCropChange={onCropChange}
            onPressDone={onCropDone}
            onImageClose={onImageClose}
            open={open}
            onOpen={_onOpenImage}
            onClose={_onCloseImage}
          />
        )}
        <DialogBox
          open={states.showDeleteModal || false}
          onClose={handleDeleteClose}
          onCancel={handleDeleteClose}
          onConfirm={deleteTrigger}
          type={"delete"}
          title={`Sure !! Want to delete this mood trigger ?`}
        />

        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
