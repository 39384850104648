import { createSlice } from "@reduxjs/toolkit";
import { SelfDevelopmentContentResponse } from "api/models/gamification-service/gamification_pb";

interface selfDevelopmentStateType {
  contentList: {
    loading: boolean;
    content: SelfDevelopmentContentResponse.AsObject;
  };
}

const initialState: selfDevelopmentStateType = {
  contentList: {
    loading: true,
    content: {
      articlelistList: [],
      booklistList: [],
      storylistList: [],
      featuredcontentList: [],
      totalarticlecount: 0,
      totalbookcount: 0,
      totalstorycount: 0,
      totalvideoscount: 0,
      videolistList: [],
      pageable: {
        selectedpagenumber: 0,
        selectedpagesize: 0,
        totalcount: 0,
      },
    },
  },
};

export const contentSlice = createSlice({
  name: "contentList",
  initialState,
  reducers: {
    setSelfDevelopmentContentListLoading: (state, action) => {
      state.contentList.loading = action.payload;
    },
    setSelfDevelopmentContentList: (state, action) => {
      state.contentList.content = action.payload;
    },
  },
});

export const {
  setSelfDevelopmentContentListLoading,
  setSelfDevelopmentContentList,
} = contentSlice.actions;

export default contentSlice.reducer;
