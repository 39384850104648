// package: unifiedplatform.liveMeditation
// file: live-meditation.proto

var live_meditation_pb = require("./live-meditation_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var LiveMeditationService = (function () {
  function LiveMeditationService() {}
  LiveMeditationService.serviceName = "unifiedplatform.liveMeditation.LiveMeditationService";
  return LiveMeditationService;
}());

LiveMeditationService.Test = {
  methodName: "Test",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.Empty,
  responseType: live_meditation_pb.TestResponse
};

LiveMeditationService.AddCoach = {
  methodName: "AddCoach",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.AddCoachRequest,
  responseType: live_meditation_pb.CoachProfile
};

LiveMeditationService.GetAllCoach = {
  methodName: "GetAllCoach",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.Empty,
  responseType: live_meditation_pb.ListOfCoach
};

LiveMeditationService.GetCoachByID = {
  methodName: "GetCoachByID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.CoachProfile
};

LiveMeditationService.GetLocaleCoachByID = {
  methodName: "GetLocaleCoachByID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.GetLocaleCoachByIdRequest,
  responseType: live_meditation_pb.LocaleCoachProfile
};

LiveMeditationService.UpdateCoach = {
  methodName: "UpdateCoach",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.CoachProfile,
  responseType: live_meditation_pb.CoachProfile
};

LiveMeditationService.DeleteCoachByID = {
  methodName: "DeleteCoachByID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.DeleteCoachByIdResponse
};

LiveMeditationService.AddQuestion = {
  methodName: "AddQuestion",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.AddQuestionRequest,
  responseType: live_meditation_pb.Question
};

LiveMeditationService.AddQuestionByUser = {
  methodName: "AddQuestionByUser",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.AddQuestionRequest,
  responseType: live_meditation_pb.Question
};

LiveMeditationService.GetAllQuestionBySessionID = {
  methodName: "GetAllQuestionBySessionID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestQuestionBySessionId,
  responseType: live_meditation_pb.ListOfQuestion
};

LiveMeditationService.GetAllUserQuestionBySessionID = {
  methodName: "GetAllUserQuestionBySessionID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestQuestionBySessionId,
  responseType: live_meditation_pb.ListOfQuestion
};

LiveMeditationService.ScheduleSession = {
  methodName: "ScheduleSession",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.AddLiveMeditationRequest,
  responseType: live_meditation_pb.SessionInfo
};

LiveMeditationService.createSession = {
  methodName: "createSession",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.CreateLiveMeditationRequest,
  responseType: live_meditation_pb.SessionInfo
};

LiveMeditationService.GetAllScheduleSessionsBetweenDateRange = {
  methodName: "GetAllScheduleSessionsBetweenDateRange",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.requestScheduleSessionsDateRange,
  responseType: live_meditation_pb.responseScheduleSessionsDateRange
};

LiveMeditationService.UpdateScheduleSession = {
  methodName: "UpdateScheduleSession",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.SessionInfo,
  responseType: live_meditation_pb.SessionInfo
};

LiveMeditationService.DeleteScheduleSessionByID = {
  methodName: "DeleteScheduleSessionByID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.DeleteScheduleSessionByIDResponse
};

LiveMeditationService.GetLiveSessionByID = {
  methodName: "GetLiveSessionByID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.ResponseLiveMeditation
};

LiveMeditationService.GetLiveSessionByIDWithSelectedLanguage = {
  methodName: "GetLiveSessionByIDWithSelectedLanguage",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestLiveSessionId,
  responseType: live_meditation_pb.ResponseLiveMeditationWithSelectedLanguage
};

LiveMeditationService.GetLiveSessionByIDWithDelayTime = {
  methodName: "GetLiveSessionByIDWithDelayTime",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForLiveSessionExist,
  responseType: live_meditation_pb.ResponseLiveMeditationWithSelectedLanguage
};

LiveMeditationService.GetAllLiveSession = {
  methodName: "GetAllLiveSession",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestGetAllLiveSession,
  responseType: live_meditation_pb.ResponseListOfLiveMeditations
};

LiveMeditationService.GetAllLiveSessionWithSelectedLanguage = {
  methodName: "GetAllLiveSessionWithSelectedLanguage",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestGetAllLiveSessionWithSelectedLanguage,
  responseType: live_meditation_pb.ResponseListOfLiveMeditationsWithSelectedLanguage
};

LiveMeditationService.UpcomingLiveMeditation = {
  methodName: "UpcomingLiveMeditation",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestGetAllLiveSession,
  responseType: live_meditation_pb.ResponseLiveMeditation
};

LiveMeditationService.UpcomingLiveMeditationWithSelectedLanguage = {
  methodName: "UpcomingLiveMeditationWithSelectedLanguage",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestGetAllLiveSessionWithSelectedLanguage,
  responseType: live_meditation_pb.ResponseLiveMeditationWithSelectedLanguage
};

LiveMeditationService.AddRegisterCount = {
  methodName: "AddRegisterCount",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.DecreaseRegisterCount = {
  methodName: "DecreaseRegisterCount",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.AddSessionBenefits = {
  methodName: "AddSessionBenefits",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestSessionBenefits,
  responseType: live_meditation_pb.ResponseSessionBenefits
};

LiveMeditationService.UpdateSessionBenefits = {
  methodName: "UpdateSessionBenefits",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestUpdateBenefits,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.GetAllBenefitsBySessionID = {
  methodName: "GetAllBenefitsBySessionID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.ResponseListOfBenefits
};

LiveMeditationService.addBenefitsToSession = {
  methodName: "addBenefitsToSession",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestAddBenefitAndSessionIDs,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.CheckLiveSessionIdExisted = {
  methodName: "CheckLiveSessionIdExisted",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForLiveSessionExist,
  responseType: live_meditation_pb.SessionInfo
};

LiveMeditationService.addLiveMeditationForNextFewDays = {
  methodName: "addLiveMeditationForNextFewDays",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.requestAddLiveMeditationForNextFewDays,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.RateLiveMeditationSession = {
  methodName: "RateLiveMeditationSession",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForRating,
  responseType: live_meditation_pb.ResponseForRating
};

LiveMeditationService.GetAverageRatingBySessionId = {
  methodName: "GetAverageRatingBySessionId",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.ResponseAverageRating
};

LiveMeditationService.GetCompletedLiveSessionsById = {
  methodName: "GetCompletedLiveSessionsById",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.ResponseLiveMeditation
};

LiveMeditationService.GetLiveMeditationsScheduleBetween = {
  methodName: "GetLiveMeditationsScheduleBetween",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForLiveSessionsBetween,
  responseType: live_meditation_pb.ResponseForLiveSessionsBetween
};

LiveMeditationService.GetLiveSessionsByCoachId = {
  methodName: "GetLiveSessionsByCoachId",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForGetLiveSessionByCoachId,
  responseType: live_meditation_pb.ResponseForGetLiveSessionByCoachId
};

LiveMeditationService.GetQuestionsByLiveSessionId = {
  methodName: "GetQuestionsByLiveSessionId",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.GetAllBenefits = {
  methodName: "GetAllBenefits",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForGetAllBenefits,
  responseType: live_meditation_pb.ResponseForGetAllBenefits
};

LiveMeditationService.GetAllBenefitsByCoachId = {
  methodName: "GetAllBenefitsByCoachId",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForGetAllBenefitsByCoachId,
  responseType: live_meditation_pb.ResponseForGetAllBenefitsByCoachId
};

LiveMeditationService.GetAllQuestionsWithRegisterCount = {
  methodName: "GetAllQuestionsWithRegisterCount",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForGetAllQuestionsWithRegisterCount,
  responseType: live_meditation_pb.ResponseForGetAllQuestionsWithRegisterCount
};

LiveMeditationService.GetLiveSessionState = {
  methodName: "GetLiveSessionState",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: true,
  requestType: live_meditation_pb.RequestForLiveSessionState,
  responseType: live_meditation_pb.ResponseForLiveSessionState
};

LiveMeditationService.PreceptorSession = {
  methodName: "PreceptorSession",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestPreceptorSession,
  responseType: live_meditation_pb.ResponseForLiveSessionState
};

LiveMeditationService.GetZoomToken = {
  methodName: "GetZoomToken",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForGetZoomToken,
  responseType: live_meditation_pb.ResponseForGetZoomToken
};

LiveMeditationService.ZoomWrapperInterFace = {
  methodName: "ZoomWrapperInterFace",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForZoomWrapper,
  responseType: live_meditation_pb.ResponseForZoomWrapper
};

LiveMeditationService.ScheduleSessionDetails = {
  methodName: "ScheduleSessionDetails",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.AddLiveMeditationRequestDetails,
  responseType: live_meditation_pb.SessionInfoDetails
};

LiveMeditationService.UpdateScheduleSessionDetails = {
  methodName: "UpdateScheduleSessionDetails",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.SessionInfoDetails,
  responseType: live_meditation_pb.SessionInfoDetails
};

LiveMeditationService.DeleteScheduleSessionDetailsByID = {
  methodName: "DeleteScheduleSessionDetailsByID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.DeleteScheduleSessionDetailsByIDResponse
};

LiveMeditationService.GetAllLiveSessionDetails = {
  methodName: "GetAllLiveSessionDetails",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestGetAllLiveSession,
  responseType: live_meditation_pb.ResponseListOfLiveMeditationsDetails
};

LiveMeditationService.GetLiveSessionDetailsByID = {
  methodName: "GetLiveSessionDetailsByID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.ResponseLiveMeditationDetails
};

LiveMeditationService.UpcomingLiveMeditationDetails = {
  methodName: "UpcomingLiveMeditationDetails",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestGetAllLiveSession,
  responseType: live_meditation_pb.ResponseLiveMeditation
};

LiveMeditationService.AddUserLiveSessionFeedBack = {
  methodName: "AddUserLiveSessionFeedBack",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForUserFeedBack,
  responseType: live_meditation_pb.UserLiveSessionFeedBack
};

LiveMeditationService.GetAllUserFeedBacksByLiveSessionId = {
  methodName: "GetAllUserFeedBacksByLiveSessionId",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestPageableWithId,
  responseType: live_meditation_pb.ListOfUserFeedBacks
};

LiveMeditationService.AddSessionBenefitsDetails = {
  methodName: "AddSessionBenefitsDetails",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestAddSessionBenefitsDetails,
  responseType: live_meditation_pb.ResponseSessionBenefitsDetails
};

LiveMeditationService.UpdateSessionBenefitsDetails = {
  methodName: "UpdateSessionBenefitsDetails",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.SessionBenefitsDetails,
  responseType: live_meditation_pb.SessionBenefitsDetails
};

LiveMeditationService.GetAllBenefitDetailsBySessionID = {
  methodName: "GetAllBenefitDetailsBySessionID",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.ResponseListOfBenefitsDetails
};

LiveMeditationService.AddBenefitsToSessionDetails = {
  methodName: "AddBenefitsToSessionDetails",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestBenefitAndSessionIDs,
  responseType: live_meditation_pb.SessionInfoDetails
};

LiveMeditationService.RemoveBenefitsFromSessionDetails = {
  methodName: "RemoveBenefitsFromSessionDetails",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestBenefitAndSessionIDs,
  responseType: live_meditation_pb.SessionInfoDetails
};

LiveMeditationService.SetLiveSessionRegisteredCount = {
  methodName: "SetLiveSessionRegisteredCount",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestSetLiveSessionRegisterCount,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.CreateChannel = {
  methodName: "CreateChannel",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.AwsChannelRequest,
  responseType: live_meditation_pb.AwsChannelResponse
};

LiveMeditationService.DeleteChannel = {
  methodName: "DeleteChannel",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ChannelArn,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.GetChannelByArm = {
  methodName: "GetChannelByArm",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ChannelArn,
  responseType: live_meditation_pb.AwsChannelResponse
};

LiveMeditationService.GetLiveSessionViewersCount = {
  methodName: "GetLiveSessionViewersCount",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.AwsChannelViewerCountResponse
};

LiveMeditationService.GetLiveSessionsFeedBack = {
  methodName: "GetLiveSessionsFeedBack",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.ResponseForLiveSessionFeedBacks
};

LiveMeditationService.GetDynamicFeedBacks = {
  methodName: "GetDynamicFeedBacks",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: true,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.ResponseForLiveSessionFeedBacks
};

LiveMeditationService.GetDynamicFeedBacksV2 = {
  methodName: "GetDynamicFeedBacksV2",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: true,
  requestType: live_meditation_pb.ReportTypeWithIdRequest,
  responseType: live_meditation_pb.ResponseForLiveSessionFeedBacks
};

LiveMeditationService.GetLiveSessionCurrentState = {
  methodName: "GetLiveSessionCurrentState",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForLiveSessionState,
  responseType: live_meditation_pb.ResponseForLiveSessionState
};

LiveMeditationService.getUpcomingLiveMeditationV2 = {
  methodName: "getUpcomingLiveMeditationV2",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForUpcomingGetAllLiveMediationV2,
  responseType: live_meditation_pb.ResponseForUpcomingGetAllLiveMeditationsV2
};

LiveMeditationService.getDynamicLiveSessionViewerCountV2 = {
  methodName: "getDynamicLiveSessionViewerCountV2",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: true,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.AwsChannelViewerCountResponse
};

LiveMeditationService.GetAllDynamicUserQuestionBySessionIDV2 = {
  methodName: "GetAllDynamicUserQuestionBySessionIDV2",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: true,
  requestType: live_meditation_pb.RequestQuestionBySessionId,
  responseType: live_meditation_pb.ListOfQuestion
};

LiveMeditationService.GetAllLiveSessionV2 = {
  methodName: "GetAllLiveSessionV2",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestGetAllLiveSessionV2,
  responseType: live_meditation_pb.ResponseListOfLiveMeditationsWithSelectedLanguage
};

LiveMeditationService.GetListOfLiveSessionV2ByCoachIdOnSpecifiedTime = {
  methodName: "GetListOfLiveSessionV2ByCoachIdOnSpecifiedTime",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestLiveSessionV2ByCoachIdOnSpecifiedTime,
  responseType: live_meditation_pb.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime
};

LiveMeditationService.getZoomUrl = {
  methodName: "getZoomUrl",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.Empty,
  responseType: live_meditation_pb.ZoomUrlsResponse
};

LiveMeditationService.endZoomUrl = {
  methodName: "endZoomUrl",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.Empty,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.UpdateZoomLiveStreamStatus = {
  methodName: "UpdateZoomLiveStreamStatus",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ZoomLiveStreamActionRequest,
  responseType: live_meditation_pb.ResponseForZoomWrapper
};

LiveMeditationService.EventNotificationZoomWebhookEndUrl = {
  methodName: "EventNotificationZoomWebhookEndUrl",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ZoomEventRequest,
  responseType: live_meditation_pb.ZoomEventResponse
};

LiveMeditationService.DeleteQuestionsByUserId = {
  methodName: "DeleteQuestionsByUserId",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.DeleteFeedBacksByUserId = {
  methodName: "DeleteFeedBacksByUserId",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.GetAllSessionViewerCount = {
  methodName: "GetAllSessionViewerCount",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.ListOfSessionViewerCount
};

LiveMeditationService.AddPreRecorded = {
  methodName: "AddPreRecorded",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.PreRecordedRequest,
  responseType: live_meditation_pb.PreRecordedResponse
};

LiveMeditationService.UpdatePreRecorded = {
  methodName: "UpdatePreRecorded",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.PreRecordedResponse,
  responseType: live_meditation_pb.PreRecordedResponse
};

LiveMeditationService.DeletePreRecorded = {
  methodName: "DeletePreRecorded",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.GetAllPreRecorded = {
  methodName: "GetAllPreRecorded",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestPageable,
  responseType: live_meditation_pb.GetAllPreRecordedResponse
};

LiveMeditationService.GetAllPreRecordedByPreRecordedType = {
  methodName: "GetAllPreRecordedByPreRecordedType",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.PreRecordedTypeRequest,
  responseType: live_meditation_pb.ListOfPreRecordedLocaleResponse
};

LiveMeditationService.GetPreRecordedByIdWithSelectedLanguage = {
  methodName: "GetPreRecordedByIdWithSelectedLanguage",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.LocaleType,
  responseType: live_meditation_pb.PreRecordedWithLocaleResponse
};

LiveMeditationService.SearchPreRecordedByTitle = {
  methodName: "SearchPreRecordedByTitle",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.PreRecordedTypeRequest,
  responseType: live_meditation_pb.ListOfPreRecordedLocaleResponse
};

LiveMeditationService.AddGroups = {
  methodName: "AddGroups",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.GroupsRequest,
  responseType: live_meditation_pb.GroupsResponse
};

LiveMeditationService.UpdateGroups = {
  methodName: "UpdateGroups",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.GroupsResponse,
  responseType: live_meditation_pb.GroupsResponse
};

LiveMeditationService.DeleteGroups = {
  methodName: "DeleteGroups",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.GetAllGroupsBetweenDateRange = {
  methodName: "GetAllGroupsBetweenDateRange",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestGroupsBetweenDateRange,
  responseType: live_meditation_pb.ListOfGroups
};

LiveMeditationService.UpcomingGroups = {
  methodName: "UpcomingGroups",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.UpcomingGroupsRequest,
  responseType: live_meditation_pb.ListOfGroupsWithSelectLanguage
};

LiveMeditationService.GetGroupsByIDWithLocaleSupport = {
  methodName: "GetGroupsByIDWithLocaleSupport",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.LocaleType,
  responseType: live_meditation_pb.GroupsResponseWithLocaleType
};

LiveMeditationService.RegisterOrUnregisterForGroups = {
  methodName: "RegisterOrUnregisterForGroups",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.GroupsUserCountRequest,
  responseType: live_meditation_pb.GroupsResponse
};

LiveMeditationService.IncreaseJoinCountForGroupOccurrence = {
  methodName: "IncreaseJoinCountForGroupOccurrence",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.GroupsUserCountRequest,
  responseType: live_meditation_pb.GroupsResponse
};

LiveMeditationService.PublishOrUnPublishGroup = {
  methodName: "PublishOrUnPublishGroup",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.PublishOrUnPublishGroupRequest,
  responseType: live_meditation_pb.Status
};

LiveMeditationService.GetAllGroups = {
  methodName: "GetAllGroups",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestPageable,
  responseType: live_meditation_pb.ListOfGroups
};

LiveMeditationService.GetAllGroupOccurrencesBetweenDateRange = {
  methodName: "GetAllGroupOccurrencesBetweenDateRange",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.GroupIdRequest,
  responseType: live_meditation_pb.ListOfGroupOccurrences
};

LiveMeditationService.GetGroupOccurrenceById = {
  methodName: "GetGroupOccurrenceById",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.ID,
  responseType: live_meditation_pb.GroupOccurrencesDetails
};

LiveMeditationService.CreateOrUpdateContentTags = {
  methodName: "CreateOrUpdateContentTags",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.Tag,
  responseType: live_meditation_pb.Tag
};

LiveMeditationService.GetAllContentTags = {
  methodName: "GetAllContentTags",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestPageable,
  responseType: live_meditation_pb.TagsList
};

LiveMeditationService.AddOrUpdateMultipleTags = {
  methodName: "AddOrUpdateMultipleTags",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.Tags,
  responseType: live_meditation_pb.Tags
};

LiveMeditationService.GetLiveSessionByTag = {
  methodName: "GetLiveSessionByTag",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.Tag,
  responseType: live_meditation_pb.TagWithSession
};

LiveMeditationService.GetPrerecordedVideoByTag = {
  methodName: "GetPrerecordedVideoByTag",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.Tag,
  responseType: live_meditation_pb.TagWithPrerecorded
};

LiveMeditationService.CheckListOfLiveSessionIdsExisted = {
  methodName: "CheckListOfLiveSessionIdsExisted",
  service: LiveMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: live_meditation_pb.RequestForLiveSessionExist,
  responseType: live_meditation_pb.ResponseForGetLiveSessionByCoachId
};

exports.LiveMeditationService = LiveMeditationService;

function LiveMeditationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

LiveMeditationServiceClient.prototype.test = function test(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.Test, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addCoach = function addCoach(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddCoach, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllCoach = function getAllCoach(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllCoach, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getCoachByID = function getCoachByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetCoachByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLocaleCoachByID = function getLocaleCoachByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLocaleCoachByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.updateCoach = function updateCoach(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpdateCoach, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.deleteCoachByID = function deleteCoachByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.DeleteCoachByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addQuestion = function addQuestion(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddQuestion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addQuestionByUser = function addQuestionByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddQuestionByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllQuestionBySessionID = function getAllQuestionBySessionID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllQuestionBySessionID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllUserQuestionBySessionID = function getAllUserQuestionBySessionID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllUserQuestionBySessionID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.scheduleSession = function scheduleSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.ScheduleSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.createSession = function createSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.createSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllScheduleSessionsBetweenDateRange = function getAllScheduleSessionsBetweenDateRange(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllScheduleSessionsBetweenDateRange, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.updateScheduleSession = function updateScheduleSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpdateScheduleSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.deleteScheduleSessionByID = function deleteScheduleSessionByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.DeleteScheduleSessionByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveSessionByID = function getLiveSessionByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLiveSessionByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveSessionByIDWithSelectedLanguage = function getLiveSessionByIDWithSelectedLanguage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLiveSessionByIDWithSelectedLanguage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveSessionByIDWithDelayTime = function getLiveSessionByIDWithDelayTime(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLiveSessionByIDWithDelayTime, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllLiveSession = function getAllLiveSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllLiveSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllLiveSessionWithSelectedLanguage = function getAllLiveSessionWithSelectedLanguage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllLiveSessionWithSelectedLanguage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.upcomingLiveMeditation = function upcomingLiveMeditation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpcomingLiveMeditation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.upcomingLiveMeditationWithSelectedLanguage = function upcomingLiveMeditationWithSelectedLanguage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpcomingLiveMeditationWithSelectedLanguage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addRegisterCount = function addRegisterCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddRegisterCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.decreaseRegisterCount = function decreaseRegisterCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.DecreaseRegisterCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addSessionBenefits = function addSessionBenefits(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddSessionBenefits, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.updateSessionBenefits = function updateSessionBenefits(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpdateSessionBenefits, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllBenefitsBySessionID = function getAllBenefitsBySessionID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllBenefitsBySessionID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addBenefitsToSession = function addBenefitsToSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.addBenefitsToSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.checkLiveSessionIdExisted = function checkLiveSessionIdExisted(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.CheckLiveSessionIdExisted, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addLiveMeditationForNextFewDays = function addLiveMeditationForNextFewDays(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.addLiveMeditationForNextFewDays, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.rateLiveMeditationSession = function rateLiveMeditationSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.RateLiveMeditationSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAverageRatingBySessionId = function getAverageRatingBySessionId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAverageRatingBySessionId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getCompletedLiveSessionsById = function getCompletedLiveSessionsById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetCompletedLiveSessionsById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveMeditationsScheduleBetween = function getLiveMeditationsScheduleBetween(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLiveMeditationsScheduleBetween, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveSessionsByCoachId = function getLiveSessionsByCoachId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLiveSessionsByCoachId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getQuestionsByLiveSessionId = function getQuestionsByLiveSessionId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetQuestionsByLiveSessionId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllBenefits = function getAllBenefits(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllBenefits, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllBenefitsByCoachId = function getAllBenefitsByCoachId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllBenefitsByCoachId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllQuestionsWithRegisterCount = function getAllQuestionsWithRegisterCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllQuestionsWithRegisterCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveSessionState = function getLiveSessionState(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(LiveMeditationService.GetLiveSessionState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.preceptorSession = function preceptorSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.PreceptorSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getZoomToken = function getZoomToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetZoomToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.zoomWrapperInterFace = function zoomWrapperInterFace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.ZoomWrapperInterFace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.scheduleSessionDetails = function scheduleSessionDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.ScheduleSessionDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.updateScheduleSessionDetails = function updateScheduleSessionDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpdateScheduleSessionDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.deleteScheduleSessionDetailsByID = function deleteScheduleSessionDetailsByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.DeleteScheduleSessionDetailsByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllLiveSessionDetails = function getAllLiveSessionDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllLiveSessionDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveSessionDetailsByID = function getLiveSessionDetailsByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLiveSessionDetailsByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.upcomingLiveMeditationDetails = function upcomingLiveMeditationDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpcomingLiveMeditationDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addUserLiveSessionFeedBack = function addUserLiveSessionFeedBack(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddUserLiveSessionFeedBack, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllUserFeedBacksByLiveSessionId = function getAllUserFeedBacksByLiveSessionId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllUserFeedBacksByLiveSessionId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addSessionBenefitsDetails = function addSessionBenefitsDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddSessionBenefitsDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.updateSessionBenefitsDetails = function updateSessionBenefitsDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpdateSessionBenefitsDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllBenefitDetailsBySessionID = function getAllBenefitDetailsBySessionID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllBenefitDetailsBySessionID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addBenefitsToSessionDetails = function addBenefitsToSessionDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddBenefitsToSessionDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.removeBenefitsFromSessionDetails = function removeBenefitsFromSessionDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.RemoveBenefitsFromSessionDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.setLiveSessionRegisteredCount = function setLiveSessionRegisteredCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.SetLiveSessionRegisteredCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.createChannel = function createChannel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.CreateChannel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.deleteChannel = function deleteChannel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.DeleteChannel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getChannelByArm = function getChannelByArm(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetChannelByArm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveSessionViewersCount = function getLiveSessionViewersCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLiveSessionViewersCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveSessionsFeedBack = function getLiveSessionsFeedBack(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLiveSessionsFeedBack, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getDynamicFeedBacks = function getDynamicFeedBacks(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(LiveMeditationService.GetDynamicFeedBacks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getDynamicFeedBacksV2 = function getDynamicFeedBacksV2(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(LiveMeditationService.GetDynamicFeedBacksV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveSessionCurrentState = function getLiveSessionCurrentState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLiveSessionCurrentState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getUpcomingLiveMeditationV2 = function getUpcomingLiveMeditationV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.getUpcomingLiveMeditationV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getDynamicLiveSessionViewerCountV2 = function getDynamicLiveSessionViewerCountV2(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(LiveMeditationService.getDynamicLiveSessionViewerCountV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllDynamicUserQuestionBySessionIDV2 = function getAllDynamicUserQuestionBySessionIDV2(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(LiveMeditationService.GetAllDynamicUserQuestionBySessionIDV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllLiveSessionV2 = function getAllLiveSessionV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllLiveSessionV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getListOfLiveSessionV2ByCoachIdOnSpecifiedTime = function getListOfLiveSessionV2ByCoachIdOnSpecifiedTime(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetListOfLiveSessionV2ByCoachIdOnSpecifiedTime, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getZoomUrl = function getZoomUrl(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.getZoomUrl, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.endZoomUrl = function endZoomUrl(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.endZoomUrl, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.updateZoomLiveStreamStatus = function updateZoomLiveStreamStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpdateZoomLiveStreamStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.eventNotificationZoomWebhookEndUrl = function eventNotificationZoomWebhookEndUrl(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.EventNotificationZoomWebhookEndUrl, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.deleteQuestionsByUserId = function deleteQuestionsByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.DeleteQuestionsByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.deleteFeedBacksByUserId = function deleteFeedBacksByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.DeleteFeedBacksByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllSessionViewerCount = function getAllSessionViewerCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllSessionViewerCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addPreRecorded = function addPreRecorded(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddPreRecorded, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.updatePreRecorded = function updatePreRecorded(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpdatePreRecorded, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.deletePreRecorded = function deletePreRecorded(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.DeletePreRecorded, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllPreRecorded = function getAllPreRecorded(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllPreRecorded, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllPreRecordedByPreRecordedType = function getAllPreRecordedByPreRecordedType(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllPreRecordedByPreRecordedType, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getPreRecordedByIdWithSelectedLanguage = function getPreRecordedByIdWithSelectedLanguage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetPreRecordedByIdWithSelectedLanguage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.searchPreRecordedByTitle = function searchPreRecordedByTitle(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.SearchPreRecordedByTitle, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addGroups = function addGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.updateGroups = function updateGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpdateGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.deleteGroups = function deleteGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.DeleteGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllGroupsBetweenDateRange = function getAllGroupsBetweenDateRange(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllGroupsBetweenDateRange, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.upcomingGroups = function upcomingGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.UpcomingGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getGroupsByIDWithLocaleSupport = function getGroupsByIDWithLocaleSupport(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetGroupsByIDWithLocaleSupport, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.registerOrUnregisterForGroups = function registerOrUnregisterForGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.RegisterOrUnregisterForGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.increaseJoinCountForGroupOccurrence = function increaseJoinCountForGroupOccurrence(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.IncreaseJoinCountForGroupOccurrence, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.publishOrUnPublishGroup = function publishOrUnPublishGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.PublishOrUnPublishGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllGroups = function getAllGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllGroupOccurrencesBetweenDateRange = function getAllGroupOccurrencesBetweenDateRange(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllGroupOccurrencesBetweenDateRange, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getGroupOccurrenceById = function getGroupOccurrenceById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetGroupOccurrenceById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.createOrUpdateContentTags = function createOrUpdateContentTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.CreateOrUpdateContentTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getAllContentTags = function getAllContentTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetAllContentTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.addOrUpdateMultipleTags = function addOrUpdateMultipleTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.AddOrUpdateMultipleTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getLiveSessionByTag = function getLiveSessionByTag(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetLiveSessionByTag, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.getPrerecordedVideoByTag = function getPrerecordedVideoByTag(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.GetPrerecordedVideoByTag, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LiveMeditationServiceClient.prototype.checkListOfLiveSessionIdsExisted = function checkListOfLiveSessionIdsExisted(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LiveMeditationService.CheckListOfLiveSessionIdsExisted, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.LiveMeditationServiceClient = LiveMeditationServiceClient;

