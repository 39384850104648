/**
 * @author : Godwin Vinny Carole Kati
 * @created : 2021-04-30 00:38:02
 * @organisation : Codeprism Technologies Pvt Ltd
 **/

import { grpc } from "@improbable-eng/grpc-web";
import { Code } from "@improbable-eng/grpc-web/dist/typings/Code";
import { Metadata } from "@improbable-eng/grpc-web/dist/typings/metadata";
import {
  Breadcrumbs,
  CircularProgress,
  Grid,
  Link,
  ListItem,
  Typography,
} from "@mui/material";
import { CheckCircleRounded } from "@mui/icons-material";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import {
  FileInfo,
  UploadFileRequest,
  UploadFileResponse,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import React, { ReactElement, useEffect, useState } from "react";
import {
  bytesToSize,
  fileToUint8Array,
  getFileExtension,
  getFileName,
} from "utils/helpers";
import { useUploadManagerStyles } from "./UploadManager.style";

interface Props {
  uploadFile: UploadFile;
}

export interface UploadEventResponse {
  code: number;
  response: string;
}
//3145728 : 3mb 2097152: 2mb 1048576: 1mb 524288: 512KB;
const maxSize = 3145728;

function UploadListItem({
  uploadFile: { file: uploadFile, objectPath, token: fileToken },
}: Props): ReactElement {
  const classes = useUploadManagerStyles();
  const checkIconAnimation = useSpring({
    from: {
      opacity: 0,
      transform: "scale(2.5)",
    },
    to: {
      opacity: 1,
      transform: "scale(1)",
    },
  });

  /**
   * Uploads are divided into {@linkcode stage}
   * Stage 0: Initial/idle Stage
   * Stage 1: Parsing to binary
   * Stage 2: Initalizing upload
   * Stage 3: Uploading progress
   * Stage 4: Upload successful
   * Stage 5: Upload failed
   * Stage 6: Parsing Error
   * Stage 7: Failed to initalize upload
   * Stage 8: Error while uploading
   */
  const [stage, setStage] = useState<number>(0);
  const [currentChunk, setCurrentChunk] = React.useState<number>(0);
  const [totalChunks, setTotalChunks] = React.useState<number>(0);
  const [uploadedURL, setUploadedURL] = React.useState<string | null>(null);
  useEffect(() => {
    fileToUint8Array(uploadFile)
      .then((binaryData) => {
        setStage(2);
        initUpload(binaryData);
      })
      .catch(() => {
        setStage(6);
      });
  }, []);

  const initUpload = (binaryData: Uint8Array) => {
    if (uploadFile) {
      const uploadRequest = new UploadFileRequest();
      const fileInfo = new FileInfo();
      fileInfo.setName(getFileName(uploadFile.name));
      fileInfo.setFormat(getFileExtension(uploadFile.name));
      fileInfo.setSize(uploadFile.size);
      fileInfo.setToken(fileToken);
      fileInfo.setObjectpath(objectPath);
      fileInfo.setChunkrange(`0-${maxSize - 1}`);
      fileInfo.setType(uploadFile.type);
      uploadRequest.setInfo(fileInfo);
      if (uploadFile.size > maxSize) {
        const _totalChunks = Math.ceil(binaryData.byteLength / maxSize);
        setTotalChunks(_totalChunks);
        setStage(2);
        uploadRequest.setFile(binaryData.slice(0, maxSize));
        invokeUploadRequest(uploadRequest, 0, _totalChunks, binaryData);
      } else {
        uploadRequest.setFile(binaryData);
        invokeUploadRequest(uploadRequest, 0, fileInfo.getSize(), binaryData);
      }
    }
  };

  const uploadSubsequentChunks = (
    uploadRequest: UploadFileRequest,
    currentChunk: number,
    totalChunks: number,
    binaryData: Uint8Array
  ) => {
    setCurrentChunk(currentChunk);
    const _uploadRequest = uploadRequest;
    const _fileInfo = uploadRequest.getInfo();
    if (_fileInfo) {
      const firstByteIndex = maxSize * currentChunk;
      const lastByteIndex = maxSize * (currentChunk + 1) - 1;
      _fileInfo.setChunkrange(
        `${firstByteIndex}-${
          lastByteIndex < _fileInfo.getSize()
            ? lastByteIndex
            : _fileInfo.getSize() - 1
        }`
      );
      _uploadRequest.setInfo(_fileInfo);
      binaryData &&
        _uploadRequest.setFile(
          binaryData.slice(
            maxSize * currentChunk,
            lastByteIndex < _fileInfo.getSize()
              ? maxSize * (currentChunk + 1)
              : _fileInfo.getSize()
          )
        );
      invokeUploadRequest(
        _uploadRequest,
        currentChunk,
        totalChunks,
        binaryData
      );
    } else {
      console.error("No File info found in subsequent uploads");
    }
  };

  const invokeUploadRequest = async (
    uploadRequest: UploadFileRequest,
    currentChunk: number,
    totalChunks: number,
    binaryData: Uint8Array
  ) => {
    console.log(`currentChunk: ${currentChunk}, totalChunks: ${totalChunks}`);
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    grpc.invoke(GamificationService.UploadFile, {
      request: uploadRequest,
      host: GAMIFICATION_SERVICE_HOST,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onMessage: (res: UploadFileResponse) => {
        stage < 3 && setStage(3);
        if (res.getCode() === 200 || res.getCode() === 201) {
          setStage(4);
          setUploadedURL(res.getUrl());
          const _event = new CustomEvent<UploadEventResponse>(fileToken, {
            detail: {
              code: res.getCode(),
              response: res.getUrl(),
            },
          });
          window.dispatchEvent(_event);
        } else {
          console.log(`${(currentChunk / totalChunks) * 100}%`);
          uploadSubsequentChunks(
            uploadRequest,
            currentChunk + 1,
            totalChunks,
            binaryData
          );
        }
      },
      onEnd: (code: Code, message: string, trailers: Metadata) => {
        if (code) {
          resetUpload();
          console.error({ code, message, trailers });
          const _event = new CustomEvent<UploadEventResponse>(fileToken, {
            detail: {
              code: code,
              response: message,
            },
          });
          window.dispatchEvent(_event);
        }
      },
    });
  };
  const resetUpload = () => {
    setStage(0);
    setCurrentChunk(0);
    setTotalChunks(0);
  };

  function renderSpinner() {
    const size = 30;
    switch (stage) {
      case 1:
      case 2:
        return <CircularProgress size={size} />;
      case 3:
        return (
          <CircularProgress
            size={size}
            variant="determinate"
            value={Math.round((currentChunk / totalChunks) * 100)}
          />
        );
      case 4:
        return (
          <animated.div style={checkIconAnimation}>
            <CheckCircleRounded
              className={classes.successCheckIcon}
              fontSize="large"
            />
          </animated.div>
        );
      default:
      case 0:
        return <></>;
    }
  }

  function renderMessage() {
    switch (stage) {
      case 1:
        return (
          <>
            Parsing to binary &nbsp;&#8212;&nbsp;
            <i>{bytesToSize(uploadFile.size || 0)}</i>
          </>
        );
      case 2:
        return (
          <>
            Initalizing connection &nbsp;&#8212;&nbsp;
            <i>{bytesToSize(uploadFile.size || 0)}</i>
          </>
        );
      case 3:
        return (
          <>
            Uploading &nbsp;&#8212;&nbsp;
            <i>
              {bytesToSize(currentChunk * maxSize)}/
              {bytesToSize(uploadFile.size || 0)}
            </i>
          </>
        );
      case 4:
        return (
          <>
            Uploaded Successfully &nbsp;&#8212;&nbsp;
            <i>
              <Link href={uploadedURL || "#"} target="_blank">
                View File
              </Link>
            </i>
          </>
        );
      case 0:
      default:
        return (
          <>
            Ready to upload &nbsp;&#8212;&nbsp;
            <i>{bytesToSize(uploadFile.size || 0)}</i>
          </>
        );
    }
  }

  return (
    <ListItem divider className="pt-3 pb-3">
      <Grid container spacing={1}>
        <Grid item md={10} xs={10} lg={10}>
          <Breadcrumbs maxItems={4} aria-label="breadcrumb">
            <Typography
              variant="overline"
              noWrap
              component="small"
              color="primary"
            >
              bucket
            </Typography>
            {objectPath &&
              objectPath.split("/").map((i, x) => (
                <Typography
                  variant="overline"
                  noWrap
                  component="small"
                  color="primary"
                  key={i + x}
                >
                  {i}
                </Typography>
              ))}
            <br />
          </Breadcrumbs>
          <Typography variant="body1" noWrap component="p" color="textPrimary">
            {uploadFile.name}
          </Typography>
          <Typography
            variant="subtitle2"
            className="MuiFormHelperText-root MuiFormHelperText-contained m-0 pt-2"
            component="p"
          >
            {renderMessage()}
          </Typography>
        </Grid>
        <Grid
          item
          md={2}
          xs={2}
          lg={2}
          container
          alignItems="center"
          justifyContent="center"
        >
          {renderSpinner()}
        </Grid>
      </Grid>
    </ListItem>
  );
}

function areEqual(prevProps: Props, nextProps: Props) {
  const {
    uploadFile: {
      file: { lastModified: prevMod, name: prevName },
    },
  } = prevProps;
  const {
    uploadFile: {
      file: { lastModified: nextMod, name: nextName },
    },
  } = nextProps;
  return `${prevName}_${prevMod}` === `${nextName}_${nextMod}`;
}

export default React.memo(UploadListItem, areEqual);
