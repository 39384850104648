import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  FileInfo,
  UploadFileRequest,
  UploadFileResponse,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import {
  DurationFile,
  ID,
  PurposeFileDetails,
  RequestForGetAllMeditationFiles,
  RequestGetAllPurposeFileDetails,
  RequestPageable,
  ResponseAllPurposeFiles,
  SelectedFileDetailsResponseV2,
  SelectedMeditationFiles,
} from "api/models/guided-meditation-service/guided-meditation_pb";
import { GuidedMeditationService } from "api/models/guided-meditation-service/guided-meditation_pb_service";
import {
  GAMIFICATION_SERVICE_HOST,
  GUIDED_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { AppThunk } from "redux/store/store";
import {
  meditationListError,
  meditationListLoading,
  meditationListUpdated,
  meditationListUpdateImage,
  purposeFileListUpdate,
  purposeFileListUpError,
  purposeFileListUpLoading,
  purposeMeditationListError,
  purposeMeditationListLoading,
  purposeMeditationListUpdate,
  setCurrentDurationFile,
  setPurposeFile,
} from "../reducers/guidedMeditationReducer";
// interface AddMeditation {
//   meditationType: 0 | 1 | 2 | 3 | 4;
//   title: string;
//   subTitle: string;
//   description: string;
//   duration: number;
//   tag: string;
//   rating: number;
//   likes: number;
//   audioUrl: string;
//   imageUrl: string;
// }
// interface updateMed {
//   id: number;
//   meditationType: 0 | 1 | 2 | 3 | 4;
//   title: string;
//   subTitle: string;
//   description: string;
//   duration: number;
//   tag: string;
//   rating: number;
//   likes: number;
//   audioUrl: string;
//   imageUrl: string;
// }
export const getDurationDetailsbyID =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      const durationReq: ID = new ID();
      durationReq.setId(id);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GuidedMeditationService.getDurationFileDetailsById, {
        request: durationReq,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<DurationFile>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("duration file", message.toObject());
              dispatch(setCurrentDurationFile(res.message.toObject()));
            }
          }
        },
      });
    } catch (err) {
      console.error(err);
    }
  };
export const getAllMeditation =
  (
    pageSize: number,
    pageNumber: number,
    meditationType: 0 | 1 | 2 | 3 | 4
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(meditationListLoading());
      const meditationReq: RequestForGetAllMeditationFiles =
        new RequestForGetAllMeditationFiles();
      const pageable = new RequestPageable();
      pageable.setPagenumber(pageNumber);
      pageable.setPagesize(pageSize);
      meditationReq.setRequestpageable(pageable);
      meditationReq.setMeditationtype(meditationType);
      // meditationReq.setPagesize(pageSize);
      // meditationReq.setPagenumber(pageNumber);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      console.log("request data->", meditationReq.toObject());
      grpc.unary(GuidedMeditationService.getAllMeditationFileDetails, {
        request: meditationReq,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<SelectedMeditationFiles>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log(
                "data coming from get all  meditation",
                message.toObject()
              );
              dispatch(
                meditationListUpdated(
                  res.message.toObject().selectedmeditationfileList
                )
              );
            }
          } else {
            dispatch(meditationListUpdated([]));
          }
        },
      });
    } catch (err) {
      dispatch(meditationListError());
      console.error(err);
    }
  };
// export const getAllPurposeMeditation=
// (
//   pageSize: number,
//   pageNumber: number,
//   meditationType: 0 | 1 | 2 | 3 | 4
// ): AppThunk =>
// async (dispatch) => {
//   try {
//     dispatch(purposeMeditationListLoading());
//     const meditationReq: RequestForGetAllMeditationFiles =
//       new RequestForGetAllMeditationFiles();
//     const pageable = new RequestPageable();
//     pageable.setPagenumber(pageNumber);
//     pageable.setPagesize(pageSize);
//     meditationReq.setRequestpageable(pageable);
//     meditationReq.setMeditationtype(meditationType);
//     // meditationReq.setPagesize(pageSize);
//     // meditationReq.setPagenumber(pageNumber);
//     const jwtToken = await firebaseAuth.currentUser?.getIdToken();
//     //console.log("request data->", meditationReq.toObject());
//     grpc.unary(GuidedMeditationService.getAllMeditationFileDetails, {
//       request: meditationReq,
//       host: GUIDED_MEDITATION_SERVICE_HOST,
//       metadata: {
//         Authorization: `Bearer ${jwtToken}`,
//       },
//       onEnd: (res: UnaryOutput<SelectedMeditationFiles>) => {
//         const { status, message } = res;
//         if (status === grpc.Code.OK && message) {
//           if (res.message) {
//             console.log(
//               "data coming from get all  meditation",
//               message.toObject()
//             );
//             dispatch(
//               purposeMeditationListUpdate(
//                 res.message.toObject().selectedmeditationfileList
//               )
//             );
//           }
//         } else {
//           dispatch(purposeMeditationListUpdate([]));
//         }
//       },
//     });
//   } catch (err) {
//     dispatch(purposeMeditationListError());
//     console.error(err);
//   }
// };

export const getAllPurposeFile =
  (
    language: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21
  ): AppThunk =>
  async (dispatch) => {
    try {
      console.log("language selected", language);
      dispatch(purposeFileListUpLoading());
      const reqBody: RequestGetAllPurposeFileDetails =
        new RequestGetAllPurposeFileDetails();
      reqBody.setUsertype(2);
      reqBody.setSelectedlanguage(language);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GuidedMeditationService.getAllPurposeFileDetails, {
        request: reqBody,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseAllPurposeFiles>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log(
                "data coming from get allpurposefileDetails",
                message.toObject()
              );
              dispatch(
                purposeFileListUpdate(
                  res.message.toObject().listofpurposefileList
                )
              );
            }
          }
        },
      });
    } catch (err) {
      dispatch(purposeFileListUpError());
      console.error(err);
    }
  };

export const getPurposeFileDetailsById =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      const reqBody: ID = new ID();
      reqBody.setId(id);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GuidedMeditationService.getPurposeFileDetailsById, {
        host: GUIDED_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<PurposeFileDetails>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            dispatch(setPurposeFile(message.toObject()));
          }
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

// export const getMeditationAsync = (): AppThunk => async (dispatch) => {
//   try {
//     const meditationReq = new SelectMeditationFiles();
//     meditationReq.setDuration(10);
//     meditationReq.setMeditationtype(0);
//     meditationReq.setPurpose("");
//     meditationReq.setUsertype(0);
//     dispatch(meditationListLoading());
//     const jwtToken = await firebaseAuth.currentUser?.getIdToken();
//     console.log(`Bearer ${jwtToken}`);
//     grpc.unary(GuidedMeditationService.GetMeditationFiles, {
//       request: meditationReq,
//       host: GUIDED_MEDITATION_SERVICE_HOST,
//       metadata: {
//         Authorization: `Bearer ${jwtToken}`,
//       },
//       onEnd: (res: UnaryOutput<SelectedFiles>) => {
//         const { status, message } = res;
//         if (status === grpc.Code.OK && message) {
//           if (res.message) {
//             dispatch(
//               meditationListUpdated(
//                 res.message.toObject().selectedmeditationfileList
//               )
//             );
//           }
//         }
//       },
//     });
//   } catch (err) {
//     dispatch(meditationListError());
//     console.error(err);
//   }
// };

export const upload =
  (data: any): AppThunk =>
  async (dispatch) => {
    console.log("data in upload", data);
    const payload = new UploadFileRequest();
    const fileInfo = new FileInfo();
    payload.setFile(data.icon);
    fileInfo.setName(data.name);
    fileInfo.setFormat(data.format);
    payload.setInfo(fileInfo);
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    console.log(`Bearer ${jwtToken}`);
    grpc.unary(GamificationService.UploadFile, {
      request: payload,
      host: GAMIFICATION_SERVICE_HOST,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<UploadFileResponse>) => {
        const { status, statusMessage, headers, message, trailers } = res;
        console.log("statusMessage", statusMessage);
        console.log("headers", headers);
        console.log("trailers", trailers);
        if (status === grpc.Code.OK && message) {
          console.log("all ok ", message.toObject());
          dispatch(meditationListUpdateImage(message?.toObject().url));
          return message.toObject;
        }
      },
    });
    // console.log('final result', result)
  };

export const getAllMeditationFileDetailsV2 =
  (
    pageSize: number,
    pageNumber: number,
    meditationType: 0 | 1 | 2 | 3 | 4
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(purposeMeditationListLoading());
      const meditationReq: RequestForGetAllMeditationFiles =
        new RequestForGetAllMeditationFiles();
      const pageable = new RequestPageable();
      pageable.setPagenumber(pageNumber);
      pageable.setPagesize(pageSize);
      meditationReq.setRequestpageable(pageable);
      meditationReq.setMeditationtype(meditationType);
      // meditationReq.setPagesize(pageSize);
      // meditationReq.setPagenumber(pageNumber);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      //console.log("request data->", meditationReq.toObject());
      grpc.unary(GuidedMeditationService.GetAllMeditationFileDetailsV2, {
        request: meditationReq,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<SelectedFileDetailsResponseV2>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log(
                "data coming from get all  meditation",
                message.toObject()
              );
              dispatch(purposeMeditationListUpdate(res.message.toObject()));
            }
          } else {
            dispatch(
              purposeMeditationListUpdate({
                meditationfiledetailsv2List: [],
                totalcount: 0,
              })
            );
          }
        },
      });
    } catch (err) {
      dispatch(purposeMeditationListError());
      console.error(err);
    }
  };
