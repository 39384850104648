import React, { useEffect } from "react";
import { Box, Card, ListItem, ListItemText, Skeleton } from "@mui/material";
import { styled } from "@mui/styles";
import ReactDevicePreview from "react-device-preview";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/store";

const StyledDiv = styled("div")({
  marginTop: "5vh",
  marginLeft: "2vw",
  // height: "80vh",
  overflow: "hidden",
  padding: 10,
  width: "38vw",
  display: "flex",
  justifyContent: "center",
});

interface LayoutPreviewProps {
  data: [];
}
export default function LayoutPreview(props: LayoutPreviewProps) {
  const carouselsList = useSelector(
    (state: RootState) => state.layout.layoutManager.carousels.carouselList
  );
  const [carouselsData, setCarouselsData] = React.useState(carouselsList);

  useEffect(() => {
    setCarouselsData(props.data);
  }, [props]);
  return (
    <StyledDiv>
      <ReactDevicePreview scale="0.9">
        <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "scroll",
            background: "#FBF6FB",
            paddingTop: "9%",
          }}
        >
          {carouselsData.map((el: any, idx: number) => {
            if (el.title === "Banner") {
              return (
                <Box
                  key={idx}
                  sx={{
                    height: "25%",
                    borderRadius: "5px",
                    background: "#ffffff",
                    ml: 1,
                    mr: 1,
                  }}
                >
                  <ListItem>
                    <ListItemText primary={el.title} />
                  </ListItem>
                </Box>
              );
            } else {
              return (
                <Box
                  key={idx}
                  sx={{
                    height: "25%",
                    borderRadius: "5px",
                    background: "#ffffff",
                    m: 1,
                    overflowX: "scroll",
                  }}
                >
                  <ListItem>
                    <ListItemText primary={el.title} />
                  </ListItem>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "max-content",
                    }}
                  >
                    {Array(5)
                      .fill(0)
                      .map((i, x) => (
                        <Card
                          sx={{ width: "200px", m: 1 }}
                          key={`MedSkele${x}`}
                        >
                          <ListItem>
                            <Skeleton width="90%" animation={false} />
                          </ListItem>
                          <ListItem>
                            <Skeleton width="80%" animation={false} />
                          </ListItem>
                          <ListItem>
                            <Skeleton width="70%" animation={false} />
                          </ListItem>
                        </Card>
                      ))}
                  </Box>
                </Box>
              );
            }
          })}
        </div>
      </ReactDevicePreview>
    </StyledDiv>
  );
}
