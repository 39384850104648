import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Alert,
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { firebaseAuth } from "config/firebase";
import { grpc } from "@improbable-eng/grpc-web";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import { HFN_EVENTS_SERVICE_HOST } from "api/serviceEndpoints";
import {
  LostAndFoundDetails,
  LostAndFoundRequest,
} from "api/models/hfn-events-service/hfn-events_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { useDispatch } from "react-redux";
import { getAllLostAndFoundItems } from "redux/actions/bhandaraActions";
const PREFIX = "CreateEvent";
const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
};
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    maxWidth: 350,
    // overflow: "scroll",
  },
  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `10%`,
    left: `40%`,
  },
}));

const phoneRegex = /^((\+)?(\d{2}[-]))?(\d{10}){1}?$/;

const AddLostAndFound = React.forwardRef<HTMLDivElement, any>((props, ref) => {
  const [itemDescription, setItemDescription] = useState<string>("");
  const [reportTime, setReportTime] = useState(Date.now());
  const [location, setLocation] = useState<string>("");
  const [contactNumber, setContactNumber] = useState<string>("");
  const [statusType, setStatusType] = useState<0 | 1 | 2 | 3>(1); // 1 is for lost and 2 is for found
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });
  const [isAdding, setIsAdding] = useState(false);

  const dispatch = useDispatch();

  const validateData = () => {
    if (itemDescription.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Please provide an item description",
      });
    } else if (location.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Location can't be empty",
      });
    } else if (!contactNumber.match(phoneRegex)) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Enter a valid 10 digit number.",
      });
    } else {
      return true;
    }
  };
  const _addItem = async () => {
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const reqBody: LostAndFoundRequest = new LostAndFoundRequest();

    const googleTimeStamp: Timestamp = new Timestamp();
    googleTimeStamp.setSeconds(Math.round(reportTime / 1000));

    reqBody.setDescription(itemDescription);
    reqBody.setKeylocation(location);
    reqBody.setContactnumber(contactNumber);
    reqBody.setBhandarahelplinetype(statusType);
    reqBody.setCreatedat(googleTimeStamp);

    console.log("add Lost and found item req", reqBody.toObject());
    grpc.unary(HFNEventsService.AddHelpLine, {
      host: HFN_EVENTS_SERVICE_HOST,
      request: reqBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<LostAndFoundDetails>) => {
        const { status, statusMessage, message } = res;
        if (status === grpc.Code.OK && message) {
          setSnackBar({
            open: true,
            severity: "success",
            message: "Lost and Found entry created successfully",
          });
          dispatch(getAllLostAndFoundItems(100, 0, statusType));
          setTimeout(props.onClickBack, 1500);
        } else {
          setSnackBar({
            open: true,
            severity: "error",
            message:
              "Something went wrong while creating the lost and found entry",
          });
          console.error("error in adding lost and found item\n", statusMessage);
        }
      },
    });
  };
  const onClickAddItem = () => {
    if (validateData()) {
      setIsAdding(true);
      _addItem();
    }
  };
  return (
    <Root ref={ref}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        style={{
          padding: " 8px",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Grid
            container
            className={props.isEditing ? "" : "align-items-center"}
          >
            <Grid item>
              <IconButton onClick={props.onClickBack} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">Report Lost & Found Items</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid className="d-flex">
              <TextField
                fullWidth
                required
                type="text"
                name="item-description"
                label="Item Description"
                variant="outlined"
                onChange={(e) => {
                  setItemDescription(e.target.value);
                }}
                value={itemDescription}
                className="mr-3"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid className="d-flex">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  inputFormat="dd/MM/yyyy hh:mm a"
                  label="Schedule Date And Time"
                  renderInput={(params) => (
                    <TextField fullWidth className="mr-3" {...params} />
                  )}
                  disablePast={true}
                  value={reportTime}
                  onChange={(value: any) => {
                    setReportTime(Date.parse(value));
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid className="d-flex">
              <TextField
                fullWidth
                required
                type="text"
                name="location"
                label="Location"
                variant="outlined"
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                value={location}
                className="mr-3"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid className="d-flex">
              <Typography sx={{ mr: 5, mt: 1 }}>Type: </Typography>
              <ToggleButtonGroup
                color="primary"
                value={statusType}
                size="medium"
                exclusive
                onChange={(e, val) => setStatusType(val)}
              >
                <ToggleButton value={1}>Lost</ToggleButton>
                <ToggleButton value={2}>Found</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid className="d-flex">
              <TextField
                fullWidth
                // required
                type="text"
                name="contact-number"
                label="Contact Number"
                variant="outlined"
                error={!contactNumber.match(phoneRegex)}
                helperText={"Enter a valid 10 digit number"}
                onChange={(e) => {
                  setContactNumber(e.target.value);
                }}
                value={contactNumber}
                className="mr-3"
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className=" p-2 d-flex justify-content-end">
        <Box display="flex" justifyContent="flex-end">
          <Button
            disabled={isAdding}
            onClick={onClickAddItem}
            color="primary"
            variant="contained"
          >
            Add Item
          </Button>
        </Box>
      </CardActions>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Root>
  );
});

export default AddLostAndFound;
