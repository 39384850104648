import { createSlice } from "@reduxjs/toolkit";
import { TagsList } from "api/models/gamification-service/gamification_pb";

interface tagListType {
  tagList: {
    loading: boolean;
    tags: TagsList.AsObject;
  };
}

const initialState: tagListType = {
  tagList: {
    loading: true,
    tags: {
      tagsList: [],
    },
  },
};

export const tagSlice = createSlice({
  name: "tagList",
  initialState,
  reducers: {
    tagsListIsLoading(state, action) {
      state.tagList.loading = action.payload;
    },
    setTagsList(state, action) {
      state.tagList.tags = action.payload;
    },
  },
});

export const { tagsListIsLoading, setTagsList } = tagSlice.actions;

export default tagSlice.reducer;
