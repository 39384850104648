import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  FetchUserByGroupIdRequest,
  Status,
} from "api/models/heartintune-profile-service/heartintune-profile_pb";
import {
  GetAllPreRecordedResponse,
  GroupIdRequest,
  ListOfGroupOccurrences,
  ListOfGroups,
  PublishOrUnPublishGroupRequest,
  RequestGroupsBetweenDateRange,
  RequestPageable,
} from "api/models/live-meditation-service/live-meditation_pb";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import { LIVE_MEDITATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";
import {
  occurrenceListUpdate,
  scheduledVideosListError,
  scheduledVideosListLoading,
  setScheduledVideosListUpdate,
  setVideosListError,
  setVideosListLoading,
  setVideosListUpdate,
} from "redux/reducers/groupsReducer";
import { AppThunk } from "redux/store/store";

export const getAllPreRecordedVideos =
  (page: number, rowsPerPage: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setVideosListLoading());
      const reqBody: RequestPageable = new RequestPageable();
      reqBody.setPagenumber(page);
      reqBody.setPagesize(rowsPerPage);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      console.log("token", jwtToken);
      grpc.unary(LiveMeditationService.GetAllPreRecorded, {
        request: reqBody,
        host: LIVE_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<GetAllPreRecordedResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log(
              "response from get all pre-recorded-videos",
              message.toObject()
            );
            dispatch(setVideosListUpdate(message.toObject()));
          } else {
            console.log(
              `Error in fetching pre-recorded videos ${statusMessage}`
            );
            dispatch(setVideosListError());
          }
        },
      });
    } catch (error) {
      dispatch(setVideosListError());
      console.log(`Error pre-recorded videos ${error}`);
    }
  };

export const getAllGroups =
  (page: number, rowsPerPage: number, isUnpublished: boolean): AppThunk =>
  async (dispatch) => {
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const reqBody: RequestPageable = new RequestPageable();
      reqBody.setPagenumber(page);
      reqBody.setPagesize(rowsPerPage);
      reqBody.setIsunpublished(isUnpublished);

      grpc.unary(LiveMeditationService.GetAllGroups, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: { Authorization: `Bearer ${jwtToken}` },
        onEnd: (res: UnaryOutput<ListOfGroups>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("Get all groups response", message.toObject());
              dispatch(setScheduledVideosListUpdate(message.toObject()));
            }
          }
        },
      });
    } catch (error) {
      dispatch(scheduledVideosListError());
      console.error("error in getting groups", error);
    }
  };

export const unpublishGroup =
  (
    groupId: number,
    page: number,
    rowsPerPage: number,
    isUnpublished: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(scheduledVideosListLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const reqBody: PublishOrUnPublishGroupRequest =
        new PublishOrUnPublishGroupRequest();

      reqBody.setGroupid(groupId);
      reqBody.setIspublish(isUnpublished);

      console.log("request for unpulishing group", reqBody.toObject());
      grpc.unary(LiveMeditationService.PublishOrUnPublishGroup, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          if (res.message?.toObject().success === true) {
            dispatch(getAllGroups(page, rowsPerPage, isUnpublished));
          } else {
            console.error("Not able to unpublish", res);
            dispatch(getAllGroups(page, rowsPerPage, isUnpublished));
          }
        },
      });
    } catch (err) {
      dispatch(scheduledVideosListError());
      console.error("Error in unpublishing group", err);
    }
  };

export const getAllScheduledVideoBetweenDates =
  (
    startDate: string,
    endDate: string,
    page: number,
    rowsPerPage: number
  ): AppThunk =>
  async (dispatch) => {
    // const end = moment(endDate).endOf("day").format("");
    console.log("start Date", moment(startDate).format(""));

    try {
      const end = moment(endDate).endOf("day").format("");
      console.log("end Date", end);
      dispatch(scheduledVideosListLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqPagable: RequestPageable = new RequestPageable();
      reqPagable.setPagenumber(page);
      reqPagable.setPagesize(rowsPerPage);

      const reqBody: RequestGroupsBetweenDateRange =
        new RequestGroupsBetweenDateRange();
      const currentGoogleTimeStamp: Timestamp = new Timestamp();
      const endGoogleTimeStamp: Timestamp = new Timestamp();

      currentGoogleTimeStamp.fromDate(new Date(startDate));
      endGoogleTimeStamp.fromDate(new Date(end));

      reqBody.setStartdate(currentGoogleTimeStamp);
      reqBody.setEnddate(endGoogleTimeStamp);
      reqBody.setPageable(reqPagable);

      console.log("reqbody", reqBody.toObject());

      grpc.unary(LiveMeditationService.GetAllGroupsBetweenDateRange, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ListOfGroups>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "response from get all sceduled-pre-recorded-videos",
                message.toObject()
              );
              dispatch(setScheduledVideosListUpdate(message.toObject()));
            }
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(scheduledVideosListError());
    }
  };

export const getAllOccurrencesById =
  (
    groupId: number,
    startDate: string,
    endDate: string,
    page: number,
    rowsPerPage: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const reqPagable: RequestPageable = new RequestPageable();
      reqPagable.setPagenumber(page);
      reqPagable.setPagesize(rowsPerPage);

      const startGoogleTimeStamp: Timestamp = new Timestamp();
      const endGoogleTimeStamp: Timestamp = new Timestamp();

      startGoogleTimeStamp.fromDate(new Date(startDate));
      endGoogleTimeStamp.fromDate(new Date(endDate));

      const reqBody: GroupIdRequest = new GroupIdRequest();
      reqBody.setGroupid(groupId);
      reqBody.setStartdate(startGoogleTimeStamp);
      reqBody.setEnddate(endGoogleTimeStamp);
      reqBody.setPageable(reqPagable);

      console.log("reqBody: ", reqBody.toObject());

      grpc.unary(LiveMeditationService.GetAllGroupOccurrencesBetweenDateRange, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ListOfGroupOccurrences>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "response from get all occurrences",
                message.toObject()
              );
              dispatch(occurrenceListUpdate(message.toObject()));
            }
          }
        },
      });
    } catch (err) {
      console.error("Error in fetching group occurrences", err);
    }
  };
