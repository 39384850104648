import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import {
  Divider,
  FormControl,
  Typography,
  TextField,
  Grid,
  Box,
  Grow,
  IconButton,
  Paper,
  TablePagination,
  Tooltip,
  Snackbar,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import DatePicker from "@mui/lab/DatePicker";
import { RootState } from "redux/store/store";
import {
  getAllBenifitsBySessionId,
  getAllLiveMeditationsBetweenDates,
  getAllQuestionBySessionID,
} from "redux/actions/liveMeditationAction";
import RefreshIcon from "@mui/icons-material/Refresh";
import style from "./index.module.scss";
import UpdateSession from "./updateSession";
import SessionBenifits from "./sessionBenifits";
import ScheduleSession from "./scheduleSession";
import SingleCardComponent from "./SingleCardComponent";
import EmailModal from "components/notificationModal/emailModal";
import { ListSkeleton } from "components/listSkeleton";
import ViewQuestions from "./viewQuestions";
import ViewFeedback from "./viewFeedback";
import { Alert } from "@mui/material";
import { firebaseAuth } from "config/firebase";
import "firebase/remote-config";
import firebase from "firebase/app";
import { grpc } from "@improbable-eng/grpc-web";
import {
  LiveMeditationService,
} from "api/models/live-meditation-service/live-meditation_pb_service";
import {
  Empty,
  ZoomUrlsResponse,
} from "api/models/live-meditation-service/live-meditation_pb";
import { LIVE_MEDITATION_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { styled } from "@mui/material";
import { useAuth } from "services/AuthService";
import QuestionsCard from "./questionscard";
import MoreInfo from "./MoreInfo";
import { MenuProps } from "styles/global.styles";

const TableWrapper = styled("div")({
  margin: "16px",
  // flex: "1 1 auto",
  height: "100%",
  // width: "100vw",
  overflow: "auto",
  // border: "1px solid rgba(0, 0, 0, 0.12)",
  // borderRadius: "5px",
  // width: "110%",
});
interface State {
  meditationList?: Array<any>;
  loadingMeditation?: boolean;
  editing?: boolean;
  loadingImage?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number;
  badgeNameLang?: any;
}
interface duplicateState {
  editing?: boolean;
  editItem?: any;
  coachId?: number;
  type?: string;
}
interface email {
  showModal?: boolean;
  userEmail?: string | null;
}

const UpcomingLiveMeditation = React.forwardRef<HTMLDivElement, any>(
  (
    {
      onClickScheduleSession,
      setstartDate,
      startDate,
      setendDate,
      endDate,
      endDateTime,
      setendDateTime,
    },
    ref
  ): ReactElement => {
    const dispatch = useDispatch();
    //const classes = useStyles();
    const state = useSelector((state: RootState) => state.liveMeditation);
    const languages = useSelector((state: RootState) => state.app.languages);

    const initialState = {
      editing: false,
      editItem: null,
      showDeleteModal: false,
      deleteItemId: 0,
    };
    const initialStates = {
      editing: false,
      editItem: null,
      coachId: 0,
    };
    const userDetails = {
      showModal: false,
      userEmail: "",
    };
    const [userData, setUserData] = useState<any>(
      JSON.parse(localStorage.getItem("user")!)
    );
    const currentUser = useAuth().currentUser;
    const [openEmailPopup, setopenEmailPopup] = useState<email>(userDetails);
    const [currentIndex, setcurrentIndex] = useState(0);
    const [searchLiveSession, setsearchLiveSession] = useState("");
    const [sessionId, setsessionId] = useState(0);
    const [searchData, setsearchData] = useState<any[]>([]);
    const [sessionTitle, setsessionTitle] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [states, setStates] = useState<State>(initialState);
    const [sessionIdForEdit, setsessionIdForEdit] = useState(0);
    const [coachIdForEdit, setcoachIdForEdit] = useState(0);
    const [coachIdForViewQuestions, setcoachIdForViewQuestions] = useState(0);
    const [duplicateSession, setduplicateSession] =
      useState<duplicateState>(initialStates);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [meetingStartUrl, setMeetingStartUrl] = useState<string>();
    const [startingLiveSession, setStartingLiveSession] = useState<number>(0); // controls the time before which start broadcast button becomes active, value is fetched from firebase remote config
    const [liveSessionOn, setliveSessionOn] = useState<number>(0);
    const [liveSessionStop, setLiveSessionStop] = useState(0);
    const [infoItem, setInfoItem] = useState<any>();
    const [language, setLanguage] = useState<any>(1);

    const remoteConfig = firebase.remoteConfig();

    useEffect(() => {
      getStartUrl();

      const fetchRemoteConfigVals = () => {
        remoteConfig
          .fetchAndActivate()
          .then(() => {
            const val = remoteConfig
              .getValue("heartintune_app_config")
              .asString();
            const rcJSON = JSON.parse(val);
            setStartingLiveSession(rcJSON.startingLiveSession);
            setliveSessionOn(rcJSON.liveSessionOn);
            setLiveSessionStop(rcJSON.refreshMeditationTime);
          })
          .catch((err) => console.log("error in remote config\n", err));
      };
      fetchRemoteConfigVals();
      console.log("local storage user", userData);
    }, []);

    useEffect(() => {
      dispatch(getAllLiveMeditationsBetweenDates(startDate, endDate, language));
    }, [dispatch, endDate, language, startDate]);

    async function getStartUrl() {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: Empty = new Empty();
      grpc.unary(LiveMeditationService.getZoomUrl, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ZoomUrlsResponse>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(message.toObject());
              setMeetingStartUrl(message.getStartUrl());
            }
          }
        },
      });
    }

    const closeModal = () => {
      setopenEmailPopup({ showModal: false });
    };
    const openModal = (email: string) => {
      setopenEmailPopup({
        showModal: true,
        userEmail: email,
      });
    };
    const onClickBack = () => {
      // getAllLiveMeditationsBetweenDates(startDate, endDate)
      setcurrentIndex(0);
      setStates({
        editing: false,
        editItem: null,
      });
    };

    const onClickEdit = () => {
      setcurrentIndex(3);
    };

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
      dispatch(getAllLiveMeditationsBetweenDates(startDate, endDate, language));
    };

    const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    // function genToken() {
    //   const _header = { alg: "HS256", typ: "JWT" };
    //   const _payload = {
    //     iss: API_KEY,
    //     exp: Date.now() + 3600,
    //   };
    //   const removeIllegalCharacters = function (str: string) {
    //     return str.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
    //   };
    //   const base64object = function (input: any) {
    //     let inputWords = CryptoJS.enc.Utf8.parse(JSON.stringify(input));
    //     let base64 = CryptoJS.enc.Base64url.stringify(inputWords);

    //     return base64;
    //   };
    //   const unsignedToken =
    //     base64object(_header) + "." + base64object(_payload);
    //   const signatureHash = CryptoJS.HmacSHA256(unsignedToken, SECRET);
    //   const encSignature = CryptoJS.enc.Base64.stringify(signatureHash);
    //   const token = unsignedToken + "." + encSignature;
    //   return removeIllegalCharacters(token);
    // }
    const getSessionList = () => {
      let data = (
        searchLiveSession !== "" ? searchData : returnCoachBasedSessionList()
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      if (data.length === 0 && page > 0) {
        data = (
          searchLiveSession !== "" ? searchData : returnCoachBasedSessionList()
        ).slice(
          (page - 1) * rowsPerPage,
          (page - 1) * rowsPerPage + rowsPerPage
        );
        setPage(page - 1);
      }
      return data;
    };

    function returnCoachBasedSessionList() {
      return state.liveMeditationsListBetween.liveMeditationsBetween.sessioninfodetailsList.filter(
        (sessionlist) => {
          if (userData.role === 5) {
            return sessionlist.coachprofile?.email === currentUser?.email;
          }
          return true;
        }
      );
    }

    const renderLiveSessionsListBetween = () => (
      <div ref={ref}>
        {state.liveMeditationsListBetween.liveMeditationsBetween
          .sessioninfodetailsList.length ? (
          <div>
            {getSessionList().map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {console.log(item)}
                  <SingleCardComponent
                    item={item}
                    dispatch={dispatch}
                    getAllBenifitsBySessionId={getAllBenifitsBySessionId}
                    setcoachIdForEdit={setcoachIdForEdit}
                    setsessionIdForEdit={setsessionIdForEdit}
                    setStates={setStates}
                    setcurrentIndex={setcurrentIndex}
                    setduplicateSession={setduplicateSession}
                    setsessionId={setsessionId}
                    setsessionTitle={setsessionTitle}
                    getAllQuestionsBySessionID={getAllQuestionBySessionID}
                    openModal={openModal}
                    setcoachIdForViewQuestions={setcoachIdForViewQuestions}
                    meetingStartUrl={meetingStartUrl}
                    startingLiveSession={startingLiveSession}
                    liveSessionOn={liveSessionOn}
                    startDate={startDate}
                    endDate={endDate}
                    language={language}
                    liveSessionStop={liveSessionStop}
                    setSnackBar={setSnackBar}
                    setInfoItem={setInfoItem}
                  />
                </React.Fragment>
              );
            })}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={
                userData.role !== 5
                  ? searchLiveSession !== ""
                    ? searchData.length
                    : state.liveMeditationsListBetween.liveMeditationsBetween
                        .sessioninfodetailsList.length
                  : returnCoachBasedSessionList().length
              }
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </div>
        ) : (
          <Box height={400}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No live session to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
    // console.log(state.questionList.questions.questionList);

    return (
      <div ref={ref}>
        <Grow
          in={currentIndex === 0}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentIndex === 0 ? { timeout: 1000 } : {})}
        >
          <div>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                padding: " 16px",
              }}
            >
              <Grid item xs={12} sm={6} md={8} lg={6}>
                <Grid
                  container
                  spacing={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid item>
                    <Typography variant="h6">Live Sessions</Typography>
                  </Grid>
                  {/* <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        onClickScheduleSession();
                        dispatch(getCoachListAsync());
                      }}
                      startIcon={<AddIcon />}
                    >
                      Schedule Live Session
                    </Button>
                  </Grid> */}
                  {/* <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        window.open(
                          "https://docs.google.com/presentation/d/1llf-l82bs2FyR1_aY5E9-3V8OD5ET8EMKmNF3IQyFkc/edit#slide=id.p"
                        )
                      }
                    >
                      How to use
                    </Button>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6} className="my-1">
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  justifyContent="flex-end"
                  className={style.date}
                >
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      style={{ maxWidth: "150px" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          // autoOk
                          // variant="inline"
                          // okLabel={false}
                          // cancelLabel={false}
                          inputFormat="dd/MM/yyyy"
                          label="Start Date"
                          // size="small"
                          maxDate={new Date(endDate)}
                          value={startDate}
                          // error={false}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(value: any) => {
                            //moment.tz(scheduleTimeZone, 'YYYY-MM-DD HH:mm:ss', `${newValue}`).format("YYYY-MM-DD HH:mm:ss")
                            const time = moment(value)
                              .tz(moment.tz.guess(true))
                              .format("YYYY-MM-DD");
                            setstartDate(time);
                            const start = moment(time)
                              .startOf("day")
                              .format("YYYY-MM-DD");
                            dispatch(
                              getAllLiveMeditationsBetweenDates(
                                start,
                                endDate,
                                language
                              )
                            );
                          }}
                          // inputVariant="outlined"
                          // TextFieldComponent={(props) => (
                          //   <TextField variant="outlined" {...props} />
                          // )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    //className="mx-2"
                  ></Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      style={{ maxWidth: "150px" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          // autoOk
                          //variant="inline"
                          // okLabel={false}
                          // cancelLabel={false}
                          // error={false}
                          inputFormat="dd/MM/yyyy"
                          label="End Date"
                          // size="small"
                          minDate={new Date(startDate)}
                          value={endDate}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(value: any) => {
                            const time = moment(new Date(value))
                              .tz(moment.tz.guess())
                              .format("YYYY-MM-DD");
                            const end = moment(time)
                              .endOf("day")
                              .format("YYYY-MM-DD");
                            setendDate(end);
                            console.log("end", end);
                            dispatch(
                              getAllLiveMeditationsBetweenDates(
                                startDate,
                                end,
                                language
                              )
                            );
                          }}
                          // inputVariant="outlined"
                          // TextFieldComponent={(props) => (
                          //   <TextField variant="outlined" {...props} />
                          // )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3}>
              <FormControl variant="outlined" className="m-3" fullWidth>
                <InputLabel id="language-select">Language</InputLabel>
                {languages && (
                  <Select
                    defaultValue=""
                    labelId="language-select"
                    value={language}
                    label="Language"
                    onChange={(e) => {
                      setLanguage(e.target.value as number);
                    }}
                    MenuProps={MenuProps}
                  >
                    {languages.map((lang: any, i: any) => (
                      <MenuItem key={i} value={lang.code}>
                        {lang.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Divider />
            <TableWrapper>
              <Paper>
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: "transparent" }}
                  className="py-2"
                >
                  {userData.role !== 5 ? (
                    <Grid className="d-flex align-items-center">
                      <TextField
                        type="search"
                        fullWidth
                        value={searchLiveSession}
                        variant="outlined"
                        placeholder="Search by coach name or meditation name "
                        size="small"
                        onChange={(e) => {
                          setsearchLiveSession(e.target.value);
                          const filteredData =
                            state.liveMeditationsListBetween.liveMeditationsBetween.sessioninfodetailsList.filter(
                              (sessionlist) =>
                                sessionlist.coachprofile?.name
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase()) ||
                                sessionlist.sessioninfo?.sessiontitle
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                            );

                          setsearchData(filteredData);
                        }}
                        className="ml-3"
                      />
                      <Grid>
                        <IconButton
                          onClick={() => {
                            dispatch(
                              getAllLiveMeditationsBetweenDates(
                                startDate,
                                endDate,
                                language
                              )
                            );
                          }}
                          size="large"
                        >
                          <Tooltip title="Refresh">
                            <RefreshIcon />
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Grid>
                        <IconButton
                          onClick={() => {
                            dispatch(
                              getAllLiveMeditationsBetweenDates(
                                startDate,
                                endDate,
                                language
                              )
                            );
                          }}
                          size="large"
                        >
                          <Tooltip title="Refresh">
                            <RefreshIcon />
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {state.liveMeditationsListBetween.loading
                  ? ListSkeleton()
                  : renderLiveSessionsListBetween()}
              </Paper>
            </TableWrapper>
            <EmailModal
              open={openEmailPopup.showModal || false}
              onClose={closeModal}
              setSnackBar={setSnackBar}
              snackBar={snackBar}
              openEmailPopup={openEmailPopup}
              setopenEmailPopup={setopenEmailPopup}
              sessionId={sessionId}
            />
            <Snackbar
              open={snackBar.open}
              autoHideDuration={10000}
              onClose={() => {
                setSnackBar({ ...snackBar, open: false });
              }}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <Alert
                severity={snackBar.severity}
                onClose={() => {
                  setSnackBar({ ...snackBar, open: false });
                }}
              >
                {snackBar.message}
              </Alert>
            </Snackbar>
          </div>
        </Grow>
        <Grow
          in={currentIndex === 1}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentIndex === 1 ? { timeout: 1000 } : {})}
        >
          <ViewQuestions
            setcurrentIndex={setcurrentIndex}
            sessionId={sessionId}
            sessionTitle={sessionTitle}
            coachIdForViewQuestions={coachIdForViewQuestions}
          />
        </Grow>
        <Grow
          in={currentIndex === 2}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentIndex === 2 ? { timeout: 1000 } : {})}
        >
          <ScheduleSession
            coachId={coachIdForEdit}
            onClickBack={onClickBack}
            setLiveSessionId={setsessionId}
            isEditing={duplicateSession.editing}
            editItem={duplicateSession.editItem}
            setendDateTime={setendDateTime}
            setstartDate={setstartDate}
            //startDate={startDate}
            setendDate={setendDate}
            // duplicateSession={duplicateSession}
            setduplicateSession={setduplicateSession}
            type="copy"
            //onClickBackScheduleSession={onClickBackScheduleSession}
          />
        </Grow>

        <Grow
          in={currentIndex === 3}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentIndex === 3 ? { timeout: 1000 } : {})}
        >
          <UpdateSession
            onClickBack={onClickBack}
            isEditing={states.editing}
            editItem={states.editItem}
            setcurrentIndex={setcurrentIndex}
            setstartDate={setstartDate}
            setendDate={setendDate}
            startDate={startDate}
            endDate={endDate}
            dataLanguage={language}
          />
        </Grow>

        <Grow
          in={currentIndex === 4}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentIndex === 4 ? { timeout: 1000 } : {})}
        >
          <SessionBenifits
            sessionId={sessionIdForEdit}
            page="liveSession"
            onClickEdit={onClickEdit}
            coachId={coachIdForEdit}
          />
        </Grow>
        <Grow
          in={currentIndex === 5}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentIndex === 5 ? { timeout: 1000 } : {})}
        >
          <ViewFeedback
            setcurrentIndex={setcurrentIndex}
            sessionId={sessionId}
            sessionTitle={sessionTitle}
            coachIdForViewQuestions={coachIdForViewQuestions}
          />
        </Grow>
        <Grow
          in={currentIndex === 6}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentIndex === 6 ? { timeout: 1000 } : {})}
        >
          <QuestionsCard
            sessionId={sessionId}
            setcurrentIndex={setcurrentIndex}
            sessionTitle={sessionTitle}
          />
        </Grow>
        <Grow
          in={currentIndex === 7}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentIndex === 7 ? { timeout: 1000 } : {})}
        >
          <MoreInfo
            setcurrentIndex={setcurrentIndex}
            sessionId={sessionId}
            sessionTitle={sessionTitle}
            coachIdForViewQuestions={coachIdForViewQuestions}
            infoItem={infoItem}
          />
        </Grow>
      </div>
    );
  }
);

export default UpcomingLiveMeditation;
