// source: heartintune-profile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var device_pb = require('./device_pb.js');
goog.object.extend(proto, device_pb);
var stats_pb = require('./stats_pb.js');
goog.object.extend(proto, stats_pb);
var journal_pb = require('./journal_pb.js');
goog.object.extend(proto, journal_pb);
var tracker_pb = require('./tracker_pb.js');
goog.object.extend(proto, tracker_pb);
var notification_pb = require('./notification_pb.js');
goog.object.extend(proto, notification_pb);
var donation_pb = require('./donation_pb.js');
goog.object.extend(proto, donation_pb);
var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
var schedule_pb = require('./schedule_pb.js');
goog.object.extend(proto, schedule_pb);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.CampaignStat', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.CarouselDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.CircleRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.CircleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.CoachProfileV2', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.DailyMeditationData', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.Empty', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.FavoriteRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.Favourites', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.FcmToken', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.FetchStatsFor', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.Gender', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ID', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ListOfIDs', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.LoginRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.LoginResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.LoginStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.LogoutRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.LogoutResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.MoodDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.PaginationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.PaginationResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.Provider', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.QuotesResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RegistrationDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ReminderDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ReminderRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestUserID', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseUserStats', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.Role', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.SessionInfoV2', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.Status', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.SubscribedGoal', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.TestResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.TriggerDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.User', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserCircleRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserMoodDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserMoodMap', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.UserStatsInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.totalUsers', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.Empty.displayName = 'proto.unifiedplatform.heartIntuneProfile.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.TestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.TestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.TestResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.TestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.totalUsers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.totalUsers.displayName = 'proto.unifiedplatform.heartIntuneProfile.totalUsers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.User.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.User.displayName = 'proto.unifiedplatform.heartIntuneProfile.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.SubscribedGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.displayName = 'proto.unifiedplatform.heartIntuneProfile.SubscribedGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.displayName = 'proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.displayName = 'proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.LoginRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.LoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.LoginResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.LoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.LogoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.LogoutRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.LogoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.LogoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.LogoutResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.LogoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.FetchStatsFor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.displayName = 'proto.unifiedplatform.heartIntuneProfile.FetchStatsFor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserStatsInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserStatsInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.RegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ListOfIDs.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ListOfIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ListOfIDs.displayName = 'proto.unifiedplatform.heartIntuneProfile.ListOfIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.Status.displayName = 'proto.unifiedplatform.heartIntuneProfile.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ID.displayName = 'proto.unifiedplatform.heartIntuneProfile.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.Favourites = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.Favourites.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.Favourites, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.Favourites.displayName = 'proto.unifiedplatform.heartIntuneProfile.Favourites';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestUserID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestUserID.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestUserID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.UserInfo.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.FcmToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.FcmToken.displayName = 'proto.unifiedplatform.heartIntuneProfile.FcmToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseUserStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseUserStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.DailyMeditationData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.displayName = 'proto.unifiedplatform.heartIntuneProfile.DailyMeditationData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.displayName = 'proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.displayName = 'proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.displayName = 'proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.displayName = 'proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.CarouselDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.CarouselDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.CarouselDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.CarouselDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.SessionInfoV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.displayName = 'proto.unifiedplatform.heartIntuneProfile.SessionInfoV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.CoachProfileV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.displayName = 'proto.unifiedplatform.heartIntuneProfile.CoachProfileV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.displayName = 'proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.displayName = 'proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.displayName = 'proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserMoodMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserMoodMap.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserMoodMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ReminderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ReminderRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.ReminderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ReminderDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ReminderDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.ReminderDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.displayName = 'proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.UserIdRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserIdRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.displayName = 'proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.displayName = 'proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.FavoriteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.FavoriteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.displayName = 'proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.displayName = 'proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.QuotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.QuotesResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.QuotesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.MoodDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.MoodDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.MoodDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.TriggerDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.TriggerDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.TriggerDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserMoodDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserMoodDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.displayName = 'proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserCircleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserCircleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.displayName = 'proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.displayName = 'proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.CircleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.CircleRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.CircleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.CircleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.CircleResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.CircleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.PaginationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.PaginationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.PaginationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.PaginationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.PaginationResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.PaginationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.displayName = 'proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.displayName = 'proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.displayName = 'proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.displayName = 'proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.displayName = 'proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.CampaignStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.CampaignStat.displayName = 'proto.unifiedplatform.heartIntuneProfile.CampaignStat';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {
    lasthours: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.Empty}
 */
proto.unifiedplatform.heartIntuneProfile.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.Empty;
  return proto.unifiedplatform.heartIntuneProfile.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.Empty}
 */
proto.unifiedplatform.heartIntuneProfile.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLasthours(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLasthours();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 lastHours = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.Empty.prototype.getLasthours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.Empty} returns this
 */
proto.unifiedplatform.heartIntuneProfile.Empty.prototype.setLasthours = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.TestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.TestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.TestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.TestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TestResponse}
 */
proto.unifiedplatform.heartIntuneProfile.TestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.TestResponse;
  return proto.unifiedplatform.heartIntuneProfile.TestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.TestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TestResponse}
 */
proto.unifiedplatform.heartIntuneProfile.TestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.TestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.TestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.TestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.TestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string result = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.TestResponse.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TestResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TestResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.totalUsers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.totalUsers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalmeditationminutes: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.totalUsers}
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.totalUsers;
  return proto.unifiedplatform.heartIntuneProfile.totalUsers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.totalUsers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.totalUsers}
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalmeditationminutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.totalUsers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.totalUsers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTotalmeditationminutes();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 count = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.totalUsers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 totalMeditationMinutes = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers.prototype.getTotalmeditationminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.totalUsers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.totalUsers.prototype.setTotalmeditationminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.User.repeatedFields_ = [14,15,16,17,18,19,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    provider: jspb.Message.getFieldWithDefault(msg, 3, 0),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    profileurl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastlogin: (f = msg.getLastlogin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    gender: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dob: (f = msg.getDob()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    firebaseid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 12, ""),
    heartpointscumulative: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    registeredlivemeditationsessionidList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    completedlivemeditationsessionidList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    acceptedgoalsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    completedgoalsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    favouritemeditationidList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    scheduleList: jspb.Message.toObjectList(msg.getScheduleList(),
    stats_pb.MeditationScheduled.toObject, includeInstance),
    badgeidsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    city: jspb.Message.getFieldWithDefault(msg, 21, ""),
    addressline: jspb.Message.getFieldWithDefault(msg, 22, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 23, ""),
    citizenshipcountry: jspb.Message.getFieldWithDefault(msg, 24, ""),
    taxid: jspb.Message.getFieldWithDefault(msg, 25, ""),
    state: jspb.Message.getFieldWithDefault(msg, 26, ""),
    country: jspb.Message.getFieldWithDefault(msg, 27, ""),
    passportnumber: jspb.Message.getFieldWithDefault(msg, 28, ""),
    fcmToken: jspb.Message.getFieldWithDefault(msg, 29, ""),
    role: jspb.Message.getFieldWithDefault(msg, 30, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 31, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 32, ""),
    about: jspb.Message.getFieldWithDefault(msg, 33, ""),
    usermoodexist: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    rating: jspb.Message.getFieldWithDefault(msg, 35, 0),
    feedback: jspb.Message.getFieldWithDefault(msg, 36, ""),
    invitestatus: jspb.Message.getFieldWithDefault(msg, 37, 0),
    visibilitytype: jspb.Message.getFieldWithDefault(msg, 38, 0),
    enableemail: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    enablewhatsapp: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    mysrcmid: jspb.Message.getFieldWithDefault(msg, 41, 0),
    placeofresidence: jspb.Message.getFieldWithDefault(msg, 42, ""),
    preceptorid: jspb.Message.getFieldWithDefault(msg, 43, 0),
    education: jspb.Message.getFieldWithDefault(msg, 44, ""),
    profession: jspb.Message.getFieldWithDefault(msg, 45, ""),
    street: jspb.Message.getFieldWithDefault(msg, 46, ""),
    cityid: jspb.Message.getFieldWithDefault(msg, 47, 0),
    lastseenat: (f = msg.getLastseenat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    anonymousid: jspb.Message.getFieldWithDefault(msg, 49, ""),
    enableemailcampaignnotification: jspb.Message.getFieldWithDefault(msg, 50, 0),
    enablepushcampaignnotification: jspb.Message.getFieldWithDefault(msg, 51, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.User;
  return proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.LoginStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.Provider} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileurl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastlogin(value);
      break;
    case 9:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDob(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeartpointscumulative(value);
      break;
    case 14:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRegisteredlivemeditationsessionid(values[i]);
      }
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompletedlivemeditationsessionid(values[i]);
      }
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAcceptedgoals(values[i]);
      }
      break;
    case 17:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompletedgoals(values[i]);
      }
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFavouritemeditationid(values[i]);
      }
      break;
    case 19:
      var value = new stats_pb.MeditationScheduled;
      reader.readMessage(value,stats_pb.MeditationScheduled.deserializeBinaryFromReader);
      msg.addSchedule(value);
      break;
    case 20:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBadgeids(values[i]);
      }
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressline(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitizenshipcountry(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxid(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportnumber(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setFcmToken(value);
      break;
    case 30:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 31:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbout(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsermoodexist(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRating(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedback(value);
      break;
    case 37:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (reader.readEnum());
      msg.setInvitestatus(value);
      break;
    case 38:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.VisibilityType} */ (reader.readEnum());
      msg.setVisibilitytype(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableemail(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnablewhatsapp(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMysrcmid(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceofresidence(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreceptorid(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setEducation(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfession(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCityid(value);
      break;
    case 48:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastseenat(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnonymousid(value);
      break;
    case 50:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.EnableFeature} */ (reader.readEnum());
      msg.setEnableemailcampaignnotification(value);
      break;
    case 51:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.EnableFeature} */ (reader.readEnum());
      msg.setEnablepushcampaignnotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProfileurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastlogin();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getDob();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFirebaseid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getHeartpointscumulative();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getRegisteredlivemeditationsessionidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      14,
      f
    );
  }
  f = message.getCompletedlivemeditationsessionidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      15,
      f
    );
  }
  f = message.getAcceptedgoalsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      16,
      f
    );
  }
  f = message.getCompletedgoalsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      17,
      f
    );
  }
  f = message.getFavouritemeditationidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      18,
      f
    );
  }
  f = message.getScheduleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      stats_pb.MeditationScheduled.serializeBinaryToWriter
    );
  }
  f = message.getBadgeidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      20,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAddressline();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getCitizenshipcountry();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTaxid();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getPassportnumber();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getFcmToken();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      31,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getAbout();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getUsermoodexist();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getRating();
  if (f !== 0) {
    writer.writeInt32(
      35,
      f
    );
  }
  f = message.getFeedback();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getInvitestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      37,
      f
    );
  }
  f = message.getVisibilitytype();
  if (f !== 0.0) {
    writer.writeEnum(
      38,
      f
    );
  }
  f = message.getEnableemail();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getEnablewhatsapp();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getMysrcmid();
  if (f !== 0) {
    writer.writeInt64(
      41,
      f
    );
  }
  f = message.getPlaceofresidence();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getPreceptorid();
  if (f !== 0) {
    writer.writeInt32(
      43,
      f
    );
  }
  f = message.getEducation();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getProfession();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getCityid();
  if (f !== 0) {
    writer.writeInt32(
      47,
      f
    );
  }
  f = message.getLastseenat();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAnonymousid();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getEnableemailcampaignnotification();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getEnablepushcampaignnotification();
  if (f !== 0.0) {
    writer.writeEnum(
      51,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional LoginStatus status = 2;
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginStatus}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getStatus = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.LoginStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.LoginStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Provider provider = 3;
 * @return {!proto.unifiedplatform.heartIntuneProfile.Provider}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getProvider = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.Provider} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.Provider} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string profileURL = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getProfileurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setProfileurl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string displayName = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
*/
proto.unifiedplatform.heartIntuneProfile.User.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp lastLogin = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getLastlogin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
*/
proto.unifiedplatform.heartIntuneProfile.User.prototype.setLastlogin = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearLastlogin = function() {
  return this.setLastlogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.hasLastlogin = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Gender gender = 9;
 * @return {!proto.unifiedplatform.heartIntuneProfile.Gender}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getGender = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.Gender} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.Gender} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp dob = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getDob = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
*/
proto.unifiedplatform.heartIntuneProfile.User.prototype.setDob = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearDob = function() {
  return this.setDob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.hasDob = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string firebaseId = 11;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getFirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setFirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string mobileNumber = 12;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional double heartPointsCumulative = 13;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getHeartpointscumulative = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setHeartpointscumulative = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * repeated int64 registeredLiveMeditationSessionID = 14;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getRegisteredlivemeditationsessionidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setRegisteredlivemeditationsessionidList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.addRegisteredlivemeditationsessionid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearRegisteredlivemeditationsessionidList = function() {
  return this.setRegisteredlivemeditationsessionidList([]);
};


/**
 * repeated int64 completedLiveMeditationSessionID = 15;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getCompletedlivemeditationsessionidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setCompletedlivemeditationsessionidList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.addCompletedlivemeditationsessionid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearCompletedlivemeditationsessionidList = function() {
  return this.setCompletedlivemeditationsessionidList([]);
};


/**
 * repeated int64 acceptedGoals = 16;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getAcceptedgoalsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setAcceptedgoalsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.addAcceptedgoals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearAcceptedgoalsList = function() {
  return this.setAcceptedgoalsList([]);
};


/**
 * repeated int64 completedGoals = 17;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getCompletedgoalsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setCompletedgoalsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.addCompletedgoals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearCompletedgoalsList = function() {
  return this.setCompletedgoalsList([]);
};


/**
 * repeated int64 favouriteMeditationID = 18;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getFavouritemeditationidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setFavouritemeditationidList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.addFavouritemeditationid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearFavouritemeditationidList = function() {
  return this.setFavouritemeditationidList([]);
};


/**
 * repeated stats.MeditationScheduled schedule = 19;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled>}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getScheduleList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled>} */ (
    jspb.Message.getRepeatedWrapperField(this, stats_pb.MeditationScheduled, 19));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
*/
proto.unifiedplatform.heartIntuneProfile.User.prototype.setScheduleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.addSchedule = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearScheduleList = function() {
  return this.setScheduleList([]);
};


/**
 * repeated int64 badgeIds = 20;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getBadgeidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setBadgeidsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.addBadgeids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearBadgeidsList = function() {
  return this.setBadgeidsList([]);
};


/**
 * optional string city = 21;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string addressLine = 22;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getAddressline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setAddressline = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string postalCode = 23;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string citizenshipCountry = 24;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getCitizenshipcountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setCitizenshipcountry = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string taxId = 25;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getTaxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setTaxid = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string state = 26;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string country = 27;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string passportNumber = 28;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getPassportnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setPassportnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string fcm_token = 29;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getFcmToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setFcmToken = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional Role role = 30;
 * @return {!proto.unifiedplatform.heartIntuneProfile.Role}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getRole = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.Role} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.Role} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 31;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 31, value);
};


/**
 * optional string timeZone = 32;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string about = 33;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getAbout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setAbout = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional bool userMoodExist = 34;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getUsermoodexist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setUsermoodexist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional int32 rating = 35;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional string feedback = 36;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getFeedback = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setFeedback = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional unifiedplatform.heartintune.common.InviteStatus inviteStatus = 37;
 * @return {!proto.unifiedplatform.heartintune.common.InviteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getInvitestatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setInvitestatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 37, value);
};


/**
 * optional unifiedplatform.heartintune.common.VisibilityType visibilityType = 38;
 * @return {!proto.unifiedplatform.heartintune.common.VisibilityType}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getVisibilitytype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.VisibilityType} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.VisibilityType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setVisibilitytype = function(value) {
  return jspb.Message.setProto3EnumField(this, 38, value);
};


/**
 * optional bool enableEmail = 39;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getEnableemail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setEnableemail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional bool enableWhatsApp = 40;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getEnablewhatsapp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setEnablewhatsapp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional int64 mysrcmId = 41;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getMysrcmid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setMysrcmid = function(value) {
  return jspb.Message.setProto3IntField(this, 41, value);
};


/**
 * optional string placeOfResidence = 42;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getPlaceofresidence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setPlaceofresidence = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional int32 preceptorId = 43;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getPreceptorid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 43, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setPreceptorid = function(value) {
  return jspb.Message.setProto3IntField(this, 43, value);
};


/**
 * optional string education = 44;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getEducation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setEducation = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string profession = 45;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getProfession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setProfession = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string street = 46;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional int32 cityId = 47;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getCityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setCityid = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};


/**
 * optional google.protobuf.Timestamp lastSeenAt = 48;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getLastseenat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 48));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
*/
proto.unifiedplatform.heartIntuneProfile.User.prototype.setLastseenat = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.clearLastseenat = function() {
  return this.setLastseenat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.hasLastseenat = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional string anonymousId = 49;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getAnonymousid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setAnonymousid = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional unifiedplatform.heartintune.common.EnableFeature enableEmailCampaignNotification = 50;
 * @return {!proto.unifiedplatform.heartintune.common.EnableFeature}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getEnableemailcampaignnotification = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.EnableFeature} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.EnableFeature} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setEnableemailcampaignnotification = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * optional unifiedplatform.heartintune.common.EnableFeature enablePushCampaignNotification = 51;
 * @return {!proto.unifiedplatform.heartintune.common.EnableFeature}
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.getEnablepushcampaignnotification = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.EnableFeature} */ (jspb.Message.getFieldWithDefault(this, 51, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.EnableFeature} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.User} returns this
 */
proto.unifiedplatform.heartIntuneProfile.User.prototype.setEnablepushcampaignnotification = function(value) {
  return jspb.Message.setProto3EnumField(this, 51, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goalid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selecteddateandtimeList: jspb.Message.toObjectList(msg.getSelecteddateandtimeList(),
    google_protobuf_timestamp_pb.Timestamp.toObject, includeInstance),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    startedon: (f = msg.getStartedon()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expirydate: (f = msg.getExpirydate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.SubscribedGoal;
  return proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.addSelecteddateandtime(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartedon(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirydate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSelecteddateandtimeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getStartedon();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpirydate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 goalID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated google.protobuf.Timestamp selectedDateAndTime = 3;
 * @return {!Array<!proto.google.protobuf.Timestamp>}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.getSelecteddateandtimeList = function() {
  return /** @type{!Array<!proto.google.protobuf.Timestamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {!Array<!proto.google.protobuf.Timestamp>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} returns this
*/
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.setSelecteddateandtimeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.protobuf.Timestamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.addSelecteddateandtime = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.protobuf.Timestamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.clearSelecteddateandtimeList = function() {
  return this.setSelecteddateandtimeList([]);
};


/**
 * optional bool status = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp startedOn = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.getStartedon = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} returns this
*/
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.setStartedon = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.clearStartedon = function() {
  return this.setStartedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.hasStartedon = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp expiryDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.getExpirydate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} returns this
*/
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.setExpirydate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribedGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.clearExpirydate = function() {
  return this.setExpirydate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribedGoal.prototype.hasExpirydate = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selecteddateandtimewithcalendareventList: jspb.Message.toObjectList(msg.getSelecteddateandtimewithcalendareventList(),
    proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal;
  return proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader);
      msg.addSelecteddateandtimewithcalendarevent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSelecteddateandtimewithcalendareventList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 goalID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated SelectedDateAndTimeWithCalendarEvent selectedDateAndTimeWithCalendarEvent = 3;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent>}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.prototype.getSelecteddateandtimewithcalendareventList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.prototype.setSelecteddateandtimewithcalendareventList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.prototype.addSelecteddateandtimewithcalendarevent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestSubscribeToGoal.prototype.clearSelecteddateandtimewithcalendareventList = function() {
  return this.setSelecteddateandtimewithcalendareventList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    selecteddateandtime: (f = msg.getSelecteddateandtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    calendareventid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 3, 0),
    completedstatus: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent;
  return proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSelecteddateandtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalendareventid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompletedstatus(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelecteddateandtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCalendareventid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCompletedstatus();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp selectedDateAndTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.getSelecteddateandtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent} returns this
*/
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.setSelecteddateandtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.clearSelecteddateandtime = function() {
  return this.setSelecteddateandtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.hasSelecteddateandtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string calendarEventId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.getCalendareventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.setCalendareventid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 duration = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool completedStatus = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.getCompletedstatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.setCompletedstatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent} returns this
*/
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selecteddateandtimewithcalendareventList: jspb.Message.toObjectList(msg.getSelecteddateandtimewithcalendareventList(),
    proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal;
  return proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader);
      msg.addSelecteddateandtimewithcalendarevent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSelecteddateandtimewithcalendareventList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 goalID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated UpdateSelectedDateAndTimeWithCalendarEvent selectedDateAndTimeWithCalendarEvent = 3;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent>}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.prototype.getSelecteddateandtimewithcalendareventList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.prototype.setSelecteddateandtimewithcalendareventList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.prototype.addSelecteddateandtimewithcalendarevent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUpdateToGoal.prototype.clearSelecteddateandtimewithcalendareventList = function() {
  return this.setSelecteddateandtimewithcalendareventList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    selecteddateandtime: (f = msg.getSelecteddateandtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    calendareventid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scheduleid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent;
  return proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSelecteddateandtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalendareventid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduleid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelecteddateandtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCalendareventid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScheduleid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp selectedDateAndTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.prototype.getSelecteddateandtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.prototype.setSelecteddateandtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.prototype.clearSelecteddateandtime = function() {
  return this.setSelecteddateandtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.prototype.hasSelecteddateandtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string calendarEventId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.prototype.getCalendareventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.prototype.setCalendareventid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 scheduleId = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.prototype.getScheduleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateSelectedDateAndTimeWithCalendarEvent.prototype.setScheduleid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    provider: jspb.Message.getFieldWithDefault(msg, 2, 0),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profileurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dob: (f = msg.getDob()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && device_pb.DeviceRequest.toObject(includeInstance, f),
    firebaseid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    heartpoints: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    stats: (f = msg.getStats()) && stats_pb.MeditationStats.toObject(includeInstance, f),
    city: jspb.Message.getFieldWithDefault(msg, 13, ""),
    addressline: jspb.Message.getFieldWithDefault(msg, 14, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 15, ""),
    citizenshipcountry: jspb.Message.getFieldWithDefault(msg, 16, ""),
    taxid: jspb.Message.getFieldWithDefault(msg, 17, ""),
    country: jspb.Message.getFieldWithDefault(msg, 18, ""),
    state: jspb.Message.getFieldWithDefault(msg, 19, ""),
    passportnumber: jspb.Message.getFieldWithDefault(msg, 20, ""),
    fcmtoken: jspb.Message.getFieldWithDefault(msg, 21, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 22, ""),
    userrole: jspb.Message.getFieldWithDefault(msg, 23, 0),
    anonymousid: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest;
  return proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.LoginStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.Provider} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileurl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDob(value);
      break;
    case 8:
      var value = new device_pb.DeviceRequest;
      reader.readMessage(value,device_pb.DeviceRequest.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeartpoints(value);
      break;
    case 12:
      var value = new stats_pb.MeditationStats;
      reader.readMessage(value,stats_pb.MeditationStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressline(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitizenshipcountry(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxid(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportnumber(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFcmtoken(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 23:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.Role} */ (reader.readEnum());
      msg.setUserrole(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnonymousid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfileurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDob();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      device_pb.DeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getFirebaseid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHeartpoints();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      stats_pb.MeditationStats.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAddressline();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCitizenshipcountry();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTaxid();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPassportnumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getFcmtoken();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getUserrole();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getAnonymousid();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional LoginStatus status = 1;
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginStatus}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getStatus = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.LoginStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.LoginStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Provider provider = 2;
 * @return {!proto.unifiedplatform.heartIntuneProfile.Provider}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getProvider = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.Provider} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.Provider} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profileURL = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getProfileurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setProfileurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string displayName = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Gender gender = 6;
 * @return {!proto.unifiedplatform.heartIntuneProfile.Gender}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getGender = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.Gender} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.Gender} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp dob = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getDob = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setDob = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.clearDob = function() {
  return this.setDob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.hasDob = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional device.DeviceRequest device = 8;
 * @return {?proto.unifiedplatform.heartIntuneProfile.device.DeviceRequest}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getDevice = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.device.DeviceRequest} */ (
    jspb.Message.getWrapperField(this, device_pb.DeviceRequest, 8));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.device.DeviceRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string firebaseId = 9;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getFirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setFirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string mobileNumber = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double heartPoints = 11;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getHeartpoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setHeartpoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional stats.MeditationStats stats = 12;
 * @return {?proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getStats = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} */ (
    jspb.Message.getWrapperField(this, stats_pb.MeditationStats, 12));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.hasStats = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string city = 13;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string addressLine = 14;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getAddressline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setAddressline = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string postalCode = 15;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string citizenshipCountry = 16;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getCitizenshipcountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setCitizenshipcountry = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string taxId = 17;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getTaxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setTaxid = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string country = 18;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string state = 19;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string passportNumber = 20;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getPassportnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setPassportnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string fcmToken = 21;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getFcmtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setFcmtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string timeZone = 22;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional Role userRole = 23;
 * @return {!proto.unifiedplatform.heartIntuneProfile.Role}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getUserrole = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.Role} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.Role} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setUserrole = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional string anonymousId = 24;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.getAnonymousid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserRequest.prototype.setAnonymousid = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && device_pb.Device.toObject(includeInstance, f),
    isnewuser: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    stats: (f = msg.getStats()) && stats_pb.MeditationStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse;
  return proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new device_pb.Device;
      reader.readMessage(value,device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsnewuser(value);
      break;
    case 4:
      var value = new stats_pb.MeditationStats;
      reader.readMessage(value,stats_pb.MeditationStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getIsnewuser();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      stats_pb.MeditationStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional device.Device device = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.device.Device}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.getDevice = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.device.Device} */ (
    jspb.Message.getWrapperField(this, device_pb.Device, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.device.Device|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool isNewUser = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.getIsnewuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.setIsnewuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional stats.MeditationStats stats = 4;
 * @return {?proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.getStats = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} */ (
    jspb.Message.getWrapperField(this, stats_pb.MeditationStats, 4));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ConnectUserResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firebaseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    profileurl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dob: (f = msg.getDob()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.LoginRequest;
  return proto.unifiedplatform.heartIntuneProfile.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.Provider} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileurl(value);
      break;
    case 7:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirebaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProfileurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDob();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string firebaseId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.getFirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.setFirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string displayName = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Provider provider = 3;
 * @return {!proto.unifiedplatform.heartIntuneProfile.Provider}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.getProvider = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.Provider} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.Provider} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string mobileNumber = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string profileURL = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.getProfileurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.setProfileurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Gender gender = 7;
 * @return {!proto.unifiedplatform.heartIntuneProfile.Gender}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.getGender = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.Gender} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.Gender} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp dob = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.getDob = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.setDob = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.clearDob = function() {
  return this.setDob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LoginRequest.prototype.hasDob = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.LoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.LoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && device_pb.Device.toObject(includeInstance, f),
    existingfirebaseid: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    existingemail: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    existingmobilenumber: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginResponse}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.LoginResponse;
  return proto.unifiedplatform.heartIntuneProfile.LoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginResponse}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new device_pb.Device;
      reader.readMessage(value,device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExistingfirebaseid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExistingemail(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExistingmobilenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.LoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getExistingfirebaseid();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getExistingemail();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getExistingmobilenumber();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional device.Device device = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.device.Device}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.getDevice = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.device.Device} */ (
    jspb.Message.getWrapperField(this, device_pb.Device, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.device.Device|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool existingFirebaseId = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.getExistingfirebaseid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.setExistingfirebaseid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool existingEmail = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.getExistingemail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.setExistingemail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool existingMobileNumber = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.getExistingmobilenumber = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LoginResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LoginResponse.prototype.setExistingmobilenumber = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.LogoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.LogoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && device_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutRequest}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.LogoutRequest;
  return proto.unifiedplatform.heartIntuneProfile.LogoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LogoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutRequest}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new device_pb.Device;
      reader.readMessage(value,device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.LogoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LogoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      device_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional device.Device device = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.device.Device}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.prototype.getDevice = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.device.Device} */ (
    jspb.Message.getWrapperField(this, device_pb.Device, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.device.Device|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.LogoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && device_pb.Device.toObject(includeInstance, f),
    existingfirebaseid: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    existingemail: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    existingmobilenumber: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.LogoutResponse;
  return proto.unifiedplatform.heartIntuneProfile.LogoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new device_pb.Device;
      reader.readMessage(value,device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExistingfirebaseid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExistingemail(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExistingmobilenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.LogoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getExistingfirebaseid();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getExistingemail();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getExistingmobilenumber();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional device.Device device = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.device.Device}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.getDevice = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.device.Device} */ (
    jspb.Message.getWrapperField(this, device_pb.Device, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.device.Device|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool existingFirebaseId = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.getExistingfirebaseid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.setExistingfirebaseid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool existingEmail = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.getExistingemail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.setExistingemail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool existingMobileNumber = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.getExistingmobilenumber = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LogoutResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LogoutResponse.prototype.setExistingmobilenumber = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.FetchStatsFor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deviceid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FetchStatsFor}
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.FetchStatsFor;
  return proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.FetchStatsFor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FetchStatsFor}
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.FetchStatsFor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 userID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FetchStatsFor} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 deviceID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FetchStatsFor} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FetchStatsFor.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserStatsInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    heartpointscumulative: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totaldurationmeditated: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalnumberofmeditationsessions: jspb.Message.getFieldWithDefault(msg, 4, 0),
    last7daysdataMap: (f = msg.getLast7daysdataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserStatsInfo}
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserStatsInfo;
  return proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserStatsInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserStatsInfo}
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeartpointscumulative(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotaldurationmeditated(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalnumberofmeditationsessions(value);
      break;
    case 5:
      var value = msg.getLast7daysdataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readUint32, null, "", 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserStatsInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHeartpointscumulative();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotaldurationmeditated();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTotalnumberofmeditationsessions();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getLast7daysdataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeUint32);
  }
};


/**
 * optional string userName = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserStatsInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double heartPointsCumulative = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.getHeartpointscumulative = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserStatsInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.setHeartpointscumulative = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional uint32 totalDurationMeditated = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.getTotaldurationmeditated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserStatsInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.setTotaldurationmeditated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 totalNumberOfMeditationSessions = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.getTotalnumberofmeditationsessions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserStatsInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.setTotalnumberofmeditationsessions = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * map<string, uint32> last7DaysData = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.getLast7daysdataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserStatsInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserStatsInfo.prototype.clearLast7daysdataMap = function() {
  this.getLast7daysdataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    livemeditationsessionid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RegistrationDetails}
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RegistrationDetails;
  return proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RegistrationDetails}
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivemeditationsessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLivemeditationsessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 userID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RegistrationDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 liveMeditationSessionID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.prototype.getLivemeditationsessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RegistrationDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RegistrationDetails.prototype.setLivemeditationsessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    livemeditationid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currenttime: (f = msg.getCurrenttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    livesessiondateandtime: (f = msg.getLivesessiondateandtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    calendareventid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails;
  return proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivemeditationid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrenttime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLivesessiondateandtime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalendareventid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLivemeditationid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrenttime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLivesessiondateandtime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCalendareventid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 userID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 liveMeditationID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.getLivemeditationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.setLivemeditationid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp currentTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.getCurrenttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.setCurrenttime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.clearCurrenttime = function() {
  return this.setCurrenttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.hasCurrenttime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp liveSessionDateAndTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.getLivesessiondateandtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.setLivesessiondateandtime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.clearLivesessiondateandtime = function() {
  return this.setLivesessiondateandtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.hasLivesessiondateandtime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string calendarEventId = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.getCalendareventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegistrationDetails.prototype.setCalendareventid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    livemeditationid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    calendareventid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId;
  return proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivemeditationid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalendareventid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLivemeditationid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCalendareventid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 userID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 liveMeditationID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.prototype.getLivemeditationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.prototype.setLivemeditationid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string calendarEventId = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.prototype.getCalendareventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForUnRegisterLiveSessionId.prototype.setCalendareventid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ListOfIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfIDs}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ListOfIDs;
  return proto.unifiedplatform.heartIntuneProfile.ListOfIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfIDs}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ListOfIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 userID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 IDs = 2;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfIDs.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.Status}
 */
proto.unifiedplatform.heartIntuneProfile.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.Status;
  return proto.unifiedplatform.heartIntuneProfile.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.Status}
 */
proto.unifiedplatform.heartIntuneProfile.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool Success = 1;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.Status.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.Status} returns this
 */
proto.unifiedplatform.heartIntuneProfile.Status.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.Status.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.Status} returns this
 */
proto.unifiedplatform.heartIntuneProfile.Status.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    firebaseid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fcmtoken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviceid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    jwttoken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ID}
 */
proto.unifiedplatform.heartIntuneProfile.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ID;
  return proto.unifiedplatform.heartIntuneProfile.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ID}
 */
proto.unifiedplatform.heartIntuneProfile.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFcmtoken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwttoken(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFirebaseid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFcmtoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getJwttoken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string firebaseId = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.getFirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.setFirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fcmToken = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.getFcmtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.setFcmtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string deviceId = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string jwtToken = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.getJwttoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.setJwttoken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ID.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.Favourites.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.Favourites} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    favouritemeditationidList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.Favourites}
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.Favourites;
  return proto.unifiedplatform.heartIntuneProfile.Favourites.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.Favourites} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.Favourites}
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFavouritemeditationid(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.Favourites.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.Favourites} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFavouritemeditationidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 UserID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.Favourites} returns this
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 favouriteMeditationID = 2;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.prototype.getFavouritemeditationidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.Favourites} returns this
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.prototype.setFavouritemeditationidList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.Favourites} returns this
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.prototype.addFavouritemeditationid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.Favourites} returns this
 */
proto.unifiedplatform.heartIntuneProfile.Favourites.prototype.clearFavouritemeditationidList = function() {
  return this.setFavouritemeditationidList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    favouritemeditationid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite;
  return proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFavouritemeditationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFavouritemeditationid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 UserID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 favouriteMeditationID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.prototype.getFavouritemeditationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetFavourite.prototype.setFavouritemeditationid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation;
  return proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeditationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 UserID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 MeditationID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.prototype.getMeditationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUsersWithMeditation.prototype.setMeditationid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestUserID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserID}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestUserID;
  return proto.unifiedplatform.heartIntuneProfile.RequestUserID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserID}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestUserID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 UserID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 pageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string platform = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserID} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserID.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f),
    deviceList: jspb.Message.toObjectList(msg.getDeviceList(),
    device_pb.Device.toObject, includeInstance),
    journalinfoList: jspb.Message.toObjectList(msg.getJournalinfoList(),
    journal_pb.JournalInfo.toObject, includeInstance),
    fcmtokenList: jspb.Message.toObjectList(msg.getFcmtokenList(),
    proto.unifiedplatform.heartIntuneProfile.FcmToken.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserInfo}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserInfo;
  return proto.unifiedplatform.heartIntuneProfile.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserInfo}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new device_pb.Device;
      reader.readMessage(value,device_pb.Device.deserializeBinaryFromReader);
      msg.addDevice(value);
      break;
    case 3:
      var value = new journal_pb.JournalInfo;
      reader.readMessage(value,journal_pb.JournalInfo.deserializeBinaryFromReader);
      msg.addJournalinfo(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.heartIntuneProfile.FcmToken;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.FcmToken.deserializeBinaryFromReader);
      msg.addFcmtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getDeviceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getJournalinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      journal_pb.JournalInfo.serializeBinaryToWriter
    );
  }
  f = message.getFcmtokenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.heartIntuneProfile.FcmToken.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated device.Device Device = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.device.Device>}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.getDeviceList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.device.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, device_pb.Device, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.device.Device>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.setDeviceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.device.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.device.Device}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.addDevice = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.device.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.clearDeviceList = function() {
  return this.setDeviceList([]);
};


/**
 * repeated journal.JournalInfo JournalInfo = 3;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo>}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.getJournalinfoList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, journal_pb.JournalInfo, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.setJournalinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.addJournalinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.clearJournalinfoList = function() {
  return this.setJournalinfoList([]);
};


/**
 * repeated FcmToken fcmToken = 4;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.FcmToken>}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.getFcmtokenList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.FcmToken>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.FcmToken, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.FcmToken>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.setFcmtokenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.FcmToken=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.FcmToken}
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.addFcmtoken = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.heartIntuneProfile.FcmToken, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserInfo.prototype.clearFcmtokenList = function() {
  return this.setFcmtokenList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.FcmToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.FcmToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    fcmtoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accesstoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FcmToken}
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.FcmToken;
  return proto.unifiedplatform.heartIntuneProfile.FcmToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.FcmToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FcmToken}
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFcmtoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.FcmToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.FcmToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFcmtoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string fcmToken = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken.prototype.getFcmtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FcmToken} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken.prototype.setFcmtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string accessToken = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FcmToken} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FcmToken.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userinfoList: jspb.Message.toObjectList(msg.getUserinfoList(),
    proto.unifiedplatform.heartIntuneProfile.User.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo;
  return proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.addUserinfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * repeated User userInfo = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.User>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.getUserinfoList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.User>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.setUserinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.addUserinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.clearUserinfoList = function() {
  return this.setUserinfoList([]);
};


/**
 * optional int64 totalCount = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 selectedPageSize = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 selectedPageNumber = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfUserInfo.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f),
    totalmeditationseconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    completedsessioncounts: jspb.Message.getFieldWithDefault(msg, 3, 0),
    longestmeditationseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currentmeditationsessionstreak: jspb.Message.getFieldWithDefault(msg, 5, 0),
    completedgoalsidList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    usercompletedguidedmeditationscount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    usercompletedpurposefulmeditationscount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    usercompletedlivemeditationscount: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseUserStats;
  return proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalmeditationseconds(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletedsessioncounts(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLongestmeditationseconds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentmeditationsessionstreak(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompletedgoalsid(values[i]);
      }
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsercompletedguidedmeditationscount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsercompletedpurposefulmeditationscount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsercompletedlivemeditationscount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getTotalmeditationseconds();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCompletedsessioncounts();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLongestmeditationseconds();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCurrentmeditationsessionstreak();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCompletedgoalsidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getUsercompletedguidedmeditationscount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUsercompletedpurposefulmeditationscount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUsercompletedlivemeditationscount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 totalMeditationSeconds = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.getTotalmeditationseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.setTotalmeditationseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 completedSessionCounts = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.getCompletedsessioncounts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.setCompletedsessioncounts = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 longestMeditationSeconds = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.getLongestmeditationseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.setLongestmeditationseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 currentMeditationSessionStreak = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.getCurrentmeditationsessionstreak = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.setCurrentmeditationsessionstreak = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated int64 completedGoalsId = 6;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.getCompletedgoalsidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.setCompletedgoalsidList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.addCompletedgoalsid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.clearCompletedgoalsidList = function() {
  return this.setCompletedgoalsidList([]);
};


/**
 * optional int64 userCompletedGuidedMeditationsCount = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.getUsercompletedguidedmeditationscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.setUsercompletedguidedmeditationscount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 userCompletedPurposefulMeditationsCount = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.getUsercompletedpurposefulmeditationscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.setUsercompletedpurposefulmeditationscount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 userCompletedLiveMeditationsCount = 9;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.getUsercompletedlivemeditationscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserStats.prototype.setUsercompletedlivemeditationscount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    meditationseconds: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData}
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.DailyMeditationData;
  return proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData}
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeditationseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMeditationseconds();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timeStamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData} returns this
*/
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData} returns this
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 meditationSeconds = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.prototype.getMeditationseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData} returns this
 */
proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.prototype.setMeditationseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentdate: (f = msg.getCurrentdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    noofdays: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats;
  return proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentdate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNoofdays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrentdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNoofdays();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 UserId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp currentDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.prototype.getCurrentdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.prototype.setCurrentdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.prototype.clearCurrentdate = function() {
  return this.setCurrentdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.prototype.hasCurrentdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 noOfdays = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.prototype.getNoofdays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserWeeklyStats.prototype.setNoofdays = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f),
    dailymeditationdataList: jspb.Message.toObjectList(msg.getDailymeditationdataList(),
    proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.toObject, includeInstance),
    weeklyaveragemeditationseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    weeklytotalmeditationseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    guidedmeditationscount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    purposefulmeditationscount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    livemeditationcount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    longestmeditationinminutes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalmeditationcount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalacceptedgoalcount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    totalcompletedgoalcount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalmissedgoalcount: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats;
  return proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.DailyMeditationData;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.deserializeBinaryFromReader);
      msg.addDailymeditationdata(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeeklyaveragemeditationseconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeeklytotalmeditationseconds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGuidedmeditationscount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurposefulmeditationscount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivemeditationcount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLongestmeditationinminutes(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalmeditationcount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalacceptedgoalcount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcompletedgoalcount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalmissedgoalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getDailymeditationdataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.DailyMeditationData.serializeBinaryToWriter
    );
  }
  f = message.getWeeklyaveragemeditationseconds();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getWeeklytotalmeditationseconds();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getGuidedmeditationscount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPurposefulmeditationscount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLivemeditationcount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLongestmeditationinminutes();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTotalmeditationcount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTotalacceptedgoalcount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTotalcompletedgoalcount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getTotalmissedgoalcount();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DailyMeditationData dailyMeditationData = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getDailymeditationdataList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.DailyMeditationData, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setDailymeditationdataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.DailyMeditationData}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.addDailymeditationdata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.DailyMeditationData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.clearDailymeditationdataList = function() {
  return this.setDailymeditationdataList([]);
};


/**
 * optional int64 weeklyAverageMeditationSeconds = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getWeeklyaveragemeditationseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setWeeklyaveragemeditationseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 weeklyTotalMeditationSeconds = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getWeeklytotalmeditationseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setWeeklytotalmeditationseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 guidedMeditationsCount = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getGuidedmeditationscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setGuidedmeditationscount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 purposefulMeditationsCount = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getPurposefulmeditationscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setPurposefulmeditationscount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 liveMeditationCount = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getLivemeditationcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setLivemeditationcount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 longestMeditationInMinutes = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getLongestmeditationinminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setLongestmeditationinminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 totalMeditationCount = 9;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getTotalmeditationcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setTotalmeditationcount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 totalAcceptedGoalCount = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getTotalacceptedgoalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setTotalacceptedgoalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 totalCompletedGoalCount = 11;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getTotalcompletedgoalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setTotalcompletedgoalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 totalMissedGoalCount = 12;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.getTotalmissedgoalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserWeeklyStats.prototype.setTotalmissedgoalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    badgeid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation;
  return proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadgeid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBadgeid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 badgeId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.prototype.getBadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseCompletedLiveMeditation.prototype.setBadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackerinfo: (f = msg.getTrackerinfo()) && tracker_pb.TrackerInfo.toObject(includeInstance, f),
    badgeid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    badgename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badgeurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    goalcompletedstatus: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    donationpopup: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    goaltitle: jspb.Message.getFieldWithDefault(msg, 7, ""),
    badgecount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    badgedetailsv2List: jspb.Message.toObjectList(msg.getBadgedetailsv2List(),
    proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge;
  return proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new tracker_pb.TrackerInfo;
      reader.readMessage(value,tracker_pb.TrackerInfo.deserializeBinaryFromReader);
      msg.setTrackerinfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadgeid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgeurl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoalcompletedstatus(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDonationpopup(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoaltitle(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 9:
      var value = new proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.deserializeBinaryFromReader);
      msg.addBadgedetailsv2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackerinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      tracker_pb.TrackerInfo.serializeBinaryToWriter
    );
  }
  f = message.getBadgeid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBadgename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadgeurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGoalcompletedstatus();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDonationpopup();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getGoaltitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getBadgedetailsv2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.serializeBinaryToWriter
    );
  }
};


/**
 * optional tracker.TrackerInfo trackerInfo = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.getTrackerinfo = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} */ (
    jspb.Message.getWrapperField(this, tracker_pb.TrackerInfo, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
*/
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.setTrackerinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.clearTrackerinfo = function() {
  return this.setTrackerinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.hasTrackerinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 badgeId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.getBadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.setBadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string badgeName = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.getBadgename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.setBadgename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string badgeURL = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.getBadgeurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.setBadgeurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool goalCompletedStatus = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.getGoalcompletedstatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.setGoalcompletedstatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool donationPopup = 6;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.getDonationpopup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.setDonationpopup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string goalTitle = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.getGoaltitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.setGoaltitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 badgeCount = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated BadgeDetailsV2 badgeDetailsV2 = 9;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2>}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.getBadgedetailsv2List = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2, 9));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
*/
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.setBadgedetailsv2List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2}
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.addBadgedetailsv2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TrackerWithBadge.prototype.clearBadgedetailsv2List = function() {
  return this.setBadgedetailsv2List([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profileurl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dob: (f = msg.getDob()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    city: jspb.Message.getFieldWithDefault(msg, 8, ""),
    about: jspb.Message.getFieldWithDefault(msg, 9, ""),
    feedback: jspb.Message.getFieldWithDefault(msg, 10, ""),
    rating: jspb.Message.getFieldWithDefault(msg, 11, 0),
    visibilitytype: jspb.Message.getFieldWithDefault(msg, 12, 0),
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f),
    enablefeature: jspb.Message.getFieldWithDefault(msg, 14, 0),
    cityid: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest;
  return proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileurl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDob(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbout(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedback(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRating(value);
      break;
    case 12:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.VisibilityType} */ (reader.readEnum());
      msg.setVisibilitytype(value);
      break;
    case 13:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 14:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.EnableFeature} */ (reader.readEnum());
      msg.setEnablefeature(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCityid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfileurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDob();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAbout();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFeedback();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRating();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getVisibilitytype();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getEnablefeature();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getCityid();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profileURL = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getProfileurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setProfileurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string displayName = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Gender gender = 4;
 * @return {!proto.unifiedplatform.heartIntuneProfile.Gender}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getGender = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.Gender} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.Gender} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp dob = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getDob = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setDob = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.clearDob = function() {
  return this.setDob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.hasDob = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 userID = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string timeZone = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string city = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string about = 9;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getAbout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setAbout = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string feedback = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getFeedback = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setFeedback = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 rating = 11;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional unifiedplatform.heartintune.common.VisibilityType visibilityType = 12;
 * @return {!proto.unifiedplatform.heartintune.common.VisibilityType}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getVisibilitytype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.VisibilityType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.VisibilityType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setVisibilitytype = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional User user = 13;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 13));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional unifiedplatform.heartintune.common.EnableFeature enableFeature = 14;
 * @return {!proto.unifiedplatform.heartintune.common.EnableFeature}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getEnablefeature = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.EnableFeature} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.EnableFeature} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setEnablefeature = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional int32 cityId = 15;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.getCityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserRequest.prototype.setCityid = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest;
  return proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UpdateUserLanguageRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    badgename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    badgeurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badgesearnedcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    meditatetogetgemsdescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    hours: jspb.Message.getFieldWithDefault(msg, 6, 0),
    minutes: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser;
  return proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadgeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgeurl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBadgesearnedcount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeditatetogetgemsdescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHours(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBadgename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBadgeurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadgesearnedcount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMeditatetogetgemsdescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHours();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMinutes();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 badgeId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.getBadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.setBadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string badgeName = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.getBadgename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.setBadgename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string badgeURL = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.getBadgeurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.setBadgeurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 badgesEarnedCount = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.getBadgesearnedcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.setBadgesearnedcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string meditateToGetGemsDescription = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.getMeditatetogetgemsdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.setMeditatetogetgemsdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 hours = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.getHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.setHours = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 minutes = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.getMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.prototype.setMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgedetailsbyuserList: jspb.Message.toObjectList(msg.getBadgedetailsbyuserList(),
    proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.toObject, includeInstance),
    totalearnedgemsbyuser: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId;
  return proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.deserializeBinaryFromReader);
      msg.addBadgedetailsbyuser(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalearnedgemsbyuser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgedetailsbyuserList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser.serializeBinaryToWriter
    );
  }
  f = message.getTotalearnedgemsbyuser();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated BadgeDetailsByUser badgeDetailsByUser = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.prototype.getBadgedetailsbyuserList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.prototype.setBadgedetailsbyuserList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.prototype.addBadgedetailsbyuser = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.BadgeDetailsByUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.prototype.clearBadgedetailsbyuserList = function() {
  return this.setBadgedetailsbyuserList([]);
};


/**
 * optional int32 totalEarnedGemsByUser = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.prototype.getTotalearnedgemsbyuser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseBadgesByUserId.prototype.setTotalearnedgemsbyuser = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f),
    weeklymeditationdataList: jspb.Message.toObjectList(msg.getWeeklymeditationdataList(),
    proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats;
  return proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.deserializeBinaryFromReader);
      msg.addWeeklymeditationdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getWeeklymeditationdataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated WeeklyMeditationData weeklyMeditationData = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.prototype.getWeeklymeditationdataList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.prototype.setWeeklymeditationdataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.prototype.addWeeklymeditationdata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserMonthlyStats.prototype.clearWeeklymeditationdataList = function() {
  return this.setWeeklymeditationdataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.toObject = function(includeInstance, msg) {
  var f, obj = {
    week: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationseconds: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData}
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData;
  return proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData}
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWeek(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeditationseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeek();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMeditationseconds();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional uint32 week = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.prototype.getWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData} returns this
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.prototype.setWeek = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 meditationSeconds = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.prototype.getMeditationseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData} returns this
 */
proto.unifiedplatform.heartIntuneProfile.WeeklyMeditationData.prototype.setMeditationseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    currentdate: (f = msg.getCurrentdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    donationstatus: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest;
  return proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentdate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDonationstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCurrentdate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDonationstatus();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp currentDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.getCurrentdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.setCurrentdate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.clearCurrentdate = function() {
  return this.setCurrentdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.hasCurrentdate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool donationStatus = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.getDonationstatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SaveDonationRequest.prototype.setDonationstatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    donaterequest: (f = msg.getDonaterequest()) && donation_pb.DonateRequest.toObject(includeInstance, f),
    saveprofile: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isselectedavailtax: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest}
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest;
  return proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest}
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new donation_pb.DonateRequest;
      reader.readMessage(value,donation_pb.DonateRequest.deserializeBinaryFromReader);
      msg.setDonaterequest(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaveprofile(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsselectedavailtax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonaterequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      donation_pb.DonateRequest.serializeBinaryToWriter
    );
  }
  f = message.getSaveprofile();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsselectedavailtax();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional donation.DonateRequest donateRequest = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest}
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.prototype.getDonaterequest = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} */ (
    jspb.Message.getWrapperField(this, donation_pb.DonateRequest, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.prototype.setDonaterequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.prototype.clearDonaterequest = function() {
  return this.setDonaterequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.prototype.hasDonaterequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool saveProfile = 2;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.prototype.getSaveprofile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.prototype.setSaveprofile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool isSelectedAvailTax = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.prototype.getIsselectedavailtax = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.DonationInfoRequest.prototype.setIsselectedavailtax = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goalid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId}
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId;
  return proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId}
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 goalId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestGoalByUserId.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selecteddateandtimewithcalendareventList: jspb.Message.toObjectList(msg.getSelecteddateandtimewithcalendareventList(),
    proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.toObject, includeInstance),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal;
  return proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader);
      msg.addSelecteddateandtimewithcalendarevent(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSelecteddateandtimewithcalendareventList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 goalID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ResponseSelectedDateAndTimeWithCalendarEvent selectedDateAndTimeWithCalendarEvent = 3;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.getSelecteddateandtimewithcalendareventList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.setSelecteddateandtimewithcalendareventList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.addSelecteddateandtimewithcalendarevent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.clearSelecteddateandtimewithcalendareventList = function() {
  return this.setSelecteddateandtimewithcalendareventList([]);
};


/**
 * optional bool status = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseScheduleGoal.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    selecteddateandtime: (f = msg.getSelecteddateandtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    calendareventid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scheduleid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    completedstatus: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent;
  return proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSelecteddateandtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalendareventid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduleid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompletedstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelecteddateandtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCalendareventid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScheduleid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCompletedstatus();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp selectedDateAndTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.getSelecteddateandtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.setSelecteddateandtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.clearSelecteddateandtime = function() {
  return this.setSelecteddateandtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.hasSelecteddateandtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string calendarEventId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.getCalendareventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.setCalendareventid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 scheduleId = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.getScheduleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.setScheduleid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool completedStatus = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.getCompletedstatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseSelectedDateAndTimeWithCalendarEvent.prototype.setCompletedstatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    delayexpirybygivenminutes: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId;
  return proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelayexpirybygivenminutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDelayexpirybygivenminutes();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 delayExpiryByGivenMinutes = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.prototype.getDelayexpirybygivenminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForRegisterLiveMeditationId.prototype.setDelayexpirybygivenminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firebaseid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    checkemailexistinfirebase: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail;
  return proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckemailexistinfirebase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirebaseid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCheckemailexistinfirebase();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mobileNumber = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firebaseId = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.prototype.getFirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.prototype.setFirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool checkEmailExistInFireBase = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.prototype.getCheckemailexistinfirebase = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserDetailsByEmail.prototype.setCheckemailexistinfirebase = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.toObject = function(includeInstance, msg) {
  var f, obj = {
    userlistList: jspb.Message.toObjectList(msg.getUserlistList(),
    proto.unifiedplatform.heartIntuneProfile.User.toObject, includeInstance),
    existinfirebase: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    uid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    providerList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail;
  return proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.addUserlist(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExistinfirebase(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getExistinfirebase();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProviderList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * repeated User userList = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.User>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.getUserlistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.User>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.setUserlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.addUserlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.clearUserlistList = function() {
  return this.setUserlistList([]);
};


/**
 * optional bool existInFirebase = 2;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.getExistinfirebase = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.setExistinfirebase = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string uid = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string provider = 4;
 * @return {!Array<string>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.getProviderList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.setProviderList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.addProvider = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserDetailsByEmail.prototype.clearProviderList = function() {
  return this.setProviderList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goalid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule;
  return proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 goalId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteSchedule.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    flag: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    invitestatus: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable;
  return proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFlag(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (reader.readEnum());
      msg.setInvitestatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFlag();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getInvitestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 UserID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 pageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool flag = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.getFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.setFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.InviteStatus inviteStatus = 5;
 * @return {!proto.unifiedplatform.heartintune.common.InviteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.getInvitestatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.prototype.setInvitestatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    totalcount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs;
  return proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalcount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional int64 userID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 IDs = 2;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional uint32 totalCount = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 selectedPageSize = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 selectedPageNumber = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseListOfIDs.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    removecompletedlivesessionid: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId;
  return proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemovecompletedlivesessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRemovecompletedlivesessionid();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 liveSessionId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool removeCompletedLiveSessionId = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.prototype.getRemovecompletedlivesessionid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteLiveSessionId.prototype.setRemovecompletedlivesessionid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    removefromcompletedlivesessionidlist: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    removefromregisteredlivesessionidlist: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    value: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions;
  return proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemovefromcompletedlivesessionidlist(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemovefromregisteredlivesessionidlist(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRemovefromcompletedlivesessionidlist();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRemovefromregisteredlivesessionidlist();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 liveSessionId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool removeFromCompletedLiveSessionIdList = 2;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.prototype.getRemovefromcompletedlivesessionidlist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.prototype.setRemovefromcompletedlivesessionidlist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool removeFromRegisteredLiveSessionIdList = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.prototype.getRemovefromregisteredlivesessionidlist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.prototype.setRemovefromregisteredlivesessionidlist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string value = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForClearUserDataForDeletedLiveSessions.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldfirebaseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newfirebaseid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers;
  return proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldfirebaseid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewfirebaseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldfirebaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewfirebaseid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string oldFireBaseId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.prototype.getOldfirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.prototype.setOldfirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string newFireBaseId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.prototype.getNewfirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForHotFixMigrationsForMobileUsers.prototype.setNewfirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers;
  return proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForHotFixMigrationsForMobileUsers.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagesize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails;
  return proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 pageSize = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageNumber = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchinput: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails;
  return proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchinput(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchinput();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string searchInput = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.prototype.getSearchinput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.prototype.setSearchinput = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RequestForGetUserDetails pageable = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSearchUserByDetails.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    userList: jspb.Message.toObjectList(msg.getUserList(),
    proto.unifiedplatform.heartIntuneProfile.User.toObject, includeInstance),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails;
  return proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.addUser(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagenumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagesize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated User user = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.User>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.getUserList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.User>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.setUserList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.addUser = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.clearUserList = function() {
  return this.setUserList([]);
};


/**
 * optional int32 selectedPageNumber = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 selectedPageSize = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 totalCount = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseForSearchUserByDetails.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.toObject = function(includeInstance, msg) {
  var f, obj = {
    firebaseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    heartintuneuserid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase;
  return proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeartintuneuserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirebaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHeartintuneuserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string fireBaseId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.prototype.getFirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.prototype.setFirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 heartInTuneUserId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.prototype.getHeartintuneuserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForDeleteUserAccountFromFireBase.prototype.setHeartintuneuserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userrole: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole;
  return proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.Role} */ (reader.readEnum());
      msg.setUserrole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserrole();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Role userRole = 2;
 * @return {!proto.unifiedplatform.heartIntuneProfile.Role}
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.prototype.getUserrole = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.Role} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.Role} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestSetUserRole.prototype.setUserrole = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount;
  return proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 liveSessionId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: jspb.Message.getFieldWithDefault(msg, 1, ""),
    announcementtext: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scheduledat: (f = msg.getScheduledat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    locationurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    publishstatus: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest;
  return proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnouncementtext(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationurl(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.PublishContent} */ (reader.readEnum());
      msg.setPublishstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAnnouncementtext();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScheduledat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocationurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPublishstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string topic = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string announcementText = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.getAnnouncementtext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.setAnnouncementtext = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp scheduledAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.getScheduledat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.setScheduledat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.clearScheduledat = function() {
  return this.setScheduledat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.hasScheduledat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string locationUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.getLocationurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.setLocationurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.PublishContent publishStatus = 5;
 * @return {!proto.unifiedplatform.heartintune.common.PublishContent}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.getPublishstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.PublishContent} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.PublishContent} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementRequest.prototype.setPublishstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    announcementtext: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scheduledat: (f = msg.getScheduledat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    locationurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    publishstatus: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails;
  return proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnouncementtext(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationurl(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.PublishContent} */ (reader.readEnum());
      msg.setPublishstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnnouncementtext();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScheduledat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocationurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPublishstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string announcementText = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.getAnnouncementtext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.setAnnouncementtext = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp scheduledAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.getScheduledat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.setScheduledat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.clearScheduledat = function() {
  return this.setScheduledat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.hasScheduledat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string locationUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.getLocationurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.setLocationurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.PublishContent publishStatus = 5;
 * @return {!proto.unifiedplatform.heartintune.common.PublishContent}
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.getPublishstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.PublishContent} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.PublishContent} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AnnouncementDetails.prototype.setPublishstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    triggeredtime: (f = msg.getTriggeredtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isread: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    userid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ismute: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    thumbnailurl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    activitytype: jspb.Message.getFieldWithDefault(msg, 9, 0),
    meditationid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    visibility: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse;
  return proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNotificationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTriggeredtime(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsread(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsmute(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailurl(value);
      break;
    case 9:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (reader.readEnum());
      msg.setActivitytype(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeditationid(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibility(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTriggeredtime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsread();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getIsmute();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getThumbnailurl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getActivitytype();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getMeditationid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getVisibility();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int64 notificationId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getNotificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setNotificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subTitle = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp TriggeredTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getTriggeredtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setTriggeredtime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.clearTriggeredtime = function() {
  return this.setTriggeredtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.hasTriggeredtime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool isRead = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getIsread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setIsread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 userId = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool isMute = 7;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getIsmute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setIsmute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string thumbnailUrl = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getThumbnailurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setThumbnailurl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional unifiedplatform.heartintune.common.Activity activityType = 9;
 * @return {!proto.unifiedplatform.heartintune.common.Activity}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getActivitytype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Activity} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setActivitytype = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 meditationId = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getMeditationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setMeditationid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool visibility = 11;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.getVisibility = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.prototype.setVisibility = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.repeatedFields_ = [1,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationpageableresponseList: jspb.Message.toObjectList(msg.getNotificationpageableresponseList(),
    proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.toObject, includeInstance),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unreadnotificationlistList: jspb.Message.toObjectList(msg.getUnreadnotificationlistList(),
    proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse;
  return proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.deserializeBinaryFromReader);
      msg.addNotificationpageableresponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagenumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagesize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.deserializeBinaryFromReader);
      msg.addUnreadnotificationlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationpageableresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.serializeBinaryToWriter
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUnreadnotificationlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NotificationPageableResponse notificationPageableResponse = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.getNotificationpageableresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.setNotificationpageableresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.addNotificationpageableresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.clearNotificationpageableresponseList = function() {
  return this.setNotificationpageableresponseList([]);
};


/**
 * optional int32 selectedPageNumber = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 selectedPageSize = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 totalCount = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated NotificationPageableResponse unReadNotificationList = 5;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.getUnreadnotificationlistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse, 5));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.setUnreadnotificationlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.addUnreadnotificationlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.unifiedplatform.heartIntuneProfile.NotificationPageableResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationPageableResponse.prototype.clearUnreadnotificationlistList = function() {
  return this.setUnreadnotificationlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isread: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest;
  return proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNotificationid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsread(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIsread();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 notificationId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.prototype.getNotificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.prototype.setNotificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool isRead = 2;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.prototype.getIsread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReadNotificationRequest.prototype.setIsread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.toObject = function(includeInstance, msg) {
  var f, obj = {
    singleuserfirebaseid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers;
  return proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleuserfirebaseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSingleuserfirebaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string singleUserFirebaseId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.prototype.getSingleuserfirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SubscribeToTopicByAllUsers.prototype.setSingleuserfirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail;
  return proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestToGenerateOtpForEmail.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    otp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation}
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation;
  return proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation}
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOtp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string otp = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.prototype.getOtp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestOtpForEmailValidation.prototype.setOtp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.CarouselDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.CarouselDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.CarouselDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CarouselDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    carouseljson: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CarouselDetails}
 */
proto.unifiedplatform.heartIntuneProfile.CarouselDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.CarouselDetails;
  return proto.unifiedplatform.heartIntuneProfile.CarouselDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CarouselDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CarouselDetails}
 */
proto.unifiedplatform.heartIntuneProfile.CarouselDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarouseljson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.CarouselDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.CarouselDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CarouselDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CarouselDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarouseljson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string carouselJson = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CarouselDetails.prototype.getCarouseljson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CarouselDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CarouselDetails.prototype.setCarouseljson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 4, 0),
    selecteddateandtimewithcalendareventList: jspb.Message.toObjectList(msg.getSelecteddateandtimewithcalendareventList(),
    proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.toObject, includeInstance),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal;
  return proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader);
      msg.addSelecteddateandtimewithcalendarevent(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSelecteddateandtimewithcalendareventList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional int64 goalID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userID = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 4;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated SelectedDateAndTimeWithCalendarEvent selectedDateAndTimeWithCalendarEvent = 5;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent>}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.getSelecteddateandtimewithcalendareventList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent, 5));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.setSelecteddateandtimewithcalendareventList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.addSelecteddateandtimewithcalendarevent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.clearSelecteddateandtimewithcalendareventList = function() {
  return this.setSelecteddateandtimewithcalendareventList([]);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 6;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestToScheduleGoal.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    calendareventid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    delaytimeinminutes: jspb.Message.getFieldWithDefault(msg, 5, 0),
    scheduletime: (f = msg.getScheduletime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.heartIntuneProfile.PaginationRequest.toObject(includeInstance, f),
    firebaseid: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest;
  return proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalendareventid(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelaytimeinminutes(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduletime(value);
      break;
    case 7:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationRequest;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationRequest.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCalendareventid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDelaytimeinminutes();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getScheduletime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationRequest.serializeBinaryToWriter
    );
  }
  f = message.getFirebaseid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 UserId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 liveSessionId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string calendarEventId = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.getCalendareventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.setCalendareventid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 4;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint32 delayTimeInMinutes = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.getDelaytimeinminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.setDelaytimeinminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp scheduleTime = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.getScheduletime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.setScheduletime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.clearScheduletime = function() {
  return this.setScheduletime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.hasScheduletime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PaginationRequest pageable = 7;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationRequest, 7));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string firebaseId = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.getFirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddLiveSessionCalendarEventIdRequest.prototype.setFirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sessiontitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sessionsubtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sessioninfo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    registeredusercount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    liveparticipantscount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    aboutsession: jspb.Message.getFieldWithDefault(msg, 9, ""),
    meditationsteps: jspb.Message.getFieldWithDefault(msg, 10, ""),
    associatedcoachid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 15, 0),
    attribute: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.SessionInfoV2;
  return proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionsubtitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessioninfo(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegisteredusercount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLiveparticipantscount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAboutsession(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeditationsteps(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedcoachid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 15:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSessiontitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSessionsubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSessioninfo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getRegisteredusercount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getLiveparticipantscount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getAboutsession();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMeditationsteps();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAssociatedcoachid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getAttribute();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string sessionTitle = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getSessiontitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setSessiontitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sessionSubTitle = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getSessionsubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setSessionsubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sessionInfo = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getSessioninfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setSessioninfo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 duration = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 registeredUserCount = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getRegisteredusercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setRegisteredusercount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 liveParticipantsCount = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getLiveparticipantscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setLiveparticipantscount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string aboutSession = 9;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getAboutsession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setAboutsession = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string meditationSteps = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getMeditationsteps = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setMeditationsteps = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 associatedCoachId = 11;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getAssociatedcoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setAssociatedcoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string imageURL = 13;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string videoURL = 14;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 15;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional string attribute = 16;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.getAttribute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.prototype.setAttribute = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profiletitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    profilesubtitle: jspb.Message.getFieldWithDefault(msg, 5, ""),
    profiledescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    playbackurl: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.CoachProfileV2;
  return proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfiletitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilesubtitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfiledescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaybackurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfiletitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProfilesubtitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProfiledescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPlaybackurl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profileTitle = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.getProfiletitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.setProfiletitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string profileSubTitle = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.getProfilesubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.setProfilesubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string profileDescription = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.getProfiledescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.setProfiledescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string imageURL = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string videoURL = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string playBackUrl = 9;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.getPlaybackurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.prototype.setPlaybackurl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    duration: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requiredtimes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usercompletedrequiredtimescount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime}
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime;
  return proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime}
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequiredtimes(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsercompletedrequiredtimescount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequiredtimes();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUsercompletedrequiredtimescount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 duration = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 requiredTimes = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.prototype.getRequiredtimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.prototype.setRequiredtimes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 userCompletedRequiredTimesCount = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.prototype.getUsercompletedrequiredtimescount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.prototype.setUsercompletedrequiredtimescount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.repeatedFields_ = [4,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    durationandrequiredtimeList: jspb.Message.toObjectList(msg.getDurationandrequiredtimeList(),
    proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.toObject, includeInstance),
    requireddays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 6, 0),
    associatedbadgeid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    badgecount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    hours: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    requiredsessionidsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    requiredtimes: jspb.Message.getFieldWithDefault(msg, 13, 0),
    goaltitle: (f = msg.getGoaltitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    goaldescription: (f = msg.getGoaldescription()) && common_common_pb.Locale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2;
  return proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.deserializeBinaryFromReader);
      msg.addDurationandrequiredtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequireddays(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedbadgeid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHours(value);
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRequiredsessionids(values[i]);
      }
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequiredtimes(value);
      break;
    case 14:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setGoaltitle(value);
      break;
    case 15:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setGoaldescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDurationandrequiredtimeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime.serializeBinaryToWriter
    );
  }
  f = message.getRequireddays();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAssociatedbadgeid();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getHours();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getRequiredsessionidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getRequiredtimes();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getGoaltitle();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getGoaldescription();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DurationAndRequiredTime durationAndRequiredTime = 4;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime>}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getDurationandrequiredtimeList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setDurationandrequiredtimeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.addDurationandrequiredtime = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.heartIntuneProfile.DurationAndRequiredTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.clearDurationandrequiredtimeList = function() {
  return this.setDurationandrequiredtimeList([]);
};


/**
 * optional uint32 requiredDays = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getRequireddays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setRequireddays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 6;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 associatedBadgeID = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getAssociatedbadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setAssociatedbadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 badgeCount = 9;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 duration = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional double hours = 11;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getHours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setHours = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * repeated int64 requiredSessionIds = 12;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getRequiredsessionidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setRequiredsessionidsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.addRequiredsessionids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.clearRequiredsessionidsList = function() {
  return this.setRequiredsessionidsList([]);
};


/**
 * optional int64 requiredTimes = 13;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getRequiredtimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setRequiredtimes = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale goalTitle = 14;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getGoaltitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 14));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setGoaltitle = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.clearGoaltitle = function() {
  return this.setGoaltitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.hasGoaltitle = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale goalDescription = 15;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.getGoaldescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 15));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.setGoaldescription = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.clearGoaldescription = function() {
  return this.setGoaldescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.prototype.hasGoaldescription = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badgedescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    badgecolor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    badgecount: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2;
  return proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgedescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgecolor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadgedescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBadgecolor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string badgeDescription = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.getBadgedescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.setBadgedescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string badgeColor = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.getBadgecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.setBadgecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 badgeCount = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessioninfo: (f = msg.getSessioninfo()) && proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.toObject(includeInstance, f),
    associatecoach: (f = msg.getAssociatecoach()) && proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2;
  return proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.SessionInfoV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.CoachProfileV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.deserializeBinaryFromReader);
      msg.setAssociatecoach(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.serializeBinaryToWriter
    );
  }
  f = message.getAssociatecoach();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfoV2 sessionInfo = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.SessionInfoV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.SessionInfoV2, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.SessionInfoV2|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CoachProfileV2 associateCoach = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.CoachProfileV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.prototype.getAssociatecoach = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.CoachProfileV2, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.CoachProfileV2|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.prototype.setAssociatecoach = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.prototype.clearAssociatecoach = function() {
  return this.setAssociatecoach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.prototype.hasAssociatecoach = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.repeatedFields_ = [9,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    goaltypes: (f = msg.getGoaltypes()) && proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.toObject(includeInstance, f),
    badge: (f = msg.getBadge()) && proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.toObject(includeInstance, f),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goalscheduledtime: (f = msg.getGoalscheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    daystocomplete: jspb.Message.getFieldWithDefault(msg, 5, 0),
    completedgoalpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    usercompletedrequiredtimescount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    earnedbadgecount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    listofgoalscheduleddatesList: jspb.Message.toObjectList(msg.getListofgoalscheduleddatesList(),
    proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.toObject, includeInstance),
    totalregisteredusercount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    isregistered: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    badgewithcountList: jspb.Message.toObjectList(msg.getBadgewithcountList(),
    proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2;
  return proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.deserializeBinaryFromReader);
      msg.setGoaltypes(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setGoalscheduledtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDaystocomplete(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCompletedgoalpercentage(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsercompletedrequiredtimescount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEarnedbadgecount(value);
      break;
    case 9:
      var value = new proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader);
      msg.addListofgoalscheduleddates(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalregisteredusercount(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsregistered(value);
      break;
    case 12:
      var value = new proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.deserializeBinaryFromReader);
      msg.addBadgewithcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoaltypes();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2.serializeBinaryToWriter
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.serializeBinaryToWriter
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getGoalscheduledtime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDaystocomplete();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCompletedgoalpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getUsercompletedrequiredtimescount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getEarnedbadgecount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getListofgoalscheduleddatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter
    );
  }
  f = message.getTotalregisteredusercount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getIsregistered();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getBadgewithcountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2.serializeBinaryToWriter
    );
  }
};


/**
 * optional GoalInfoV2ResponseV2 goalTypes = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getGoaltypes = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.GoalInfoV2ResponseV2|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setGoaltypes = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.clearGoaltypes = function() {
  return this.setGoaltypes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.hasGoaltypes = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BadgeDetailsV2 badge = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getBadge = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 day = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp goalScheduledTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getGoalscheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setGoalscheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.clearGoalscheduledtime = function() {
  return this.setGoalscheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.hasGoalscheduledtime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 daysToComplete = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getDaystocomplete = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setDaystocomplete = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double completedGoalPercentage = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getCompletedgoalpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setCompletedgoalpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 userCompletedRequiredTimesCount = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getUsercompletedrequiredtimescount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setUsercompletedrequiredtimescount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 earnedBadgeCount = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getEarnedbadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setEarnedbadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated SelectedDateAndTimeWithCalendarEvent listOfGoalScheduledDates = 9;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getListofgoalscheduleddatesList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent, 9));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setListofgoalscheduleddatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.addListofgoalscheduleddates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.unifiedplatform.heartIntuneProfile.SelectedDateAndTimeWithCalendarEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.clearListofgoalscheduleddatesList = function() {
  return this.setListofgoalscheduleddatesList([]);
};


/**
 * optional int64 totalRegisteredUserCount = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getTotalregisteredusercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setTotalregisteredusercount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool isRegistered = 11;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getIsregistered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setIsregistered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated BadgeDetailsV2 badgeWithCount = 12;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.getBadgewithcountList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2, 12));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.setBadgewithcountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.addBadgewithcount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.unifiedplatform.heartIntuneProfile.BadgeDetailsV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.prototype.clearBadgewithcountList = function() {
  return this.setBadgewithcountList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startdatetime: (f = msg.getStartdatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddatetime: (f = msg.getEnddatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    version: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp;
  return proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdatetime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddatetime(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStartdatetime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddatetime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startDateTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.getStartdatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.setStartdatetime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.clearStartdatetime = function() {
  return this.setStartdatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.hasStartdatetime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endDateTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.getEnddatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.setEnddatetime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.clearEnddatetime = function() {
  return this.setEnddatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.hasEnddatetime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string version = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestV2GetAllMySignUp.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.toObject = function(includeInstance, msg) {
  var f, obj = {
    listofregisteredupcominglivesessionList: jspb.Message.toObjectList(msg.getListofregisteredupcominglivesessionList(),
    proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.toObject, includeInstance),
    goaldetailsactiveongivendayList: jspb.Message.toObjectList(msg.getGoaldetailsactiveongivendayList(),
    proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.toObject, includeInstance),
    unregisteredupcominglivesessionList: jspb.Message.toObjectList(msg.getUnregisteredupcominglivesessionList(),
    proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.toObject, includeInstance),
    groupsresponseheartintuneList: jspb.Message.toObjectList(msg.getGroupsresponseheartintuneList(),
    proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.toObject, includeInstance),
    threedaymasterclassscheduledetailsList: jspb.Message.toObjectList(msg.getThreedaymasterclassscheduledetailsList(),
    schedule_pb.ScheduleInfo.toObject, includeInstance),
    circleactivityList: jspb.Message.toObjectList(msg.getCircleactivityList(),
    proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp;
  return proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.deserializeBinaryFromReader);
      msg.addListofregisteredupcominglivesession(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.deserializeBinaryFromReader);
      msg.addGoaldetailsactiveongivenday(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.deserializeBinaryFromReader);
      msg.addUnregisteredupcominglivesession(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.deserializeBinaryFromReader);
      msg.addGroupsresponseheartintune(value);
      break;
    case 5:
      var value = new schedule_pb.ScheduleInfo;
      reader.readMessage(value,schedule_pb.ScheduleInfo.deserializeBinaryFromReader);
      msg.addThreedaymasterclassscheduledetails(value);
      break;
    case 6:
      var value = new proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.deserializeBinaryFromReader);
      msg.addCircleactivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListofregisteredupcominglivesessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.serializeBinaryToWriter
    );
  }
  f = message.getGoaldetailsactiveongivendayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.serializeBinaryToWriter
    );
  }
  f = message.getUnregisteredupcominglivesessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2.serializeBinaryToWriter
    );
  }
  f = message.getGroupsresponseheartintuneList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.serializeBinaryToWriter
    );
  }
  f = message.getThreedaymasterclassscheduledetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      schedule_pb.ScheduleInfo.serializeBinaryToWriter
    );
  }
  f = message.getCircleactivityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseLiveMeditationV2 listOfRegisteredUpcomingLiveSession = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.getListofregisteredupcominglivesessionList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.setListofregisteredupcominglivesessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.addListofregisteredupcominglivesession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.clearListofregisteredupcominglivesessionList = function() {
  return this.setListofregisteredupcominglivesessionList([]);
};


/**
 * repeated ResponseGoalInfoV2 goalDetailsActiveOnGivenDay = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.getGoaldetailsactiveongivendayList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.setGoaldetailsactiveongivendayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.addGoaldetailsactiveongivenday = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.clearGoaldetailsactiveongivendayList = function() {
  return this.setGoaldetailsactiveongivendayList([]);
};


/**
 * repeated ResponseLiveMeditationV2 UnRegisteredUpcomingLiveSession = 3;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.getUnregisteredupcominglivesessionList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.setUnregisteredupcominglivesessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.addUnregisteredupcominglivesession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.heartIntuneProfile.ResponseLiveMeditationV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.clearUnregisteredupcominglivesessionList = function() {
  return this.setUnregisteredupcominglivesessionList([]);
};


/**
 * repeated GroupsResponseHeartInTune groupsResponseHeartInTune = 4;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.getGroupsresponseheartintuneList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.setGroupsresponseheartintuneList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.addGroupsresponseheartintune = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.clearGroupsresponseheartintuneList = function() {
  return this.setGroupsresponseheartintuneList([]);
};


/**
 * repeated schedule.ScheduleInfo ThreeDayMasterClassScheduleDetails = 5;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.getThreedaymasterclassscheduledetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, schedule_pb.ScheduleInfo, 5));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.setThreedaymasterclassscheduledetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.addThreedaymasterclassscheduledetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.clearThreedaymasterclassscheduledetailsList = function() {
  return this.setThreedaymasterclassscheduledetailsList([]);
};


/**
 * repeated CircleActivityDetails circleActivity = 6;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.getCircleactivityList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails, 6));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.setCircleactivityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.addCircleactivity = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseV2GetAllMySignUp.prototype.clearCircleactivityList = function() {
  return this.setCircleactivityList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    coachid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sessioninfo: (f = msg.getSessioninfo()) && proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.toObject(includeInstance, f),
    associatecoach: (f = msg.getAssociatecoach()) && proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.toObject(includeInstance, f),
    registeredforlivesession: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse;
  return proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCoachid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.heartIntuneProfile.SessionInfoV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    case 6:
      var value = new proto.unifiedplatform.heartIntuneProfile.CoachProfileV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.deserializeBinaryFromReader);
      msg.setAssociatecoach(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegisteredforlivesession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCoachid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.heartIntuneProfile.SessionInfoV2.serializeBinaryToWriter
    );
  }
  f = message.getAssociatecoach();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.unifiedplatform.heartIntuneProfile.CoachProfileV2.serializeBinaryToWriter
    );
  }
  f = message.getRegisteredforlivesession();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 coachId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.getCoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.setCoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SessionInfoV2 sessionInfo = 5;
 * @return {?proto.unifiedplatform.heartIntuneProfile.SessionInfoV2}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.SessionInfoV2} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.SessionInfoV2, 5));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.SessionInfoV2|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CoachProfileV2 associateCoach = 6;
 * @return {?proto.unifiedplatform.heartIntuneProfile.CoachProfileV2}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.getAssociatecoach = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.CoachProfileV2} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.CoachProfileV2, 6));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.CoachProfileV2|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.setAssociatecoach = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.clearAssociatecoach = function() {
  return this.setAssociatecoach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.hasAssociatecoach = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool registeredForLiveSession = 10;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.getRegisteredforlivesession = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusResponse.prototype.setRegisteredforlivesession = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    coachid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    version: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest;
  return proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCoachid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCoachid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 coachId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.getCoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.setCoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 5;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string version = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionDetailsAndUserRegistrationStatusRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sharedvia: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sharedcontent: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sharedlink: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sharedcontentid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest;
  return proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.SharedVia} */ (reader.readEnum());
      msg.setSharedvia(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.SharedContent} */ (reader.readEnum());
      msg.setSharedcontent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSharedlink(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSharedcontentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSharedvia();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSharedcontent();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSharedlink();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSharedcontentid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.SharedVia sharedVia = 2;
 * @return {!proto.unifiedplatform.heartintune.common.SharedVia}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.getSharedvia = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.SharedVia} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.SharedVia} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.setSharedvia = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.SharedContent sharedContent = 3;
 * @return {!proto.unifiedplatform.heartintune.common.SharedContent}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.getSharedcontent = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.SharedContent} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.SharedContent} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.setSharedcontent = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string sharedLink = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.getSharedlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.setSharedlink = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 sharedContentId = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.getSharedcontentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsRequest.prototype.setSharedcontentid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sharedvia: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sharedcontent: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sharedlink: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sharedcontentid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    shareddatetime: (f = msg.getShareddatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails;
  return proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.SharedVia} */ (reader.readEnum());
      msg.setSharedvia(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.SharedContent} */ (reader.readEnum());
      msg.setSharedcontent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSharedlink(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSharedcontentid(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setShareddatetime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSharedvia();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSharedcontent();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSharedlink();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSharedcontentid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getShareddatetime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.SharedVia sharedVia = 2;
 * @return {!proto.unifiedplatform.heartintune.common.SharedVia}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.getSharedvia = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.SharedVia} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.SharedVia} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.setSharedvia = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.SharedContent sharedContent = 3;
 * @return {!proto.unifiedplatform.heartintune.common.SharedContent}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.getSharedcontent = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.SharedContent} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.SharedContent} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.setSharedcontent = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string sharedLink = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.getSharedlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.setSharedlink = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 sharedContentId = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.getSharedcontentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.setSharedcontentid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp sharedDateTime = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.getShareddatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.setShareddatetime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.clearShareddatetime = function() {
  return this.setShareddatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.hasShareddatetime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 userId = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.StayConnectedWithUsDetails.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rating: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest;
  return proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} */ (reader.readEnum());
      msg.setRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 liveSessionID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LiveMeditationRating rating = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LiveMeditationRating}
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.prototype.getRating = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.LiveSessionRatingRequest.prototype.setRating = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goalstatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectlanguage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goalid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    imported: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.heartIntuneProfile.PaginationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest;
  return proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setGoalstatus(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectlanguage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setImported(value);
      break;
    case 6:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationRequest;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationRequest.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGoalstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSelectlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getImported();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus goalStatus = 2;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.getGoalstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.setGoalstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectLanguage = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.getSelectlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.setSelectlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 goalId = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool imported = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.getImported = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.setImported = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional PaginationRequest pageable = 6;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationRequest, 6));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsegoalinfov2List: jspb.Message.toObjectList(msg.getResponsegoalinfov2List(),
    proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.toObject, includeInstance),
    totalgoalscount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    goalstatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.heartIntuneProfile.PaginationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse;
  return proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.deserializeBinaryFromReader);
      msg.addResponsegoalinfov2(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalgoalscount(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setGoalstatus(value);
      break;
    case 6:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationRequest;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationRequest.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsegoalinfov2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.serializeBinaryToWriter
    );
  }
  f = message.getTotalgoalscount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getGoalstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseGoalInfoV2 responseGoalInfoV2 = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2>}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.getResponsegoalinfov2List = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.setResponsegoalinfov2List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.addResponsegoalinfov2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.clearResponsegoalinfov2List = function() {
  return this.setResponsegoalinfov2List([]);
};


/**
 * optional int64 totalGoalsCount = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.getTotalgoalscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.setTotalgoalscount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus goalStatus = 3;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.getGoalstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.setGoalstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PaginationRequest pageable = 6;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationRequest, 6));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllGoalStatusByUserResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    useracceptedgoalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usermissedgoalcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usercompletedgoalcount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse}
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse;
  return proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse}
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUseracceptedgoalcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsermissedgoalcount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsercompletedgoalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUseracceptedgoalcount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsermissedgoalcount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUsercompletedgoalcount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 userAcceptedGoalCount = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.prototype.getUseracceptedgoalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.prototype.setUseracceptedgoalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userMissedGoalCount = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.prototype.getUsermissedgoalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.prototype.setUsermissedgoalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 userCompletedGoalCount = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.prototype.getUsercompletedgoalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetGoalCountByUserResponse.prototype.setUsercompletedgoalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime;
  return proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 pageNumber = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 pageSize = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 6;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodRequestBySpecifiedTime.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    moodmatterdetailsList: jspb.Message.toObjectList(msg.getMoodmatterdetailsList(),
    proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.toObject, includeInstance),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime;
  return proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.UserMoodDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.deserializeBinaryFromReader);
      msg.addMoodmatterdetails(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagenumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagesize(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMoodmatterdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.serializeBinaryToWriter
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp startTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated UserMoodDetails moodMatterDetails = 3;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.getMoodmatterdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.UserMoodDetails, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.setMoodmatterdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.addMoodmatterdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.heartIntuneProfile.UserMoodDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.clearMoodmatterdetailsList = function() {
  return this.setMoodmatterdetailsList([]);
};


/**
 * optional int32 selectedPageNumber = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 selectedPageSize = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 totalCount = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodResponseBySpecifiedTime.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    moodmapList: jspb.Message.toObjectList(msg.getMoodmapList(),
    proto.unifiedplatform.heartIntuneProfile.UserMoodMap.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse;
  return proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.heartIntuneProfile.UserMoodMap;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.UserMoodMap.deserializeBinaryFromReader);
      msg.addMoodmap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMoodmapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.heartIntuneProfile.UserMoodMap.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated UserMoodMap moodMap = 4;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.UserMoodMap>}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.getMoodmapList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.UserMoodMap>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.UserMoodMap, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.UserMoodMap>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.setMoodmapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.addMoodmap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.heartIntuneProfile.UserMoodMap, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMapResponse.prototype.clearMoodmapList = function() {
  return this.setMoodmapList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserMoodMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    moodid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalcheckin: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ratingone: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ratingtwo: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ratingthree: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ratingfour: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ratingfive: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lowername: jspb.Message.getFieldWithDefault(msg, 8, ""),
    uppername: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserMoodMap;
  return proto.unifiedplatform.heartIntuneProfile.UserMoodMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMoodid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcheckin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingone(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingtwo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingthree(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingfour(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingfive(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLowername(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUppername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserMoodMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoodid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTotalcheckin();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRatingone();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRatingtwo();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRatingthree();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRatingfour();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getRatingfive();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLowername();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUppername();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 moodId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.getMoodid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.setMoodid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 totalCheckIn = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.getTotalcheckin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.setTotalcheckin = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 ratingOne = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.getRatingone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.setRatingone = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 ratingTwo = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.getRatingtwo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.setRatingtwo = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 ratingThree = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.getRatingthree = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.setRatingthree = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 ratingFour = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.getRatingfour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.setRatingfour = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 ratingFive = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.getRatingfive = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.setRatingfive = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string lowerName = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.getLowername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.setLowername = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string upperName = 9;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.getUppername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodMap} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodMap.prototype.setUppername = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ReminderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    remindertime: (f = msg.getRemindertime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tuesday: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    wednesday: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    thursday: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    friday: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    saturday: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    sunday: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    monday: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    remindertype: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ReminderRequest;
  return proto.unifiedplatform.heartIntuneProfile.ReminderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRemindertime(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTuesday(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWednesday(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setThursday(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFriday(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaturday(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSunday(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMonday(value);
      break;
    case 10:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ReminderType} */ (reader.readEnum());
      msg.setRemindertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ReminderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRemindertime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTuesday();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getWednesday();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getThursday();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFriday();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSaturday();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSunday();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMonday();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getRemindertype();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp reminderTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.getRemindertime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.setRemindertime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.clearRemindertime = function() {
  return this.setRemindertime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.hasRemindertime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool tuesday = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.getTuesday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.setTuesday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool wednesday = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.getWednesday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.setWednesday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool thursday = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.getThursday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.setThursday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool friday = 6;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.getFriday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.setFriday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool saturday = 7;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.getSaturday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.setSaturday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool sunday = 8;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.getSunday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.setSunday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool monday = 9;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.getMonday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.setMonday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional unifiedplatform.heartintune.common.ReminderType reminderType = 10;
 * @return {!proto.unifiedplatform.heartintune.common.ReminderType}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.getRemindertype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ReminderType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ReminderType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderRequest.prototype.setRemindertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ReminderDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    remindertime: (f = msg.getRemindertime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tuesday: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    wednesday: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    thursday: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    friday: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    saturday: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    sunday: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    monday: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    remindertype: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ReminderDetails;
  return proto.unifiedplatform.heartIntuneProfile.ReminderDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRemindertime(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTuesday(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWednesday(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setThursday(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFriday(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaturday(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSunday(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMonday(value);
      break;
    case 11:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ReminderType} */ (reader.readEnum());
      msg.setRemindertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ReminderDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRemindertime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTuesday();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getWednesday();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getThursday();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getFriday();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSaturday();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSunday();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMonday();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getRemindertype();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional int64 Id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp reminderTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getRemindertime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setRemindertime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.clearRemindertime = function() {
  return this.setRemindertime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.hasRemindertime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool tuesday = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getTuesday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setTuesday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool wednesday = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getWednesday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setWednesday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool thursday = 6;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getThursday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setThursday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool friday = 7;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getFriday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setFriday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool saturday = 8;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getSaturday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setSaturday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool sunday = 9;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getSunday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setSunday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool monday = 10;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getMonday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setMonday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional unifiedplatform.heartintune.common.ReminderType reminderType = 11;
 * @return {!proto.unifiedplatform.heartintune.common.ReminderType}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.getRemindertype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ReminderType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ReminderType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderDetails.prototype.setRemindertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    listofmoodratingsList: jspb.Message.toObjectList(msg.getListofmoodratingsList(),
    proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.toObject, includeInstance),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse;
  return proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.deserializeBinaryFromReader);
      msg.addListofmoodratings(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagenumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagesize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getListofmoodratingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.serializeBinaryToWriter
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ListOfMoodRatings listOfMoodRatings = 4;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings>}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.getListofmoodratingsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.setListofmoodratingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.addListofmoodratings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.clearListofmoodratingsList = function() {
  return this.setListofmoodratingsList([]);
};


/**
 * optional int32 selectedPageNumber = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 selectedPageSize = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 totalCount = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInDetailsResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.toObject = function(includeInstance, msg) {
  var f, obj = {
    moodid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mooduppername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    moodlowername: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rating: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings;
  return proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMoodid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMooduppername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMoodlowername(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRating(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoodid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMooduppername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMoodlowername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRating();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 moodId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.getMoodid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.setMoodid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string moodUpperName = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.getMooduppername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.setMooduppername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string moodLowerName = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.getMoodlowername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.setMoodlowername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 rating = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfMoodRatings.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    remindertype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest;
  return proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ReminderType} */ (reader.readEnum());
      msg.setRemindertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRemindertype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 UserId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.ReminderType reminderType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.ReminderType}
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.prototype.getRemindertype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ReminderType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ReminderType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ReminderTypeRequest.prototype.setRemindertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    backgroundmusicid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    backgroundimageid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest;
  return proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBackgroundmusicid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBackgroundimageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBackgroundmusicid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBackgroundimageid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 backgroundMusicId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.prototype.getBackgroundmusicid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.prototype.setBackgroundmusicid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 backgroundImageId = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.prototype.getBackgroundimageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundRequest.prototype.setBackgroundimageid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    respectiveid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    listofinvitestatusList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserIdRequest;
  return proto.unifiedplatform.heartIntuneProfile.UserIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRespectiveid(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.unifiedplatform.heartintune.common.InviteStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addListofinvitestatus(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRespectiveid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getListofinvitestatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 id = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 respectiveId = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.getRespectiveid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.setRespectiveid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.InviteStatus status = 5;
 * @return {!proto.unifiedplatform.heartintune.common.InviteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.getStatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated unifiedplatform.heartintune.common.InviteStatus listOfInviteStatus = 6;
 * @return {!Array<!proto.unifiedplatform.heartintune.common.InviteStatus>}
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.getListofinvitestatusList = function() {
  return /** @type {!Array<!proto.unifiedplatform.heartintune.common.InviteStatus>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartintune.common.InviteStatus>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.setListofinvitestatusList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.addListofinvitestatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserIdRequest.prototype.clearListofinvitestatusList = function() {
  return this.setListofinvitestatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    backgroundtypeimageid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    backgroundimageurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    backgroundtypemusicid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    backgroundmusicurl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    backgroundtypemusicimageurl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse;
  return proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBackgroundtypeimageid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundimageurl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBackgroundtypemusicid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundmusicurl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundtypemusicimageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBackgroundtypeimageid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBackgroundimageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBackgroundtypemusicid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getBackgroundmusicurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBackgroundtypemusicimageurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 backgroundTypeImageId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.getBackgroundtypeimageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.setBackgroundtypeimageid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string backgroundImageUrl = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.getBackgroundimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.setBackgroundimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 backgroundTypeMusicId = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.getBackgroundtypemusicid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.setBackgroundtypemusicid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string backgroundMusicUrl = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.getBackgroundmusicurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.setBackgroundmusicurl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string backgroundTypeMusicImageUrl = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.getBackgroundtypemusicimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPreferredBackgroundResponse.prototype.setBackgroundtypemusicimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goaltitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage}
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage;
  return proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage}
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoaltitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGoaltitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 goalId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string goalTitle = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.prototype.getGoaltitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.prototype.setGoaltitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 day = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.toObject = function(includeInstance, msg) {
  var f, obj = {
    getinprogressgoalcardforhomepageList: jspb.Message.toObjectList(msg.getGetinprogressgoalcardforhomepageList(),
    proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage;
  return proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.deserializeBinaryFromReader);
      msg.addGetinprogressgoalcardforhomepage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetinprogressgoalcardforhomepageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetInProgressGoalCardForHomePage getInProgressGoalCardForHomePage = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.prototype.getGetinprogressgoalcardforhomepageList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.prototype.setGetinprogressgoalcardforhomepageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.prototype.addGetinprogressgoalcardforhomepage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.GetInProgressGoalCardForHomePage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGetInProgressGoalCardForHomePage.prototype.clearGetinprogressgoalcardforhomepageList = function() {
  return this.setGetinprogressgoalcardforhomepageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contentid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 3, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paginationrequest: (f = msg.getPaginationrequest()) && proto.unifiedplatform.heartIntuneProfile.PaginationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.FavoriteRequest;
  return proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContentid(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationRequest;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationRequest.deserializeBinaryFromReader);
      msg.setPaginationrequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContentid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPaginationrequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 contentId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.getContentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.setContentid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 3;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PaginationRequest paginationRequest = 5;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.getPaginationrequest = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationRequest, 5));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.setPaginationrequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.clearPaginationrequest = function() {
  return this.setPaginationrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteRequest.prototype.hasPaginationrequest = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.repeatedFields_ = [18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    author: jspb.Message.getFieldWithDefault(msg, 4, ""),
    image: jspb.Message.getFieldWithDefault(msg, 5, ""),
    content: jspb.Message.getFieldWithDefault(msg, 6, ""),
    readingtime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedat: (f = msg.getModifiedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tag: jspb.Message.getFieldWithDefault(msg, 10, ""),
    webviewurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 12, 0),
    videourl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    featuredimage: jspb.Message.getFieldWithDefault(msg, 14, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 15, ""),
    videocategory: jspb.Message.getFieldWithDefault(msg, 16, 0),
    featuredcontent: jspb.Message.getFieldWithDefault(msg, 17, 0),
    filteridsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    attribute: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse;
  return proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReadingtime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebviewurl(value);
      break;
    case 12:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeaturedimage(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 16:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.VideoCategory} */ (reader.readEnum());
      msg.setVideocategory(value);
      break;
    case 17:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.FeaturedContent} */ (reader.readEnum());
      msg.setFeaturedcontent(value);
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFilterids(values[i]);
      }
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReadingtime();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWebviewurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFeaturedimage();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getVideocategory();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getFeaturedcontent();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getFilteridsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      18,
      f
    );
  }
  f = message.getAttribute();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subtitle = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string author = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string content = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 readingTime = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getReadingtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setReadingtime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp modifiedAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getModifiedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setModifiedat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.clearModifiedat = function() {
  return this.setModifiedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.hasModifiedat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string tag = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string webViewUrl = 11;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getWebviewurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setWebviewurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 12;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string videoUrl = 13;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string featuredImage = 14;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getFeaturedimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setFeaturedimage = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string thumbnail = 15;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional unifiedplatform.heartintune.common.VideoCategory videoCategory = 16;
 * @return {!proto.unifiedplatform.heartintune.common.VideoCategory}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getVideocategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.VideoCategory} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.VideoCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setVideocategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional unifiedplatform.heartintune.common.FeaturedContent featuredContent = 17;
 * @return {!proto.unifiedplatform.heartintune.common.FeaturedContent}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getFeaturedcontent = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.FeaturedContent} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.FeaturedContent} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setFeaturedcontent = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * repeated int64 filterIds = 18;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getFilteridsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setFilteridsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.addFilterids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.clearFilteridsList = function() {
  return this.setFilteridsList([]);
};


/**
 * optional string attribute = 19;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.getAttribute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.prototype.setAttribute = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentList: jspb.Message.toObjectList(msg.getContentList(),
    proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && proto.unifiedplatform.heartIntuneProfile.PaginationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse;
  return proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.deserializeBinaryFromReader);
      msg.addContent(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationResponse;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationResponse.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HeartInTuneContentLocaleResponse content = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.prototype.getContentList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.prototype.setContentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.prototype.addContent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.HeartInTuneContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.prototype.clearContentList = function() {
  return this.setContentList([]);
};


/**
 * optional PaginationResponse pagination = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.prototype.getPagination = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationResponse, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfContentLocaleResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personalityquizid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userpersonalityid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    selectedmeterrating: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest;
  return proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonalityquizid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserpersonalityid(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedmeterrating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonalityquizid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserpersonalityid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSelectedmeterrating();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 personalityQuizId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.prototype.getPersonalityquizid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.prototype.setPersonalityquizid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userPersonalityId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.prototype.getUserpersonalityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.prototype.setUserpersonalityid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string selectedMeterRating = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.prototype.getSelectedmeterrating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.prototype.setSelectedmeterrating = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userpersonalityquizrequestList: jspb.Message.toObjectList(msg.getUserpersonalityquizrequestList(),
    proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest;
  return proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.deserializeBinaryFromReader);
      msg.addUserpersonalityquizrequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserpersonalityquizrequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated UserPersonalityQuizRequest userPersonalityQuizRequest = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest>}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.prototype.getUserpersonalityquizrequestList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.prototype.setUserpersonalityquizrequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.prototype.addUserpersonalityquizrequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.UserPersonalityQuizRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserPersonalityRequest.prototype.clearUserpersonalityquizrequestList = function() {
  return this.setUserpersonalityquizrequestList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    meterrating: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale;
  return proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeterrating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMeterrating();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string meterRating = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.prototype.getMeterrating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.prototype.setMeterrating = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.toObject = function(includeInstance, msg) {
  var f, obj = {
    heartintunepersonalityquizlocaleList: jspb.Message.toObjectList(msg.getHeartintunepersonalityquizlocaleList(),
    proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale;
  return proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.deserializeBinaryFromReader);
      msg.addHeartintunepersonalityquizlocale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeartintunepersonalityquizlocaleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HeartInTunePersonalityQuizLocale heartInTunePersonalityQuizLocale = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.prototype.getHeartintunepersonalityquizlocaleList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.prototype.setHeartintunepersonalityquizlocaleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.prototype.addHeartintunepersonalityquizlocale = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.HeartInTunePersonalityQuizLocale, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfHeartInTunePersonalityQuizLocale.prototype.clearHeartintunepersonalityquizlocaleList = function() {
  return this.setHeartintunepersonalityquizlocaleList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest;
  return proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 userID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetAllUserPersonalityQuizRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    devicenotificationrequest: (f = msg.getDevicenotificationrequest()) && notification_pb.DeviceNotificationRequest.toObject(includeInstance, f),
    useridswithcommaseperated: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest;
  return proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new notification_pb.DeviceNotificationRequest;
      reader.readMessage(value,notification_pb.DeviceNotificationRequest.deserializeBinaryFromReader);
      msg.setDevicenotificationrequest(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseridswithcommaseperated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDevicenotificationrequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      notification_pb.DeviceNotificationRequest.serializeBinaryToWriter
    );
  }
  f = message.getUseridswithcommaseperated();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional notification.DeviceNotificationRequest deviceNotificationRequest = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.prototype.getDevicenotificationrequest = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest} */ (
    jspb.Message.getWrapperField(this, notification_pb.DeviceNotificationRequest, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.prototype.setDevicenotificationrequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.prototype.clearDevicenotificationrequest = function() {
  return this.setDevicenotificationrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.prototype.hasDevicenotificationrequest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string userIdsWithCommaSeperated = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.prototype.getUseridswithcommaseperated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.HeartInTuneNotificationRequest.prototype.setUseridswithcommaseperated = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    heartspotemail: jspb.Message.getFieldWithDefault(msg, 1, ""),
    visitat: (f = msg.getVisitat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest}
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest;
  return proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest}
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeartspotemail(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setVisitat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeartspotemail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVisitat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string heartspotEmail = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.getHeartspotemail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.setHeartspotemail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp visitAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.getVisitat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.setVisitat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.clearVisitat = function() {
  return this.setVisitat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.hasVisitat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 userId = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EmailHeartspotRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quoteactivity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest}
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest;
  return proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest}
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (reader.readEnum());
      msg.setQuoteactivity(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQuoteactivity();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Activity quoteActivity = 2;
 * @return {!proto.unifiedplatform.heartintune.common.Activity}
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.prototype.getQuoteactivity = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Activity} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.prototype.setQuoteactivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.QuoteActivityRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.QuotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    author: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quote: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tagone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tagtwo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tagthree: jspb.Message.getFieldWithDefault(msg, 6, ""),
    screen: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.QuotesResponse;
  return proto.unifiedplatform.heartIntuneProfile.QuotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuote(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagtwo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagthree(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.QuotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTagone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTagtwo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTagthree();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getScreen();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string author = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string quote = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.getQuote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.setQuote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tagOne = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.getTagone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.setTagone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tagTwo = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.getTagtwo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.setTagtwo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tagThree = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.getTagthree = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.setTagthree = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string screen = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.getScreen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.QuotesResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.QuotesResponse.prototype.setScreen = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.MoodDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    moodid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    associatedcolor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    moodname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    moodtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetails}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.MoodDetails;
  return proto.unifiedplatform.heartIntuneProfile.MoodDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetails}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMoodid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedcolor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMoodname(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MoodType} */ (reader.readEnum());
      msg.setMoodtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.MoodDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoodid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAssociatedcolor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMoodname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMoodtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 moodId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.prototype.getMoodid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.prototype.setMoodid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string associatedColor = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.prototype.getAssociatedcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.prototype.setAssociatedcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string moodName = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.prototype.getMoodname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.prototype.setMoodname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.MoodType moodType = 4;
 * @return {!proto.unifiedplatform.heartintune.common.MoodType}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.prototype.getMoodtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MoodType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MoodType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetails.prototype.setMoodtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.TriggerDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    triggerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    emojiUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    triggername: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails}
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.TriggerDetails;
  return proto.unifiedplatform.heartIntuneProfile.TriggerDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails}
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTriggerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmojiUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTriggername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.TriggerDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTriggerid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmojiUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTriggername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 triggerId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.prototype.getTriggerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.prototype.setTriggerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string emoji_url = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.prototype.getEmojiUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.prototype.setEmojiUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string triggerName = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.prototype.getTriggername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TriggerDetails.prototype.setTriggername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    moodidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    triggeridsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    createat: (f = msg.getCreateat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userdefinemood: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userdefinetrigger: jspb.Message.getFieldWithDefault(msg, 6, ""),
    journaldata: (f = msg.getJournaldata()) && journal_pb.RequestJournal.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest;
  return proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMoodids(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTriggerids(values[i]);
      }
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserdefinemood(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserdefinetrigger(value);
      break;
    case 7:
      var value = new journal_pb.RequestJournal;
      reader.readMessage(value,journal_pb.RequestJournal.deserializeBinaryFromReader);
      msg.setJournaldata(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMoodidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getTriggeridsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getCreateat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserdefinemood();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserdefinetrigger();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getJournaldata();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      journal_pb.RequestJournal.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional int64 userID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 moodIds = 2;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.getMoodidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.setMoodidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.addMoodids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.clearMoodidsList = function() {
  return this.setMoodidsList([]);
};


/**
 * repeated int64 triggerIds = 3;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.getTriggeridsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.setTriggeridsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.addTriggerids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.clearTriggeridsList = function() {
  return this.setTriggeridsList([]);
};


/**
 * optional google.protobuf.Timestamp createAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.getCreateat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.setCreateat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.clearCreateat = function() {
  return this.setCreateat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.hasCreateat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string userDefineMood = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.getUserdefinemood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.setUserdefinemood = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string userDefineTrigger = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.getUserdefinetrigger = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.setUserdefinetrigger = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional journal.RequestJournal journalData = 7;
 * @return {?proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.getJournaldata = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal} */ (
    jspb.Message.getWrapperField(this, journal_pb.RequestJournal, 7));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.journal.RequestJournal|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.setJournaldata = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.clearJournaldata = function() {
  return this.setJournaldata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.hasJournaldata = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 8;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddUserMoodRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.repeatedFields_ = [3,4,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mooddetailsList: jspb.Message.toObjectList(msg.getMooddetailsList(),
    proto.unifiedplatform.heartIntuneProfile.MoodDetails.toObject, includeInstance),
    triggerdetailsList: jspb.Message.toObjectList(msg.getTriggerdetailsList(),
    proto.unifiedplatform.heartIntuneProfile.TriggerDetails.toObject, includeInstance),
    createtime: (f = msg.getCreatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userdefinemood: jspb.Message.getFieldWithDefault(msg, 6, ""),
    journalinfo: (f = msg.getJournalinfo()) && journal_pb.JournalInfo.toObject(includeInstance, f),
    mooddetailsandtriggersList: jspb.Message.toObjectList(msg.getMooddetailsandtriggersList(),
    proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.toObject, includeInstance),
    userdefinetrigger: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserMoodDetails;
  return proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.MoodDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.MoodDetails.deserializeBinaryFromReader);
      msg.addMooddetails(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.heartIntuneProfile.TriggerDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.TriggerDetails.deserializeBinaryFromReader);
      msg.addTriggerdetails(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatetime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserdefinemood(value);
      break;
    case 7:
      var value = new journal_pb.JournalInfo;
      reader.readMessage(value,journal_pb.JournalInfo.deserializeBinaryFromReader);
      msg.setJournalinfo(value);
      break;
    case 8:
      var value = new proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.deserializeBinaryFromReader);
      msg.addMooddetailsandtriggers(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserdefinetrigger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMooddetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.MoodDetails.serializeBinaryToWriter
    );
  }
  f = message.getTriggerdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.heartIntuneProfile.TriggerDetails.serializeBinaryToWriter
    );
  }
  f = message.getCreatetime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserdefinemood();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getJournalinfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      journal_pb.JournalInfo.serializeBinaryToWriter
    );
  }
  f = message.getMooddetailsandtriggersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.serializeBinaryToWriter
    );
  }
  f = message.getUserdefinetrigger();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated MoodDetails moodDetails = 3;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.MoodDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.getMooddetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.MoodDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.MoodDetails, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.MoodDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.setMooddetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetails}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.addMooddetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.heartIntuneProfile.MoodDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.clearMooddetailsList = function() {
  return this.setMooddetailsList([]);
};


/**
 * repeated TriggerDetails triggerDetails = 4;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.TriggerDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.getTriggerdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.TriggerDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.TriggerDetails, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.TriggerDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.setTriggerdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.addTriggerdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.heartIntuneProfile.TriggerDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.clearTriggerdetailsList = function() {
  return this.setTriggerdetailsList([]);
};


/**
 * optional google.protobuf.Timestamp createTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.getCreatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.setCreatetime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.clearCreatetime = function() {
  return this.setCreatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.hasCreatetime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string userDefineMood = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.getUserdefinemood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.setUserdefinemood = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional journal.JournalInfo journalInfo = 7;
 * @return {?proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.getJournalinfo = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} */ (
    jspb.Message.getWrapperField(this, journal_pb.JournalInfo, 7));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.setJournalinfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.clearJournalinfo = function() {
  return this.setJournalinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.hasJournalinfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated MoodDetailsAndTriggers moodDetailsAndTriggers = 8;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers>}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.getMooddetailsandtriggersList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers, 8));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.setMooddetailsandtriggersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.addMooddetailsandtriggers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.clearMooddetailsandtriggersList = function() {
  return this.setMooddetailsandtriggersList([]);
};


/**
 * optional string userDefineTrigger = 9;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.getUserdefinetrigger = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodDetails.prototype.setUserdefinetrigger = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.toObject = function(includeInstance, msg) {
  var f, obj = {
    mooddetails: (f = msg.getMooddetails()) && proto.unifiedplatform.heartIntuneProfile.MoodDetails.toObject(includeInstance, f),
    triggerdetailsList: jspb.Message.toObjectList(msg.getTriggerdetailsList(),
    proto.unifiedplatform.heartIntuneProfile.TriggerDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers;
  return proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.MoodDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.MoodDetails.deserializeBinaryFromReader);
      msg.setMooddetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.TriggerDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.TriggerDetails.deserializeBinaryFromReader);
      msg.addTriggerdetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMooddetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.MoodDetails.serializeBinaryToWriter
    );
  }
  f = message.getTriggerdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.TriggerDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional MoodDetails moodDetails = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.MoodDetails}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.prototype.getMooddetails = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.MoodDetails} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.MoodDetails, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.MoodDetails|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers} returns this
*/
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.prototype.setMooddetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.prototype.clearMooddetails = function() {
  return this.setMooddetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.prototype.hasMooddetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TriggerDetails triggerDetails = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.TriggerDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.prototype.getTriggerdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.TriggerDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.TriggerDetails, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.TriggerDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers} returns this
*/
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.prototype.setTriggerdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.TriggerDetails}
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.prototype.addTriggerdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.TriggerDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodDetailsAndTriggers.prototype.clearTriggerdetailsList = function() {
  return this.setTriggerdetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    checkincount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    happycheckincount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sadcheckincount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    happypercent: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sadpercent: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse;
  return proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCheckincount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHappycheckincount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSadcheckincount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHappypercent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSadpercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCheckincount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHappycheckincount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSadcheckincount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getHappypercent();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSadpercent();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 checkInCount = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.getCheckincount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.setCheckincount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 happyCheckInCount = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.getHappycheckincount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.setHappycheckincount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 sadCheckInCount = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.getSadcheckincount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.setSadcheckincount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 happyPercent = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.getHappypercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.setHappypercent = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 sadPercent = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.getSadpercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodCheckInsWithPercentResponse.prototype.setSadpercent = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contentid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest;
  return proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContentid(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContentid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 contentId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.prototype.getContentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.prototype.setContentid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 3;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FavoriteContentIsExistOrNotForUserRequest.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    journalinfo: (f = msg.getJournalinfo()) && journal_pb.JournalInfo.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest;
  return proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = new journal_pb.JournalInfo;
      reader.readMessage(value,journal_pb.JournalInfo.deserializeBinaryFromReader);
      msg.setJournalinfo(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getJournalinfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      journal_pb.JournalInfo.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional journal.JournalInfo journalInfo = 3;
 * @return {?proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.getJournalinfo = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo} */ (
    jspb.Message.getWrapperField(this, journal_pb.JournalInfo, 3));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.journal.JournalInfo|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.setJournalinfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.clearJournalinfo = function() {
  return this.setJournalinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.hasJournalinfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserMoodJournalRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    moodid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    triggerid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest}
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest;
  return proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest}
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMoodid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTriggerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoodid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTriggerid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 moodId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.prototype.getMoodid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.prototype.setMoodid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 triggerId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.prototype.getTriggerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MoodIdAndTriggerIdRequest.prototype.setTriggerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    register: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    join: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    groupOccurrenceId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pagination: (f = msg.getPagination()) && proto.unifiedplatform.heartIntuneProfile.PaginationRequest.toObject(includeInstance, f),
    currenttime: (f = msg.getCurrenttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest;
  return proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegister(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setJoin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupOccurrenceId(value);
      break;
    case 7:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationRequest;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationRequest.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrenttime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRegister();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJoin();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getGroupOccurrenceId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationRequest.serializeBinaryToWriter
    );
  }
  f = message.getCurrenttime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 group_id = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool register = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.getRegister = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.setRegister = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool join = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.getJoin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.setJoin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 group_occurrence_id = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.getGroupOccurrenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.setGroupOccurrenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional PaginationRequest pagination = 7;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.getPagination = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationRequest, 7));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp currentTime = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.getCurrenttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.setCurrenttime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.clearCurrenttime = function() {
  return this.setCurrenttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserGroupsRequest.prototype.hasCurrenttime = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    groupresponseList: jspb.Message.toObjectList(msg.getGroupresponseList(),
    proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.toObject, includeInstance),
    paginationresponse: (f = msg.getPaginationresponse()) && proto.unifiedplatform.heartIntuneProfile.PaginationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse;
  return proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGroupids(values[i]);
      }
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.deserializeBinaryFromReader);
      msg.addGroupresponse(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationResponse;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationResponse.deserializeBinaryFromReader);
      msg.setPaginationresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getGroupresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.serializeBinaryToWriter
    );
  }
  f = message.getPaginationresponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int64 groupIds = 1;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.getGroupidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.setGroupidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.addGroupids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.clearGroupidsList = function() {
  return this.setGroupidsList([]);
};


/**
 * repeated GroupsResponseHeartInTune groupResponse = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.getGroupresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.setGroupresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.addGroupresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.clearGroupresponseList = function() {
  return this.setGroupresponseList([]);
};


/**
 * optional PaginationResponse paginationResponse = 3;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.getPaginationresponse = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationResponse, 3));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.setPaginationresponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.clearPaginationresponse = function() {
  return this.setPaginationresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfGroupIDsResponse.prototype.hasPaginationresponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currenttime: (f = msg.getCurrenttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest}
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest;
  return proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest}
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrenttime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrenttime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp currentTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.prototype.getCurrenttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.prototype.setCurrenttime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.prototype.clearCurrenttime = function() {
  return this.setCurrenttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.TimeWithIdRequest.prototype.hasCurrenttime = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.repeatedFields_ = [13,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    prerecordedid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    recurring: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    scheduleat: (f = msg.getScheduleat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createat: (f = msg.getCreateat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    recurringendtime: (f = msg.getRecurringendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    joinusercount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    prerecordedimageurl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    totalacceptedcount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    dayfrequency: jspb.Message.getFieldWithDefault(msg, 12, 0),
    weekdaysList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    prerecordedobject: (f = msg.getPrerecordedobject()) && proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.toObject(includeInstance, f),
    circleactivityinvitedetailsList: jspb.Message.toObjectList(msg.getCircleactivityinvitedetailsList(),
    proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.toObject, includeInstance),
    circleactivityoccurrenceid: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails;
  return proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrerecordedid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurring(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduleat(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateat(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecurringendtime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJoinusercount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrerecordedimageurl(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalacceptedcount(value);
      break;
    case 12:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (reader.readEnum());
      msg.setDayfrequency(value);
      break;
    case 13:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeekdays(values[i]);
      }
      break;
    case 14:
      var value = new proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.deserializeBinaryFromReader);
      msg.setPrerecordedobject(value);
      break;
    case 15:
      var value = new proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.deserializeBinaryFromReader);
      msg.addCircleactivityinvitedetails(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCircleactivityoccurrenceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActivityname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrerecordedid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getRecurring();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getScheduleat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRecurringendtime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getJoinusercount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPrerecordedimageurl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTotalacceptedcount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDayfrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getWeekdaysList();
  if (f.length > 0) {
    writer.writePackedInt32(
      13,
      f
    );
  }
  f = message.getPrerecordedobject();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.serializeBinaryToWriter
    );
  }
  f = message.getCircleactivityinvitedetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.serializeBinaryToWriter
    );
  }
  f = message.getCircleactivityoccurrenceid();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activityName = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getActivityname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setActivityname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 preRecordedId = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getPrerecordedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setPrerecordedid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool recurring = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getRecurring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setRecurring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp scheduleAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getScheduleat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setScheduleat = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.clearScheduleat = function() {
  return this.setScheduleat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.hasScheduleat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp createAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getCreateat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setCreateat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.clearCreateat = function() {
  return this.setCreateat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.hasCreateat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp recurringEndTime = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getRecurringendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setRecurringendtime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.clearRecurringendtime = function() {
  return this.setRecurringendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.hasRecurringendtime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 joinUserCount = 9;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getJoinusercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setJoinusercount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string preRecordedImageUrl = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getPrerecordedimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setPrerecordedimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 totalAcceptedCount = 11;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getTotalacceptedcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setTotalacceptedcount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional unifiedplatform.heartintune.common.DayFrequency dayFrequency = 12;
 * @return {!proto.unifiedplatform.heartintune.common.DayFrequency}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getDayfrequency = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.DayFrequency} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setDayfrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * repeated int32 weekDays = 13;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getWeekdaysList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setWeekdaysList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.addWeekdays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.clearWeekdaysList = function() {
  return this.setWeekdaysList([]);
};


/**
 * optional PreRecordedWithLocaleResponseHeartInTune preRecordedObject = 14;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getPrerecordedobject = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune, 14));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setPrerecordedobject = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.clearPrerecordedobject = function() {
  return this.setPrerecordedobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.hasPrerecordedobject = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated CircleActivityInviteDetails circleActivityInviteDetails = 15;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getCircleactivityinvitedetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails, 15));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setCircleactivityinvitedetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.addCircleactivityinvitedetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.clearCircleactivityinvitedetailsList = function() {
  return this.setCircleactivityinvitedetailsList([]);
};


/**
 * optional int64 circleActivityOccurrenceId = 16;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.getCircleactivityoccurrenceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.prototype.setCircleactivityoccurrenceid = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userinviteid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    circleactivityid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    invitestatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails;
  return proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserinviteid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCircleactivityid(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (reader.readEnum());
      msg.setInvitestatus(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserinviteid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCircleactivityid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getInvitestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userInviteId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.getUserinviteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.setUserinviteid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 circleActivityId = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.getCircleactivityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.setCircleactivityid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.InviteStatus InviteStatus = 4;
 * @return {!proto.unifiedplatform.heartintune.common.InviteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.getInvitestatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.setInvitestatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional User user = 5;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 5));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserCircleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    useridandpagination: (f = msg.getUseridandpagination()) && proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.toObject(includeInstance, f),
    lastactivity: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    circlemetadataid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserCircleRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserCircleRequest;
  return proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserCircleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserCircleRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.deserializeBinaryFromReader);
      msg.setUseridandpagination(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLastactivity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCirclemetadataid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserCircleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUseridandpagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable.serializeBinaryToWriter
    );
  }
  f = message.getLastactivity();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCirclemetadataid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional RequestUserIDByPageable userIdAndPagination = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable}
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.prototype.getUseridandpagination = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.RequestUserIDByPageable|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserCircleRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.prototype.setUseridandpagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserCircleRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.prototype.clearUseridandpagination = function() {
  return this.setUseridandpagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.prototype.hasUseridandpagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool lastActivity = 2;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.prototype.getLastactivity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserCircleRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.prototype.setLastactivity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 circleMetadataId = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.prototype.getCirclemetadataid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserCircleRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserCircleRequest.prototype.setCirclemetadataid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    register: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    listofuserinfodetails: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest}
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest;
  return proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest}
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegister(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setListofuserinfodetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRegister();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getListofuserinfodetails();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 groupId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool register = 2;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.prototype.getRegister = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.prototype.setRegister = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 listOfUserInfoDetails = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.prototype.getListofuserinfodetails = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.FetchUserByGroupIdRequest.prototype.setListofuserinfodetails = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    meditationsteps: jspb.Message.getFieldWithDefault(msg, 5, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imgurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    prerecordedtype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    videoduration: jspb.Message.getFieldWithDefault(msg, 9, 0),
    attribute: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune;
  return proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeditationsteps(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgurl(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.PreRecordedType} */ (reader.readEnum());
      msg.setPrerecordedtype(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVideoduration(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMeditationsteps();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImgurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPrerecordedtype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getVideoduration();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getAttribute();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string videoUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string meditationSteps = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.getMeditationsteps = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.setMeditationsteps = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string thumbnail = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string imgUrl = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.getImgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.setImgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional unifiedplatform.heartintune.common.PreRecordedType preRecordedType = 8;
 * @return {!proto.unifiedplatform.heartintune.common.PreRecordedType}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.getPrerecordedtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.PreRecordedType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.PreRecordedType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.setPrerecordedtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 videoDuration = 9;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.getVideoduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.setVideoduration = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string attribute = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.getAttribute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.prototype.setAttribute = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scheduletime: (f = msg.getScheduletime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createat: (f = msg.getCreateat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    prerecorded: (f = msg.getPrerecorded()) && proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.toObject(includeInstance, f),
    userjoincount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    recurrence: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    recurringendtime: (f = msg.getRecurringendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dayfrequency: jspb.Message.getFieldWithDefault(msg, 8, 0),
    weekdaysList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    userregistercount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    firebasetopicname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    publish: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    forcefullyupdate: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    status: (f = msg.getStatus()) && proto.unifiedplatform.heartIntuneProfile.Status.toObject(includeInstance, f),
    groupoccurrenceid: jspb.Message.getFieldWithDefault(msg, 15, 0),
    userregistered: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune;
  return proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduletime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateat(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.deserializeBinaryFromReader);
      msg.setPrerecorded(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserjoincount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurrence(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecurringendtime(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (reader.readEnum());
      msg.setDayfrequency(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeekdays(values[i]);
      }
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserregistercount(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebasetopicname(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublish(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForcefullyupdate(value);
      break;
    case 14:
      var value = new proto.unifiedplatform.heartIntuneProfile.Status;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupoccurrenceid(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserregistered(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScheduletime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPrerecorded();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune.serializeBinaryToWriter
    );
  }
  f = message.getUserjoincount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRecurrence();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getRecurringendtime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDayfrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getWeekdaysList();
  if (f.length > 0) {
    writer.writePackedInt32(
      9,
      f
    );
  }
  f = message.getUserregistercount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getFirebasetopicname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPublish();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getForcefullyupdate();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.unifiedplatform.heartIntuneProfile.Status.serializeBinaryToWriter
    );
  }
  f = message.getGroupoccurrenceid();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getUserregistered();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp scheduleTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getScheduletime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setScheduletime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.clearScheduletime = function() {
  return this.setScheduletime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.hasScheduletime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp createAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getCreateat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setCreateat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.clearCreateat = function() {
  return this.setCreateat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.hasCreateat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PreRecordedWithLocaleResponseHeartInTune preRecorded = 4;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getPrerecorded = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune, 4));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PreRecordedWithLocaleResponseHeartInTune|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setPrerecorded = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.clearPrerecorded = function() {
  return this.setPrerecorded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.hasPrerecorded = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 userJoinCount = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getUserjoincount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setUserjoincount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool recurrence = 6;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getRecurrence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setRecurrence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp recurringEndTime = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getRecurringendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setRecurringendtime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.clearRecurringendtime = function() {
  return this.setRecurringendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.hasRecurringendtime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional unifiedplatform.heartintune.common.DayFrequency dayFrequency = 8;
 * @return {!proto.unifiedplatform.heartintune.common.DayFrequency}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getDayfrequency = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.DayFrequency} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setDayfrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated int32 weekDays = 9;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getWeekdaysList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setWeekdaysList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.addWeekdays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.clearWeekdaysList = function() {
  return this.setWeekdaysList([]);
};


/**
 * optional int64 userRegisterCount = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getUserregistercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setUserregistercount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string firebaseTopicName = 11;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getFirebasetopicname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setFirebasetopicname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool publish = 12;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getPublish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setPublish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool forcefullyUpdate = 13;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getForcefullyupdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setForcefullyupdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional Status status = 14;
 * @return {?proto.unifiedplatform.heartIntuneProfile.Status}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getStatus = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.Status} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.Status, 14));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.Status|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int64 groupOccurrenceId = 15;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getGroupoccurrenceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setGroupoccurrenceid = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional bool userRegistered = 16;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.getUserregistered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GroupsResponseHeartInTune.prototype.setUserregistered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    circleormetadataid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    invitestatus: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest;
  return proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCircleormetadataid(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (reader.readEnum());
      msg.setActivity(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (reader.readEnum());
      msg.setInvitestatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCircleormetadataid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getActivity();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getInvitestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 circleOrMetadataId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.prototype.getCircleormetadataid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.prototype.setCircleormetadataid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.Activity activity = 3;
 * @return {!proto.unifiedplatform.heartintune.common.Activity}
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.prototype.getActivity = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Activity} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.prototype.setActivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.InviteStatus inviteStatus = 4;
 * @return {!proto.unifiedplatform.heartintune.common.InviteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.prototype.getInvitestatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserActionForCircleInvitationRequest.prototype.setInvitestatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    circleoractivityid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userinviteidList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest;
  return proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCircleoractivityid(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserinviteid(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCircleoractivityid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserinviteidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 circleOrActivityId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.prototype.getCircleoractivityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.prototype.setCircleoractivityid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 userInviteId = 3;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.prototype.getUserinviteidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.prototype.setUserinviteidList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.prototype.addUserinviteid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleInvitationRequest.prototype.clearUserinviteidList = function() {
  return this.setUserinviteidList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    image: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activity: jspb.Message.getFieldWithDefault(msg, 5, 0),
    circleormetadataid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userinviteid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    invitestatus: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails;
  return proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (reader.readEnum());
      msg.setActivity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCircleormetadataid(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserinviteid(value);
      break;
    case 9:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (reader.readEnum());
      msg.setInvitestatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivity();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCircleormetadataid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserinviteid();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getInvitestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string image = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userName = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.Activity activity = 5;
 * @return {!proto.unifiedplatform.heartintune.common.Activity}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.getActivity = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Activity} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.setActivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 circleOrMetaDataId = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.getCircleormetadataid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.setCircleormetadataid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 userInviteId = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.getUserinviteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.setUserinviteid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional unifiedplatform.heartintune.common.InviteStatus inviteStatus = 9;
 * @return {!proto.unifiedplatform.heartintune.common.InviteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.getInvitestatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.prototype.setInvitestatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestalertsdetailsList: jspb.Message.toObjectList(msg.getRequestalertsdetailsList(),
    proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.toObject, includeInstance),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails;
  return proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.deserializeBinaryFromReader);
      msg.addRequestalertsdetails(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestalertsdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails.serializeBinaryToWriter
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated NotificationAlertsRequestDetails requestAlertsDetails = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.getRequestalertsdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.setRequestalertsdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.addRequestalertsdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.NotificationAlertsRequestDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.clearRequestalertsdetailsList = function() {
  return this.setRequestalertsdetailsList([]);
};


/**
 * optional int32 pageNumber = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 pageSize = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 totalCount = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfNotificationRequestAlertsDetails.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.toObject(includeInstance, f),
    ismycircle: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    islastactivity: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    invitestatus: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest;
  return proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsmycircle(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIslastactivity(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (reader.readEnum());
      msg.setInvitestatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails.serializeBinaryToWriter
    );
  }
  f = message.getIsmycircle();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIslastactivity();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getInvitestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phoneNumber = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional RequestForGetUserDetails pageable = 5;
 * @return {?proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails, 5));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.RequestForGetUserDetails|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool isMyCircle = 6;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.getIsmycircle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.setIsmycircle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool isLastActivity = 7;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.getIslastactivity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.setIslastactivity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional unifiedplatform.heartintune.common.InviteStatus inviteStatus = 8;
 * @return {!proto.unifiedplatform.heartintune.common.InviteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.getInvitestatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.SearchUserByDetailsRequest.prototype.setInvitestatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.CircleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    circlename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleRequest}
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.CircleRequest;
  return proto.unifiedplatform.heartIntuneProfile.CircleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleRequest}
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCirclename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.CircleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCirclename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string circleName = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest.prototype.getCirclename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleRequest.prototype.setCirclename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.CircleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    circlename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    totalusersinvitecount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleResponse}
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.CircleResponse;
  return proto.unifiedplatform.heartIntuneProfile.CircleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleResponse}
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCirclename(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalusersinvitecount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.CircleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCirclename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotalusersinvitecount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string circleName = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.prototype.getCirclename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.prototype.setCirclename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 totalUsersInviteCount = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.prototype.getTotalusersinvitecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleResponse.prototype.setTotalusersinvitecount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.repeatedFields_ = [10,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    circleid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    circlename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    activityname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    prerecordedid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    recurring: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    scheduleat: (f = msg.getScheduleat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dayfrequency: jspb.Message.getFieldWithDefault(msg, 9, 0),
    weekdaysList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    recurringendtime: (f = msg.getRecurringendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    useridinviteList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    circleactivityid: jspb.Message.getFieldWithDefault(msg, 13, 0),
    zonaldifference: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest;
  return proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCircleid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCirclename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrerecordedid(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurring(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduleat(value);
      break;
    case 9:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (reader.readEnum());
      msg.setDayfrequency(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeekdays(values[i]);
      }
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecurringendtime(value);
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUseridinvite(values[i]);
      }
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCircleactivityid(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZonaldifference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCircleid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCirclename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getActivityname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrerecordedid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getRecurring();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getScheduleat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDayfrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getWeekdaysList();
  if (f.length > 0) {
    writer.writePackedInt32(
      10,
      f
    );
  }
  f = message.getRecurringendtime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUseridinviteList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getCircleactivityid();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getZonaldifference();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
};


/**
 * optional int64 circleId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getCircleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setCircleid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string circleName = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getCirclename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setCirclename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 userId = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string activityName = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getActivityname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setActivityname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 preRecordedId = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getPrerecordedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setPrerecordedid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool recurring = 7;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getRecurring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setRecurring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp scheduleAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getScheduleat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setScheduleat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.clearScheduleat = function() {
  return this.setScheduleat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.hasScheduleat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional unifiedplatform.heartintune.common.DayFrequency dayFrequency = 9;
 * @return {!proto.unifiedplatform.heartintune.common.DayFrequency}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getDayfrequency = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.DayFrequency} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setDayfrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated int32 weekDays = 10;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getWeekdaysList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setWeekdaysList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.addWeekdays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.clearWeekdaysList = function() {
  return this.setWeekdaysList([]);
};


/**
 * optional google.protobuf.Timestamp recurringEndTime = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getRecurringendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setRecurringendtime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.clearRecurringendtime = function() {
  return this.setRecurringendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.hasRecurringendtime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated int64 userIdInvite = 12;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getUseridinviteList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setUseridinviteList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.addUseridinvite = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.clearUseridinviteList = function() {
  return this.setUseridinviteList([]);
};


/**
 * optional int64 circleActivityId = 13;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getCircleactivityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setCircleactivityid = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 zonalDifference = 14;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.getZonaldifference = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleActivityRequest.prototype.setZonaldifference = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.PaginationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.PaginationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagenumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.PaginationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.PaginationRequest;
  return proto.unifiedplatform.heartIntuneProfile.PaginationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.PaginationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.PaginationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.PaginationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.PaginationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 pageNumber = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PaginationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PaginationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PaginationRequest.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.PaginationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.PaginationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.PaginationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.PaginationResponse;
  return proto.unifiedplatform.heartIntuneProfile.PaginationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.PaginationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.PaginationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagenumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSelectedpagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.PaginationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.PaginationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int32 selectedPageNumber = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PaginationResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 selectedPageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PaginationResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 totalCount = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.PaginationResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.PaginationResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    circleactivityList: jspb.Message.toObjectList(msg.getCircleactivityList(),
    proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.toObject, includeInstance),
    paginationresponse: (f = msg.getPaginationresponse()) && proto.unifiedplatform.heartIntuneProfile.PaginationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse;
  return proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.deserializeBinaryFromReader);
      msg.addCircleactivity(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationResponse;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationResponse.deserializeBinaryFromReader);
      msg.setPaginationresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCircleactivityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails.serializeBinaryToWriter
    );
  }
  f = message.getPaginationresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CircleActivityDetails circleActivity = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.prototype.getCircleactivityList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.prototype.setCircleactivityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.prototype.addCircleactivity = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.CircleActivityDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.prototype.clearCircleactivityList = function() {
  return this.setCircleactivityList([]);
};


/**
 * optional PaginationResponse paginationResponse = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.prototype.getPaginationresponse = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationResponse, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.prototype.setPaginationresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.prototype.clearPaginationresponse = function() {
  return this.setPaginationresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ListOfCircleActivityDetailsResponse.prototype.hasPaginationresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currenttime: (f = msg.getCurrenttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paginationrequest: (f = msg.getPaginationrequest()) && proto.unifiedplatform.heartIntuneProfile.PaginationRequest.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    delaytimeinminutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    listofinvitestatusList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest;
  return proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrenttime(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationRequest;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationRequest.deserializeBinaryFromReader);
      msg.setPaginationrequest(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDelaytimeinminutes(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.unifiedplatform.heartintune.common.InviteStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addListofinvitestatus(values[i]);
      }
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrenttime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaginationrequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationRequest.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDelaytimeinminutes();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getListofinvitestatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 UserID = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp currentTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.getCurrenttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.setCurrenttime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.clearCurrenttime = function() {
  return this.setCurrenttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.hasCurrenttime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PaginationRequest paginationRequest = 3;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.getPaginationrequest = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationRequest, 3));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.setPaginationrequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.clearPaginationrequest = function() {
  return this.setPaginationrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.hasPaginationrequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.InviteStatus status = 5;
 * @return {!proto.unifiedplatform.heartintune.common.InviteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.getStatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 delayTimeInMinutes = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.getDelaytimeinminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.setDelaytimeinminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated unifiedplatform.heartintune.common.InviteStatus listOfInviteStatus = 7;
 * @return {!Array<!proto.unifiedplatform.heartintune.common.InviteStatus>}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.getListofinvitestatusList = function() {
  return /** @type {!Array<!proto.unifiedplatform.heartintune.common.InviteStatus>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartintune.common.InviteStatus>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.setListofinvitestatusList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.addListofinvitestatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.clearListofinvitestatusList = function() {
  return this.setListofinvitestatusList([]);
};


/**
 * optional google.protobuf.Timestamp endTime = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GetUpcomingActivityDetailsRequest.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    removeduserslistList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    adduserslistList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    circleoractivityid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    iscircleinvitation: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest;
  return proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRemoveduserslist(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdduserslist(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCircleoractivityid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscircleinvitation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRemoveduserslistList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getAdduserslistList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getCircleoractivityid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getIscircleinvitation();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 removedUsersList = 2;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.getRemoveduserslistList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.setRemoveduserslistList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.addRemoveduserslist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.clearRemoveduserslistList = function() {
  return this.setRemoveduserslistList([]);
};


/**
 * repeated int64 addUsersList = 3;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.getAdduserslistList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.setAdduserslistList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.addAdduserslist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.clearAdduserslistList = function() {
  return this.setAdduserslistList([]);
};


/**
 * optional int64 circleOrActivityId = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.getCircleoractivityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.setCircleoractivityid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool isCircleInvitation = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.getIscircleinvitation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.EditCircleOrActivityInvitationRequest.prototype.setIscircleinvitation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userinviteid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    circleid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    invitestatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    user: (f = msg.getUser()) && proto.unifiedplatform.heartIntuneProfile.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails;
  return proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserinviteid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCircleid(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (reader.readEnum());
      msg.setInvitestatus(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.heartIntuneProfile.User;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserinviteid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCircleid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getInvitestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.heartIntuneProfile.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userInviteId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.getUserinviteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.setUserinviteid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 circleId = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.getCircleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.setCircleid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.InviteStatus InviteStatus = 4;
 * @return {!proto.unifiedplatform.heartintune.common.InviteStatus}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.getInvitestatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.InviteStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.InviteStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.setInvitestatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional User user = 5;
 * @return {?proto.unifiedplatform.heartIntuneProfile.User}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.getUser = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.User} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.User, 5));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.User|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    circleinvitedetailsList: jspb.Message.toObjectList(msg.getCircleinvitedetailsList(),
    proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.toObject, includeInstance),
    circleactivityinvitedetailsList: jspb.Message.toObjectList(msg.getCircleactivityinvitedetailsList(),
    proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.toObject, includeInstance),
    paginationresponse: (f = msg.getPaginationresponse()) && proto.unifiedplatform.heartIntuneProfile.PaginationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse}
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse;
  return proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse}
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.deserializeBinaryFromReader);
      msg.addCircleinvitedetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.deserializeBinaryFromReader);
      msg.addCircleactivityinvitedetails(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationResponse;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationResponse.deserializeBinaryFromReader);
      msg.setPaginationresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCircleinvitedetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails.serializeBinaryToWriter
    );
  }
  f = message.getCircleactivityinvitedetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails.serializeBinaryToWriter
    );
  }
  f = message.getPaginationresponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CircleInviteDetails circleInviteDetails = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.getCircleinvitedetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.setCircleinvitedetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails}
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.addCircleinvitedetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.CircleInviteDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.clearCircleinvitedetailsList = function() {
  return this.setCircleinvitedetailsList([]);
};


/**
 * repeated CircleActivityInviteDetails circleActivityInviteDetails = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails>}
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.getCircleactivityinvitedetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.setCircleactivityinvitedetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails}
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.addCircleactivityinvitedetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.CircleActivityInviteDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.clearCircleactivityinvitedetailsList = function() {
  return this.setCircleactivityinvitedetailsList([]);
};


/**
 * optional PaginationResponse paginationResponse = 3;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.getPaginationresponse = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationResponse, 3));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.setPaginationresponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.clearPaginationresponse = function() {
  return this.setPaginationresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GetUsersFromMyCircleResponse.prototype.hasPaginationresponse = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fcmtokenList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    clearall: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken}
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken;
  return proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken}
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFcmtoken(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClearall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFcmtokenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getClearall();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken} returns this
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string fcmToken = 2;
 * @return {!Array<string>}
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.prototype.getFcmtokenList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken} returns this
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.prototype.setFcmtokenList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken} returns this
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.prototype.addFcmtoken = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken} returns this
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.prototype.clearFcmtokenList = function() {
  return this.setFcmtokenList([]);
};


/**
 * optional bool clearAll = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.prototype.getClearall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken} returns this
 */
proto.unifiedplatform.heartIntuneProfile.repeatedFcmToken.prototype.setClearall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastlogin: (f = msg.getLastlogin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    thirdsittingdate: (f = msg.getThirdsittingdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona;
  return proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastlogin(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setThirdsittingdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastlogin();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getThirdsittingdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp createdAt = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp lastLogin = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.getLastlogin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.setLastlogin = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.clearLastlogin = function() {
  return this.setLastlogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.hasLastlogin = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp thirdSittingDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.getThirdsittingdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona} returns this
*/
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.setThirdsittingdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona} returns this
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.clearThirdsittingdate = function() {
  return this.setThirdsittingdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.ResponseUserPersona.prototype.hasThirdsittingdate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestscheduleinfo: (f = msg.getRequestscheduleinfo()) && schedule_pb.RequestScheduleInfo.toObject(includeInstance, f),
    paginationrequest: (f = msg.getPaginationrequest()) && proto.unifiedplatform.heartIntuneProfile.PaginationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo;
  return proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new schedule_pb.RequestScheduleInfo;
      reader.readMessage(value,schedule_pb.RequestScheduleInfo.deserializeBinaryFromReader);
      msg.setRequestscheduleinfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationRequest;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationRequest.deserializeBinaryFromReader);
      msg.setPaginationrequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestscheduleinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      schedule_pb.RequestScheduleInfo.serializeBinaryToWriter
    );
  }
  f = message.getPaginationrequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional schedule.RequestScheduleInfo requestScheduleInfo = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.prototype.getRequestscheduleinfo = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo} */ (
    jspb.Message.getWrapperField(this, schedule_pb.RequestScheduleInfo, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.schedule.RequestScheduleInfo|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.prototype.setRequestscheduleinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.prototype.clearRequestscheduleinfo = function() {
  return this.setRequestscheduleinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.prototype.hasRequestscheduleinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PaginationRequest paginationRequest = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.prototype.getPaginationrequest = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationRequest, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.prototype.setPaginationrequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.prototype.clearPaginationrequest = function() {
  return this.setPaginationrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.RequestUserScheduleInfo.prototype.hasPaginationrequest = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduleinfoList: jspb.Message.toObjectList(msg.getScheduleinfoList(),
    schedule_pb.ScheduleInfo.toObject, includeInstance),
    paginationresponse: (f = msg.getPaginationresponse()) && proto.unifiedplatform.heartIntuneProfile.PaginationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo}
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo;
  return proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo}
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new schedule_pb.ScheduleInfo;
      reader.readMessage(value,schedule_pb.ScheduleInfo.deserializeBinaryFromReader);
      msg.addScheduleinfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationResponse;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationResponse.deserializeBinaryFromReader);
      msg.setPaginationresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduleinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      schedule_pb.ScheduleInfo.serializeBinaryToWriter
    );
  }
  f = message.getPaginationresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated schedule.ScheduleInfo scheduleInfo = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo>}
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.prototype.getScheduleinfoList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, schedule_pb.ScheduleInfo, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.prototype.setScheduleinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo}
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.prototype.addScheduleinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.schedule.ScheduleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.prototype.clearScheduleinfoList = function() {
  return this.setScheduleinfoList([]);
};


/**
 * optional PaginationResponse paginationResponse = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.prototype.getPaginationresponse = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationResponse, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.prototype.setPaginationresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.prototype.clearPaginationresponse = function() {
  return this.setPaginationresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserScheduledInfo.prototype.hasPaginationresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest;
  return proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest}
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopicid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string topicId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.prototype.getTopicid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscriptionId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.prototype.getSubscriptionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.prototype.setSubscriptionid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GcpPubSubRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profileurl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    refid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateofjoining: jspb.Message.getFieldWithDefault(msg, 4, ""),
    recordtype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    issuedby: jspb.Message.getFieldWithDefault(msg, 7, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 8, ""),
    email: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dob: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mysrcmid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    city: jspb.Message.getFieldWithDefault(msg, 12, ""),
    profession: jspb.Message.getFieldWithDefault(msg, 13, ""),
    street: jspb.Message.getFieldWithDefault(msg, 14, ""),
    state: jspb.Message.getFieldWithDefault(msg, 15, ""),
    country: jspb.Message.getFieldWithDefault(msg, 16, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 17, ""),
    cityid: jspb.Message.getFieldWithDefault(msg, 18, 0),
    idcardappliedfromhfnornot: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    ispopupshownflag: jspb.Message.getBooleanFieldWithDefault(msg, 20, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails;
  return proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileurl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateofjoining(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordtype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuedby(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDob(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMysrcmid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfession(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCityid(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIdcardappliedfromhfnornot(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspopupshownflag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfileurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRefid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateofjoining();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRecordtype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIssuedby();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDob();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMysrcmid();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getProfession();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCityid();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getIdcardappliedfromhfnornot();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getIspopupshownflag();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
};


/**
 * optional string displayName = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profileUrl = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getProfileurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setProfileurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string refId = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getRefid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setRefid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dateOfJoining = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getDateofjoining = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setDateofjoining = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string recordType = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getRecordtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setRecordtype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string mobileNumber = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string issuedBy = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getIssuedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setIssuedby = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string gender = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string email = 9;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string dob = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getDob = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setDob = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 mySrcmId = 11;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getMysrcmid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setMysrcmid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string city = 12;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string profession = 13;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getProfession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setProfession = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string street = 14;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string state = 15;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string country = 16;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string postalCode = 17;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int32 cityId = 18;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getCityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setCityid = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool idCardAppliedFromHfnOrNot = 19;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getIdcardappliedfromhfnornot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setIdcardappliedfromhfnornot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool isPopupShownFlag = 20;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.getIspopupshownflag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MYSRCMDetails.prototype.setIspopupshownflag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateofjoining: jspb.Message.getFieldWithDefault(msg, 1, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firebaseid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    jwttoken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner}
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner;
  return proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner}
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateofjoining(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwttoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateofjoining();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirebaseid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJwttoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string dateOfJoining = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.prototype.getDateofjoining = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.prototype.setDateofjoining = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string notes = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firebaseId = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.prototype.getFirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.prototype.setFirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string jwtToken = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.prototype.getJwttoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MySRCMPractitioner.prototype.setJwttoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    daycompleted: jspb.Message.getFieldWithDefault(msg, 3, 0),
    completedat: (f = msg.getCompletedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastnotified: (f = msg.getLastnotified()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sentnotificationcount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    notifystatus: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails;
  return proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMeditationid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDaycompleted(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastnotified(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSentnotificationcount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotifystatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDaycompleted();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCompletedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastnotified();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSentnotificationcount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getNotifystatus();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 meditationId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.getMeditationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.setMeditationid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 dayCompleted = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.getDaycompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.setDaycompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp completedAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.getCompletedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.setCompletedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.clearCompletedat = function() {
  return this.setCompletedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.hasCompletedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp lastNotified = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.getLastnotified = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.setLastnotified = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.clearLastnotified = function() {
  return this.setLastnotified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.hasLastnotified = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 sentNotificationCount = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.getSentnotificationcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.setSentnotificationcount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 userId = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool notifyStatus = 8;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.getNotifystatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.MeditationReminderDetails.prototype.setNotifystatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    requesttrackerinfoList: jspb.Message.toObjectList(msg.getRequesttrackerinfoList(),
    tracker_pb.RequestTrackerInfo.toObject, includeInstance),
    trackerinfoList: jspb.Message.toObjectList(msg.getTrackerinfoList(),
    tracker_pb.TrackerInfo.toObject, includeInstance),
    trackeridsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    paginationresponse: (f = msg.getPaginationresponse()) && proto.unifiedplatform.heartIntuneProfile.PaginationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails;
  return proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new tracker_pb.RequestTrackerInfo;
      reader.readMessage(value,tracker_pb.RequestTrackerInfo.deserializeBinaryFromReader);
      msg.addRequesttrackerinfo(value);
      break;
    case 2:
      var value = new tracker_pb.TrackerInfo;
      reader.readMessage(value,tracker_pb.TrackerInfo.deserializeBinaryFromReader);
      msg.addTrackerinfo(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTrackerids(values[i]);
      }
      break;
    case 4:
      var value = new proto.unifiedplatform.heartIntuneProfile.PaginationResponse;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.PaginationResponse.deserializeBinaryFromReader);
      msg.setPaginationresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequesttrackerinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      tracker_pb.RequestTrackerInfo.serializeBinaryToWriter
    );
  }
  f = message.getTrackerinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      tracker_pb.TrackerInfo.serializeBinaryToWriter
    );
  }
  f = message.getTrackeridsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getPaginationresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.unifiedplatform.heartIntuneProfile.PaginationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated tracker.RequestTrackerInfo requestTrackerInfo = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo>}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.getRequesttrackerinfoList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, tracker_pb.RequestTrackerInfo, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.setRequesttrackerinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.addRequesttrackerinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.clearRequesttrackerinfoList = function() {
  return this.setRequesttrackerinfoList([]);
};


/**
 * repeated tracker.TrackerInfo trackerInfo = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo>}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.getTrackerinfoList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, tracker_pb.TrackerInfo, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.setTrackerinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.addTrackerinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.clearTrackerinfoList = function() {
  return this.setTrackerinfoList([]);
};


/**
 * repeated int64 trackerIds = 3;
 * @return {!Array<number>}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.getTrackeridsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.setTrackeridsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.addTrackerids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.clearTrackeridsList = function() {
  return this.setTrackeridsList([]);
};


/**
 * optional PaginationResponse paginationResponse = 4;
 * @return {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.getPaginationresponse = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.PaginationResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.PaginationResponse, 4));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.PaginationResponse|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.setPaginationresponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.clearPaginationresponse = function() {
  return this.setPaginationresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.AddAndUpdateListOfUsersTrackerDetails.prototype.hasPaginationresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    discoveredcountMap: (f = msg.getDiscoveredcountMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse}
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse;
  return proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse}
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = msg.getDiscoveredcountMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDiscoveredcountMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<string, string> discoveredCount = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.prototype.getDiscoveredcountMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.DiscoverCountResponse.prototype.clearDiscoveredcountMap = function() {
  this.getDiscoveredcountMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    associatedgoalid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    associatedgoalcategoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdon: (f = msg.getCreatedon()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expiryon: (f = msg.getExpiryon()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    user: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response;
  return proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedgoalid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedgoalcategoryid(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiryon(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUser(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAssociatedgoalid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAssociatedgoalcategoryid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiryon();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 associatedGoalId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.getAssociatedgoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.setAssociatedgoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 associatedGoalCategoryId = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.getAssociatedgoalcategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.setAssociatedgoalcategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 4;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp createdOn = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.getCreatedon = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp expiryOn = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.getExpiryon = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.setExpiryon = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.clearExpiryon = function() {
  return this.setExpiryon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.hasExpiryon = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 user = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.getUser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.setUser = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus status = 8;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.getStatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    useracceptedgoalsv2responseList: jspb.Message.toObjectList(msg.getUseracceptedgoalsv2responseList(),
    proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse;
  return proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.deserializeBinaryFromReader);
      msg.addUseracceptedgoalsv2response(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUseracceptedgoalsv2responseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserAcceptedGoalsV2Response userAcceptedGoalsV2Response = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response>}
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.prototype.getUseracceptedgoalsv2responseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.prototype.setUseracceptedgoalsv2responseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response}
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.prototype.addUseracceptedgoalsv2response = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.UserAcceptedGoalsV2Response, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.UserGoalsResponse.prototype.clearUseracceptedgoalsv2responseList = function() {
  return this.setUseracceptedgoalsv2responseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    goalid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goalstatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    associatedid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    responsegoalinfov2: (f = msg.getResponsegoalinfov2()) && proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.toObject(includeInstance, f),
    isreminder: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification;
  return proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setGoalstatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedid(value);
      break;
    case 6:
      var value = new proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.deserializeBinaryFromReader);
      msg.setResponsegoalinfov2(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsreminder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGoalstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAssociatedid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getResponsegoalinfov2();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2.serializeBinaryToWriter
    );
  }
  f = message.getIsreminder();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 goalId = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus goalStatus = 4;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.getGoalstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.setGoalstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 associatedId = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.getAssociatedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.setAssociatedid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ResponseGoalInfoV2 responseGoalInfoV2 = 6;
 * @return {?proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.getResponsegoalinfov2 = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2, 6));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.ResponseGoalInfoV2|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.setResponsegoalinfov2 = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.clearResponsegoalinfov2 = function() {
  return this.setResponsegoalinfov2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.hasResponsegoalinfov2 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool isReminder = 7;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.getIsreminder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.prototype.setIsreminder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalschedulesnotificationList: jspb.Message.toObjectList(msg.getGoalschedulesnotificationList(),
    proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.toObject, includeInstance),
    goalstatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    notificationdetails: (f = msg.getNotificationdetails()) && notification_pb.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications;
  return proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.deserializeBinaryFromReader);
      msg.addGoalschedulesnotification(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setGoalstatus(value);
      break;
    case 3:
      var value = new notification_pb.Notification;
      reader.readMessage(value,notification_pb.Notification.deserializeBinaryFromReader);
      msg.setNotificationdetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalschedulesnotificationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification.serializeBinaryToWriter
    );
  }
  f = message.getGoalstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNotificationdetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      notification_pb.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GoalSchedulesNotification goalSchedulesNotification = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification>}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.getGoalschedulesnotificationList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.setGoalschedulesnotificationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.addGoalschedulesnotification = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.clearGoalschedulesnotificationList = function() {
  return this.setGoalschedulesnotificationList([]);
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus goalStatus = 2;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.getGoalstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.setGoalstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional notification.Notification notificationDetails = 3;
 * @return {?proto.unifiedplatform.heartIntuneProfile.notification.Notification}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.getNotificationdetails = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.notification.Notification} */ (
    jspb.Message.getWrapperField(this, notification_pb.Notification, 3));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.notification.Notification|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications} returns this
*/
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.setNotificationdetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications} returns this
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.clearNotificationdetails = function() {
  return this.setNotificationdetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.GoalSchedulesNotifications.prototype.hasNotificationdetails = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.CampaignStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasdonated: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    cause: jspb.Message.getFieldWithDefault(msg, 4, ""),
    channel: jspb.Message.getFieldWithDefault(msg, 5, 0),
    campaignid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isnotificationsent: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isbirthdayrequest: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    hasclicked: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    uuid: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.CampaignStat;
  return proto.unifiedplatform.heartIntuneProfile.CampaignStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasdonated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCause(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Channel} */ (reader.readEnum());
      msg.setChannel(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignid(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsnotificationsent(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsbirthdayrequest(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasclicked(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.CampaignStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHasdonated();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCause();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChannel();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCampaignid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getIsnotificationsent();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsbirthdayrequest();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getHasclicked();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool hasDonated = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getHasdonated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setHasdonated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string cause = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getCause = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setCause = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.Channel channel = 5;
 * @return {!proto.unifiedplatform.heartintune.common.Channel}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getChannel = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Channel} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Channel} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setChannel = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 campaignId = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getCampaignid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setCampaignid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool isNotificationSent = 7;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getIsnotificationsent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setIsnotificationsent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
*/
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool isBirthdayRequest = 9;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getIsbirthdayrequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setIsbirthdayrequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool hasClicked = 10;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getHasclicked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setHasclicked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string uuid = 11;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.CampaignStat} returns this
 */
proto.unifiedplatform.heartIntuneProfile.CampaignStat.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.LoginStatus = {
  UNKNOWN_STATUS: 0,
  ANONYMOUS: 1,
  LOGGEDIN: 2,
  LOGGEDOUT: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.Provider = {
  UNKNOWN_PROVIDER: 0,
  FACEBOOK: 1,
  GOOGLE: 2,
  APPLE: 3,
  EMAIL: 4,
  MOBILE: 5
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.Gender = {
  UNKNOWN_GENDER: 0,
  MALE: 1,
  FEMALE: 2,
  GENDER_DONT_REVEAL: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.Role = {
  UNKNOWN_ROLE: 0,
  SUPER_ADMIN: 1,
  ADMIN: 2,
  CONTENT_OWNER: 3,
  USER: 4,
  PRECEPTOR: 5,
  CUSTOM_ROLE: 6,
  SCHEDULER: 7,
  EVENTS_ANNOUNCEMENTS_ADMIN_ROLE: 8,
  BHE_CONTENT_MANAGER: 9
};

goog.object.extend(exports, proto.unifiedplatform.heartIntuneProfile);
