import {
  Grid,
  Typography,
  Button,
  Divider,
  Grow,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Step,
  StepButton,
  Stepper,
  StepLabel,
  Box,
  TablePagination,
  Alert,
  Snackbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { CreateLiveSessionForm } from "./createLiveSessionForm";
import { useDispatch, useSelector } from "react-redux";
import { getAllLiveMeditationsBetweenDatesV2 } from "redux/actions/liveMeditationAction";
import { RootState } from "redux/store/store";
import SessionCard from "./sessionInfoCard";
import { ListSkeleton } from "components/listSkeleton";
import { styled } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import styleCSS from "./index.module.scss";
import CreateLiveSessionBenifits from "./createLiveSessionBenefitsForm";
import { schedularUpdateStep } from "redux/reducers/liveMeditationReducer";

const TableWrapper = styled("div")({
  margin: "16px",
  // flex: "1 1 auto",
  height: "100%",
  // width: "100vw",
  overflow: "auto",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "5px",
  // width: "110%",
});

const CreateLiveSessionScreen = () => {
  const dispatch = useDispatch();

  const steps: string[] = ["Define Session", "Add Session Benefits"];

  const state = useSelector((state: RootState) => state.liveMeditation);
  const [currentScreen, setCurrentScreen] = useState(0);
  const [searchLiveSession, setSearchLiveSession] = useState("");
  const [searchData, setSearchData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sessionId, setsessionId] = useState(0);
  const [currentState, setCurrentState] = useState({
    type: "",
    isEditing: false,
    item: {},
  });
  const [snackBar, setSnackBar] = React.useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const _handleStepClick = (stepIndex: number) => {
    dispatch(schedularUpdateStep(stepIndex));
  };

  const _isStepDisabled = (stepIndex: number): boolean => {
    switch (stepIndex) {
      case 1:
        return !(
          state.selectedCoach !== undefined && state.selectedCoach !== null
        );
      case 2:
        // return !(state.sessionConfigType !== null);
        return state.sessionConfigType !== null;
      case 0:
      default:
        return false;
    }
  };

  const onClickCraeteSession = () => {
    dispatch(schedularUpdateStep(0));
    setCurrentScreen(1);
    setCurrentState({
      isEditing: false,
      item: {},
      type: "",
    });
  };

  const cereateSessionHandler = (id?: number) => {
    if (id) {
      setsessionId(id);
    }
    dispatch(schedularUpdateStep(1));
    setCurrentScreen(2);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getAllLiveMeditationsBetweenDatesV2(rowsPerPage, page, true));
  }, [dispatch, page, rowsPerPage]);

  const onClickBack = () => {
    dispatch(schedularUpdateStep(0));
    setCurrentScreen(0);
    setCurrentState({
      type: "",
      isEditing: false,
      item: {},
    });
  };

  const getSessionList = () => {
    let data =
      searchLiveSession !== ""
        ? searchData
        : state.liveMeditationsListBetween.liveMeditationsBetween
            .sessioninfodetailsList;

    return data;
  };

  const renderLiveSessionsListBetween = () => (
    <div>
      {state.liveMeditationsListBetween.liveMeditationsBetween
        .sessioninfodetailsList.length ? (
        <div>
          {getSessionList().map((item, index) => {
            return (
              <React.Fragment key={index}>
                {console.log(item)}
                <SessionCard
                  item={item}
                  setsessionId={setsessionId}
                  setCurrentState={setCurrentState}
                  setCurrentScreen={setCurrentScreen}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  dispatch={dispatch}
                  setSnackBar={setSnackBar}
                />
              </React.Fragment>
            );
          })}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={
              state.liveMeditationsListBetween.liveMeditationsBetween.pageable
                ?.totalcount ?? 0
            }
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
            className={styleCSS.scrollbar}
          />
        </div>
      ) : (
        <Box height={400}>
          <Grid
            container
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" color="textSecondary">
              No live session to show
            </Typography>
          </Grid>
        </Box>
      )}
    </div>
  );

  return (
    <>
      <Grid
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: "5px",
          margin: {
            lg: "20px 80px 0px ",
            md: "20px 25px 0px ",
            sm: "20px 11px 0px",
          },
        }}
      >
        <Grow
          in={currentScreen === 0}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 0 ? { timeout: 1000 } : {})}
        >
          <div>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                padding: " 16px",
              }}
            >
              <Grid item xs={12} sm={6} md={8} lg={6}>
                <Grid
                  container
                  spacing={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid item>
                    <Typography variant="h6">Define Sessions</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        onClickCraeteSession();
                      }}
                      startIcon={<AddIcon />}
                    >
                      Define new session
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <TableWrapper>
              <Paper>
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: "transparent" }}
                  className="py-2"
                >
                  <Grid className="d-flex align-items-center">
                    <TextField
                      type="search"
                      fullWidth
                      value={searchLiveSession}
                      variant="outlined"
                      placeholder="Search by session name"
                      size="small"
                      onChange={(e) => {
                        setSearchLiveSession(e.target.value);
                        const filteredData =
                          state.liveMeditationsListBetween.liveMeditationsBetween.sessioninfodetailsList.filter(
                            (sessionlist) =>
                              sessionlist.sessioninfo?.sessiontitle
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                          );

                        setSearchData(filteredData);
                      }}
                      className="ml-3"
                    />
                    <Grid>
                      <IconButton
                        onClick={() => {
                          dispatch(
                            getAllLiveMeditationsBetweenDatesV2(
                              rowsPerPage,
                              page,
                              true
                            )
                          );
                        }}
                        size="large"
                      >
                        <Tooltip title="Refresh">
                          <RefreshIcon />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                  {state.liveMeditationsListBetween.loading
                    ? ListSkeleton()
                    : renderLiveSessionsListBetween()}
                </Grid>
              </Paper>
            </TableWrapper>
          </div>
        </Grow>
        {currentScreen > 0 && currentState.type === "" && (
          <Stepper
            activeStep={state.scheduleStep}
            alternativeLabel
            nonLinear
            style={{ padding: "10px 0", margin: 0 }}
          >
            {steps.map((step, index) => (
              <Step key={step} completed={false}>
                <StepButton
                  onClick={() => {
                    _handleStepClick(index);
                  }}
                  disabled={_isStepDisabled(index)}
                >
                  <StepLabel optional={false}>{step}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
        <Grow
          in={currentScreen === 1}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 0 ? { timeout: 1000 } : {})}
        >
          <Paper>
            <CreateLiveSessionForm
              setsessionId={setsessionId}
              onClickBack={onClickBack}
              rowsPerPage={rowsPerPage}
              setCurrentScreen={setCurrentScreen}
              page={page}
              cereateSessionHandler={cereateSessionHandler}
              {...currentState}
            />
          </Paper>
        </Grow>
        <Grow
          in={currentScreen === 2}
          mountOnEnter
          unmountOnExit
          style={{ transformOrigin: "0 0 0" }}
          {...(currentScreen === 0 ? { timeout: 1000 } : {})}
        >
          <Paper>
            <CreateLiveSessionBenifits
              onClickBack={onClickBack}
              sessionId={sessionId}
              setcurrentScreen={setCurrentScreen}
              coachId={0}
              page={page}
              rowsPerPage={rowsPerPage}
            />
            .
          </Paper>
        </Grow>
      </Grid>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateLiveSessionScreen;
