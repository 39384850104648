import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Snackbar,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import { ImageOutlined } from "@mui/icons-material";
import { firebaseAuth } from "config/firebase";
import { grpc } from "@improbable-eng/grpc-web";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import {
  GAMIFICATION_SERVICE_HOST,
  HFN_EVENTS_SERVICE_HOST,
} from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import {
  EssentialDetails,
  EssentialRequest,
  ID,
  Status,
} from "api/models/hfn-events-service/hfn-events_pb";
import { useDispatch } from "react-redux";
import { getAllEssentials } from "redux/actions/bhandaraActions";
import { useFileUploadService } from "services/FileUplaodService";
import ImageCrop from "components/image-crop/image-crop";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";

const PREFIX = "CreateEvent";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  thumbNail: `${PREFIX}-thumbNail`,
  actionIcon: `${PREFIX}-actionIcon`,
};

interface EventProps {
  isEditing: any;
  editItem: any;
  onClickBack: any;
}

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    maxWidth: 350,
    // overflow: "scroll",
  },
  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `10%`,
    left: `40%`,
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },
}));
const phoneRegex = /^([0|+[0-9]{1,5})?([7-9][0-9]{9})$/;
export const AddService: any = React.forwardRef<HTMLDivElement, EventProps>(
  (props, ref): ReactElement => {
    const cropDef = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };

    const dispatch = useDispatch();
    const { appendFilesToUploadManager } = useFileUploadService();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [serviceName, setServiceName] = useState<string>("");
    const [contactName, setContactName] = useState<any>("");
    const [contactNumber, setContactNumber] = useState<any>("");
    const [whatsAppNumber, setWhatsAppNumber] = useState<string>("");
    const [smsNumber, setSmsNumber] = useState<string>("");
    const [landline, setLandline] = useState<string>("");
    const [locationUrl, setLocationUrl] = useState<string>("");
    const [editLocation] = useState<boolean>(true);
    const [isAdding, setIsAdding] = useState(false);

    const [croppedImageUrl, setCroppedImageUrl] = useState<any>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const hiddenFileInput: any = React.useRef(null);
    const [src, setSrc] = useState<any>(null);
    const [imageRef, setImageRef] = useState<any>();
    const [imageFile, setImageFile] = useState<any>(null);
    const [crop, setCrop] = useState<any>(cropDef);
    const [open, setopen] = useState(false);

    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const onClickEdit = useCallback((item: any) => {
      console.log("Edit event details: ", item);
      setServiceName(item.essentialtitle);
      setContactName(item.name);
      setContactNumber(item.mobilenumber);
      setWhatsAppNumber(item.whatsappnumber);
      setSmsNumber(item.smsnumber);
      setLandline(item.landlinenumber);
      setLocationUrl(item.directionurl);
      setCroppedImageUrl(item.imageurl);
    }, []);

    useEffect(() => {
      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
    }, [props.isEditing, props.editItem, onClickEdit]);

    // --------------------image crop and upload logic [start]--------------------//

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                //setIsLoading(false);
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
                //setCroppedImageUrl("");
              }
            } else {
              setIsLoading(false);
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    const onImageClose = () => {
      setSrc(null);
      setImageFile(null);
      _deleteFileFromGcp(croppedImageUrl);
      setCroppedImageUrl("");
      setCrop(cropDef);
    };

    const handleUploadClick = () => {
      hiddenFileInput.current.click();
      console.log("clicked on image upload");
    };
    const onSelectImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading(true);
        setImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };
    const onCropChange = (crop: any) => {
      setCrop(crop);
    };

    const onCropDone = async () => {
      setIsUploading(true);
      const cr: any = crop;
      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        setCroppedImageUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          "hfn-events/essentials".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setCroppedImageUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading(false);
            } else {
              setIsUploading(false);
            }
          }
        );
      }
      setSrc(null);
    };
    const _onOpenImage = () => {
      setopen(true);
    };
    const _onCloseImage = () => {
      setopen(false);
      setIsUploading(false);
    };

    // --------------------image crop and upload logic [end]--------------------//

    const addEssentials = async (
      essentialTitle: string,
      contactName: string,
      imagerUrl: string,
      mobileNumber: string,
      smsNumber: string,
      whatsAppNumber: string,
      landlineNumber: string,
      locationUrl: string
    ) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: EssentialRequest = new EssentialRequest();
      reqBody.setEssentialtitle(essentialTitle);
      reqBody.setName(contactName);
      reqBody.setImageurl(imagerUrl);
      reqBody.setMobilenumber(mobileNumber);
      reqBody.setSmsnumber(smsNumber);
      reqBody.setWhatsappnumber("+91" + whatsAppNumber);
      reqBody.setLandlinenumber(landlineNumber);
      reqBody.setDirectionurl(locationUrl);
      grpc.unary(HFNEventsService.AddEssentialDetails, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<EssentialDetails>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            setSnackBar({
              open: true,
              severity: "success",
              message: "Essential added sucessfully",
            });

            setTimeout(props.onClickBack, 1000);
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message:
                "Something went wrong while adding the essential service",
            });
          }
        },
      });
    };

    async function deleteEssentialById(id: number) {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(id);
      grpc.unary(HFNEventsService.DeleteEssentialDetailsById, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, statusMessage } = res;
          if (status === grpc.Code.OK) {
            setSnackBar({
              open: true,
              severity: "success",
              message: "Essential deleted sucessfully",
            });

            setTimeout(props.onClickBack, 1000);
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message:
                "Something went wrong while deleting the essential service",
            });
            console.log(
              "error in deleting essential service \n",
              statusMessage
            );
          }
        },
      });
    }

    const editEssentials = async (
      essentialTitle: string,
      contactName: string,
      imagerUrl: string,
      mobileNumber: string,
      smsNumber: string,
      whatsAppNumber: string,
      landlineNumber: string,
      locationUrl: string
    ) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: EssentialDetails = new EssentialDetails();
      reqBody.setId(props.editItem.id);
      reqBody.setEssentialtitle(essentialTitle);
      reqBody.setName(contactName);
      reqBody.setImageurl(imagerUrl);
      reqBody.setMobilenumber(mobileNumber);
      reqBody.setSmsnumber(smsNumber);
      reqBody.setWhatsappnumber(whatsAppNumber);
      reqBody.setLandlinenumber(landlineNumber);
      reqBody.setDirectionurl(locationUrl);
      grpc.unary(HFNEventsService.UpdateEssentialDetails, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<EssentialDetails>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            setSnackBar({
              open: true,
              severity: "success",
              message: "Essential updated sucessfully",
            });
            dispatch(getAllEssentials(50, 0));

            setTimeout(props.onClickBack, 1000);
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message:
                "Something went wrong while updaing the essential service",
            });
          }
        },
      });
    };

    function validateData() {
      if (serviceName.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Service name can not be empty",
        });
      } else if (contactName.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Contact name can't be empty",
        });
      } else if (!contactNumber.match(phoneRegex)) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Enter a valid contact number",
        });
      } else if (!whatsAppNumber.match(phoneRegex)) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Enter a valid WhatsApp number",
        });
      } else if (!smsNumber.match(phoneRegex)) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Enter a valid SMS number",
        });
      } else if (locationUrl === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Location Url can't be empty",
        });
      } else if (croppedImageUrl === "" || croppedImageUrl === null) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Please upload an image",
        });
      } else {
        return true;
      }
    }

    function onClickAddService() {
      if (validateData()) {
        setIsAdding(true);
        addEssentials(
          serviceName,
          contactName,
          croppedImageUrl,
          contactNumber,
          smsNumber,
          whatsAppNumber,
          landline,
          locationUrl
        );
      }
    }

    function onClickUpdateService() {
      if (validateData()) {
        setIsAdding(true);
        editEssentials(
          serviceName,
          contactName,
          croppedImageUrl,
          contactNumber,
          smsNumber,
          whatsAppNumber,
          landline,
          locationUrl
        );
      }
    }

    return (
      <Root ref={ref}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          style={{
            padding: " 8px",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Grid
              container
              className={props.isEditing ? "" : "align-items-center"}
            >
              <Grid item>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Service" : "Add Service"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      services
                    </Typography>
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {}}
                    >
                      {serviceName}
                    </Typography>
                    <Typography color="textPrimary">edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteEssentialById(props.editItem.id)}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="serviceName"
                  label="Service Name"
                  variant="outlined"
                  onChange={(e) => {
                    setServiceName(e.target.value);
                  }}
                  value={serviceName}
                  className="mr-3"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="contactName"
                  label="Contact Name"
                  variant="outlined"
                  onChange={(e) => {
                    setContactName(e.target.value);
                  }}
                  value={contactName}
                  className="mr-3"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  name="contactNumber"
                  label="Mobile Number"
                  variant="outlined"
                  error={!contactNumber.match(phoneRegex)}
                  helperText={"Enter a valid 10 digit number"}
                  onChange={(e) => {
                    setContactNumber(e.target.value);
                  }}
                  value={contactNumber}
                  className="mr-3"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  name="WhatsApp Number"
                  label="WhatsApp Number"
                  variant="outlined"
                  error={!whatsAppNumber.match(phoneRegex)}
                  helperText={"Enter a valid 10 digit number"}
                  onChange={(e) => {
                    setWhatsAppNumber(e.target.value);
                  }}
                  value={whatsAppNumber}
                  className="mr-3"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  name="sms-number"
                  label="SMS Number"
                  variant="outlined"
                  error={!smsNumber.match(phoneRegex)}
                  helperText={"Enter a valid 10 digit number"}
                  onChange={(e) => {
                    setSmsNumber(e.target.value);
                  }}
                  value={smsNumber}
                  className="mr-3"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  name="landline"
                  label="Landline"
                  variant="outlined"
                  // error={!contactNumber.match(phoneRegex)}
                  // helperText={"Enter a valid 10 digit number"}
                  onChange={(e) => {
                    setLandline(e.target.value);
                  }}
                  value={landline}
                  className="mr-3"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className="d-flex">
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="location-url"
                  label="Location Url"
                  variant="outlined"
                  onChange={(e) => {
                    setLocationUrl(e.target.value);
                  }}
                  value={locationUrl}
                  className="mr-3"
                />
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {editLocation ? (
                    <>
                      {croppedImageUrl ? (
                        <PreviewProfileImage
                          onImageClose={onImageClose}
                          src={croppedImageUrl}
                        />
                      ) : (
                        <>
                          {isUploading === true ? (
                            <Card className={classes.thumbNail}>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className="pt-5"
                              >
                                <Typography className="pt-4">
                                  Uploading...
                                </Typography>
                              </Grid>
                            </Card>
                          ) : (
                            <CardActionArea
                              className={classes.thumbNail}
                              onClick={() => {
                                handleUploadClick();
                              }}
                            >
                              <InputLabel htmlFor="thumbnail">
                                Upload image
                              </InputLabel>
                              <Avatar
                                className={classes.actionIcon}
                                variant="square"
                              >
                                <ImageOutlined />
                              </Avatar>
                              <input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                hidden
                                onChange={onSelectImage}
                                ref={hiddenFileInput}
                              />
                            </CardActionArea>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className="mt-4"
                    >
                      {croppedImageUrl === "" || croppedImageUrl === null ? (
                        <Avatar
                          src=""
                          style={{ height: "200px", width: "200px" }}
                        />
                      ) : (
                        <img
                          alt="coach Profile"
                          src={croppedImageUrl}
                          style={{
                            height: "200px",
                            width: "200px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </Grid>
                  )}
                </Card>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className=" p-2 d-flex justify-content-end">
          <Box display="flex" justifyContent="flex-end">
            {props.isEditing ? (
              <>
                <Button
                  onClick={props.onClickBack}
                  color="primary"
                  variant="contained"
                  className="mr-2"
                >
                  Cancel
                </Button>
                <Button
                  onClick={onClickUpdateService}
                  color="primary"
                  variant="contained"
                  disabled={isAdding}
                >
                  Update Service
                </Button>
              </>
            ) : (
              <Button
                disabled={
                  !contactNumber.match(phoneRegex) ||
                  !whatsAppNumber.match(phoneRegex) ||
                  !smsNumber.match(phoneRegex) ||
                  isAdding
                }
                onClick={onClickAddService}
                color="primary"
                variant="contained"
              >
                Add Service
              </Button>
            )}
          </Box>
        </CardActions>
        {src && (
          <ImageCrop
            src={src.toString()}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onCropChange={onCropChange}
            onPressDone={onCropDone}
            onImageClose={onImageClose}
            open={open}
            onOpen={_onOpenImage}
            onClose={_onCloseImage}
          />
        )}
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
