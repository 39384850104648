import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Grow,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ReactElement, useState, useEffect } from "react";
import { ListSkeleton } from "components/listSkeleton";
import RefreshIcon from "@mui/icons-material/Refresh";
import style from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { getAllHomeScreenCards } from "redux/actions/bhandaraActions";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { homeScreenCardsLoading } from "redux/reducers/bhandaraReducer";
import { Status, WidgetOrder } from "api/models/hfn-events-service/hfn-events_pb";
import { firebaseAuth } from "config/firebase";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { HFN_EVENTS_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";


export default function HomeScreenCardsScreen(): ReactElement {
  const dispatch = useDispatch();
  const homeScreenCards = useSelector(
    (state: RootState) => state.bhandara.homeScreenCards
  );
  const [searchArticle, setSearchArticle] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);


  const saveHomeScreenOrder = async (id: number, ordernumber: number, title: string, visible: boolean = true) => {
    try {
      dispatch(homeScreenCardsLoading(true));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: WidgetOrder = new WidgetOrder();
      reqBody.setTitle(title);
      reqBody.setOrdernumber(ordernumber);
      reqBody.setVisible(visible);
      reqBody.setId(id);
      grpc.unary(HFNEventsService.SaveOrUpdateWidgetOrder, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message, statusMessage } = res;
          console.log("Cards Order Saved", message?.toObject());
          if (status === grpc.Code.OK && message) {
            dispatch(getAllHomeScreenCards(0, true));
          } else {
            console.error("error in saving cards order", statusMessage, status);
            dispatch(homeScreenCardsLoading(false));
          }
        },
      });
    } catch (err) {
      dispatch(homeScreenCardsLoading(false));
      console.error("error in request to save cards order", err);
    }
  }

  useEffect(() => {
    dispatch(getAllHomeScreenCards(0, true));
  }, [dispatch]);

  const handleToggleVisibility = (item: any, visible: boolean) => {
    saveHomeScreenOrder(item.id, item.ordernumber, item.title, visible);
  };

  const handleMoveUp = (item: any, index: number) => {
    if (index > 0) {
      saveHomeScreenOrder(item.id, item.ordernumber - 1, item.title);
    }
  };

  const handleMoveDown = (item: any, index: number) => {
    if (index < homeScreenCards.cards.widgetorderlistList.length - 1) {
      saveHomeScreenOrder(item.id, item.ordernumber + 1, item.title);
    }
  };

  const renderHomeScreenCardsList = (data: any) => {
    return (
      <div>
        {data.length ? (
          <>
            <Grid
              style={{ backgroundColor: "transparent" }}
              className="d-flex py-2 pl-3 align-items-center"
            >
              <Grid className="d-flex flex-grow-1">
                <TextField
                  type="search"
                  fullWidth
                  value={searchArticle}
                  variant="outlined"
                  placeholder="Search article by title"
                  size="small"
                  onChange={(e) => {
                    setSearchArticle(e.target.value);
                    const filteredData = data.filter((item: any) =>
                      item.title
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    setsearchData(filteredData);
                  }}
                  className="mr-2"
                />
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer
              style={{ maxHeight: "400px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell
                      align="left"
                    >
                      Card Title
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      Order
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      Enable/Disable
                    </TableCell>
                    <TableCell align="left">Card Order</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchArticle !== "" ? searchData : data).map(
                    (item: any, x: any) => (
                      <React.Fragment key={x}>
                        <TableRow
                          sx={{ cursor: "pointer", height: "50px" }}
                        >
                          <TableCell align="left">
                            {item.title}
                          </TableCell>
                          <TableCell align="left">
                            {item.ordernumber}
                          </TableCell>
                          <TableCell align="left">
                            <Switch
                              checked={item.visible}
                              onChange={() => handleToggleVisibility(item, !item.visible)}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              size="small"
                              onClick={() => handleMoveUp(item, x)}
                              disabled={x === 0}
                            >
                              <ArrowUpwardIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => handleMoveDown(item, x)}
                              disabled={x === (searchArticle !== "" ? searchData : data).length - 1}
                            >
                              <ArrowDownwardIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={homeScreenCards.cards.widgetorderlistList.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            /> */}
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No cards to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Card className="mt-3 mb-5">
            <Grow
              in={true}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(true ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid container className="align-items-center p-3" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">Cards Order</Typography>
                  </Grid>
                  {/* <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                    >
                      Save Order
                    </Button>
                  </Grid> */}
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    {homeScreenCards.loading
                      ? ListSkeleton()
                      : renderHomeScreenCardsList(
                          homeScreenCards.cards.widgetorderlistList
                        )}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
